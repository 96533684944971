import React from "react";
import Link from "react-router-dom/Link";

export const projects = [
  {
    image: "/img/projects/fashionovation.jpg",
    title: "Fashionovation & Business fashion sprint",
    project: (
      <>
        <p>Акселерационная программа для предпринимателей индустрии моды</p>
        <p>
          Высшая школа стилистики, дизайна и технологий является официальным
          партнером и представителем в России компании{" "}
          <a href="https://fashionovation.us" target="_blank">
            Fashionovation.us
          </a>{" "}
        </p>
        <p>
          Участники программы получают новые связи и каналы продаж, поддержку и
          документы установленного в РФ образца об образовательной программе в
          рамках акселератора.
        </p>
        <p>
          О программе акселератора смотрите{" "}
          <Link to="/bfashionsprint">здесь &gt;&gt;&gt;</Link>
        </p>
        <p>
          Официальный портал партнера —
          <a href="https://fashionovation.us" target="_blank">
            Fashionovation.us
          </a>
        </p>
      </>
    )
  },
  {
    image: "/img/projects/fashionleaders.jpg",
    title:
      "Всероссийский конкурс креативных специалистов индустрии моды «ЛИДЕРЫ МОДЫ»",
    project: (
      <>
        <p>
          Рекомендуем проект наших партнеров{" "}
          <a href="https://моднаясеть.рф" target="_blank">
            моднаясеть.рф
          </a>{" "}
          — FASHION LEADERS
        </p>
        <p>
          Это масштабный конкурс среди студентов и молодых профессионалов в
          индустрии моды по 20 различным номинациям, включающий проведение
          фестиваля и образовательную программу. Проект дает возможность
          получить гранты на продвижение собственного проекта или повышение
          квалификации, пройти стажировку в крупных компаниях как в России так и
          за рубежом, пройти практику в брендах с мировым именем.
        </p>
        <p>
          Официальный портал проекта —{" "}
          <a href="fashionleaders.ru" target="_blank">
            fashionleaders.ru
          </a>
        </p>
      </>
    )
  }
  /*
  {
    image: "",
    title: "",
    project: <></>
  },  
  */
];
