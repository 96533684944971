import React from "react";
import { Grid } from "@material-ui/core";
import Paragraph from "../../components/p-array";
import List from "../../components/list";
import { styles } from "./styles";
import Teacher from "../../components/teacher-in-course";
import Ig from "../../components/instagram";

export const prochorovich = (
  <Paragraph
    content={[
      <Ig a="elenacult_com" />,
      <>
        Fashion маркетолог, бизнес-тренер, преподаватель и автор курсов в Высшей
        школе стилистики, дизайна и технологий:
      </>,
      <List
        arr={[
          <>«Личный бренд в индустрии моды и дизайна»</>,
          <>«Международные коммуникации, вывод бренда на глобальный рынок»</>,
          <>«Технологии запуска StartUp- проектов»</>,
          <>Контентмейкер и визуализатор бренда</>
        ]}
        style={{ ...styles.p, textAlign: "justify" }}
      />,
      <>Основатель международных коммуникационных образовательных проектов:</>,
      <List
        arr={[
          <>Стажировки на Milan fashion week / Milan design week;</>,
          <>Summer fashion school in Italy;</>,
          <>Fashion-практика в Париже;</>,
          <>Fashion-практика в Грузии</>
        ]}
      />,
      <>
        Руководитель Центра Карьеры ВШСДТ. Куратор fashion-практик. Организация
        Fashion workshop / Fashion-фотосессий. Более 10 лет консультирую
        компании/экспертов по продвижению брендов и занимаюсь организацией
        профессиональных мероприятий в сфере моды и дизайна, а также
        маркетинговыми и бизнес исследованиями.
      </>,
      <>Член Российской Ассоциации связей с общественностью.</>,
      <>
        Делюсь знаниями, накопленными за 15 лет постоянной практики и
        обучения,последние 7 лет в индустрии моды и дизайна.На своем примере и
        примере моих учеников показываю, как превратить идею в работающий
        бизнес, рассказываю зачем нужен личный бренд предпринимателю и делюсь
        способами его монетизации. Раскрываю секреты и специфику продвижения в
        fashion & design индустрии.
      </>
    ]}
    style={{ ...styles.p, textAlign: "justify" }}
  />
);

export default function Teachers() {
  return (
    <Grid container justify="center" alignItems="stretch">
      <Grid item xs={12} md={12}>
        <h3 style={styles.h2small}>Автор и ведущая курса</h3>
      </Grid>
      <Grid item xs={12} md={12}>
        <Teacher
          head="Прохорович Елена Владимировна"
          text={prochorovich}
          image="/img/personal-brand/prochorovish.jpg"
          styleImg={{ maxWidth: 320 }}
        />
      </Grid>
    </Grid>
  );
}
