import React from "react";
import Link from "react-router-dom/Link";
import Button from "../../components/button";
import Ig from "../../components/instagram";
import List from "../../components/list";
import "./style.css";

export const rozhnova = {
  descript: (
    <>
      Свежий номер Журнала: Instagram <Ig a="teencruze" />
      <br />
      Организация: Instagram <Ig a="ema_official_rus" />
      <br />
      Фото: Instagram <Ig a="solaris_foto" />
      <br />
      Образы моделей: Instagram <Ig a="zhannarozhnova" />{" "}
      <Ig a="r_mironova_style" /> <Ig a="muah_bynastya" />
      <br />
      Стиль: Instagram <Ig a="stylist_u" />
      <br />
      Продюсер: Instagram <Ig a="evmaltseva" />
    </>
  ),
  slides: [
    "/img/about/photosessions/rozhnova-01.jpg",
    "/img/about/photosessions/rozhnova-02.jpg",
    "/img/about/photosessions/rozhnova-03.jpg",
    "/img/about/photosessions/rozhnova-04.jpg",
    "/img/about/photosessions/rozhnova-05.jpg",
    "/img/about/photosessions/rozhnova-06.jpg",
    "/img/about/photosessions/rozhnova-07.jpg"
  ]
};

export const sotnikova = {
  descript: (
    <>
      Стилист фотосессии Алина Сотникова. Instagram <Ig a="alinaas.diary" />
    </>
  ),
  slides: [
    "/img/about/photosessions/sotnikova-01.jpg",
    "/img/about/photosessions/sotnikova-02.jpg",
    "/img/about/photosessions/sotnikova-03.jpg",
    "/img/about/photosessions/sotnikova-05.jpg",
    "/img/about/photosessions/sotnikova-04.jpg"
  ]
};

export const troepolskaya = {
  descript: (
    <>
      Любовь Троепольская
      <br />
      Fashion stylist: Instagram <Ig a="troepolskayalstyle" />
    </>
  ),
  slides: [
    "/img/about/photosessions/troepolskaya-01.jpg",
    "/img/about/photosessions/troepolskaya-02.jpg",
    "/img/about/photosessions/troepolskaya-03.jpg",
    "/img/about/photosessions/troepolskaya-04.jpg",
    "/img/about/photosessions/troepolskaya-05.jpg",
    "/img/about/photosessions/troepolskaya-06.jpg",
    "/img/about/photosessions/troepolskaya-07.jpg",
    "/img/about/photosessions/troepolskaya-08.jpg",
    "/img/about/photosessions/troepolskaya-09.jpg",
    "/img/about/photosessions/troepolskaya-10.jpg",
    "/img/about/photosessions/troepolskaya-11.jpg"
  ]
};

export const practicsExemple = {
  descript: "Пример фотографий с практик",
  slides: [
    "/img/about/photosessions/practics-01.jpg",
    "/img/about/photosessions/practics-02.jpg",
    "/img/about/photosessions/practics-03.jpg",
    "/img/about/photosessions/practics-04.jpg",
    "/img/about/photosessions/practics-05.jpg",
    "/img/about/photosessions/practics-06.jpg",
    "/img/about/photosessions/practics-07.jpg",
    "/img/about/photosessions/practics-08.jpg",
    "/img/about/photosessions/practics-09.jpg",
    "/img/about/photosessions/practics-10.jpg",
    "/img/about/photosessions/practics-11.jpg",
    "/img/about/photosessions/practics-12.jpg",
    "/img/about/photosessions/practics-13.jpg",
    "/img/about/photosessions/practics-14.jpg",
    "/img/about/photosessions/practics-15.jpg",
    "/img/about/photosessions/practics-16.jpg",
    "/img/about/photosessions/practics-17.jpg",
    "/img/about/photosessions/practics-18.jpg",
    "/img/about/photosessions/practics-19.jpg",
    "/img/about/photosessions/practics-20.jpg",
    "/img/about/photosessions/practics-21.jpg",
    "/img/about/photosessions/practics-22.jpg",
    "/img/about/photosessions/practics-23.jpg",
    "/img/about/photosessions/practics-24.jpg",
    "/img/about/photosessions/practics-25.jpg"
  ]
};

export const bogatyriova = {
  descript: (
    <>
      <p className="p-constructor">Stylebook Богатыревой Оксаны</p>
      <p className="p-constructor">
        Instagram стилиста <Ig a="oxana_bogatyreva" />
      </p>
    </>
  ),
  media: "lBvZILAKMp8"
};

export const opalina = {
  descript: (
    <>
      <p className="p-constructor">Stylebook Ольги Апалиной</p>
      <p className="p-constructor">
        Instagram стилиста <Ig a="apalstyle" />
      </p>
    </>
  ),
  media: "sDar9Ok7awA"
};

export const malvie = {
  descript: (
    <>
      <h3>
        Обложка и фотосессия в журнале Malvie Magazine, август 2021 (Франция)
      </h3>
      <List
        arr={[
          <>
            Журнал: Malvie Magazine <Ig a="malviemag" />
          </>,
          <>Съёмка: Natural beauty</>,
          <>
            Photo: Евгений Стулов <Ig a="solaris_foto" />
          </>,
          <>
            Cr Dir: Евгения Мальцева <Ig a="evmaltseva" />
          </>,
          <>
            Stylist: Русудан Миронова <Ig a="r_mironova_style" />
          </>,
          <>
            Assistant: Мария Стецурина <Ig a="mariastetsurina" />
          </>,
          <>
            MUA: Анастасия Норкина <Ig a="muah_bynastya" />
          </>,
          <>
            Assistant: Евгения Веретина <Ig a="everetina_official" />
          </>,
          <>
            Hair: Татьяна Трубинова <Ig a="tatiana_t007" />
          </>,
          <>
            Assistant: Екатерина Жандаева <Ig a="katerina_ema_" />
          </>,
          <>
            Model: <Ig a="anna_nazarenko_official" />
          </>,
          <>
            Model: <Ig a="starygina_masha" />
          </>,
          <>
            Model: <Ig a="eva_shipunova_model" />
          </>,
          <>
            Model: <Ig a="trebinaanastasia" />
          </>,
          <>
            Model: <Ig a="danausova_margarita_official" />
          </>,
          <>
            Video: Екатерина Жандаева <Ig a="katerina_ema_" />
          </>
        ]}
        num="none"
        olStyle={{
          paddingLeft: 0,
          marginLeft: 0
        }}
      />
      <Link to="/stylistportfolio-malviemagazine-aug21">
        <div className="button-link">Подробнее</div>
      </Link>
    </>
  ),
  cover: (
    <img
      src="/img/stylistportfolio-malviemagazine-aug21/malvie.jpg"
      alt="Обложка журнала Malvie Magazine, август 2021 (Франция)"
    />
  )
};

export const karimova = {
  descript: (
    <>
      <h3>Фотосессия выпускницы Лейсан Каримовой</h3>
      <List
        arr={[
          <>Photo: Pauli Salmi</>,
          <>
            Photosession idea: Лейсан Каримова <Ig a="leysan.karimova" />
          </>,
          <>
            Stylist & hairstylist & makeup: Лейсан Каримова{" "}
            <Ig a="leysan.karimova" />
          </>,
          <>
            Model: Лейсан Каримова <Ig a="leysan.karimova" />
          </>
        ]}
        num="none"
        olStyle={{
          paddingLeft: 0,
          marginLeft: 0
        }}
      />
      <Link to="/stylistportfolio-leysan-karimova">
        <div className="button-link">Подробнее</div>
      </Link>
    </>
  ),
  cover: (
    <img
      src="/img/stylistportfolio-leysan-karimova/slide-03.jpg"
      alt="Фотосессия выпускницы Лейсан Каримовой"
      style={{ width: 400, height: "auto" }}
    />
  )
};

export const podlesnyh = {
  descript: (
    <>
      <h3>Forward to the future, куратор Марина Подлесных</h3>
      <List
        arr={[
          <>
            Организатор: <Ig a="styleschool_msk" />
          </>,
          <>
            Куратор практики / преподаватель: Подлесных Марина{" "}
            <Ig a="marina_lefay" />
          </>,
          <>
            Photo: Евгений Стулов
            <Ig a="solaris_foto" />
          </>,
          <>
            Stylist & Model: Папава Лиана <Ig a="etopapali" />
          </>,
          <>
            Шилина Анастасия <Ig a="stasya.shilina" />
          </>,
          <>
            Радецкая Оля <Ig a="olya_malysheva" />
          </>,
          <>
            Сотникова Алина <Ig a="alinaas.diary" />
          </>,
          <>
            Якупова Евгения <Ig a="yakupova__evgenia" />
          </>,
          <>
            Зиненко Алёна <Ig a="aliona_mladi" />
          </>
        ]}
        num="none"
        olStyle={{ paddingLeft: 0, marginLeft: 0 }}
      />
      <Link to="/portfolio-marina-podlesnyh">
        <div className="button-link">Подробнее</div>
      </Link>
    </>
  ),
  cover: (
    <img
      src="/img/backstage/student_01.jpg"
      alt=""
      style={{ width: 400, height: "auto" }}
    />
  )
};
