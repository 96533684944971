import React from "react";
import Ig from "../../components/instagram";

export function findNewsById(id, news) {
  const l = news.length;
  let el = {};

  for (let i = 0; i < l; i++) {
    if (news[i].id == id) {
      el = news[i];
      break;
    }
  }
  return el;
}

export const news = [
  {
    id: "04082021",
    date: new Date(2021, 7, 4),
    title:
      "Обучение по программе «Консультант по стилю» по государственному гранту",
    grayText: (
      <>
        Обучение по программе повышения квалификации "Консультант по стилю"
        доступно в рамках федерального проекта РФ «Содействие занятости»
        национального проекта «Демография»: определенным категориям граждан
        обучение полностью оплачивает государство.
      </>
    ),
    image: "/img/news/04082021.jpg"
  },
  {
    id: "0003",
    date: new Date(2021, 7, 2),
    title: "В августе: курсы по стилю и дизайну, доступные для всех",
    grayText: (
      <>
        Специальный социально ориентированный проект, инициированный
        Иновационно-образовательным комплексом Техноград с участием
        преподавателей ВШСДТ. Курсы по индивидуальному стилю, личному PR и
        дизайну интерьера доступны по минимальной стоимости.
      </>
    ),
    image: "/img/news/0001.jpg"
  },
  {
    id: "0002",
    date: new Date(2021, 6, 30),
    title: "Утверждена дата старта «Дизайнер чат-ботов с ИИ» в сентябре",
    grayText: (
      <>
        Новая программа обучения профессии в области IT технологий, созданная
        специалистами ВШСДТ и партнером ВШСДТ, компанией «0110 LAB», реализуемая
        при поддержке МФТИ. Студенты первой группы уже проходят стажировку в
        компании Яндекс.
      </>
    ),
    image: "/img/news/0002.jpg"
  },
  {
    id: "0001",
    date: new Date(2021, 6, 21),
    title:
      "Партнерская программа ВШСДТ для тренеров и образовательных организаций",
    grayText: (
      <>
        Сотрудничество для частных лекторов и образовательных проектов,
        заинтересованных: в законном ведении обучения на основе лицензии, выдаче
        документов установленного государством образца, участие в грантах и
        совместном продвижении
      </>
    ),
    image: "/img/news/0003.jpg"
  },
  {
    id: "16082021",
    date: new Date(2021, 7, 16),
    title: "18 и 21 августа, Арт.Техноград, встречи ассоциации ВШСДТ",
    grayText: (
      <>
        Нетворкинг, обсуждение актуальных вопросов в работе стилиста.
        Возможности для заработка стилиста. Сотрудничество в рамках Ассоциации
        выпускников ВШСДТ.
      </>
    ),
    image: "/img/news/17082021.jpg"
  },
  {
    id: "17082021",
    date: new Date(2021, 7, 17),
    title: "Обложка и фотосессия в журнале Malvie Magazine, август 2021",
    grayText: (
      <>
        Фотосессия организована преподавателями ВШСДТ Евгением Стуловым и
        Евгенией Мальцевой. В съемках ассистентами стилиста выступили выпускницы
        ВШСДТ Мария Стецурина и Евгения Веретина.
      </>
    ),
    image: "/img/news/17082021_malvie.jpg"
  },
  {
    id: "2508",
    date: new Date(2021, 7, 25),
    title: "В сентябре: курсы по стилю и дизайну, доступные для всех",
    grayText: (
      <>
        Специальный социально ориентированный проект, инициированный
        Иновационно-образовательным комплексом Техноград с участием
        преподавателей ВШСДТ. Курсы по индивидуальному стилю, личному PR и
        дизайну интерьера доступны по минимальной стоимости.
      </>
    ),
    image: "/img/news/25082021.jpg"
  },
  {
    id: "260821",
    date: new Date(2021, 7, 26),
    title:
      "28 августа презентация новых книг Наталии Найденской и Инессы Трубецковой",
    grayText: (
      <>
        В рамках презентации Инесса Трубецкова проведет Мастер-класс «Как найти
        главное достоинство своей фигуры и стоит ли сегодня стремиться к
        идеалу?». Мероприятие пройдет в книжном магазине «Библио-Глобус» по
        адресу: Москва, ул. Мясницкая, д. 6/3, стр. 1
      </>
    ),
    image: "/img/news/260821.jpg"
  },
  {
    id: "160921",
    date: new Date(2021, 8, 16),
    title:
      "Бесплатное обучение по программам «Консультант по стилю» и «Интерьерный стилист»",
    grayText: (
      <>
        Вам может быть доступно обучение новой профессии в области
        имидж-консалтинга и дизайна интерьера в рамках федерального проекта
        «Содействие занятости», если Вы подходите под определенную категорию.
        Обучение полностью оплачивает государство.
      </>
    ),
    image: "/img/news/280821.png"
  },
  {
    id: "290821",
    date: new Date(2021, 7, 29),
    title: "2 сентября, четверг, Арт.Техноград, встреча выпускников",
    grayText: (
      <>
        Нетворкинг, обсуждение актуальных вопросов в работе стилиста.
        Возможности для заработка стилиста. Сотрудничество в рамках Ассоциации
        выпускников ВШСДТ.
      </>
    ),
    image: "/img/news/17082021.jpg"
  },
  {
    id: "220921",
    date: new Date(2021, 8, 22),
    title: "25 сентября, суббота, Школа юного стилиста",
    grayText: (
      <>
        Для девушек от 13 до 18 лет и их мам. Сезон открытых лекций и
        мастер-классов осень-зима 2021-22. Бесплатно для жителей и гостей
        Москвы. Занятия ведут практикующие имидж-стилисты.
      </>
    ),
    image: "/img/news/220921.jpg"
  },
  {
    id: "011021",
    date: new Date(2021, 9, 1),
    title: "09 октября, суббота, Школа юного стилиста",
    grayText: (
      <>
        Теория цвета и модные тенденции. Новые встречи в сезоне открытых лекций
        и мастер-классов осень-зима 2021-22. Занятия проводят практикующие
        стилисты.
      </>
    ),
    image: "/img/news/011021.jpg"
  },
  {
    id: "021021",
    date: new Date(2021, 9, 2),
    title: "Курсы по стилю и дизайну, доступные для всех",
    grayText: (
      <>
        Специальный социально ориентированный проект, инициированный
        Иновационно-образовательным комплексом Техноград с участием
        преподавателей ВШСДТ. Курсы по дизайну интерьера в октябре, по стилю в
        декабре.
      </>
    ),
    image: "/img/news/021021.png"
  },
  {
    id: "161021",
    date: new Date(2021, 9, 16),
    title: "В ноябре 2021 года доступно обучение по гос программе",
    grayText: (
      <>
        Вам доступно обучение новой профессии Дизайнера чат-ботов (область
        искусственного интеллекта и диалоговых систем), если Вы подходите под
        определенную категорию граждан или готовы обратиться в центр занятости
        для получения справки и последующего обучения с целью получения новой
        профессии или повышения квалификации на текущем месте работы.
      </>
    ),
    image: "/img/news/161021.jpg"
  },
  {
    id: "151021",
    date: new Date(2021, 9, 15),
    title:
      "18 октября, 7 шагов к построению и запуску успешного fashion business",
    grayText: (
      <>
        Для создания успешного fashion business творческому дизайнеру необходимо
        знать алгоритмы предпринимательства и маркетинга, и быть в курсе новых
        технологий, которые дают дополнительные возможности!.
      </>
    ),
    image: "/img/news/151021.jpg",
    link: "https://fashionovation.us/web_7steps"
  },
  {
    id: "101121",
    date: new Date(2021, 10, 10),
    title: "Поздравляем нашу коллегу и преподавателя с номинацией",
    grayText: (
      <>
        Эмилия Жилова, лауреат в номинации «Мужской стилист-имиджмейкер года»: «
        <i>
          Было безумно приятно оказаться на одной сцене с единственным
          российским участником синдиката Высокой Моды и Прет-а-Порте Франции
          Владимиром Серединым (бренд SEREDIN PARIS), с такими звездами, как
          @angelicaagurbash, @yurirozum, @shakhov5295, @levan_todua и многими
          другими
        </i>
        »
      </>
    ),
    image: "/img/news/101121.jpg"
  },
  {
    id: "151121",
    date: new Date(2021, 10, 15),
    title: "Имидж-практика в пространстве магазина M.REASON",
    grayText: (
      <>
        13 ноября в ТРК «Щука» в пространстве магазина M.REASON{" "}
        <Ig a="m.reason.ru " />
        имидж-консультант Гульнара Аширова <Ig a="open.your.upstyle" /> провела
        практикум для студентов и выпускников ВШСДТ на тему «Гардероб деловой
        женщины. Как усилить влияние в профессиональной среде с помощью одежды».
      </>
    ),
    image: "/img/news/151121.jpg"
  },
  {
    id: "091221",
    date: new Date(2021, 11, 9),
    title:
      "Поздравляем нашу коллегу, fashion стилиста Любовь Троепольскую с наградой",
    grayText: (
      <>
        31 й Московский международный фестиваль <Ig a="redapplefest" />,
        специальный приз Best in Instagram Red Apple. Tag in Fest в подкатегории
        Tag In Briefs: Rich <Ig a="rich_russia" /> в номинации Special award for
        best Instagram. Идея и концепт: Любовь Троепольская. Фото: Ольга
        Мельникова. Makeup: Амина Кусова.
      </>
    ),
    image: "/img/news/091221.jpg"
  },
  {
    id: "221221",
    date: new Date(2021, 11, 22),
    title:
      "Школа Инессы Трубецковой. Скидки от 20% до 50% на самые популярные курсы",
    grayText: (
      <>
        Мир архетипов
        <br />
        Карта стилей: от древности до современности
        <br />
        Код внешности
        <br />
        Маленькое черное платье
      </>
    ),
    image: "/img/news/221221.jpg"
  },
  {
    id: "220122",
    date: new Date(2022, 1, 22),
    title: "Имидж-практика в пространстве магазина MEZZATORRE",
    grayText: (
      <>
        Недавно в VEGAS Кунцево в пространстве магазина MEZZATORRE{" "}
        <Ig a="mezzatorre" /> имидж-консультант Гульнара Аширова{" "}
        <Ig a="open.your.upstyle" /> провела очередной практикум для студентов и
        выпускников ВШСДТ на тему «Гардероб деловой женщины. Как усилить влияние
        в профессиональной среде с помощью одежды».
      </>
    ),
    image: "/img/news/220122.jpg"
  },
  {
    id: "150322",
    date: new Date(2022, 2, 15),
    title: "Практика для стилистов в VASSA&CO",
    grayText: (
      <>
        12 марта прошла практика для Стилистов нашей школы в известном
        российском бренде VASSA&CO. Для занятия нам предоставили флагманский
        магазин в центре города на Арбате с очень красивым концептуальным
        пространством. Практикум проходил в рамках программы «Гардероб деловой
        женщины». Практикум провела стилист-имиджмейкер Гульнара Аширова{" "}
        <Ig a="open.your.upstyle" />
      </>
    ),
    image: "/img/news/150322.jpg"
  },
  {
    id: "090322",
    date: new Date(2022, 2, 9),
    title: "Весна в ТЦ Семеновском!",
    grayText: (
      <>
        6 марта в ТЦ Семеновском, в универмаге Kansai, прошел день Моды и Стиля,
        посвященный 8 марта. ВШСДТ выступила партнером на мероприятии. Участие
        приняли преподаватели и выпускницы нашей школы.
      </>
    ),
    image: "/img/news/090322.jpg"
  }
  /*
  ,
  {
    id: "0002",
    date: new Date(),
    title: "",
    grayText: <></>,
    image: "/img/news/"
  },
  */
];
