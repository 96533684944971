import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>Курсы имиджмейкеров, стилистов, дизайнеров в Каннах</title>
        <meta
          name="descript"
          content="Курсы имиджмейкеров, стилистов, дизайнеров в Каннах проводят специалисты Высшей школы стилистики, дизайна и технологий как в очном формате, так и в дистанционном формате. Здесь Вы получите необходимые знания и навыки для реализации своих возможностей в бизнесе, социальной сфере, для воплощения своих идей, развития карьеры и реализации проектов."
        />
        <meta
          name="keywords"
          content="стилист, имидж, имиджмейкер, дизайнер, шопер, канны, франция, курсы, обучение, школа, учиться"
        />
      </Helmet>
      <PageGlobal lable="Канны" filial="Канны">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
