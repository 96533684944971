import React from "react";
import { Grid } from "@material-ui/core";
import { dates, DateToString, TimeToString } from "../../shedule/dates";
import Card2 from "./changing-world-of-fashion";
import Card3 from "./crowd-reaction";
import Card5 from "./japanese-fashion-philosophy";
import Card6 from "./non-verbal-communications";
import Card7 from "./personal-brand-in-net";
import Card8 from "./personal-brand";
import Card9 from "./photographing";
import Card10 from "./pitfalls-of-repair";
import Card11 from "./poor-fashion";
import Card12 from "./pr-attacks";
import Card13 from "./presentation";
import Card15 from "./profashion-interior-designer";
import Card16 from "./psychology-color";
import Card17 from "./selection";
import Card18 from "./smm";
import Card19 from "./spectacular-look";
import Card21 from "./stylemap";
import Card22 from "./style-vector";
import Card23 from "./trands-in-accessuaries";
import Card24 from "./trands-in-makeup";
import Card25 from "./trands-trandsetter";
import Card26 from "./personal-brand-in-net";
import Card27 from "./self-stylist";
import Card28 from "./stylist-work";
import Card29 from "./psychology-color";
import Card30 from "./pr-attacks";
import Card31 from "./personal-brand";
import Card32 from "./self-stylist-2";
import Card33 from "./self-stylist-3";
import Card34 from "./drien-van-noten";
import Card35 from "./alexander-mc-queen";
import Card36 from "./schiapareli";
import Card37 from "./intervew-shabalina";
import Card38 from "./intervew-prohorovich";
import Card39 from "./trands-trandsetter";
import Card40 from "./crowd-reaction";
import Card41 from "./self-stylist-4";
import Card42 from "./hermes";
import FutureOfFashion from "./poor-fashion-cicle";
import Chanel from "./chanel";
import HowToAdjust from "./how-to-adjuste";
import Vintage from "./vintage";
import DenimStyle from "./denim-style-secret";
import InteriorOfHeppines from "./interior-of-heppines";
import LightingScenario from "./lighting-scenario";

function CardsList(props) {
  return (
    <div>
      <Grid container justify="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} md={3}>
          <Card2 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card3 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card5 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card6 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card7 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card8 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card9 index={0} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card10 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card11 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card12 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card13 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card15 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card16 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card17 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card18 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card19 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card21 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card22 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card23 index={0} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card24 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card25 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card26 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card27 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card28 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card29 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card30 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card31 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card32 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card33 index={0} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card34 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card35 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card36 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card37 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card38 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card39 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card40 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card41 />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card42 />
        </Grid>
        <Grid item xs={12} md={3}>
          <InteriorOfHeppines />
        </Grid>
        <Grid item xs={12} md={3}>
          <LightingScenario />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="1"
            date={DateToString(dates.masterClasses.futureOfFashion.part1, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part1)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Chanel />
        </Grid>
        <Grid item xs={12} md={3}>
          <HowToAdjust />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="2"
            date={DateToString(dates.masterClasses.futureOfFashion.part2, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part2)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="3"
            date={DateToString(dates.masterClasses.futureOfFashion.part3, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part3)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="4"
            date={DateToString(dates.masterClasses.futureOfFashion.part4, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part4)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="5"
            date={DateToString(dates.masterClasses.futureOfFashion.part5, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part5)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FutureOfFashion
            part="6"
            date={DateToString(dates.masterClasses.futureOfFashion.part6, true)}
            setBillingId={props.setBillingId}
            time={TimeToString(dates.masterClasses.futureOfFashion.part6)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DenimStyle />
        </Grid>
        <Grid item xs={12} md={3}>
          <Vintage />
        </Grid>
      </Grid>
    </div>
  );
}

export default CardsList;
