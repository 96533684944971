import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
// import { dates, Month } from "../shedule/dates";
import Paragraph from "../../components/p-array";
import Slider from "./slider";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    title: "Стили в одежде. Принципы подбора",
    head: (
      <>
        Стили в одежде.
        <br />
        Принципы подбора
      </>
    ),
    headAfterDescript:
      "Система создания стильных и модных комплектов в одежде и аксессуарах. Технологии для расширения спектра услуг и сервиса.",
    descript: [
      "Очно, онлайн, индивидуально",
      "Удостоверение о повышении квалификации",
      "4 месяца, 80 занятий, 160 академических часов",
      "Рассрочка от 9 тыс.рублей в месяц",
      "Налоговый вычет после обучения",
      "Закрытая группа, живое общение"
    ],
    text: "",
    imageComponent: (
      <Slider
        images={[
          "/img/base-image-style/slide-01.jpg",
          "/img/base-image-style/slide-02.jpg",
          "/img/base-image-style/slide-03.jpg",
          "/img/base-image-style/slide-04.jpg",
          "/img/base-image-style/slide-05.jpg"
        ]}
      />
    ),
    video: "8s_7e-79VUI",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    }
  },
  dates: {
    online: false,
    /*
    {
      day: dates.baseImageStyle.online.getDate(),
      month: Month(dates.baseImageStyle.online.getMonth()),
      type: "дистанционно"
    },
    */
    ofline: false
    /*{
      day: dates.baseImageStyle.ofline.getDate(),
      month: Month(dates.baseImageStyle.ofline.getMonth()),
      type: "очно"
    }*/
  },
  screen2: {
    dates: false,
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>
          Для кого курс
        </span>
      )
    },
    text: (
      <List
        arr={[
          "Для Вас, когда Вам нужны изменения",
          <>
            Для Вас, когда Вы совер&shy;шенствуете сервис и систему работы с
            клиентами
          </>,
          <>
            Для Вас, когда Вы готовы к само&shy;реализации, новым успешным
            кейсам, новому хобби и новым возможностям
          </>
        ]}
      />
    ),
    video: "8s_7e-79VUI"
  },
  text1: (
    <>
      <Paragraph
        content={[
          <strong>Ценность знаний и</strong>,
          "… курса в целом — в команде его преподавателей, в их опыте и умении поделиться знаниями с Вами. Последовательно, внимательно, помогая Вам изучить основы и нюансы. Помогая выстроить систему и подбирать одежду и образ в целом себе, окружающим, клиентам. "
        ]}
      />
    </>
  ),
  tags1: (
    <span>
      <a href="#teachers">
        <span style={{ color: "rgb(180,180,180)" }}>#преподаватели&ensp;</span>
      </a>{" "}
      <a href="#price">
        <span style={{ color: "rgb(180,180,180)" }}>#стоимость&ensp;</span>
      </a>
    </span>
  ),
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель обучения
          </h2>,
          <Paragraph
            style={styles.p}
            content={[
              "Следовать современному стилю жизни",
              "Изучать модные тренды и управлять впечатлением",
              "Раскрывать новые сферы коммуникаций через стиль",
              <>&emsp;</>,
              "Это обучение заменит консультации стилистов",
              "Поможет максимально разобраться в основах имиджелогии",
              "Раскроет известные и новые знания как эффективную последовательность действий"
            ]}
          />
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            Колористика и цветовой анализ внешности как профессиональный
            инструмент
          </>,
          <>
            Типология фигур и силуэтов, возможности и способы визуальной
            коррекции с помощью одежды
          </>,
          <>
            Изучение основных групп современных стилей как системы для
            транслирования необходимого впечатления в имидже
          </>,
          <>
            Материаловедение и терминология для имидж-консультантов и стилистов
          </>,
          <>
            Изучение аксессуаров, головных уборов, обуви, правил и способов их
            подбора с учетом fashiontrends
          </>,
          <>
            Профессиональный глоссарий, изучение профессиональной лексики и
            терминологии
          </>,
          <>
            Изучение типов современного гардероба, создание гардеробных капсул
          </>,
          <>Источники вдохновения — история костюма с Русланом Миграновым</>,
          <>
            Профайлинг, психологические особенности разработки имиджа и стиля
          </>,
          <>Трендспоттинг — изучение модных тенденций</>
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Практика подбора образа (стиля) от А до Я",
          "Практика подбора аксессуаров",
          "Практика подбора прически и макияжа",
          "Практика эффективного шопинга"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат обучения
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={["Смотрите работы студентов и выпускников"]}
        num="none"
      />
    ]
  },
  teachers: [
    {
      image: "/img/teachers/320x240/tatiyana-fomina.jpg",
      name: "Татьяна Фомина",
      text:
        "Имидж-стилист, дизайнер. Куратор курса и старший преподаватель дисциплин имиджа и стилистики в Высшей школе стилистики дизайна и технологий",
      instagram: false,
      buttons: {
        detail: "/tatiana_fomina",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/320x240/marina-bonetskaya.jpg",
      name: "Марина Бонецкая",
      text:
        "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
      instagram: false,
      buttons: {
        detail: "/marina_bonetskaya",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/320x240/svetlana-kononets.jpg",
      // imageFilter: true,
      name: "Светлана Шабалина",
      text:
        "Практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения, преподаватель Высшей школы стилистики.",
      instagram: false,
      buttons: {
        detail: "/svetlana_kononets",
        linkInside: true,
        video: "a1T_ln5fDlE"
      }
    },
    {
      image: "/img/teachers/320x240/liana-bahova.jpg",
      name: "Лиана Бахова",
      text:
        "Имидж-консультант, профайлер, автор программ по управлению впечатлением в бизнесе и межличностных коммуникациях",
      instagram: false,
      buttons: {
        detail: "/liana_bahova",
        linkInside: true
      }
    }
  ],
  pay: {
    afterPrice: "Возможно индивидуальное обучение",
    countPay: 2,
    pay: {
      ofline: {
        short: (
          <>
            <h3 style={styles.h3}>Очное обучение, стоимость</h3>
            <List
              num="none"
              arr={[
                <>Полная стоимость — 90 тыс.рублей</>,
                <>Предоставляется рассрочка на время обучения</>,
                <>Возможна банковская рассрочка на 10 месяцев</>,
                <>Возможны скидки до 35%</>
              ]}
            />
          </>
        ),
        textButton: "Очное обучение",
        text: (
          <>
            <h3 style={styles.h3}>
              Стоимость и характеристики очного обучения
            </h3>
            <p style={style.text}>
              <strong>Характеристики:</strong>
            </p>
            <Paragraph
              style={style.text}
              content={[
                <>
                  Документ об окончании: Сертификат или удостоверение о
                  повышении квалификации
                </>,
                <>Длительность: 4 месяца, 40 занятий по 4 академ. часа</>,
                <>Группы: дневные, вечерние, выходного дня</>,
                <>
                  Формат обучения: лекционные и практические занятия в
                  аудиториях, в местах практик, + самостоятельная работа
                </>,
                <>Лекционные занятия доступны в записи в личном кабинете</>,
                <>Экзамены: тестирование (для повышения квалификации)</>
              ]}
            />
            <p style={style.text}>
              <strong>Стоимость:</strong>
            </p>
            <List
              style={style.text}
              num="decimal"
              arr={[
                <>
                  Вариант оплаты: рассрочка на время обучения
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        1й платеж — 30 <sup>000</sup> руб.
                      </>,
                      <>
                        2й, 3й, 4й платежи — по 20 <sup>000</sup> руб.
                      </>,
                      <>
                        полная стоимость — 90 <sup>000</sup> руб.
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      <>
                        9<sup>000</sup> рублей ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 90
                        <sup>000</sup> рублей
                      </>,
                      <Forma
                        sum="90000.00"
                        name="Стили в одежде. (Очное обучение)"
                        test={false}
                        yellow
                      />,
                      "возможно оформление кредита на более длительный срок на условиях банка партнера"
                    ]}
                  />
                </>,
                <>
                  Скидки:
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      <>
                        Оформление договора на условиях раннего бронирования =
                        скидка&nbsp;5%
                      </>,
                      <>Оформление договора 1+1 = скидка&nbsp;20%</>,
                      <>
                        Обучение «для себя» = скидка 10&nbsp;
                        <sup>000</sup>
                        &nbsp;руб.
                      </>
                    ]}
                  />
                </>,
                <>
                  <strong>Важно:</strong>
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      "по запросу мы предоставляем документы для налогового вычета",
                      "можно оплатить обучение материнским капиталом"
                    ]}
                  />
                </>
              ]}
            />
          </>
        )
      },
      online: {
        short: (
          <>
            <h3 style={styles.h3}>Онлайн обучение, стоимость</h3>
            <List
              num="none"
              arr={[
                <>Полная стоимость — 75 тыс.рублей</>,
                <>Предоставляется рассрочка на время обучения</>,
                <>Возможна банковская рассрочка на 10 месяцев</>,
                <>Возможны скидки до 35%</>
              ]}
            />
          </>
        ),
        textButton: "Онлайн обучение",
        text: (
          <>
            <h3 style={styles.h3}>
              Стоимость и характеристики онлайн обучения
            </h3>
            <p style={style.text}>
              <strong>Характеристики:</strong>
            </p>
            <Paragraph
              style={style.text}
              content={[
                <>
                  Документ об окончании: Сертификат или удостоверение о
                  повышении квалификации
                </>,
                <>Длительность: 4 месяца, 80 занятий по 2 академ. часа</>,
                <>Группы: утренние, вечерние, выходного дня</>,
                <>
                  Формат обучения: более 50% занятий — живое общение с
                  преподавателями и группой, + записи лекций, + самостоятельная
                  работа
                </>,
                <>Все занятия доступны в записи в личном кабинете</>,
                <>Экзамены: тестирование (для повышения квалификации)</>
              ]}
            />
            <p style={style.text}>
              <strong>Стоимость:</strong>
            </p>
            <List
              style={style.text}
              num="decimal"
              arr={[
                <>
                  Вариант оплаты: рассрочка на время обучения
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        1й платеж — 30 <sup>000</sup> руб.
                      </>,
                      <>
                        2й, 3й, 4й платежи — по 15 <sup>000</sup> руб.
                      </>,
                      <>
                        полная стоимость — 75 <sup>000</sup> руб.
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      <>
                        7<sup>500</sup> рублей ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 75&nbsp;
                        <sup>000</sup>
                        &nbsp;руб.
                      </>,
                      <Forma
                        sum="90000.00"
                        name="Стили в одежде. (Онлайн обучение)"
                        test={false}
                        yellow
                      />,
                      "возможно оформление кредита на более длительный срок на условиях банка партнера"
                    ]}
                  />
                </>,
                <>
                  Скидки:
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      <>
                        Оформление договора на условиях раннего бронирования =
                        скидка&nbsp;5%
                      </>,
                      <>Оформление договора 1+1 = скидка&nbsp;20%</>,
                      <>
                        Обучение «для себя» = скидка 10&nbsp;
                        <sup>000</sup>
                        &nbsp;руб.
                      </>
                    ]}
                  />
                </>,
                <>
                  <strong>Важно:</strong>
                  <br />
                  <List
                    style={style.text}
                    num="none"
                    arr={[
                      "по запросу мы предоставляем документы для налогового вычета",
                      "можно оплатить обучение материнским капиталом"
                    ]}
                  />
                </>
              ]}
            />
          </>
        )
      }
    }
  },
  notFirstForm: true
};
