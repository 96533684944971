import React from "react";
import Helmet from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Teacher from "../../components/teacher";
import Page from "../../components/page";
import List from "../../components/list";

export default function Teach() {
  var content = [
    {
      title: "Образование",
      text: (
        <List
          arr={[
            "Ужгородский государственный университет",
            "Аспирантура Института социологических исследований Академии наук СССР"
          ]}
        />
      )
    },
    {
      title: "Преподавательский опыт и научная деятельность",
      text: (
        <List
          arr={[
            "Институт молодежи, курс «Социологические исследования»",
            "Королевский технологический университет, курсы «Основы паблик рилейшн», «Коммуникационный менеджмент», «Деловое общение», «Формирование имиджа органов государственной власти»",
            "Заведующий кафедрой партийной школы «Единой России» в Московской области",
            "Автор более 130 статей, монографий и методических пособий в области социологии, общественного мнения, политической рекламы и имиджа."
          ]}
        />
      )
    },
    {
      title: "Опыт работы в политических кампаниях",
      text:
        "В течение 20 лет участвовал в различных политических кампаниях по выборам президентов России, Украины, Монголии, Абхазии, депутатов Государственной Думы, мэров городов и глав районов."
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Валерий Чередниченко</title>
        <meta
          name="keywords"
          content="Валерий Чередниченко, кандидат экономических наук, социолог, бизнес-тренер, политтехнолог, школа"
        />
        <meta
          name="descript"
          content="Валерий Чередниченко — кандидат экономических наук, социолог, бизнес-тренер, политтехнолог, директор Института стратегических исследований и проектов. Преподаватель ВШСДТ курса «Формирование имиджа лидера. Инструменты политтехнологий»"
        />
      </Helmet>
      <Page lable="Валерий Чередниченко" lableMobile="В. Чередниченко">
        <Grid container justify="center" alignItems="flex-start">
          <Grid item style={{ padding: 20 }}>
            <Teacher
              head="Валерий Чередниченко"
              short="Кандидат экономических наук, социолог, бизнес-тренер, политтехнолог Директор Института стратегических исследований и проектов"
              image="/img/about/valeriy-cherednichenko.jpg"
              tiles={[
                {
                  image: "/img/book-open-variant.svg",
                  title: "Более 130 статей",
                  text:
                    "В области социологии, общественного мнения, политической рекламы и имиджа"
                },
                {
                  image: "/img/star.svg",
                  title: "В течении 20 лет",
                  text:
                    "Участвовал в различных политических кампаниях по выборам президентов России, Украины, Монголии, Абхазии, депутатов Государственной Думы, мэров городов и глав районов."
                }
              ]}
              content={content}
            />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
