import React from "react";
import Helmet from "react-helmet";
import { contents } from "./contents";
import Page from "../../components/page";
import Layout from "../../patterns/pattern-fs/layout";

const Fashion = () => {
  return (
    <div>
      <Helmet>
        <title>Курс по истории моды с Русланом Миграновым</title>
        <meta
          name="keywords"
          content="Руслан Мигранов, история, мода, обучение, идеи, образы, иконы стиля, школа, где, учиться, курс, лекции"
        />
        <meta
          name="descript"
          content="Мода XX века: путешествие от модерна до конца тысячелетия. Как и почему меняется мода, мода как взгляд на эпоху, основные характеристики костюма каждого десятилетия, создатели моды, их идеи и образы, иконы стиля и «уличная» мода, развитие моды как развитие идей искусства и технологий XX века, суть творческого метода французских, итальянских, британских и американских дизайнеров-кутюрье — все это и еще многое другое в авторском цикле лекций Руслана Мигранова"
        />
      </Helmet>
      <Page lable="История моды" lableMobile="История моды">
        <Layout content={contents} />
      </Page>
    </div>
  );
};

export default Fashion;
