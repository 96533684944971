import React from "react";
import "./style.css";

export function Person(props) {
  const { content } = props;
  return (
    <div className="person-container" key={props.key}>
      <div className="person-text">{content.text}</div>
      {content.photo && (
        <div className="person-photo">
          <img src={content.photo} alt={content.photoAlt} />
        </div>
      )}
    </div>
  );
}

export function All(props) {
  const { content } = props;
  return (
    <a href={content[1]}>
      <div className="person-all-link">{content[0]}</div>
    </a>
  );
}

export function Persons(props) {
  const { content } = props;
  return (
    <>
      <h2 className="person-h2">{props.head}</h2>
      {content.map((el, key) => (
        <Person content={el} key={`person-${key}`} />
      ))}
      {props.link && <All content={props.link} />}
    </>
  );
}
