import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents-new";
import { OneNews } from "../root-test2/news";
import Page from "../../components/page";
import { Tiles } from "../../components/tiles/tiles";
import Slider from "../../components/slider-rk-bkg";
import {
  HeadBlock,
  ShessBlock,
  SocnetBlock,
  Teachers,
  Text3Block,
  BuilderLayout,
  TitleH2,
  HowIsTheTrainingGoing
} from "../../patterns/pattern-fs/components";

const SelfStyle = props => {
  return (
    <div>
      <Helmet>
        <title>Сам себе дизайнер</title>
        <meta
          name="keywords"
          content="сам, себе, дизайнер, интерьер, стилистика, обучение, где, школа, курсы"
        />
        <meta
          name="descript"
          content="Курс «Сам себе дизайнер» поможет Вам воплотить идеи и мечты, которые Вы задумали. Этот курс сочетает в себе основы дизайна интерьера и стили дизайна, кроме этого Вы научитесь работать с цветами и их сочетанием в интерьере. Вы будете смотреть по-новому на интерьер Вашей квартиры или дома, избежите финансовых потерь и разочарований от сотрудничества с подрядчиками. Научитесь анализировать собственный интерьер и давать оценку собственным потребностям."
        />
      </Helmet>
      <Page lable="Стилист-декоратор интерьера">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <OneNews content={c.news} />,
            <ShessBlock
              content={{
                left: c.text1.image,
                right: c.text1.text
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
            />,
            <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />,
            <>
              <TitleH2 content="Программа курса" scroll="programma" />
              <Tiles content={c.tilesProgramma} />
            </>,
            <Teachers content={{ teachers: c.teachers, teacherHead: false }} />,
            // c.insertAfterDates,
            <>
              <TitleH2 content="Примеры стилбордов наших студентов" />
              <div style={{ margin: "0 auto" }}>
                <Slider images={c.slides} infinity full white />
              </div>
            </>,
            <SocnetBlock />
          ]}
        />
      </Page>
    </div>
  );
};

export default SelfStyle;
