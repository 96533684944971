import React from "react";

import Expand from "../../components/expanded";
import H2 from "../../components/head1";
import { Grid } from "@material-ui/core";
import List from "../../components/list";

export default function Programma() {
  var styles = {
    summary: {
      background: "rgba(220,220,220,1)",
      fontSize: "1em",
      fontWeight: 700,
      color: "rgb(100,100,100)",
      padding: "0 15px"
    },
    detail: {
      background: "rgba(240,240,240,1)",
      fontSize: "1em",
      fontWeight: 400,
      color: "rgb(100,100,100)",
      padding: 8,
      margin: 0
    }
  };

  return (
    <div>
      <Grid container justify="center" style={{ padding: 20 }}>
        <Grid item xs={12} md={12}>
          <H2 content="Программа курса" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Expand
            summary={{
              text: "1. Материаловедение",
              style: styles.summary
            }}
            details={{
              text: (
                <div>
                  <p>
                    <b>6</b> видео уроков + <b>2</b> вебинара с разбором
                    домашних заданий и изучением трендов, всего <b>16</b>{" "}
                    академ. часов
                  </p>
                  <List
                    arr={[
                      "Задачи и специфика материаловедения для стилистики. Краткая история материалов, применяемых в костюме. Виды классификации материалов: по составу сырья, способу производства, назначению. Потребительские свойства материалов (прочность, толщина, эластичность, драпируемость, сминаемость, формоустойчивость, износостойкость, тепловые, акустические, оптические, электрические, тактильные свойства).",
                      "Текстильное материаловедение. Состав тканей. Ткани из растительного сырья: хлопок, лен, бамбук. Краткая история ткачества. Традиционные и современные названия тканей. Свойства и назначение тканей. Ведущие мировые производители и тренды. Как выбирать изделие из растительного сырья.",
                      "Ткани из сырья животного происхождения: шелк и шерсть. Строение волокон шерсти и шелка, обуславливающее их свойства и уход за ними. Назначение тканей, необходимость в производстве смесовых материалов. История и технологии современного производства. Бренды. Как выбирать шерстяные и шелковые ткани.",
                      "Трикотаж и нетканые материалы. Состав полотна и его свойства. Виды и назначение трикотажа. Трикотаж в бельевом и спортивном ассортименте. Другие виды полотен машинного и ручного производства (фетр, войлок, кружево и др.)",
                      "Кожа, ее строение, виды и назначение. Кожевенное сырье в одежде, обуви и кожгалантерее. Натуральный мех и его потребительские свойства. Классификация и назначение. Страны-производители.",
                      "Современные материалы. Смешанные, искусственные, синтетические, пленочные, экоматериалы. Новые тенденции. Экологизация потребления как мировая тенденция. Искусственная кожа и искусственный мех."
                    ]}
                    style={styles.detail}
                    num="decimal"
                  />
                </div>
              ),
              style: styles.detail
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Expand
            summary={{
              text: "2. Ассортимент элементов костюма",
              style: styles.summary
            }}
            details={{
              text: (
                <div>
                  <p>
                    <b>6</b> видео уроков + <b>2</b> вебинара с разбором
                    домашних заданий и изучением трендов, всего <b>16</b> академ
                    часов
                  </p>
                  <List
                    arr={[
                      "Костюм как система. Элементы костюма. Ансамбль. Комплект. Лук. Виды классификации элементов костюма: по назначению, по возрасту и полу, по сезону, по стилю, по зоне на фигуре. Виды верхней одежды: традиционно мужские и традиционно женские. Современный стиль «унисекс». Виды пальто, курток, плащей. Наименование деталей и особенностей кроя. История возникновения. Национальные традиции. Стилевые нюансы.",
                      "Классическая одежда как наиболее стабильный пласт предметной культуры. Мужская классика. Виды классической мужской плечевой одежды. Названия элементов. Брюки. Костюмы. Женская классика: английская и французская. Жакеты и юбки. Ассортимент и терминология.",
                      "Легкая одежда. Мужская плечевая одежда – виды рубашек, трикотажных изделий. Наименование деталей, стилевые особенности. Поясная одежда для спорта и отдыха, бельевая группа. Женская легкая одежда – блузы, платья, юбки. Наименование деталей и элементов кроя. Нижнее белье. Купальные костюмы.",
                      "Обувь. Сезонные особенности, ассортиментные группы. Зимняя и демисезонная обувь. Обувь для отдыха и спорта. Нарядная и повседневная обувь. Название частей и конструкций обуви. Современные тенденции. Ведущие страны-производители и бренды.",
                      "Кожгалантерейные изделия. Виды сумок. Наименование деталей, стили. Ремни, пояса и их производные. Виды перчаток. Современные тренды и терминология.",
                      "Головные уборы. Классические мужские головные уборы. История и наименование. Женские головные уборы из фетра и соломки. Трикотажные головные уборы. Сезонные головные уборы из кожи и меха. Платки. Головные украшения. Национальные традиции и современные тренды."
                    ]}
                    style={styles.detail}
                    num="decimal"
                  />
                </div>
              ),
              style: styles.detail
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
