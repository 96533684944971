import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Head from "./header";
import Interes from "./interes";
import Programma from "./programma";
import Author from "./about";
import Line from "../../components/line";
import { HorizontalForm } from "../../components/forms";
import Page from "../../components/page";

export default function Component() {
  return (
    <div>
      <Helmet>
        <title>Курс имидж и этикет: светское и деловое позиционирование</title>
        <meta
          name="title"
          content="Курс имидж и этикет: светское и деловое позиционирование"
        />
        <meta
          name="keywords"
          content="имидж, этикет, позиционирование, личный бренд, школа, обучение, учиться, где, профессия"
        />
        <meta
          name="description"
          content="Это обучение позволит вам разобраться в тонкостях светского и делового позиционирования. Курс подойдет для тех кто создает личный бренд и прокачивает карьеру, кто занимает представительские должности и хочет соответствовать мировому стандарту бизнес сообщества. А также этот курс будет актуальным для тех, кто работает над собственным имиджем или клиентским."
        />
      </Helmet>
      <Page lable="Этикет" lableMobile="Этикет">
        <Head />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <Interes />
            <Programma />
            <Author />
            {/* <Dates /> */}
            <Line />
            <HorizontalForm />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
