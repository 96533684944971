import React from "react";
import Expand from "../../components/expanded";
import List from "../../components/list";

let styles = {
    list: {
      font: "400 1em",
      color: "rgb(100,100,100)"
    },
    summary: {
      background: "rgb(240,240,240)",
      font: "700 1em",
      color: "rgb(100,100,100)"
    },
    details: {
      background: "rgb(240,240,240)",
      font: "400 1em",
      color: "rgb(100,100,100)"
    }
  },
  content = [
    {
      summary: "Теория моды: экономические законы и законы искусства",
      details: (
        <List
          arr={[
            "Социокультурные тенденции развития современной моды.",
            "Мода как форма визуальной коммуникации. Специфика модного образа.",
            "Мода как индустрия: основы функционирования, структура, процесс, инновации.",
            "Мода как форма современного искусства. Продажа образов.",
            "Основные участники модного рынка. Продвижение модного продукта."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Производство и продажи в индустрии моды",
      details: (
        <List
          arr={[
            "Международные группы компаний: структура и стратегическое развитие.",
            "Торговля (оптовая и розничная): принципы работы ритейла.",
            "Система функционирования магазина как предприятия.",
            "Основные формы производства модного продукта: принципы работы, отличительные признаки.",
            "Жизненный цикл модного продукта: от создания до реализации.",
            "Тренды и тенденции: источники, анализ и внедрение. Прогнозирование."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Основы маркетинга и стратегическое управление",
      details: (
        <List
          arr={[
            "Бизнес-планирование.",
            "Стратегическое планирование.",
            "Организация магазина в зависимости от выбранной стратегии. Факторы, определяющие выбор стратегии.",
            "Формирование концепции магазина: выбор целевой аудитории, расчет мотивов посещения магазина и стереотипа восприятия бренда.",
            "Бюджетирование и ценообразование.",
            "Привлечение инвестиций.",
            "Управление продажами."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Брендинг, PR, продвижение",
      details: (
        <List
          arr={[
            "Бренды и торговые марки. Бренды класса люкс и высокой моды. Дизайнерские бренды.",
            "Стратегии сегментирования fashion-рынка. Емкость рынка.",
            "Имидж бренда – основа современных коммуникаций.",
            "Анализ позиционирования и подходы к продвижению.",
            "Fashon-PR: способы, каналы и технологии.",
            "Рекламные материалы как визуализация концепции бренда."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Управление закупками и сбытом",
      details: (
        <List
          arr={[
            "Fashion-ассортимент. Структура ассортимента – основа ассортиментной матрицы.",
            "Управление fashion ассортиментом: методика планирования товарного запаса и ассортиментной матрицы на сезон.",
            "Алгоритм построения эффективной закупки: процессы и методики.",
            "Баинг и работа с поставщиками. Техники ведения переговоров.",
            "Минимизация остатков. Стимулирование продаж.",
            "Работа с непроданными остатками."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Fashion-мерчендайзинг",
      details: (
        <List
          arr={[
            "Визуальный мерчендайзинг как инструмент продаж: стратегии и инструменты.",
            "Современные тенденции в дизайне.",
            "Планирование торгового пространства, зонирование в соответствии с ассортиментом. Освещение.",
            "Современное торговое оборудование и выкладка товара.",
            "Трекинг покупательского потока. Горячие и холодные зоны.",
            "Витрины как визуальная коммуникация. Влияние на продажи."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Продажи товара и поведение потребителей",
      details: (
        <List
          arr={[
            "Определение модели поведения потребителей. Разновидности.",
            "Внешние факторы, влияющие на формирование потребностей.",
            "Факторы личностного характера. Мотивация и потребности.",
            "Модель коммуникационного воздействия на потребителя.",
            "Работа и коммуникации с покупателем: правила и принципы."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Управление персоналом",
      details: (
        <List
          arr={[
            "Поиск персонала и эффективные методы отбора.",
            "Модели управления и их выбор в соответствии со стратегией магазина.",
            "Системы оплаты труда. Прогнозирование.",
            "Обучение и мотивация персонала.",
            "Стандарты сервиса.",
            "Технологии эффективных продаж. KPI."
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Современные технологии продвижения для ритейла",
      details: (
        <List
          arr={[
            "Социальные сети и возможности продвижения модного продукта.",
            "E-commerce как поддержка традиционной розницы и дополнительный канал продаж.",
            "Построение системы франчайзинга для российских fashion компаний."
          ]}
          style={styles.list}
        />
      )
    }
  ];

export default function Programma() {
  return (
    <div>
      <h2
        style={{
          font: "500 1.8em 'Lora'",
          textAlign: "center",
          color: "rgb(80,80,80)",
          margin: 0,
          padding: 24
        }}
      >
        Содержание программы обучения
      </h2>
      {content.map((el, i) => (
        <Expand
          summary={{
            text: i + 1 + ". " + el.summary,
            style: styles.summary
          }}
          details={{
            text: el.details,
            style: styles.details
          }}
          expand={i === 0 ? true : false}
          key={i}
        />
      ))}
    </div>
  );
}
