export const content = [
  {
    cover: "/img/about/l-shepel.jpg",
    title: "Шепель В.М. Профессия мыслитель. Креативное пособие по аналитике.",
    link: "/blog/professiya-myslitel",
    descript:
      "Аналитика востребована в бизнесе, менеджменте, медицине, педагогике …"
  },
  {
    cover: "/img/blog/book-andreeva.jpg",
    title: "Андреева А.Н. Дизайнерские бренды в фэшн-бизнесе.",
    link: "/blog/dizaynerskie-brendy-v-fashion-biznese",
    descript:
      "Автор дает свое видение основ концепции портфеля дизайнерских брендов в …"
  },
  {
    cover: "/img/blog/book-palomo-lovinski.jpg",
    title: "Ноэль Паломо-Ловински. Мода и модельеры.",
    link: "/blog/moda-i-modeliery",
    descript:
      "В книге представлены по принципу хронологии 50 самых знаковых модельеров за всю …"
  },
  {
    cover: "/img/blog/book-ermilova.jpg",
    title: "Ермилова Д.Ю. История домов моды.",
    link: "/blog/istoriya-domov-mody",
    descript:
      "Книга посвящена истории моды конца XIX—начала XX в. как многообразия стилей Домов высокой моды …"
  },
  {
    cover: "/img/blog/book-gandl.jpg",
    title: "Стивен Гандл. Гламур.",
    link: "/blog/glamur6 ",
    descript:
      "Книга английского исследователя массовой культуры и кино является больше обзорной и в этом есть ее преимущество, поскольку читатель …"
  },
  {
    cover: "/img/blog/book-ribeyro.jpg",
    title: "Эйлин Рибейро. Мода и мораль.",
    link: "/blog/moda-i-moral",
    descript:
      "Книга интересна своим достаточно откровенным взглядом на мир моды, одежды и тела …"
  }
];

export const articles = [
  {
    cover: "/img/articles/8558.jpg",
    descript: "Понедельник, 29 Январь 2018, 19:43",
    title: "Кутюрье мечты",
    link: "/blog/kuturye-mechty"
  },
  {
    cover: "/img/articles/132.jpg",
    descript: "Вторник, 30 Май 2017, 19:20",
    title: "Платье, которое идет всем.",
    link: "/blog/platye-kotoroe-idet-vsem"
  },
  {
    cover: "/img/articles/130.jpg",
    descript: "Вторник, 30 Май 2017, 17:09",
    title: "Два сапога – ПАРА, или пресловутый family look.",
    link: "/blog/dva-sapoga-para-ili-preslovutyj-family-look"
  },
  {
    cover: "/img/articles/126.jpg",
    descript: "Понедельник, 13 Февраль 2017, 11:25",
    title: "Детская мода: вчера и сегодня",
    link: "/blog/detskaya-moda-vchera-i-segodnya"
  },
  {
    cover: "/img/articles/125.jpg",
    descript: "Четверг, 22 Декабрь 2016, 12:30",
    title: "Ирония судьбы – или еще одна статья о новогодних нарядах",
    link: "/blog/ironiya-sudby-ili-esche-odna-statiya-o-novogodnih-naryadah"
  },
  {
    cover: "/img/articles/123.jpg",
    descript: "Вторник, 15 Ноябрь 2016, 13:21",
    title: "Мода в мешке",
    link: "/blog/moda-v-meshke"
  },
  {
    cover: "/img/articles/122.jpg",
    descript: "Вторник, 25 Октябрь 2016, 11:16",
    title: "Ты мне это… брошь!",
    link: "/blog/ty-mne-eto-broshj"
  },
  {
    cover: "/img/articles/121.jpg",
    descript: "Вторник, 4 Октябрь 2016, 10:32",
    title: "Смерть ей к лицу",
    link: "/blog/smertj-ey-k-litsu"
  },
  {
    cover: "/img/articles/120.jpg",
    descript: "Понедельник, 26 Сентябрь 2016, 12:00",
    title: "Мода на время",
    link: "/blog/moda-na-vremya"
  },
  {
    cover: "/img/articles/119.jpg",
    descript: "Понедельник, 19 Сентябрь 2016, 10:55",
    title: "Зверски красива!",
    link: "/blog/zverski-krasiva"
  },
  {
    cover: "/img/articles/118.jpg",
    descript: "Вторник, 6 Сентябрь 2016, 15:15",
    title: "Канотье: соломенная шляпка с богатой историей",
    link: "/blog/kanotje-solomennaya-shlyapka-s-bogatoj-istoriej"
  },
  {
    cover: "/img/articles/115.jpg",
    descript: "Четверг, 1 Сентябрь 2016, 14:19",
    title: "Кушать подано… на подиум",
    link: "/blog/kushatj-podano-na-podium"
  },
  {
    cover: "/img/articles/111.jpg",
    descript: "Четверг, 14 Июль 2016, 13:29",
    title: "Индивидуальный стиль. Творчество или необходимость?",
    link: "/blog/individualnyj-stilj-tvorchestvo-ili-neobhodimostj"
  },
  {
    cover: "/img/articles/39.jpg",
    descript: "Воскресенье, 4 Октябрь 2015, 15:58",
    title: "Анализ коллекции Maison Margiela",
    link: "/blog/analiz-kollektsii-maison-margiela"
  }
];
