import React from "react";
import Page from "../../components/page";
import Grid from "@material-ui/core/Grid";
import Teacher from "../../components/teacher";
import List from "../../components/list";
import Helmet from "react-helmet";

export default function Teach() {
  var content = [
    {
      title: "Образование",
      text: (
        <List
          arr={[
            <>
              <strong>Диплом</strong> — Художественно-промышленная Академия
              барона Штиглица Санкт-Петербург
            </>,
            <>
              <strong>Стажировки</strong> — архитектурная ассоциация «MA2»
              Metzger&AssociesArchitecture, Бельгия
            </>
          ]}
        />
      )
    },
    {
      title: "Проекты",
      text: <List arr={["Проект реновации гостиницы «Astoria» Брюссель"]} />
    },
    {
      title: "Профессиональная деятельность",
      text: (
        <List
          arr={[
            "Исследовательская работа архитектурного наследия Виктора Орта",
            "Руководитель Francis Metzger архитектор,профессор свободного университета Брюсселя, вице президент национального совета архитектуры и градостроительства Бельгии"
          ]}
        />
      )
    },
    {
      title: "Экспертная деятельность",
      text: (
        <List
          arr={[
            "Архитектурная ассоциация «MA2» Metzger&AssociesArchitecture — арткоучинг, дизайнер",
            "Showroom «Maison du monde» Бельгия — декоратор",
            "Интерьерный салон «Flamant» Брюссель — декоратор"
          ]}
        />
      )
    },
    {
      title: "Преподавательская деятельность",
      text: (
        <List
          arr={[
            "2000-2010 — создатель и владелец первой частной школы дизайна в России при Союзе Дизайнеров России",
            "2000-2009 — заведующая кафедры дизайна МГУКИ",
            "2014-2019 — сотрудник кафедры дизайна свободного университета Брюсселя",
            "2011-2020 — владелец и коуч он-Лайн мастерской арт-дизайна",
            "2016-2020 — сотрудничество с Высшей школой стилистики — курс проектной деятельности, композиции, эргономики, авторский курс «Консультант по стилю и дизайну интерьера»"
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Марина Костарнова</title>
        <meta
          name="keywords"
          content="Марина Костарнова, дизайнер, дизайн, курс, школа"
        />
        <meta
          name="descript"
          content="Марина Костарнова — автор инновационных проектов в области дизайн образования, концептуального дизайна и арт-коучинга. Преподаватель ВШСДТ профессиональной переподготовки курса «Дизайн и декорирование интерьера», а также преподаватель авторского курса «Интерьерный стилист»"
        />
      </Helmet>
      <Page lable="Марина Костарнова">
        <Grid container justify="center" alignItems="flex-start">
          <Grid item style={{ padding: 20 }}>
            <Teacher
              head="Марина Костарнова"
              short="Автор инновационных проектов в области дизайн образования, концептуального дизайна и арт-коучинга"
              image="/img/teachers/kostarnova-v.jpg"
              tiles={[
                {
                  image: "/img/star.svg",
                  title: "Более 20 лет",
                  text: "преподавательской деятельности"
                },
                {
                  image: "/img/star.svg",
                  title: "Более 10 лет",
                  text:
                    "руководитель кафедры дизайна Рязанского филиала Московского государственного института культуры"
                },
                {
                  image: "/img/star.svg",
                  title: "Более 20 лет",
                  text:
                    "профессиональной деятельности в области дизайна и декорирования среды."
                }
              ]}
              content={content}
            />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
