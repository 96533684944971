import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import { Brd as X } from "./brand";

/* const online1 = `${dates.idfw.online.getDate()} ${Month(
  dates.idfw.online.getMonth()
)}`; */
const online2 = `${dates.idfw.online2.getDate()} ${Month(
  dates.idfw.online2.getMonth()
)}`;

export const contents = {
  meta: {
    keywords: "продвижение, бренд, одежда, digital, fashion, week, IDFW",
    descript:
      "Продвижение бренда одежды на международный рынок: что нужно знать при подготовке коллекции для показов на международных цифровых площадках, как оформить документацию к размещению на ведущей платформе International Digital Fashion Week (IDFW)"
  },
  head: {
    title:
      "International Digital Fashion Week или как заявить о себе на весь мир",
    headDescript: "Специализация",
    head: (
      <>
        Продвижение бренда одежды на
        <br />
        International Digital Fashion Week
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      "Онлайн практикум",
      "Удостоверение о повышении квалификации",
      "1 месяц, 16 академических часов",
      "Подготовка к размещению на IDFW",
      "Консультации с наставником",
      "Рассрочка, налоговый вычет"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Практические алгоритмы: как презентовать бренд на International Digital
        Fashion Week, как консультировать бренды по выходу на международный
        рынок через модную сеть с 78 000 000 медиа-показов
      </div>
    ),
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/idfw/evgeniy-stulov-official-idfw.jpg",
    imageAlt: "Евгений Стулов – официальный представитель IDFW в России",
    styleImage: {
      maxHeight: 380
    }
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online2}</>]
    },
    {
      title: "Формат",
      text: [
        <>Blended learning – чат с коллегами,</>,
        <>видео лекции и он-лайн общение</>
      ]
    },
    {
      title: "Начинающим",
      text: [
        <>Доступ на IDFW открыт для</>,
        <>начинающих дизайнеров, узнайте как</>
      ]
    }
  ],
  screen2: {
    text: (
      <>
        <h3>Заявите о себе на весь мир</h3>
        <p>
          Ваш бренд будет на онлайн подиуме вместе с{" "}
          <X content="Louis Vuitton" />, <X content="Salvatore Ferragamo" />,{" "}
          <X content="Elie Saab" />, <X content="Etro" />,{" "}
          <X content="Moschino" />, <X content="Max Mara" /> и другими мировыми
          брендами. Уже сегодня можно создать свою уникальную историю и обратить
          внимание байеров со всего мира.
        </p>
        <p>
          Узнайте, как попасть на IDFW на специальных условиях. Ваши идеи
          увидят: эксперты, инвесторы, трендсеттеры, инфлюэнсеры, баейры,
          дизайнеры и самая широкая аудитория покупателей. Скачайте в{" "}
          <X content="App Store" /> или <X content="Google Play" /> приложение{" "}
          <X content="FNL Network" /> и смотрите в будущее.
        </p>
      </>
    ),
    video: "dgDEXUtGllo"
  },
  howIsTheTrainingGoing: [
    {
      title: "Видео кейсы",
      text: (
        <>
          Каждую неделю изу­ча­ете кей­сы и чек-листы о прак&shy;тиках и
          алго&shy;рит&shy;мах пре&shy;зен&shy;тации брен&shy;да на
          между&shy;на&shy;род&shy;ном рынке
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          На основе чек-листов про­&shy;ве­ря&shy;­ете го­тов&shy;­ность
          брен&shy;да и кол­&shy;лек&shy;­ции к раз­ме­&shy;ще­нию на
          International Digital Fashion Week
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое общение с груп­пой и пре­по­да­ва­те­лем, ответы
          на ак­ту­аль­ные воп­ро­сы и об&shy;суж&shy;де&shy;ние возможностей
        </>
      )
    },
    {
      title: "Стратегия + Заявка",
      text: (
        <>
          В итоге получаете ал&shy;го&shy;рит&shy;мы вывода бренда через
          международную мод&shy;ную сеть FNL Network и офор&shy;мля&shy;ете
          заявку на IDFW
        </>
      )
    }
  ],
  programma: {
    head: "Программа",
    content: [
      <p>
        <strong>Digital fashion / Рынок моды</strong>
      </p>,
      <List
        style={styles.p}
        // num="none"
        // olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Что нужно знать, чтобы коллекция «зашла»</>,
          <>Традиционный VS цифровой рынок моды сегодня</>,
          <>Ограничения и возможности мирового цифрового подиума</>,
          <>Digital-каналы и инструменты digital-маркетинга</>
        ]}
      />,
      <p>
        <strong>International communications / Коммуникации и продажи</strong>
      </p>,
      <List
        style={styles.p}
        arr={[
          <>
            Правила коммуникации с международными СМИ, партнерами,
            благотворительность
          </>,
          <>
            Онлайн и офлайн продажи: требования к коллекциям одежды на
            международном рынке
          </>,
          <>
            Практики оформления социальных сетей и сайта для презентации бренда
          </>,
          <>Подготовка PR стратегии продвижения бренда</>
        ]}
      />,
      <p>
        <strong>Prodaction / Производство контента</strong>
      </p>,
      <List
        style={styles.p}
        arr={[
          <>Контент как проект: концепт, команда, бюджетирование</>,
          <>Подготовка: сценарии / референсы, постановка, стилистика</>,
          <>Производство фотоконтента, лучшие практики, технические нюансы</>,
          <>Производство видео контента, лучшие практики, технические нюансы</>
        ]}
      />,
      <p>
        <strong>Promoution / Продвижение</strong>
      </p>,
      <List
        style={styles.p}
        arr={[
          <>Упаковка проекта: подготовка презентации коллекции</>,
          <>Международная практика защиты авторского права, что нужно знать</>,
          <>
            Выбор формата размещения на IDFW на основе готовности коллекции и
            ресурсов
          </>,
          <>Заполнение технической документации и подача заявки</>
        ]}
      />,
      <p>
        <strong>Цель курса</strong> — дать руководство по выходу на
        международный цифровой подиум, помочь дизайнерам провести анализ своего
        продукта/коллекции на основе определенного алгоритма, успешность
        которого подтверждена деятельностью IDFW и получить реально достижимый
        результат — создать свой бренд и сделать его узнаваемым, вывести на
        мировой рынок fashion-индустрии, занять перспективную нишу, расширить
        целевую аудиторию и даже получить предварительные заказы. Ключевые
        навыки, что даются в ходе изучения курса, помогут проверить свой бренд
        на соответствие современным требованиям к визуальной коммуникации: какой
        визуальный контент влияет на повышение продаж и узнаваемость бренда,
        какие digital-каналы и инструменты необходимы в новой цифровой эре
        fashion-рынка.
      </p>,
      <p>
        Курс поможет повысить квалификацию специалистов, занимающихся
        продвижением fashion-брендов. Вы освоите механизм крупной инновационной
        площадки по презентации и продаже коллекций, разберетесь, какая
        презентация бренда сегодня успешна, каковы критерии экспертной оценки и
        самой широкой аудитории потребителей.
      </p>
    ]
  },
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Учебная нагрузка: 4 занятия в неделю по 90 минут, 4 недели</>}
        text={
          <>
            <p>График занятий:</p>
            <List
              olStyle={{ paddingLeft: "1rem" }}
              arr={[
                <>
                  3 занятия в неделю по 90 минут — мастер-классы с домашними
                  практическими заданиями
                </>,
                <>
                  1 занятие в неделю — семинары с преподавателями, живое общение
                  online
                </>
              ]}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Слушатели, прошедшие обучение на курсе, получают удостоверение о
        повышении квалификации установленного государством образца по программе
        «Продвижение бренда одежды на международный рынок: практика презентации
        коллекции на International Digital Fashion Week», 16 академических часов
      </p>
    ),
    image: (
      <img
        src="/img/udostoverenie-o-povyshenii-kvalifikatsii.png"
        alt="Удостоверение о повышении квалификации ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  teachers: [
    {
      image: "/img/fashion-styling/stulov-st.jpg",
      name: "Евгений Стулов (Россия, Москва)",
      text: (
        <List
          arr={[
            <>
              Официальный представитель International Digital Fashion Week в
              России
            </>,
            <>Креативный директор Siberian Fashion Week</>,
            <>
              Фотограф, музыкальный продюсер, композитор, основатель и участник
              электронного проекта SOLARIS
            </>,
            <>Преподаватель курсов профессиональной переподготовки ВШСДТ</>
          ]}
        />
      ),
      instagram: "solaris_foto",
      buttons: {
        detail: false,
        video: "Fps72ns-MyI"
      }
    },
    {
      image: "/img/fashion-styling/maltseva-st.jpg",
      name: "Евгения Мальцева (Россия, Москва)",
      text: (
        <List
          arr={[
            <>Президент Siberian Fashion Week</>,
            <>CEO / Founder European Model Academy</>,
            <>Режиссер-постановщик модных показов</>,
            <>Профессиональный коуч по дисциплинам: дефиле, фотопозирование.</>,
            <>
              Автор мастер-классов для профессиональных моделей и директоров
              модельных агентств
            </>,
            <>Преподаватель курсов профессиональной переподготовки ВШСДТ</>
          ]}
        />
      ),
      instagram: "evmaltseva",
      buttons: false
    },
    {
      image: "/img/teachers/moatti.jpg",
      name: "Юлия Моатти (Франция, Канны)",
      text: (
        <List
          arr={[
            <>Fashion стилист (Лазурный берег)</>,
            <>16 лет опыта в международной fashion индустрии</>,
            <>проекты для Valentino, YSL, Fendi, Linda Farrow</>,
            <>
              рекламные компании и editorial съемки для Eric Zemmour, Chopard,
              Misaki, журналов L'Officiel, Riviera Magazine, Lucy mag, Huf mag и
              др.
            </>
          ]}
        />
      ),
      instagram: "yuliamoatti_stylist",
      buttons: false
    },

    {
      image: "/img/teachers/zolotariova.jpg",
      name: "Виктория Золотова (США, Калифорния)",
      text: (
        <>
          <List
            arr={[
              <>Специалист по маркетингу и продвижению брендов</>,
              <>Основатель проектов Fashionovation и Innovation Camp</>,
              <>Организатор международных образовательных туров (fashion, IT)</>
            ]}
          />
          <p style={{ textAlign: "center" }}>
            <img
              src="/img/green/earth.svg"
              style={{ height: 24, marginBottom: -6 }}
            />
            &emsp;
            <a href="https://fashionovation.us" target="_blank">
              fashionovation.us
            </a>
          </p>
        </>
      ),
      instagram: false,
      buttons: false
    }
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>1500 &#8381;</>,
    full: <>18 000 &#8381;</>, //  полная стоимость
    discount: false, // <>11 940 &#8381;</>, // со скидкой
    discountSize: <>20%</>,
    discountDuration: "для групп",
    qrLink: "https://link.tinkoff.ru/35eAY5fTDda",
    durationInstallment: "12"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
