import React from "react";
import Card from "../card";
import List from "../../../components/list";
import Paragraph from "../../../components/p-array";
import { dates, DateToString } from "../../shedule/dates";
// import { Link } from "react-router-dom";
import Links from "../links";

export default function CardModern(props) {
  return (
    <Card
      head="Мода и стиль"
      title={
        <span style={{ fontSize: "0.9em" }}>
          Кто меняет моду сегодня? Разбор профессий стилиста, имиджмейкера и
          шопера.
        </span>
      }
      teacher="Татьяна Фомина"
      descript="Лекция, очно, Москва"
      descriptString
      onlineText={
        <span>
          Лекция, очно, Москва
          <br />
          Место проведения: Техноград, ВДНХ
        </span>
      }
      oflineIcon={props.oflineIcon ? true : false}
      date={DateToString(dates.masterClasses.changingWorld, true)}
      time="14:00"
      duration=""
      image="/img/master-class/fomina.jpg"
      text={
        <Paragraph
          content={[
            <Links
              link="/profession_imagemaker_stylist_shopper"
              text="Перейти на страницу мероприятия"
            />,
            "Для тех, кто интересуется модой",
            "Для тех, кто любит шопинг и стиль",
            "Для тех, кто хочет стать частью модного мира",
            "Чувствовать себя современно и уверенно",
            "На встрече в инновационном пространстве Технограда:",
            <List
              arr={[
                "Мы поговорим о модных трендах: на примерах посмотрим, как выглядеть современно",
                "Мы поговорим, насколько изменился мир моды",
                "Какие нас ждут кардинальные перемены в отношении к моде в мире"
              ]}
            />,
            "И конечно, мы рассмотрим аспекты и перспективы профессии имиджмейкера, стилиста, шопера",
            "Какой запрос на консультантов в индустрии моды?",
            "Почему эта профессия для тех, кто хочет быть независимым специалистом?",
            "Эти и многие другие вопросы обсудим в процессе встречи",
            "Ответим на Ваши вопросы о том, как стать профессионалом в индустрии",
            "В подарок Вы сможете стать моделью и получить консультацию по стилю или шопинг со студентами курсов имиджмейкеров ВШСДТ",
            "Ведущие встречи:",
            <List
              arr={[
                "Татьяна фомина, дизайнер одежды, имидж-стилист, ведущий преподаватель и куратор программ профессиональной подготовки имиджмейкеров-стилистов #ВШСДТ",
                "Иван Гулиенко, руководитель Высшей школы стилистики, дизайна и технологий"
              ]}
            />
          ]}
        />
      }
      q
      part
      noView
      free
      partPrice="0"
      color="rose"
    />
  );
}
