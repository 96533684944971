import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import {
  BuilderLayout,
  HeadBlock,
  TextAndTags,
  SocnetBlock,
  Programma,
  DatesBlock,
  Teachers,
  ScrollingLink,
  Calendar,
  Price
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Стили в одежде, обучение правилам для женщин</title>
        <meta
          name="keywords"
          content="стиль, стильная, модная, одежда, консультация, стилист, курс, обучение, учиться, где"
        />
        <meta
          name="descript"
          content="Система создания стильных и модных комплектов в одежде и аксессуарах для женщин. Это обучение заменит консультации стилистов. Поможет максимально разобраться в имиджелогии и на 100% раскроет Вашу индивидуальность. Учитесь, и консультируйте окружающих, это старт в профессию."
        />
      </Helmet>
      <Page lable="Стили в одежде">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <>
              <ScrollingLink scrollLink="dates" />
              <Calendar content={c.dates} />
            </>,
            <TextAndTags content={{ text: c.text1, tags: c.tags1 }} />,
            <DatesBlock content={c.screen2} />,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            <>
              <ScrollingLink scrollLink="price" />
              <Price content={c.pay} />
            </>,
            <SocnetBlock />
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
