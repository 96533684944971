import React from "react";
import List from "../list";

export const bot = {
  image: (
    <img src="/img/imagemaker-stylist/chat-bot-ill.jpg" alt="Бот Валерия" />
  ),
  text: (
    <>
      <p className="p-constructor">
        <strong>Открытое образование ВШСДТ</strong>
      </p>
      <List
        arr={[
          "Бот Валерия подбирает контент в зависимости от предпочтений",
          "Регулярно открывает доступ к онлайн лекциям и курсам",
          "Без оплат и условий. Общается вежливо. Как начать?",
          "Подпишитесь, нажмите «старт» и выбирайте интересующие темы"
        ]}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        classLi="p-constructor"
      />
      {/* Ai_Style_Bot */}
      <a href="https://t.me/imagemakerpro_bot">
        <div
          style={{
            background: "rgb(40,168,233)",
            padding: ".5rem 1rem",
            color: "rgb(255,255,255)",
            textAlign: "center",
            margin: "2rem auto 0 auto",
            maxWidth: "max-content"
          }}
        >
          <img
            src="/img/telegram-bw.svg"
            style={{ height: "1rem", width: "1rem" }}
          />
          &ensp;
          <span>Подписаться</span>
        </div>
      </a>
    </>
  )
};
