import React from "react";
import { Grid, Hidden, Paper } from "@material-ui/core";
import TwoBlocks from "../../components/two-blocks-with-bkg";
import TwoBlocks2 from "./two-blocks";
import { withStyles } from "@material-ui/styles";
import { stylesDark } from "../../components/styles";
import Paragraph from "../../components/p-array";
import Formats from "./formats";
import Papers from "./table";
import Block from "./block";
import { HorizontalForm, VerticalForm } from "../../components/forms";
import Socnet from "../../components/want-to-know";
import Pay from "./pay";
import Programma from "./programma";
import Button from "../../components/button-messages";
import ButtonLink from "./button-black";
import ButtonVideo from "./button-video";
import DigitText from "./digit-and-text";
import Video from "../../components/video";
import Space from "./space";
import ScrollableAnchor from "react-scrollable-anchor";
import Tags from "./tags";
import TextBlock from "./text-block";
// import Slides from "./slides";
import SliderVideo from "./slider-for-video";
import SliderInHeader from "./slider-in-header";
import ListInfo from "./list";
import Teacher from "./teacher-element";
import GridDates from "./grid-dates";
import GridProg from "./grid-programma";
import SpolerNew from "./spoler-new";
import Examples from "./examples";
import Together from "./together";
import Boxes from "./boxes";
import Sequence from "../../components/sequence/sequence";
import { ShessBuilder } from "../../components/infographica/infographica";
import "./style.css";

const fontTitle = "PT Sans Narrow";
const font = "Roboto"; // "Alegreya Sans";
const fontCursive = {
  fontFamily: "Lora",
  fontStyle: "italic"
};
// const sizeButton = "5rem";
const gold = "rgb(122,101,89)";
const styles = {
  ...stylesDark,
  h1: {
    fontFamily: fontTitle,
    fontSize: "4rem",
    fontWeight: 400,
    color: gold,
    textShadow: "4px 4px 0px rgba(0,0,0,0.15)",
    padding: 0,
    margin: "1rem 0",
    lineHeight: "4rem"
  },
  small: {
    fontFamily: font,
    fontSize: "0.8rem",
    color: "rgb(100,100,100)",
    fontWeight: 400,
    padding: 0,
    margin: 0
  },
  h2: {
    fontSize: "1.6em",
    fontWeight: 700,
    color: gold,
    textAlign: "center",
    margin: 0,
    padding: "3.2rem 1rem 1.4rem"
  },
  p: {
    fontFamily: font,
    fontSize: "1rem",
    textAlign: "justify",
    color: "rgb(100,100,100)",
    lineHeight: "1.4rem",
    margin: 0,
    padding: "1rem 0"
  },
  imageHead: {
    margin: 0,
    padding: "3rem 1rem"
  },
  imageHeadPicture: {
    maxWidth: "100%",
    verticalAlign: "middle"
  },
  box: {
    maxWidth: "100vw",
    padding: 0,
    margin: 0,
    boxSizing: "border-box"
  },
  programma: {
    borderTop: "1px solid rgb(240,240,240)"
  },
  imageDate: {
    margin: "0.25rem",
    maxWidth: 500
  },
  "@media (max-width: 959px)": {
    imageHead: {
      padding: "1rem 1rem"
    },
    imageHeadPicture: {
      width: 280
    },
    box: {
      padding: "0 1rem"
    },
    h2: {
      paddingLeft: "1rem",
      paddingRight: "1rem"
    },
    programma: {
      borderTop: "none"
    },
    imageDate: {
      margin: 0,
      width: 280
    }
  }
};
const wStyles = theme => styles;

function DatesBlock(props) {
  const { classes } = props;

  return (
    <>
      {props.content.dhead && (
        <h2
          style={{
            ...styles.h2,
            textAlign: "left",
            paddingTop: 0,
            fontFamily: fontTitle
          }}
        >
          {props.contents.head}
        </h2>
      )}
      <GridDates
        date={
          props.content.dates[0] ? (
            <React.Fragment>
              <DigitText
                digit={props.content.dates[0].d || <>&ensp;</>}
                text={
                  props.content.dates[0].d ? (
                    props.content.dates[0].t
                  ) : (
                    <>&ensp;</>
                  )
                }
                comment={props.content.dates[0].c}
              />
              {props.content.dates[1] ? (
                <DigitText
                  digit={props.content.dates[1].d}
                  text={props.content.dates[1].t}
                  comment={props.content.dates[1].c}
                />
              ) : null}
            </React.Fragment>
          ) : (
            false
          )
        }
        video={
          props.content.video ? (
            <div style={{ margin: "2rem 0" }}>
              <Video
                link={props.content.video}
                h={300}
                onlyVideo={props.content.videoOnly}
                wVideo={props.content.videoWidth}
              />
              {props.content.videoComment && (
                <ButtonVideo
                  linkVideo={props.content.video}
                  slider={false}
                  text="смотрите видео >>"
                  oneText={true}
                  style={{ cursor: "pointer", textAlign: "right" }}
                />
              )}
            </div>
          ) : props.content.media ? (
            <>{props.content.media}</>
          ) : (
            <div
              style={{
                padding: "0.5rem"
              }}
            >
              <img
                src={props.content.image.source}
                alt={props.content.image.comment}
                className={classes.imageDate}
                style={props.content.image.style}
              />
              {props.content.image.comment && (
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "0.8rem",
                    color: "rgb(80,80,80)",
                    textAlign: "right"
                  }}
                >
                  {props.content.image.comment}
                </p>
              )}
            </div>
          )
        }
        text={
          <div>
            <p
              className={classes.p}
              style={{
                ...fontCursive,
                paddingBottom: 0
              }}
            >
              {props.content.quote.text}
            </p>
            <p
              className={classes.p}
              style={{
                textAlign: "right",
                fontFamily: font,
                paddingTop: 0
              }}
            >
              {props.content.quote.author}
            </p>
            <p
              className={classes.p}
              style={{
                textAlign: "justify",
                paddingTop: "1rem",
                fontFamily: font
              }}
            >
              {props.content.text}
            </p>
          </div>
        }
        image={props.content.image}
      />
    </>
  );
}

function Layout(props) {
  const { classes } = props;
  const contents = props.content;

  return (
    <div>
      <Space inside />
      <TwoBlocks
        textOnLeft
        left={
          <div>
            <h1 className={classes.small}>{contents.head.headDescript}</h1>
            <h1
              className={classes.h1}
              style={{ fontFamily: fontTitle, ...contents.head.styleHead }}
            >
              {contents.head.head}
            </h1>
            {contents.head.headAfterDescript ? (
              <h1 className={classes.small}>
                {contents.head.headAfterDescript}
              </h1>
            ) : null}
            <div style={{ height: contents.head.spaceHeadText || "2rem" }} />
            <Paragraph
              style={{
                ...styles.p,
                padding: "0.2rem 0",
                color: "rgb(100,100,100)"
              }}
              content={contents.head.descript}
            />
            <div style={{ height: "2rem" }} />
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={12} md={4}>
                <Button
                  linkType="component"
                  componentLink={
                    <div className="button-white-in-black">записаться</div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ButtonLink
                  link="#programma"
                  text="программа"
                  style={{ fontFamily: font, ...contents.head.styleButton }}
                />
              </Grid>
              {contents.head.noVideo ? null : (
                <Grid item xs={12} md={4}>
                  <ButtonVideo
                    linkVideo={
                      contents.head.video ? contents.head.video : false
                    }
                    slider={
                      contents.head.video ? (
                        false
                      ) : (
                        <SliderVideo videos={contents.videos} />
                      )
                    }
                    text="видео"
                    // style={{ width: sizeButton }}
                    sizeAdaptive={/* contents.head.sizeVideo */ true}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
        right={
          contents.head.slider ? (
            <div className={classes.imageHead}>
              <SliderInHeader slides={contents.head.slides} />
            </div>
          ) : contents.head.imageComponent ? (
            contents.head.imageComponent
          ) : (
            <figure
              className={classes.imageHead}
              style={{ ...contents.head.styleImageBox }}
            >
              <picture>
                {contents.head.imageSourceWeb && (
                  <source
                    type="image/webp"
                    srcset={contents.head.imageSourceWeb}
                  />
                )}
                <source type="image/jpeg" srcset={contents.head.imageSource} />
                <img
                  src={contents.head.imageSource}
                  alt=""
                  style={{
                    ...contents.head.styleImage
                  }}
                  className={classes.imageHeadPicture}
                />
              </picture>
              <figcaption
                className={classes.small}
                style={{
                  textAlign: "right"
                }}
              >
                {contents.head.imageComment}
              </figcaption>
            </figure>
          )
        }
      />
      <Together>
        <TextBlock content={<p className={classes.p}>{contents.text1}</p>} />
        <Space inside />
        <Tags text={contents.tags1} />
      </Together>
      <ScrollableAnchor id="continue">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      {contents.screen2 && (
        <Boxes outer>
          <DatesBlock content={contents.screen2} classes={classes} />
        </Boxes>
      )}
      {contents.screen2_2 && (
        <Boxes outer>
          <DatesBlock content={contents.screen2_2} classes={classes} />
        </Boxes>
      )}
      {contents.insertAfterDates && (
        <Boxes outer>{contents.insertAfterDates}</Boxes>
      )}
      <Space />
      {contents.infos1 ? (
        <>
          <Boxes one>
            {contents.infos1.head && (
              <h2
                style={{
                  ...styles.h2,
                  textAlign: "left",
                  paddingTop: 0,
                  fontFamily: fontTitle
                }}
              >
                {contents.infos1.head}
              </h2>
            )}
            <ShessBuilder
              picture={contents.infos1.pictures}
              datas={contents.infos1.texts}
              noShess={contents.infos1.noShess}
            />
          </Boxes>
        </>
      ) : null}
      <ScrollableAnchor id="info">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      {contents.infos2 ? (
        <div style={{ marginBottom: "4rem" }}>
          {contents.infos2.head && (
            <h2
              style={{
                ...styles.h2,
                textAlign: "center",
                paddingTop: 0,
                fontFamily: fontTitle
              }}
            >
              {contents.infos2.head}
            </h2>
          )}
          <Boxes one>
            <TwoBlocks2
              left={
                <ShessBuilder
                  picture={contents.infos2.picture}
                  datas={contents.infos2.texts}
                  noShess={contents.infos2.noShess}
                  noShessLarge={true}
                  pictureWord={contents.infos2.pictureWord}
                />
              }
              right={contents.infos3}
            />
          </Boxes>
        </div>
      ) : null}
      <ScrollableAnchor id="programma">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      {contents.programmaOutComponent || (
        <GridProg
          programma={
            <Boxes one>
              <Programma
                fontHead={fontTitle}
                styleHead={{
                  paddingTop: "1.5rem"
                }}
                head={contents.programma.head ? contents.programma.head : false}
                content={contents.programma.content || contents.programma}
              />
            </Boxes>
          }
          variants={
            contents.variants ? (
              <Papers
                head={contents.variants.head}
                content={contents.variants.content}
                size={12}
                styleHead={{
                  textAlign: "right",
                  paddingRight: "1rem",
                  paddingTop: "1.5rem"
                }}
                styleTitle={{
                  textAlign: "right"
                }}
                fontHead={fontTitle}
                styleBox={{
                  position: "sticky",
                  top: 0
                }}
              />
            ) : (
              false
            )
          }
          descript={""}
          // descript={<Tags text={contents.aboutEducation} />}
        />
      )}
      {contents.howIsTheTrainingGoing && (
        <Boxes outer>
          <h2
            style={{
              ...styles.h2,
              textAlign: "center",
              paddingTop: 0,
              fontFamily: fontTitle
            }}
          >
            Как проходит обучение
          </h2>
          <Sequence content={contents.howIsTheTrainingGoing} />
        </Boxes>
      )}
      {contents.afterEducation ? (
        <Boxes outer>
          <ScrollableAnchor id="career">
            <div style={{ height: 1 }} />
          </ScrollableAnchor>
          <h2
            style={{
              ...styles.h2,
              textAlign: "center",
              paddingTop: 0,
              fontFamily: fontTitle
            }}
          >
            {contents.afterEducation.head}
          </h2>
          <div className={classes.box}>
            <Grid container alignItems="stretch" justify="center" spacing={2}>
              {contents.afterEducation.videos.map((el, k) => (
                <Grid item xs={12} md={4} key={k}>
                  <Video
                    link={el}
                    w={300}
                    h={300}
                    style={{
                      margin: "0 auto",
                      width: "max-content"
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12} md={10}>
                <p className={classes.p} style={{ textAlign: "center" }}>
                  Подписывайтесь на Youtube канал ВШСДТ ТВ:
                  <br />
                  интервью с преподавателями, экспертами, выпускниками, открытые
                  лекции, обзоры трендов
                </p>
                <p className={classes.p} style={{ textAlign: "center" }}>
                  <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
                    Перейти на Youtube канал &gt;&gt;&gt;
                  </a>
                </p>
              </Grid>
            </Grid>
          </div>
        </Boxes>
      ) : null}
      {contents.teachers && (
        <ScrollableAnchor id="teachers">
          <div style={{ height: 1 }}> </div>
        </ScrollableAnchor>
      )}
      {/* <Together> */}
      {contents.teachers && (
        <>
          <h2 className={classes.h2} style={{ fontFamily: fontTitle }}>
            {contents.teachersHead
              ? contents.teachersHead
              : contents.teachers.length > 1
                ? "Преподаватели"
                : "Преподаватель"}
          </h2>
          {contents.teachers.map((el, key) => (
            <div key={key}>
              <TwoBlocks
                left={key % 2 ? Teacher(el).text : Teacher(el).image}
                right={key % 2 ? Teacher(el).image : Teacher(el).text}
                textOnLeft={key % 2 ? true : false}
              />
              <Space inside />
            </div>
          ))}
        </>
      )}
      {contents.graduatesWork && (
        <Boxes outer>
          <h2
            className={classes.h2}
            style={{
              fontFamily: fontTitle
            }}
          >
            Работы выпускников
          </h2>
          <Grid container justify="space-between" alignItems="flex-end">
            {contents.graduatesWork.map((el, key) => (
              <Grid item xs={12} md={6} key={`grWork_${key}`}>
                <Video
                  link={el.link}
                  hVideo={el.hVideo}
                  onlyVideo={true}
                  wVideo={el.wVideo}
                />
              </Grid>
            ))}
          </Grid>
        </Boxes>
      )}
      {contents.formsBeforePay &&
        contents.formats && (
          <>
            <ScrollableAnchor id="formats">
              <div style={{ height: 1 }}> </div>
            </ScrollableAnchor>
            <Boxes outer>
              <Formats
                head={contents.formats.head}
                content={contents.formats.content}
                columns={contents.formats.columns}
              />
            </Boxes>
          </>
        )}
      {/* <Papers head={false} content={contents.teachers2} size={4} /> */}
      <ScrollableAnchor id="price">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      <Space after />
      {contents.payTile ? (
        <>
          <Grid container spacing={3} alignContent="stretch">
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  padding: "1.2rem",
                  color: "rgb(100,100,100)",
                  height: "100%"
                }}
              >
                <h3 style={{ ...styles.h3, textAlign: "center" }}>
                  {contents.pay.online.textButton}
                </h3>
                {contents.pay.online.text}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  padding: "1.2rem",
                  color: "rgb(100,100,100)",
                  height: "100%"
                }}
              >
                <h3 style={{ ...styles.h3, textAlign: "center" }}>
                  {contents.pay.free.textButton}
                </h3>
                {contents.pay.free.text}
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        contents.pay && (
          <Pay
            content={contents.pay}
            pay={contents.countPay}
            title={contents.payTitle}
            open={contents.payOpen}
            afterPrice={contents.afterPrice}
          />
        )
      )}
      {contents.noLinkFormats
        ? null
        : contents.formats && (
            <a href="#formats">
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "1rem",
                  color: "rgb(110,110,110)",
                  paddingTop: "1rem"
                }}
              >
                <sup>*</sup>
                Подробнее о форматах обучения &gt;&gt;&gt;
              </p>
            </a>
          )}
      {contents.notFirstForm ? (
        <h2
          className={classes.h2}
          style={{
            fontFamily: fontTitle,
            marginTop: "4rem",
            marginBottom: "-2rem"
          }}
        >
          Получите квалифицированную консультацию специалиста учебного отдела
        </h2>
      ) : (
        <Boxes outer>
          <h2
            className={classes.h2}
            style={{
              fontFamily: fontTitle
            }}
          >
            Получите квалифицированную консультацию специалиста учебного отдела
          </h2>
          {contents.noForms ? (
            <Socnet
              target="fashion_styling_complete"
              noHead
              open={contents.phoneOpen}
            />
          ) : (
            <>
              <Hidden mdUp>
                <VerticalForm target="send_form_fashion_styling_complete" />
              </Hidden>
              <Hidden smDown>
                <HorizontalForm target="send_form_fashion_styling_complete" />
              </Hidden>
            </>
          )}
        </Boxes>
      )}
      {contents.text2 && (
        <Boxes outer>
          <TextBlock
            content={
              <div>
                <h2
                  style={{
                    fontFamily: fontTitle
                  }}
                  className={classes.h2}
                >
                  {contents.text2.title}
                </h2>
                <div className={classes.p}>{contents.text2.text}</div>
              </div>
            }
          />
        </Boxes>
      )}
      {/* </Together> */}
      {contents.aboutProfeshion && (
        <>
          <ScrollableAnchor id="profession">
            <div style={{ height: 1 }} />
          </ScrollableAnchor>
          <Together>
            <TextBlock
              content={
                <div>
                  <h2
                    style={{
                      ...styles.h2,
                      textAlign: "left",
                      paddingTop: 0,
                      paddingLeft: 0,
                      fontFamily: fontTitle
                    }}
                  >
                    {contents.aboutProfeshion.title}
                  </h2>
                  <SpolerNew
                    short={contents.aboutProfeshion.short}
                    detail={
                      contents.aboutProfeshion.detail
                        ? contents.aboutProfeshion.detail.map((el, k) => (
                            <div className={classes.p} key={k}>
                              {el}
                            </div>
                          ))
                        : false
                    }
                  />
                </div>
              }
            />
          </Together>
        </>
      )}
      {contents.principes && (
        <Boxes middle>
          <TextBlock
            content={
              <div>
                <h2
                  className={classes.h2}
                  style={{
                    fontFamily: fontTitle,
                    paddingLeft: 0,
                    paddingTop: 0,
                    textAlign: "left"
                  }}
                >
                  Принципы обучения
                </h2>
                <ListInfo arr={contents.principes} />
              </div>
            }
          />
        </Boxes>
      )}
      {contents.formsBeforePay
        ? null
        : contents.formats && (
            <>
              <ScrollableAnchor id="formats">
                <div style={{ height: 1 }}> </div>
              </ScrollableAnchor>
              <Boxes outer>
                <Formats
                  head={contents.formats.head}
                  content={contents.formats.content}
                  columns={contents.formats.columns}
                />
              </Boxes>
            </>
          )}
      {contents.info && (
        <Boxes outer>
          <h2 className={classes.h2} style={{ fontFamily: fontTitle }}>
            {contents.info.head}
          </h2>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Paper style={{ padding: "1rem" }}>
                <Grid container>
                  <Grid item xs={2} md={1}>
                    {contents.info.iconDocs}
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <a href="/educationdoc" target="_blank">
                      <span style={{ color: "rgb(100,100,100)" }}>
                        Порядок заключения договора и зачисления на обучение
                      </span>
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{ padding: "1rem" }}>
                <Grid container>
                  <Grid item xs={2} md={1}>
                    {contents.info.iconMonitor}
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <a href="/educationonline" target="_blank">
                      <span style={{ color: "rgb(100,100,100)" }}>
                        Особенности реализации программ дистанционного обучения
                      </span>
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Boxes>
      )}
      {(contents.review || contents.examples) && (
        <Together>
          <ScrollableAnchor id="review">
            <div style={{ height: 1 }}> </div>
          </ScrollableAnchor>
          {contents.review ? (
            <Boxes middle>
              <Block title={contents.review.head} text={contents.review.text} />
            </Boxes>
          ) : null}
          {props.notExample || !contents.examples ? null : (
            <Boxes middle>
              <Examples content={contents.examples} />
            </Boxes>
          )}
          {contents.userBlock && <Boxes middle>{contents.userBlock}</Boxes>}
        </Together>
      )}
      <Boxes
        outer={contents.review || contents.examples}
        middle={!(contents.review || contents.examples)}
      >
        <Socnet
          target="fashion_styling_complete"
          noHead
          open={contents.phoneOpen}
        />
      </Boxes>
      <div style={styles.space} />
    </div>
  );
}

export default withStyles(wStyles)(Layout);
