import React from "react";
import Link from "react-router-dom/Link";
import "./style.css";

export function ShessBlocks(props) {
  const c = props.content;

  return (
    <div className="shess-blocks-container">
      {c.map((el, key) => (
        <div className="item-shess">
          <h3>{el.title}</h3>
          <p>{el.duration}</p>
          <p>
            {el.descript}
            &emsp;
            <Link to={el.link}>подробнее…</Link>
          </p>
          {el.descript2 && <p>{el.descript2}</p>}
        </div>
      ))}
    </div>
  );
}
