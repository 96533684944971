import React from "react";
import Video from "../../components/video";
import { Link } from "react-router-dom";
import "./style.css";

export function TileNotSpoler(props) {
  const side = props.sideImage;
  const el = props.short;

  return (
    <>
      <div className="box" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <div className={side === "left" ? "grid1" : "grid2"}>
          <div className="grid-image">
            {el.video ? (
              <div className="box-image">
                <Video link={el.video} h={240} w={320} />
              </div>
            ) : (
              <div className="box-image">
                <img src={el.image} alt="" className="image" />
              </div>
            )}
          </div>
          <div className="grid-text">
            {el.text}
            <Link to={el.link}>
              <div className="button">подробнее</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
