import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Loadable from "react-loadable";
import Link from "react-router-dom/Link";
import { news } from "./content";

function createLable(id) {
  const l = news.length;
  let d, m, y;
  for (let i = 0; i < l; i++) {
    if (news[i].id == id) {
      d = news[i].date.getDate();
      m = news[i].date.getMonth() + 1;
      y = news[i].date.getFullYear();
      if (d < 10) {
        d = "0" + d;
      }
      if (m < 10) {
        m = "0" + m;
      }
    }
  }
  return d + "." + m + "." + y;
}

export default function OneNews(props) {
  const id = props.match.params.n;
  const LoadComponent = Loadable({
    loader: () => import(`./contents/${id}.js`),
    loading: () => <div>Загрузка…</div>
  });
  return (
    <div>
      <Helmet>
        <title />
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable={createLable(id)}>
        <LoadComponent />
        <Link to="/news">Вернуться в раздел «Новости»</Link>
        <div style={{ height: "4rem" }} />
      </Page>
    </div>
  );
}
