import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { MiniForm } from "../../components/forms";
import "./style.css";
import { Grid } from "@material-ui/core";
import Banners from "./banners";
import {
  BuilderLayout,
  HeadBlock,
  Programma,
  TitleH2,
  ShessBlock,
  Text3Block
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Байер Fashion</title>
        <meta name="keywords" content="байер, мода, курсы, обучение" />
        <meta
          name="descript"
          content="Программа профессиональной переподготовки по профессии Байер с максимальным погружением в индустрию моды, ее внутренних механизмов продвижения и продаж модного продукта, управления закупками и рисками, прогнозирования трендов и спроса"
        />
      </Helmet>
      <Page lable="Байер Fashion">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content={c.academy.title} />
              <ShessBlock
                content={{
                  left: c.academy.image,
                  right: c.academy.text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} style={{ paddingTop: 0 }} />
              <MiniForm formName="miniform-second-degree-imagemaker" />
            </>,
            <Programma content={{ programma: c.programma, variants: false }} />,
            <ShessBlock
              content={{
                right: c.shess[0].media,
                left: c.shess[0].text
              }}
              mediaLeft={false}
              vAlign="center"
              allWhite={true}
            />,
            <>
              <TitleH2 content="Где доступно обучение" />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <div className="box-news">{c.text1}</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  {c.text1Media}
                </Grid>
              </Grid>
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
            </>,
            <>
              <TitleH2 content="О нас" />
              <ShessBlock
                content={{
                  right: c.text2,
                  left: (
                    <img
                      src="/img/imagemaker-stylist/about.webp"
                      style={{ maxWidth: 320, height: "auto" }}
                    />
                  )
                }}
                allWhite={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
