import React from "react";
import List from "../../components/list";
import Pattern, { styles } from "../../components/pattern-page-of-course";
import { style } from "../information-about-educational-organization/style";
import Button from "../../components/button-reserved";

const Typology = props => {
  return (
    <Pattern
      title="Типология архитектурных форм в интерьере"
      faq="Курсы"
      short="Курс | 2 месяца | 16 занятий | 16 академ. часов | ONLINE"
      teacher={{
        text: (
          <div>
            <p>
              Ведущая курса &ndash; Галина Варакина. Эксперт Российского
              научного и Российского гуманитарного научного фондов. Член
              редакционной коллегии журнала «Культура и цивилизация».
            </p>
            <p>
              Изучение курса предоставит Вам базовые знания о конструктивной
              природе интерьера, многообразии форм и архитектурных деталей,
              применимых в интерьере, в том числе о форматности оконного и
              дверного проемов, вариативности поверхностей пола, потолка и стен,
              выразительных возможностях архитектурного декора и стационарного
              оборудования (камин, перегородки, встроенная мебель, освещение).
            </p>
            <p>
              Цель: понять конструктивную природу интерьера и возможности его
              декоративного осмысления.
            </p>
          </div>
        ),
        img: "/img/about/galina-varakina.jpg",
        name: "Галина Варакина"
      }}
      leftTile={
        <div>
          <h3 style={styles.h3}>Online</h3>
          <p style={{ ...styles.p, background: "rgb(245,245,245)" }}>
            с 24 октября 2019
          </p>
          <p style={styles.p}>Внесите аванс 2000 рублей</p>
          <p style={styles.p}>Оформите документы</p>
          <p style={styles.p}>Забронируйте место в группе</p>
          <Button link="https://billing.styleschool.ru/event/KjrNxei6WE3omKwx7" />
        </div>
      }
      rightTile={
        <div>
          <h3 style={styles.h3}>Стоимость</h3>
          <p style={styles.p}>
            Для записи на курс внесите аванс и оформите договор.
          </p>
          <p style={styles.p}>
            В случае отказа от обучения аванс возвращается.
          </p>
        </div>
      }
      programm={
        <div>
          <h3 style={styles.h3}>Теория (12 академических часов)</h3>
          <List
            arr={[
              "Вариативность внутреннего пространства с точки зрения функциональности, структуры и организации. Организация внутренней среды здания. Оснащение внутреннего пространства.",
              "Типология архитектурных деталей в конструкции интерьера: пол и стены; крыша и потолок; оконные и дверные проемы; лестницы.",
              "Типология архитектурных деталей в декоре интерьера: ордер в интерьере; перегородки и встроенная мебель; камин; мебель и аксессуары.",
              "Роль освещения в осмыслении внутреннего пространства: характеристика источников света; типы освещения и осветительные приборы; схемы светового дизайна в интерьере."
            ]}
            style={styles.list}
          />
          <h3 style={styles.h3}>Практика (4 академических часа)</h3>
          <List
            arr={[
              "Практика составления линейки однотипных элементов интерьера – пол, потолок, стена, оконный, дверной проемы, лестница, камин, перегородка, освещение.",
              "Практика составления линейки различных элементов интерьера, объединенных одним стилем."
            ]}
            style={styles.list}
          />
          <List
            arr={[
              "По итогам обучения выдается Удостоверение о повышении квалификации установленного образца"
            ]}
            style={styles.p}
            num="none"
          />
        </div>
      }
    />
  );
};

export default Typology;
