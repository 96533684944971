import React from "react";
import "./style.css";

export function ContentBlock(props) {
  const c = props.content;

  return (
    <div className="bkg-content">
      <h2>{c.title}</h2>
      <div className="content-line" />
      <p>{c.text}</p>
    </div>
  );
}

export function Block(props) {
  return (
    <div
      class="block-bkg-container"
      style={{ backgroundImage: `url("${props.background}")` }}
    >
      <ContentBlock content={props.content} />
    </div>
  );
}
