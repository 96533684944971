import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import {
  HeadBlock,
  Calendar,
  Teachers,
  Programma,
  TextAndTags,
  SocnetBlock,
  Formats,
  Price,
  AboutProfession,
  Principes,
  GrayBlock,
  Text2,
  DatesBlock, //screen2
  BuilderLayout,
  Information
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Дизайнер интерьера. Годовая программа обучения</title>
        <meta
          name="keywords"
          content="дизайнер, интерьер, курсы, школа, обучение, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Вы освоите цикл основополагающих дисциплин, связанных как с эстетикой, так и с прикладными аспектами дизайна. Мы даем базу для старта карьеры дизайнера интерьеров и вместе понимаем, что профессия потребует дальнейшего постоянного совершенствования. Здесь Вы научитесь работать с жилым пространством, выполняя реальные проекты. Узнаете, как продвигать свои услуги. Сможете реализовать свои мечты, выйти на новый уровень или запустить собственный бизнес."
        />
      </Helmet>
      <Page lable="Профессия дизайнер" lableMobile="Профессия дизайнер">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Calendar content={c.dates} />,
            <TextAndTags
              content={{
                text: c.text1,
                tags: c.tags1
              }}
            />,
            <DatesBlock content={c.screen2} />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <>
              <Teachers
                content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
              />
              {/* <Price content={{ pay: c.pay, countPay: 4 }} /> */}
            </>,
            <SocnetBlock />,
            <Text2 content={c.text2} />,
            <AboutProfession content={c.aboutProfeshion} />,
            <Principes content={c.principes} />,
            <Formats content={c.formats} />,
            <Information content={c.info} />,
            <GrayBlock content={c.review} />,
            c.designHome
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
