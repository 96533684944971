import React, { useState } from "react";
import Slider from "../../components/slider-rk";
import "./style.css";

export function Slides(props) {
  return (
    <div className="photosession-box">
      <div style={{}} className="photosession-slider">
        <Slider
          images={props.images}
          imageStyle={props.imageStyle}
          imageClassName={props.imageClass || "photosession-image"}
          infinity
        />
      </div>
      <div
        className="photosession-descript slider-descript"
        style={props.descriptStyle}
      >
        {props.descript}
      </div>
    </div>
  );
}

export function Photossesion(props) {
  const [open, setOpen] = useState(false);
  const opener = () => setOpen(!open);

  return (
    <>
      <div className="slider-box" style={{ maxHeight: open ? 800 : 0 }}>
        <Slides
          images={props.slides}
          imageStyle={props.imageStyle}
          descript={props.descript}
          descriptStyle={props.descriptStyle}
        />
      </div>
      <div className="spoller-button" onClick={opener}>
        {props.text}
        &ensp;
        <div
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all .5s",
            display: "inline-block"
          }}
        >
          <svg width="16px" height="16px" viewBox="0 0 24 24">
            <path
              fill="rgb(123,143,87)"
              d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
