import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Paragraph from "./paragraph";

export default function Info() {
  return (
    <div>
      <Helmet>
        <title>
          Информация для пользователей | Политика конфиденциальности
        </title>
        <meta name="keywords" content="ВШС: Политика конфиденциальности" />
      </Helmet>
      <Page lable="Политика конфиденциальности" lableMobile="">
        <Grid
          container
          justify="center"
          style={{
            background: "url(/img/info/fullscreen.jpg) left top/cover no-repeat"
          }}
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <div style={{ height: 100 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <h1
              style={{
                padding: 10,
                paddingLeft: 20,
                margin: 0,
                background: "rgba(255,255,255,0.8)",
                fontFamily: "Roboto",
                fontSize: "2em",
                fontWeight: 700,
                color: "rgb(80,80,80)",
                textAlign: "left",
                boxSizing: "border-box"
              }}
            >
              Информация для пользователей
              <br />
              <span style={{ color: "rgb(123,143,87)" }}>/&nbsp;</span>
              Политика конфиденциальности
            </h1>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: "5%" }}>
            <Paragraph
              content={[
                <span>
                  <b>
                    Политика конфиденциальности и безопасности:
                    <br />
                    согласие на обработку персональных данных
                  </b>
                  <br />
                  (далее «Согласие на обработку персональных данных»)
                </span>,
                <i>Дата вступления в действие: 21/05/2018</i>,
                "Нажимая на кнопку «Оставить заявку» или «Задать вопрос», заполняя поля с контактной информацией в соответствующих формах, Вы подтверждаете свое согласие на обработку Ваших персональных данных в соответствии с Федеральным законом Российской Федерации от 27 июля 2006 г. N 152-ФЗ «О персональных данных» и в соответствии с «Согласием на обработку персональных данных», при этом даете Ваше согласие на их обработку своей волей и в своем интересе.",
                "Любая Ваша персональная информация, полученная на нашем сайте, остается Вашей собственностью. Тем не менее, отправляя свои персональные данные, вы доверяете нам право использовать вашу персональную информацию для любого законного использования, включая, без ограничений: совершение заказа продукта или услуги, который вы делаете на нашем сайте, показ рекламных предложений средствами телемаркетинга, почтового маркетинга и др. рекламы, для проверки, подписки, отписки, улучшения контента и целей получения обратной связи.",
                "СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ",
                "Регистрируясь на нашем сайте http://styleschool.ru, Вы принимаете Согласие на обработку персональных данных (далее – Согласие). Принятием (акцептом) оферты настоящего Согласия является регистрация на Сайте в момент заполнения Вами одного из или всех полей на страницах сайта, а именно: «Имя», «телефон», «e-mail», с последующим нажатием кнопки «Отправить».",
                "Вы даете свое согласие АНО ДПО «Высшая школа стилистики, дизайна и технологий», зарегистрированного по юр. адресу 129085, г. Москва, проспект Мира, ДОМ 101, стр.1, этаж 6 пом. I ком. 1, 2, 2а, 3, на обработку своих персональных данных со следующими условиями:",
                "Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.",
                "Согласие дается на обработку следующих персональных данных:",
                "2.1 Персональные данные",
                "2.1.1 Фамилия, имя, отчество;",
                "2.1.2 Номера контактных телефонов;",
                "2.1.3 Адреса электронной почты.",
                "Персональные данные не относятся к общедоступным.",
                "Целью обработки персональных данных является – предоставление доступа к контенту и функционалу сайта http://styleschool.ru.",
                "Основанием для обработки персональных данных является Ст. 24 Конституции Российской Федерации; ст.6 Федерального закона №152-ФЗ «О персональных данных»; Устав АНО ДПО «Высшая школа стилистики, дизайна и технологий», иные федеральные законы и нормативно правовые акты.",
                "В ходе обработки с персональными данными будут совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.",
                "Передача персональных данных третьим лицам осуществляется на основании установленного законодательства Российской Федерации, договора с участием Пользователя или с согласия Пользователя. Передача персональных данных осуществляется третьим лицам на основании договоров о предоставлении услуг Пользователям, передавшим свои персональные данные для обработки.",
                "Персональные данные обрабатываются до ликвидации Сайта или до отзыва Пользователем персональных данных. Хранение персональных данных осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного хранения.",
                <>
                  Согласие может быть отозвано Пользователем, путем направления
                  письменного заявления на электронный адрес{" "}
                  <a href="mailto:info@styleschool.ru">info@styleschool.ru</a>.
                </>,
                "В случае отзыва Пользователем согласия на обработку персональных данных АНО ДПО «Высшая школа стилистики, дизайна и технологий» вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.",
                "Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в п.8 и п.9 данного Согласия.",
                <b>Безопасность.</b>,
                "Пожалуйста, помните, никакая передача данных через интернет, мобильное устройство или через беспроводное устройство не могут гарантировать 100%-ную безопасность. Вы контролируете те данные, которые Вы сообщаете при использовании интернет сервисов, и, в конечном счете, Вы несете ответственность за сохранение в тайне Вашей личности, паролей и/или любой другой личной информации, находящейся в Вашем распоряжении в процессе пользования интернет сервисами. Всегда будьте осторожны и ответственны в отношении Вашей личной информации. Мы не несем ответственности за, и не можем контролировать использование другими лицами любой информации, которую Вы предоставляете им, и Вы должны соблюдать осторожность в выборе личной информации, которую Вы передаете третьим лицам."
              ]}
            />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
