import React from "react";
import Pattern from "./pattern";
import { style } from "./style";

const Stipendii = props => {
  return (
    <div>
      <Pattern
        title="Стипендии и иные виды материальной поддержки"
        content={
          <div>
            <h3 style={style.h3}>
              Информация о наличии и об условиях предоставления обучающимся
              стипендий, мер социальной поддержки
            </h3>
            <p style={style.text}>
              Образовательная организация ВШСДТ не предоставляет обучающимся
              стипендии и иные меры социальной поддержки.
            </p>
            <h3 style={style.h3}>
              Информация о наличии общежития, интерната, количестве жилых
              помещений в общежитии, интернате для иногородних обучающихся,
              формировании платы за проживание в общежитии
            </h3>
            <p style={style.text}>
              В АНО ДПО ВШСДТ общежития и интернаты отсутствуют.
            </p>
            <h3 style={style.h3}>Информация о трудоустройстве выпускников</h3>
            <p style={style.text}>
              Система образования в АНО ДПО ВШСДТ не предусматривает
              обязательного трудоустройства выпускников.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default Stipendii;
