import React from "react";
import Pattern from "./pattern";
import { style } from "./style";
import Paragraph from "../../components/p-array";
import { Link } from "react-router-dom";

const Sved = props => {
  return (
    <div>
      <Pattern
        title="Основные сведения"
        content={
          <div>
            <h2 style={style.h2}>Дата создания организации</h2>
            <p style={style.text}>
              Автономная некоммерческая организация дополнительного
              профессионального образования «Высшая школа стилистики, дизайна и
              технологий» зарегистрирована Управлением Федеральной налоговой
              службы по городу Москве 21 мая 2018 года.
            </p>
            <h2 style={style.h2}>
              Учредители образовательной организации и орган управления
            </h2>
            <Paragraph
              style={style.text}
              content={[
                "Автономная некоммерческая организация дополнительного профессионального образования «Высшая школа стилистики, дизайна и технологий»",
                "ИНН: 9717068337",
                "КПП: 771701001",
                "ОГРН: 1187700009056",
                "Юридический адрес: 129085, г. Москва, проспект Мира, ДОМ 101, стр.1, этаж 6 пом. I ком. 1, 2, 2а, 3",
                <span style={{ ...style.text, padding: 0 }}>
                  Информация об учредителях:{" "}
                  <a href="/download/vypiska-iz-egrul.pdf">Выписка из ЕГРЮЛ</a>
                </span>,
                "Генеральный директор: Гулиенко Иван Анатольевич"
              ]}
            />
            <h2 style={style.h2}>Информация об образовательной организации</h2>
            <Paragraph
              style={style.text}
              content={[
                <span style={style.text}>
                  Адрес образовательной организации: 129085, г. Москва, проспект
                  Мира, дом 101, стр.1, офис 600
                </span>,
                <span style={style.text}>
                  Контактные телефоны: 8 (800) 550-78-93,&ensp;+7 (495)
                  221-89-35
                </span>,
                <span style={style.text}>
                  Адрес электронной почты:{" "}
                  <a href="mailto:info@styleschool.ru">info@styleschool.ru</a>
                </span>,
                <span style={style.text}>
                  Официальный сайт: <Link to="/">www.styleschool.ru</Link>
                </span>
              ]}
            />
            <h2 style={style.h2}>Режим и график работы</h2>
            <h2 style={style.h2}>
              Режим и график работы административно-управленческого персонала
            </h2>
            <Paragraph
              style={style.text}
              content={[
                "Понедельник-Пятница: время начала работы 10 ч. 00 мин., время окончания работы 19 ч. 00 мин.",
                "Выходные дни: суббота, воскресенье."
              ]}
            />
            <h2 style={style.h2}>
              Режим и график работы профессорско-преподавательского состава
              (далее &ndash; ППС)
            </h2>
            <Paragraph
              style={style.text}
              content={[
                "Смещение времени начала и окончания работы может быть обусловлено расписанием учебных занятий.",
                "Понедельник-суббота: время начала работы 9 ч. 30 мин., время окончания работы 22 ч. 00 мин.",
                "Выходной день: воскресенье"
              ]}
            />
          </div>
        }
      />
    </div>
  );
};

export default Sved;
