import React from "react";

import Paragraph from "../../components/p-array";
import Blog from "./template";

export default function Myslitel() {
  return (
    <Blog
      title="Ноэль Паломо-Ловински. Книга Мода и модельеры."
      keywords="Ноэль, мода, модельеры, стиль, искусство, творчество"
      descript="В книге «Мода и модельеры» представлены по принципу хронологии 50 самых знаковых модельеров за всю историю модной индустрии от первых знаменитых портных, таких как Поль Пуаре, до современных дизайнеров, изменивших стиль и культуру в XXI веке. Самые значимые коллекции и отдельные творения всех модельеров представлены очень подробно: перечислено всё то, чем вдохновлялся дизайнер, создавая свои работы, а также имена личностей, повлиявших на его творчество, основные этапы его творческого пути."
      head="Ноэль Паломо-Ловински. Мода и модельеры."
      author="Наталья Горских"
      link="/natalya_gorskyh"
      image="/img/blog/book-palomo-lovinski.jpg"
      content={
        <Paragraph
          content={[
            "В книге представлены по принципу хронологии 50 самых знаковых модельеров за всю историю модной индустрии от первых знаменитых портных, таких как Поль Пуаре, до современных дизайнеров, изменивших стиль и культуру в XXI веке. Самые значимые коллекции и отдельные творения всех модельеров представлены очень подробно: перечислено всё то, чем вдохновлялся дизайнер, создавая свои работы, а также имена личностей, повлиявших на его творчество, основные этапы его творческого пути. Наконец, указаны те современные дизайнеры-кутюрье, чье творчество развивается под влиянием тех 50, кто на протяжении 100 лет создавал модную индустрию."
          ]}
        />
      }
    />
  );
}
