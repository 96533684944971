import React from "react";
import Paragraph from "../../components/p-array";
import { styles } from "../../patterns/pattern-educations/style";

export const consultInterior = {
  head: (
    <>
      Сам себе дизайнер
      <br />
      Стилистика интерьера
    </>
  ),
  typeEducation: "Soft skill",
  duration: "1 мес. Очно или Дистанционно",
  specialisation:
    "Для начинающих — это первый шаг к профессии дизайнера или к осознанному оформлению свого пространства",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>Стили, колористика, композиция, освещение, мате&shy;риалы</>,
        <>Зонирование интерьера, эрго&shy;но&shy;мика</>,
        <>Подготовка сметы, бюд&shy;жети&shy;рова&shy;ние</>,
        "Проектирование и визуализация",
        "Работа со специалистами из смежных областей",
        "Коучинг наставника: как найти баланс с самим собой, клиентом, подрядчиками"
      ]}
    />
  ),
  diplom: <>Сертификат</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/interior-design"
  },
  space: "2rem"
};

export const psychologyOfColor = {
  head: (
    <>
      Психология цвета.
      <br />
      Как обрести гармонию
    </>
  ),
  typeEducation: "Видео курс",
  duration: "15 занятий. Дистанционно",
  specialisation:
    "Понимать семантику цветов в мировой культуре, а также психофизиологии их восприятия, их воздействия на моду и стиль жизни",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Первобытная цветовая триада и ее семантика.",
        "Палитра цветов. Оттенки, смыслы, тренды.",
        "Эстетика пастельных цветов.",
        "«Неоновые» цвета: назначение и воздействие.",
        "«Земли»: ассоциативное восприятие  и тренды.",
        "Сочетания цветов и визуальное впечатление"
      ]}
    />
  ),
  diplom: "сертификат",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/psychology-of-color" // "https://lectorium.club/psihologiya_cveta"
  }
  //space: "3.55rem"
};

export const interior = {
  head: (
    <>
      Идеальный интерьер.
      <br />
      Как сделать для себя
    </>
  ),
  typeEducation: "Видео курс",
  duration: "12 занятий. Дистанционно",
  specialisation:
    "Определение стратегий развития бизнеса по франшизе для брендов индустрии моды",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Стили в современном интерьере",
        "Цвет и композиция в идеальном интерьере",
        "Источники креативности и визуализация идей",
        "Зонирование, эргономика, строительные нормы",
        "Декоративные материалы и стилизация",
        "Планирование бюджета и сметы"
      ]}
    />
  ),
  diplom: "сертификат",
  link: {
    // type: "outerLink",
    // text: "купить",
    // link: "https://tickets.ru"
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: " https://intensive-online.ru/"
  },
  space: "1.3rem"
};

export const preraphaelites = {
  head: (
    <>
      Прерафаэлиты.
      <br />
      Быть самим собой
    </>
  ),
  typeEducation: "Видео курс",
  duration: "9 занятий. Дистанционно",
  specialisation:
    "Умение видеть, как выглядит синтез прошлого, настоящего и будущего в искусстве",
  price: false,
  process:
    "В курсе рассмотрена знаково-символическая система художественного образа «Братства»: новое понимание цвета и композиции, правдоподобие и символизм, натурализм и мистичность, синтез литературы, жизни и творчества, двойственность и психологизм, вариативность категории красоты и образа Женщины.",
  diplom: "сертификат",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/pre-raphaelites" // "https://lectorium.club/prerafaelity"
  }
  //space: "3.55rem"
};

export const history = {
  head: (
    <>
      История моды
      <br />с Русланом Миграновым
    </>
  ),
  typeEducation: "Видео курс",
  duration: "5 занятий. Дистанционно",
  specialisation: "Понимать изменчивость и красоту моды",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Основные характеристики костюма каждого десятилетия",
        "Создатели моды, их идеи и образы",
        "Иконы стиля и «уличная» мода",
        "Развитие моды как развитие идей искусства и технологий XX века",
        "Суть творческого метода французских, итальянских, британских и американских дизайнеров-кутюрье"
      ]}
    />
  ),
  diplom: "сертификат",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/fashion-history" // "https://lectorium.club/istoriya_mody"
  }
  //space: "3.55rem"
};

export const phylosophyJapanFashion = {
  head: (
    <>
      Философия
      <br />
      японской моды
    </>
  ),
  typeEducation: "Видео курс",
  duration: "10 занятий. Дистанционно",
  specialisation: "Аналитика моды. Повышение квалификации",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Традиционный костюм и современное «fashion сознание».",
        "Основные черты японской философии и эстетики как основа отношения к проектированию современного костюма.",
        "Эволюция японской моды и принципов проектирования: от 70-х до настоящего времени.",
        "И еще больше о японской моде…"
      ]}
    />
  ),
  diplom: "удостоверение о повышении квалификации",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/japanese-fashion-philosophy"
  }
  //space: "3.55rem"
};

export const trandsModernFashion = {
  head: (
    <>
      Главные стратегии
      <br />
      современной моды
    </>
  ),
  typeEducation: "Soft Skill",
  duration: "1 мес. Дистанционно",
  specialisation: (
    <>
      Аналитика моды. Для спе&shy;циа&shy;листов индус&shy;трии мо&shy;ды и
      красоты
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Мода как форма доступного искусства",
        "Тенденция «бедности» моды и образов",
        <>
          Тенденция разум&shy;ного по&shy;треб&shy;ления: non-fa&shy;shion, slow
          fashion
        </>,
        "Эко-направление. Ресйклинг",
        <>Постоянство этники. Развитие рынка «мусуль&shy;ман&shy;ской» моды</>,
        "Модные показы: суть изменений"
      ]}
    />
  ),
  diplom: <>сертификат</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/trends-of-modern-fashion"
  }
};

export const imageCommunication = {
  head: (
    <>
      Имидж-коммуникация
      <br />
      как личный PR и продвижение
    </>
  ),
  typeEducation: "Soft skill",
  duration: "1 мес. Очно и онлайн",
  specialisation:
    "Создание успешного имиджа для карьеры и бизнеса. Продуманная трансляция информации о себе через костюм, текст, фото, соц. сети",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>Стилистика: имидж индивидуальный, профес&shy;сиональ&shy;ный</>,
        "Организация фото и видео съемок для соц сетей",
        "Формирование, продвижение персонального бренда в сети"
      ]}
    />
  ),
  diplom: "сертификат",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/image-communication"
  }
};

export const selfStyle = {
  head: (
    <>
      Сам себе стилист
      <br />
      Мастерская Светланы Шабалиной
    </>
  ),
  typeEducation: "Soft skill",
  duration: "1 мес. Очно. Онлайн",
  specialisation:
    "Практический курс по созданию своего стиля с преподавателями, практикующими имидж-стилистами",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Индивидуальная цветовая палитра",
        "Типология фигур. Выигрышные фасоны",
        "Смешение разных стилей и создание модных комплектов",
        "Принципы подбора аксессуаров, прически, макияжа",
        "Принципы создания рационального гардероба",
        "Секреты удачного шопинга"
      ]}
    />
  ),
  diplom: "сертификат",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/self_style"
  }
};

export const content = {
  head: "Краткосрочные курсы",
  tiles: [
    imageCommunication,
    consultInterior,
    selfStyle,
    psychologyOfColor,
    history,
    phylosophyJapanFashion,
    preraphaelites,
    // masteryOfText,
    trandsModernFashion
    // interior,
    // personalBrand,
  ]
};
