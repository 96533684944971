import React from "react";
import Helmet from "react-helmet";
import Layout from "./layout";
import Page from "../../components/page";

function PageEducation(props) {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="keywords" content={props.keyword} />
        <meta name="description" content={props.description} />
      </Helmet>
      <Page lable={props.lable} lableMobile={props.lable}>
        <Layout content={props.content} />
      </Page>
    </div>
  );
}

export default PageEducation;
