import React from "react";
import List from "../../components/list";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import { kostarnova, lamonova } from "../../contents/teachers";
import Link from "react-router-dom/Link";
// import Phones from "../../components/phones-icons";
import { styles } from "./styles";
import Paragraph from "../../components/p-array";

const date1 = dates.interiorStylistTGU.online;
const date2 = dates.interiorStylistTGU.online2;

export const contents = {
  titlePage: "Интерьерный стилист. Бесплатное обучение",
  headNew: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Интерьерный стилист
        <br />
        Бесплатное обучение
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },

    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Поэтапная работа по реализации проекта создания стиля и дизайна жилого
        помещения как эффективного решения задач клиента, а также ориентация на
        трудоустройство после обучения
      </div>
    ),
    descript: [
      <>Онлайн обучение</>,
      <>Удостоверение о повышении квалификации</>,
      <>
        Обучение проводиться на базе НИ ТГУ в рамках федерального проекта
        «Содействие занятости»
      </>,
      <>до 2.5 месяцев, 44 занятия + защита проекта</>,
      <>Живое общение с преподавателями</>
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#singup",
        text: "записаться"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      }
    ],
    imageSource: "/img/interior-stylist-tgu/cover.png"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        <>
          Онлайн с {date1.getDate()} {Month(date1.getMonth())}
        </>,
        <>
          Онлайн с {date2.getDate()} {Month(date2.getMonth())}
        </>
      ]
    },
    {
      title: "Формат",
      text: [<>Живые вебинары,</>, <>чат в группе</>]
    },
    {
      title: "Эксперты",
      text: [<>Российские и зарубежные практики —</>, <>дизайнеры, эксперты</>]
    }
  ],
  text: {
    text: (
      <>
        <p className="constructor-p">
          Цель курса «Консультант по стилю» — дать необходимую систему и навыки
          для создания индивидуального стиля клиентов, научить принципам
          создания имиджевого фото контента и продвижения личного бренда в
          социальных сетях.
        </p>
        <p className="constructor-p">
          Каждой теме уделяется необходимое количество времени, чтобы закрепить
          навыки и получить результат.
        </p>
        <p className="constructor-p">
          Программа обучения рассчитана на 3,5 месяца
        </p>
      </>
    ),
    tags: false
  },
  screen2: {
    text: (
      <List
        arr={[
          <>
            Национальный исследовательский Томский государственный университет
            является оператором Федерального проекта «Содействие занятости» и
            приглашает Вас принять участие в проекте, в рамках которого Вы
            можете получить допол&shy;ни&shy;тель&shy;ное профес&shy;сиональное
            обра&shy;зова&shy;ние по одной из более 50 про&shy;грамм, повысить
            свою востре&shy;бован&shy;ность на рынке труда, пере&shy;обучиться
            на новую специаль&shy;ность, а также полу&shy;чить помощь с
            трудо&shy;устрой&shy;ством в Центре карьеры Феде&shy;раль&shy;ного
            опера&shy;тора НИ ТГУ.
          </>,
          <>
            Проект осуществляется в рамках национальной программы «Демография» с
            2021 по 2024 гг. (включительно) и реализуется при поддержке
            Министерства труда и социальной защиты РФ, Мини&shy;стер&shy;ства
            науки и высшего обра&shy;зова&shy;ния РФ и Министер&shy;ства
            просве&shy;щения РФ.
          </>
        ]}
        num="none"
        classLi="p-constructor"
        olStyle={{ paddingLeft: 0 }}
      />
    ),
    image: (
      <img
        src="/img/style-consultant-tgu/tgu.jpg"
        alt="Томский государственный университет"
      />
    )
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Программа разработана на основе курса профессиональной переподготовки{" "}
        <Link to="/interior-your-life">«Интерьерный стилист. Декоратор»</Link>.
      </p>,
      <p>
        Цель программы: формирование дизайн-мышления и навыков проектной работы
        — от исследования и концепции до планирования, презентации и реализации,
        результатом которой является грамотно созданный интерьер (или его
        ключевые детали) с учетом художественно-эстетической выразительности и
        функционально-планировочных решений.
      </p>,
      <p>
        <strong>Программа направлена на формирование компетенций:</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <>
            консультанта по стилю и дизайну интерьера, оказывающего услуги
            частным или корпоративным клиентам;
          </>,
          <>
            продавца-консультанта по выбору интерьерных решений в проектах
            онлайн торговли/услуг, на предприятиях розничной торговли/услуг;
          </>
        ]}
      />,
      <p>
        <strong>В результате обучения выпускник программы сможет:</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            выявлять потребности клиентов, консультировать клиентов по
            современным концепциям интерьеров
          </>,
          <>
            согласовывать техническое задание, производить замеры,
            контролировать подрядчиков, сметы
          </>,
          <>
            разрабатывать и презентовать идеи в дизайне интерьера жилых
            помещений, формировать для клиента Design project folder
          </>,
          <>подбирать материалы, мебель и аксессуары декора</>,
          <>
            самостоятельно реализовывать проекты по созданию дизайна жилых
            помещений
          </>
        ]}
      />,
      <p>
        <strong>В программе</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Основы композиции в дизайне интерьера.</>,
          <>Основы проектирования.</>,
          <>Стили современного интерьера.</>,
          <>Технология дизайна. Эргономика.</>,
          <>Проектирование. Техническое исполнение.</>,
          <>Итоговая аттестация.</>
        ]}
      />
    ]
  },
  textNote: (
    <>
      <Paragraph
        classElement="p-constructor"
        content={[
          <>
            Подробнее о проекте «Содействие занятости» и Информацию по
            категориям слушателей, которым доступно обучение, смотрите на{" "}
            <a
              href="https://trudvsem.ru/information-pages/support-employment"
              target="_blank"
            >
              trudvsem.ru
            </a>
          </>,
          "Для предварительной регистрации подпишитесь на группу Открытого образования в Телеграмм"
        ]}
      />
      {/*
      <Spoler
        title={"Условия обучения в 2022 году"}
        text={
          <>
            <p>
              Для записи на обучение Вам потребуется пройти три этапа: подача
              заявки, загрузка комплекта документов, подписание договора на
              обучение. Возможно, Вам потребуется наша помощь.
            </p>
            <p>
              <strong>Кому доступно обучение</strong>
            </p>
            <p>
              В рамках Федерального проекта «Содействие занятости» обучение по
              данной программе доступно следующим категориям граждан РФ:
            </p>
            <List
              arr={[
                <>
                  граждане, ищущие работу и обратившиеся в органы службы
                  занятости, включая безработных;
                </>
              ]}
            />

            <List
              arr={[
                <>
                  Выбирайте эту категорию, даже если Вы работаете и просто
                  хотите повысить квалификацию. Обратитесь в центр занятости за
                  справкой для обучения.
                </>
              ]}
              olStyle={{ listStyle: "url('/img/green-check.svg')" }}
            />
            <List
              arr={[
                <>женщины, находящиеся в отпуске по уходу за ребенком;</>,
                <>
                  женщины, имеющие детей дошкольного возраста и не состоящие в
                  трудовых отношениях;
                </>,
                <>
                  все граждане в возрасте 50-ти лет и старше (предпенсионного
                  возраста)
                </>
              ]}
            />
            <p>
              <strong>Обучение полностью оплачивается государством</strong>
            </p>
            <p>
              Для получения доступа к обучению Вам потребуется заполнить заявку
              и предоставить определенные документы. На практике процесс
              согласования занимает не менее двух недель. Чтобы ускорить данный
              процесс, следуйте инструкциям ниже.
            </p>
            <List
              arr={[
                <>
                  Необходимо зарегистрироваться / авторизоваться на сайте{" "}
                  <a href="https://trudvsem.ru/" target="_blank">
                    trudvsem.ru
                  </a>{" "}
                  через портал Госуслуг
                </>,
                <>
                  Подать заявку на участие в одной из программ. По нашим
                  компетенциям Томский государственный университет представляет
                  три программы:
                  <Link to="/style-consultant-tgu">Консультант по стилю</Link>,
                  Интерьерный стилист, Стилист фотосессий.
                </>,
                <>
                  Получить письмо на электронную почту со ссылкой для загрузки
                  подтверждающих документов
                </>,
                <>
                  Получить письмо об одобрении Вашей заявки и приглашения от
                  ВУЗа на обучение с указанием конкретных дат начала и
                  завершения обучения
                </>
              ]}
              num="decimal"
            />
            <p>
              <strong>Важно, когда нужно написать нам:</strong>
            </p>
            <List
              arr={[
                <>
                  процесс рассмотрения заявки на портале «Работа в России»
                  составляет не менее 15 рабочих дней; чтобы получить ответ на
                  заявку быстрее, напишите нам через форму обратной связи после
                  оформления заявки и мы сможем ускорить этот процесс
                </>,
                <>
                  если у Вас возникают сложности с поиском программ или
                  заполнением заявки, пишите нам или в НИ ТГУ через сайт
                  <a href="https://tgu-dpo.ru/program/stylist" target="_blank">
                    tgu-dpo.ru/program/stylist
                  </a>
                </>,
                <>
                  если у Вас возникают вопросы по обучению, в том числе если Вы
                  не успеваете к заявленным на сайте датам, пишите нам в
                  WhatsApp или Telegram
                </>
              ]}
            />
          </>
        }
      />
      */}
    </>
  ),
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неде­лю Вы смот&shy;рите онлайн лекции. Лекции доступны по мере
          про&shy;­хож&shy;­дения груп­&shy;пой курса. Обу&shy;че­ние
          дви&shy;­жет&shy;ся в интен&shy;сив&shy;ном ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние
          за&shy;да&shy;ния. Это важная часть обу&shy;че&shy;ния, в
          резуль&shy;тате кото&shy;рой форми&shy;руется итого&shy;вая
          проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;по&shy;да&shy;­вате&shy;лем. Препо&shy;­да&shy;ва&shy;­тель
          лично прове&shy;­ряет зада&shy;­ния, находит ошибки,
          ком­мен&shy;­тирует и помо­гает
        </>
      )
    },
    {
      title: "Практика + Проект",
      text: (
        <>
          В итоге Вы созда­­ете проект офор&shy;мле&shy;ния квар&shy;тиры
          (жи&shy;ло&shy;го поме&shy;ще&shy;ния), прока&shy;чива&shy;ете
          на&shy;выки для об&shy;щения с кли&shy;ен&shy;тами и
          ра&shy;бо&shy;то&shy;дате&shy;лями
        </>
      )
    }
  ],
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={<>Форма обучения: онлайн</>}
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Альтернативные программы: 3 варианта</>}
        text={
          <List
            arr={[
              <>
                <Link to="/living-interior-design-and-decoration">
                  Дизайн и декорирование интерьера
                </Link>{" "}
                (диплом), 9 месяцев
              </>,
              <>
                <Link to="/interior-your-life">
                  Интерьерный стилист. Декоратор
                </Link>{" "}
                (диплом), 3.5 месяца
              </>,
              <>
                <Link to="/interior-design">Сам себе дизайнер</Link> (базовый
                курс), 1 месяц
              </>
            ]}
          />
        }
      />
    ]
  },
  miniFormHead: "Записаться на обучение",
  teachers: [kostarnova, lamonova]
};
