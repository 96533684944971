import React from "react";
import Slider from "./slider";
import Paragraph from "../../components/p-array";

const styles = {
  imageBox: {
    display: "inline-block",
    textAlign: "center",
    maxWidth: "50%"
  },
  smallImageBox: {
    display: "inline-block",
    textAlign: "center",
    maxWidth: "45%"
  },
  horizontalImageBox: {
    display: "block",
    textAlign: "center",
    maxWidth: "100%"
  },
  image: {
    maxWidth: "100%"
  },
  descriptBox: {
    display: "inline-block",
    padding: "1rem",
    fontFamily: "Roboto",
    fontSize: "1rem",
    color: "rgb(100,100,100)",
    maxWidth: "50%"
  },
  horizontalDescriptBox: {
    display: "block",
    padding: "1rem",
    fontFamily: "Roboto",
    fontSize: "1rem",
    color: "rgb(100,100,100)",
    maxWidth: "100%"
  },
  smallDescriptBox: {
    display: "inline-block",
    padding: "1rem",
    fontFamily: "Roboto",
    fontSize: "1rem",
    color: "rgb(100,100,100)",
    maxWidth: "45%"
  }
};

function Slides(arr) {
  let conts = [];

  arr.map(
    (el, k) =>
      (conts[k] = (
        <div>
          <div style={styles.imageBox}>
            <img src={el.image} alt="" style={styles.image} />
          </div>
          <div style={styles.descriptBox}>
            <Paragraph content={el.description} />
          </div>
        </div>
      ))
  );
  return conts;
}

function SlidesVideos(arr, video = false) {
  let conts = [];
  let classVideo = {
    small: styles.smallImageBox,
    horizontal: styles.horizontalImageBox
  };
  let classDescript = {
    small: styles.smallDescriptBox,
    horizontal: styles.horizontalDescriptBox
  };

  arr.map(
    (el, k) =>
      (conts[k] = (
        <div>
          <div style={classVideo[video]}>
            <video
              src={el.video}
              controls
              autoplay
              className="video-element-vertical"
            >
              <source
                src={el.video}
                type={el.types || 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'}
              />
            </video>
          </div>
          {el.description && (
            <div style={classDescript[video]}>
              <Paragraph
                content={el.description}
                style={{ fontSize: video ? ".8rem" : "1rem" }}
              />
            </div>
          )}
        </div>
      ))
  );
  return conts;
}

function Examples(props) {
  return (
    <Slider
      small={props.video ? true : false}
      slides={
        props.video
          ? SlidesVideos(props.content, props.small)
          : Slides(props.content)
      }
    />
  );
}

export default Examples;
