import React from "react";
import Grid from "@material-ui/core/Grid";

import List from "../../components/list";
import Paragraph from "../../components/p-array";

export default function Programm() {
  var styles = {
    h3: {
      fontFamily: "Roboto",
      fontSize: "1.1em",
      color: "rgb(80,80,80)",
      textAlign: "left",
      fontWeight: 700,
      margin: 0,
      padding: 4,
      paddingBottom: 0
    },
    h2: {
      fontFamily: "Lora",
      fontSize: "1.8em",
      color: "rgb(80,80,80)",
      textAlign: "center",
      fontWeight: 500,
      margin: 8,
      padding: "12px 0",
      paddingTop: 32
    },
    list: {
      fontSize: "1em",
      color: "rgb(80,80,80)",
      textAlign: "left",
      fontWeight: 400
    }
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <h2 style={styles.h2}>Программа курса</h2>
        <h3 style={styles.h3}>
          1, 2, 3 месяцы. Основы имиджмейкинга (Style Basic).{" "}
          <span style={{ fontWeight: 400, fontFamily: "inherit" }}>
            120 академ. часов
          </span>
        </h3>
        <List
          arr={[
            "колористика и цветовой анализ внешности как профессиональный инструмент стилиста",
            "психология формы и силуэтов, современные типологии фигур, возможности и способы визуальной коррекции с помощью одежды",
            "изучение основных групп современных стилей как системы для транслирования необходимого впечатления в имидже",
            "изучение аксессуаров, головных уборов, обуви, правил и способов их подбора с учетом fashiontrends",
            "профессиональный глоссарий стилиста, изучение профессиональной лексики и терминологии",
            "теория стилевых направлений как действенный инструмент в создании индивидуального стиля",
            "мужская стилистика, ее особенности, практика применения и современные тренды",
            // "дресс-коды",
            "изучение типов современного гардероба, создание гардеробных капсул",
            "теория и практика шопинга, в том числе on-line шопинга"
          ]}
          num="circle"
          style={styles.list}
        />
        <h3 style={styles.h3}>
          4, 5, 6 месяцы. Имидж-консалтинг (Personal styling).{" "}
          <span style={{ fontWeight: 400, fontFamily: "inherit" }}>
            120 академ. часов
          </span>
        </h3>
        <List
          arr={[
            "управление впечатлением",
            "структура имиджа, оценка впечатления",
            "психологические особенности разработки имиджа",
            "имиджевое восприятие и инструменты влияния",
            "концепция индивидуального стиля. Теория стилевых направлений",
            "концепция индивидуального стиля. Смешение стилевых направлений",
            "мужской стиль. Особенности стильного образа у мужчин",
            "дресс-коды, этикет",
            "практика формирования клиентского STYLE BOOK",
            "изучение особенностей презентации себя как профессионала в соц. сетях (Instagram)"
          ]}
          num="circle"
          style={styles.list}
        />
        <h3 style={styles.h3}>МОДУЛЬ: ПРОФАЙЛИНГ</h3>
        <List
          arr={[
            "изучение психологии клиентов, правил коммуникаций с клиентами",
            "современная система профайлинга и психотипирования",
            "алгоритмы работы с клиентами",
            "изучение особенностей презентации себя как профессионала в соц. сетях (Instagram)"
          ]}
          num="circle"
          style={styles.list}
        />
        <Paragraph
          content={[
            "УНИКАЛЬНОСТЬ обучения на программе Имидж-консультант в том, что здесь большое количество реальной практики, без которой невозможно стать настоящим профессионалом!"
          ]}
        />
        <h3 style={styles.h3}>В ПРОЦЕССЕ ОБУЧЕНИЯ:</h3>
        <List
          arr={[
            "практика в шоурумах и торговых пространствах",
            "практика определения цветотипа и выбора индивидуальной палитры",
            "практика визуальной коррекции фигуры с помощью одежды при помощи",
            "силуэта, линии, пропорций, законов оптических иллюзий",
            "практика создания комплектов в разных стилях",
            "практика определения стилевого направления клиента по чертам лица и фигуре практика",
            "правильного выбора аксессуаров, головных уборов и обуви",
            "практика разбора гардероба клиента",
            "практика создания гардеробных капсул",
            "практика мужского стиля",
            <span>практика создания релукингов &laquo;до и после&raquo;</span>,
            "практика определения психотипа клиента",
            "практика и алгоритмы консультирования клиента",
            "практика создания персональных style books",
            "практика создания личного бренда и имидж-консультанта",
            "практика создания персонального портфолио",
            "практика продвижения в Instagramm"
          ]}
          num="circle"
          style={styles.list}
        />
        <Paragraph
          content={[
            <b>
              По итогам обучения на модуле &laquo;Fashion-стилистика&raquo;
              слушатели готовят и защищают проектную работу: фото-сессия (зачет)
            </b>
          ]}
        />
        <h3 style={styles.h3}>А так же:</h3>
        <List
          arr={[
            "МАСТЕР-КЛАССЫ С ПРОФЕССИОНАЛАМИ ИНДУСТРИИ",
            "АТМОСФЕРА ТВОРЧЕСТВА И КРЕАТИВА",
            "ПОСЕЩЕНИЕ МОДНЫХ ПОКАЗОВ И НЕДЕЛЬ МОДЫ",
            "ЗАКРЫТЫЕ ВЫСТАВКИ И ШОУ-РУМЫ",
            "УЧАСТИЕ В МОДНЫХ ПРОЕКТАХ",
            "ОБЩЕНИЕ С ЕДИНОМЫШЛЕННИКАМИ И БУДУЩИМИ КОЛЛЕГАМИ"
          ]}
          num="circle"
          style={styles.list}
        />
        <Paragraph
          content={[
            <b>
              В процессе обучения на модуле &laquo;Имидж-консалтинг&raquo;
              слушатели готовят и защищают проектную работу: STYLE-BOOK для
              клиента. Итоговая аттестация: Экзамен
            </b>,
            "Результат Вашего обучения зависит не только от грамотно составленной программы и удобного формата, но и от преподавателей. Вас будет обучать команда опытных специалистов. Практикующие имидж-консультанты, дизайнеры, психологи, стилисты, у каждого из которых есть своя специализация и уникальный опыт. Все преподаватели поделятся своим опытом и вниманием с Вами.",
            <span>
              По итогам обучения на модулях &laquo;Имидж-консалтинг&raquo; и
              &laquo;Профайлинг&raquo; слушатели сдают промежуточный экзамен, по
              результатам успешной сдачи которого получают Диплом ВШСДТ о
              профессиональной переподготовке по программе «Имидж-консультант» с
              правом деятельности в новой области &laquo;Имидж-консалтинг&raquo;
            </span>
          ]}
        />
      </Grid>
    </Grid>
  );
}
