import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Head from "../../components/head-articles";
import Footer from "../../components/footer";
import Paragraph from "../../components/p-array";
import List from "../../components/list";

export default function Individ() {
  return (
    <div>
      <Helmet>
        <title>
          ВШС: Наталья Горских: Индивидуальный стиль. Творчество или
          необходимость?
        </title>
        <meta
          name="keywords"
          content="ВШС: Наталья  Горских: Индивидуальный стиль. Творчество или необходимость?"
        />
      </Helmet>
      <Top />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{ maxWidth: 1000, margin: "0 auto", padding: 20 }}
      >
        <Grid utem xs={12} md={12}>
          <Head
            head="Индивидуальный стиль. Творчество или необходимость?"
            author="Наталья Горских"
            linkAuthor="/natalya_gorskyh"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Когда-то древние культуры и античная цивилизация не знали индивидуальности в том смысле и значении, в котором мы привычно произносим это слово сегодня по отношению ко всему: к экономическим концепциям, к научным решениям, к географическим открытиям, к произведениям искусства, к стилю поведения, к манере говорить, к умению носить одежду.",
              "Нас всех учат, что нужно быть лидером, чтобы многого добиться и достичь, быть профессионалом в своем деле, что нужно уметь управлять организацией и впечатлением, уметь управлять страхами и эмоциями, уметь самовыражаться и быть убедительным для этого мира. В эпоху постмодерна, где каждый индивидуальный авторский голос имеет равные права с таким же, но все равно другим, индивидуальным авторским голосом, нужно сделать так, чтобы тебя услышали и признали.",
              "Как это сделать? Способов сегодня множество. Кто-то наводняет соцсети своими фотографиями и видео в доказательство своей правоты и красоты, кто-то демонстративно потребляет статусные и роскошные вещи, кто-то любит китч и провокацию, кто-то задумывается и создании личностного бренда, а кто-то работает над развитием себя в профессии, занимается любимым делом и получает желаемый результат.",
              "Современное многоголосие имеет такое же внешнее выражение: есть приверженцы всего яркого, контрастного, необычного, оригинального, есть сторонники традиций и консерватизма, есть интеллектуальный дендизм, есть стремление подчеркнуть демократизм, есть представители нормкора, а есть те, кто одевает маску безразличия и выражения «мне все равно, что вы про меня думаете»."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container justify="center" alignItems="flex-start" spacing={3}>
            <Grid item xs={12} md={12}>
              <img
                src="/img/blog/individ/1.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph
                j
                content={[
                  "Но не много найдется индивидуальностей, которые задумываются над сутью: а почему я выгляжу и одеваюсь так, а не иначе, почему мне свойственен такой стиль общения, почему я принимаю именно такие решения, в чем суть того, что я делаю. И как только вы начинаете задумываться о себе и пытаться ответить на вопрос: «Кто я?», тогда и происходит зарождение того самого индивидуального стиля."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph
                j
                content={[
                  "Однако трудно бывает познать себя, даже честно ответив на многие вопросы. Но еще трудней найти верное внешнее выражение своих внутренних достоинств. А если к этому прибавить наши желания достичь профессионального успеха, развить свой талант и мастерство, реализовать себя в профессии и получить как материальное, так и социальное признание…Так вопрос о том, как выглядеть, чтобы тебя услышали и поняли, становится все сложнее и актуальнее."
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                border: "3px solid rgb(238,238,238)",
                borderLeft: 0,
                borderRight: 0,
                padding: 20
              }}
            >
              <Paragraph
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Lora",
                  fontStyle: "italic"
                }}
                content={[
                  "Сегодня на рынке услуг уже стали привычными стилисты, имиджмейкеры, имидж-стилисты, консультанты по имиджу и стилю. Но мало кто знает, чем занимаются эти специалисты, кроме того, что «подбирают одежду» и «ходят с клиентом по магазинам»."
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ background: "rgb(203,234,245)" }}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={3}
          style={{
            background: "rgb(203,234,245)",
            maxWidth: 1000,
            margin: "0 auto",
            padding: 20
          }}
        >
          <Grid item xs={12} md={6}>
            <Paragraph
              j
              content={[
                "Как правило, большинство не видят необходимости обращаться к таким специалистам по нескольким причинам:",
                <List
                  arr={[
                    "Сомнительно наличие тех умений и знаний, которых у меня нет. Про цветотипы и типы фигуры только ленивый не прочитал.",
                    "Вполне хорошо выгляжу, меня все устраивает, я сам неплохо разбираюсь в моде (а в современном мире все «разбираются в моде, как и в дизайне). Поэтому могу сделать, как эти стилисты, и даже лучше.",
                    "Одежда – это малосущественная часть личности, главное – ум, знания, компетенция, а особенным образом думать о подборе одежды и тратить на это время могут только молодые девушки или состоятельные скучающие дамы, или мужчины нетрадиционной ориентации."
                  ]}
                  num
                />
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="/img/blog/individ/2.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        alignItems="flex-start"
        spacing={3}
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid item xs={12} md={8}>
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={12} md={12} style={{ paddingTop: 34 }}>
              <img
                src="/img/blog/individ/3.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph
                j
                content={[
                  "Высшая школа стилистики учит тем базовым современным понятиям, которые придадут вашей жизни новое звучание, заставят смотреть на себя, окружающий мир и профессию иначе. Творчество, психология в содружестве с инструментами продажи и продвижения себя – что еще нужно современному человеку, чтобы найти опору в современном мире? Потому обучение и программы, которые предлагает ВШС – это программы не только для тех, кто готов стать стилистом и имиджмейкером. Это программы поиска решений и их реализации в любой области деятельности любого задумывающегося о себе человека.",
                  "Современный и отчасти элитарный подход к обучению поддерживается и вариативностью форм обучения. Школа активно развивает, помимо традиционных очных форм, дистанционные способы обучения. Дистанционный формат – это и анонимность для тех, кто не готов открыться взгляду аудитории, и индивидуальность, и комфорт и экономия времени."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph
                j
                content={[
                  "В мире, распавшемся на множество мнений, ценностей, взглядов, где смешиваются границы между красивым и некрасивым, искусством и китчем, между мужскими и женскими ролями, между естественностью и видимостью, между вечным и сиюминутным, важно самоопределиться и сохранить свою целостность.",
                  "Приходя на обучение в школу вы начинаете путь к самому себе, который заканчивается созданием взвешенного и продуманного слова. Вы можете сформировать текст своего образа, который считывают другие люди, он – показатель ваших творческих и интеллектуальных способностей. И этот роман с самим собой будет продолжаться всю жизни, поскольку стиль – это развитие, процесс, как в любви.",
                  "Высшая школа стилистики уже много лет обучает именно такой специальности – стилист и имиджмейкер (к сведению, это – разные специальности, хоть и схожи по функциям)."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <p
                style={{
                  background: "rgb(60,105,150)",
                  color: "rgb(255,255,255)",
                  fontFamily: "Lora",
                  fontSize: "1.2em",
                  textAlign: "justify",
                  padding: 8,
                  fontStyle: "italic"
                }}
              >
                Поиск индивидуальности и выразительности, еще и как умения
                выглядеть уместно и соответствовать ситуации, связан не только с
                одеждой и атрибутами внешнего облика.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              <p
                style={{
                  background: "rgb(60,105,150)",
                  padding: 8,
                  color: "rgb(255,255,255)",
                  fontFamily: "Lora",
                  fontSize: "1em",
                  fontStyle: "italic",
                  textAlign: "justify"
                }}
              >
                Однако, те, кто заканчивают эту школу, занимаются не подбором
                одежды в соответствии с природными внешними данными, а учат
                своих клиентов убедительно пользоваться тем, что им дала
                природа, понимая, как с помощью одежды, прически, аксессуаров
                написать текст о себе.
              </p>,
              "Текст о том, кто вы есть сегодня (ваш характер, стиль коммуникации, род деятельности, возраст, статус) и кем вы можете/хотите стать в будущем.",
              "Индивидуальный стиль – это почерк, который при использовании одинаковых букв, имеет свою неповторимую узнаваемость. Школа учит стилистов при работе с человеком суметь сохранить общее (человек должен вписаться в современную жизнь и ее требования) и стать индивидуально-выразительным. Ведь скрыть недостатки своего лица или фигуры можно, однако это никак не поможет вам стать успешным, уверенным, довольным собой и своей деятельностью, добиться результата, сохранить баланс с миром.",
              <b>
                На вопрос «как это сделать» и помогает ответить обучение в ВШС:
                вы учитесь творческому подходу и образному мышлению, что
                позволяет увидеть себя глазами других, посмотреть на себя как на
                красивое произведение и создать свой индивидуальный стиль
                используя те же приемы, что используют художники для создания
                полотен.
              </b>,
              "А дальше вы учитесь тому, как донести миру свою красоту и свою индивидуальность, чтобы она не мешала, а помогала вам добиться профессиональных целей и получать удовлетворение от своей социальной и профессиональной реализации. Каждый из нас исполняет свою личную роль, социальную роль, профессиональную роль, и, возможно, не одну. Как суметь создать не демонстративный имидж («другой» вместо меня) или наоборот, создать демонстративный имидж, но при этом не поссориться с собой – вот вопросы, ответы на которые дает обучение в Высшей школе стилистики."
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ background: "rgb(211,219,245)" }}>
        <Grid
          container
          alignItems="flex-start"
          spacing={3}
          style={{ maxWidth: 1000, margin: "0 auto" }}
        >
          <Grid item xs={12} md={8}>
            <Paragraph
              j
              content={[
                "Есть еще и пространство, которое вы создаете и к которому стремитесь – пространство вашего дома. Высшая школа стилистики продолжает исследовать индивидуальный стиль, но уже на уровне окружающего вас пространства и обучает стилю в области дизайна и декорирования интерьера.",
                <div
                  style={{
                    borderLeft: "4px solid rgb(238,238,238)",
                    fontStyle: "italic",
                    fontFamily: "Lora",
                    padding: 16
                  }}
                >
                  Возможно, вы хотите заниматься в жизни тем, чтобы формировать
                  «стиль жизни» торговых марок, брендов и магазинов, понимая,
                  что такое творчество и маркетинг, вдохновение и аналитика.
                  Школа предлагает для желающих стать таким специалистом
                  практическую, глубокую и комплексную программу в области
                  визуального мерчендайзинга и витринистики.
                </div>,
                "Отдельные лекции и мастер-классы разной тематики от профессиональных преподавателей-практиков завершают всю композицию обучения в школе."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src="/img/blog/individ/4.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
