import React from "react";
import Title from "./title-meta";
import { Grid, Hidden } from "@material-ui/core";
import Header from "./header-links";
import Top from "../../components/topmenu";
import Paragraph from "../../components/p-array";
import Footer from "../../components/footer";

const name = "Анна Марчук";

const styles = {
  h2: {
    color: "rgb(123,143,87)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "left",
    margin: 0,
    padding: "12px 0"
  },
  text: {
    padding: "12px 0"
  }
};

export default function Marchuk() {
  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/marchuk1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              facebook="https://www.facebook.com/profile.php?id=100001683990023"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={11}>
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Все началось с танца</h2>
              <Paragraph
                j
                content={[
                  <React.Fragment>
                    Еще с самых юных лет я начала себя реализовывать в
                    творческих сферах. В детстве я профессионально занималась
                    бальными танцами &mdash; это была европейская и
                    латино-американская программы.
                  </React.Fragment>,
                  "В них я черпала вдохновение на проектирование и создание своих сценических образов. Вечерами мы вместе с мамой придумывали эскиз очередного платья и подготавливали нехитрые выкройки, которые затем относили знакомой швее для дальнейшего пошива."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Книга по стилистике</h2>
              <Paragraph
                j
                content={[
                  "Но даже после этого работа над нарядом не заканчивалась: мы украшали его пайетками и стразами. Самое лучшее платье, которое нам удалось создать, было в виде перевернутого бутона цветка: ярко-малиновая пышная юбка и изумрудная блузка.",
                  "В том же возрасте мне подарили мою первую книгу по стилистике, из которой я получила свои начальные знания в этой сфере, такие как: сочетания цветов и форм, искусство макияжа, секреты по уходу."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Новый образ</h2>
              <Paragraph
                j
                content={[
                  "Занятия танцами требовали постоянного использования лака для волос в безумных объемах, что впоследствии негативно отразилось на их здоровье. Волосы требовали лечения, и мы, прислушавшись к советам знакомых, решили воспользоваться хной. Правда вместо положенных 10 минут, я продержала ее 3 часа, потому что просто уснула. В результате я получила огненно-рыжий цвет, который, как оказалось со временем, дал выход моей внутренней творческой энергии, воплотившейся в моем новом образе и мироощущении."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div
                style={{
                  fontSize: "1.2em",
                  color: "rgb(100,100,100)",
                  background: "rgb(210,205,196)",
                  padding: 12
                }}
              >
                Занятия в ВШС произвели перелом в моем сознании, после них я
                впервые взглянула на мир под другим углом. Появилось забытое
                ощущение свободы. Ведь я, наконец, занимаюсь тем, что приносит
                мне истинное удовольствие. Теперь я счастливый человек. Я нашла
                себя.
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/marchuk2.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Карьерный путь</h2>
              <Paragraph
                j
                content={[
                  "Должна признать, что мой карьерный путь не всегда был связан с миром моды. Закончив в юном возрасте модельную школу и недолго проработав в этом направлении, я познакомилась с маркетологом, от которого узнала об этой молодой и малоизвестной профессии.",
                  <React.Fragment>
                    Решив работать в сфере рекламы, я получила соответствующее
                    образование и устроилась в фирму, занимающуюся разработкой
                    индивидуального стиля компаний. Но кризис 2008 года решил
                    все за меня &mdash; я сменила работу на должность,
                    занимающуюся продвижением предвыборных компаний одной из
                    крупных партий, проработав там три года.
                  </React.Fragment>,
                  "Подобная сфера деятельности не приносила мне должного удовлетворения, и, сменив пару однотипных должностей, я поняла, что снова хочу связать свою жизнь с модой и танцами, только в новом ключе. Я заметила, что получаю удовольствие от походов по магазинам и созданию цельных образов, что подтолкнуло меня к решению стать имиджмейкером, а шопинг превратить в танец."
                ]}
                style={styles.text}
              />
              <Hidden smDown>
                <div style={{ height: 32 }} />
              </Hidden>
              <div
                style={{
                  fontSize: "1.2em",
                  color: "rgb(100,100,100)",
                  background: "rgb(210,205,196)",
                  padding: 24
                }}
              >
                Но уже в процессе работы со своим первым клиентом выяснилось:
                моих теоретических знаний и опыта маловато, чтобы быть хорошим
                специалистом в этой области. Чтобы заполнить серьезный пробел в
                знаниях, я поступила в ВШС.
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Процесс обучения и опыт работы</h2>
              <Paragraph
                j
                content={[
                  "В начале обучения мне было очень трудно, так как внутри меня происходила сильная внутренняя борьба, но рамки, в которые я была заключена прежними должностями, постепенно теряли свою власть надо мной. Состояние «законсервированности» медленно, но верно отступало, происходило расширение моего креативного видения. С каждым днем я все больше убеждалась в правильности своего выбора.",
                  "Сейчас я совмещаю работу стилиста-имиджмейкера с должностью визуального мерчендайзера, параллельно продолжая обучение в Школе.",
                  "Из своего опыта работы могу сказать, что к услугам стилистов прибегают чаще всего женщины, уже состоявшиеся в карьере, которые хотят продвижения по службе. Что касается мужчин, то они, не скрывая этого, открыто ставят цель нравиться девушкам и быть более привлекательными в их глазах.",
                  <React.Fragment>
                    Планы на ближайшее будущее &mdash; собираюсь преподавать
                    стилистику и макияж, для того, чтобы люди становились лучше
                    и умели правильно выражать в своем образе индивидуальность
                    внутреннего мира.
                  </React.Fragment>
                ]}
                style={styles.text}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/marchuk3.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Совет</h2>
              <div
                style={{
                  fontSize: "1.2em",
                  color: "rgb(100,100,100)",
                  background: "rgb(210,205,196)",
                  padding: 24
                }}
              >
                Мой совет для всех &mdash; не бойтесь экспериментировать,
                чувствуйте себя уверенно в процессе творчества. Также советую
                хотя бы раз обратиться к профессиональному имиджмейкеру, потому
                что он искренне желает, чтобы вы были совершенны. А практикующим
                имиджмейкерам и тем, кто хочет начать работать в этой сфере, я
                настоятельно советую впитывать в себя как можно больше новой
                информации по теме психологии, PR, модных тенденций. Слушайте
                свое сердце и верьте себе!
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
