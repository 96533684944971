import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
//import Layout from "./layout";
// import Layout from "../../patterns/pattern-fs/layout";
import {
  BuilderLayout,
  HeadBlock,
  TextAndTags,
  SocnetBlock,
  Programma,
  TitleH2,
  // DatesBlock,
  CardImage,
  CardImageText,
  // Rgsu,
  Teachers,
  // HowIsTheTrainingGoing,
  ShessBlock,
  // PapersModul,
  // Actions,
  ShessArray,
  ShessBuilder,
  BoldText,
  Calendar,
  // Cards3,
  // VideosTile,
  ScrollingLink,
  Information,
  // Information2,
  AfterEducation,
  Text2,
  Principes,
  AboutProfession,
  Formats,
  OpenPay,
  GrayBlock
  // SpaceElement
} from "../../patterns/pattern-fs/components";
import Examples from "../../patterns/pattern-fs/examples";

const ImageConsulting = props => {
  return (
    <div>
      <Helmet>
        <title>{c.head.head}</title>
        <meta name="keywords" content={"ВШСДТ: " + c.head.head} />
      </Helmet>
      <Page lable="Имидж консалтинг">
        {/* <Layout content={contents} /> */}
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <TextAndTags content={{ text: c.text1, tags: c.tags1 }} />,
            <>
              <TitleH2 content={c.textAndMedia.head} />
              <ShessBuilder content={c.textAndMedia.content} vAlign="center" />
            </>,
            <BoldText content={c.boldText} />,
            <>
              <ScrollingLink scrollLink="dates" />
              <Calendar content={c.dates} />
            </>,
            // <Information2 content={{...c.infos2, info3: c.info3}} />,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            // <Information1 content={c.infos1} />,
            <AfterEducation content={c.afterEducation} />,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            <>
              <ScrollingLink scrollLink="price" />
              <OpenPay content={c.pay} tags={c.payTags} head={c.payHead} />
            </>,
            <SocnetBlock />,
            <>
              <TitleH2 content="Вас могут заинтересовать" />
              <ShessBlock
                content={{
                  left: <CardImage content={c.cardSelfStyle.image} />,
                  right: <CardImageText content={c.cardSelfStyle.text} />
                }}
                mediaLeft={true}
              />
            </>,
            <ShessBlock
              content={{
                right: (
                  <CardImage content={c.cardConsultingStyleAndDesign.image} />
                ),
                left: (
                  <CardImageText
                    content={c.cardConsultingStyleAndDesign.text}
                  />
                )
              }}
              mediaLeft={false}
            />,
            <Text2 content={c.text2} />,
            <>
              <TitleH2 content="Вам будет интересно и полезно учиться в ВШСДТ, если Вы:" />
              <ShessArray content={c.digitPuncts} />
            </>,
            <AboutProfession content={c.aboutProfeshion} />,
            <Principes content={c.principes} />,
            <Formats content={c.formats} />,
            <Information content={c.info} />,
            <GrayBlock
              content={{
                title: c.review.head,
                text: c.review.text
              }}
            />,
            <Examples content={c.examples} />,
            <SocnetBlock />
          ]}
        />
      </Page>
    </div>
  );
};

export default ImageConsulting;
