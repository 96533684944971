import React from "react";
import { Grid } from "@material-ui/core";
import { styles } from "./styles";
import { withStyles } from "@material-ui/styles";

const wStyles = theme => ({
  ...styles,
  formats: {
    padding: "1rem",
    maxWidth: 667,
    margin: "0 auto",
    boxSizing: "border-box"
  },
  columns: {
    maxWidth: "100%"
  },
  item: {
    background: "rgb(250,250,250)",
    padding: "2rem 1rem 1rem"
  },
  "@media (max-width: 959px)": {
    formats: {
      padding: "1rem",
      maxWidth: "100vw",
      boxSizing: "border-box"
    }
  }
});

function Formats(props) {
  const { classes } = props;

  return (
    <div className={`${classes.formats} ${props.columns && classes.columns}`}>
      <h2
        className={classes.h2}
        style={{
          fontFamily: "PT Sans Narrow",
          paddingTop: 0
        }}
      >
        {props.head}
      </h2>
      {props.columns ? (
        <Grid container spacing={4} justify="center" alignItems="stretch">
          {props.content.map((el, key) => (
            <Grid item xs={12} md={6} key={`format-item-component-${key}`}>
              <div className={classes.imgBox}>{el.icon}</div>
              <h3 className={classes.h3format}>{el.title}</h3>
              <p
                style={{
                  ...styles.p,
                  textAlign: "justify"
                }}
              >
                {el.text}
              </p>
            </Grid>
          ))}
        </Grid>
      ) : (
        props.content.map((el, key) => (
          <div className={classes.item} key={`format-item-component-${key}`}>
            <div className={classes.imgBox}>{el.icon}</div>
            <h3 className={classes.h3format}>{el.title}</h3>
            <p
              style={{
                ...styles.p,
                textAlign: "justify"
              }}
            >
              {el.text}
            </p>
          </div>
        ))
      )}
    </div>
  );
}

export default withStyles(wStyles)(Formats);
