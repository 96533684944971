import React from "react";

export default function About() {
  return (
    <p
      style={{
        fontSize: "1.1em",
        padding: 24,
        color: "rgb(100,100,100)"
      }}
    >
      Курс посвящен одной из самых актуальных тем, о которой в настоящее время
      говорят fashion-эксперты и арт-критики, дизайнеры и галеристы, маркетологи
      и искусствоведы – общность траекторий развития моды и искусства, в
      частности о понятии «концептуальность» по отношению к искусству и моде.
    </p>
  );
}
