import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Paragraph from "../../components/p-array";

export const contents = {
  head: {
    headDescript: "Интеллектуальное образование",
    title: "Философия японской моды: творцы, идеи, образы",
    head: (
      <>
        Философия японской моды:
        <br />
        <span style={{ fontSize: ".8em" }}>творцы, идеи, образы</span>
      </>
    ),
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Не бывает устья без истоков. Любое начало относительно, а любой конец —
        лишь начало чего-то нового
      </div>
    ),
    descript: [
      "Онлайн",
      "Новые идеи за 1 месяц регулярных занятий в мини группе",
      "Длительность — 12 занятий по полтора часа",
      "Стоимость 25 тыс. рублей"
    ],
    text: "",
    imageSource: "/img/japanese-fashion-philosophy/ill01.jpg",
    video: "CtrQbsBrdLY",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    }
  },
  screen2: {
    text: (
      <Paragraph
        content={[
          <strong>Если Вы хотите</strong>,
          "Разобраться, в чем уникальность японского стилеообразования",
          "Увидеть отличие японского и европейского подхода к моде",
          "Проследить истоки стиля современных японских дизайнеров"
        ]}
      />
    ),
    video: "CtrQbsBrdLY"
  },
  programma: {
    head: "Японская мода",
    content: [
      <List
        style={{ ...styles.p, textAlign: "justify" }}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Японская мода давно стала брендом, который аудитория мирового рынка модной индустрии связывает с понятиями «авангард», «деконструктивизм», «художественная революция». И каждый год новые коллекции Issey Miyake, Yohji Yamamoto, Comme des Garçons, Junya Watanabe, Undercover и др. современных японских брендов оправдывают ожидания своих поклонников и fashion-экспертов, подтверждают свой особый статус и символическое место в мировой системе моды.",
          "В чем уникальность японского подхода к формообразованию, что дает сочетание архаики, ремесленных практик с инновациями и технологиями, почему будущее одежды — это ее прошлое, чем японский подход к деконструкции отличается от европейского, что объединяет и что различает принципы стилеообразования Рей Кавакубо, Иссея Мияке, Йоджи Ямомото, молодых японских брендов — основные, но не все вопросы, что будут обсуждаться в цикле лекций."
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={{ ...styles.p, textAlign: "justify" }}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            <strong>Первая лекция</strong> цикла посвящена основе современного
            японского подхода к дизайну костюма — культуре кимоно как
            традиционного отношения к телу и к одежде, как символической
            взаимосвязи содержания и основных элементов формы: крой, цвет и
            рисунок, центр композиции.
          </>,
          <>
            <strong>Вторая лекция</strong> цикла посвящена основным чертам
            традиционной японской философии и эстетики, поскольку без понимания
            того, что такое красота и гармония в японской художественной
            системе, каковы основные принципы организации пространства (в
            интерьере, костюме) трудно правильно расставить ориентиры в анализе
            современных коллекций японских дизайнеров.
          </>,
          <>
            <strong>Третья лекция</strong> цикла посвящена ключевым
            характеристикам японского fashion-сознания 70-х и 80-х гг. XXв.
            Особое внимание будет уделено методу деконструктивизма как
            важнейшему принципу проектирования костюма, имеющего
            непосредственную связь с архитектурой деконструктивизма. Для
            пояснения этой аналогии будут рассмотрены суть и принципы
            деконструктивизма в искусстве и дизайне.
          </>,
          <>
            <strong>Четвертая лекция</strong> цикла продолжает разговор о
            принципах и приемах деконструктивизма как метода проектирования
            современного костюма на примере японских дизайнеров. Кроме того,
            будут даны ключевые характеристики японского fashion-сознания
            90-хгг.XX века. Итогом лекции станет начало разговора о философском
            и художественном аспекте творчества Yohji Yamamoto.
          </>,
          <>
            <strong>Пятая лекция</strong> цикла посвящена философским и
            художественным аспектам творчества Yohji Yamamoto, с одной стороны,
            объединяющих его с японским и европейским подходом к созданию
            концепт-образов, с другой стороны — выделяющих его из общей системы,
            отображением его особого, узнаваемого авторского почерка.
          </>,
          <>
            <strong>Шестая лекция</strong> цикла посвящена особенностям
            художественно-проективного метода творчества Issey Miyake: поиск
            многофункциональной ткани, эксперименты с преобразованием
            двухмерности, «метаморфозы» традиционных ручных техник, авторское
            решение проблемы соединения концепт-идей haute couture и
            prêt-a-porter.
          </>,
          <>
            <strong>Седьмая лекция</strong> цикла продолжает разговор об
            особенностях художественно-проективного метода творчества Issey
            Miyake: признаки концептуальности бренда, связь тела, одежды и
            архитектуры, применение новых технологий в создании ткани, проект «A
            Piece of Cloth» и концепция одежды будущего.
          </>,
          <>
            <strong>Восьмая лекция</strong> цикла посвящена Rei Kawakubo как
            представительнице интеллектуального дизайна, во многом определившей
            коды современной моды и оказавшей влияние даже на те бренды, что
            работают в иной плоскости, чем концептуальный дизайн. Творчество Rei
            Kawakubo будет рассмотрено через оппозицию и диалог с европейской
            традицией проектирования одежды и взаимоотношения одежды тела, через
            концептуальный подход, через социокультурные авторские декларации,
            переведенные на язык костюма.
          </>,
          <>
            <strong>Девятая лекция</strong> цикла — продолжение обсуждения
            творчества Rei Kawakubo как представительнице интеллектуального
            дизайна, во многом определившей коды современной моды. Творчество
            Rei Kawakubo будет рассмотрено через оппозицию и диалог с
            европейской традицией проектирования одежды и взаимоотношения одежды
            тела, через концептуальный подход, через социокультурные авторские
            декларации, переведенные на язык костюма.
          </>,
          <>
            <strong>Десятая лекция</strong> цикла посвящена содержательным и
            проективным принципам дизайна костюма молодых японских брендов —
            Undercover, Anrealage, Attachment, Final Houm как развитие, диалог и
            полемика с традиционными принципами японского и европейского
            дизайна, как визуальная демонстрация событий настоящего и прогноза
            на будущее.
          </>
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  pay: false
};
