import React from "react";
import List from "../../components/list";
import { Link } from "react-router-dom";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import Video from "../../components/video";
import {
  shabalina2,
  migranov2,
  moatti,
  zolotova,
  gorskih2
} from "../../contents/teachers";
import { styles } from "./styles";

export const contents = {
  titlePage: "Консультант по стилю. Быстрый старт",
  headNew: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Консультант по стилю <br />
        Быстрый старт{" "}
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },

    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        В этом курсе мы собрали самые нужные знания, чтобы вы могли учиться с
        нуля, в любом возрасте, в любом городе, получая нужный результат: для
        себя, для карьеры дипломированного стилиста, для бизнеса.
      </div>
    ),
    descript: [
      "Дипломы установленного госу­дар­ством образца",
      "Траектория обучения: 4 месяца",
      "Формат: очно и онлайн. Практики",
      "Стоимость от 79,8 тыс.руб.",
      "Доступны рассрочки, скидки",
      "Подготовительный курс без оплаты",
      "Налоговый вычет после обучения"
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "+79150342020",
        name: "Иван",
        tg: false, // "Ksenyastyle",
        wa: "79150342020"
      }
    ],
    imageSource: "/img/style-your-life/style-your-life.webp"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        <>очно / дистанционно </>,
        <>
          с{" "}
          {" " +
            dates.styleYourLife.ofline.getDate() +
            " " +
            Month(dates.styleYourLife.ofline.getMonth())}{" "}
        </>
      ]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции, </>,
        <>чат в группе, очная практика </>
      ]
    },
    {
      title: "Эксперты",
      text: [
        <>Российские и зарубежные практики— </>,
        <>стилисты, fashion эксперты </>
      ]
    }
  ],
  text3blockWImgs: [
    {
      img: {
        src: "/img/style-your-life/personal32.jpg",
        alt: "Персональный стилист"
      },
      title: "Персональный стилист",
      text: [
        <>
          {" "}
          Вы можете заниматься имидж - консультированием и шоппинг -
          сопровождением из любой точки мира, очно или онлайн{" "}
        </>
      ]
    },
    {
      img: {
        src: "/img/style-your-life/clients32.jpg",
        alt: "Клиентский сервис"
      },
      title: "Клиентский сервис",
      text: [
        <>
          Вы уже работаете с клиентами— обучаете персонал, расширяйте свои
          услуги, улучшайте сервис и свое позиционирование{" "}
        </>
      ]
    },
    {
      img: {
        src: "/img/style-your-life/media32.jpg",
        alt: "Стилист в медиапространстве"
      },
      title: "Стилист в медиапространстве",
      text: [
        <>
          Работайте со стилем персон для фото и видео съемок, популярных
          программ, онлайн каналов и социальных сетей Программа курса{" "}
        </>
      ]
    }
  ],
  text: {
    text: (
      <>
        <p className="constructor-p">
          Цель курса «Консультант по стилю» — дать необходимую систему и навыки
          для создания индивидуального стиля клиентов, научить принципам
          создания имиджевого фото контента и продвижения личного бренда в
          социальных сетях.
        </p>{" "}
        <p className="constructor-p">
          Каждой теме уделяется необходимое количество времени, чтобы закрепить
          навыки и получить результат.{" "}
        </p>{" "}
        <p className="constructor-p">
          Программа обучения рассчитана на 3, 5 месяца{" "}
        </p>{" "}
      </>
    ),
    tags: false
  },
  text1: (
    <>
      <p className="p-constructor">
        Обучение по программе «Консультант по стилю» по государственному гранту.
      </p>
      <p className="p-constructor">
        Обучение реализуется в онлайн формате с живым общением с преподавателями
        на базе Национального исследовательского Томского государственного
        университета совместно с АНО ДПО Высшая школа стилистики, дизайна и
        технологий.
      </p>
      <p>
        <Link to="/style-consultant-tgu">Подробнее…</Link>
      </p>
    </>
  ),
  text1Media: (
    <>
      <div>
        <a href="/style-consultant-tgu" target="_blank">
          <img
            src="/img/second-degree-imagemaker/tgu-education.png"
            alt="Консультант по стилю. Бесплатное обучение по гос гранту"
            style={{ border: "1px solid black" }}
          />
        </a>
      </div>
    </>
  ),
  text2: {
    media: (
      <>
        <div>
          <a href="/style-consultant-tgu" target="_blank">
            <img
              src="/img/second-degree-imagemaker/tgu-education.png"
              alt="Консультант по стилю. Бесплатное обучение по гос гранту"
              style={{ border: "1px solid black" }}
            />
          </a>
        </div>{" "}
      </>
    )
  },
  schedule: (
    <>
      <p>группа 2.06 (онлайн, будни утро/вечер), с 14.04.22, набор открыт</p>{" "}
    </>
  ),
  screen2: {
    text: (
      <List
        arr={[
          <>
            Цель курса «Консультант по стилю» — дать необ&shy;ходи&shy;мую
            сис&shy;тему и на&shy;вы&shy;ки для соз&shy;дания
            ин&shy;диви&shy;дуаль&shy;ного сти&shy;ля клиен&shy;тов, научить
            прин&shy;ци&shy;пам соз&shy;да&shy;ния имид&shy;же&shy;вого фото
            кон&shy;тен&shy;та и про&shy;дви&shy;жения лич&shy;ного брен&shy;да
            в со&shy;циаль&shy;ных сетях.
          </>,
          <>
            Вы будете под&shy;бирать одежду и аксес&shy;суары по
            оп&shy;реде&shy;лен&shy;ной сис&shy;теме, что помо&shy;жет
            рас&shy;кры&shy;вать ха&shy;рак&shy;тер и инди&shy;видуаль&shy;ность
            ок&shy;ружаю&shy;щих, соз&shy;давать клас&shy;сичес&shy;кие или
            креатив&shy;ные об&shy;разы, де&shy;лать мир пре&shy;крас&shy;нее,
            доби&shy;вать&shy;ся жизнен&shy;ных и карьер&shy;ных целей.
          </>
        ]}
        num="none"
        // style={{ paddingBottom: ".3rem" }}
        classLi="p-constructor"
        olStyle={{ paddingLeft: 0 }}
      />
    ),
    video: <Video link="fOZzI3bKXIU" />
  },
  graduates: {
    text: (
      <>
        <p>Татьяна Баталова</p>{" "}
        <p> Куратор: преподаватель ВШСДТ, Светлана Шабалина </p>{" "}
      </>
    ),
    image: (
      <img src="/img/style-your-life/batalova.jpg" alt="Татьяна Баталова" />
    )
  },
  practic: {
    text: (
      <>
        <h3 className="h3-constructor">Практика шопинга</h3>{" "}
        <p className="p-constructor">
          В курсе предусмотрено 6 практик шопинг - сопровождения.На каждом
          занятии Вы отрабатываете подбор одежды под определенную
          ситуацию(запрос клиента), бюджет.{" "}
        </p>{" "}
      </>
    ),
    image: <img src="/img/style-your-life/practic.jpg" alt="Практика шопинга" />
  },
  photosession: {
    text: (
      <>
        <h3 className="h3-constructor">Фотосессия</h3>{" "}
        <p className="p-constructor">
          В конце обучения предусмотрена очная 3 - х дневная практика имиджевой
          съемки для продвижения стилиста в социальных сетях: от концепции до
          подбора одежды, съемки.{" "}
        </p>{" "}
      </>
    ),
    image: <img src="/img/style-your-life/photosession.jpg" alt="Фотосессия" />
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Основание для обучения на курсе — тренд на визуализацию личного бренда и
        правильное имидж позиционирование. Этот тренд стремительно захватывает
        все области жизни. Сегодня как никогда ранее тот, кто профессионально
        владеет нужными креа­тив­ными навыками формирования имид­жа и
        продвижения, можете найти своих клиентов и реализовать свои планы в
        карьере и бизнесе.
      </p>,
      <p>
        <strong>Personal Styling. Индивидуальный стиль от А до Я</strong>
        <br />
        (36 занятий, 72 академических часа)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Концепция стилевых направлений как система гармонии внутреннего и внешнего",
          "Формирование стиля на основе индивидуальных особенностей в лице, фигуре, характере",
          "Типологии фигур, возможности и способы визуальной коррекции фигур",
          "Подбор индивидуальной цветовой палитры, кроя, фактур, рисунков",
          "Аксессуары, правила и способы подбора с учетом fashiontrends",
          "Типы современного гардероба, формирование рационального гардероба",
          "Особенности работы со стилем мужчин",
          "Принципы формирования модного образа с учетом актуальных трендов",
          "Навыки анализа качества одежды. Какие вещи / бренды выбирать",
          "Анализ: масс-маркет vs. люкс. Принципы планирования бюджета.",
          "ДНК марок и тренды «для себя», «для клиента». Сайты для лучшего онлайн шопинга",
          "Анализ: как понимать качество приобретаемых вещей в Online",
          "Основы материаловедения и принципы ухода за одеждой. Новые ткани и фактуры",
          "Как и где отслеживать новинки моды",
          "Планирование и практика шопинга.",
          "Практика создания Style Book (персонального, клиентского)"
        ]}
      />,

      <p>
        <strong>Fashion trends. Главные тенденции моды ХХ и XXI века</strong>
        <br />
        (20 занятий, 40 академических часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>История моды ХХ века как источник вдохновения</>,
          <>Мода как форма современного искусства. Образный язык моды:</>,
          <>Эстетика провокации в моде. Мода и стили субкультур</>,
          <>Демократизация моды. Взаимодействие высокой и уличной моды</>,
          <>Полистилизм и стилевые прототипы</>,
          <>Приемы и цель использования искусства в создании модного образа</>,
          <>Основные долгосрочные тенденции индустрии моды:</>,
          <>Ugly-fashion и normcore как проявление индвидуальности. </>,
          <>Причины и суть постоянства этники и эко-тренда в коллекциях</>,
          <>Философия тренда разумного потребления. Slow-fashion, ресайклинг</>,
          <>Тренд естественности, простоты, универсальности форм</>,
          <>Структура индустрии и ключевые каналы коммуникации:</>,
          <>
            Корпорации: управление и финансы. Роль дизайнера. Иерархия внутри
            системы
          </>,
          <>Модные показы. Недели моды. Haute couture и prêt-a-porter.</>,
          <>Прогнозирование и важнейшие каналы распространения моды.</>,
          <>Digital-коммуникации, изменившие моду</>
        ]}
      />,
      <p>
        <strong>Принципы продвижения личного бренда</strong>
        <br />
        (8 занятий, 16 академических часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Где будет успешен fashion консультант, стилист",
          "Разработка стратегии продвижения личного бренда",
          "Упаковка личного бренда",
          "Формирование портфолио стилиста",
          "Принципы реализации фотосъемок (имиджевого контента для соц. сетей)",
          "Основы продвижения персонального бренда в Instagram"
        ]}
      />,
      <p>
        <strong>Дополнительно в программе:</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Очная практика шопинга (6 занятий, 18 академических часов)</>,
          <>Workshop — реализация фотосессии (3 дня, 24 академических часа)</>,
          <>
            Предпроектные консультации и мастер-классы (3 занятия, 6
            академических часов)
          </>,
          <>
            Итого: 73 занятия по 2 академ.часа + 3 дня workshop +
            самостоятельная работа (254 академичесиких часа в дипломе)
          </>
        ]}
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Курс дает системные и практические знания для старта карь&shy;еры в
        индус&shy;трии моды. После обу&shy;че&shy;ния Вы полу&shy;чите диплом
        уста&shy;новлен&shy;ного госу&shy;­дар&shy;­ством образца о
        про&shy;фес&shy;сиональ&shy;ной пере&shy;под&shy;готов&shy;ке по
        про&shy;грам&shy;ме «Консультант по имиджу и стилю» с правом
        деятель&shy;ности в изу&shy;чен&shy;ной профес&shy;сио&shy;наль&shy;ной
        области.
      </p>
    ),
    image: (
      <img
        src="/img/sdi/diplom-2.jpg"
        alt="Диплом ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  howIsTheTrainingGoing: [
    {
      title: "Лекции",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;чаете теорию. Лекции ста&shy;новятся
          доступны по мере про­хождения группой курса. Обучение движется в
          ком­форт­ном, рабочем рит&shy;ме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основным темам курса Вы получаете домашние задания. Это важная
          часть обучения, в результате ко&shy;то&shy;рой формируется итоговая
          проект­ная работа.
        </>
      )
    },
    {
      title: "Практика",
      text: (
        <>
          Каждую неделю — живое общение с груп­пой и пре­подавате­лем.
          Преподава­тель прове­ряет задания, находит ошибки, ком­мен­тирует,
          помо­гает, напра&shy;вляет.
        </>
      )
    },
    {
      title: "Практика + Книга стиля",
      text: (
        <>
          В итоге обучения Вы созда&shy;ете презентацию пер&shy;сональ&shy;ного
          стиля (Книгу стиля — stylebook), прово­дите шо&shy;пинг, реали­зуете
          фото&shy;сес&shy;сию для Instagram
        </>
      )
    }
  ],
  additionally: (
    <>
      <p>Практика шопинга (6 занятий, 16 академических часов)</p>{" "}
      <p> Workshop— реализация фотосессии(3 дня, 24 академических часа) </p>{" "}
      <p>
        <strong>Итого</strong>{" "}
      </p>{" "}
      <p>
        60 занятий + 3 дня workshop + самостоятельная работа(254 академичесиких
        часа в дипломе){" "}
      </p>{" "}
    </>
  ),
  formats: {
    head: "Дополнительная информация",
    content: [
      <Spoler
        title={<>Формат обучения: Очно-заочное обучение</>}
        text={
          <>
            <p>
              Очно-заочное обучение реализуется с частичным применением
              дистанционных технологий (некоторые преподаватели с необходимыми
              компетенциями живут в других городах, в т.ч. в Европе и США). В
              целом на очное обучение приходиться 2/3 занятий от заявленной
              программы. На данном курсе в очном формате доступно больше практик
              на проектах, в том числе шопинг практики.
            </p>
            <p>
              <strong>Группы: выходного дня.</strong>
            </p>
            <p>
              В процессе обучения: лекционные и практические занятия в
              аудиториях, практики в ТЦ, шоу-румах, фотосессиях, самостоятельная
              работа.
            </p>
            <p>
              Пропущенные лекционные занятия студенты очного отделения могут
              посмотреть в записи. После обучения все лекционные занятия по
              программе курса будут доступны в записи в личном кабинете в
              течение 6 месяцев.
            </p>
          </>
        }
      />,
      <Spoler
        title={<>Формат обучения: Заочное обучение</>}
        text={
          <>
            <p>
              Сочетание полноценного обучения в онлайн формате по всем темам
              лекционных и практических занятий с еженедельными очными
              практиками в Вашем городе /регионе (ТЦ, шоу-румы, фотосъемки),
              самостоятельная работа.
            </p>
            <p>Группы: вечерние, выходного дня</p>
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />,
      <Spoler
        title="Альтернативные программы: 4 варианта"
        text={
          <>
            <List
              arr={[
                <>
                  <Link to="/second-degree-imagemaker">
                    Профессия: стилист-имиджмейкер как второе высшее
                  </Link>{" "}
                  (диплом), 12 месяцев
                </>,
                <>
                  <Link to="/fashion-styling">
                    Профессия: стилист фотосессий
                  </Link>{" "}
                  (повышение квалификации), 4 месяца
                </>,
                <>
                  <Link to="/style-consultant-tgu">
                    Консультант по стилю. Обучение оплачивает государство
                  </Link>{" "}
                  (повышение квалификации), 2.5 месяца
                </>,
                <>
                  <Link to="/self-style">Сам себе стилист</Link> (базовый курс),
                  1 месяц
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />{" "}
          </>
        }
      />,
      <Spoler
        title="Личный кабинет"
        text={
          <>
            <p className="p-constructor">
              {" "}
              Все обучающиеся получают доступ в личный кабинет с текущей
              информацией по обучению, доступом к материалам лекций. В удобное
              время вы сможете просматривать занятия и выполнять домашние
              задания. Главное — соблюдать график учебного процесса в целом. Вы
              сможете оценить работы других студентов и задать вопрос куратору
              курса, а также воспользоваться поддержкой специалистов учебного
              отдела.
            </p>{" "}
            <p className="p-constructor">
              Студенты так же получают доступ в закрытую группу(чат), где
              общаются с одногруппниками{" "}
            </p>{" "}
          </>
        }
      />,
      <Spoler
        title="Где доступно обучение"
        text={
          <>
            <p className="p-constructor">Очное обучение доступно в Москве</p>
            <p className="p-constructor">
              Заочное обучение (очно + онлайн) доступно в Москве,{" "}
              <a href="http://novosib.styleschool.ru/">Новосибирске</a>,{" "}
              <Link to="/cannes">Каннах (Франция)</Link>
            </p>
            <p className="p-constructor">
              Дистанционное обучение(живое преподавание онлайн) доступно по
              всему миру.
            </p>
            <p className="p-constructor">
              После обучения практикующие выпускники могут{" "}
              <Link to="/stylist-association">вступить в ассоциацию ВШСДТ</Link>
              для поддержки в развитии карьеры и бизнеса.{" "}
            </p>
          </>
        }
      />
    ]
  },
  miniFormHead:
    "Запишитесь на курс или получите консультацию, отправьте сообщение",
  message: {
    head: (
      <>
        Тест - драйв: первые две недели обучения <br /> +консультация стилиста =
        900 руб. <br />
        Как это работает ?
      </>
    ),
    text: (
      <>
        Зарегистрируйтесь или напишите нам.Получи́те консультацию специалиста
        учебного отдела.Потом оплати́те тест драйв. <br />
        Вы получите полноценный доступ к обучению на две недели и консультацию
        стилиста .100 % гарантия возврата: мы вернем Вам деньги, если Вы решите
        отказаться от услуги в течение двух недель с момента оплаты{" "}
      </>
    )
  },
  bot: {
    image: <img src="/img/other/open_lesson.jpg" alt="Бот Валерия" />,
    text: (
      <>
        <p className="p-constructor">
          <strong>Телеграмм группа открытого образования ВШСДТ</strong>
        </p>
        <p className="p-constructor">
          В группе Открытого образования публикуем информацию об открытых курсах
          и мероприятиях, или социальных проектах
        </p>
        <p className="p-constructor">
          Здесь Вы узнаете, когда и как большую часть материалов можно изучить
          без оплат и условий
        </p>
        <a href="https://t.me/+Sfmarwgq2CtCji81">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  price: {
    title: "Стоимость обучения",
    installment: <> 6650 ₽</>,
    full: (
      <>
        79 800 ₽<br />
        <span style={styles.grayText}>
          Доступна рассрочка от 4 до 12 месяцев
        </span>
      </>
    ),
    discount: false, // <>11 940 &#8381;</>, // со скидкой
    discountSize: <> 20 % </>,
    discountDuration: "при записи 1+1",
    qrLink: "https://link.tinkoff.ru/7OMvu5oxnq1",
    durationInstallment: "12",
    textPercent:
      "Производиться единовременно со скидкой 10% или в рассрочку от 4 до 12 месяцев",
    bankText:
      "Мы сотрудничаем с Банком Тинькофф при предоставлении рассрочки от 6 до 12 месяцев без первого взноса, без переплат, доступно для граждан РФ.",
    noButtonInstallment: true,
    noPartners: true,
    noButtons: true
  },
  teachers: [shabalina2, moatti, zolotova, gorskih2, migranov2]
};
