import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { stylesLite } from "./styles";
import { Link } from "react-router-dom";
import Paragraph from "./p-array";
import List from "./list";
import SocNetIcons from "./socnet-icons";

const wStyles = theme => stylesLite;
const font = "Alegreya Sans";
const fontTitle = "PT Sans Narrow";

function Footer(props) {
  const { classes } = props;

  return (
    <div className={classes.footer}>
      <div
        style={{
          maxWidth: 1000,
          margin: "0 auto"
        }}
      >
        <div className={classes.geo}>
          <p className={classes.footerTitle} style={{ fontFamily: fontTitle }}>
            ГЕОГРАФИЯ
          </p>
          <Paragraph
            style={stylesLite.footerText}
            classBox={classes.geoItem}
            content={[
              <a href="https://styleschool.ru/">
                <span
                  className={classes.footerText}
                  style={{ fontFamily: font }}
                >
                  Москва
                </span>
              </a>,
              <a href="http://novosib.styleschool.ru/">
                <span
                  className={classes.footerText}
                  style={{ fontFamily: font }}
                >
                  Новосибирск
                </span>
              </a>,
              <Link to="/cannes">
                <span
                  className={classes.footerText}
                  style={{ fontFamily: font }}
                >
                  Франция
                </span>
              </Link>,
              <span className={classes.footerText} style={{ fontFamily: font }}>
                Online
              </span>
            ]}
          />
        </div>
        <div className={classes.adress}>
          <p className={classes.footerTitle} style={{ fontFamily: fontTitle }}>
            АДРЕС &bull;{" "}
            {props.adress ? (
              <span style={{ textTransform: "uppercase" }}>{props.filial}</span>
            ) : (
              "МОСКВА"
            )}
          </p>
          {props.adress ? (
            <p className={classes.footerText} style={{ fontFamily: font }}>
              {props.adress}
            </p>
          ) : (
            <>
              <p className={classes.footerText} style={{ fontFamily: font }}>
                г. Москва, Проспект Мира,
                <br />
                дом&nbsp;101, стр.&nbsp;1, офис&nbsp;210
              </p>
              <p className={classes.footerText} style={{ fontFamily: font }}>
                Для почтовых отправлений:
                <br />
                а/я&nbsp;218
              </p>
            </>
          )}
        </div>
        <div className={classes.time}>
          <p className={classes.footerTitle} style={{ fontFamily: fontTitle }}>
            ВРЕМЯ РАБОТЫ
          </p>
          <p className={classes.footerText} style={{ fontFamily: font }}>
            {props.filial || "Москва"}
          </p>
          <p className={classes.footerText} style={{ fontFamily: font }}>
            Пон.-пят.
          </p>
          <p className={classes.footerText} style={{ fontFamily: font }}>
            с 10:00 до 19:00
          </p>
          <p className={classes.footerText} style={{ fontFamily: font }}>
            Суб. с 11:00 до 14:00
          </p>
        </div>
        <div className={classes.contacts}>
          <p className={classes.footerTitle} style={{ fontFamily: fontTitle }}>
            КОНТАКТЫ
          </p>
          {props.phones ? (
            <p className={classes.footerText} style={{ fontFamily: font }}>
              {props.phones}
            </p>
          ) : (
            <>
              <p className={classes.footerText} style={{ fontFamily: font }}>
                +7 (495) 221-89-35
              </p>
            </>
          )}
          <div className={classes.footerText}>
            <Link to="/contacts">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                Все контакты
              </span>
            </Link>
          </div>
        </div>
        <div className={classes.services}>
          <p className={classes.footerTitle} style={{ fontFamily: fontTitle }}>
            СОТРУДНИЧЕСТВО
          </p>
          <List
            style={{ ...stylesLite.footerText, fontFamily: font }}
            olStyle={{ paddingLeft: 10 }}
            arr={[
              <Link to="/partners-pro">
                <span
                  className={classes.footerLink}
                  style={{ fontFamily: font }}
                >
                  Партнерская программа
                </span>
              </Link>,
              <Link to="/network-educational-programs">
                <span
                  className={classes.footerLink}
                  style={{ fontFamily: font }}
                >
                  Сетевые программы
                </span>
              </Link>,
              <Link to="/corporate">
                <span
                  className={classes.footerLink}
                  style={{ fontFamily: font }}
                >
                  Корпоративные программы
                </span>
              </Link>,
              /*
              <Link to="/stylist-association">
                <span
                  className={classes.footerLink}
                  style={{ fontFamily: font }}
                >
                  ВШСДТ.Ассоциация
                </span>
              </Link>
              */

              <Link to="/vacancy">
                <span
                  className={classes.footerLink}
                  style={{ fontFamily: font }}
                >
                  Вакансии
                </span>
              </Link>
            ]}
          />
        </div>
      </div>
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        style={{
          maxWidth: 1000,
          margin: "0 auto"
        }}
      >
        {/* <Grid item xs={12} md={12}>
          <SocNetIcons />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <div
            style={{
              height: 1,
              background: "rgb(140,140,140)",
              maxWidth: 1000,
              padding: 0,
              margin: "2rem 0 1rem"
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SocNetIcons />
        </Grid>
        <Grid item xs={12} md={6}>
          <p
            className={classes.footerText}
            style={{ fontFamily: font, lineHeight: "1.3rem" }}
          >
            Автономная некоммерческая организация
            <br />
            Дополнительного профессионального образования
            <br />
            «Высшая школа стилистики, дизайна и технологий»
          </p>
          <div style={{ height: "1rem" }} />
          <Link to="/information-about-educational-organization">
            <span className={classes.footerLink} style={{ fontFamily: font }}>
              Сведения об образовательной организации
            </span>
          </Link>
          <br />
          <Link to="/information">
            <span className={classes.footerLink} style={{ fontFamily: font }}>
              Информация для пользователей
            </span>
          </Link>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12} style={{ height: "2rem" }} />
        </Hidden>
        <Grid item xs={12} md={6}>
          <p className={classes.footerGold} style={{ fontFamily: font }}>
            Партнёры:
            <br />
            &bull;&emsp;Московский физико-технический институт
            <br />
            &bull;&emsp;Московский художественно-промышленный институт
            <br />
            &bull;&emsp;Московский психолого-социальный университет
            <br />
            &bull;&emsp;Иновационно-образовательный комплекс «Техноград»
            <br />
            &bull;&emsp;European Model Academy
          </p>
        </Grid>
        <Grid item xs={12} md={12} style={{ height: "2rem" }} />
        <Grid item xs={12} md={12}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontFamily: font,
              color: "rgb(160,160,160)"
            }}
          >
            ТЕГИ:&emsp;
            <Link to="/">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #ВШСДТ
              </span>
            </Link>
            &emsp;
            <Link to="/second-degree-imagemaker">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #имиджмейкерстилист
              </span>
            </Link>
            &emsp;
            <Link to="/imagemaker-stylist">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #курсыстилистовимиджмейкеров
              </span>
            </Link>
            &emsp;
            <Link to="/interior-design-courses">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #курсыдизайнинтерьера
              </span>
            </Link>
            &emsp;
            <Link to="/living-interior-design-and-decoration">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #дизайнидекорированиеинтереьера
              </span>
            </Link>
            &emsp;
            <Link to="/visual-merchandising-and-showcases-design">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #визуальныймерчендайзинг
              </span>
            </Link>
            &emsp; &emsp;
            <Link to="/business-analitic">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #бизнесаналитик
              </span>
            </Link>
            &emsp; &emsp;
            <Link to="/bfashionsprint">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #fashionnovation
              </span>
            </Link>
            &emsp; &emsp;
            <Link to="/chatbot">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #conversationai
              </span>
            </Link>
            &emsp;
            <Link to="/price-list">
              <span className={classes.footerLink} style={{ fontFamily: font }}>
                #прайслист
              </span>
            </Link>
          </p>
        </Grid>
        <Grid item xs={12} md={12}>
          <p
            style={{
              fontFamily: font,
              fontSize: "0.9rem",
              color: "rgb(160,160,160)",
              padding: "1rem 0",
              margin: 0,
              textAlign: "center"
            }}
          >
            &copy;&emsp;ВШСДТ 2011-2021 Российское академическое онлайн
            образование.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(wStyles)(Footer);
