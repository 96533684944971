import React from "react";
import "./style.css";

function Button(props) {
  return (
    <div className="button-free-lessons">
      <p className="button-text">Пробные лекции</p>
      <p className="button-text">Записаться</p>
    </div>
  );
}

export default Button;
