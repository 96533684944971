import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import {
  HeadBlock,
  Calendar,
  Teachers,
  Programma,
  TextAndTags,
  SocnetBlock,
  Price,
  DatesBlock, //screen2
  BuilderLayout
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <>
      <Helmet>
        <title>Обучение основам motion design и анимации</title>
        <meta
          name="keywords"
          content="motion design, анимация, повышение квалификации, обучение, учиться, где, профессия, курсы, школа"
        />
        <meta
          name="descript"
          content="Креативные анимационные ролики с &quot;нуля&quot;: от идеи до готовой работы. Вы научитесь разбираться в основах дизайна и анимации. Изучите четыре современных инструмента для создания 2d анимации и графики. Научитесь создавать рекламные ролики и анимировать объекты. Освоите работу с кадром, научитесь готовить модели для анимации, применять спецэффекты в работе. После обучения Вы сможете самостоятельно делать рекламные графические ролики и тизеры, создавать анимационные видео с различными персонажами и объектами, изготавливать анимационный онлайн контент."
        />
      </Helmet>
      <Page lable="Моушн-дизайн" lableMobile="Моушн-дизайн">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Calendar content={c.dates} />,
            <TextAndTags
              content={{
                text: c.text1,
                tags: c.tags1
              }}
            />,
            <DatesBlock content={c.screen2} />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <>
              <Teachers
                content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
              />
              <Price content={{ pay: c.pay, countPay: 1 }} />
            </>,
            <SocnetBlock />
          ]}
        />
      </Page>
    </>
  );
};

export default Fashion;
