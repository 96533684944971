import React from "react";
import Link from "react-router-dom/Link";
import { news } from "../news/content";
import { DateToString } from "../shedule/dates";
import "./style.css";

export function OneNews(props) {
  const c = props.content;

  return (
    <div className="home-news-item">
      <div className="home-news-image">
        <img
          src={c.image}
          alt={c.title}
          style={{ maxWidth: 320, height: "auto" }}
        />
      </div>
      <div className="home-news-text">
        {c.project ? (
          <div>
            <h3>
              <strong>{c.title}</strong>
            </h3>
            {c.project}
          </div>
        ) : (
          <>
            {props.dateShow && <p>{DateToString(c.date)}</p>}
            {c.link ? (
              <a href={c.link} target="_blank">
                <h3>{c.title}</h3>
              </a>
            ) : (
              <Link to={`/news/${c.id}`}>
                <h3>{c.title}</h3>
              </Link>
            )}
            <p>{c.grayText}</p>
            {c.noLink ? null : c.link ? (
              <a href={c.link} target="_blank">
                {c.linkText || "Подробнее…"}
              </a>
            ) : (
              <Link to={`/news/${c.id}`}>читать далее…</Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function News(props) {
  let n, t;
  if (props.content) {
    n = props.content;
  } else {
    t = news.sort((a, b) => b.date.getTime() - a.date.getTime());
    n = t.slice(0, 4);
  }

  return (
    <div className="home-news-container">
      {props.noHead ? null : (
        <h2 style={{ ...props.headStyle }}>
          {props.head || "Новости ВШСДТ и партнеров"}
        </h2>
      )}
      {n.map(el => (
        <OneNews content={el} dateShow={props.dateShow} />
      ))}
      {props.content ? null : <Link to="/news">Все новости</Link>}
    </div>
  );
}
