import React from "react";
import Paragraph from "../../components/p-array";
import Grid from "@material-ui/core/Grid";
import ButtonVideo from "../../patterns/pattern-fs/button-video";
import Slides from "../../patterns/pattern-fs/slides";
import Socnet from "../../components/want-to-know";
import ButtonMessage from "../../components/button-messages";
import Video from "../../components/video";
import "./styles.css";
import "./style-grides.css";

const fontTitle = "PT Sans Narrow";

function Button(props) {
  return <div className="button-white-black">записаться</div>;
}

function Head(props) {
  return (
    <div className="twoblock-head">
      <h2 className="small">{props.content.headDescript}</h2>
      <h2
        className="h1-style"
        style={{ fontFamily: fontTitle, ...props.content.styleHead }}
      >
        {props.content.head}
      </h2>
      {props.content.headAfterDescript ? (
        <h2 className="small">{props.content.headAfterDescript}</h2>
      ) : null}
    </div>
  );
}

function Descript(props) {
  return (
    <div className="twoblock-descript">
      {props.content.descript && (
        <Paragraph
          classElement="p"
          style={{
            padding: "0.2rem 0",
            color: "rgb(100,100,100)"
          }}
          content={props.content.descript}
        />
      )}
      <div style={{ height: "2rem" }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ButtonMessage textLink={<Button />} />
        </Grid>
        {props.content.noVideo ? null : (
          <Grid item xs={12} md={4}>
            <ButtonVideo
              linkVideo={props.content.video ? props.content.video : false}
              slider={
                props.content.video ? (
                  false
                ) : (
                  <Slides videos={props.content.videos} />
                )
              }
              text="видео"
              sizeBig={props.content.sizeVideo}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

function MediaBlock(props) {
  return (
    <div className="twoblock-video" style={{ textAlign: "center" }}>
      {props.content.mediaVideo ? (
        <Video
          link={props.content.mediaVideo}
          image={props.content.imageSource}
          wMax={true}
          size="max"
        />
      ) : (
        <picture>
          {props.content.imageMobile && (
            <source
              srcset={props.content.imageMobile}
              media="(max-width: 950px)"
            />
          )}
          <img
            src={props.content.imageSource}
            alt=""
            style={{ maxWidth: 380 }}
          />
        </picture>
      )}
    </div>
  );
}

function ShessElement(props) {
  return (
    <div
      className={
        props.iLeft ? "twoblock-container-right" : "twoblock-container-left"
      }
    >
      <Head content={props.content} />
      <MediaBlock content={props.content} />
      <Descript content={props.content} />
    </div>
  );
}

function Layout(props) {
  const { contents } = props;
  let news = [],
    pastEvent = [];
  let actualTime = new Date();
  contents.news.map(el => {
    if (el.date.getTime() >= actualTime) {
      news.push(el);
    } else {
      pastEvent.push(el);
    }
  });
  news.sort((a, b) => a.date.getTime() - b.date.getTime());
  pastEvent.sort((a, b) => b.date.getTime() - a.date.getTime());
  return (
    <>
      <h1 className="h1-style m-t-b-double center">{contents.head.head}</h1>
      {news.map((el, key) => (
        <>
          <ShessElement iLeft={key % 2 ? true : false} content={el} />
          <div style={{ height: "8rem" }} />
        </>
      ))}
      <h1 className="h1-style m-t-b-double center">{contents.head2.head}</h1>
      {pastEvent.map((el, key) => (
        <>
          <ShessElement iLeft={key % 2 ? true : false} content={el} />
          <div style={{ height: "8rem" }} />
        </>
      ))}
      <Socnet noHead />
    </>
  );
}

export default Layout;
