import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import "./styles.css";

export const contents = {
  meta: {
    keywords: "личные инвестиции, стратегии, финансы",
    descript:
      "Курс о разнообразии и результатах использования различных финансовых инструментов. Подробные описания, шаги и — способы применения личных стратегий для получения дополнительной прибыли, накоплению и преумножению капитала"
  },
  head: {
    title: "Азбука инвестиций",
    headDescript: "Открытое образование",
    head: <>Первые инвестиции</>,
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      <>
        Инесса Трубецкова, финансовый консультант и успешный имидж-эксперт,
        преподаватель и соавтор серии популярных книг, специально для креативных
        специалистов делится основными инструментами и принципами в
        инвестировании, доступными способами получения доходности.
      </>
    ],
    headAfterDescript: false,
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватель"
      },
      {
        link: "#price",
        text: "безоплаты"
      }
    ],
    imageSource: "/img/yourinvest/yourinvest.jpg",
    messagesLinks: true
  },
  text3block: [
    {
      title: "Различные инструменты для инвестирования",
      text: false
    },
    {
      title: "Пошаговые действия для начала инвестирования",
      text: false
    },
    {
      title: "Понимание цели и разработка своей стратегии",
      text: false
    }
  ],
  screen2: {
    text: (
      <Paragraph
        content={[
          "Курс «Первые инвестиции»",
          "Автор: Инесса Трубецкова",
          <>
            1 урок * <b>ДЕНЬГИ</b>
          </>,
          "Откуда появились деньги",
          "Как деньги «работают»",
          "Что такое инвестиции"
        ]}
        classElement="p-constructor"
      />
    ),
    video: "fRHmrQj5O7M"
  },
  programma: {
    head: "Программа",
    content: [
      <p>
        Каждый урок рассказывает о разнообразии и результатах использования
        различных финансовых инструментов. Подробные описания, шаги и — способы
        применения личных стратегий для получения дополнительной прибыли,
        накоплению и преумножению капитала.
      </p>,
      <p>
        <strong>1 урок: ДЕНЬГИ</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Откуда появились деньги",
          "Как деньги «работают»",
          "Что такое инвестиции"
        ]}
      />,
      <p>
        <strong>2 урок: ИНВЕСТИЦИИ</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Финансовые инвестиции",
          "Банковские вклады",
          "Брокерские счета",
          "С чего начать",
          "3 шага для открытия брокерского счета"
        ]}
      />,
      <p>
        <strong>3 урок: НЕДВИЖИМОСТЬ</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Виды недвижимости",
          "Наследство, ипотека, кооператив",
          "3 шага для разработки стратегии"
        ]}
      />,
      <p>
        <strong>4 урок: АВТОМОБИЛИ</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Плюсы и минусы инвестирования",
          "Доходные автомобили",
          "3 шага для определения стратегии"
        ]}
      />,
      <p>
        <strong>5 урок: СТРАТЕГИИ</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "Определение активов и целей",
          "Выбор инвестиционных инструментов",
          "Составление плана долгосрочных стратегий"
        ]}
      />
    ]
  },
  teachers: {
    head: "Преподаватель",
    text: (
      <>
        <p className="p-constructor">
          <strong>Чему учиться у меня?</strong>
        </p>
        <p className="p-constructor">
          Меня зовут, Инесса Трубецкова и в уроках я рассказываю о своих шагах в
          инвестировании, инструментах и различных способах получения
          доходности. Мой многолетний опыт в приобретении недвижимости и работы
          с брокерскими счетами поможет не делать ошибок и прийти к намеченной
          Вами цели. За 5 бесплатных уроков Вы сможете понять как устроен рынок
          инвестиций, стать инвестором и попробовать некоторые инструменты.
        </p>
        <p className="p-constructor">
          Если после 5 уроков у Вас останутся вопросы, то я готова Вам помочь в
          них разобраться.
        </p>
        <p className="comment">
          * Не является финансовой консультацией (услугой)
        </p>
      </>
    ),
    image: (
      <img src="/img/master-class/new-24-10-2020.jpg" alt="Иннеса Трубецкова" />
    )
  },
  price: (
    <Paragraph
      content={[
        "Курс полностью в открытом доступе Вы можете смотреть на странице автора",
        "Уроки подходят для изучения и новичкам, тем кто уже знаком с инвестициями.",
        "Для изучения уроков Вам понадобится телефон, компьютер и доступ в интернет.",
        "Смотрите полную версию курса по ссылке",
        <a href="https://expertstyle.getcourse.ru/invest" target="_blank">
          <div className="button">Полная версия курса</div>
        </a>
      ]}
    />
  ),
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
