import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="История моды"
      title="Louis Vuitton: путешествия с роскошью"
      teacher="Руслан Мигранов"
      descript={<span>on-line</span>}
      online
      date="4 июля"
      time="19:00"
      duration=""
      image="/img/modern-fashion-style/lv.jpg"
      imageHeight
      text={
        <span>
          &laquo;Покажи мне твой багаж, и я скажу тебе, кто ты&raquo;. Этот
          рекламный слоган 1921 г. очень верно охватывает суть тех интимных
          взаимоотношений, которые складываются у каждого путешественника с
          чемоданами и кофрами &mdash; его спутниками и компаньонами.
          <br />
          На лекции мы поговорим о самых красивых и необычных изделиях Дома:
          cундук-кровать, сундук-гардероб, шкатулка для чая, несессер с
          туалетными принадлежностями, цирковой сундук, сундук-библиотека или
          ящичек для икры &mdash; столько невероятных вещей, за которыми
          угадываются захватывающие приключения их владельцев. От первых
          сундуков с выпуклой крышкой до последних моделей, изготовляемых в
          мастерской в Аньере в наши дни. Louis Vuitton всегда воодушевляла одна
          идея &mdash; увековечить свое мастерство и превратить искусство
          путешествовать в искусство жить!
        </span>
      }
      q
      part
      color="rose"
      id="p7GcwoeXxKy9doyZz"
      setBillingId={props.setBillingId}
    />
  );
}
