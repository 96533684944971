import React from "react";
import { withStyles } from "@material-ui/styles";

const Space = withStyles({
  space: {
    height: 0
  },
  "@media (max-width: 959px)": {
    space: {
      height: "3rem"
    }
  }
})(props => {
  const { classes } = props;
  return <div className={classes.space} />;
});

export default Space;
