import React from "react";
import { StringContacts } from "../../components/string-contact/string-contact";
import "./style.css";

export function Head(props) {
  const c = props.content;

  return (
    <div className="head">
      <div className="type">{c.type}</div>
      <h1>{c.head}</h1>
      <div className="descript">{c.descript}</div>
    </div>
  );
}

export function Image(props) {
  const c = props.content;

  return (
    <div className="image-head">
      {c.media ? (
        c.media
      ) : (
        <img src={c.src} alt={c.alt} style={{ ...c.style }} />
      )}
    </div>
  );
}

export function Text(props) {
  const c = props.content;

  return (
    <div className="text-cnt">
      {c.map(el => (
        <p>{el}</p>
      ))}
    </div>
  );
}

export function Tags(props) {
  const c = props.content;

  return (
    <div className="tags">
      {c.map(el => (
        <>
          <a href={el.link}>#{el.text}</a>
          &emsp;
        </>
      ))}
    </div>
  );
}

export function Other(props) {
  const c = props.content;

  return (
    <div className="other">
      <Tags content={c.tags} />
      <StringContacts contacts={c.contacts} inside />
    </div>
  );
}

export function HeadBlock(props) {
  const c = props.content;
  /**
   * head: type, head, descript
   * text: [p,p,p,…]
   * tags: [{link, text}, …]
   * contacts: []
   * image: src, alt, style | media
   */
  return (
    <div className="head-block">
      <Head content={c.head} />
      <Text content={c.text} />
      <Other
        content={{
          tags: c.tags,
          contacts: c.contacts
        }}
      />
      <Image content={c.image} />
    </div>
  );
}
