import React from "react";
import { Grid } from "@material-ui/core";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Title from "./title-meta";
import Header from "./header-links";
import Paragraph from "../../components/p-array";

const name = "Анна Назимова";

const styles = {
  h2: {
    color: "rgb(142,138,130)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "center",
    margin: 0,
    padding: "12px 0"
  }
};

export default function Nazimova() {
  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/nazimova1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              facebook="https://www.facebook.com/profile.php?id=1458328517801229"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <h2 style={styles.h2}>Достигла всего, чего хотела</h2>
          <Paragraph
            j
            content={[
              "Я по образованию классический музыкант, играю на виолончели. После окончания консерватории я работала по своей профессии в разных местах. В России мои ожидания от работы в финансовом плане не оправдались, поэтому я стала искать контракты за границей. Сейчас я работаю в Южной Корее. В своей профессии я достигла всего, что я хотела.",
              "В тот период мне захотелось в своей жизни чего-то нового и интересного. Так я начала задумываться о поиске новой профессии. Это должна была быть обязательно творческая профессия. Я совсем не представляю для себя работу в офисе, хотя имею опыт работы в разных сферах. В течение года я думала и не могла определиться, чем же все-таки я хочу заниматься.",
              <React.Fragment>
                В своем выборе я решила отталкиваться от своих способностей.
                Новая профессия должна была включать в себя креативное образное
                мышление, быть связанной с общением, с процессом создания, иметь
                возможность постоянного развития. Так, я просто задала запрос в
                поисковике &laquo;творческие профессии&raquo; и стала читать про
                все подряд.
              </React.Fragment>
            ]}
          />
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <img
                src="/img/graduates/nazimova2.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="/img/graduates/nazimova3.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
          <h2 style={styles.h2}>
            Стилист-имиджмейкер &mdash; для себя и для карьеры
          </h2>
          <Paragraph
            j
            content={[
              <React.Fragment>
                Я остановила свой выбор на профессии стилиста-имиджмейкера. Это
                было точно то, что мне нужно! Эта профессия отвечала всем моим
                запросам, даже больше. Плюс ко всему, мне нравилось, что эта
                профессия дает огромные возможности в развитии! Включает в себя
                предоставление индивидуальных услуг стилиста и образовательные
                программы. Огромнейший плюс &mdash; знания, которые я могу
                использовать каждый день для того, чтобы отлично выглядеть! Что
                еще нужно для молодой девушки?
              </React.Fragment>
            ]}
          />
          <h2 style={styles.h2}>
            Высшая Школа стилистики &mdash; удобный формат и большие возможности
          </h2>
          <Paragraph
            j
            content={[
              <React.Fragment>
                Я нашла Высшую Школу Стилистики через интернет. В первую
                очередь, мне подошла эта школа, потому что мне подходил только
                дистанционный формат обучения. Я находилась в другой стране
                &mdash; это было для меня очень удобно. Это был мой первый опыт
                дистанционного обучения, я долго сомневалась, как это вообще
                будет. Но в конце концов, я решилась, о чем ни разу не пожалела.
                Еще огромным преимуществом ВШС перед другими школами обучения
                моде и стилю, было предоставление по окончанию курса гос.
                диплома. Гос. диплом в наше время дает значительно большие
                возможности в будущем, чем просто сертификат.
              </React.Fragment>,
              "Изучив всю информацию, отзывы студентов и информацию о педагогах, я выбрала курс обучения на имиджмейкера – стилиста, как второе дополнительное образование. В курсе, кроме самой программы, мне очень понравилось изложение материала. Это взгляд на одну тему с позиции нескольких педагогов. Каждый педагог давал свой подход, знания дополняли друг друга и складывались в общую картину. Понравилось очень доступное изложение материала Марины Семаевой, авторский курс «Композиция создания образа» Татьяны Котенко, художественный взгляд на тему «Мода» Натальи Горских.",
              <div style={{ textAlign: "center" }}>
                <img
                  src="/img/graduates/nazimova4.jpg"
                  alt={name}
                  style={{ maxWidth: "100%" }}
                />
              </div>,
              <p style={{ textAlign: "center", padding: 8, margin: 0 }}>
                Моя статья о моде в журнале &laquo;Город женщин&raquo;
              </p>
            ]}
          />
          <h2 style={styles.h2}>Загадочный мир моды стал ближе</h2>
          <Paragraph
            j
            content={[
              "идеоуроки дополнялись подробными презентациями и рекомендациями. Очень понравилось, что раз в 1-2 недели было живое общение в онлайн-чате с педагогом, где можно было задать накопившиеся вопросы, а также послушать и посмотреть работы других студентов. Немного не хватило практики, ее всегда не хватает, хотелось бы глубже отработать приобретенные навыки и знания.",
              "ВШС дала мне не только возможность работать в новой профессии, но и открыла новый загадочный мир символов моды, который раньше казался недоступным."
            ]}
          />
          <h2 style={styles.h2}>Моя практика</h2>
          <Paragraph
            j
            content={[
              <React.Fragment>
                Курс Высшей школы стилистики вдохновил меня на продолжение
                обучения. Я закончила программу в итальянской школе Стиля и Моды
                &laquo;PR технологии в fashion бизнесе&raquo;, на данный момент
                обучаюсь в итальянской школе в Милане &mdash; &laquo;Fashion
                sketching: Дизайнерский рисунок&raquo;.
              </React.Fragment>,
              <React.Fragment>
                Все полученные знания я с удовольствием применяю на практике для
                себя и своих клиентов. Я пересмотрела свой гардероб, наконец-то
                определила для себя свой уникальный стиль. Сейчас я пишу статьи
                и веду проект &laquo;Личный стилист-имиджмейкер&raquo; для
                первого русского журнала в Южной Корее{" "}
                <a href="https://www.facebook.com/gorodW/">
                  &laquo;Город женщин&raquo;
                </a>
                , имею свою группу в Facebook{" "}
                <a href="https://www.facebook.com/uniquelookpro/">
                  &laquo;UNIQUE STYLE for you&raquo;
                </a>
                , работаю над созданием большого проекта &mdash; онлайн FASHION
                ACADEMY и имидж студии &laquo;UNIQUE STYLE&raquo;.
              </React.Fragment>,
              <React.Fragment>
                ВШС &mdash; это отличный START UP в профессии имидж-стилиста,
                где вы сможете получить все базовые знания!
              </React.Fragment>
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
