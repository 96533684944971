import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import {
  stulov,
  moatti,
  maltseva,
  migranov,
  bonetskaya
} from "../../contents/teachers";
import { dates, Month } from "../shedule/dates";
import Paragraph from "../../components/p-array";
import { Spoler } from "../../components/spoler/spoler";
import { Link } from "react-router-dom";

const online = `${dates.fashionStyling.online.getDate()}  ${Month(
  dates.fashionStyling.online.getMonth()
)}`;
/*
const ofline = `${dates.fashionStyling.ofline.getDate()} ${Month(
  dates.fashionStyling.ofline.getMonth()
)}`;
*/

export const contents = {
  head: {
    title: "Профессия: стилист фотоссесий, продюсер",
    headDescript: "Профессиональное образование",
    head: (
      <>
        Профессия:
        <br />
        стилист фотоссесий
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      "Очно, онлайн, индивидуально",
      "Удостоверение о повышении квалификации",
      "4.5 месяцев, 180 академических часов",
      <a href="#freeeducation">Открытые лекции и курсы</a>,
      "Налоговый вычет после обучения",
      "Лучшие фото проекты публикуем в СМИ"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Вы научитесь создавать образы и стилизовать фотосессии. Работать в
        команде и с командой, организовывать, бюджетировать. Для офлайн и онлайн
        журналов, fashion-брендов, рекламы, портфолио.
      </div>
    ),
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      }
    ],
    imageComment: "Работа преподавателей курса",
    imageSource: "/img/fashion-styling/ill-02.jpg"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>очно / дистанционно</>, <>с {online}</>]
    },
    {
      title: "Форматы",
      text: [<>Комбинированное очное-заочное,</>, <>дистанционное (онлайн)</>]
    },
    {
      title: "Эксперты",
      text: [
        <>российские и зарубежные практики —</>,
        <>стилисты, fashion эксперты</>
      ]
    }
  ],
  screen2: {
    dates: false,
    quote: false,
    text: (
      <List
        arr={[
          <>
            Курс ориентирован на дизайнеров, фотографов, визажистов, стилистов,
            парикмахеров и других специалистов, кто постоянно развивается и кому
            необходимо повысить свою квалификацию, освоить принципы и практику
            стилизации фотосессий
          </>,
          <>
            Цель курса — раскрыть Ваши таланты, сделать Вас проводником в мир
            осознанной красоты, чтобы Вы открывали окружающим прекрасное в
            повседневном, сближали мир реальный и мир, преображенный
            творчеством, несли потребителям послание модных брендов через
            визуальные образы. И правильно взаимодействовали в команде
            фотопроекта.
          </>
        ]}
        num="none"
        style={{ paddingBottom: "1rem" }}
      />
    ),
    video: "0s4k1IH3Pik" // "rYKZAsSnC84"
  },
  howIsTheTrainingGoing: [
    {
      title: "Лекции",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;ча­&shy;ете лек&shy;ции,
          по&shy;сле&shy;до&shy;ва&shy;тель&shy;но по мере
          про&shy;­хож&shy;­де&shy;ния груп&shy;­пой курса. Обу&shy;че&shy;­ние
          дви&shy;­жет&shy;ся в ра&shy;бо&shy;чем рит&shy;ме по
          рас&shy;писа&shy;нию.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние задания.
          Это важная часть обуче&shy;ния, в резуль&shy;тате кото&shy;рой
          форми&shy;руется итого&shy;вая проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;пода&shy;­вате&shy;лем. Препо&shy;­дава&shy;­тель лично
          прове&shy;­ряет зада&shy;­ния, находит ошибки, ком­мен&shy;­тирует и
          помо­гает
        </>
      )
    },
    {
      title: "Практика + Фотосессии",
      text: (
        <>
          В процессе обучения Вы позна&shy;коми&shy;тесь с прин&shy;ци&shy;пами
          про&shy;дюси&shy;рова&shy;ния и тремя под&shy;хо&shy;дами к
          сти&shy;ли&shy;за&shy;ции фото&shy;сес&shy;сий, реали&shy;зуете
          ми&shy;ни&shy;мум 3 фото&shy;сессии
        </>
      )
    }
  ],
  aboutProfeshion: {
    title: (
      <font style={{ fontSize: "2rem" }}>О Профессии стилиста фотосессий</font>
    ),
    short:
      "Стилист — это профессионал, чья деятельность связана с созданием и продвижением модного продукта посредством образных решений…",
    detail: [
      <span>
        Это может быть текст о коллекции или визуальный образ коллекции
        (стильные фотосессии), это может быть концепция показа коллекции или
        концепция индивидуального творческого образа.
        <br />
        Fashion-стилист должен уметь выполнять как техническую задачу — подбор
        одежды и аксессуаров с учетом концепции fashion-съемки, так и быть
        художником, интерпретирующим модную тенденцию, рассказывающим
        определенную историю через визуальные образы.
        <br />В современном мире коммуникаций fashion-стилист является важнейшим
        звеном, отвечающим за процесс обмена информацией между fashion-брендом и
        потребителем. Правильно понятая, удачно найденная и выраженная идея
        стилизации съемки становится ценностью, напрямую связанная, как с
        личными профессиональными качествами, так и с качествами fashion-бренда.
        Именно потому сегодня творческий профессиональный успех — это умение
        соединять творческие субъективные решения с объективными законами
        создания образа и с маркетинговой, рекламной стратегией.
      </span>
    ]
  },
  principes: [
    "Методики исследования и творческого эксперимента – эвристика, кастомайзинг, образно-ассоциативный подход, референсы и мудборды, нацеленные на развитие эстетического чувства, личного вкуса и визуальной культуры.",
    "Навыки разработки концепции стиля, определение элементов стилизации, опыт прочтения и интерпретации тенденций и идей через изучение моды XX века и тенденций современной моды, анализ ДНК брендов, законов и принципов композиции.",
    "Определение и применение принципов управления процессами создания fashion-съемок на основе изучения специфики проектов для рекламы, для глянца, для подиума, для fashion retail.",
    "Развитие организационных и коммуникационных навыков для осуществления коллабораций с единомышленниками, дизайнерами, фотографами, редакторами и блогерами, формирование портфолио для продвижения своих профессиональных творческих проектов"
  ],
  text1: (
    <>
      <p>
        Цель курса — раскрыть Ваши таланты, сделать Вас проводником в мир
        осознанной красоты, чтобы Вы открывали окружающим прекрасное в
        повседневном, сближали мир реальный и мир, преображенный творчеством,
        несли потребителям послание модных брендов через визуальные образы.
        Чтобы Вы занимались любимым делом и это помогало Вам в карьере и бизнесе
      </p>
      <p>
        Курс ориентирован на дизайнеров, фотографов, визажистов,
        стилистов-парикмахеров и других специалистов, кто постоянно развивается
        и кому необходимо повысить свою квалификацию, освоить принципы и
        практику стилизации фотосессий
      </p>
    </>
  ),
  schedule: (
    <>
      <p>
        группа 2.148-5 (очное-new обучение дневное / вечернее, вторник,
        четверг), с 14.02.22
      </p>
      <p>
        группа 2.149-5 поток 2 (онлайн обучение дневное / вечернее, вторник,
        четверг), с 14.02.22
      </p>
    </>
  ),
  programma: {
    head: "Программа курса",
    content: [
      <p>
        <strong>Вступительный отбор</strong>
      </p>,
      <p>
        <strong>Модуль 1.</strong>
        &emsp; Введение в профессию (2 недели, 20 академ.часов)
      </p>,
      <p>
        <strong>Основное обучение</strong>
      </p>,
      <p>
        <strong>Модуль 2.</strong>
        &emsp; Fashion-стилистика. Специализации стилиста (4 месяца, 160
        академ.часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Источники современной моды: история моды XX века",
          "Мода XXI века, направления и тренды: ДНК брендов, идеи коллекций",
          "Творческие методы в создании проектов. Методики стилизации. Ассоциации",
          "Творческие методы в создании проектов. Эвристика",
          "Fashion-стилистика. Законы и приемы композиции",
          "Основы проектной работы (реализации фотосессий). Организация, этапы проекта.",
          "Коммуникация в проектах, работа с командой",
          "Принципы реализации фотопроектов для портфолио. Проекты на условиях TFP",
          "Специфика работы стилиста с глянцем, рекламой, для подиума",
          "Практика оформления работ, формирования и презентации портфолио",
          "Практика реализации, подготовка фотопроекта: концепция, выбор модели, локации",
          "Практика проведения фотосъемок на условиях TFP",
          "Постпродакшн: работа с медиаресурсами",
          <>
            Промежуточная аттестация – зачеты по проектным работам:
            <List
              arr={[
                "студийная фотосессия",
                "фотосессия streetstyle",
                "фотосессия editorial"
              ]}
            />
          </>
        ]}
      />,
      <p>
        <strong>В процессе обучения:</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Постановочные креативные fashion-съемки",
          "Лучшие съемки отправляем в журналы",
          "Лучшим приглашения на показы и Недели моды",
          "Мастер-классы с приглашенными экспертами",
          "Знакомство с будущими партнерами и единомышленниками",
          "Творческая атмосфера"
        ]}
      />,
      <p>
        <strong>Результат успешной учебы:</strong>
      </p>,
      <p>
        Защита итоговой работы и подтвержденные документом компетенций в
        профессии
      </p>
    ]
  },
  shess: {
    media: (
      <img
        src="/img/sdi/diplom-2.jpg"
        style={{ maxWidth: 400, height: "auto" }}
      />
    ),
    text: (
      <p className="p-constructor">
        Слушатели, прошедшие обучение на курсе, получают удостоверение о
        повышении квалификации установленного государством образца по программе
        «Стилист фотосессий», 160 академических часов, сдавшие проектные работы,
        не имеющие академической задолженности и представившие все необходимые
        документы, получают диплом установленного государством образца о
        профессиональной переподготовке с правом деятельности в изученной
        профессиональной области.
      </p>
    )
  },
  variants: false,
  aboutEducation: (
    <span>
      Программа обучения в очном и онлайн форматах состоит из 125&nbsp;занятий
      по 2&nbsp;академических часа.
      <br />
      График проведения занятий: по 2&nbsp;занятия 2-3&nbsp;раза в неделю.
      <br />
      Длительность всего курса — 5+&nbsp;месяцев.
    </span>
  ),
  afterEducation: {
    head: "После обучения. Карьера и бизнес",
    videos: ["rYKZAsSnC84", "gZeWO85gha0", "npJ2B4syBMk"]
  },
  teachers: [stulov, moatti, maltseva, migranov, bonetskaya],
  additional: {
    head: "Дополнительная информация",
    content: [
      <Spoler
        title={<>Формат обучения: Очное обучение</>}
        text={
          <Paragraph
            content={[
              <>
                Максимальное количество практических занятий проходите очно (не
                менее 2х раз по графику + доп. занятия). Теорию Вы изучаете
                онлайн, в непосредственном контакте с преподавателями или в
                записи, как Вам удобнее. Новый очный формат актуален тем, кто
                ведет активный образ жизни, постоянно путешествует, и кто
                настроен при этом на долгосрочный полноценный результат.
                Максимум практики на проектах и мастер-классы с экспертами
                доступны именно в этом формате.
              </>,
              <>Группы: дневные, вечерние, выходного дня.</>,
              <>
                В процессе обучения: лекционные занятия онлайн, практика в
                аудиториях, в ТЦ, шоу-румах, музеях, на модных мероприятиях,
                фотосессиях, самостоятельная работа.
              </>,
              <>
                Преимущества: лекционные занятия без пропусков, практику можно
                восстановить без потери качества освоения материала. После
                обучения все лекционные занятия по программе курса будут
                доступны в записи в личном кабинете в течение 6 месяцев.
              </>
            ]}
          />
        }
      />,
      <Spoler
        title={<>Формат обучения: Заочное обучение</>}
        text={
          <Paragraph
            content={[
              <>
                Формат обучения: сочетание полноценного обучения в онлайн
                формате по всем темам лекционных и практических занятий с
                еженедельными очными практиками в Вашем городе /регионе (ТЦ,
                шоу-румы, фотосъемки), самостоятельная работа.
              </>,
              <>
                Заочное обучение доступно в: Москве, Новосибирске, Красноярске,
                Воронеже, Смоленске
              </>,
              <>Группы: утренние, вечерние, выходного дня</>
            ]}
          />
        }
      />,
      <Spoler
        title={<>Формат обучения: Онлайн (дистанционное) обучение</>}
        text={
          <Paragraph
            content={[
              <>
                Это обучение, где основное внимание уделяется живому общению с
                преподавателями онлайн в сочетании с изучением учебного
                материала в записи. Такой формат прекрасно заменяет очное
                обучение, когда в силу занятости или удаленности необходимо
                гибко подходить к планированию времени и его длительности для
                изучения отдельных дисциплин. На основе этого формата можно
                эффективно подбирать индивидуальную траекторию обучения. В
                процессе обучения предусмотрены защиты проектных и дипломных
                работ онлайн.
              </>,
              <>
                Для студентов, у которых есть возможность приехать в один из
                городов с очным или заочным обучением, предусмотрена возможность
                прохождения дополнительной очной практики.
              </>,
              <>Группы: утренние, вечерние, выходного дня</>,
              <>
                Формат обучения: более 70% занятий — живое общение с
                преподавателями и группой, + записи лекций + самостоятельная
                работа
              </>,
              <>
                Все занятия доступны в записи в личном кабинете в процессе
                обучение и в течение 6 месяцев после обучения.
              </>
            ]}
          />
        }
      />,
      <Spoler
        title="Варианты обучения: дипломная программа, вольный слушатель, отдельные модули"
        text={
          <>
            <p>
              <strong>Дипломная программа</strong>
            </p>
            <List
              arr={[
                <>
                  Обучение по программе «Стилист фотосессий» реализуется на
                  основании Лицензии на образовательную деятельность и
                  соответствует требованиям, предъявляемым к программам
                  профессиональной переподготовки
                </>,
                <>
                  Нагрузка по программе, с учетом самостоятельной работы, не
                  менее 14 академических часов в неделю
                </>,
                <>
                  Ограничений для поступления нет. Обучение доступно для
                  начинающих
                </>,
                <>
                  Требования в процессе обучения: обязательно выполнение д/з,
                  тестов, экзамен.
                </>,
                <>
                  Выпускники других образовательных учреждений принимаются с
                  зачетом соответствующих дисциплин
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
            <p>
              <strong>Вольный слушатель</strong>
            </p>
            <List
              arr={[
                <>
                  Доступно обучение без требований к выполнению заданий и
                  экзаменов.
                </>,
                <>Вольные слушатели после обучения получают сертификат</>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
            <p>
              <strong>
                Обучение по программе отдельного интересующего модуля / теме
              </strong>
            </p>
            <List
              arr={[
                <>
                  Вы можете выбирать интересующие дисциплины/модули с
                  полноценным вовлечением в образовательный процесс с получением
                  удостоверения о повышении квалификации или диплома о
                  профессиональной переподготовке в зависимости от выбранного
                  набора модулей и тем.
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title="Альтернативные программы: 4 варианта"
        text={
          <>
            <List
              arr={[
                <>
                  <Link to="/style-your-life">
                    Консультант по стилю. Быстрый старт
                  </Link>{" "}
                  (диплом), 3 месяца
                </>,
                <>
                  <Link to="/second-degree-imagemaker">
                    Профессия: стилист-имиджмейкер как второе высшее
                  </Link>{" "}
                  (диплом), 12 месяцев
                </>,
                <>
                  <Link to="/style-consultant-tgu">
                    Консультант по стилю. Обучение оплачивает государство
                  </Link>{" "}
                  (повышение квалификации), 2.5 месяца
                </>,
                <>
                  <Link to="/self-style">Сам себе стилист</Link> (базовый курс),
                  1 месяц
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title="Личный кабинет"
        text={
          <>
            <p className="p-constructor">
              {" "}
              Все обучающиеся получают доступ в личный кабинет с текущей
              информацией по обучению, доступом к материалам лекций. В удобное
              время вы сможете просматривать занятия и выполнять домашние
              задания. Главное — соблюдать график учебного процесса в целом. Вы
              сможете оценить работы других студентов и задать вопрос куратору
              курса, а также воспользоваться поддержкой специалистов учебного
              отдела.
            </p>
            <p className="p-constructor">
              Студенты так же получают доступ в закрытую группу (чат), где
              общаются с одногруппниками
            </p>
          </>
        }
      />,
      <Spoler
        title="Где доступно обучение"
        text={
          <>
            <p className="p-constructor">
              Очное обучение с применением онлайн технологий доступно в Москве
            </p>
            <p className="p-constructor">
              Заочное обучение (очно + онлайн) доступно в России (Москва,{" "}
              <a href="http://novosib.styleschool.ru/">Новосибирск</a>,{" "}
              <Link to="/krasnoyarsk">Красноярск</Link>,{" "}
              <Link to="/voronezh">Воронеж</Link>,{" "}
              <Link to="/smolensk">Смоленск</Link>
              ), <a href="http://bishkek.styleschool.ru/">Киргизии</a>,{" "}
              <Link to="/kazahstan">Казахстане</Link>,{" "}
              <Link to="/cannes">Франции</Link>
            </p>
            <p className="p-constructor">
              Дистанционное обучение (живое преподавание онлайн) доступно по
              всему миру.
            </p>
            <p className="p-constructor">
              После обучения практикующие выпускники могут{" "}
              <Link to="/stylist-association">вступить в ассоциацию ВШСДТ</Link>{" "}
              для поддержки в развитии карьеры и бизнеса.
            </p>
          </>
        }
      />
    ]
  },
  price: {
    head: "Стоимость обучения",
    content: [
      <Spoler
        title="Стоимость обучения, очно"
        text={
          <Paragraph
            content={[
              <h3 className="center">
                Базовая стоимость очного обучения, рассрочка на время обучения
              </h3>,
              <>
                •&emsp;20
                <sup>000</sup> рублей — 2й и последующие ежемесячные платежи
              </>,
              <>
                •&emsp;первый платеж — 30
                <sup>000</sup> рублей
              </>,
              <>
                •&emsp;полная стоимость обучения — 90
                <sup>000</sup> рублей
              </>,
              <>
                Для базовой стоимости очного обучения доступна банковская
                рассрочка на 12 месяцев — 7<sup>500</sup> руб. в месяц
              </>,
              <h3 className="center">
                Стоимость очного обучения при раннем бронировании, рассрочка на
                время обучения
              </h3>,
              <>
                •&emsp;При раннем бронировании предоставляется скидка — 9
                <sup>000</sup> руб.
              </>,
              <>
                •&emsp;20
                <sup>000</sup> рублей — 2й и последующие ежемесячные платежи
              </>,
              <>
                •&emsp;первый платеж — 21
                <sup>000</sup> рублей
              </>,
              <>
                •&emsp;полная стоимость обучения при раннем бронировании — 81
                <sup>000</sup> рублей
              </>,
              <font style={{ fontSize: ".8em" }}>
                *Договора на условиях раннего бронирования заключаются во время
                действия специальных акций. Банковская рассрочка на договора
                раннего бронирования не распространяется
              </font>,
              <font style={{ fontSize: ".8em" }}>
                ** Приведена стоимость очного обучения на полном курсе по
                программе «Стилист фотосессий». Стоимость обучения по отдельным
                модулям уточняйте у специалистов учебного отдела
              </font>,
              <font style={{ fontSize: ".8em" }}>
                *** Стоимость индивидуальных консультаций с преподавателями в
                рамках образовательных программ составляет от 2000 рублей за
                академический час
              </font>
            ]}
          />
        }
      />,
      <Spoler
        title="Стоимость обучения, онлайн (/ заочное отделение)дистанционно)"
        text={
          <Paragraph
            content={[
              <h3 className="center">
                Базовая стоимость очного обучения, рассрочка на время обучения
              </h3>,
              <>
                •&emsp;15
                <sup>000</sup> рублей — 2й и последующие ежемесячные платежи
              </>,
              <>
                •&emsp;первый платеж — 30
                <sup>000</sup> рублей
              </>,
              <>
                •&msp;полная стоимость обучения — 75
                <sup>000</sup> рублей
              </>,
              <h3 className="center">
                Стоимость онлайн обучения при раннем бронировании*
              </h3>,
              <>
                •&emsp;При раннем бронировании предоставляется скидка — 9
                <sup>000</sup> руб.
              </>,
              <>
                •&emsp;Стоимость при раннем бронировании, рассрочка на 11
                месяцев
              </>,
              <>
                •&emsp;15
                <sup>000</sup> рублей — 2й и последующие ежемесячные платежи
              </>,
              <>
                •&emsp;первый платеж — 21
                <sup>000</sup> рублей
              </>,
              <>
                •&emsp;полная стоимость обучения — 171
                <sup>000</sup> рублей
              </>,
              <></>,
              <>
                Для базовой стоимости онлайн обучения доступна банковская
                рассрочка на 12 месяцев — 6250 руб. в месяц
              </>,
              <font style={{ fontSize: ".8em" }}>
                *Договора на условиях раннего бронирования заключаются во время
                действия специальных акций. Банковская рассрочка на договора
                раннего бронирования не распространяется
              </font>,
              <font style={{ fontSize: ".8em" }}>
                ** Приведена стоимость онлайн / заочного обучения на полном
                курсе по программе «Имидж-консалтинг и fashion‑styling».
                Стоимость обучения по отдельным модулям уточняйте у специалистов
                учебного отдела
              </font>,
              <font style={{ fontSize: ".8em" }}>
                *** Стоимость индивидуальных консультаций с преподавателями в
                рамках образовательных программ составляет от 2000 рублей за
                академический час
              </font>
            ]}
          />
        }
      />
    ]
  },
  garanty: {
    title: "Финансовая гарантия",
    text: (
      <>
        В программе действует безусловная гарантия на возврат 100% денег в
        течении 14 дней. Это значит, что если вы по какой-то причине передумаете
        учавствовать, мы вернем всю сумму
      </>
    )
  },
  freeEdu: {
    image: (
      <>
        <div>
          <a href="/style-consultant-tgu" target="_blank">
            <img
              src="/img/second-degree-imagemaker/tgu-education.png"
              alt="Консультант по стилю. Бесплатное обучение по гос гранту"
              style={{ border: "1px solid black" }}
            />
          </a>
        </div>
      </>
    ),
    text: (
      <>
        <p className="p-constructor">
          Обучение по программе «Консультант по стилю» по государственному
          гранту.
        </p>
        <p className="p-constructor">
          Обучение реализуется в онлайн формате с живым общением с
          преподавателями на базе Национального исследовательского Томского
          государственного университета совместно с АНО ДПО Высшая школа
          стилистики, дизайна и технологий.
        </p>
        <p>
          <Link to="/style-consultant-tgu">Подробнее…</Link>
        </p>
      </>
    )
  },
  bot: {
    image: (
      <img src="/img/imagemaker-stylist/chat-bot-ill.jpg" alt="Бот Валерия" />
    ),
    text: (
      <>
        <p className="p-constructor">
          <strong>Открытое образование ВШСДТ</strong>
        </p>
        <List
          arr={[
            "Бот Валерия подбирает контент в зависимости от предпочтений",
            "Регулярно открывает доступ к онлайн лекциям и курсам",
            "Без оплат и условий. Общается вежливо. Как начать?",
            "Подпишитесь, нажмите «старт» и выбирайте интересующие темы"
          ]}
          num="none"
          olStyle={{ marginLeft: 0, paddingLeft: 0 }}
          classLi="p-constructor"
        />
        <a href="https://t.me/imagemakerpro_bot">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  }
};
