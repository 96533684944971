import React from "react";
import { Grid } from "@material-ui/core";
import "./style.css";

export function Tile(props) {
  return (
    <div className="review-shess-image-box">
      <img
        src={props.image}
        alt={props.altText}
        className={"review-image"}
        style={{ ...props.imageStyle }}
      />
    </div>
  );
}

export function TilesBuilder(props) {
  const c = props.content;
  return (
    <div className="reviews-tiles">
      <h3>{c.title}</h3>
      <Grid container justify="space-around" spacing={6} alignItems="stretch">
        {c.images.map((el, key) => (
          <Grid item xs={12} md={4} key={`review-block-${key}`}>
            <Tile
              image={el.image}
              altText={el.alt}
              imageClassName={props.imageClassName}
              imageStyle={props.imageStyle}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
