import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Images from "../../components/images-in-line";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import { styles } from "./styles";

const online = `${dates.visualMerchandising.online.getDate()}  ${Month(
  dates.visualMerchandising.online.getMonth()
)}`;
const testdrive = `${dates.visualMerchandisingTestDrive.start.getDate()}.${dates.visualMerchandisingTestDrive.start.getMonth()}-${dates.visualMerchandisingTestDrive.finish.getDate()}.${dates.visualMerchandisingTestDrive.finish.getMonth()}`;

export const contents = {
  titlePage: "Визуальный мерчендайзинг — обучение для индустрия моды",
  // meta
  keywords:
    "визуальный, мерчендайзинг, школа, курс, квалификация, обучение, учиться, где, профессия",
  // meta
  descript:
    "Визуальный мерчендайзинг — курс предназначен для повышения квалификации специалистов, работающих в сфере розничной торговли в индустрии моды. Курс помогает повысить уровень визуальных коммуникаций и сделать бренд или пространство привлекательнее для потребителей.",
  lable: "Визуальный мерчандайзинг",
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Профессия: специалист по
        <br />
        визуальному мерчандайзингу
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Курс предназначен для повышения квалификации специалистов, работающих в
        сфере розничной торговли в индустрии моды, а также для соискателей
        вакансии визуального мерчендайзера
      </div>
    ),
    descript: [
      "Онлайн обучение + очная практика",
      "Диплом о профессиональной переподготовке",
      "6 месяцев, 254 академических часа",
      "Стоимость 50 тыс. рублей",
      "Рассрочка, налоговый вычет"
    ],
    text: false,
    slider: true,
    slides: [
      "/img/vitrinism/foto1.jpg",
      "/img/vitrinism/foto2.jpg",
      "/img/vitrinism/foto3.jpg",
      "/img/vitrinism/foto5.jpg",
      "/img/vitrinism/foto6.jpg",
      "/img/vitrinism/foto9.jpg",
      "/img/vitrinism/foto4.jpg",
      "/img/vitrinism/foto11.jpg",
      "/img/vitrinism/foto13.jpg",
      "/img/vitrinism/foto14.jpg"
    ],
    noVideo: true,
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ]
  },
  text1: (
    <span>
      Данный курс будет полезен прежде всего тем, чья профессиональная
      деятельность связана с розничной продажей модной одежды, обуви и модных
      аксессуаров. Руководители, предприниматели, дизайнеры и опытные участники
      модного рынка зафиксируют определенный комплекс технологий в планировании
      и управлении пространством и продажами в fashion retail.
      <br />
      Тем, кто только планирует построить карьеру в индустрии моды и сделать это
      правильно, освоив профессиональные навыки и компетенции, получит
      практичную специальность, сможет действовать рационально
    </span>
  ),
  tags1: false,
  screen2: {
    text: (
      <List
        arr={[
          <>
            Цель курса – помочь руководителям,
            пред&shy;при&shy;нима&shy;те&shy;лям, ди&shy;зай&shy;нерам и
            дру&shy;гим участ&shy;никам мод&shy;ного рын&shy;ка
            за&shy;фик&shy;сиро&shy;вать оп&shy;ределен&shy;ный ком&shy;плекс
            тех&shy;ноло&shy;гий в пла&shy;ниро&shy;ва&shy;нии
            про&shy;стран&shy;ства с целью
            сти&shy;му&shy;ли&shy;ро&shy;ва&shy;ния про&shy;даж в
            роз&shy;нич&shy;ных ма&shy;га&shy;зинах мод&shy;ной одеж&shy;ды,
            обуви, ак&shy;сес&shy;суаров.
          </>,
          <>
            А также курс помо&shy;гает повы&shy;сить уро&shy;вень
            ви&shy;зуаль&shy;ных ком&shy;муни&shy;ка&shy;ций и сде&shy;лать
            бренд / прос&shy;тран&shy;ство при&shy;вле&shy;ка&shy;тель&shy;нее
            для по&shy;тре&shy;би&shy;телей.{" "}
          </>
        ]}
        num="none"
        style={{ paddingBottom: "1rem", lineHeight: "2rem" }}
      />
    ),
    image: <img src="/img/vitrinism/hlestova.jpg" />
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>дистанционно с {online}</>, <>тест-драйв {testdrive}</>]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции,</>,
        <>чат в группе, очная практика</>
      ]
    },
    {
      title: "Эксперты",
      text: [<>российские и зарубежные</>, <>специалисты практики</>]
    }
  ],
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;ча&shy;ете видео лек&shy;ции. Лек&shy;ции
          от&shy;кры&shy;вают&shy;ся по мере про&shy;хож&shy;дения курса.
          Обу&shy;че&shy;ние дви&shy;жет&shy;ся в ком&shy;форт&shy;ном,
          ра&shy;бо&shy;чем ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние задания.
          Это важная часть обуче&shy;ния, в резуль&shy;тате кото&shy;рой
          форми&shy;руется итого&shy;вая проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;пода&shy;­вате&shy;лем. Препо&shy;­дава&shy;­тель лично
          прове&shy;­ряет зада&shy;­ния, находит ошибки, ком­мен&shy;­тирует и
          помо­гает
        </>
      )
    },
    {
      title: "Практика + Merchbook",
      text: (
        <>
          В итоге обу&shy;че&shy;ния Вы соз&shy;да&shy;ете плано&shy;грам&shy;му
          мага&shy;зи&shy;на, для со&shy;труд&shy;ников
          про&shy;писы&shy;ва&shy;ете стан&shy;дарты
          мер&shy;чен&shy;дай&shy;зин&shy;га и тре&shy;нинг по сти&shy;лю в
          одеж&shy;де и ра&shy;бо&shy;те с кли&shy;ен&shy;тами.
        </>
      )
    }
  ],
  text2: {
    title: "Ищете идеальное образование?",
    text: (
      <Paragraph
        content={[
          "Вы можете выбрать интересующие Вас темы и форматы их освоения. Здесь Вы найдете как формат классического образования — в составе группы и единой программы, так и возможность индивидуальной траектории обучения.",
          "Мы рассматриваем профессию в связке с областями fashion   styling и fashion retail. Изучаем ожидания работодателей и психологию потребителей, а так же области применения полученных знаний и практических навыков в части развития карьеры в индустрии моды или развития бизнеса.",
          "Мы — Автономная некоммерческая организация Дополнительного   профессионального образования «Высшая школа стилистики,   дизайна и технологий», одна из ведущих образовательных   площадок в индустрии"
        ]}
        style={styles.p}
      />
    )
  },
  aboutProfeshion: {
    title: "О профессии",
    short:
      "Мерчандайзинг — составная часть маркетинга, деятельность, направленная на обеспечение максимально интенсивного продвижения товара на уровне розничной торговли …",
    detail: [
      <Paragraph
        style={styles.p}
        content={[
          "Концепция мерчандайзинга включает:",
          "Визуальный мерчандайзинг (планировка торгового зала, вывеска, витрина, интерьер, торговое оборудование, размещение рекламных материалов, стратегия представления и непосредственно выкладка товара)",
          "Товарный мерчандайзинг (учет поставок товара, расчет   товарного запаса по ассортиментным группам, размещение товара по ценовым категориям).",
          "Мерчендайзер отвечает за создание концепта магазина и   визуальное представление товара в его пространстве для   максимально выигрышной демонстрации ассортимента: организует презентацию сезонных коллекций, оформляет прилавки и витрины, создаёт стильные и привлекающие внимание образы на манекенах, занимается развеской и выкладкой товара в зале, обеспечивает наличие информации о товаре; создаёт дополнительный объем продаж за счёт импульсных покупок, планирует и подготавливает магазин к смене сезонных кампаний; оформляет акции, сезонные распродажи; участвует в проведении инвентаризации и других товарных операций.",
          "Современный эффективный специалсит по мерчендайзингу должен обладать всем комплексом знаний и инструментов, используемых сегодня в бизнесе. Он устанавливает коммуникацию непосредственно между товаром и покупателем, без посредничества продавца.",
          "Хороший специалист использует техники эмоционального коммерческого мерчандайзинга и специфику работы с коллекциями в торговом зале."
        ]}
      />
    ]
  },
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <b>Основы визуального мерчендайзинга и витринистики</b>,
          "Специфика профессии.",
          "История развития моды и ВМ.",
          "Роль ВМ в компании. Структура отдела BM.",
          <b>Организация пространства магазина</b>,
          "Концепция пространства и визуальное оформление",
          "Оборудование. Планирование и распределение. Техзадание   и закупка",
          "Реконструкция / перезонирование магазина",
          "Освещение: торговый зал, витрина, примерочная, кассовый узел.",
          "Цвет в интерьере магазина: психология восприятия.",
          "Зонирование.",
          "Трекинг покупательского потока",
          "Расчет вместимости товара в магазине",
          "Выявление горячих и холодных зон",
          <b>Мерчендайзинг как инструмент успешных продаж</b>,
          "Портрет покупателя",
          "Работа с товарным ассортиментом",
          "Профессиональная терминология",
          "Специфика мерчендайзинга для разных ценовых сегментов",
          "Специфика работы с группами женской и мужской одежды",
          "Мерчендайзинг аксессуаров",
          "Способы визуализации философии бренда. POS  материалы",
          "Персонализация, программа лояльности, апсейл, кросссейл",
          "Мерчендайзинг в период распродажи",
          "Мерчендайзинг – бук",
          "Бюджетирование",
          "Отчетность. Способы контроля",
          <b>Витрина как коммуникация: эстетика и продажи</b>,
          "Типы и виды витрин",
          "Принципы оформления витрин. Законы колористики и композиции",
          "Манекены в витрине. Роль в увеличении прибыли Особенности   представления товара.",
          "Декор и декорирование. Идеи и решения, материалы",
          <b>Дополнительные дисциплины</b>,
          "Женская стилистика",
          "Мужская стилистика",
          "Виды стилей и их отличительные характеристики",
          "Создание модного образа",
          "Карьера и бизнес. Личный брендинг"
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения:
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Изучение графических редакторов и программ",
          <Images
            justify="flex-start"
            size={50}
            imgs={[
              {
                image: "img/living-interior-design-and-decoration/ps.png",
                text: "Adobe Photoshop"
              },
              {
                image: "img/living-interior-design-and-decoration/archicad.png",
                text: "Archicad"
              },
              {
                image: "img/living-interior-design-and-decoration/3d-max.png",
                text: "3D Max"
              }
            ]}
          />,
          "Контрольные документы, стандарты",
          "Аналитика и статистика, расчет количественных характеристик",
          "Принципы обучения персонала"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат успешной учебы
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Защита итоговой работы и подтверждение дипломом изученных компетенций",
          "Навыки планирования пространства магазина, выкладки, развески, оформления зала и витрин, планирование продаж, система отчетности",
          "Новые партнеры, команда, клиенты",
          "План развития личного бренда"
        ]}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Дополнительно в программе:
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            Очная практика создания комплектов одежды (6 занятий, 12
            академических часов)
          </>,
          <>Workshop — реализация фотосессии (3 дня, 24 академических часа)</>,
          <>
            Предпроектные консультации и мастер-классы (6 занятий, 12
            академических часов)
          </>,
          <>
            Итого: 60 занятий + 3 дня workshop + самостоятельная работа (254
            академичесиких часа в дипломе)
          </>
        ]}
        num="none"
      />
    ]
  },
  aboutEducation: (
    <span>
      Программа обучения в очном и онлайн форматах состоит из 125&nbsp;занятий
      по 2&nbsp;академических часа.
      <br />
      График проведения занятий: по 2&nbsp;занятия 2-3&nbsp;раза в неделю.
      <br />
      Длительность всего курса — 5+&nbsp;месяцев.
    </span>
  ),
  formatsNew: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Учебная нагрузка: 4 занятия в неделю по 90 минут, 4 недели</>}
        text={
          <>
            <p>График занятий:</p>
            <List
              olStyle={{ paddingLeft: "1rem" }}
              arr={[
                <>
                  3 занятия в неделю по 90 минут — мастер-классы с домашними
                  практическими заданиями
                </>,
                <>
                  1 занятие в неделю — семинары с преподавателями, живое общение
                  online
                </>
              ]}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  formats: {
    head: "Форматы обучения",
    content: [
      <p>
        <strong>
          Форма обучения: заочная с применением дистанционных технологий
        </strong>
      </p>,
      <p>
        Обучение предусматривает изучение основного блока лекционных и
        практических материалов онлайн (дистанционно). В рамках курса проводятся
        практические занятия непосредственно в торговых центрах (бутиках) и
        практика подготовки и реализации фотосъемок.
      </p>,
      <p>Очная практика доступна в Москве, Новосибирске, Одессе, Бишкеке.</p>,
      <p>
        Если вы учитесь только дистанционно, мы дадим вам задания и технологию,
        и вы пройдете такую практику в своем городе самостоятельно.
      </p>,
      <p>
        <strong>Учебная нагрузка</strong>
      </p>,
      <p>График занятий:</p>,
      <List
        olStyle={{ paddingLeft: "1rem" }}
        arr={[
          <>
            3 занятия в неделю по 2 академических часа — лекционный материал с
            домашними практическими заданиями
          </>,
          <>
            1 занятие в неделю по 2 академических часа — практические семинары с
            преподавателями по изученным темам, живое общение online
          </>,
          <>1 занятие в месяц — очная практика</>,
          <>1 занятие в месяц — предпроектная консультация онлайн</>,
          <>
            3 дня workshop (реализация фотопроекта) — проводиться в конце
            обучения
          </>
        ]}
        num="none"
      />,
      <p>
        <strong>Варианты обучения:</strong>
      </p>,
      <p>
        <strong>Дипломная программа</strong> — в рамках обучения с правом
        получения Диплома о профессиональной переподготовке установленного
        государством образца обязательными требованиями являются выполнение
        домашних заданий, реализация проектной работы и прохождение итогового
        тестирования — экзамена (онлайн)
      </p>,
      <p>
        <strong>Вольный слушатель</strong> — Вы можете учиться без обязательных
        требований по выполнению д/з и практик, без сдачи экзаменов. В таком
        случае вы получите сертификат о прохождении программы и дополнительную
        скидку на обучение.
      </p>
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Курс дает системные и практические знания для старта карь&shy;еры в
        индус&shy;трии моды. После обу&shy;че&shy;ния Вы полу&shy;чите диплом
        уста&shy;новлен&shy;ного госу&shy;­дар&shy;­ством образца о
        про&shy;фес&shy;сиональ&shy;ной пере&shy;под&shy;готов&shy;ке по
        про&shy;грам&shy;ме «Консультант по имиджу и стилю» с правом
        деятель&shy;ности в изу&shy;чен&shy;ной профес&shy;сио&shy;наль&shy;ной
        области.
      </p>
    ),
    image: (
      <img
        src="/img/sdi/diplom-2.jpg"
        alt="Диплом ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  info: {
    head: "Информационные материалы и документация",
    iconDocs: (
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path
          fill="rgb(100,100,100)"
          d="M16 0H8C6.9 0 6 .9 6 2V18C6 19.1 6.9 20 8 20H20C21.1 20 22 19.1 22 18V6L16 0M20 18H8V2H15V7H20V18M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4Z"
        />
      </svg>
    ),
    iconMonitor: (
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path
          fill="rgb(100,100,100)"
          d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
        />
      </svg>
    )
  },
  teachers: [
    {
      image: "/img/teachers/hlestova-small.jpg",
      name: "Маргарита Хлестова",
      text:
        "Визуальный мерчендайзер, имидж-консультант, преподаватель Высшей школы стилистики. Более 5 лет проведения обучающих курсов и вебинаров по Визуальному мерчендайзингу и Стилистике женского образа. Частное консультирование.",
      instagram: false,
      buttons: {
        detail: "/margarita-hlestova",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/lamonova-small.jpg",
      name: "Елена Ламонова",
      text:
        "Дизайнер. Специалист в области интерьерного, ландшафтного, предметного дизайна, создатель концепций и проектов оформления мероприятий всероссийского уровня, создатель собственного дизайн бюро, преподаватель Высшей школы стилистики",
      instagram: false,
      buttons: {
        detail: "/elena-lamonova",
        linkInside: true
      }
    },
    {
      image: "/img/about/ruslan-migranov.jpg",
      name: "Руслан Мигранов",
      text:
        "Историк моды, визуальный мерчендайзер, витринист, член творческого союза дизайнеров и имиджмейкеров, преподаватель Высшей школы стилистики.",
      instagram: false,
      buttons: {
        detail: "/ruslan-migranov",
        linkInside: true
      }
    },
    {
      image: "/img/about/marina-bonetskaya.jpg",
      name: "Марина Бонецкая",
      text:
        "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
      instagram: false,
      buttons: {
        detail: "/marina-bonetskaya",
        linkInside: true
      }
    },
    {
      image: "/img/about/anna-kachalina.jpg",
      name: "Анна Качалина",
      text:
        "Автор и преподаватель курса «Визуальный мерчендайзинг и витринистика». Специалист по открытию магазинов, Start Up проектам и консалтингу малого бизнеса.",
      instagram: false,
      buttons: {
        detail: "/anna-kachalina",
        linkInside: true
      }
    }
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>5750 &#8381;</>,
    durationInstallment: "12",
    full: <>69 000 &#8381;</>, //  полная стоимость
    discount: false, // <>55 200 &#8381;</>, // со скидкой
    discountSize: "20%",
    discountDuration: "для групп",
    qrLink: "https://link.tinkoff.ru/7KCykkkrmF7"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
