import React from "react";

import Paragraph from "../../components/p-array";
import Blog from "./template";

export default function Myslitel() {
  return (
    <Blog
      title="	Эйлин Рибейро. Книга Мода и мораль."
      keywords="Эйлин Рибейро, мода, стиль, стильная, модная, одежда"
      descript="Книга Эйлин Рибейро «Мода и мораль» интересна своим достаточно откровенным взглядом на мир моды, одежды и тела. Как одежда помогает привлекать внимание к телу? Какие детали одежды и детали кроя этому помогают на протяжении всего времени существования моды как украшения тела? Как влияет одежда на выражение сексуальности женщины или мужчины? Против чего в одежде и моде боролись моралисты и защитники нравственности на протяжении веков? Как одежда помогает реализовать человеческие желания и устремления? Книга британского искусствоведа для всех, кто интересуется историей и теорией моды."
      head="Эйлин Рибейро. Мода и мораль."
      author="Наталья Горских"
      link="/natalya_gorskyh"
      image="/img/blog/book-ribeyro.jpg"
      content={
        <Paragraph
          content={[
            <span>
              Книга интересна своим достаточно откровенным взглядом на мир моды,
              одежды и тела. Как одежда помогает привлекать внимание к телу?
              Какие детали одежды и детали кроя этому помогают на протяжении
              всего времени существования моды как украшения тела? Как влияет
              одежда на выражение сексуальности женщины или мужчины? Против чего
              в одежде и моде боролись моралисты и защитники нравственности на
              протяжении веков? Как одежда помогает реализовать человеческие
              желания и устремления? Книга британского искусствоведа для всех,
              кто интересуется историей и теорией моды.
            </span>
          ]}
        />
      }
    />
  );
}
