const gorskih = {
  descript:
    "Заместитель руководителя, руководитель учебных процессов, автор образовательных программ, кандидат филологических наук, ведущий преподаватель Высшей школы стилистики",
  image: "/img/about/natalya-gorskih.jpg",
  name: "Наталья Горских",
  link: "/natalya_gorskyh"
};

const kostarnova = {
  descript: "",
  image: "/img/about/marina-kostarnova.jpg",
  name: "Марина Костарнова",
  link: "/marina_kostarnova"
};

const iskhakova = {
  descript:
    "Практикующий имиджмейкер, имидж-тренер, fashion-стилист, преподаватель Высшей школы стилистики, руководитель филиала Высшей школы стилистики в Новосибирске",
  image: "/img/about/nadejda-ishakova.jpg",
  name: "Надежда Исхакова",
  link: "/nadezhda_iskhakova"
};

// const fomina = {
//   descript:
//     'Художник-модельер, преподаватель и практикующий имидж-консультант.  В Высшей школы стилистики ведет занятия в рамках дисциплины "Профессинальные имидж-технологии".',
//   image: "/img/about/tatiyana-fomina.jpg",
//   name: "Татьяна Фомина",
//   link: "/tatiana_fomina"
// };

const rodina = {
  descript:
    "Кандидат политических наук, психолог, бизнес-тренер, политтехнолог, Директор фонда «Общественных инициатив»",
  image: "/img/about/mariya-rodina.jpg",
  name: "Мария Родина",
  link: "/maria_rodina"
};

const cherednichenko = {
  descript:
    "Кандидат экономических наук, социолог, бизнес-тренер, политтехнолог, Директор Института стратегических исследований и проектов",
  image: "/img/about/valeriy-cherednichenko.jpg",
  name: "Валерий Чередниченко",
  link: "/valery_cherednichenko"
};

const bahova = {
  descript:
    "Имидж-консультант, профайлер, автор программ по управлению впечатлением в бизнесе и межличностных коммуникациях",
  image: "/img/about/liana-bahova.jpg",
  name: "Лиана Бахова",
  link: "/liana_bahova"
};

const ahmetova = {
  descript:
    "Руководитель студии профессионального макияжа «ProMakeupArtist». Соавтор и постоянный тренер образовательного проекта ProMakeupArtist School Prague (Прага, Чехия).",
  image: "/img/about/yana-ahmetova.jpg",
  name: "Яна Ахметова",
  link: "/yana_ahmetova"
};

const kachalina = {
  descript: "",
  image: "/img/about/anna-kachalina.jpg",
  name: "Анна Качалина",
  link: "/anna_kachalina"
};

const lamonova = {
  descript:
    "Дизайнер. Специалист в области интерьерного, ландшафтного, предметного дизайна, создатель концепций и проектов оформления мероприятий всероссийского уровня, создатель собственного дизайн бюро, преподаватель Высшей школы стилистики",
  image: "/img/teachers/lamonova-small.jpg",
  name: "Елена Ламонова",
  link: "/elena-lamonova"
};

const hlestova = {
  descript:
    "Визуальный мерчендайзер, имидж-консультант, преподаватель Высшей школы стилистики.",
  image: "/img/teachers/hlestova-small.jpg",
  name: "Маргарита Хлестова",
  link: "/margarita-hlestova"
};

const nenasheva = {
  descript:
    "Практикующий дизайнер интерьера. Художник. Технический директор собственной студии дизайна интерьеров ArtStreet Design.",
  image: "/img/teachers/erika-nenasheva-small.jpg",
  name: "Эрика Ненашева",
  link: "/erika_nenasheva"
};

const troepolskaya = {
  descript: "Fashion stylist , creator fashion project",
  image: "/img/about/g-troepolskaya.jpg",
  name: "Любовь Троепольская",
  link: "/troepolskaya"
};

export const otherTeachers = [
  gorskih,
  kostarnova,
  iskhakova,
  rodina,
  cherednichenko,
  bahova,
  ahmetova,
  kachalina,
  lamonova,
  hlestova,
  nenasheva,
  troepolskaya
];
