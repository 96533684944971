import React from "react";
import List from "../../components/list";
import { dates, Month } from "../shedule/dates";
import Video from "../../components/video";
import Ig from "../../components/instagram";

export const contents = {
  titlePage: "Fashion практика для студентов курсов имиджмейкеров, стилистов",
  head: {
    headDescript: "Профессиональное образование",
    head: "Fashion практика для студентов курсов имиджмейкеров, стилистов",
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Практика №2 для студентов, обучающихся на программах «Имидж-консалтинг и
        fashion-styling», «Консультант по стилю»
      </div>
    ),
    descript: [
      "Очная практика",
      "Сертификат",
      "5 дней, 40 академических часов",
      "Аудиторная практика, практика шопинга",
      "Консультации с наставниками"
    ],
    contactInside: {
      phone: "+7 (916) 058 00 92",
      phoneLink: "tel:+79160580092",
      name: "Евгения",
      tg: "tg://resolve?domain=evgeniya_pimenova",
      whatsapp: "whatsapp://send?phone=+79160580092"
    },
    text: "",
    styleImage: {
      maxHeight: 334
    },
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    noVideo: true
  },
  boldText: {
    boldText: <>Очная практика № 2</>,
    text: "будет проходить"
  },
  // dates: {
  //   ofline: {
  //     type: "с",
  //     day: dates.practicImageConsulting2.start.getDate(),
  //     month: Month(dates.practicImageConsulting2.start.getMonth())
  //   },
  //   online: {
  //     type: "по",
  //     day: dates.practicImageConsulting2.finish.getDate(),
  //     month: Month(dates.practicImageConsulting2.finish.getMonth())
  //   }
  // },
  programma: {
    head: "Программа модных практик",
    content: [
      <List
        style={{
          fontFamily: "PT Sans Narrow"
        }}
        classLi="list-head-constructor"
        num="none"
        arr={[
          <>
            День 1 – Индивидуальный стиль.
            <br />
            <List
              arr={[<>Преподаватель: Светлана Шабалина</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 2 – Мужской стиль.
            <br />
            <List
              arr={[<>Преподаватель: Руслан Мигранов</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 3 – Эвристика.
            <br />
            <List
              arr={[<>Преподаватель: Марина Бонецкая</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 4 – Практика работы с клиентом.
            <br />
            <List
              arr={[<>Преподаватель: Лиана Бахова</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 5 – Методики работы с клиентом.
            <br />
            <List
              arr={[<>Преподаватель: Светлана Шабалина</>]}
              num="none"
              classLi="list-constructor"
            />
          </>
        ]}
      />
    ]
  },
  variants: false,
  afterEducation: (
    <>
      <p className="p-constructor" style={{ textAlign: "center" }}>
        Подписывайтесь на Youtube канал ВШСДТ ТВ:
        <br />
        интервью с преподавателями, экспертами, выпускниками, открытые лекции,
        обзоры трендов
      </p>
      <p className="p-constructor" style={{ textAlign: "center" }}>
        <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
          Перейти на Youtube канал &gt;&gt;&gt;
        </a>
      </p>
    </>
  ),
  videoHead: "Fashion практики",
  videoLocal: {
    head: false,
    videos: [
      {
        video: "/media/practics/tree-1.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      },
      {
        video: "/media/practics/tree-2.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      },
      {
        video: "/media/practics/tree-3.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      }
    ]
  },
  perspectives: {
    text: (
      <>
        <div className="block-left-space">
          <p className="p-constructor">
            Смотрите вебинар о перспективах раз­вития в профес­сии стилиста,
            имидж­мейкера: специализации в профессии, успеш­ные кейсы и истории.
            Оцените, какие ниши и сег­менты можно занять в индус­трии моды.
            Убедитесь, что это по-настоящему по­тряса­ющая и творческая работа.
          </p>
          <p className="p-constructor">
            Спикер — Елена Прохорович <Ig a="elenacult_com" />
          </p>
        </div>
      </>
    ),
    media: <Video link="AGcaa7kGFLE" />,
    side: true
  },
  examplesVideo: [
    {
      video: "/media/practics/01s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Фотосессия для журнала. Съемка fashion editorial в отеле Рэдиссон.
          Практика с Еленой Прохорович и Жанно Рожновой.
        </strong>,
        <>
          Photo <Ig a="zaika_photo" />
        </>,
        <>
          Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Backstage <Ig a="elenacult_com" />
        </>,
        <>
          Assistant stylist <Ig a="olapalina" />
        </>,
        <>
          Assistant stylist <Ig a="alinaas.diary" />
        </>,
        <>
          Assistant stylist <Ig a="kermen_eva" />
        </>,
        <>
          Hair stylist <Ig a="dave_bern" />
        </>,
        <>
          Model <Ig a="yulianagoldman_style" />
        </>,
        <>
          Clothes <Ig a="primavera_showroom" /> <Ig a="canotier_shop" />
        </>,
        <>
          Concept <Ig a="styleschool_msk" />
        </>
      ]
    },
    {
      video: "/media/practics/02s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Фотосессия для журнала. Съемка fashion editorial в отеле Рэдиссон.
          Практика с Еленой Прохорович и Жанно Рожновой.
        </strong>,
        <>
          Photo <Ig a="zaika_photo" />
        </>,
        <>
          Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Backstage <Ig a="elenacult_com" />
        </>,
        <>
          Assistant stylist <Ig a="olapalina" />
        </>,
        <>
          Assistant stylist <Ig a="alinaas.diary" />
        </>,
        <>
          Assistant stylist <Ig a="kermen_eva" />
        </>,
        <>
          Hair stylist <Ig a="dave_bern" />
        </>,
        <>
          Model <Ig a="yulianagoldman_style" />
        </>,
        <>
          Clothes <Ig a="primavera_showroom" /> <Ig a="canotier_shop" />
        </>,
        <>
          Concept <Ig a="styleschool_msk" />
        </>
      ]
    },
    {
      video: "/media/practics/03s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Пратика по мужскому стилю в шоуруме Indever с Русланом Миграновым.
        </strong>
      ]
    },
    {
      video: "/media/practics/04s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Фотосессия детской одежды. Практика с Евгением Стуловым.
        </strong>,
        <>Backstage. Art photoshoot ALEGRIA</>,
        <>
          Management <Ig a="ema_official_rus" />
        </>,
        <>
          Producer <Ig a="evmaltseva" />
        </>,
        <>
          Photo <Ig a="solaris_foto" />
        </>,
        <>
          Video <Ig a="katerina_ema_" />
        </>,
        <>
          Style <Ig a="olgamma_" /> <Ig a="r_mironova_style" />
        </>,
        <>
          Hair style <Ig a="muah_bynastya" /> <Ig a="r_mironova_style" />
        </>,
        <>
          Muah <Ig a="muah_bynastya" />
        </>,
        <>
          Models <Ig a="emamodelbase" />
        </>
      ]
    },
    {
      video: "/media/practics/05s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Фотосессия для журнала. Съемка fashion editorial в отеле Рэдиссон.
          Практика с Еленой Прохорович и Жанно Рожновой.
        </strong>,
        <>
          Photo <Ig a="zaika_photo" />
        </>,
        <>
          Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Backstage <Ig a="elenacult_com" />
        </>,
        <>
          Assistant stylist <Ig a="olapalina" />
        </>,
        <>
          Assistant stylist <Ig a="alinaas.diary" />
        </>,
        <>
          Assistant stylist <Ig a="kermen_eva" />
        </>,
        <>
          Hair stylist <Ig a="dave_bern_" />
        </>,
        <>
          Model <Ig a="yulianagoldman_style" />
        </>,
        <>
          Clothes <Ig a="primavera_showroom" /> <Ig a="canotier_shop" />
        </>,
        <>
          Concept <Ig a="styleschool_msk" />
        </>
      ]
    },
    {
      video: "/media/practics/06s.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: false
    }
  ]
};
