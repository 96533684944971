import React from "react";
import Helmet from "react-helmet";
import Ig from "../../components/instagram";
import Page from "../../components/page";
import { BuilderLayout, TitleH2 } from "../../patterns/pattern-fs/components";
import { content } from "./contents";

export function graduate(arr) {
  let newArr = [];
  const l = arr.length;
  for (let i = 0; i < l; i++) {
    newArr.push(
      <div className="graduates-item">
        <h3>{arr[i].name}</h3>
        <p>{arr[i].text}</p>
        <h4>Образование</h4>
        <p>{arr[i].education}</p>
        <p>
          <Ig a={arr[i].instagram} />
        </p>
      </div>
    );
  }
  return newArr;
}

export default function AboutGraduates(props) {
  return (
    <>
      <Helmet>
        <title>Выпускники ВШСДТ</title>
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Выпускники">
        <BuilderLayout
          contents={(<TitleH2 content="Наши выпускники" />, graduate(content))}
        />
      </Page>
    </>
  );
}
