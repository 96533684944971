import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>Курсы стилистов, дизайнеров, имиджмейкеров в Воронеже</title>
        <meta
          name="descript"
          content="Обучение стилистов, дизайнеров, имиджмейкеров в Воронеже. Обучение профессиям, программы профессиональной переподготовки и повышения квалификации в индустрии моды и дизайна. Академическое образование и реальная практика."
        />
        <meta
          name="keywords"
          content="воронеж, курсы, обучение, имиджмейкер, стилист, школа, учиться, где, профессия"
        />
      </Helmet>
      <PageGlobal lable="Воронеж" filial="Воронеж">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
