import React from "react";
import List from "../components/list";

export const fomina = {
  image: "/img/fashion-styling/fomina.jpg",
  name: "Татьяна Фомина",
  text: (
    <List
      arr={[
        "Имидж-стилист, дизайнер",
        "Куратор курса и старший преподаватель дисциплин  имиджа и стилистики в Высшей школе стилистики дизайна и технологий",
        "Спикер «3-го Международного Съезда Стилистов»",
        "Автор издания «Книга Стиля», психологической программы «Психология красоты», ведущая передачи о стиле и моде на TВ, лауреат и призер конкурса дизайнеров «Новая Россия 21 век»"
      ]}
    />
  ),
  instagram: false,
  buttons: {
    detail: "/tatiana_fomina",
    linkInside: true
  }
};

export const bonetskaya = {
  image: "/img/fashion-styling/bonetskaya.jpg",
  name: "Марина Бонецкая",
  text:
    "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
  instagram: false,
  buttons: {
    detail: "/marina_bonetskaya",
    linkInside: true
  }
};

export const bahova = {
  image: "/img/teachers/320x240/liana-bahova.jpg",
  imageFilter: true,
  name: "Лиана Бахова",
  text:
    "Имидж-консультант, профайлер, автор программ по управлению впечатлением в бизнесе и межличностных коммуникациях",
  instagram: false,
  buttons: {
    detail: "/liana_bahova",
    linkInside: true,
    video: "m7lYgO78wNM",
    videoText: "видео"
  }
};

export const shabalina = {
  image: "/img/teachers/320x240/svetlana-kononets.jpg",
  imageFilter: true,
  name: "Светлана Шабалина",
  text:
    "Практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения, преподаватель Высшей школы стилистики.",
  instagram: false,
  buttons: {
    detail: "/svetlana_kononets",
    linkInside: true,
    video: "a1T_ln5fDlE"
  }
};

export const shabalina2 = {
  image: "/img/teachers/320x240/svetlana-kononets.jpg",
  imageFilter: false,
  name: "Светлана Шабалина",
  text:
    "Преподаватель дисциплин по стилистике и авторских курсов в ВШСДТ. Автор книги «Быть стильной, оставаясь собой.Как рассказать о своей уникальности с помощью одежды», изд-во «Директ Медиа», 2020 год",
  instagram: false,
  buttons: {
    detail: "/svetlana_kononets",
    linkInside: true,
    video: "a1T_ln5fDlE"
  }
};

export const shabalina3 = {
  image: "/img/about/svetlana-kononets.jpg",
  imageFilter: true,
  name: "Светлана Шабалина",
  text:
    "Преподаватель дисциплин по стилистике и авторских курсов в ВШСДТ. Автор книги «Быть стильной, оставаясь собой.Как рассказать о своей уникальности с помощью одежды», изд-во «Директ Медиа», 2020 год",
  instagram: false,
  buttons: {
    detail: "/svetlana_kononets",
    linkInside: true,
    video: "a1T_ln5fDlE"
  }
};

export const kononets = {
  image: "/img/teachers/320x240/svetlana-kononets.jpg",
  name: "Светлана Кононец",
  text:
    "Практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения, преподаватель Высшей школы стилистики.",
  instagram: false,
  buttons: {
    detail: "/svetlana_kononets",
    linkInside: true,
    video: "a1T_ln5fDlE"
  }
};

export const migranov = {
  image: "/img/fashion-styling/migranov.jpg",
  name: "Руслан Мигранов",
  text:
    "Ведущий лекций по теме «История моды XX века» в рамках \
  программы профессиональной переподготовки «Современные технологии \
  в работе имиджмейкера: персональный и корпоративный стиль». \
  Преподаватель курса «Визуальный мерчендайзинг и витринистика», \
  ведущий отдельных мастер-классов по моде и витринистике, а \
  также выездного арт-тура в Венецию «Мода и искусство Венеции»",
  instagram: false,
  buttons: {
    detail: "/ruslan_migranov",
    linkInside: true
  }
};

export const migranov2 = {
  image: "/img/teachers/320x240/ruslan-migranov.jpg",
  name: "Руслан Мигранов",
  text:
    "Историк моды, куратор выставочных проектов и научный сотрудник в «Музее моды», преподаватель дисциплин по истории костюма и моды XX века в ведущих вузах Москвы. Автор и ведущий арт-туров в Венецию и Париж.",
  instagram: false,
  buttons: {
    detail: "/ruslan_migranov",
    linkInside: true
  }
};

export const stulov = {
  image: "/img/fashion-styling/stulov-st.jpg",
  name: "Евгений Стулов",
  text: (
    <List
      arr={[
        "Фотограф",
        "Преподаватель курса по специфике работы стилиста на fashion съемках; истории и практической стороне фотографии в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
        "Креативный директор Siberian Fashion Week",
        "Музыкальный продюсер, композитор, основатель и участник электронного проекта SOLARIS"
      ]}
    />
  ),
  instagram: "solaris_foto",
  buttons: {
    detail: false,
    video: "Fps72ns-MyI"
  }
};

export const maltseva = {
  image: "/img/fashion-styling/maltseva-st.jpg",
  name: "Евгения Мальцева",
  text: (
    <List
      arr={[
        "Режиссер-постановщик модных показов",
        "Преподаватель курса «Стилизация модных показов» в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
        "Президент Siberian Fashion Week. CEO / Founder European Model Academy",
        "Профессиональный коуч по дисциплинам: дефиле, фотопозирование. Автор мастер-классов для профессиональных моделей и директоров модельных агентств"
      ]}
    />
  ),
  instagram: "evmaltseva",
  buttons: false
};

export const zhilova = {
  image: "/img/fashion-styling/zhilova-e.jpg",
  name: "Эмилия Жилова",
  text: (
    <List
      arr={[
        "Продюсер фотосъемок, мужской стилист-имиджмейкер, beauty/fashion фотограф",
        "Преподаватель курса  «Продюсирование fashion съемок» в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
        "Многократный призер фотоконкурсов (International Photography Awards, RussianArtWeek), колумнист Designers From Russia https://designersfromrussia.ru/",
        "MBA в РЭУ им. Плеханова, работа на руководящих должностях"
      ]}
    />
  ),
  instagram: "emiliazhilova",
  buttons: false
};

export const prohorovich = {
  image: "/img/fashion-styling/prohorovich.jpg",
  name: "Елена Прохорович",
  text:
    "Автор и куратор курсов Высшей школы стилистики дизайна и технологий. Специалист по fashion-коммуникациям, рекламе и PR, комму­ника­ционный тренер, ТВ-эксперт, предприниматель. Идеолог и основатель международных комму­ника­цион­ных образовательных проектов: Стажировки на Milan fashion week / Milan design week; Summer fashion school in Italy;",
  instagram: false,
  buttons: false
};

export const prohorovich2 = {
  image: "/img/teachers/320x240/elena-prohorovich.jpg",
  name: "Елена Прохорович",
  text:
    "Специалист по fashion-коммуникациям, рекламе и PR. Комму­ника­ционный тренер, ТВ-эксперт. Идеолог и организатор международных стажировок на Milan fashion week / Milan design week.",
  instagram: false,
  buttons: false
};

export const gorskih = {
  image: "/img/about/natalya-gorskih.jpg",
  name: "Наталья Горских",
  text:
    "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
  instagram: false,
  buttons: {
    detail: "/natalya_gorskyh",
    linkInside: true
  }
};

export const gorskih2 = {
  image: "/img/teachers/320x240/natalya-gorskih.jpg",
  name: "Наталья Горских",
  text:
    "К.филол.наук, преподаватель дисциплин по теории и социологии моды, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
  instagram: false,
  buttons: {
    detail: "/natalya_gorskyh",
    linkInside: true
  }
};

export const gorskih3 = {
  image: "/img/about/natalya-gorskih.jpg",
  name: "Наталья Горских",
  imageFilter: true,
  text:
    "К.филол.наук, преподаватель дисциплин по теории и социологии моды, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
  instagram: false,
  buttons: {
    detail: "/natalya_gorskyh",
    linkInside: true
  }
};

export const rodina = {
  image: "/img/political-style/teacher01.jpg",
  name: "Мария Родина",
  text:
    "Кандидат политических наук, психолог, бизнес-тренер, политтехнолог, Директор фонда «Общественных инициатив»",
  instagram: false,
  buttons: {
    detail: "/maria_rodina",
    linkInside: true
  }
};

export const kostarnova = {
  image: "/img/teachers/kostarnova.jpg",
  name: "Марина Костарнова (Бельгия)",
  text: (
    <>
      <p>Дизайнер & декоратор & coach</p>
      <p>Практик и теоретик дизайна</p>
      <p>Специалист в области концептуального средового дизайна</p>
      <p>Член международного союза дизайнеров</p>
    </>
  ),
  instagram: "mara_van_brussels",
  buttons: {
    detail: "/marina_kostarnova",
    linkInside: true
  }
};

export const moatti = {
  image: "/img/teachers/moatti.jpg",
  name: "Юлия Моатти (Франция, Канны)",
  text: (
    <List
      arr={[
        <>Fashion стилист (Лазурный берег)</>,
        <>16 лет опыта в международной fashion индустрии</>,
        <>проекты для Valentino, YSL, Fendi, Linda Farrow</>,
        <>
          рекламные компании и editorial съемки для Eric Zemmour, Chopard,
          Misaki, журналов L'Officiel, Riviera Magazine, Lucy mag, Huf mag и др.
        </>
      ]}
    />
  ),
  instagram: "yuliamoatti_stylist",
  buttons: false
};

export const lamonova = {
  image: "/img/teachers/lamonova_g.jpg",
  name: "Елена Ламонова (Москва), преподаватель очного курса",
  text: (
    <>
      Дизайнер. Специалист в области интерь&shy;ерного, ландшафтного,
      предмет&shy;ного ди&shy;зайна, создатель концепций и проектов
      оформ&shy;ления меро&shy;приятий всерос&shy;сий&shy;ского уровня,
      создатель собст&shy;венного дизайн бюро, преподаватель Высшей школы
      стилистики
    </>
  ),
  instagram: false,
  buttons: {
    detail: "/elena-lamonova",
    linkInside: true
  }
};

export const rotar = {
  image: "/img/living-interior-design-and-decoration/rotar.jpg",
  name: "Татьяна Ротарь (Россия, Италия)",
  text: "Дизайнер интерьера, преподаватель #ВШСДТ",
  instagram: false,
  buttons: {
    detail: "/tatyana_rotar",
    linkInside: true
  }
};

export const nenasheva = {
  image: "/img/living-interior-design-and-decoration/nenasheva.jpg",
  name: "Эрика Ненашева (Россия)",
  text:
    "Практикующий дизайнер интерьера. Художник. Технический директор собственной студии дизайна интерьеров ArtStreet Design.",
  instagram: false,
  buttons: {
    detail: "/erika_nenasheva",
    linkInside: true
  }
};

export const islamova = {
  image: "/img/living-interior-design-and-decoration/islamova.jpg",
  name: "Камила Исламова (Италия)",
  text:
    "Сейчас я являюсь руководителем и главным дизайнером ателье интерьеров Dreamers.",
  instagram: false,
  buttons: {
    detail: "/islamova",
    linkInside: true
  }
};

export const zolotova = {
  image: "/img/teachers/zolotariova.jpg",
  name: "Виктория Золотова (США, Калифорния)",
  text: (
    <>
      <List
        arr={[
          <>Специалист по маркетингу и продвижению брендов</>,
          <>Основатель проектов Fashionovation и Innovation Camp</>,
          <>Организатор международных образовательных туров (fashion, IT)</>
        ]}
      />
      <p style={{ textAlign: "center" }}>
        <img
          src="/img/green/earth.svg"
          style={{ height: 24, marginBottom: -6 }}
        />
        &emsp;
        <a href="https://fashionovation.us" target="_blank">
          fashionovation.us
        </a>
      </p>
    </>
  ),
  instagram: false,
  buttons: false
};

export const gileva = {
  image: "/img/teachers/320x240/gileva.jpg",
  name: "Анна Гилева",
  text: (
    <p className="p-constructor">
      Куратор ассоциации: Гилёва Анна. Практикующий стилист-имиджмейкер.
      Выпускница, преподаватель Высшей школы стилистики, дизайна и технологии.
      Юрист, преподаватель с 9-летним опытом, методолог образовательных
      программ.
    </p>
  ),
  instagram: false,
  button: false
};
