import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Pattern, { styles } from "../../components/pattern-page-of-course";

const Typology = props => {
  return (
    <Pattern
      title="История стилей в интерьере"
      faq="Курсы"
      short="Курс | 3 месяца | 28 занятий | 16 академ. часов | ONLINE"
      teacher={{
        text: (
          <div>
            <p>
              Ведущая курса &ndash; Галина Варакина. Эксперт Российского
              научного и Российского гуманитарного научного фондов. Член
              редакционной коллегии журнала «Культура и цивилизация».
            </p>
            <p>
              Изучение курса предоставит Вам базовые знания о конструктивной
              природе интерьера, многообразии форм и архитектурных деталей,
              применимых в интерьере, в том числе о форматности оконного и
              дверного проемов, вариативности поверхностей пола, потолка и стен,
              выразительных возможностях архитектурного декора и стационарного
              оборудования (камин, перегородки, встроенная мебель, освещение).
            </p>
            <p>
              Цель: понять конструктивную природу интерьера и возможности его
              декоративного осмысления.
            </p>
          </div>
        ),
        img: "/img/about/galina-varakina.jpg",
        name: "Галина Варакина"
      }}
      leftTile={
        <div>
          <h3 style={styles.h3}>Очно</h3>
          <p style={styles.p}>Внесите аванс 2000 рублей</p>
          <p style={styles.p}>Оформите документы</p>
          <p style={styles.p}>Забронируйте место в группе</p>
          <a href="">
            <div style={styles.button}>забронировать</div>
          </a>
        </div>
      }
      rightTile={
        <div>
          <h3 style={styles.h3}>Стоимость</h3>
          <p style={styles.p}>
            Для записи на курс внесите аванс и оформите договор.
          </p>
          <p style={styles.p}>
            В случае отказа от обучения аванс возвращается.
          </p>
        </div>
      }
      programm={
        <div>
          <h3 style={styles.h3}>Теория (20 академических часов)</h3>
          <Paragraph
            content={[
              "Предметное наполнение интерьеров в культурах Древнего мира и Средневековья",
              <List
                arr={[
                  "Дом и его наполнение в Древнем Египте.",
                  "Древнегреческий мегарон и его предметное наполнение.",
                  "Древнеримский дом, вариативность жилища, особенности предметного наполнения.",
                  "Особенности романского интерьера.",
                  "Особенности готического интерьера.",
                  "Архитектура и интерьер в эпоху Ренессанса."
                ]}
                style={styles.list}
              />,
              "Барокко и рококо в европейском интерьере XVII-XVIII вв.",
              <List
                arr={[
                  "Барочное понимание интерьера как ансамбля.",
                  "Образ райского сада в рокайльном интерьере.",
                  "Национальные особенности барокко и рококо в русском интерьере XVIII в."
                ]}
                style={styles.list}
              />,
              "Классицизм и его модификации в европейском интерьере XVII-XIX вв.",
              <List
                arr={[
                  "Классицизм в интерьере XVII века.",
                  "Неоклассицизм в интерьере 2 половины XVIII века.",
                  "Стиль ампир в европейском интерьере.",
                  "Национальные особенности русского классицизма в интерьере."
                ]}
                style={styles.list}
              />,
              "Эклектика и ранний функционализм в архитектуре и интерьере.",
              <List
                arr={[
                  "Бидермайер в эпоху упадка великих стилей.",
                  "Эклектика и ее вариативность в архитектуре и интерьере.",
                  "Российский историзм и его стили.",
                  "Рациональная линия в архитектуре и интерьере середины – второй половины XIX века."
                ]}
                style={styles.list}
              />,
              "Исторические стили, особенности их современной интерпретации.",
              "Динамика стилевого развития искусства интерьера в ХХ-XXI веках."
            ]}
          />
          <h3 style={styles.h3}>Практика (8 академических часов)</h3>
          <List
            arr={[
              "Развитие профессиональных навыков через понимание целостности стиля, взаимодополняемости его элементов."
            ]}
            style={styles.list}
          />
        </div>
      }
    />
  );
};

export default Typology;
