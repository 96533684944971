import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./content-test";
import Page from "../../components/page";
import Papers from "../../components/papers/papers";
import { Tiles } from "../../components/tiles/tiles";
import { MiniForm } from "../../components/forms";
import Slider from "../../components/slider-rk-bkg";
import {
  HeadBlock,
  BuilderLayout,
  Text3Block,
  ShessBlock,
  HowIsTheTrainingGoing,
  TitleH2,
  Teachers
} from "../../patterns/pattern-fs/components";

function Interior(props) {
  return (
    <div>
      <Helmet>
        <title>{c.titlePage}</title>
        <meta name="keywords" content={"ВШСДТ: " + c.titlePage} />
      </Helmet>
      <Page lable="Сам себе дизайнер">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} vAlign="flex-start" noButtons />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                left: <Slider images={c.text1.image} infinity white />,
                right: c.text1.text
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
            />,
            <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />,
            <>
              <TitleH2 content="Программа курса" scroll="programma" />
              <Tiles content={c.tilesProgramma} />
            </>,
            <Teachers content={{ teachers: c.teachers, teacherHead: false }} />,
            <>
              <TitleH2 content="Купите курс и консультируйтесь с профессиональным дизайнером каждый месяц" />
              <ShessBlock
                content={{
                  left: c.curent.image,
                  right: c.curent.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content="Варианты обучения, стоимость" scroll="price" />
              <Papers content={c.insertAfterDates} />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} />
              <MiniForm formName="miniforma-interior-design" />
            </>
          ]}
        />
      </Page>
    </div>
  );
}

export default Interior;
