import React from "react";
import List from "../../components/list";
import { Link } from "react-router-dom";
import "./styles.css";
import Ig from "../../components/instagram";
import ButtonMessages from "../../components/button-messages";

export const content = {
  head: {
    head: "Предстоящие мероприятия",
    lable: "Эфиры, встречи"
  },
  head2: {
    head: "Прошедшие мероприятия"
  },
  news: [
    {
      date: new Date(2021, 9, 18, 12, 0),
      headDescript: "18 октября 2021",
      head: (
        <>
          Международный вебинар: 7 шагов к построению и запуску успешного
          Fashion бизнеса
        </>
      ),
      headAfterDescript: (
        <>
          Построение любого бизнеса состоит из определенных неотъемлемых частей,
          об этом надо знать и применять свои знания.
        </>
      ),
      descript: [
        <>
          Для создания успешного fashion business творческому дизайнеру
          необходимо знать алгоритмы предпринимательства и маркетинга, и быть в
          курсе новых технологий, которые дают дополнительные возможности.
        </>,
        <>
          Приглашаем на открытый вебинар, где спикеры расскажут
          последовательность мышления и действий на каждом шаге
        </>,
        <>Спикеры:</>,
        <List
          arr={[
            <>
              Виктория Золотова <Ig a="shantyvi" />
            </>,
            <>
              Анастасия Риводо <Ig a="anastasiarivodeaux" />
            </>
          ]}
        />,
        <>
          Регистрация обязательна на{" "}
          <a href="https://fashionovation.us/web_7steps" target="_blank">
            fashionovation.us
          </a>
        </>,
        <a href="https://www.youtube.com/watch?v=4J4xUpheDOs" target="_blank">
          <div className="button">смотреть</div>
        </a>
      ],
      imageSource: "/img/master-class/viktoria.webp",
      noVideo: true
    },
    {
      date: new Date(2021, 3, 10, 13, 30),
      headDescript: "10 апреля 2021",
      head: <>Цвет в моде.</>,
      headAfterDescript: <>Презентации на тему влияния цвета на нашу жизнь.</>,
      descript: [
        <List
          arr={[
            <>Психология цвета</>,
            <>Цвет в одежде, чтобы производить нужное впечатление</>,
            <>Как использовать цвет, чтобы создать определённое настроение</>,
            <>Цвет в бизнесе. Как известные бренды выбирают свою палитру</>,
            <>Цвет в Вашем личном стиле</>,
            <>Ваши цвета по гороскопу</>
          ]}
        />,
        <>
          Всё это в лекциях от стилиста-имиджмейкера с опытом проведения
          презентаций более 15 лет, выпускницы Высшей Школы Стилистики Дизайна и
          Технологий – Татьяны Кузиной <Ig a="tatianastylelife" />
        </>,
        <>Все слушатели получат бесплатную консультацию по стилю.</>,
        <>
          Регистрация обязательна на{" "}
          <a href="https://beinfashion.ru" target="_blank">
            beinfashion.ru
          </a>
        </>,
        <>
          Мероприятие пройдёт на территории ТЕХНОГРАД Урбан. (
          <a href="/download/tehnograd-urban-map.pdf">скачать схему проезда</a>)
        </>
      ],
      imageSource: "/img/master-class/news-10-04-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 3, 3, 13, 30),
      headDescript: "3 апреля 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Презентации от выпускников Высшей Школы Стилистики Дизайна и
          Технологий.
        </>
      ),
      descript: [
        <>
          Лекция Галины Володарской на тему «Как управлять впечатлением с
          помощью одежды»
        </>,
        <List
          arr={[
            <>как изменить имидж за 1 день</>,
            <>как начать транслировать нужное впечатление</>,
            <>быстрая трансформация «из мамочки в бизнес вумен»</>,
            <>разберём преображения образов женщин после работы стилиста</>,
            <>все посетители получат консультацию стилиста</>
          ]}
        />,
        <>
          Регистрация обязательна на{" "}
          <a href="https://beinfashion.ru" target="_blank">
            beinfashion.ru
          </a>
        </>,
        <>
          Мероприятие пройдёт на территории ПРОМ ТЕХНОГРАД (главное здание —
          павильон 63) в лекционном зале (
          <a href="/download/tehnograd-vshsdt-map.pdf">скачать схему проезда</a>
          )
        </>
      ],
      imageSource: "/img/master-class/news-03-04-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 2, 27, 13, 30),
      headDescript: "27 марта 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча руководителя ВШСДТ Ивана Гулиенко с Еленой Прохорович —
          маркетологом, бизнес-тренером, обучающим бизнес-стратегиям,
          технологиям брендинга, коммуникаций.
        </>
      ),
      descript: [
        <>
          С самого начала работы в индустрии моды и дизайна очень важно
          выстраивать свой личный бренд.
        </>,
        <>«Личный бренд. С чего начать?»</>,
        <>
          На встрече поговорим о концепции, позиционировании, продукте,
          планировании стратегии Личного бренда.
        </>,
        <>Елена Прохорович — преподаватель и автор курсов в ВШСДТ:</>,
        <List
          arr={[
            "Личный бренд в индустрии моды и дизайна",
            "Международные коммуникации, вывод бренда на глобальный рынок",
            "Технологии запуска StartUp- проектов"
          ]}
        />,
        <>
          Идеолог и основатель международных коммуникационных образовательных
          проектов:
        </>,
        <List
          arr={[
            <>Стажировки на Milan fashion week / Milan design week</>,
            <>Summer fashion school in Italy</>,
            <>
              Руководитель Центра Карьеры Высшей Школы Стилистики Дизайна и
              Технологий #ВШСДТ
            </>
          ]}
        />,
        <>Регистрация на мероприятие</>
      ],
      imageSource: "/img/master-class/news-27-03-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 2, 20, 13, 30),
      headDescript: "20 марта 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: <>Методики определения индивидуального стиля.</>,
      descript: [
        <>
          С чего начать, этапы создания образа под имиджевый запрос в
          презентации от Высшей Школы Стилистики Дизайна и Технологий #ВШСДТ —
          выпускницы Татьяны Кузиной.
        </>,
        <>На встрече</>,
        <List
          arr={[
            <>разбор инструментов для создания личного стиля.</>,
            <>
              наглядная демонстрация работы стилиста на примере модели –
              посетительнице предыдущей лекции.
            </>,
            <>ответы на вопросы по стилю и имиджу для всех присутствующих.</>,
            <>все посетители могут получить консультацию стилиста.</>
          ]}
        />,
        <>Необходима регистрация. Количество мест ограничено.</>,
        <>
          Если Вы не успели зарегистрироваться, пишите в директ{" "}
          <Ig a="styleschool_msk" /> c пометкой «Техноград – консультация
          стилиста»
        </>,
        <>
          Визажист мероприятия Алёна Рябчевская <Ig a="alena_makeup_pro" />
        </>,
        <>
          <a href="https://technograd.moscow/corps/poster/shkola_junogo_stilista/6236.html">
            <div
              className="button-white-black"
              style={{ width: "max-content", margin: "1rem auto" }}
            >
              Зарегистрироваться
            </div>
          </a>
        </>
      ],
      imageSource: "/img/master-class/news-06-03-2021.jpg",
      noVideo: true
    },

    {
      date: new Date(2021, 2, 6, 13, 30),
      headDescript: "6 марта 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: false,
      descript: [
        <>
          Презентация с разбором модных тенденций от стилистов-выпускников
          Высшей Школы Стилистики, учебного заведения, дающего высшее
          образование в сфере моды и дизайна уже более 20 лет.
        </>,
        <>
          Татьяна Кузина — выпускница с опытом публичных выступлений более 15
          лет расскажет об актуальных образах сезона весна-лето 2021 на примерах
          российских дизайнеров. <Ig a="tatianastylelife" />
        </>,
        <>
          Интерактивная программа с показом модных трендов на примерах образов
          из шоурума Адрес Стиля <Ig a="adres_style" />
        </>,
        <>
          Шоурум предлагает итальянские бренды и более 500 готовых луков,
          подобранных стилистами. Мы рассмотрим самые актуальные из них.
        </>
      ],
      imageSource: "/img/master-class/news-06-03-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 2, 13, 16, 30),
      headDescript: "13 марта 16:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча руководителя ВШСДТ Ивана Гулиенко с фэшн-журналистом, автором
          проекта atelier-magazine.ru, директором Центра развития компетенций в
          области фэшн-индустрии и фэшн-ритейла РЭУ им.&nbsp;Плеханова Светланой
          Костенко.
        </>
      ),
      descript: [
        <>
          Кто такой фэшн-журналист и где на него учат? Чем журнал Vogue
          отличается от журнала Cosmopolitan и выживут ли бумажные журналы в
          эпоху Интернета? Как стать модным блогером и нужно ли идти в ТикТок?
          Эти и другие вопросы обсудит Иван со Светланой, а автор самого
          интересного вопроса из зала получит в подарок номер раритетнего
          журнала «Ателье».
        </>,
        <>
          Фотограф и видеограф мероприятия Ульрих Гребенштай{" "}
          <Ig a="urickh_gribenshtaine" />
        </>
      ],
      imageSource: "/img/master-class/news-13-03-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 1, 13, 16, 30),
      headDescript: "13 февраля 16:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript:
        "Онлайн встреча руководителя ВШСДТ Ивана Гулиенко с блогером Мари Лис (США)",
      descript: [
        <>
          Мари Лис{" "}
          <a href="https://instagram.com/maryleest" target="_blank">
            @maryleest
          </a>{" "}
          успешный блогер, которая к 25 годам имеет более 1 млн подписчиков в
          инстаграм, а главное – огромный опыт работы в фэшн индустрии. В её
          портфолио сотрудничества с такими брендами как Hugo Boss, Giorgio
          Armani Beauty, Max&nbsp;&&nbsp;Co., Tommy Hilfiger, Givenchy, Michael
          Kors, Max Mara и другие.
        </>,
        <>
          Мари родилась в Курске, была прилежной девочкой-отличницей, которая
          хотела жить в Париже и мечтала стать моделью. С 13 лет она начала
          делать планомерные шагу в сторону своей мечты. Пошла на конкурс Little
          Supermodel of Kursk. В 14 лет получила первую работу модели в Москве.
          В 15 лет начала вести модный блог. А дальше стала много работать, не
          обращала внимание на неудачи, нарабатывала опыт и портфолио, в том
          числе брала бесплатные проекты.
        </>,
        <>
          О своём пути в сфере модной индустрии, о сложностях, которые
          приходилось преодолевать и успехе, которого она в итоге добилась
          поговорим в субботу 13 февраля.
        </>
        // <a href="https://technograd.moscow/corps/online/vebinary/5996.html">
        //   <div
        //     className="button-white-black"
        //     style={{ width: "max-content", margin: "1rem auto" }}
        //   >
        //     Зарегистрироваться
        //   </div>
        // </a>
      ],
      imageSource: "/img/master-class/news-13-02-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2021, 1, 27, 16, 30),
      headDescript: "27 февраля 16:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript:
        "Онлайн встреча руководителя ВШСДТ Ивана Гулиенко с Еленой Виноградовой — экспертом по продажам и закупкам в fashion-бизнесе.",
      descript: [
        <>Елена работает в индустрии моды 17 лет:</>,
        <List
          arr={[
            <>
              прошла путь от помощника байера до коммерческого директора
              розничной сети (30 магазинов)
            </>,
            <>
              автор делового блога для магазинов одежды, обуви и аксессуаров{" "}
              <a
                href="https://instagram.com/fashion.business.blog"
                target="_blank"
              >
                @fashion.business.blog
              </a>
            </>,
            <>
              эксперт профильных СМИ в fashion-индустрии (более 50 публикаций),
              спикер деловых выставок и конференций
            </>,
            <>
              ведёт курсы и тренинги для байеров, собственников и персонала
              магазинов с ростом выручки, среднего чека и конверсии
            </>
          ]}
        />,
        <>
          На встрече в субботу 27 февраля Елена расскажет об особенностях своей
          профессии – управлении закупками, продажами и сервисом в
          fashion-бизнесе.
        </>
        // <a href="https://technograd.moscow/corps/online/vebinary/5996.html">
        //   <div
        //     className="button-white-black"
        //     style={{ width: "max-content", margin: "1rem auto" }}
        //   >
        //     Зарегистрироваться
        //   </div>
        // </a>
      ],
      imageSource: "/img/master-class/news-27-02-2021.jpg",
      noVideo: true
    },
    {
      date: new Date(2020, 11, 26, 13, 30),
      headDescript: "26 декабря 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript:
        "Онлайн встреча руководителя ВШСДТ Ивана Гулиенко со стилистом-шоппером Юлией Моатти (Франция)",
      descript: [
        <>
          Юлия Моатти{" "}
          <a href="https://instagram.com/yuliamoatti_stylist">
            @yuliamoatti_stylist
          </a>
          , по первому образованию выпускница факультета дизайна одежды
          Московского государственного университета дизайна и технологий, после
          обучения переехала во Францию и покорила Лазурный берег.
        </>,
        <>
          За 16 лет в международной fashion индустрии Юлия работала с такими
          домами мод, как Valentino, YSL, Fendi, Linda Farrow. В ее арсенале
          рекламные компании и editorial съемки для Eric Zemmour, Chopard,
          Misaki, журнала L'Officiel, Riviera Magazine, Lucy mag, Huf mag и др.
          И конечно, Юлия консультирует клиентов по стилю и проводит шопинг во
          Франции.
        </>,
        <>
          О секретах успеха Юлии Моатти, о возможностях творческой реализации в
          профессии стилиста во Франции, а так же о модных трендах в предверии
          встречи 2021 года, мы будем говорить в субботу, 26 декабря в 13:30
        </>,
        <a href="https://technograd.moscow/corps/online/vebinary/5996.html">
          <div
            className="button-white-black"
            style={{ width: "max-content", margin: "1rem auto" }}
          >
            Зарегистрироваться
          </div>
        </a>
      ],
      imageSource: "/img/master-class/news-26-12-2020.jpg",
      noVideo: true
    },
    {
      date: new Date(2020, 11, 19, 13, 30),
      headDescript: "19 декабря 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript:
        "Онлайн встреча руководителя центра карьеры ВШСДТ Елены Прохорович с основательницей ателье и известным дизайнером одежды Еленой Брежневой",
      descript: [
        <>
          Еще в 1993 году Елена Брежнева произвела на свет свои первые
          дизайнерские вещи. 14 лет кропотливого труда и занятия любимым делом
          принесли свои плоды. В 2007 году в Воронеже она открыла свое первое
          ателье.
        </>,
        <>
          За всё это время ателье elenabrezhneva.ru заслужило солидный уровень
          популярности. На суд искушенной публике Елена ежегодно предлагает
          классические и повседневные коллекции для мужчин и женщин.
        </>,
        <>
          Сегодня Елене Брежневой доверяют известные личности: Денис Клявер,
          Дмитрий Ермак, Сергей Дикий, Виктор Дорин, Константин Кинст, Степан
          Меньшиков, Дмитрий Галихин, Александр Асташенко, Прохор Шаляпин, барда
          Александра Розенбаума, Митя Фомин, Ярослав Брин, Томас Невергрин и
          т.д.
        </>,
        <>
          О секретах успеха Елены, о возможностях творческой реализации в
          профессии дизайнера одежды, о перспективах малого бизнеса в индустрии
          моды будем говорить в субботу, 19 декабря в 13:30
        </>,
        <a href="https://technograd.moscow/corps/online/vebinary/5939.html">
          <div
            className="button-white-black"
            style={{ width: "max-content", margin: "1rem auto" }}
          >
            Зарегистрироваться
          </div>
        </a>
      ],
      imageSource: "/img/master-class/news-19-12-2020.jpg",
      noVideo: true
    },
    {
      date: new Date(2020, 11, 5, 13, 30),
      headDescript: "5 декабря 13:30",
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript:
        "Онлайн встреча руководителя ВШСДТ Ивана Гулиенко с экспертом fashion рынка Антоном Алфером.",
      descript: [
        <>
          Антон Алфер, известный как основатель MODA.RU, первого сайта о моде в
          России, генеральный директор Alfer Group, создатель платформы{" "}
          <a href="https://fashion.ru" target="_blank">
            FASHION.RU
          </a>{" "}
          и первого модельного портала{" "}
          <a href="https://model.ru" target="_blank">
            MODEL.RU
          </a>
          , руководитель сети группы компаний Модная Сеть, председатель
          бизнес-кластера РАЭК / FASHION TECH и комитета по цифровым технологиям
          Союзлегпрома.
        </>,
        <>
          Антон является куратором-партнёром направления индустрия моды
          арт-кластера «Таврида» и успешным продюсером многочисленных проектов в
          fashion индустрии.
        </>,
        <>
          О пути Антона, о модельном бизнесе и о fashion-индустрии в целом мы
          будем говорить уже в эту субботу!
        </>,
        <a href="https://tekhnograd-vdnkh.timepad.ru/event/1496061/">
          <div
            className="button-white-black"
            style={{ width: "max-content", margin: "1rem auto" }}
          >
            Зарегистрироваться
          </div>
        </a>
      ],
      imageSource: "/img/videos/hKRT-i54OF0.jpg",
      mediaVideo: "hKRT-i54OF0",
      noVideo: true
    },
    {
      date: new Date(2020, 10, 21, 16, 30),
      headDescript: <>21 ноября, 16:30</>,
      head: (
        <>
          Мода и стиль.
          <br />
          Ключ к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча с экспертом fashion рынка, владелицей агентства по Fashion
          мерчендайзингу Мариной Полковниковой, в проекте ВШСДТ и
          Иновационно-образовательного комплекса Техноград (ВДНХ) для юных леди
          и их мам
        </>
      ),
      descript: [
        <>
          Марина расскажет, как стать основательницей своего бизнеса в индустрии
          моды, о перспективах бизнеса и карьеры в индустрии моды.
        </>,
        <>
          <strong>Марина Полковникова</strong>
          &emsp;
          <a
            href="https://instagram.com/marinapolkovnikova_official"
            target="_blank"
          >
            @marinapolkovnikova_official
          </a>
          &nbsp; — основатель компании VMC Retail и онлайн школы VMC Education.
          Эксперт по визуальному мерчендайзингу и ритейл дизайну.
        </>,
        <>
          Опыт работы с компаниями CPMМoscow, Сколково, Планета Колготок,
          CavalliClass, Koton, 12storeez, Lenazh, ТРЦ OZМолл, ТК Макс, ТРЦ Asia
          Park, ТРЦ Март, Stels, Credit asia (бытовая техника), All Home (товары
          для дома), LoriBlu, I lovemum, Climber, Paul&shark, Tribuna,
          Woolstreet, Boscosportи др.
        </>,
        // https://technograd.moscow/corps/online/vebinary/5840.html
        <a href="https://technograd.moscow/corps/online/vebinary/5840.html">
          <div
            className="button-white-black"
            style={{ width: "max-content", margin: "1rem auto" }}
          >
            Зарегистрироваться
          </div>
        </a>
      ],
      imageSource: "/img/master-class/news-21-11-2020-c.jpg",
      noVideo: true
    },
    {
      date: new Date(2020, 10, 14, 16, 30),
      headDescript: <>14 ноября, 16:30 онлайн</>,
      head: (
        <>
          Мода и стиль.
          <br />
          Профессия, которую я выбираю
        </>
      ),
      headAfterDescript: (
        <>
          Встреча с экспертом fashion рынка Еленой Письменской и имидж-стилистом
          Лидой Акимовой
        </>
      ),
      descript: [
        <>
          Совместный публичный проект ВШСДТ и Иновационно-обраовательного
          комплекса Техноград (ВДНХ) для юных леди и их мам
        </>,
        <>
          <strong>Елена</strong>{" "}
          <a href="https://instagram.com/lepsik5" target="_blank">
            @lepsik5
          </a>{" "}
          — эксперт рынка детской одежды, основатель консалтингового агентства
          Kids Fashion Retail. Постоянный спикер отраслевых выставок и форумов.
          Она поделиться с Вами 17-летним опытом работы в области
          Sales&Marketing с такими компаниями, как Спортма­стер, Детская Галерея
          Якиманка, Gulliver, Шалуны, Faberlic-Kids, Смена и др. А так же
          расскажет о поколение Alfa, перспективах развития в России и
          зарубежом, и конечно главных трендах подростковой моды.
        </>,
        <>
          <strong>Лидия</strong>{" "}
          <a href="https://instagram.com/lididilisa" target="_blank">
            @lididilisa
          </a>{" "}
          — дипломированный имидж-стилист с опытом работы более 6 лет, в ее
          портфолио тысячи ревизий гардероба ии стильных перевоплощений. Лидия
          является спикером на крупных площадках Москвы, проводит лекции и
          вебинары, ведёт информативный блог о доступной моде и
          индивидуальности. И на встрече расскажет о своем опыте успешного
          вхождения в профессию стилиста и конечно о модных трендах текущего
          сезона, для Мам
        </>,
        <>
          {/* <strong>Адрес:</strong> ВДНХ, Иновационно-обраовательный комплекс{" "}
          <a href="https://technograd.moscow" target="_blank">
            «Техноград»
          </a> */}
          Встреча перенесена в онлайн формат.
        </>,

        <a href="https://technograd.moscow/corps/online/vebinary/5829.html">
          <div
            className="button-white-black"
            style={{ width: "max-content", margin: "1rem auto" }}
          >
            Зарегистрироваться
          </div>
        </a>
      ],
      imageSource: "/img/master-class/new-14-11-2020.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 9, 31, 16, 0),
      headDescript: <>31 октября, 16:00</>,
      head: (
        <>
          Мода и стиль.
          <br />
          Путь к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча с руководителем ВШСДТ Иваном Гулиенко и директором European
          Model Academy Евгенией Мальцевой
        </>
      ),
      descript: [
        <>
          Совместный публичный проект ВШСДТ и Техноград (ВДНХ) для детей и
          подростков
        </>,
        <>
          <strong>Тема:</strong> Профессия: fashion модель
        </>,
        <>
          На встрече говорим:
          <List
            arr={[
              "о подводных камнях модельного бизнеса, или как быстро и без особых затрат стать моделью",
              "о портфолио, для чего оно нужно, о коммерческих и TFP съёмках",
              "как правильно выбирать агента",
              "как выбирать проекты, на что стоит откликаться, а на что нет"
            ]}
          />
        </>,
        <>
          <strong>Адрес:</strong> ВДНХ, Иновационно-обраовательный комплекс{" "}
          <a href="https://technograd.moscow" target="_blank">
            «Техноград»
          </a>
        </>
      ],
      imageSource: "/img/master-class/new-31-10-2020.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 9, 24, 16, 0),
      headDescript: <>24 октября, 16:00</>,
      head: (
        <>
          Мода и стиль.
          <br />
          Путь к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча с руководителем ВШСДТ Иваном Гулиенко и имидж-экспертом,
          автором книг-бестселлеров по моде и стилю Инессой Трубецковой
        </>
      ),
      descript: [
        <>
          Совместный публичный проект ВШСДТ и Техноград (ВДНХ) для детей и
          подростков
        </>,
        <>
          <strong>Тема:</strong> Профессия: стилист, имидж-консультант
        </>,
        <>
          На встрече говорим о том:
          <List
            arr={[
              "как становятся успешными имидж-консультантами",
              "как раскрывается творческий потенциал",
              "что необходимо знать о профессии",
              "обзор принципов подбора цвета, кроя, стиля в одежде"
            ]}
          />
        </>,
        <>
          <strong>Адрес:</strong> ВДНХ, Иновационно-обраовательный комплекс{" "}
          <a href="https://technograd.moscow" target="_blank">
            «Техноград»
          </a>
        </>
      ],
      imageSource: "/img/master-class/new-24-10-2020.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    // ],
    // pastEvents: [
    {
      date: new Date(2020, 9, 22, 20, 0),
      headDescript: <>22 октября, 20:00</>,
      head: (
        <>
          Триумф черного платья.
          <br />
          <span
            style={{
              fontSize: ".6em",
              fontStyle: "italic"
            }}
          >
            Создай свой неповторимый стиль
          </span>
        </>
      ),
      headAfterDescript: (
        <>Прямой эфир с Иваном Гулиенко и Инессой Трубецковой</>
      ),
      descript: [
        <>Ведущий эфира, CEO ВШСДТ Иван Гулиенко @styleschool.moda</>,
        <>Приглашенный гость — автор книг-бестселлеров Инесса Трубецкова.</>,
        <>
          В эфире говорим о книге Инессы Трубецковой и Натальи Найденской
          "Триумф черного платья. Создай свой неповторимый стиль".
        </>,
        <>Говорим о важности черного платья в гардеробе каждой женщины.</>,
        <>
          Даже если вы не любите черный, то знайте, что черное платье может быть
          и других оттенков. Важен принцип сочетаемости, виды платьев,
          особенности выбора обуви и аксессуаров к ним и их уместность. Плюс в
          книге рассмотрены особенности различных стран и замечательные
          дизайнеры, которые признаются в любви к маленькому черному платью!
        </>,
        <>
          Смотрите трансляцию 22 октября в 20:00 в{" "}
          <a href="https://instagram.com/styleschool_msk" target="_blank">
            @styleschool_msk
          </a>{" "}
          и{" "}
          <a
            href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
            target="_blank"
          >
            YouTube
          </a>
        </>
      ],
      imageSource: "/img/master-class/new-22-10-2020-v.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 9, 17, 16, 0),
      headDescript: <>17 октября, 16:00</>,
      head: (
        <>
          Мода и стиль.
          <br />
          Путь к успеху
        </>
      ),
      headAfterDescript: (
        <>
          Встреча с руководителем Высшей школы стилистики, дизайна и технологий
          Иваном Гулиенко
        </>
      ),
      descript: [
        <>
          Совместный публичный проект ВШСДТ и Техноград (ВДНХ) для детей и
          подростков
        </>,
        <>
          Открываем цикл встреч со специалистами индустрии моды и красоты, на
          которых эксперты рассказывают свой путь в профессию, специфику карьеры
          и бизнеса в индустрии моды.
        </>,
        <>Адрес: ВДНХ, Техноград</>
      ],
      imageSource: "/img/master-class/new-17-10-2020.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 9, 14, 20, 0),
      headDescript: <>14 октября, 20:00</>,
      head: (
        <>
          Маленькие секреты большого гардероба
          <br />
          Прямой эфир
        </>
      ),
      headAfterDescript: (
        <>Прямой эфир Иваном Гулиенко и Инессой Трубецковой.</>
      ),
      descript: [
        <>
          Ведущий эфира, CEO ВШСДТ Иван Гулиенко{" "}
          <a href="https://instagram.com/styleschool.moda" target="_blank">
            @styleschool.moda
          </a>
        </>,
        <>
          Приглашенный гость — автор книг-бестселлеров Инессой Трубецковой{" "}
          <a href="https://instagram.com/inessa.trubetskova" target="_blank">
            @inessa.trubetskova
          </a>
          .
        </>,
        <>
          Говорим об оттенках, их использовании в различных стилях, по сезонам и
          многом другом.
          {/* Говорим о семи главах книги, о семи основных цветах, про все их
          оттенки. Затрагиваем темы историй, связанных с различными цветами,
          палитрами и названиями оттенков, использования их в различных стилях,
          по сезонам и многое другое. */}
        </>,
        <>
          Смотрите трансляцию 14 октября в 20:00 в @styleschool_msk и{" "}
          <a
            href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
            target="_blank"
          >
            YouTube
          </a>
        </>
      ],
      imageSource: "/img/master-class/large-garderobe.jpg",
      imageMobile: "/img/master-class/large-garderobe-m.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 9, 1, 20, 0),
      headDescript: <>1 октября, 20:00</>,
      head: (
        <>
          Мода. Цвет. Стиль
          <br />
          Прямой эфир
        </>
      ),
      headAfterDescript: (
        <>Прямой эфир Иваном Гулиенко и Инессой Трубецковой.</>
      ),
      descript: [
        <>Ведущий эфира, CEO ВШСДТ Иван Гулиенко @styleschool.moda</>,
        <>Приглашенный гость — автор книг-бестселлеров Инессой Трубецковой.</>,
        <>
          Говорим об оттенках, их использовании в различных стилях, по сезонам и
          многом другом.
          {/* Говорим о семи главах книги, о семи основных цветах, про все их
          оттенки. Затрагиваем темы историй, связанных с различными цветами,
          палитрами и названиями оттенков, использования их в различных стилях,
          по сезонам и многое другое. */}
        </>,
        <>
          Смотрите трансляцию 1 октября в 20:00 в @styleschool_msk и{" "}
          <a
            href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
            target="_blank"
          >
            YouTube
          </a>
        </>
      ],
      imageSource: "/img/master-class/new-03-10-2020.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2020, 8, 30, 19, 0),
      headDescript: <>30 сентября, 19:00</>,
      head: (
        <>
          Профессия, которую я выбираю
          <br />
          Прямой эфир
        </>
      ),
      headAfterDescript: (
        <>Прямой эфир с Ольгой Коротиной и Евгенией Лябинцевой</>
      ),
      descript: [
        <>
          Ведущая эфира, продюссер стилистов, специалист Центра карьеры ВШСДТ
          Ольга Коротина @o_korotina
        </>,
        <>
          Приглашенный гость — коуч-стилист и студентка ВШСДТ Евгения Лябинцева
          @evka.1618
        </>,
        <>
          Смотрите трансляцию 30 сентября в 19:00 на @styleschool_msk и{" "}
          <a
            href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
            target="_blank"
          >
            YouTube
          </a>
        </>
      ],
      imageSource: "/img/master-class/new-28-0920-20.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2021, 10, 7),
      headDescript: <>7 ноября 2021</>,
      head: (
        <>
          Влияние модных тенденций на выбор одежды для деловых коммуникаций.
          <br />
          Психология восприятия образа.
        </>
      ),
      headAfterDescript: (
        <>
          На вебинаре вы узнаете, как с помощью правильно подобранной одежды
          можно управлять впечатлением и создать тот самый образ «успешного»
          человека.
        </>
      ),
      descript: [
        <>
          Людям свойственно стремиться к успеху и самореализации. Нам важно быть
          принятыми и одобренными обществом. Ключевыми факторами на пути к
          успеху являются и уровень нашей деловой коммуникации, и эмоциональный
          интеллект и, безусловно, наши действия. Однако не стоит недооценивать
          и важность внешнего вида, так как первое впечатление формируется
          исходя из того, как мы выглядим.
        </>,
        <>
          <strong>Спикер:</strong> Ольга Мальцева — практикующий
          имидж-консультант и выпускница ВШСДТ
        </>,
        <>
          <strong>Организаторы:</strong> Техноград и ВШСДТ
        </>,
        <>Вебинар проводиться на онлайн площадке Техноград</>,
        <a
          href="https://rutube.ru/video/753914f81bb9356fe68e7b6b28687735/"
          target="_blank"
        >
          <div className="button">Смотреть на RuTube</div>
        </a>
      ],
      imageSource: "/img/master-class/news-07-11-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2021, 9, 18, 24, 0),
      headDescript: <>28 октября 2021</>,
      head: (
        <>
          Международный вебинар: Модные тенденции SS2022.
          <br />
          Секреты Модного образа
        </>
      ),
      headAfterDescript: (
        <>
          Для тех, кто хочет правильно оценивать модные и стильные вещи,
          различать текущие тренды и базовые комплекты, понимать, в какую одежду
          стоит вкладывать деньги. Кто хочет чувствовать модный и стильный
          образ!
        </>
      ),
      descript: [
        <>
          Юлия Моатти, лучший стилист международного уровня на Лазурном берегу,
          преподаватель Высшей школы стилистики, дизайна и технологий. Она
          регулярно стилизует fashion-съемки в Италии, Франции, Швейцарии.
          Встречает своих постоянных клиентов на шопинг во Франции из Европы и
          России. Сертифицированный коуч во Франции.
        </>,
        <>
          Юлия провела вебинар по тенденциям моды SS2022. Вас ждут самые яркие
          тренды, с которыми дизайнеры не собираются прощаться в ближайшем
          будущем. Обязательно посмотрим глобальные изменения, которые придут к
          нам в 2022 году, обсудим новых скандинавских дизайнеров, которые
          покоряют fashion-рынок.
        </>,
        <>
          <strong>Организаторы:</strong> Техноград и ВШСДТ
        </>,
        <>Вебинар проводиться на онлайн площадке Техноград</>
      ],
      imageSource: "/img/master-class/news-18-10-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2021, 10, 13),
      headDescript: <>13 ноября 2021</>,
      head: (
        <>
          Гардероб деловой женщины. Как усилить влияние в<br />
          профессиональной среде с помощью одежды.
        </>
      ),
      headAfterDescript: (
        <>
          Цель практикума — получение слушателями навыков составления делового
          образа, усиливающего личное профессиональное впечатление. Практикум
          пройдет в магазине одежды M.REASON (ТРК Щука) с 11:00 до 13:00
        </>
      ),
      descript: [
        <>
          <strong>На практикуме:</strong> собираем деловые образы, с учетом
          особенностей внешности, типа фигуры и стилевого вектора условного
          клиента. Разбираем подобранные образы каждой группы.
        </>,
        <>
          Участие в практикуме доступно для студентов и выпускников ВШСДТ, а так
          же для участников программы лояльности.
        </>,
        <>
          <strong>Спикер:</strong> Гульнара Аширова{" "}
          <Ig a="open.your.upstyle" icon={true} />
        </>,
        <>
          Персональный имиджмейкер-стилист, автор обучающих семинаров по
          стилистике и типажам Внешности. Имидж-тренер, ведущая обучающих
          тренингов для персонала премиальных марок одежды. Преподаватель
          Европейской Академии Имиджа. Стилист ателье по пошиву классического
          костюма Vestoro. Мама двух дочерей.
        </>,
        <>
          <strong>Место проведения практикума:</strong> ТРК Щука, Магазин одежды
          M.REASON <Ig a="m.reason.ru" icon={true} />
        </>,
        <Link to="/news/151121">
          <div className="button">Смотреть в новостях</div>
        </Link>
      ],
      imageSource: "/img/master-class/news-13-11-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2021, 10, 18, 23, 59),
      headDescript: <>18 ноября 2021</>,
      head: <>Косметичка: инструкция по применению</>,
      headAfterDescript: (
        <>
          18 ноября в 18:00 по московскому времени состоится открытый вебинар
          для всех женщин. Вместе с визажистом Яной Ахметовой мы в прямом смысле
          слова заглянем в Ваши косметички и наведем в них порядок.
        </>
      ),
      descript: [
        <>
          <strong>На практикуме:</strong> Даже если у вас дорогие средства
          декоративной косметики, которые посоветовал ваш любимый блогер, это не
          всегда гарантия того, что отношения с этими средствами сложатся хорошо
          и они вам действительно нужны. Сколько средств вы используете
          ежедневно, что ждет своего звездного часа и выхода в свет, а что и
          вовсе лежит мертвым грузом?
        </>,
        <>
          Яна расскажет, как правильно подойти к выбору средств, чтобы они
          работали на 100%, разберет средства, которые очень заманчиво выглядят
          на полках магазинов, в итоге поселяются в ваших косметичках, но мало
          применимы в обычной жизни, поделится лайфхаками по реабилитации
          средств, которые уже куплены вами, но не используются по разным
          причинам и даст рецепт от бьюти-шопоголизма для тех, кто им страдает)
          Одним словом, все секреты личной функциональной косметички от
          визажиста и бьюти-гида с 20 летним стажем.
        </>,
        <>
          <strong>Спикер:</strong> Яна Ахметова, «играющий тренер» и на 100%
          практикующий визажист-стилист, бьюти-гид. Автор полезного блога о
          красоте <Ig a="yana_akhmetova_makeup" icon={true} />
        </>,
        <>
          <strong>Организаторы:</strong> ВШСДТ и Техноград
        </>,
        <a
          href="https://rutube.ru/video/d61d76bf8e0d26b340472f12505c0c9d/"
          target="_blank"
        >
          <div className="button">Смотреть на RuTube</div>
        </a>
      ],
      imageSource: "/img/master-class/news-11-11-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    /*
    {
      date: new Date(2021, 11, 25),
      headDescript: <>25 декабря 2021</>,
      head: <>Модный завтрак с Юлией Моатти</>,
      headAfterDescript: (
        <>
          Продолжение встреч с Юлией, теперь в Москве. Для тех, кто хочет
          правильно оценивать модные и стильные вещи, различать тренды,
          понимать, в какую одежду стоит вкладывать деньги.
        </>
      ),
      descript: [
        <strong>Темы для модного завтрака:</strong>,
        <List
          arr={[
            "Идеи стильных образов на Новый год 2022",
            "Важные тренды 2022",
            "Правильные инвестиции в свой гардероб",
            "Сумки и обувь: новые модные марки",
            "Дресс-код: стильный образ в 2022 году",
            "Антитренды, которые нельзя выкидывать. Как дать «вторую» жизнь морально устаревшим, но любимым моделям одежды в 2022!"
          ]}
          olStyle={{ paddingLeft: "1rem" }}
        />,
        <strong>Спикер:</strong>,
        <>
          Юлия Моатти <Ig a="yuliamoatti_stylist" />, лучший стилист
          международного уровня на Лазурном берегу, преподаватель Высшей школы
          стилистики, дизайна и технологий. Она регулярно стилизует
          fashion-съемки в Италии, Франции, Швейцарии. Встречает своих
          постоянных клиентов на шопинг во Франции из Европы и России.
          Сертифицированный коуч во Франции.
        </>,
        <strong>Стоимость: 5000 руб.</strong>,
        "Время и место уточняются",
        <ButtonMessages
          linkType="component"
          componentLink={<div className="button">записаться</div>}
        />
      ],
      imageSource: "/img/master-class/news-25-12-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    */
    {
      date: new Date(2021, 11, 21),
      headDescript: <>21 декабря 2021</>,
      head: (
        <>
          Модные тенденции,
          <br />
          зима 2021-2022
        </>
      ),
      headAfterDescript: (
        <>
          21 декабря в 19:00 по московскому времени на каналу RuTube ВШСДТ
          состоится открытый вебинар для тех, кто хочет услышать все о трендах,
          узнать, какие вещи будут в моде долго, с помощью каких новинок в
          гардеробе выглядеть большой модницей.
        </>
      ),
      descript: [
        <>
          <b>На вебинаре:</b> Подробно обсудим, какие десятилетия на подиуме
          сейчас актуальны. Что из предложенного дизайнерами стоит брать на
          заметку. Разберемся в популярных цветах сезона.
        </>,
        <>
          Вы обязательно найдете что-то для себя. Будете в курсе модных
          тенденций и сможете понять, какую одежду и аксессуары нужно
          приобрести, чтобы гардероб вдохновлял.
        </>,
        <>
          <b>Спикер:</b> Марина Подлесных <Ig a="marina_lefay" />, персональный
          стилист-имиджмейкер. Преподаватель Высшей школы стилистики, дизайна и
          технологий. Работает в кино и на ТВ.
        </>,
        <>Организаторы: ВШСДТ и RuTube</>,
        <>Подписывайтесь на канал ВШСДТ в RuTube</>,
        <a
          href="https://rutube.ru/video/a7d26981765193a106790f0c87ff0741/"
          target="_blank"
        >
          <div className="button">смотреть нва Rutube</div>
        </a>
      ],
      imageSource: "/img/master-class/news-21-12-2021.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2022, 0, 22),
      headDescript: <>22 января 2022</>,
      head: (
        <>
          Гардероб деловой женщины. Как усилить влияние в профессиональной среде
          с помощью одежды.
        </>
      ),
      headAfterDescript: (
        <>
          Цель практикумов — получение слушателями навыков составления делового
          образа, усиливающего личное профессиональное впечатление. Практикум
          пройдет с имидж-консультантом Гульнарой Ашировой в магазине одежды
          Mezzatorre (VEGAS Кунцево)
        </>
      ),
      descript: [
        <>
          <b>На практикуме:</b> сигналы в одежде, обозначающие принадлежность к
          определенной корпоративной культуре; отражение в костюме
          профессиональных качеств клиента; форма или цвет – что первично; нота
          креатива в профессиональном образе как сигнал об умении мыслить
          нестандартно; детали и предметы образа, не допустимые в
          профессиональной среде.
        </>,
        <>
          Участие в практикуме доступно для студентов и выпускников ВШСДТ, а так
          же для участников программы лояльности.
        </>,
        <strong>
          Спикер: Гульнара Аширова <Ig icon a="open.your.upstyle" />
        </strong>,
        <>
          Имиджмейкер-стилист, автор семинаров по стилистике и типажам
          внешности. Ведущая тренингов для персонала премиальных марок одежды.
          Преподаватель Европейской академии имиджа. Стилист ателье по пошиву
          классического костюма Vestoro. Мама двух дочерей.
        </>,
        <strong>Место и время проведения практикума</strong>,
        <>
          Вегас Крокус Сити (ул. Международная, 66-й км МКАД, метро Мякинино)
          Магазин одежды Mezzatorre (1 этаж){" "}
          <a href="https://www.mezzatorre.ru/" target="_blank">
            wwww.mezzatorre.ru
          </a>
        </>,
        // <>25 декабря с 12 до 14 часов</>,
        <>22 января 2022 года 11:00−13:00</>,
        <Link to="/news/220122">
          <div className="button">Смотреть в новостях</div>
        </Link>
      ],
      imageSource: "/img/master-class/news-25-12-2021_2.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    {
      date: new Date(2022, 0, 30),
      headDescript: <>30 января 2022</>,
      head: <>Практикум «Как сочетать цвета гармонично»</>,
      headAfterDescript: (
        <>
          Умение гармонично сочетать цвета в одежде – базовый навык и волшебная
          палочка стилиста-имиджмейкера. С помощью цвета можно Текст основной:
          На практикуме вам откроются законы сочетания цветов, и вы сможете
          смело жонглировать оттенками. Уверяем, это настолько просто и
          интересно, что вы с удовольствием включитесь в эту игру и будете
          оттачивать полученные знания на практике во всех сферах жизни и
          увлечете этой игрой себя, своих клиентов и всех окружающих.
        </>
      ),
      descript: [
        <>
          Участие в практикуме доступно для студентов и выпускников ВШСДТ, а так
          же для участников программы лояльности.
        </>,
        <>
          <b>Спикер:</b> Наталья Четвергова, стилист имиджмейкер, соучредитель
          бренда дизайнерской женской одежды «Filippova&Chetvergova»{" "}
          <Ig a="Filippovachetvergova" />, <Ig a="Fch_plus" />
        </>,
        <b>Место и время проведения практикума</b>,
        <>
          ул. Большая Якиманка 22, ТЦ «Гименей», 2 этаж, бутик «Филиппова и
          Четвергова»
        </>,
        <>30 января 2022 года 12:30−14:00</>,
        <ButtonMessages
          linkType="component"
          componentLink={<div className="button">записаться</div>}
        />
      ],
      imageSource: "/img/master-class/news-30-01-2022.jpg",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    }
    /*
    ,
    {
      date: new Date(),
      headDescript: <></>,
      head: (
        <>
          
        </>
      ),
      headAfterDescript: (
        <></>
      ),
      descript: [
        <>
          
        </>
      ],
      imageSource: "/img/master-class/",
      noVideo: true,
      styleHead: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      }
    },
    */
  ]
};
