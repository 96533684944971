import React from "react";
import { Grid } from "@material-ui/core";
import Paragraph from "../../components/p-array";
import Helmet from "react-helmet";
import Head from "../../components/head-articles";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";

const color = {
  blue: "rgb(70,112,124)",
  borderBlue: "rgb(138,180,193)",
  gray: "rgb(100,100,100)"
};

export default function Dress() {
  return (
    <div>
      <Helmet>
        <title>ВШС: Марина НеSтерова: Платье, которое идет всем.</title>
        <meta
          name="keywords"
          content="ВШС: Марина НеSтерова: Платье, которое идет всем."
        />
        <meta name="title" content="Платье, которое идет всем" />
        <meta
          name="description"
          content="Платье, которое сделает вас роскошной в любом возрасте, в котором  вы сможете покорить мир"
        />
      </Helmet>
      <Top />
      <Grid
        container
        style={{ maxWidth: 1000, margin: "0 auto", padding: "0 32px" }}
        spacing={3}
        justify="center"
        alignContent="stretch"
      >
        <Grid item xs={12} md={12}>
          <Head
            head="Платье, которое идет всем."
            linkAuthor={false}
            author="Марина НеSтерова"
            instagram="https://instagram.com/nesterova_marina_style"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <h2
            style={{
              fontSize: "1.8em",
              color: color.gray,
              textAlign: "center",
              fontWeight: 700
            }}
          >
            Всем любительницам платьев посвящается!
          </h2>
          <p
            style={{
              color: color.blue,
              textAlign: "center",
              lineHeight: "1em",
              fontSize: "1.2em"
            }}
          >
            Как известно, желание женщины – закон. Хорошо, если женщина знает о
            своем желании. А когда сомневается? Многим знакома картина: хочу
            платье, но не знаю, какое. В примерочной женщина перемеряла 1000
            фасонов платьев, но, ни одно не село по фигуре.
          </p>
          <Paragraph
            j
            content={[
              "Многие модные источники «трубят во все концы»: у каждой женщины должно быть свое идеальное платье. И женщина в поисках него (особенно перед праздниками) мечется от магазина к магазину, от торгового центра к торговому центру. А еще, те же самые «источники» говорят, что лучше иметь маленькое черное платье, которое подарила миру великая Коко Шанель. Хорошо, если женщина 44 размера и на нее все садится великолепно. А если женщина 54 размера и есть «спецэффекты»?",
              "Времена идут, мода на платья меняется, но остается неизменным слово «платье», которое будоражит умы мужчин и каждая женщина стремится найти свое идеальное платье."
            ]}
          />
          <div style={{ height: 32 }} />
          <Paragraph
            j
            content={[
              "Итак… Платье появилось много веков назад. Предками платья можно считать шкуры диких зверей, которые набрасывали на себя древние женщины.",
              "Истоки платья – это, конечно, Древняя Греция. Их носили и мужчины, и женщины. По платью можно было оценить статус человека.",
              "В прошлом различались мужские и женские платья, но по мере дифференциации мужской и женской одежды, платье стало характерной чертой именно женского гардероба. Но не будем глубоко вдаваться в историю. Эта тема бесконечна, вернее бесконечно интересна."
            ]}
            styleBox={{
              border: color.borderBlue + "3px dashed",
              borderRadius: 12,
              padding: 12
            }}
          />
          <div style={{ height: 32 }} />
          <h3
            style={{
              fontSize: "1.2em",
              color: color.blue,
              textAlign: "center"
            }}
          >
            И все-таки, есть ли платье, которое идет всем женщинам?
          </h3>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              <span>Да, есть!</span>,
              <span>
                Платье, которое сделает вас роскошной в любом возрасте, в
                котором вы сможете покорить мир!
              </span>,
              <span>Это платье strapless.</span>,
              <span>
                Что за «невиданный зверь», спросите вы меня? А я отвечу: все
                гениальное просто!
              </span>,
              <span>
                Это платье с открытыми плечами, отрезной линией талии и объемной
                юбкой.{" "}
              </span>,
              <span>
                Ну вот, опять для «тонких и звонких», скажете вы. Позволю с вами
                не согласиться.
              </span>,
              <span>Давайте все по порядку. </span>,
              <span>
                Это платье любимо многими стилистами (о мужчинах я пока не
                говорю). Прежде всего, в этих платьях решена задача с
                пропорциями.{" "}
              </span>,
              <span>
                <b>Во-первых</b>, демонстрируются красивые женские плечи (а
                если, вы считаете, что в вашем случае это не так, набросьте на
                плечи боа). Именно горизонтальная линия плеча волшебным образом
                балансирует верх и низ, не зависимо от типа вашей фигуры.
              </span>,
              <span>
                <b>Во-вторых</b>, талия всегда будет… будет на месте.{" "}
              </span>,
              <span>
                <b>В-третьих</b>, парадокс открытых плеч и объема платья в
                бедрах добавит вашему образу определенного шарма.
              </span>,
              <span>
                <b>В-четвертых</b>, объем платья в бедрах может скрыть ваши
                «спецэффекты» или дать повод сильному полу поразмышлять над
                вопросом красоты ваших ног.
              </span>,
              <span>
                <b>И в-пятых</b>, что самое приятное, длина этого платья может
                быть совершенно разная.
              </span>
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src="/img/blog/dress/pic1.jpg"
            alt=""
            style={{ position: "sticky", top: 1, maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <h3
            style={{
              fontSize: "1.2em",
              color: color.blue,
              textAlign: "center"
            }}
          >
            Теперь о мужчинах…
          </h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src="/img/blog/dress/pic3.jpg"
            alt=""
            style={{ position: "sticky", top: 1, maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              <span>
                Красота женского тела завораживает. Но красота женщины, которая
                умело может подать себя, не зависимо от размеров своей «души»,
                всегда покоряет.
              </span>,
              <span>
                Попробуем разобрать это платье с мужской точки зрения.
              </span>,
              <span>
                <i>Открытая линия плеча</i> говорит вместо слов. Покатые женские
                плечи всегда вызывают восхищение мужского пола.
              </span>,
              <span>
                <i>Линия лифа</i> создает напряжение в груди женщины, и мужчина
                начинает слегка «терять голову».
              </span>,
              <span>
                <i>Тонкая талия</i> (ведь именно отрезная линия талии создает
                этот эффект) дает надежду. Надежду на то, что талия в женской
                фигуре присутствует. Надежду на возможность прикоснуться к
                женщине, обхватив ее руками и закружить в танце (может быть, и в
                танце любви).
              </span>,
              <span>
                А <i>объемная юбка</i> несет за собой таинственность женских
                форм.
              </span>,
              <span>
                Это не просто платье. Это платье, с помощью которого, вы сможете
                покорить не одно мужское сердце.
              </span>,
              <span>
                <b>Но важно помнить</b>: в этом платье нужно уметь себя нести и
                подавать! И неважно, сколько вам лет – 20 или 60 – это платье
                сможет многое рассказать о своей обладательнице.
              </span>,
              <span>
                <b>Важно помнить одну простую истину</b> &mdash; для чего вы
                надеваете платье <i>strapless</i>.
              </span>,
              <span>
                Когда мужчина видит женщину в таком платье, у него срабатывает
                инстинкт КОРОЛЯ, потому что это платье могут носить только
                КОРОЛЕВЫ. И даже если у вас сутулая спина, но гордый вид, то вы
                будете <b>БОГИНЕЙ</b>.
              </span>
            ]}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
