import React from "react";
import TwoBlocks from "../../components/two-blocks-with-bkg";
import "./styles.css";
import Paragraph from "../../components/p-array";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/button-with-form";
import ButtonLink from "../../patterns/pattern-fs/button-black";
import ButtonVideo from "../../patterns/pattern-fs/button-video";
import Slides from "../../patterns/pattern-fs/slides";
import SliderInHeader from "../../patterns/pattern-fs/slider-in-header";
import Socnet from "../../components/want-to-know";
import Video from "../../components/video";

function Layout(props) {
  const { contents } = props;
  const fontTitle = "PT Sans Narrow";

  return (
    <>
      <h1 className="h1 m-t-b center">Смотрите открытые лекции</h1>
      <TwoBlocks
        textOnLeft
        left={
          <div>
            <h2 className="small">{contents.head1.headDescript}</h2>
            <h2
              className="h1"
              style={{ fontFamily: fontTitle, ...contents.head1.styleHead }}
            >
              {contents.head1.head}
            </h2>
            {contents.head1.headAfterDescript ? (
              <h2 className="small">{contents.head1.headAfterDescript}</h2>
            ) : null}
            <div style={{ height: "2rem" }} />
            <Paragraph
              classElement="p"
              style={{
                padding: "0.2rem 0",
                color: "rgb(100,100,100)"
              }}
              content={contents.head1.descript}
            />
            <div style={{ height: "2rem" }} />
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <Button white />
              </Grid>
              <Grid item xs={4} md={4}>
                <ButtonLink
                  link="/image-consulting#programma"
                  text="программа"
                  style={{ ...contents.head1.styleButton }}
                  linkInside={true}
                />
              </Grid>
              {contents.head1.noVideo ? null : (
                <Grid item xs={4} md={4}>
                  <ButtonVideo
                    linkVideo={
                      contents.head1.video ? contents.head1.video : false
                    }
                    slider={
                      contents.head1.video ? (
                        false
                      ) : (
                        <Slides videos={contents.videos} />
                      )
                    }
                    text="видео"
                    sizeBig={contents.head1.sizeVideo}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
        right={
          contents.head1.slider ? (
            <div className="imageHead">
              <SliderInHeader slides={contents.head1.slides} />
            </div>
          ) : contents.head1.imageComponent ? (
            contents.head1.imageComponent
          ) : (
            <Video
              link={contents.head1.video}
              image={contents.head1.imageSource}
              wMax={true}
              size="max"
            />
          )
        }
      />
      <div style={{ height: "4rem" }} />
      <TwoBlocks
        textOnLeft={false}
        right={
          <div>
            <h2 className="small">{contents.head2.headDescript}</h2>
            <h2
              className="h1"
              style={{
                fontFamily: "PT Sans Narrow",
                ...contents.head2.styleHead
              }}
            >
              {contents.head2.head}
            </h2>
            {contents.head2.headAfterDescript ? (
              <h2 className="small">{contents.head2.headAfterDescript}</h2>
            ) : null}
            <div style={{ height: "2rem" }} />
            <Paragraph
              classElement="p"
              style={{
                padding: "0.2rem 0",
                color: "rgb(100,100,100)"
              }}
              content={contents.head2.descript}
            />
            <div style={{ height: "2rem" }} />
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <Button white />
              </Grid>
              <Grid item xs={4} md={4}>
                <ButtonLink
                  link="/trends-of-modern-fashion#programma"
                  text="программа"
                  style={{ ...contents.head2.styleButton }}
                  linkInside={true}
                />
              </Grid>
              {contents.head2.noVideo ? null : (
                <Grid item xs={4} md={4}>
                  <ButtonVideo
                    linkVideo={
                      contents.head2.video ? contents.head2.video : false
                    }
                    slider={
                      contents.head2.video ? (
                        false
                      ) : (
                        <Slides videos={contents.videos} />
                      )
                    }
                    text="видео"
                    sizeBig={contents.head2.sizeVideo}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
        left={
          contents.head2.slider ? (
            <div className="imageHead">
              <SliderInHeader slides={contents.head2.slides} />
            </div>
          ) : contents.head2.imageComponent ? (
            contents.head2.imageComponent
          ) : (
            <Video
              link={contents.head2.video}
              image={contents.head2.imageSource}
              wMax={true}
              size="max"
            />
          )
        }
      />
      <Socnet noHead />
    </>
  );
}

export default Layout;
