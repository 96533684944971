import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Paragraph from "../../components/p-array";

export const contents = {
  head: {
    headDescript: "Интеллектуальное образование",
    title:
      "Прерафаэлиты: о том, чего не было, не будет и «можно только лишь желать»",
    head: (
      <>
        Прерафаэлиты:
        <br />
        <span style={{ fontSize: "1.6rem" }}>
          о том, чего не было, не будет и «можно только лишь желать»
        </span>
      </>
    ),
    headAfterDescript:
      "Если у искусства и есть какое-либо назначение, то это помочь человеку осознать себя и научить быть самим собой",
    descript: [
      "Онлайн",
      "Новые идеи за 1 месяц регулярных занятий в мини группе",
      "Длительность — 10 занятий по полтора часа",
      "Стоимость 25 тыс.рублей"
    ],
    text: "",
    imageSource: "/img/preraphaelites/preraphaelites.jpg",
    // noVideo: true,
    video: "vA3JLZ34R1w",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2rem"
    }
  },
  screen2: {
    dates: false, // [
    // {
    //   d: dates.online.getDate(),
    //   t: Month(dates.businessStyle.online.getMonth()),
    //   c: "дистанционно"
    // }
    // {
    //   d: dates.trendsOfModernFashion.ofline.getDate(),
    //   t: Month(dates.trendsOfModernFashion.ofline.getMonth()),
    //   c: <span>&ensp;очно</span>
    // }
    // ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>
          Специальный проект:
          <br />
          ONLINE КАМПУС ВШСДТ
        </span>
      )
    },
    text: (
      <List
        arr={[
          "Зарегистрируйтесь и смотрите первые две лекции в открытом формате",
          "Весь курс будет доступен сразу после оплаты"
        ]}
      />
    ),
    video: "vA3JLZ34R1w"
  },
  text1: (
    <Paragraph
      content={[
        <strong>Если Вы хотите</strong>,
        "Увидеть, как выглядит синтез прошлого, настоящего и будущего в искусстве",
        "Разобраться в сути творчества одного из самых неоднозначных направлений искусства",
        "Проследить истоки художественных течений XX и XXI века"
      ]}
    />
  ),
  programma: {
    head: "Цель обучения",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Данный курс – это поиск ответа на вопрос, почему творчество прерафаэлитов с момента появления и до сих пор вызывает двойственное отношение: от принятия и восторга до резкой критики и отрицания, став, вне зависимости от оценки, основой развития многих направлений искусства XX и XXI века.",
          "В курсе будет рассмотрена знаково-символическая система художественного образа «Братства»: новое понимание цвета и композиции, исповедальность и театрализация, правдоподобие и символизм, натурализм и мистичность, авангардизм и архаизм, синтез литературы, жизни и творчества, телесный психологизм, двойственность и вариативность категории красоты и образа Женщины"
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  pay: false
};
