import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Paragraph from "../../components/p-array";
import List from "../../components/list";
import { style } from "./styles";

export const content = {
  head: {
    title: "Практика на Milan Design Week 21-27 апреля 2020",
    head: (
      <span>
        Практика на Milan Design Week
        <br />
        21-27 апреля 2020
      </span>
    ),
    descript: (
      <span>
        &ensp;
        <br />
        Максимальная концентрация дизайна, искусства и инноваций
      </span>
    )
  },
  descript: {
    block1: (
      <div>
        <p style={style.pEnd}>
          Миланская неделя дизайна — это уникальное профессиональное событие
          глобального масштаба в мире дизайна и арт-достижений.
        </p>
        <h3 style={style.h3}>
          Почему важно посещать Миланскую неделю дизайна:
        </h3>
        <Paragraph
          content={[
            "Это будет насыщенная неделя, наполненная событиями, которая вдохновит вас и откроет новые возможности для работы и развития.",
            "Fuorisalone и Salone del Mobile Week объединились, чтобы сделать Milan Design Week, одним из самых важных событий в мире дизайна.",
            "«Salone del Mobile» и «Fuorisalone» определяют Миланскую Неделю дизайна, представляя последние тенденции на предстоящий год и запуская волну событий, выставок и презентаций.",
            "Также во время MDW многие крупные бренды, расположенные в дизайнерских районах, презентуют новые коллекции с развлечениями в формате абсолютно незабываемой феерии.",
            "В течение недели город изобилует тематическими мероприятиями, презентациями, инсталляциями, концертами, выставками, диджейскими сетами и специальными проектами, связанными с миром дизайна, мебели и инновациями в решениях для жизни.",
            "Вместе с официальными мероприятиями, проходящими в Rho Fiera, Fuorisalone наполняет весь город и его окраины креативной энергией с помощью серии дополнительных событий, разделенных на районы, называемые «district». Самые популярные:  Brera / Lambrate Design District / 5 Vie Art + Design District / Tortona /Porta Venezia / Isola Design District / Ventura Centrale / Romana."
          ]}
          style={style.p}
        />
        <p style={style.p}>
          Мы посетим самые интересные места, будем изучать, обсуждать
          современный дизайн и учиться у лучших.
        </p>
        <h3 style={style.h3}>
          В рамках практики мы посетим самые значимые события:
        </h3>
        <List
          style={style.p}
          arr={[
            "Выставки (ISaloni), презентации, инсталляции, шоу-румы, галереи, инновационные перформансы, арт-зоны, виллы, музейные пространства, вечеринки.",
            "Обсудим как выстраивать международные коммуникации и продавать себя на глобальном рынке. Что необходимо знать и делать, чтобы стать мировым брендом.",
            "Встретимся и познакомимся с профессионалами отрасли из разных стран мира, сможем найти поставщиков, партнеров, подготовится к будущему участию на MDW.",
            "Посетим Workshop от архитектора мирового уровня и культового итальянского дизайнера Luigi Baroli.",
            "Сделаем обзор трендов в дизайне на 2 года вперёд.",
            "Познакомимся с инновациями индустрии.",
            "Посетим итальянскую фабрику мебели и познакомимся с технологичным производством."
          ]}
        />
        <p style={style.p}>
          <img
            src={"/img/green/asterisk.svg"}
            alt=""
            style={{ height: "1em", marginBottom: -2 }}
          />
          &ensp; Для студентов, которые учатся в области дизайна, предусмотрено
          задание на весь период прохождения практики и по окончанию защита
          своего проекта
        </p>
      </div>
    ),
    block2: (
      <div>
        <h3 style={{ ...style.h3, paddingTop: 0 }}>В стоимость входит:</h3>
        <List
          style={style.p}
          arr={[
            "Трансфер аэропорт MPS (туда-обратно)",
            "Авторская программа практики, которая познакомит в современными достижениями индустрии дизайна",
            "Сопровождение кураторов на весь период практики",
            "Посещение профессиональной выставки «ISALONI»",
            "Поездка и экскурсия на итальянское производство",
            "Work-shop от итальянского дизайнера",
            "Посещения музея",
            "Проезд на все время стажировки"
          ]}
        />
      </div>
    )
  },
  programma: {
    title: "Программа MDW 21/04-27/04 2020:",
    descript: "(краткий план для общего ознакомления)",
    content: [
      {
        title: "1 день 21/04",
        list: [
          "Встреча, заселение, знакомство",
          "Обсуждение целей, программа",
          "Выдача задания на MDW",
          "Прогулка по Brera Design District",
          "Посещение презентаций, шоу-румов, выставочных зон."
        ]
      },
      {
        title: "2 день 22/04",
        list: [
          "Посещение района Lambarte, выставочных пространств и пр.",
          "Бизнес-нетворкинг, обзор новинок, знакомство с компаниями.",
          "Bernini — знакомство, рассказ о бренде"
        ]
      },
      {
        title: "3 день 23/04",
        list: [
          "Посещение итальянской фабрики мебели, знакомство с производством"
        ]
      },
      {
        title: "4 день 24/04",
        list: [
          "Workshop от итальянского дизайнера мирового уровня Luigi Baroli",
          "Обзорная прогулка по центру с посещением культовых мест и мероприятий"
        ]
      },
      {
        title: "5 день 25/04",
        list: [
          "Посещение ISalone di Mobile обзор трендов, новинок — целый день"
        ]
      },
      {
        title: "6 день 26/04",
        list: [
          "Fierra Milano",
          "Музей Prada или Armani",
          "Вечеринка закрытия MDW/2020"
        ]
      },
      {
        title: "7 день 27/04",
        list: ["Отъезд"]
      }
    ]
  },
  teachers: {
    title: "Кураторы практики MDW:",
    content: [
      {
        name: "Прохорович Елена",
        text: (
          <Paragraph
            style={style.p}
            content={[
              "Автор и куратор курсов Высшей школы стилистики дизайна  и технологий.",
              "Специалист по рекламе и PR, коммуникационный тренер, ТВ-эксперт, fashion-коммуникации.",
              "Идеолог и основатель международных коммуникационных образовательных проектов:",
              <List
                style={style.p}
                arr={[
                  "Стажировки на Milan fashion week / Milan design week",
                  "Summer fashion school in Italy"
                ]}
              />,
              "Основное направление международные коммуникации, продвижение личного бренда, технологии запуска startup-проектов в индустрии моды и дизайна.",
              "Более 10 лет консультирую компании по продвижению брендов и занимаюсь организацией профессиональных мероприятий в сфере B2B, B2C, fashion- консалтингом, а также маркетинговыми и бизнес исследованиями в индустрии моды и дизайна.",
              "С 2015 года разрабатываю и провожу программы коммуникационного-коучинга и тренингов личной эффективности",
              "Член Российской Ассоциации связей с общественностью (РАСО)"
            ]}
          />
        ),
        image: "/img/internship-in-milan/teacher.jpg"
      },
      {
        name: "Львова Дарья",
        text: (
          <Paragraph
            style={style.p}
            content={[
              "Архитектор, выпускница Санкт-Петербургской академии художеств им. Репина. Специалист в сфере архитектурного дизайна.",
              "Более 8 лет практикующий дизайнер интерьера и мебели, с опытом реализации российских и международных проектов.",
              "Иллюстратор, графический дизайнер.",
              "Преподаватель авторского курса  «Оформление архитектурной подачи в программе Adobe Photoshop». С 2018 года автор курсов в области дизайна и архитектуры в проекте «Мастера»"
            ]}
          />
        ),
        image: false
      }
    ]
  }
};
