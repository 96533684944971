import React from "react";
import { Check } from "./check";
import "./style.css";

export function Title(props) {
  return (
    <div className="title-w-icon">
      {props.check ? (
        <div className="icon">
          <Check
            size={props.size || "1.2rem"}
            color={props.color || "rgb(80,80,80)"}
          />
        </div>
      ) : (
        props.svg
      )}
      &emsp;
      <div className="title">{props.title}</div>
    </div>
  );
}
