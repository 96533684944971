import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Pattern, { styles } from "../../components/pattern-page-of-course";

const Typology = props => {
  return (
    <Pattern
      title="Как создать франшизу и продвигать её"
      faq="Курсы"
      short="Курс | 1½ месяца | 5 занятий | 24 академ. часа | ONLINE"
      teacher={{
        text: (
          <Paragraph
            content={[
              <span>
                Ведущая курса &ndash; Письменская Елена. Основатель
                консалтингового агентства Kids Fashion Retail.
              </span>,
              <span>
                На курсе будут рассмотрены предлагаемые на рынке франшизы,
                особенности рынка России и ее отличие от европейской и
                американской франшизы. Создание и продвижение франшизы, а также
                целый блок будет посвящен эффективному взаимодействию с
                франчайзи.
              </span>
            ]}
            style={styles.text}
          />
        ),
        img: "/img/fashion-franchising/ill01.jpg",
        name: "Письменская Елена"
      }}
      leftTile={
        <div>
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1.6em",
              color: "rgb(100,100,100)",
              textAlign: "center",
              fontWeight: 500,
              margin: 0,
              padding: "12px 0"
            }}
          >
            Очно
          </h3>
          <p
            style={{
              background: "rgb(240,240,240)",
              fontSzie: "1em",
              textAlign: "center",
              color: "rgb(80,80,80)"
            }}
          >
            18 января 2020
          </p>
          <p
            style={{
              fontSize: "0.9em",
              textAlign: "center",
              color: "rgb(100,100,100)"
            }}
          >
            Внесите аванс 2000 рублей
            <br />
            Оформите документы
            <br />
            Забронируйте место в группе
          </p>
          <a href="https://billing.styleschool.ru/event/MYQ6caXstHRsKyySW">
            <div
              style={{
                background: "rgb(123,143,87)",
                color: "rgb(255,255,255)",
                fontSize: "0.9em",
                textAlign: "center",
                padding: 8,
                borderRadius: 4,
                width: "fit-content",
                margin: "0 auto"
              }}
            >
              забронировать
            </div>
          </a>
        </div>
      }
      rightTile={
        <div>
          <h3 style={styles.h3}>Стоимость</h3>
          <p style={styles.price}>
            50
            <sup>000</sup> руб.
          </p>
          <p style={styles.p}>
            Для записи на курс внесите аванс и оформите договор.
          </p>
          <p style={styles.p}>
            В случае отказа от обучения аванс возвращается.
          </p>
        </div>
      }
      programm={
        <Paragraph
          content={[
            <List
              arr={[
                "Обзор рынка франшиз fashion рынка. Тренды и  текущее состояние отрасли.",
                "Понятие франчайзинга: формирование франчайзинговой модели бизнеса. Терминология: франчайзер/ франчайзи; мастер-франшиза; субфранчайзинг; паушальный взнос; роялти.",
                "Понятие коммерческой концессии; отличие коммерческой концессии от дистрибуции и агентских отношений.",
                "Особенности европейской и американской систем франчайзинга.",
                <span>
                  Российская система франчайзинга &ndash; правовой аспект.
                </span>,
                "Создание франшизы.",
                "Стратегия и тактика внедрения франчайзинга.",
                "Возможные и необходимые условия для внедрения франчайзинга.",
                "Этапы внедрения франчайзинга.",
                "Подходы к разработке и оценке бизнес-модели.",
                "Особенности структуры и содержания франчайзингового пакета: баланс между необходимым и достаточным.",
                "Практические ошибки при подготовке к запуску франшизы и на начальном этапе ее функционирования.",
                "Поддержание и развитие. Эффективное взаимодействия участников франчайзинговой сети.",
                "Основные функциональные принципы успешного развития франчайзинговой сети.",
                "Современные технические средства в области франчайзинга.",
                "Оценка эффективности сотрудничества во франчайзинге.",
                "Выбор оптимального варианта распределения обязательств между участниками франчайзинговой сети."
              ]}
              style={styles.list}
            />,
            "Практикум: мониторинг конкурентов, описание своего конкурентного преимущества.",
            "Практическая работа: Создание и презентация франшизы собственного бизнес-проекта."
          ]}
        />
      }
      additional
      addTitle="Письменская Елена"
      addText={
        <p style={styles.text}>
          Основатель консалтингового агентства Kids Fashion Retail. Эксперт в
          создании новых и развитии существующих брендов детской одежды.
          Постоянный спикер отраслевых выставок и форумов. Преподаватель в
          Fashion Factory Education, Русской школе стиля, МГУ им Ломоносова,
          Центре креативных индустрий программа МВА &ndash; Индустрия Мода.
        </p>
      }
      addImg="/img/fashion-franchising/teacher.jpg"
      addName="Письменская Елена"
      addDescript="Франчайзинг — прекрасная возможность развития и масштабирования бизнеса. Однако к запуску франшизы нужно подготовиться, чтобы не допустить трагичных для бизнеса ошибок. Под словами «Успешный франчайзинг» скрывается большое количество работы и продуманный бюджет на ее продвижение. Как разработать и запустить франшизу на рынке. Что необходимо, чтобы ее продвигать, где найти своих клиентов. И как необходимо выстроить с ними процесс, чтобы они не закрылись в течении первого года работы. Все аспекты создания франчайзинга рассмотрим с Вами на курсе."
    />
  );
};

export default Typology;
