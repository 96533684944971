import React from "react";
import List from "../../components/list";
import { Spoler } from "../../components/spoler/spoler";
// import { dates, Month } from "../shedule/dates";
// import Video from "../../components/video";
import {
  shabalina3,
  maltseva,
  stulov,
  bonetskaya,
  moatti,
  gorskih3
} from "../../contents/teachers";
import Link from "react-router-dom/Link";
import Phones from "../../components/phones-icons";

const p = "p-constructor";
const h = "h3-constructor";

export const contents = {
  titlePage: "Профессия: Дизайнер чат-ботов с искусственным интеллектом",
  headNew: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Основы компьютерной лингвистики и машинного обучения для создания
        диалоговых ассистентов
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },

    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Вы научитесь создавать чат-ботов, реагирующих на естественно-языковые
        обращения, в системе онлайн коммуникаций (мессенджеров). По итогам —
        документ ВУЗа, который котируется во всем мире
      </div>
    ),
    descript: [
      <>Онлайн обучение</>,
      <>Удостоверение о повышении квалификации</>,
      <>
        Обучение проводиться на базе МФТИ в рамках федерального проекта
        «Содействие занятости»
      </>,
      <>до 2 месяцев, 72 академ.часа + защита проекта</>,
      <>Живое общение с преподавателями</>
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#aboutprofassion",
        text: "опрофессии"
      },
      {
        link: "#singup",
        text: "записаться"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/chatbot/il-01.jpg"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>ноябрь 2021</>]
    },
    {
      title: "Формат",
      text: [<>Живые вебинары, видеолекции,</>, <>чат в группе, практика</>]
    },
    {
      title: "Гранты",
      text: [<>Специальные условия для</>, <>студентов и преподавателей</>]
    }
  ],
  mfti: {
    image: {
      src: "/img/partners-pro/mfti.png", //"/img/chatbot/mfti-logo.webp"
      alt: "МФТИ",
      style: { width: 200, height: "auto" },
      block: {
        backgroundColor: "white" // "rgb(1,114,192)"
      }
    },
    text: (
      <>
        Официальный партнер проекта. Слушатели проходят обучение на базе
        Московского физико-технического института. После успешного изучения
        курса и защиты проектной работы, выпускники получат удостоверение о
        повышении квалификации МФТИ по программе «Основы компьютерной
        лингвистики и машинного обучения для создания диалоговых ассистентов»
        <br />
        <a
          href="https://mipt.ru/cdpo/programs/software/supervised_learning.php?ELEMENT_ID=1969392"
          target="_blank"
        >
          <strong>mfti.ru</strong>
        </a>
      </>
    ),
    link: "https://mipt.ru/cdpo/news/chatbot_course_announcement"
  },
  vshsdt: {
    image: {
      src: "/img/chatbot/vshsdt.png",
      alt: "ВШСДТ",
      style: {
        height: 150
      },
      block: {
        backgroundColor: "white" // "rgb(250,250,250)",
      }
    },
    text: (
      <>
        Инициатор проекта. Цель — внедрение цифровых технологий и коммуникаций
        для малого бизнеса в креативных индустриях. В частности, слушатели курса
        получат возможность изучить дисциплины, связанные со структурой и
        технологиями современной моды, в процессе обучения реализовать проекты в
        данной области.
      </>
    )
  },

  text1: {
    title: <>Профессия Дизайнер чат-ботов с ИИ</>,
    text: (
      <>
        Дизайнер чат-ботов с искусственным интеллектом создаёт умные решения для
        автоматизации рутинных задач. В последние годы устойчиво растёт спрос на
        создание умных диалоговых ассистентов для бизнеса, государства,
        образования, культуры, медицины и прочих сфер жизни общества. Поэтому
        профессионалы, способные создавать и обслуживать умные диалоговые
        системы, имеют высокие перспективы на рынке труда. В частности зарплата
        дизайнера диалоговых систем на основе данных hh.ru составляет: junior от
        50 000 руб., middle от 100 000 руб., senior от 170 000 руб.
      </>
    )
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Программа курса предусматривает освоения слушателями следующих модулей
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Модуль 1. Основы компьютерной лингвистики — 10 занятий (20
            академических часов)
          </>,
          <>
            Модуль 2. Основы разработки диалоговых ассистентов с элементами NLP,
            ML/DL — 10 занятий (20 академических часов).
          </>,
          <>
            Модуль 3. Создание диалоговых ассистентов для текстовых каналов
            коммуникаций — 10 занятий (20 академических часов).
          </>,
          <>Зачеты и итоговая аттестация — 4 единицы (10 академических часов)</>
        ]}
      />,
      <p className={p}>
        <strong>Обучение направлено на:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>изучение основных принципов и современных методов NLP, ML/DL;</>,
          <>
            овладение специальной терминологией, используемой в области NLP,
            ML/DL;
          </>,
          <>
            изучение технических средств, применяемых в разработке решений с
            элементами NLP, ML/DL
          </>,
          <>
            отработки устойчивых навыков решения практических задач с
            использованием методов NLP, ML/DL;
          </>,
          <>
            овладение современными способами анализа, тестирования результатов и
            исправления ошибок;
          </>,
          <>
            отработка навыков оценки экономической эффективности диалоговых
            ассистентов.
          </>
        ]}
      />,
      <p className={p}>
        Все занятия проводятся в интерактивном режиме, с максимальным
        вовлечением слушателей в активный процесс усвоения знаний и отработки
        навыков. Каждое занятие включает в себя теоретическую, семинарскую и
        практическую части с отработкой необходимых навыков.
      </p>,
      <p className={p}>
        <strong>Вы научитесь:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Понимать и анали&shy;зиро&shy;вать наме&shy;ре&shy;ние
            поль&shy;зова&shy;теля, опре&shy;делять леммы, рабо&shy;тать с
            кор&shy;пуса&shy;ми текстов и линг&shy;вис&shy;ти&shy;чес&shy;кими
            ин&shy;струмен&shy;тами, вла&shy;деть сов&shy;ремен&shy;ными
            спо&shy;собами раз&shy;метки текста
          </>,
          <>
            Поль&shy;зовать&shy;ся кате&shy;горий&shy;ным ап&shy;пара&shy;том,
            уметь исполь&shy;зовать инстру&shy;мен&shy;тарий для
            обра&shy;бот&shy;ки ес&shy;тес&shy;твен&shy;ного языка и работы с
            НТТР запро&shy;сами, владеть тех&shy;ничес&shy;кими и
            про&shy;грам&shy;мными сред&shy;ствами разра&shy;ботки систем с
            эле&shy;мен&shy;тами NLP и ML/DL, опре&shy;де&shy;лять усло&shy;вия
            и функ&shy;ции в диало&shy;говых ас&shy;сис&shy;тен&shy;тах
          </>,
          <>
            Пользоваться програм&shy;мны&shy;ми сред&shy;ства&shy;ми для
            кон&shy;струи&shy;рова&shy;ния диало&shy;говых
            ассис&shy;тен&shy;тов, мето&shy;дами поиска и ис&shy;прав&shy;ления
            ошибок, уметь работать с дан&shy;ными, владеть
            со&shy;времен&shy;ными спо&shy;со&shy;бами ана&shy;лиза и
            раз&shy;метки тек&shy;стов
          </>
        ]}
      />,
      <p className={p}>
        <strong>Требования для зачисления:</strong>
      </p>,
      <p className={p}>
        На обучение в МФТИ допускаются слушатели, обладающие высшим или средне
        профессиональным образованием (предоставление копий диплома
        обязательно), а так же студенты вузов и колледжей) на основе справки из
        образовательной организации).
      </p>,
      <p className={p}>
        Обучение так же доступно в формате вольного слушателя без предоставление
        диплома о высшем образовании или СПО. В таком случае по итогам обучения
        выдается сертификат.
      </p>,
      <p className={p}>
        <strong>Полная программа курса высылается по запросу.</strong>
      </p>,
      <p className={p}>
        <strong>Дополнительные модули по выбору:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Структура и система современной индустрии моды, 30 академических
            часов
          </>,
          <>
            Продвижение личного бренда в социальных сетях, 6 академических часов
          </>,
          <>Профессиональный деловой стиль, 16 академических часов</>,
          <>Правильное резюме, 2 академических часа</>,
          <>Юридические аспекты работы ИП и самозанятых, 2 академических часа</>
        ]}
      />
    ]
  },
  textNote: (
    <>
      <p>
        Для записи на обучение Вам потребуется пройти три этапа: подача заявки,
        загрузка комплекта документов, подписание договора на обучение.
        Возможно, Вам потребуется наша помощь.
      </p>
      <p>
        <strong>Обратите внимание!</strong>
      </p>
      <p>
        В рамках Федерального проекта «Содействие занятости» обучение по данной
        программе доступно следующим категориям граждан РФ:
      </p>
      <List
        arr={[
          <>
            граждане, ищущие работу и обратившиеся в органы службы занятости,
            включая безработных;
          </>
        ]}
      />
      <List
        arr={[
          <>
            Выбирайте эту категорию, даже если Вы работаете и просто хотите
            повысить квалификацию. Обратитесь в центр занятости за справкой для
            обучения.
          </>
        ]}
        olStyle={{ listStyle: "url('/img/green-check.svg')" }}
      />
      <List
        arr={[
          <>женщины, находящиеся в отпуске по уходу за ребенком;</>,
          <>
            женщины, имеющие детей дошкольного возраста и не состоящие в
            трудовых отношениях;
          </>,
          <>
            все граждане в возрасте 50-ти лет и старше (предпенсионного
            возраста)
          </>
        ]}
      />
      <p>
        <strong>Обучение полностью оплачивается государством</strong>
      </p>
      <p>
        Для получения доступа к обучению Вам потребуется заполнить заявку и
        предоставить определенные документы. На практике процесс согласования
        занимает не менее двух недель. Чтобы ускорить данный процесс, следуйте
        инструкциям ниже.
      </p>
      <List
        arr={[
          <>
            Необходимо зарегистрироваться / авторизоваться на сайте{" "}
            <a href="https://trudvsem.ru/" target="_blank">
              trudvsem.ru
            </a>{" "}
            через портал Госуслуг
          </>,
          <>
            Подать заявку на участие в программе «Основы компьютерной
            лингвистики и машинного обучения для создания диалоговых
            ассистентов»
          </>,
          <>
            Получить письмо на электронную почту со ссылкой для загрузки
            подтверждающих документов
          </>,
          <>
            Получить письмо об одобрении Вашей заявки и приглашения от ВУЗа на
            обучение с указанием конкретных дат начала и завершения обучения
          </>
        ]}
        num="decimal"
      />
      <p>
        <strong>Важно, когда нужно написать нам:</strong>
      </p>
      <List
        arr={[
          <>
            процесс рассмотрения заявки на портале «Работа в России» составляет
            не менее 15 рабочих дней; чтобы получить ответ на заявку быстрее,
            напишите нам через форму обратной связи после оформления заявки и мы
            сможем ускорить этот процесс
          </>,
          <>
            если у Вас возникают сложности с поиском программ или заполнением
            заявки, пишите нам или в НИ ТГУ через сайт
            <a href="https://tgu-dpo.ru/program/stylist" target="_blank">
              tgu-dpo.ru/program/stylist
            </a>
          </>,
          <>
            если у Вас возникают вопросы по обучению, в том числе если Вы не
            успеваете к заявленным на сайте датам, пишите нам в WhatsApp или
            Telegram
          </>
        ]}
      />
      <div style={{ marginTop: "3rem" }}>
        <Phones
          name={" "}
          size={20}
          color="rgb(0,0,100)"
          link="https://chat.whatsapp.com/DEuV6RqRKOA7J7lQcGCy5o"
          // https://t.me/joinchat/IHm0-xQ3XczI43YpD0ZbLg
          linkForTelegram="IHm0-xQ3XczI43YpD0ZbLg"
        />
      </div>
    </>
  ),
  miniFormHead: "Форма обратной связи",
  price: (
    <>
      <p className="p-constructor">Обучение оплачивается государством</p>
      <p className="p-constructor">
        Требуется записаться на сайте НИ ТГУ{" "}
        <a href="https://tgu-dpo.ru/program/stylist" target="_blank">
          tgu-dpo.ru/program/stylist
        </a>
      </p>
      <p className="p-constructor">
        Оформить все необходимые для участия в программе документы
      </p>
    </>
  ),
  teachers: [gorskih3, shabalina3, moatti, maltseva, bonetskaya, stulov]
};
