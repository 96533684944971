import React from "react";
import Helmet from "react-helmet";
import { Grid } from "@material-ui/core";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Head from "./header-links";
import Paragraph from "../../components/p-array";

const name = "Анастасия Глушкова";

const styles = {
  head: {
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "center",
    color: "rgb(142,138,130)",
    margin: 0,
    padding: "24px 0"
  },
  p: {
    fontSize: "1em",
    color: "rgb(100,100,100)"
  }
};

export default function Glushkova() {
  return (
    <div>
      <Helmet>
        <title>{name + ": Публичный профиль"}</title>
        <meta name="keywords" content={name + ": Публичный профиль"} />
      </Helmet>
      <Top />
      <Grid
        container
        justify="space-around"
        spacing={4}
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/glushkova1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Head
              head={name}
              vk="https://www.vk.com/club64793758"
              blogger="http://fashioneasily.blogspot.ru/"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={11}>
          <h2 style={styles.head}>Детство и увлечения</h2>
          <Paragraph
            j
            content={[
              "Со стороны родственников отца было много Палехских художников и иконописцев. С детства я была уверена в том, что когда вырасту, стану художником. Родители старались как можно больше творчески развивать меня: толкали на сцену петь, отдавали на латинские танцы, в художественную школу, которую за три года я окончила на отлично. В детстве смотрела на свою маму, как она красится, наряжается, вертится перед зеркалом. Видно, это мне тоже передалось – любовь к нарядам и косметике.",
              "Если говорить о прикладном творчестве, то тут я подошла нестандартно. С 8 класса школы я полюбила творчество Майкла Джексона. Началось все с создания его копии в виде куклы. Потом я перешила на него все мелкие копии его сценических костюмов. А потом и дом ему сделала. Это нечто похожее на театр: каждая комната была как декорация, наполненная мебелью. Основу интерьеров я брала из документальных источников.",
              "Живя в семье музыкантов (играют мама, папа и сестра), нужно было знать хотя бы один инструмент. Я выбрала гитару. В студенческие годы по этой причине я всегда была душой компании и кандидатом на все конкурсы. Начала писать свои стихи и песни. А училась в Художественном училище на рекламный дизайн. По окончании я вышла на работу и на первую зарплату купила красные лаковые шпильки!",
              <div style={{ textAlign: "center" }}>
                <img
                  src="/img/graduates/glushkova2.jpg"
                  alt={name}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ]}
          />
          <h2 style={styles.head}>Фриланс</h2>
          <Paragraph
            j
            content={[
              "На первом рабочем месте мне не повезло с начальством. Продержалась я там полгода. Морально раздавили. Я еще два года потом скиталась в поисках себя и не могла вернуться в сферу рекламы. Как-то мне один друг сказал, что время идет, а талант пропадает. Я очнулась. Но в офис возвращаться не хотела.",
              "Выбрала фриланс – работу на себя. Это было озарением, что можно быть свободным и зарабатывать любимым делом неограниченную сумму. Веб дизайну я фактически научилась с нуля. Более пяти лет я работала дома за компьютером, получая заказы от своей постоянной клиентской базы.",
              "За это время я поняла, что когда ты сама по себе, все зависит только от твоего качества работы. Сделала хорошо – придут еще и приведут друзей. Нужно быть фанатиком своего дела и любить сам процесс, чтобы расти как профессионал и получать хорошую денежную благодарность. Наемные люди в офисах никогда не поймут этого, так как им нет смысла расти выше – им же все равно платят фиксированную зарплату.",
              <div style={{ textAlign: "center" }}>
                <img
                  src="/img/graduates/glushkova3.jpg"
                  alt={name}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ]}
          />
          <h2 style={styles.head}>Скрытый стилист</h2>
          <Paragraph
            j
            content={[
              "Кто-то сказал очень мудрые слова: «Чтобы чувствовать себя живым, нужно каждые 3-5 лет что-то менять в своей жизни». Я дошла до того момента, когда в моей жизни все стабильно. Постоянные клиенты есть, работа есть, но не радует – тошно. Как будто дышать нечем. Ушло куда-то вдохновение, и ничего не радовало.",
              "В тот момент жизни я посещала женский клуб, где однажды была затронута тема раскрытия своих талантов. Во мне рассмотрели стилиста. Я тогда просто посмеялась – ведь я своим вкусом пользовалась сугубо для себя. Я начала припоминать, что подруги действительно меня частенько просили о помощи и спрашивали марки одежды. Девушки женского клуба попросили меня делиться всей информацией, что я знала на эту тему."
            ]}
          />
          <h2 style={styles.head}>Дальнейшее развитие</h2>
          <Paragraph
            j
            content={[
              <React.Fragment>
                Первым шагом было создание{" "}
                <a href="http://fashioneasily.blogspot.ru/">блога</a>. Моя жизнь
                с того момента стала общественной. Я чувствовала, что несу
                ответственность за информирование аудитории. Я втянулась в
                процесс. Стала показывать свои идеи, рассказывать о тенденциях и
                т.д. Потом мои подруги попросили сделать для них какой-нибудь
                мини-курс на тему стиля. Я стала копать глубже и больше читать,
                чтобы грамотно подать информацию. После первой группы пришла
                вторая – уже незнакомые мне люди. Им я рассказала тоже. Потом
                еще люди и еще… Я поняла, что в нашем городе женщины очень
                нуждаются в этой информации и хотят, чтобы им помогли. Начались
                вопросы, где я училась и откуда материал, что я даю. Говорили,
                что у меня талант, и мне нужно идти дальше. Я приняла решение,
                что нужно специальное образование по этому направлению.
                Принялась на поиски.
              </React.Fragment>,
              "В Москве я жить не хотела, хоть и смотрела предложение от Британской Школы. У них курс мне показался очень дорогим и долгим. Хотелось чего-то более доступного и без ущерба семейным отношениям. Высшая школа стилистики предлагала как раз то, что нужно. И ехать никуда не надо, и достаточно обширная информация по темам, и не так дорого. Я думала один день. Потом подала документы и сходила в банк.",
              <div style={{ textAlign: "center" }}>
                <img
                  src="/img/graduates/glushkova4.jpg"
                  alt={name}
                  style={{ maxWidth: "100%" }}
                />
              </div>,
              <p style={{ textAlign: "center" }}>
                Победа в Ивановском конкурсе &laquo;Блоггер года&raquo; в
                номинации &laquo;Тематический блог года&raquo; 2015
              </p>
            ]}
          />
          <h2 style={styles.head}>Свой проект и учеба</h2>
          <Paragraph
            j
            content={[
              <React.Fragment>
                Я поступила 16 июля, а уже 1 августа запустила свой проект
                «Личный имиджмейкер» на{" "}
                <a href="http://www.37.ru/fashion/news/lichnyy-imidzhmeyker/">
                  Ивановском городском портале
                </a>
                . Каждую пятницу мы выпускали новый материал с перевоплощением
                участницы. Это была словно бомба! Столько людей писали мне
                каждый день с просьбой им помочь. С сентября я уже начала
                работать с индивидуальными клиентами. И все это происходило
                параллельно с учебой.
              </React.Fragment>,
              "Такие разные преподаватели: каждая по-своему тонко чувствующая и очаровательная. Я вслушивалась в каждое слово и изо всех сил старалась с заданиями. Получилось наладить связь с некоторыми из однокурсников. Всегда интересно, как дела идут у сверстников.",
              "Мой блог обрел еще большую популярность. Я стала более публична, не пропускала ни одного мероприятия. В Ивановском текстильном крае с нашей Текстильной Академией столько новых модельеров выпускается каждый год. Столько новых талантов вокруг. Постоянно молодёжь устраивает какие-то показы, вечеринки…",
              "Сейчас я могу с уверенностью сказать, что приняла правильное решение. Обучение хоть и идет всего полгода, но содержит в себе концентрат самых необходимых важных знаний для стилиста. Я заполнила все пустоты в своей картине об этой работе. На одной из последних тем – о реализации в этой профессии – я поняла, что уже использую эти навыки по 3-4 из пунктов. Это приятно удивило меня.",
              <div style={{ textAlign: "center" }}>
                <img
                  src="/img/graduates/glushkova5.jpg"
                  alt={name}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ]}
          />
          <h2 style={styles.head}>Свой проект и учеба</h2>
          <Paragraph
            j
            content={[
              "Что изменилось после окончания ВШС? Я живу! Знаете, это такое чувство, как влюбленность. Ты все острее чувствуешь вокруг. Ты радуешься жизни. Это как раз та новая свежая волна, которую я искала. Снова появилось вдохновение и вкус жизни.",
              <React.Fragment>
                Я продолжаю вести{" "}
                <a href="http://fashioneasily.blogspot.ru/">блог</a>, в котором
                делюсь со своими читателями обо всем, что я делаю: о своих идеях
                и наблюдениях, о моем проекте и тех счастливых женщинах, которые
                наконец полюбили себя после преображения, о своих курсах, и как
                на них мы интересно проводим время с практикой. На данный момент
                я перевожу свой{" "}
                <a href="http://fashioneasily.blogspot.ru/">блог</a> с платформы
                blogspot на свою личную концепцию полноценного сайта.
              </React.Fragment>,
              "Я хочу быть независимой от кого-либо, создавать на своем пространстве обучающие видео и прочие материалы, пробовать что-то новое. За этот год сформировалась большая клиентская база. С радостью встречаюсь с каждой из своих клиенток и слушаю их рассказы о переменах в личной жизни, о повышении на работе и т.д. Ведь это так важно для уверенности женщины – полюбить себя и свое отражение в зеркале. Тогда она может все в этой жизни добиться.",
              "Не надо бояться перемен – это как приключение. Именно перемены дают тебе почувствовать, что ты живая, раскрывают новые качества и таланты, делают тебя более значимой. Жизнь коротка: нет времени на промедление. Идите к своей мечте прямо сейчас. Обретя свое предназначение и принося этим пользу обществу, вы почувствуете настоящее счастье."
            ]}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
