import React from "react";
import Paragraph from "../../components/p-array";
import Blog from "./template";

export default function Myslitel() {
  return (
    <Blog
      title="Стивен Гандл. Книга Гламур."
      keywords="Гандл, гламур, мода, искусство, стиль"
      descript="Книга английского исследователя массовой культуры и кино является больше обзорной и в этом есть ее преимущество, поскольку читатель познакомится со множеством имен и фактов, составляющих историю «гламура»: от известнейшего денди XIX века Д. Браммела и великого поэта Д. Байрона до принцессы Дианы и Пэрис Хилтон, от двора Наполеона до кофеен и варьете, от куртизанок до супермоделей. Гандл показывает истоки современного «гламура» и сам современный «гламур» как общество соблазнительного потребления, массового производства товаров, системы «звезд», сексуальности и призыва, образов и стилей."
      head="Стивен Гандл. Гламур."
      author="Наталья Горских"
      link="/natalya_gorskyh"
      image="/img/blog/book-gandl.jpg"
      content={
        <Paragraph
          content={[
            <span>
              Книга английского исследователя массовой культуры и кино является
              больше обзорной и в этом есть ее преимущество, поскольку читатель
              познакомится со множеством имен и фактов, составляющих историю
              «гламура»: от известнейшего денди XIX&nbsp;века Д.&nbsp;Браммела и
              великого поэта Д.&nbsp;Байрона до принцессы Дианы и Пэрис Хилтон,
              от двора Наполеона до кофеен и варьете, от куртизанок до
              супермоделей. Гандл показывает истоки современного «гламура» и сам
              современный «гламур» как общество соблазнительного потребления,
              массового производства товаров, системы «звезд», сексуальности и
              призыва, образов и стилей.
            </span>
          ]}
        />
      }
    />
  );
}
