import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="История моды"
      title="Взаимосвязь костюма и архитектуры: из глубины веков до наших дней."
      teacher="Марина Бонецкая"
      descript={<span>on-line</span>}
      online
      date="15 июля"
      time="12:00"
      duration=""
      image="/img/modern-fashion-style/architecture-costume.jpg"
      text={
        <span>
          Архитектура, говорят, состоит в родстве с музыкой. И еще &mdash; с
          костюмом и всеми его частями, потому что гармоничный, выверенный в
          пропорциях и функциях дом создан по тем же законам, что и прекрасно
          сидящая одежда.
          <br />
          Некоторые кутюрье особенно &laquo;архитектурны&raquo;: Жан-Поль Готье,
          Кристиан Диор, Александр Мак-Куин.
          <br />А бывают, наоборот, архитекторы, мыслящие в камне, стекле и
          бетоне так свободно и пластично, как будто это ткань. Так работала
          например, знаменитая Заха Хадид, британка иракского происхождения.
          Примечательно, что эту связь можно наблюдать как стилевое единство,
          начиная с Древнего мира и вплоть до современности.
        </span>
      }
      q
      part
      color="rose"
      id="AMBTyo6eCjWgG748n"
      setBillingId={props.setBillingId}
    />
  );
}
