import React from "react";
import List from "../../components/list";
import Cot from "./cot";
import { props } from "bluebird";

export default function ProgrammaComponent() {
  const content = [
    {
      title: "1-й модуль",
      text: (
        <List
          arr={[
            "колористика и цветовой анализ внешности как профессиональный инструмент стилиста",
            "психология формы и силуэтов, современные типологии фигур, возможности и способы визуальной коррекции с помощью одежды",
            "изучение основных групп современных стилей как системы для транслирования необходимого впечатления в имидже",
            "изучение аксессуаров, головных уборов, обуви, правил и способов их подбора с учетом fashiontrends",
            "профессиональный глоссарий стилиста, изучение профессиональной лексики и терминологии",
            "теория стилевых направлений как действенный инструмент в создании индивидуального стиля",
            "мужская стилистика, ее особенности, практика применения и современные тренды",
            "изучение типов современного гардероба, создание гардеробных капсул",
            "теория и практика шопинга, в том числе on-line шопинга",
            "ЗАЧЕТ, тест"
          ]}
        />
      )
    },
    {
      title: "2-й модуль",
      text: (
        <List
          arr={[
            "управление впечатлением",
            "структура имиджа, оценка впечатления",
            "психологические особенности разработки имиджа",
            "имиджевое восприятие и инструменты влияния",
            "концепция индивидуального стиля. Теория стилевых направлений",
            "концепция индивидуального стиля. Смешение стилевых направлений",
            "мужской стиль. Особенности стильного образа у мужчин",
            "дресс-коды, этикет",
            "практика формирования клиентского STYLE BOOK",
            "изучение особенностей презентации себя как профессионала в соц. сетях (Instagram)",
            <React.Fragment>
              <b>Специальный модуль: Профайлинг</b>
              <List
                arr={[
                  "изучение психологии клиентов, правил коммуникаций с клиентами",
                  "современная система профайлинга и психотипирования",
                  "алгоритмы работы с клиентами"
                ]}
              />
            </React.Fragment>,
            "ЭКЗАМЕН, защита проектной работы"
          ]}
        />
      )
    },
    {
      title: "3-й модуль",
      text: (
        <List
          arr={[
            "изучение истории моды от древности до наших дней как необходимой базы в практике fashion-стилиста",
            "изучение специальных дисциплин по композиции для стилистов",
            "развитие навыков креативного мышления (эвристика)",
            "изучение метода ассоциаций для создания художественного образа или fashion-образа",
            "метод эмоциональных линий",
            "особенности стилизации проектов для клиентов и глянца",
            "источники вдохновения для имиджмейкеров и стилистов, метод исследования",
            "практика создания и презентации mood-boards",
            "изучение основ коммуникаций стилистов с фотографами, моделями, визажистами",
            "практика работы в команде и реализации fashion-съемки",
            "практика работы в команде и реализации имиджевой сьемки",
            "практика работы в команде и реализации предметной сьемки",
            <React.Fragment>
              <b>Специальный модуль: Теория и практика моды</b>
              <List
                arr={[
                  "мода как система, мода как бизнес",
                  "теория и аналитика fashion-индустрии",
                  "анализ модных трендов и обзор актуальных показов",
                  "сегменты fashion-рынка",
                  "понятие и анализ ДНК брендов"
                ]}
              />
            </React.Fragment>,
            "ЭКЗАМЕН, защита проектной работы"
          ]}
        />
      )
    },
    {
      title: "4-й модуль",
      text: (
        <List
          arr={[
            "имидж-технологии в управлении деловой репутацией",
            "профессиональный стиль",
            "этапы создания личного бренда",
            "способы продвижения профессиональных услуг стилиста и имиджмейкера на рынке",
            "формирование портфолио стилиста",
            "подготовка к защите дипломной работы",
            <React.Fragment>
              <b>Специальный модуль: Основы создания текстов о моде</b>
              <List
                arr={[
                  "блоггинг как творчество и блоггинг как бизнес",
                  "создание текстов о моде и стиле",
                  "создания текстов и подбора иллюстраций для интернет и соц. сетей"
                ]}
              />
            </React.Fragment>
          ]}
        />
      )
    },
    {
      title: "В процессе обучения в программу включены:",
      text: (
        <List
          arr={[
            "МАСТЕР-КЛАССЫ С ПРИГЛАШЕННЫМИ ЭКСПЕРТАМИ",
            "ПОСЕЩЕНИЕ ПОКАЗОВ И НЕДЕЛЬ МОДЫ",
            "УЧАСТИЕ В МОДНЫХ ПРОЕКТАХ",
            "ОБЩЕНИЕ И ЗНАКОМСТВО С БУДУЩИМИ ПАРТНЕРАМИ И ЕДИНОМЫШЛЕННИКАМИ",
            "ПОСТОЯННАЯ СТИЛЬНАЯ АТМОСФЕРА"
          ]}
        />
      )
    },
    {
      title: false,
      text: (
        <List
          arr={[
            <span
              style={{
                fontSize: "1.4em",
                fontWeight: 500,
                color: "rgb(80,80,80)"
              }}
            >
              <img
                src="/img/green/seal.svg"
                alt=""
                style={{ height: 24, marginBottom: -5 }}
              />
              Итоговая аттестация: <b>защита дипломной работы</b>
            </span>
          ]}
          num="none"
        />
      )
    }
  ];

  return (
    <div>
      <Cot
        textButton="Программа: содержание"
        text={content.map(
          (el, i) =>
            el.title ? (
              <div>
                <h3
                  style={{
                    fontFamily: props.font ? props.font.title : "inherit",
                    fontSize: "1.4em",
                    fontWeight: 500,
                    textAlign: "center",
                    color: "rgb(100,0,0)"
                  }}
                >
                  {el.title}
                </h3>
                {el.text}
              </div>
            ) : (
              el.text
            )
        )}
      />
    </div>
  );
}
