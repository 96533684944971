import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./content";
import Page from "../../components/page";
import { MiniForm } from "../../components/forms";
import {
  BuilderLayout,
  Programma,
  Text3Block,
  TitleH2,
  HeadBlock,
  TextAndTags
} from "../../patterns/pattern-fs/components";
import { ImageText } from "../chatbot/image-text";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Профессия: Дизайнер чат-ботов с искусственным интеллектом</title>
        <meta
          name="keywords"
          content="профессия, AI, IЕ, бот, лингвистика, ИИ"
        />
        <meta
          name="descript"
          content="Вы научитесь создавать чат-ботов на конструкторе Aimylogic, реагирующих на естественно-языковые обращения, в системе онлайн коммуникаций (мессенджеров). По итогам — документ ВУЗа, который котируется во всем мире"
        />
      </Helmet>
      <Page lable="AI Bot">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.headNew} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content={"Партнеры проекта"} scroll="partners" />
              <ImageText content={c.mfti} classBox="mb" />
              <ImageText content={c.vshsdt} />
            </>,
            <Programma content={{ programma: c.programma, variants: false }} />,
            <>
              <TitleH2 content={c.text1.title} scroll="aboutprofassion" />
              <TextAndTags
                content={{
                  text: c.text1.text,
                  tags: false
                }}
              />
            </>,
            <>
              <TitleH2 content="Записаться на обучение" scroll="singup" />
              <TextAndTags
                content={{
                  text: c.textNote,
                  tags: false
                }}
              />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} />
              <MiniForm formName="miniforma-style-your-life" />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
