import React from "react";
import List from "../../components/list";
import Video from "../../components/video";
import Paragraph from "../../components/p-array";
import Ig from "../../components/instagram";
import "../imagemaker-stylist/style.css";

export const contents = {
  head: {
    left: (
      <div className="imagemaker-stylist-header">
        <h1 className="h1-constructor-header">
          Курсы стилистов, дизайнеров
          <br />
          имиджмейкеров в Красноярске
        </h1>
        <h3 style={{ paddingTop: 0 }}>Обучение профессиям</h3>
        <p>
          Стилист, имиджмейкер <sup>от 3.5 мес. до 1 года</sup>
        </p>
        <p>
          Интерьерный стилист <sup>от 3.5 до 9 мес</sup>
        </p>
        <p>
          Стилист фотосессий <sup>4 месяца</sup>
        </p>
        <p>&emsp;</p>
        <p>
          Повышение квалификации в области моды и стиля, продвижения брендов
          одежды
        </p>
        <p>&emsp;</p>
        <p>Личное развитие в области моды, стиля и дизайна</p>
        <h3>Открытые лекции</h3>
        <div className="imagemaker-stylist-tags">
          <a href="#programma">#программы</a>
          &emsp;
          <a href="#about">#ошколе</a>
          &emsp;
          <a href="#price">#стоимость</a>
        </div>
      </div>
    ),
    right: (
      <div className="imagemaker-stylist-head-logo">
        <img src="/img/root-test2/emblema-3d-5.png" alt="ВШСДТ Красноярск" />
        <h3>ВШСДТ. Красноярск</h3>
      </div>
    )
  },
  contacts: [
    {
      phone: "+7 (916) 058 00 92",
      phoneLink: "+79160580092",
      name: "Евгения",
      tg: "evgeniya_pimenova",
      wa: "79160580092"
    },
    {
      phone: "+7 (391) 220 65 05",
      phoneLink: "+73912206505",
      name: "",
      tg: false, //"evgeniya_pimenova",
      wa: false //"79160580092"
    }
  ],
  text3block: [
    {
      title: "Форматы обучения",
      text: [<>Заочное (очное + онлайн)</>]
    },
    {
      title: "Преподаватели",
      text: [<>Россия, Европа</>]
    },
    {
      title: "Дипломы",
      text: [<>ВШСДТ.Москва</>]
    }
  ],
  cardConsultingStyle: {
    image: {
      title: (
        <>
          КОНСУЛЬТАНТ ПО СТИЛЮ
          <br />
          БЫСТРЫЙ СТАРТ
        </>
      ),
      image: "/img/imagemaker-stylist/consulting-style.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: 3,5 месяца",
      specialisation:
        "Консультирование частных клиентов по имиджу и стилю с учетом индивидуальных особенностей и модных тенденций.",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            "Personal Styling. Индивидуальный стиль от А до Я",
            "Практика создания персонального Style Book",
            "Особенности реализации фото съемок",
            "Главные тенденции моды ХХ и XXI века",
            "Принципы формирования портфолио стилиста",
            "Продвижение личного бренда стилиста"
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/style-your-life",
        text: "подробнее"
      }
    }
  },

  cardImagemaker: {
    image: {
      title: (
        <>
          ИМИДЖМЕЙКЕР, СТИЛИСТ
          <br />
          ПОЛНОЕ ПОГРУЖЕНИЕ
        </>
      ),
      image: "/img/imagemaker-stylist/imagemaker.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: от 8 месяцев до 1 года",
      specialisation:
        "Консультирование частных и корпоративных клиентов, работа с фото проектами, журналами, дизайнерами",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>Профессиональные методики создания имиджа и стиля</>,
            <>
              Психология и стилевые предпочтения. Алгоритмы
              имидж-консультирования клиентов
            </>,
            <>Маршруты и особенности шопинга в России и Европе</>,
            <>Практика стилизации фото и ТВ проектов, модных показов</>,
            <>Возможности коллабораций с дизайнерами, бутиками</>,
            <>Пути создания своего модного бизнеса</>,
            <>Создание и продвижение бренда стилиста</>
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/second-degree-imagemaker",
        text: "подробнее"
      }
    }
  },

  cardInteriorYourLife: {
    image: {
      title: (
        <>
          ИНТЕРЬЕРНЫЙ СТИЛИСТ
          <br />
          ДЕКОРАТОР
        </>
      ),
      image: "/img/interior-design-courses/interior-your-life.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: 3,5 месяца",
      specialisation:
        "Старт в профессии для новичков. Ёмко и насыщенно о самом важном для реализации идей в интерьере квартиры или загородного дома",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            "Концепция современного интерьера",
            "Стилистические особенности формирования интерьера",
            "Цветопсихология и колористика",
            "Основы композиции в дизайне интерьера",
            "Эргономика — основа комфортного интерьера",
            "Замеры, фотофиксация, техническое задание",
            "Формирование концепции интерьера на основе индивидуальных предпочтений клиента"
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/interior-your-life",
        text: "подробнее"
      }
    }
  },

  cardLivingInteriorDesign: {
    image: {
      title: (
        <>
          ПРОФЕССИЯ:
          <br />
          ДИЗАЙНЕР ИНТЕРЬЕРА
        </>
      ),
      image: "/img/interior-design-courses/living-interior-design.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: от 9 месяцев",
      specialisation:
        "База знаний и практик для работы с заказчиками и жилым пространством, включая проектирование в ArchiCAD и 3d визуализации",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>Архитектурные и интерьерные стили</>,
            <>Методики развития креативности</>,
            <>Эргономика, цвет и композиция в интерьере</>,
            <>Строительные нормы и правила</>,
            <>Подготовка сметы и работа с бюджетом</>,
            <>Создание планов помещения: 2D, 3D</>,
            <>Декорирование интерьера</>
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/living-interior-design-and-decoration",
        text: "подробнее"
      }
    }
  },
  about: {
    image: (
      <div style={{ textAlign: "center" }}>
        <img
          src="/img/imagemaker-stylist/about.webp"
          alt="about"
          style={{ maxWidth: "100%", transform: "scale(-1,1)", maxHeight: 350 }}
        />
      </div>
    ),
    text: (
      <>
        <Paragraph
          content={[
            <>
              ВШСДТ — АНО ДПО «Высшая школа стилистики, дизайна и технологий»
              одна из ведущих образовательных площадок, основатели которой
              работают в индустрии с 2002 года.
            </>,
            <>
              Мы даем полноценные курсы профессиональной переподготовки и
              повышения квалификации в индустрии моды и дизайна как очно, так и
              дистанционно.
            </>,
            <>
              Мы предоставляем широкий спектр возможностей освоения программ:
              долгосрочные и краткосрочные программы, интенсивы, практикумы и
              многое другое.
            </>
          ]}
          style={{ paddingBottom: "1rem", lineHeight: "2rem" }}
        />
      </>
    ),
    head: "О школе"
  },
  headMiddleForm: (
    <>
      Есть вопросы?
      <br />
      <span
        style={{
          fontFamily: "Roboto",
          fontSize: "1.2rem",
          fontWeight: 400,
          color: "rgb(80,80,80)",
          textShadow: "none"
        }}
      >
        Пишите в мессенджер или отправьте запрос через форму обратной связи
      </span>
    </>
  ),
  teachersAndGraduatesInProjects: (
    <>
      <h3 className="h3-constructor">
        Наши преподаватели и выпускники в проектах:
      </h3>
      <p className="p-constructor">
        La Botanica Magazine (Франция), Faddy Magazine (Италия), Feroce Magazine
        (Великобритания), Imirage Magazine (Канада), F.R.W.L. Magazine (Россия),
        L'affaire Magazine (Великобритания), Surreal Magazine (Германия),
        Scorpio Jin Magazine (США), BeautyMute Magazine (Франция), Voque
        (Италия), Baby Moda Magazine (Россия), LM Magazine (Россия), The Look
        (www), Fashion Time (www). А также на Russian Fashion Week, в Музее
        Моды, в Музее Музыки, на радио и ТВ, на Всероссийском Съезде
        Стилистов-Имиджмейкеров, с корпоративными и частными клиентами.
      </p>
    </>
  ),
  teachers: {
    mpsu: {
      image: (
        <img
          src="/img/krasnoyarsk/mpsu.png"
          alt="МОСКОВСКИЙ ПСИХОЛОГО-СОЦИАЛЬНЫЙ УНИВЕРСИТЕТ"
          style={{}}
        />
      ),
      text: (
        <>
          <p className="p-constructor">
            <strong>МОСКОВСКИЙ ПСИХОЛОГО-СОЦИАЛЬНЫЙ УНИВЕРСИТЕТ</strong>
          </p>
          <p className="p-constructor">
            Филиал образовательной автономной некоммерческой организации высшего
            образования «Московский психолого-социальный университет» в г.
            Красноярске. В филиале МПСУ работают более 70% докторов и кандидатов
            наук. Университет сотрудничает с ведущими ВУЗами гг. Красноярска,
            Москвы, Тюмени, Новосибирска и др. Студенты совместно с
            педагогическим коллективом ВУЗа постоянно участвуют в создании и
            реализации научных разработок, проектов, в научно-практических
            конференциях (межвузовских, городских, краевых, общероссийских). В
            процессе обучения студенты проходят учебные и производственные
            практики.
          </p>
        </>
      )
    }
  },
  text: [
    <>
      В Красноярске курсы стилистов, имиджмейкеров, дизайнеров интерьера и ряд
      других курсов в области моды и дизайна доступны в заочном формате. Вы
      можете одновременно учиться у ведущих преподавателей и экспертов
      ВШСДТ.Москва + регулярно посещать очные практические занятия в Красноярске
    </>,
    <>
      Студенты ВШСДТ.Красноярск проходят очную практику в течении всего обучения
      на профессиональных программах, и получают возможность стажироваться в
      лучших fashion локациях. Обучаясь в ВШСДТ, занимаясь творчеством и черпая
      творческие идеи, студенты приобщаются к искусству, красоте и вдохновению,
      осваивают новые личные и профессиональные возможности в индустрии моды и
      дизайна.
    </>
  ],
  textAndMedia: [
    {
      text: (
        <>
          <h3 className="h3-constructor">Делайте мир прекрасным!</h3>
          <p className="p-constructor">
            Вы тоже сможете, как и наши выпускницы, заниматься любимым делом,
            стать свободными в своем выборе, понимать моду и создавать
            индивидуальный стиль.
          </p>
          <p className="p-constructor">
            Посмотрите интервью с успешным имидж-стилистом Лидой Акимовой{" "}
            <a href="https://instagram.com/lididilisa" target="_blank">
              @lididilisa
            </a>{" "}
            Лида активно консультирует клиентов, заряжает всех супер позитивной
            энергией, проводит мастер-классы и теперь она наставник и ведущая
            специальных очных практик для студентов в ВШСДТ
          </p>
        </>
      ),
      media: <Video link="fOZzI3bKXIU" />
    },
    {
      text: (
        <>
          <h3 className="h3-constructor">Реальная практика</h3>
          <p className="p-constructor no-padding">
            Уже в процессе обучения в ВШСДТ публикации в международных журналах
            — это реально!
          </p>
          <p className="p-constructor no-padding">
            Жанна Рожнова{" "}
            <a href="https://instagram.com/zhannarozhnova" target="_blank">
              @zhannarozhnova
            </a>
            , Имидж-стилист, визажист:
          </p>
          <List
            arr={[
              "участвовала в проектах на условиях TFP",
              "потом получила коммерческие заказы на стилизацию фотосессий",
              "теперь в ее портфолио консультации частных клиентов и фотосессии для зарубежных журналов"
            ]}
            style={{ color: "rgb(100,100,100)" }}
          />
          <p className="p-constructor">
            Смотрите <a href="#graduates">далее</a>, что говорят{" "}
            <a href="#graduates">выпускники …</a>
          </p>
        </>
      ),
      media: <Video link="YVpzoQ9aIfI" />
    },
    {
      scroll: "diploms"
    },
    {
      text: (
        <>
          <div className="block-left-space">
            <h3 className="h3-constructor">Диплом</h3>
            <p className="p-constructor">
              Отличайтесь от множества конкурентов с сертификатами — обладайте
              статусом дипло&shy;миро&shy;ван&shy;ного специа&shy;листа
            </p>
            <p className="p-constructor">
              Подготовьтесь к общению с адекватными клиентами и с люксом —
              получите опыт, личный бренд, помощь настав&shy;ников и участие в
              проектах
            </p>
            <p className="p-constructor">
              NB: Диплом — документ строгой отчетности, выдается только на
              основе лицензии, в нем прописаны изученные дисцип&shy;лины и право
              деятель&shy;ности в изученной области
            </p>
          </div>
        </>
      ),
      media: (
        <img
          src={"/img/imagemaker-stylist/diplom-2.jpg"}
          style={{ width: 320, height: "auto" /* maxWidth: "100%" */ }}
          alt="диплом Высшей школы стилистики, дизайна и технологий"
        />
      )
      // side: true
    },
    {
      text: (
        <>
          <div className="block-left-space">
            <h3 className="h3-constructor">Какие перспективы у профессии</h3>
            <p className="p-constructor">
              Смотрите вебинар о перспективах раз­вития в профес­сии стилиста,
              имидж­мейкера: специализации в профессии, успеш­ные кейсы и
              истории. Оцените, какие ниши и сег­менты можно занять в индус­трии
              моды. Убедитесь, что это по-настоящему по­тряса­ющая и творческая
              работа.
            </p>
            <p className="p-constructor">
              Спикер — Елена Прохорович <Ig a="elenacult_com" />
            </p>
          </div>
        </>
      ),
      media: <Video link="AGcaa7kGFLE" />,
      side: true
    }
  ],
  boldText: {
    boldText: <>Набор групп на профессиональное обучение открыт с</>,
    text: false
  },
  garanty: {
    title: "Финансовая гарантия",
    text: (
      <>
        В программе действует безусловная гарантия на возврат 100% денег в
        течении 14 дней. Это значит, что если вы по какой-то причине передумаете
        учавствовать, мы вернем всю сумму
      </>
    )
  },
  education: (
    <>
      <div style={{ maxWidth: 600, margin: "4rem auto" }}>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Обучение в ВШСДТ — это отличная теоретическая подготовка в сочетании с
          формированием творческого мышления и регулярной практикой создания
          модных и стильных образов
        </p>
        <div style={{ margin: "1rem auto", textAlign: "center" }}>
          <svg width={64} height={64} viewBox="0 0 24 24">
            <path
              fill="rgb(160,160,160)"
              d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"
            />
          </svg>
        </div>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Получение системных знаний, новые возможности профессионального
          развития, технологии работы с клиентами и модными трендами.
        </p>
      </div>
    </>
  ),
  formats: [
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z"
          />
        </svg>
      ),
      title: "Банковская рассрочка",
      price: false,
      text: (
        <>
          от 5750 рублей в месяц
          <br />
          рассрочка на год без переплат
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
          />
        </svg>
      ),
      title: "Онлайн / Заочно",
      price: false,
      text: (
        <>
          от 15000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            d="M20,17C21.1,17 22,16.1 22,15V4C22,2.9 21.1,2 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9C1.5,7.9 2.4,7 3.5,7H15M8,4C8,5.1 7.1,6 6,6C4.9,6 4,5.1 4,4C4,2.9 4.9,2 6,2C7.1,2 8,2.9 8,4Z"
            fill="rgb(122,101,89)"
          />
        </svg>
      ),
      title: "Очное обучение",
      price: false,
      text: (
        <>
          от 20000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    }
  ],
  youtube: {
    head: false,
    text: (
      <>
        <p className="p-constructor text-center">
          Подписывайтесь на Youtube канал ВШСДТ ТВ:
          <br />
          интервью с преподавателями, экспертами, выпускниками, открытые лекции,
          обзоры трендов
        </p>
        <p className="text-center">
          <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
            Перейти на Youtube канал &gt;&gt;&gt;
          </a>
        </p>
      </>
    )
  },
  papersTitle: "Три варианта обучения, стоимость",
  videos: [
    {
      video: "rYKZAsSnC84",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с основателем ВШС в Новосибирске Надеждой Исхаковой"
    },
    {
      video: "gZeWO85gha0",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Риберой Делаварес"
    },
    {
      video: "9Ns4tVxzE_8",
      text: "Грузинские дизайнеры. Эксперты: Татьяна Фомина, Татьяна Ярина."
    },
    {
      video: "VG3HCLBilGc",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Мариной Никулиной"
    },
    {
      video: "0s4k1IH3Pik",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Еленой Малюшиной"
    },
    {
      video: "npJ2B4syBMk",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Эвелиной Коломыцыной"
    }
  ]
};
