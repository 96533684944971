import React from "react";
import Page from "./page";
import List from "../../components/list";
import styles from "../../components/styles-for-types";
// import ScrollableAnchor from "react-scrollable-anchor";

const Corporate = function(props) {
  return (
    <Page
      title={"Создание системы дистанционного обучения"}
      faqultet={"СДО"}
      head={{
        text: (
          <span style={{ fontFamily: "inherit" }}>
            Создание системы
            <br />
            дистанционного обучения
          </span>
        ),
        image: "linear-gradient(rgba(255,0,0,.3), rgba(0,255,0,.3))",
        descript: (
          <span>
            Для учебных центров и ВУЗов.
            <br />С учетом специфики реализации программ ВО и ДПО с применением
            дистанционных технологий обучения
          </span>
        ),
        colorText: "rgb(80,80,80)",
        colorBlock: "rgba(0,0,0,0.6)"
      }}
      info={[
        {
          image: "img/corporate/butique.jpg",
          text: "Fashion пространства"
        },
        {
          image: "img/corporate/art-space.jpg",
          text: "Art пространства"
        },
        {
          image: "img/corporate/auditory.jpg",
          text: "Лектории"
        },
        {
          image: "img/corporate/baza.jpg",
          text: "Выездные мероприятия"
        }
      ]}
      infoSize={3}
      // short={[ ]}
      orientations={[
        {
          link: false,
          text: "Полноценная LMS* система"
        },
        {
          link: false,
          text: "Платформа для проведения вебинаров"
        },
        {
          link: false,
          text: "Личный кабинет для студентов"
        },
        {
          link: false,
          text: "Подключение онлайн платежей"
        },
        {
          link: false,
          text: "Интеграция со сторонними сервисами"
        },
        {
          link: false,
          text: "Интеграция с сайтом ВУЗа"
        }
      ]}
      orientationSize={4}
      smallOrientations={true}
      descript={{
        text:
          "Оплата по контракту только за тех поддержку и непосредственно используемые сторонние ресурсы. Развертывание и настройка системы дистанционного обучения входит в стоимость контракта.",
        image: false
      }}
      programmaName="Что входит в процесс создания системы дистанционного обучения"
      programma={[
        {
          link: "one",
          head: "Разработка архитектуры СДО и перспективных интеграций",
          text: (
            <List
              arr={[
                "анализ учебного процесса, согласование ТЗ на создание системы дистанционного обучения",
                "рекомендации по выбору технических решений для создания и проведения дистанционных курсов",
                "аренда выделенных серверов, настройка архитектуры в соответствии с согласованными задачами",
                "создание корпоративного git репозитория или подключение к репозиторию Заказчика для ведения проекта",
                "развертывание платформы дистанционного обучения на домене Заказчика с персонализированным входом для пользователей и администраторов",
                "развертывание платформы для проведения вебинаров в СДО Заказчика с иерархией доступа, в т.ч. гостевым доступом"
              ]}
              style={styles.p}
            />
          )
        },
        {
          link: "two",
          head: "Техническая поддержка",
          text: (
            <List
              arr={[
                "ежемесячный мониторинг устойчивости системы, логирование ошибок, испраление внештатных ситуаций, бэкап",
                "работа с Российскими и зарубежными дата центрами, администрирование выделенных серверов",
                "настройка сторонних сервисов и приложений",
                "обучение администраторов СДО",
                "другие работы"
              ]}
              style={styles.p}
            />
          )
        },
        {
          link: "tree",
          head: "Передача прав на использование СДО",
          text: (
            <List
              arr={[
                "предусмотрена Контрактом",
                "передается исходный код и инструкции по управлению СДО"
              ]}
              style={styles.p}
            />
          )
        }
      ]}
      info2={{
        head: "Характеристика основных модулей системы дистанционного обучения",
        blocks: [
          {
            title: "LMS Moodle",
            text: (
              <List
                arr={[
                  "Модульная объектно-ориентированная динамическая обучающая среда",
                  "Свободно распространяется по лицензии GNU GPL",
                  "Предоставляет возможность создавать систему управления онлайн обучением",
                  "Имеет наибольшее сообщество разработчиков",
                  "Система установлена более чем в 70% ВУЗов и учебных центров в России и зарубежом",
                  "Позволяет создавать брендированный интерфейс"
                ]}
                style={styles.p}
              />
            )
          },
          {
            title: "BigBluButton",
            text: (
              <List
                arr={[
                  "Открытое программное обеспечение для проведения веб-конференций",
                  "Сервер BigBlueButton может работать в облачной среде",
                  "Позволяет проводить конференции, вебинары, онлайн общение",
                  "Количество пользователей — без ограничений",
                  "Количество одновременных сессий — без ограничений",
                  "Позволяет создавать брендированный интерфейс",
                  "Доступно подключение участников с мобильных устройств"
                ]}
                style={styles.p}
              />
            )
          }
        ]
      }}
      formaTitle="Условия сотрудничество и подписания контракта оговариваем при очной или онлайн беседе"
    />
  );
};

export default Corporate;
