import React from "react";
import Card from "../card";

export default function Vintazh70(props) {
  return (
    <Card
      head=""
      title="Винтаж: современный стиль, индивидуальный подход. 70-е годы"
      teacher="Светлана Кононец"
      descript={<span>Онлайн-лекция,</span>}
      onlineText="Онлайн-лекция"
      date="23 апреля"
      time="10:00"
      duration="45 минут"
      image="/img/modern-fashion-style/vintage70.jpg"
      text={
        <span>
          К 1970-м годам в «большой моде» сложилось положение, которое можно
          назвать кризисным. Впервые в истории высокой моды последняя вынуждена
          подстраиваться под стихийно возникающую моду улицы. Изменения,
          происходящие в обществе, формируют и новый идеал своего времени:
          взрослую «интеллектуалку» взамен юной, энергичной бунтарки 60-х.
          Впервые подиум предлагает в одном сезоне не только различные модные
          формы, но и стили. Каким образом вещи этого периода могут составить
          часть современного костюма, поговорим на лекции.
        </span>
      }
      part
      // partPrice="600"
      view
      id="gvcJodRmEEQjnEhpf"
      setBillingId={props.setBillingId}
    />
  );
}
