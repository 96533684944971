import React from "react";
import { ShessBlock } from "../../patterns/pattern-fs/components";
import { bot } from "./content";

export default function Bot(props) {
  return (
    <ShessBlock
      content={{
        left: props.image ? (
          <img src={props.image} alt="Открытое образование" />
        ) : (
          bot.image
        ),
        right: props.text || bot.text
      }}
      mediaLeft={true}
    />
  );
}
