import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="История моды"
      title="Chanel: от Парижа до Голливуда и сексуальной революции"
      teacher="Руслан Мигранов"
      descript={<span>Онлайн-лекция</span>}
      onlineText={
        <span>
          Запись вебинара от 13.02.19
          <br />
          Длительность: 45 минут
        </span>
      }
      image="/img/modern-fashion-style/chanel.jpg"
      text={
        <span>
          В этой лекции мы проследим творческий путь Coco Chanel от ее увлечения
          дизайном женских шляпок до поездки в Голливуд в начале 30-х. и далее
          до сексуальной революции. Как менялась мода от эпохи модерна до
          «свингующих шестидесятых» и почему Chanel сопутствовал успех. Путь с
          подмостков сцены до всемирной славы! Правда ли что именно Chanel была
          законодательницей моды на загар? И в чем просматривается русский след
          при создании самых знаменитых духов №5? Затронем ее дружбу с Дягилевым
          и другими русскими и их влияние на ее творчество. Самые знаменитые
          творения великой мадмуазель и источники их вдохновлявшие. Долгий роман
          с журналом Voguе и его роль в ее карьере.
          <br />
          Правда ли, что именно Chanel была законодательницей моды, на ношение
          брюк? Ее отношения с русскими манекенщицами. Почему Chanel не любила и
          не признавала мини-юбки.
          <br />
          Чем вдохновлялась, при создании своего знаменитого твидового костюма и
          знаменитой сумочки 2.55. Самые знаменитые творения великой мадмуазель
          и источники их вдохновлявшие.
        </span>
      }
      part
      // partPrice="600"
      crown
      view
      id=""
      setBillingId={props.setBillingId}
    />
  );
}
