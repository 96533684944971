import React from "react";
import Paragraph from "../../components/p-array";
import { styles } from "../../patterns/pattern-educations/style";

export const content = {
  head: "Практики и стажировки",
  tiles: [
    {
      head: (
        <span>
          ИМИДЖ КОНСУЛЬТИРОВАНИЕ
          <br />
          Практика #1 для студентов
        </span>
      ),
      typeEducation: "Краткосрочный курс",
      duration: "5 дней. Очно, Москва",
      specialisation: "Практика #1 после 3-х месяцев обучения",
      price: false,
      process:
        "Говорим о клиентах, изучаем модные тенденции, ассортимент, закрепляем навыки планирования индивидуального гардероба на «все случаи жизни», практикуемся в выборе и реализации стилевых решений на основе базовых методик: колоритов, коррекции фигуры, стилей в одежде и принципов их сочетания.",
      diplom: "сертификат",
      link: {
        type: "form",
        text: "записаться",
        link: false
      },
      link2: {
        type: "insideLink",
        text: "подробнее",
        link: "/practice-image-consulting-1"
      },
      space: "5.5rem"
    },
    {
      head: (
        <span>
          ИМИДЖ КОНСУЛЬТИРОВАНИЕ
          <br />
          Практика #2 для студентов
        </span>
      ),
      typeEducation: "Краткосрочный курс",
      duration: "5 дней. Очно, Москва",
      specialisation: "Практика #2 после 6-ти месяцев обучения",
      price: false,
      process:
        "Изучаем модные тенденции, закрепляем навыки консультирования клиентов, практикуемся в выборе и реализации стилевых решений на основе: базовых методик, методик создания стиля с учетом художественных решений, на основе психологических особенностей разработки имиджа.",
      diplom: "сертификат",
      link: {
        type: "form",
        text: "записаться",
        link: false
      },
      link2: {
        type: "insideLink",
        text: "подробнее",
        link: "/practice-image-consulting-2"
      },
      space: "5.5rem"
    },
    {
      head: (
        <span>
          СТИЛЬ ДЛЯ СТИЛИСТА
          <br />
          Мастерская Светланы Шабалиной
        </span>
      ),
      typeEducation: "Практикум",
      duration: "6 занятий. Очно, Online",
      specialisation:
        "Мастерская преподавателя для начинающих специалистов и студентов, где Светлана выступает консультантом и наставником в насущных вопросах по имиджу и стилю.",
      price: false,
      process: "",
      diplom: "",
      link: {
        type: "form",
        text: "записаться",
        link: false
      },
      link2: {
        type: "insideLink",
        text: "подробнее",
        link: "/style4stylist"
      }
      // space: "5.5rem"
    }
  ],
  descript: false
};
