import React from "react";
import { Hidden, Paper } from "@material-ui/core";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import { styles } from "./styles";
import Info from "./info";
import Cot from "../../components/cot";
import ScrollableAnchor from "react-scrollable-anchor";
import { HorizontalForm } from "../../components/forms";

export const content = {
  faqultet: "Высшее образование дистанционно",
  title: "Графический дизайн",
  head: {
    titles: [
      "Факультет: Дизайна",
      "Направление: Графический дизайн",
      "Академическая степень: Бакалавриат (4 года)",
      "Форма обучения: ONLINE (заочно с применением дистанционных технологий)"
    ],
    descript: (
      <List
        style={styles.pHead}
        arr={[
          "Диплом ведущего в отрасли московского ВУЗа + международная нострификация",
          "Российские и зарубежные преподаватели практики и эксперты",
          "Профессиональное продвижение и карьера в процессе обучения",
          "от 9900 рублей в месяц"
        ]}
      />
    ),
    button: {
      text: "Как поступить",
      link: "#forma"
    }
  },
  target: {
    title:
      "Наша цель — востребованность и доход активных студентов уже в процессе обучения",
    text: (
      <p style={styles.p}>
        Наша сила в коллаборации, а именно в результате сотрудничества «Высшей
        школы стилистики, дизайна и технологий» и «Московского
        художественно-промышленного института». ВШСДТ обладает явными
        комтепенциями профессионального дистанционного обучения в креативных
        областях. МХПИ — обширным опытом проектной деятельности в дизайне,
        трудоустройстве выпускников, а так же репутацией и связями с зарубежными
        ВУЗами
      </p>
    )
  },
  targetRight: [
    "Дистанционное обучение считается официальной формой в России с 2012 года",
    "Мы создали полноценную среду для digital коммуникаций и атмосферы академического образования"
  ],
  infographics: {
    title: "Кто может поступить",
    info: [
      "Выпускники школ (предоставление результатов ЕГЭ обязательно)",
      <span>
        Выпускники колледжей (на&nbsp;2-й или 3&#8209;й&nbsp;курс бакалавриата)
      </span>,
      "Студенты других ВУЗов (авто зачет по общим дисциплинам)."
    ],
    text: (
      <Paragraph
        style={styles.p}
        content={[
          "Дистанционный бакалавриат как первое образование — это эффективный способ совмещать полный рабочий день и гибкий график обучения",
          "Дистанционный бакалавриат как второе образование — это возможность получить перспективную специализацию в процессе обучения в другом ВУЗе"
        ]}
      />
    )
  },
  work: {
    title: "Кем работают студенты и выпускники",
    text: (
      <Paper
        style={{
          ...styles.note,
          textAlign: "center",
          background: "rgb(240,240,250)",
          color: "rgb(80,80,80)",
          padding: "1em"
        }}
      >
        По данным сайта hh.ru на февраль 2020 года открыто более 2400 вакансий
        по специальности «Графический дизанер»
      </Paper>
    )
  },
  result: {
    title: "Результат обучения — компетенции",
    text: (
      <div>
        <List
          style={styles.p}
          arr={[
            "Работа с типографикой",
            "Оформление упаковки, мерча и сувенирной продукции",
            "Использование обратной связи",
            "Навык подбора и составления шрифтовой пары",
            "Анализ аудитории",
            "Работа с фотостоками",
            "Вывод бренда на рынок"
          ]}
        />
        <p style={styles.p}>
          Компетенции графического дизайнера открывают широкие возможности и
          перспективы работы в крупных компаниях, дизайн студиях, рекламных и PR
          агентствах, на фрилансе
        </p>
        <p style={styles.p}>
          В процессе обучения студенты осваивают растровые и векторные
          графические редакторы
        </p>
        <div
          style={{
            textAlign: "center",
            padding: 0
          }}
        >
          <img
            src="/img/graphic-design/adobeindesign.png"
            alt="Adobe InDesign"
            style={{ height: 90, marginRight: "1em" }}
          />
          <img
            src="/img/graphic-design/adobephotoshop.png"
            alt="Adobe Photoshop"
            style={{ height: 90, marginRight: "1em" }}
          />
          <img
            src="/img/graphic-design/adobeillustrator.png"
            alt="Adobe Illustrator"
            style={{ height: 90 }}
          />
        </div>
      </div>
    )
  },
  education: {
    title: "Как проходит обучение",
    text: (
      <div style={{ paddingBottom: "1em" }}>
        <h3 style={styles.h3}>Первый принцип обучения — система</h3>
        <Info
          info={[
            <span>Соответствует ФГОС&nbsp;ВО&nbsp;3++</span>,
            "Обучение из любой точки проживания",
            <span>Профессиональная IT&nbsp;платформа</span>,
            "Международные стандарты"
          ]}
          icon="/img/green/star.svg"
          sizeBlock={3}
        />
        <Cot
          colorButton="rgb(255,255,255)"
          colorButtonText="rgb(123,143,87)"
          border="1px solid rgb(123,143,87)"
          textButton="Подробнее о системе online обучения"
          text={
            <Paragraph
              style={{ ...styles.p, paddingBottom: "2em" }}
              content={[
                "Дистанционный формат обучения ведется на основе и с учетом всех требований, предъявляемых Министерством Образования РФ к программе направления «Графический дизайн», с правом получения академической степени (квалификации) бакалавра с Дипломом установленного государством образца",
                "У этого формата есть свои преимущества, связанные с доступностью обучения из любой точки проживания, с экономией времени, с максимальной скоростью передачи информации любого объема и вида",
                "Принципы организации учебного процесса не отличаются от очного или очно-заочного обучения. Студентам предоставляются все возможности полноценного обучения на основе современного портала, разработанного для реализации программ высшего образования с применением дистанционных технологий",
                "Каждому студенту предоставляется доступ в личный кабинет, который, по сути, является вашим учебным и рабочим местом. В личном кабинете хранятся все необходимые разделы и инструменты для обучения: алгоритм работы с порталом ДО, расписание, форумы, список необходимых контактов, ссылки на вебинары, на учебные задания, на списки литературы и интернет-источники, на необходимые организационные документы, на процентные показатели вашей личной успеваемости в освоении программы и т.п.",
                "Обучение проводится по расписанию, при этом у студентов есть возможность гибко подходить к изучению материалов. Форматы занятий: лекции (вебинары с группой и преподавателем на площадке или видеозаписи, предназначенная для самостоятельного прослушивания), практические и семинарские занятия (вебинары), самостоятельная работа, экзамены/зачеты.",
                "В течение всего срока обучения соблюдается логика освоения профессиональных знаний: необходимые и базовые для становления дизайн-мышления дисциплины гуманитарного и художественного направления чередуются с узкоспециальными дисциплинами, а также с основами правовых и экономических  знаний.",
                "На практических занятиях (в формате вебинаров) студенты представляют выполненные учебные задания. Преподаватель в режиме он-лайн анализирует и комментирует каждое задание. Студент таким образом видит и слышит достоинства и недостатки как своего задания, так и всех студентов группы. Такой максимальный охват дает возможность слушателям учесть максимальное количество нюансов, позволяющих минимизировать количество ошибок в практической деятельности.",
                "В обучении, помимо формата «контактная работа с преподавателем», предусмотрен формат самостоятельной работы. Для ведения самостоятельной деятельности по изучению той или иной дисциплины, для подготовки к практическим занятиям, к экзаменам, зачетам, выпускной квалификационной работе будут даны обязательные и необходимые ресурсы и рекомендации, в т.ч. (и прежде всего) в виде ссылок на актуальные и современные информационные издания, которые доступны в интернет-среде.",
                "В дистанционном формате присутствуют все формы промежуточной и итоговой аттестации: предоставление учебных заданий и сдача задолженностей по ним, проектные работы, зачеты, экзамены, выпускная квалифиКационная работа (публичная защита дипломной работы)",
                "Все материалы курса хранятся в личном кабинете. Всегда можно прослушать лекцию или практическое занятие, если оно было пропущено в силу уважительных причин. Всегда сможете обратиться к материалу повторно при подготовке к текущим и итоговым формам аттестации."
              ]}
            />
          }
        />
      </div>
    )
  },
  education2: (
    <div>
      <h3 style={styles.h3}>Второй принцип обучения — актуальность</h3>
      <Info
        info={[
          "Проектно-ориентированное обучение",
          <span>
            Возможность изучения вариативных дисциплин
            <Hidden smDown>
              <br />
              &ensp;
            </Hidden>
          </span>,
          <span>
            Профессиональный английский
            <Hidden smDown>
              <br />
              &ensp;
            </Hidden>
          </span>,
          <span>
            Очные сессии и практики
            <Hidden smDown>
              <br />
              &ensp;
              <br />
              &ensp;
            </Hidden>
          </span>
        ]}
        icon="/img/green/check-bold.svg"
        sizeBlock={3}
      />
      <Cot
        colorButton="rgb(255,255,255)"
        colorButtonText="rgb(123,143,87)"
        border="1px solid rgb(123,143,87)"
        textButton="Подробнее об актуальности online обучения"
        text={
          <Paragraph
            style={{ ...styles.p, paddingBottom: "2em" }}
            content={[
              "Формат дистанционного бакалавриата, как и очного обучения, подразумевает вариативность, позволяющую студентам изучать актуальные дополнительные и факультативные дисциплины по выботу, нацеленные на обзор современных тенденций в графическом дизайне и на расширение навыков в определенных областях.",
              "Дистанционный формат подразумевает организацию обязательного коммуникативного пространства и получение обратной связи: возможность общения с преподавателем на форуме, возможность общения с куратором, специалистами УМО, техническими специалистами. Каждый обучающийся имет возможность общения и диалога со всеми обучающимися потока/группы. Любые новости и сообщения, которые будут расположены на форумах, будут сразу же отражаться в вашей электронной почте. Для того, чтобы оперативно получать подобную информацию, вам не нужно постоянно проверять свой личный кабинет",
              "Обучение построено по системе расширения компетенций и выполнения проектов. С первого курса студенты получают навыки использования графических редакторов. Каждый следующий уровень / курс обучения — углубление в работу графического дизайнера и профессиональное продвижение (карьерное, фрилансера, бизнеса)",
              "В процессе обучения студенты обязательно изучают профессиональный английский и английский для IT специалистов",
              "В ходе реализации программы обучения предусмотрена производственная и учебная практика, которая может проводиться как по месту жительства, так и на очных сессиях в Москве."
            ]}
          />
        }
      />
    </div>
  ),
  internship: {
    title: "Стажировки",
    text: <div style={{ height: "2em" }} />
  },
  employment: {
    title: "Трудоустройство кейсы",
    text: <div style={{ height: "2em" }} />
  },
  conditions: {
    title: "Условия обучения",
    text: <div style={{ height: "2em" }} />
  },
  forma: {
    title: false,
    text: (
      <div style={{ paddingBottom: "1.4em" }}>
        <ScrollableAnchor id={"forma"}>
          <h2 style={styles.h2}>Как поступить</h2>
        </ScrollableAnchor>
        <HorizontalForm />
      </div>
    )
  }
};
