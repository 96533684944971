import React from "react";
import List from "../../components/list";
import { dates, Month, MonthToXX } from "../shedule/dates";
import Ig from "../../components/instagram";

const p = "p-constructor";
const h = "h3-constructor";

const online = `${dates.bFashionSprint.online.getDate()} ${Month(
  dates.bFashionSprint.online.getMonth()
)}`;

export const content = {
  title: "Business Fashion Sprint",
  keywords: "Fashion, Business, индустрии, моды, акселератор", // meta
  descript: "Акселерационная программа для предпринимателей индустрии моды", // meta
  lable: "fashionnovation",
  head: {
    headDescript: "Специализация",
    head: (
      <>
        Business Fashion Sprint:
        <br />
        Акселерационная программа
        <br />
        для предпринимателей индустрии моды
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Для начинающих предпринимателей индустрии моды, дизайнеров, проектов,
        сервисов. Даем эффективные инструменты и образ мышления, необходимые для
        построения своего бизнеса и входа в мировое fashion бизнес-сообщество.
      </div>
    ),
    descript: [
      <>Онлайн практикум</>,
      <>Удостоверение о повышении квалификации</>,
      <>Длительность 8 недель</>,
      <>Запуск бизнеса в США и Европе</>,
      <>Консультации с менторами</>,
      <>Рассрочка, налоговый вычет</>
    ],
    imageComment: false,
    imageSource: "/img/bfashionsprint/vika.jpg",
    hashTags: [
      {
        link: "#partners",
        text: "партнеры"
      },
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      },
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online}</>, <>онлайн февраль 2022</>]
    },
    {
      title: "Формат",
      text: [
        <>Blended learning – чат с коллегами,</>,
        <>видео лекции и он-лайн общение</>
      ]
    },
    {
      title: "Организаторы",
      text: [<>Основатели Fashionovation и</>, <>Get Funded Tools, США</>]
    }
  ],
  zolotova: {
    image: {
      src: "/img/bfashionsprint/zolotova.jpg", //"/img/chatbot/mfti-logo.webp"
      alt: "Виктория Золотова",
      style: { width: 200, height: "auto", borderRadius: "50%" },
      block: {
        backgroundColor: "white" // "rgb(1,114,192)"
      }
    },
    text: (
      <>
        <strong>Виктория Золотова</strong>
        <br />
        Основатель Fashionovation, основанной в 2015 году. Бизнес-программы для
        специалистов моды по всему миру (Нью-Йорк, Милан, Париж, Сеул, Шанхай) и
        образовательные программы для дизайнеров. Сопродюсер онлайн-школы
        fashion influencers Mary Leest Masterclass. Директор Good Partner
        Capital — поиск компаний поздних стадиях для инвестирования.
        <br />
        <Ig a="shantyvi" />
      </>
    ),
    link: false
  },
  rivodo: {
    image: {
      src: "/img/bfashionsprint/rivodo.jpg",
      alt: "Just AI",
      style: {
        width: 200,
        height: "auto",
        borderRadius: "50%"
      },
      block: {
        backgroundColor: "white" // "rgb(54,54,69)"
      }
    },
    text: (
      <>
        <strong>Анастасия Риводо</strong>
        <br />
        Основательница и управляющий партнер Get Funded Tools, GFT помогает
        импакт-стартапам привлекать инвестиции через коммуникацию ценностей
        бизнеса для инвестора и расширение органического инвест нетворка.
        Анастасия ментор в 500 startups, международный спикер и ex-СЕО
        технологических стартапов из Кремниевой Долины. Анастасия помогла
        привлечь более $80M в impact компании.
        <br />
        <Ig a="anastasiarivodeaux" />
      </>
    )
  },
  screen2: {
    video: "C5PrSla6aRw",
    text: (
      <>
        <p className="p-constructor">
          Мы уверены, что одна из проблем мира моды заключается в том, что
          дизайнеры обычно думают, что они творцы, но для успеха им также
          необходимо быть предпринимателями.
        </p>
        <p className="p-constructor">
          И если ваша цель — создать бренд, который будет приносить вам деньги,
          вы должны развивать его в соответствии со структурой и правилами
          построения бизнеса и маркетинга.
        </p>
        <p className="p-constructor">
          Также необходимо уделять внимание новым технологиям — в них кроются
          огромные возможности для креативных индустрий.
        </p>
      </>
    )
  },
  text1: {
    title: <>Профессия Дизайнер чат-ботов с ИИ</>,
    text: (
      <>
        Дизайнер чат-ботов с искусственным интеллектом создаёт умные решения для
        автоматизации рутинных задач. В последние годы устойчиво растёт спрос на
        создание умных диалоговых ассистентов для бизнеса, государства,
        образования, культуры, медицины и прочих сфер жизни общества. Поэтому
        профессионалы, способные создавать и обслуживать умные диалоговые
        системы, имеют высокие перспективы на рынке труда. В частности зарплата
        дизайнера диалоговых систем на основе данных hh.ru составляет: junior от
        50 000 руб., middle от 100 000 руб., senior от 170 000 руб.
      </>
    )
  },
  basis: {
    image: <img src="/img/chatbot/base.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Основа обучения</h3>
        <p className={p}>
          Освоение профессии дизайнера чат-ботов с искусственным интеллектом для
          текстовых каналов коммуникаций базируется на программе курса «Основы
          компьютерной лингвистики и машинного обучения для создания диалоговых
          ассистентов» (длительность 70 академических часов, 2 месяца). Курс
          разработан в рамках соглашения о сотрудничестве МФТИ и ВШСДТ от 02
          апреля 2021 года. Курирует программу технический директор «0110 ЛАБ»,
          разработчик чат-ботов, Сергей Серебров.
        </p>
      </>
    )
  },
  fashionProjects: {
    image: (
      <img src="/img/chatbot/for-fashion.jpg" className="image-for-shess" />
    ),
    text: (
      <>
        <h3 className={h}>
          Выполнение проектных работ для предприятий индустрии моды
        </h3>
        <p className={p}>
          В рамках данного обучения по выбору слушатели получают возможность
          освоить знания по программе «Структура и система современной индустрии
          моды» (длительность 30 акаде­мических часов) и выполнить проектную
          работу на основе стажировки на предприятии индустрии моды. Слушатели,
          успешно сдавшие зачет по данной дисциплине, получат удостоверение о
          повышении квалификации ВШСДТ по программе «Структура и система
          современной индустрии моды»
        </p>
      </>
    )
  },
  career: {
    image: <img src="/img/chatbot/career.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Профессиональное и карьерное продвижение</h3>
        <p className={p}>
          В данном проекте предусмотрена программа содействия в трудоустройстве
          на основе запросов профильных компаний. В&nbsp;целях повышения
          эффективности процесса трудоустройства и дальнейшего карьерного роста,
          слушатели курса проходят цикл дополнительных онлайн семинаров в
          области фор&shy;ми&shy;ро&shy;вания личного профессионального стиля,
          фор&shy;ми&shy;ро&shy;вания про&shy;фессиональной репутации в сети
          интернет, нюансов со&shy;став&shy;ления резюме и прохождения
          собеседований в IT компаниях
        </p>
      </>
    )
  },
  programma: {
    head: "Программа",
    content: [
      <p className={p}>
        <strong>Fashion Sprint содержит</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>Сегментация аудитории и анализ конкурентов</>,
          <>
            Определение миссии и ценности бренда, построение платформы бренда
          </>,
          <>Определение уникального предложения</>,
          <>Определение каналов продаж, схемы запуска бизнеса в США и Европе</>,
          <>Бизнес модель и финансовый план</>,
          <>Первые продажи и презентация проекта на Demo Day</>,
          <>Запуск своего NFT</>,
          <>Участие в конкурсе</>,
          <>Создание презентации для инвесторов</>,
          <>
            Участие в Demo Day возможность выиграть денежный и другие призы
            партнеров
          </>
        ]}
      />,
      <p className={p}>
        <strong>В процессе спринта</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>Персональные встречи с менторами</>,
          <>Бизнес воркшопы с экспертами</>,
          <>Задания по развитию бизнеса</>,
          <>Инструменты для роста, развития и управления</>,
          <>Знакомство с каналами продаж и профессионалами отрасли</>,
          <>
            Презентация своего бизнеса перед инвесторами, медиа-партнерами и
            профессионалами индустрии на финальном DEMO DAY
          </>
        ]}
      />,
      <p className={p}>
        <strong>В программе</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Использованы методики, применяемые в индустрии стартапов для
            привлечения инвестиций, а также опыт и нетворк 6 лет fashion бизнес
            программ по всему миру (Нью-Йорк, Милан, Париж, Сеул, Шанхай)
          </>
        ]}
      />,
      <p className={p}>
        <strong>Итог программы</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Четкое понимание своей аудитории, миссии, позиционирования, каналов
            продаж и стратегия; шаги запуска бренда в США и других странах.
          </>
        ]}
      />
    ]
  },
  rgsu: {
    text: (
      <>
        <p className="p-constructor">
          Слушатели, прошедшие обучение на курсе, получают удостоверение о
          повышении квалификации установленного государством образца по
          программе «Продвижение бренда одежды на международный рынок:
          акселерационная программа для предпринимателей индустрии моды
          Fashionovation», 16 академических часов
        </p>
        <p className="p-constructor">
          <strong>
            Официальный сайт проекта:{" "}
            <a href="https://fashionovation.us/fashion_sprint" target="_blank">
              fashionovation.us
            </a>
          </strong>
        </p>
        <a href="/download/business_fashion_sprint_russian_version.pdf">
          <div className="button">Скачать презентацию проекта</div>
        </a>
      </>
    ),
    image: (
      <img
        src="/img/udostoverenie-o-povyshenii-kvalifikatsii.png"
        alt="Удостоверение о повышении квалификации ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  teacherHead: "Менторы",
  teachers: [
    {
      image: "/img/bfashionsprint/cioccolato.jpg",
      name: "Элия Чоколато",
      text: (
        <>
          <p>Cioccolato Couture, Influencer 1.8M instagram, 650k Tik Tok</p>
          <List
            arr={[
              <>
                Founder&Fashion Designer Cioccolato Couture, (based in Miami)
                MBA Shanghai Economic university, В fashion bussiness с 2008 г.
              </>,
              <>
                Открыла и вела в России 40 франчайзинговых магазинов С&С Milano
                и С&С by Olga Buzova совместно с Ольгой Бузовой, с 2015 году
                компания в США.
              </>,
              <>
                Участите NYC fashion week, LA fashion Week, Washington DC
                fashion Week, Swim Miami Fashion Week
              </>
            ]}
          />
        </>
      ),
      instagram: "eliyacioccolato",
      buttons: false
    },
    {
      image: "/img/bfashionsprint/pashuta.jpg",
      name: "Виктория Пашута",
      text: (
        <>
          <p>Founder & Editor-in-Chief of BASIC Magazine</p>
          <List
            arr={[
              <>
                Предприниматель, основатель журнала Basic Magazine и PR showroom
                Basic в Лос-Анджелесе;
              </>,
              <>Фотограф, креативный директор, filmmaker и продюсер;</>,
              <>
                Работы публиковались RUNWAY (США), GQ, Esquire, VISION (Китай),
                Prestige International (Франция), Essence (США), Estetica (США),
                Vogue (Италия), Tchad (Канада), Fashizblack (Франция),
                Highlights (Великобритания), CULTURE (Австралия), съемки обложек
                Healthy Living Magazine, Runway, Orlando Style, Justine и др.
              </>,
              <>
                Работает с Пэрис Хилтон, Кэти Гриффин, Лэнс Басс, Тарин Мэннинг,
                Белла Торн, Келли Прайс, Кеке Палмер, Рошель Эйтес, Джеймс
                Голдштейн, София Ричи, Бранд Родерик и многие другие.
              </>,
              <>
                Среди ее наград — «ЛУЧШАЯ ФОТОГРАФИЯ» и «ЛУЧШИЙ ДИЗАЙН КОСТЮМА»
                на Международном фестивале fashion кино за фильм «Поцелуй
                сирены» (транслируется по FashionTV) и награда «Лучшая мода» на
                фестивале кино Mercedes Benz
              </>
            ]}
          />
        </>
      ),
      instagram: "viktorija_pashuta",
      buttons: false
    },
    {
      image: "/img/bfashionsprint/solomeina.jpg",
      name: "Елизавета Соломеина",
      text: (
        <List
          arr={[
            <>
              Сооснователь Flying Solo NYC — объединение независимых дизайнеров
              со всего мира.
            </>,
            <>Создатель бренда SOLOMEINA</>
          ]}
        />
      ),
      instagram: "elizaveta.solomeina",
      buttons: false
    },

    {
      image: "/img/bfashionsprint/draganova.jpg",
      name: "Ксения Драганова",
      text: (
        <>
          <p>
            Со-основатель курса «SMM для дизайнеров» и основатель
            телеграмм-канала FASHION стратегия
          </p>
          <List
            arr={[
              <>
                Разрабатывает стратегию по развитию и продвижению брендов
                украшений, белья, одежды и аксессуаров.
              </>,
              <>
                Является ментором начинающих дизайнеров, помогает на первых
                стадиях запуска бренда, производства и продвижения. Автор
                нескольких методологий
              </>
            ]}
          />
        </>
      ),
      instagram: "drahanova_kseniya",
      buttons: false
    }
  ],
  reviews: [
    {
      image: "/img/bfashionsprint/glinkina.jpg",
      title: "Sveta Glinkina",
      text:
        "Наш бренд был представлен Cosmopolitan, и я приняла участие в NYFW! А после мы запустили новый формат бизнеса и привлекли инвестиции!Мы очень благодарны за программу. Все мечты сбываются",
      instagram: "svetaglinkina"
    },
    {
      image: "/img/bfashionsprint/strokova.jpg",
      title: "Yana Strokova",
      text:
        "Наш бренд Rafinad начал продаваться в США, и мы приняли участие в NYFW, и каждый день открываются новые возможности для нашего бренда. Это потрясающая команда и подход!",
      instagram: "yastrokova"
    },
    {
      image: "/img/bfashionsprint/rocaille.jpg",
      title: "Tayana Rocaille",
      text:
        "Программа отличная! После этого я начала продаваться в Нью-Йорке, и мои продукты были опубликованы в L'Officiel, Weekly Style и др.! Я так рада, что нашла эту замечательную команду и программу!",
      instagram: "tatyana_rocaiile"
    }
  ],
  partners: [
    "/img/bfashionsprint/24fashion.png",
    "/img/bfashionsprint/basic.png",
    "/img/bfashionsprint/cioccolato.png",
    "/img/bfashionsprint/fashion-coin.png",
    "/img/bfashionsprint/nolcha.png",
    // "/img/bfashionsprint/styleschool.png",
    "/img/bfashionsprint/plat-for-many.png",
    "/img/bfashionsprint/proof-of-love.jpg",
    "/img/bfashionsprint/flying-solo.png",
    "/img/bfashionsprint/tavrida-art.png"
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>5000 &#8381;</>,
    full: <>50 000 &#8381;</>,
    discountSize: <>20%</>,
    discountDuration: "для групп",
    qrLink: "https://link.tinkoff.ru/1lVj7xQRFmr",
    durationInstallment: "10"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead: "Остались вопросы, получите консультацию менеджера"
};
