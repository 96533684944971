import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import {
  HeadBlock,
  Teachers,
  Programma,
  TextAndTags,
  SocnetBlock,
  // Price,
  BuilderLayout
} from "../../patterns/pattern-fs/components";

const Fashion = () => {
  return (
    <div>
      <Helmet>
        <title>Обучение главным тенденциям современной моды</title>
        <meta
          name="keywords"
          content="тенденции, тренды, индустрия, мода, современная, обучение, курсы, где, учиться, школа"
        />
        <meta
          name="descript"
          content="Краткий курс дает представление об основных стратегиях развития индустрии моды эпохи торжества технологий, относительности границ и множества способов самопрезентации. Цель курса — исследовать суть и направления современного искусства и рекламы, открытия и разработки в области текстиля, огромные возможности интернет-технологий, изменение отношения к потреблению и экологической безопасности, освоение новых рынков продаж и интерес к локальным брендам. Вы получите знание внутренних и внешних механизмов развития моды, выраженных в трендах и тенденциях, а также умение анализировать коллекции на основе знаний о ведущих тенденциях."
        />
      </Helmet>
      <Page lable="Аналитика моды" lableMobile="Аналитика моды">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <TextAndTags
              content={{
                text: c.text1,
                tags: false
              }}
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <>
              <Teachers
                content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
              />
              {/* <Price
                content={{ pay: c.pay, payOpen: c.payOpen, countPay: 1 }}
              /> */}
            </>,
            <SocnetBlock />
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
