import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Paragraph from "../../components/p-array";
import List from "../../components/list";
import { style } from "./styles";

export const content = {
  head: {
    title: "Fashion-каникулы в Тбилиси с 12 мая по 17 мая 2021 года",
    head: (
      <span>
        Fashion-каникулы в Тбилиси
        <br />с 12 мая по 17 мая 2021 года
      </span>
    ),
    descript: (
      <span>
        &ensp;
        <br />
        Уникальный модный тур в Тбилиси от экспертов Высшей школы стилистики,
        дизайна и технологий.
      </span>
    )
  },
  descript: {
    block1: (
      <div>
        <h3 style={style.h3}>Что будет:</h3>
        <Paragraph
          content={[
            <>
              Самые модные места Тбилиси. Обзорная экскурсия по старому городу.
            </>,
            <>Посетим модные шоурумы, концепт-сторы, дизайнерские магазины.</>,
            <>Познакомимся с талантливыми грузинскими дизайнерами,</>,
            <>Fashion-практика в Грузии.</>,
            <>
              Воркшоп по fashion-съемке под руководством опытных наставников. Вы
              станете членом команды и мы вместе сделаем концептуальную фэшн
              съемку «от идеи до реализации».
            </>,
            <>
              Повысите свою экспертность в мире моды, познакомитесь с
              представителями мирового фэшн коммьюнити (дизайнерами, модными
              экспертами, стилистами, фотографами, визажистами и пр.). Узнаем
              особенности индустрии в Грузии, познакомимся с аутентичными
              брендами.Снимем уникальный контент для соц.сетей
            </>
          ]}
          style={style.p}
        />
        <p style={style.p}>
          По предварительной информации в мае анонсирована{" "}
          <strong>Mercedes-Benz Fashion Week Tbilisi</strong>, если неделя моды
          состоится, мы посетим показы, выставки, вечеринки, профессиональные
          мероприятия в рамках недели моды. Также будет культурная программа,
          поездка в место силы, вкусная еда и веселье.
        </p>
        <h3 style={style.h3}>Для кого</h3>
        <p style={style.p}>
          Для экспертов индустрии моды и дизайна: стилистов, дизайнеров,
          имиджмейкеров, фэшн блогеров, инфлюэнсеров, фотографов, байеров и
          просто фанатов моды.
        </p>
        <h3 style={style.h3}>Организаторы тура</h3>
        <List
          style={style.p}
          arr={[
            <>
              <strong>Елена Прохорович</strong> — fashion-маркетолог,
              организатор стажировок и практик на Milan Fashion Week / Milan
              Design Week / Paris Fashion Week.
            </>,
            <>
              <strong>Инга Смирнова</strong> — практикующий стилист Москвы,
              работает со звездами ТВ и Youtube, стилизует клипы, съемки,
              проводит самые модные эвенты в городе, запускает fashion & design
              проекты, производства одежды.
            </>,
            <>
              <strong>Жанна Рожнова</strong> — практикующий стилист, стилизует
              модные съемки для журналов по всему миру (Америка, Австралия,
              Европа, Россия), стилист показов на Milan Fashion Week / Moscow
              fashion Week. Художник по костюмам в кино.
            </>
          ]}
        />
        <h3 style={style.h3}>Программа</h3>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <img
              src="/img/tbilisi-fashion-weekend/afisha.jpg"
              style={{ maxWidth: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ position: "sticky", top: 1 }}>
              <List
                style={style.p}
                arr={[
                  "Воркшоп fashion-съемка;",
                  "Мероприятия в рамках недели моды*;",
                  "Посещение шоурумов, концепт-сторов, ателье;",
                  "Знакомство с фэшн-экспертами (дизайнеры, фотографы, модели);",
                  "Шопинг;",
                  "Контент – съемки по красивым местам Тбилиси;",
                  "Культурная программа (экскурсии);",
                  "Гастрономический туризм, посетим лучшие места;",
                  "Гала ужин;"
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    block2: (
      <div style={{ marginTop: "2rem" }}>
        <h3 style={{ ...style.h3, paddingTop: 0 }}>В стоимость входит:</h3>
        <List
          style={style.p}
          arr={[
            <>
              вся программа, трансферы в Тбилиси, проживание, посещение
              мероприятий, воркшоп,работа кураторов, экскурсии.
            </>,
            <>
              <strong>Проживание.</strong> Мы арендуем большие апартаменты в
              центре Тбилиси. Проживание входит в стоимость поездки. По желанию
              вы можете проживать в отеле самостоятельно — за ваш счет.
            </>
          ]}
        />
        <p style={{ ...style.p, color: "rgb(160,40,40)" }}>
          Дорога и питание не входят в стоимость.
        </p>
      </div>
    )
  }
};
