import React from "react";
import { Paper, Grid } from "@material-ui/core";

export default function Papers(props) {
  const size = props.content.length === 2 ? 6 : 4;
  return (
    <>
      <Grid container spacing={3} alignItems="stretch" justify="space-between">
        {props.content.map(el => (
          <Grid
            item
            xs={12}
            md={size}
            style={{ padding: "1rem", boxSizing: "border-box" }}
          >
            <Paper style={{ paddingTop: "1rem", height: "100%" }}>
              <h3
                style={{
                  fontFamily: "Roboto",
                  color: "rgb(100,100,100)",
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  padding: 0,
                  marginBottom: "2rem"
                }}
              >
                {el.title}
              </h3>
              {el.gray && (
                <div
                  style={{
                    background: "rgb(240,240,240)",
                    color: "rgb(80,80,80)",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "1rem",
                    padding: "1rem",
                    fontWeight: 400
                  }}
                >
                  {el.gray}
                </div>
              )}
              <div
                style={{
                  color: "rgb(100,100,100)",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "1rem",
                  padding: "1rem",
                  fontWeight: 400
                }}
              >
                {el.text}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
