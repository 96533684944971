import React from "react";
import List from "../../components/list";

const Descript = props => {
  const style = {
    fontSize: "1em",
    color: "rgb(100,100,100)",
    padding: "4px 0"
  };
  return (
    <div>
      <List
        arr={[
          "«Мне нравятся украшения, но я совершенно не умею их носить!»",
          "«Я хочу научиться стильно подбирать бижутерию!»",
          "«Я не понимаю, как сочетать между собой украшения и одежду!»",
          "«Как выбирать украшения к лицу и не совершать ошибок?»",
          "«Можно ли обновить бижутерию, чтобы она выглядела модной, и как это сделать?»",
          "«Как связаны украшения и статус?»",
          <span>&nbsp;</span>,
          "Эти и похожие запросы слышит от клиентов время от времени каждый стилист. И хотя законы гармонии здесь действуют те же самые, что и в одежде, есть в украшениях свои тонкости, которые полезно знать и владельцу, и специалисту."
        ]}
        num="none"
        style={style}
      />
    </div>
  );
};

export default Descript;
