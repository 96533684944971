import React from "react";
import Footer from "../../components/footer";
import Helmet from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Teacher from "../../components/teacher";
import Top from "../../components/topmenu";
import List from "../../components/list";
import Paragraph from "../../components/p-array";

export default function Teach() {
  var content = [
    {
      title: "Сфера деятельности",
      text: (
        <Paragraph
          content={[
            "Дизайнер. Специалист в области интерьерного, ландшафтного, предметного дизайна, создатель концепций и проектов оформления мероприятий всероссийского уровня, основатель собственного дизайн бюро, преподаватель Высшей школы стилистики и Московского Художественно-Промышленного Института."
          ]}
        />
      )
    },
    {
      title: "Образование",
      text: (
        <List
          arr={[
            "Магистратура, МХПИ, направление Дизайн, окончила с отличием",
            "Visual&Branding Design, Intermark, International Design College, Китай, Шанхай",
            "Бакалавриат, МХПИ, направление Дизайн среды, окончила с отличием",
            "Специалитет, МГУПИ, Автоматизация и управление информационных ИС"
          ]}
        />
      )
    },
    {
      title: "Профессиональные навыки",
      text: (
        <Paragraph
          content={[
            "Работа в программах:",
            <List
              arr={[
                "Archicad",
                "Autocad",
                "3D Studio MAX",
                "Сinema 4D",
                "Adobe Photoshop",
                "Adobe Illustrator",
                "Adobe Lightroom",
                "Lumion"
              ]}
            />
          ]}
        />
      )
    },
    {
      title: "Курсы, повышения квалификации",
      text: (
        <List
          arr={[
            "Adidas group Russia, HR managment",
            "Marks & Spencer Head office, London, VM for flagship stores",
            "Adidas group Russia, «VM для руководителей подразделений с целью повышения KPI и общей эффективности площади»",
            "МХПИ, «Визуальный мерчендайзинг и витринистика»",
            "Индивидуальные занятия, Цинцевич Ю.Л. «Скетчинг. Архитектруный рисунок»",
            "Iulian Moise, Студийная фотография"
          ]}
        />
      )
    },
    {
      title: "Проекты",
      text: (
        <Paragraph
          content={[
            "Более 100 проектов домов и квартир",
            "Более 40 проектов офисов, ресторанов, магазинов и кафе",
            "Более 600 гектаров ландшафтного дизайна",
            "Более 30 3d-видео застраиваемых территорий и помещений",
            "География работ: Россия, Армения, Абхазия, Грузия, Крым, Испания, Италия, Польша, Литва, Марокко.",
            "Так же были выполнены следующие проекты:",
            <List
              arr={[
                "Планировочное решение и дизайн-проект полигона «Заря» ДОСААФ        России",
                "Разработка и реализация проекта разработки реорганизации территории и создания малых архитектурных форм и ненесущих конструкций для Всероссийского Молодежного Форума «Таврида» 2015 и 2016 гг.",
                "Создание дизайн-проекта полигона для соревнований радиоуправляемой техники на Фестивале робототехники и киберспорта в Туапсе",
                "Разработка дизайн-проекта организации и благоустройства территории бывшего метро-депо, решения фасадов существующих и возводимых зданий, ландшафтов, малых архитектурных форм, арт-объектов, а также интерьеров для Центра профилактики Детского Травматизма на Транспорте в Москве.",
                "Планировочное решение и дизайн-проект на реконструкцию Тренировочного полигона винтовочно-артиллерийского корпуса ДОСААФ России.",
                "Разработка и создание концепции территории ресторанно-гостиничного комплекса в Армении, с созданием искусственной системы рек.",
                "Проектирование ландшафтов в ЖК «Сказка», МО, Павловская Слобода",
                "Проект организации доступа к скалистым прибрежным зонам в Крыму, создание плавучих досуговых зон на понтонных конструкциях, Севастополь",
                "Моделирование и создание арт-объектов: роботов высотой до 15 метров для различных мероприятий на территории России.",
                "Создание мемориальных камней Мемориально-паркового комплекса героев Первой мировой войны, Москва, р-н Сокол."
              ]}
            />,
            "Так же принимала участие в создании декораций арт-объектов и малых   архитектурных форм для мероприятий:",
            <List
              arr={[
                "Яблочный спас, Пасха, Храм Христа Спасителя",
                "Макс Авиа, аэропорт Жуковский",
                "День города в Москве, Тверская улица",
                "Фестиваль мороженого, Тверская площадь",
                "Спасская Башня, ВДНХ",
                "Бронебот: Бои роботов в Олимпийском"
              ]}
            />
          ]}
        />
      )
    },
    {
      title: "Преподаватель дисциплин",
      text: (
        <List
          arr={[
            "Компьютерные технологии в дизайне",
            "Проектирование",
            "Организация пространства",
            "Креативность и личностное развитие в профессиональной сфере"
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Елена Ламонова</title>
        <meta
          name="keywords"
          content="Елена Ламонова, дизайнер, стиль, интерьер, школа"
        />
        <meta
          name="descript"
          content="Елена Ламонова — дизайнер, специалист в области интерьерного, ландшафтного, предметного дизайна, создатель концепций и проектов оформления мероприятий всероссийского уровня, создатель собственного дизайн бюро. Преподаватель ВШСДТ профессиональной переподготовки курса «Дизайн интерьера», «Сам себе дизайнер»"
        />
      </Helmet>
      <Top faqultet="Преподаватели" />
      <Grid container justify="center" alignItems="flex-start">
        <Grid item style={{ padding: 20 }}>
          <Teacher
            head="Елена Ламонова"
            short="Дизайнер. Специалист в области интерьерного, ландшафтного, предметного дизайна, создатель концепций и проектов оформления мероприятий всероссийского уровня, создатель собственного дизайн бюро, преподаватель Высшей школы стилистики"
            image="/img/teachers/lamonova.jpg"
            tiles={[]}
            content={content}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
