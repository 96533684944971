import React from "react";
import Button from "../button-messages";

import "./style.css";

export function ImageBkg(props) {
  const cls = {
    cnt: "image-cnt",
    x133: "image-cnt x133",
    vrt: "vertical",
    hrz: "horizontal"
  };
  const c = props.content;
  return (
    <div
      className={props.x133 ? cls.x133 : cls.cnt}
      style={{ backgroundImage: `url(${c.image})` }}
    >
      <img
        src={c.image}
        alt={c.alt}
        style={{ ...c.imageStyle }}
        className={c.vertical ? cls.vrt : cls.hrz}
      />
    </div>
  );
}

export function Tile(props) {
  const c = props.content;

  return (
    <div className="tile-cnt">
      <ImageBkg content={c.image} x133={props.x133} />
      <h4>{c.title}</h4>
      <div className="p">{c.text}</div>
      {c.button && (
        <Button
          linkType="component"
          componentLink={
            <div
              className="button-white-in-black"
              style={{ margin: "1rem auto" }}
            >
              {c.buttonText || "записаться"}
            </div>
          }
        />
      )}
    </div>
  );
}

export function Tiles(props) {
  const c = props.content;

  return (
    <div className={props.x3 ? "grid3x" : "grid4x"}>
      {c.map((el, key) => (
        <Tile content={el} key={"tiles-" + key} x133={props.x133} />
      ))}
    </div>
  );
}
