import React from "react";
import Banner from "./banner";

export default function Component() {
  const text = "";
  const slide = "";

  return (
    <Banner
      head={<span>AI Бот Валерия</span>}
      bkgImage="url(/img/imagemaker-stylist/chat-bot-ill.jpg)"
      bkgHead="rgba(220,220,200,1)"
      content={text}
      slide={slide}
      outLink="https://t.me/imagemakerpro_bot"
    />
  );
}
