import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { MiniForm } from "../../components/forms";
import Video from "../../components/video";
import {
  HeadBlock,
  Programma,
  Text3Block,
  ShessBlock,
  BuilderLayout,
  TitleH2,
  TextAndTags,
  ScrollingLink
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Азбука инвестиций</title>
        <meta name="keywords" content={c.meta.keywords} />
        <meta name="descript" content={c.meta.descript} />
      </Helmet>
      <Page lable="Азбука инвестиций">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                left: <Video link={c.screen2.video} />,
                right: c.screen2.text
              }}
              mediaLeft
              mobileMediaUp
              vAlign="center"
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: false
              }}
            />,
            <>
              <TitleH2 content={c.teachers.head} scroll="teachers" />
              <ShessBlock
                content={{
                  left: c.teachers.text,
                  right: c.teachers.image
                }}
              />
            </>,
            <ScrollingLink scrollLink="price" />,
            <TextAndTags
              content={{
                text: c.price,
                tags: false
              }}
            />,
            <>
              <TitleH2 content={c.miniFormHead} scroll="singup" />
              <MiniForm formName="miniforma-image-communication" />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
