import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { Slides } from "../about/photosessions";
import Video from "../../components/video";
import {
  bogatyriova,
  opalina,
  practicsExemple,
  rozhnova,
  sotnikova,
  troepolskaya,
  malvie,
  karimova
  // podlesnyh
} from "./content";
import {
  BuilderLayout,
  TitleH1,
  ShessBlock
} from "../../patterns/pattern-fs/components";

export default function Backstages(props) {
  return (
    <>
      <Helmet>
        <title>Бэкстейджи и фотосесии</title>
        <meta name="keywords" content="ВШСДТ: бэкстейджи и фотосессии" />
      </Helmet>
      <Page lable="Фотосессии">
        <BuilderLayout
          contents={[
            <TitleH1
              content={"Бэкстейджи и фотосессии"}
              style={{ textAlign: "center" }}
            />,

            // <ShessBlock
            //   content={{
            //     left: podlesnyh.cover,
            //     right: podlesnyh.descript
            //   }}
            //   mediaLeft={true}
            //   mobileMediaUp={true}
            //   vAlign="flex-start"
            //   allWhite={true}
            // />,
            <ShessBlock
              content={{
                left: malvie.cover,
                right: malvie.descript
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
              allWhite={true}
            />,
            <ShessBlock
              content={{
                left: karimova.cover,
                right: karimova.descript
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="flex-start"
              allWhite={true}
            />,
            <Slides images={rozhnova.slides} descript={rozhnova.descript} />,
            <Slides images={sotnikova.slides} descript={sotnikova.descript} />,
            <Slides
              images={troepolskaya.slides}
              descript={troepolskaya.descript}
            />,
            <Slides
              images={practicsExemple.slides}
              descript={practicsExemple.descript}
            />,
            <ShessBlock
              content={{
                right: bogatyriova.descript,
                left: <Video link={bogatyriova.media} />
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
            />,
            <ShessBlock
              content={{
                right: opalina.descript,
                left: <Video link={opalina.media} />
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
            />
          ]}
        />
      </Page>
    </>
  );
}
