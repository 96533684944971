import React from "react";
import List from "../../components/list";
import { dates, Month } from "../shedule/dates";
import Video from "../../components/video";
import Ig from "../../components/instagram";

export const contents = {
  titlePage: "Fashion практика для стилистов / имидмейкеров от ВШСДТ",
  head: {
    headDescript: "Профессиональное образование",
    head: "Fashion практика для студентов курсов имиджмейкеров, стилистов",
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Практика №1 для модулей / программ «Имидж-консалтинг и fashion-styling»,
        «Стили в одежде, принципы подбора»
      </div>
    ),
    descript: [
      "Очная практика",
      "Сертификат",
      "5 дней, 40 академических часов",
      "Аудиторная практика, практика шопинга",
      "Консультации с наставниками"
    ],
    contactInside: {
      phone: "+7 (916) 058 00 92",
      phoneLink: "tel:+79160580092",
      name: "Евгения",
      tg: "tg://resolve?domain=evgeniya_pimenova",
      whatsapp: "whatsapp://send?phone=+79160580092"
    },
    text: "",
    styleImage: {
      maxHeight: 334
    },
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    noVideo: true
  },
  boldText: {
    boldText: <>Очная практика № 1</>,
    text: "будет проходить"
  },
  dates: {
    ofline: {
      type: "с",
      day: dates.practicImageConsulting1.start.getDate(),
      month: Month(dates.practicImageConsulting1.start.getMonth())
    },
    online: {
      type: "по",
      day: dates.practicImageConsulting1.finish.getDate(),
      month: Month(dates.practicImageConsulting1.finish.getMonth())
    }
  },
  programma: {
    head: "Программа fashion-практик",
    content: [
      <List
        style={{
          fontFamily: "PT Sans Narrow"
        }}
        classLi="list-head-constructor"
        num="none"
        arr={[
          <>
            День 1 – Колористика.
            <br />
            <List
              arr={[
                <>Выбор индивидуальной цветовой палитры. Практика шопинга.</>,
                <>Преподаватель: Татьяна Фомина</>
              ]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 2 – Украшения.
            <br />
            <List
              arr={[<>Преподаватель: Марина Бонецкая</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 3 – Мужской стиль.
            <br />
            <List
              arr={[<>Преподаватель: Руслан Мигранов</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 4 – Профайлинг.
            <br />
            <List
              arr={[<>Преподаватель: Лиана Бахова</>]}
              num="none"
              classLi="list-constructor"
            />
          </>,
          <>
            День 5 – Основы композиции.
            <br />
            <List
              arr={[<>Практика шопинга. Преподаватель: Татьяна Фомина</>]}
              num="none"
              classLi="list-constructor"
            />
          </>
        ]}
      />
    ]
  },
  variants: false,
  afterEducation: (
    <>
      <p className="p-constructor" style={{ textAlign: "center" }}>
        Подписывайтесь на Youtube канал ВШСДТ ТВ:
        <br />
        интервью с преподавателями, экспертами, выпускниками, открытые лекции,
        обзоры трендов
      </p>
      <p className="p-constructor" style={{ textAlign: "center" }}>
        <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
          Перейти на Youtube канал &gt;&gt;&gt;
        </a>
      </p>
    </>
  ),
  videoHead: "Fashion практики",
  videoLocal: {
    head: false,
    videos: [
      {
        video: "/media/practics/tree-1.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      },
      {
        video: "/media/practics/tree-2.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      },
      {
        video: "/media/practics/tree-3.mov",
        type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"'
      }
    ]
  },
  perspectives: {
    text: (
      <>
        <div className="block-left-space">
          <p className="p-constructor">
            Смотрите вебинар о перспективах раз­вития в профес­сии стилиста,
            имидж­мейкера: специализации в профессии, успеш­ные кейсы и истории.
            Оцените, какие ниши и сег­менты можно занять в индус­трии моды.
            Убедитесь, что это по-настоящему по­тряса­ющая и творческая работа.
          </p>
          <p className="p-constructor">
            Спикер — Елена Прохорович <Ig a="elenacult_com" />
          </p>
        </div>
      </>
    ),
    media: <Video link="AGcaa7kGFLE" />,
    side: true
  },
  examplesVideo: [
    {
      video: "/media/practics/01.mov",
      type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Fashion фотосессия для международного журнала. Эдиториал-съемка.
        </strong>,
        <>
          Org{" "}
          <a href="https://instagram.com/evmaltseva" target="_blank">
            @evmaltseva
          </a>{" "}
          <a href="https://instagram.com/styleschool_msk" target="_blank">
            @styleschool_msk
          </a>
        </>,
        <>
          Style{" "}
          <a href="https://instagram.com/zhannarozhnova" target="_blank">
            @zhannarozhnova
          </a>
        </>,
        <>
          Hair{" "}
          <a href="https://instagram.com/instadavid_" target="_blank">
            @instadavid_
          </a>
        </>,
        <>
          Photo{" "}
          <a href="https://instagram.com/solaris_foto" target="_blank">
            @solaris_foto
          </a>
        </>,
        <>
          Backst{" "}
          <a href="https://instagram.com/elenacult_com" target="_blank">
            @elenacult_com
          </a>
        </>
      ]
    },
    {
      video: "/media/practics/02.mov",
      type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Фотосъемка визуального контента для Личного бренда артиста.
          Контент-съемка.
        </strong>,
        <>
          Style <Ig a="inga.smirnova" />
        </>,
        <>
          Ass. Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Photo <Ig a="vasilisavahromova" />
        </>,
        <>
          Backstage <Ig a="elenacult_com" />
        </>
      ]
    },
    {
      video: "/media/practics/03.mov",
      type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Workshop Концептуальная фотосъемка. От идеи до реализации.
        </strong>,
        <>
          Org <Ig a="styleschool_msk" />
        </>,
        <>
          Ph: <Ig a="yushkova_photo" />
        </>,
        <>
          Style: <Ig a="troepolskayalstyle" />
        </>,
        <>
          Muah: <Ig a="serbinamua" />
        </>,
        <>
          Mua: <Ig a="yanches.mua" />
        </>,
        <>
          Hair: <Ig a="danilina_muah" />
        </>,
        <>
          Model: <Ig a="defleppardd" />
        </>,
        <>
          Model: <Ig a="ironautumn" />
        </>,
        <>
          Model: <Ig a="eyescrow" />
        </>,
        <>
          Model: <Ig a="xadriah" />
        </>
      ]
    },
    {
      video: "/media/practics/04.mov",
      type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>Практик в шоуруме. Знакомство с ассортиментом.</strong>
      ]
    },
    {
      video: "/media/practics/05.mp4",
      type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>Съемка видео «Переодевание» для ТикТок и Инстаграм.</strong>,
        <>
          Org & Concept <Ig a="marie_wnd" /> <Ig a="elenacult_com" />
        </>,
        <>
          Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Photo <Ig a="koronasti" />
        </>,
        <>
          Content <Ig a="marie_wnd" />
        </>
      ]
    },
    {
      video: "/media/practics/06.mov",
      type: 'video/mov; codecs="avc1.42E01E, mp4a.40.2"',
      description: [
        <strong>
          Fashion фотосессия для международного журнала. Эдиториал-съемка.
        </strong>,
        <>
          Org <Ig a="evmaltseva" /> <Ig a="styleschool_msk" />
        </>,
        <>
          Style <Ig a="zhannarozhnova" />
        </>,
        <>
          Hair <Ig a="instadavid_" />
        </>,
        <>
          Photo <Ig a="solaris_foto" />
        </>,
        <>
          Backst <Ig a="elenacult_com" />
        </>
      ]
    }
  ]
};
