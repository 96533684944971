import React from "react";
import Paragrapgh from "../../components/p-array";

export default function Anons() {
  return (
    <div>
      <h2
        style={{
          font: "500 1.5em 'Lora'",
          textAlign: "left",
          color: "rgb(80,80,80)",
          margin: 0,
          padding: 12,
          paddingLeft: 0
        }}
      >
        О профессии и программе:
      </h2>
      <Paragrapgh
        j
        content={[
          <span>
            Курс &laquo;Профессиональный make-up: стилистика и технологии&raquo;
            – это тот редкий вариант профессионального обучения, который
            предлагает освоить профессию визажиста в ее целостности: все самые
            современные технологии и make-up-тренды с учетом исторического
            развития и образного подхода. Признанные и опытные профессионалы
            научат самому главному: современной профессиональной терминологии,
            выбору необходимых инструментов из множества, умению создавать
            модные или стилизованные образы, основываясь на природных данных и
            точных технологических приемах.
          </span>,
          <span>
            Ценным дополнением станут знания о том, как найти свое место на
            рынке услуг, как подтверждать свою профессиональную принадлежность.
          </span>
        ]}
      />
    </div>
  );
}
