import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { contents as c } from "./content";
import Page from "../../components/page";
import { Grid } from "@material-ui/core";
import { PriceNoQR as Price } from "../../components/price-with-action-test/price";
import Banners from "./banners";
import { MiniForm } from "../../components/forms";
import {
  BuilderLayout,
  Programma,
  Teachers,
  HowIsTheTrainingGoing,
  Text3Block,
  ShessBlock,
  TitleH2,
  TextAndTags,
  HeadBlock,
  SpaceElement
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Консультант по стилю — быстрый старт</title>
        <meta
          name="keywords"
          content="стиль, тренд, обучение, школа, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Консультант по имиджу и стилю — курсы с &quot;нуля&quot;, емко и актуально, 100% результата в индивидуальном стиле. Можно учиться в любом возрасте, в любом городе, для себя или для карьеры дипломированного стилиста."
        />
      </Helmet>
      <Page lable="Консультант по стилю">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.headNew} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content="Доступная мода" />
              <ShessBlock
                content={{
                  left: c.screen2.video,
                  right: c.screen2.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content="Расписание наборов 2022 год" />
              <TextAndTags content={{ text: c.schedule, tags: false }} />
            </>,
            <>
              <TitleH2
                content="Записаться на пробные занятия"
                style={{ paddingTop: 0 }}
              />
              <MiniForm formName="miniform-style-your-life" />
            </>,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            <>
              <TitleH2 content="Как проходит обучение" />
              <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            </>,
            <>
              <TitleH2 content="Диплом после обучения" scroll="diplom" />
              <ShessBlock
                content={{
                  left: c.rgsu.text,
                  right: c.rgsu.image
                }}
                vAlign="center"
              />
            </>,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,
            <>
              <TitleH2 content="Профессиональное применение" />
              <Text3Block content={c.text3blockWImgs} />
            </>,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            // <>
            //   <TitleH2 content="Варианты обучения, стоимость" scroll="price" />
            //   <Papers content={c.pay} />
            // </>,
            <>
              <TitleH2 content="Стоимость обучения" scroll="price" />
              <Price content={c.price} />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} />
              <MiniForm formName="miniforma-style-your-life" />
            </>,
            <>
              <TitleH2 content="Открытое образование" scroll="freeeducation" />
              <ShessBlock
                content={{
                  left: c.bot.image,
                  right: c.bot.text
                }}
                mediaLeft={true}
              />
              <div style={{ height: "4rem" }} />
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <div className="box-news">{c.text1}</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  {c.text1Media}
                </Grid>
              </Grid>
              <Link to="/openedu">
                <div className="button">
                  Смотреть все курсы открытого образования
                </div>
              </Link>
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
              <SpaceElement />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
