import React from "react";
import Grid from "@material-ui/core/Grid";

import H2 from "../../components/head1";

export default function About() {
  var style = {
    padding: 0
  };
  return (
    <Grid container justify="center" style={{ padding: 20 }}>
      <Grid item xs={12} md={12}>
        <H2 content="Как проходит обучение" />
        <div
          style={{
            fontSize: "1em",
            fontWeight: 400,
            color: "rgb(100,100,100)",
            textAlign: "left",
            margin: 0,
            padding: 2
          }}
        >
          <p style={style}>
            Первые четыре занятия &mdash; теоретическая база: собираем
            косметичку &mdash; кисти и инструменты для макияжа, изучаем основу
            макияжа &mdash; база, тон, консилер, корректор, пудры, контуринг,
            хайлайтер, шейдинг, стробинг, сэндинг, работаем над коррекцией
            бровей, с глазами без теней, нюд-макияж.
          </p>
          <p style={style}>
            Теоретические занятия чередуются с практическими, на которых
            объясняется принцип и шаги выполнения каждого вида макияжа.
            Полученные навыки закрепляют показательные уроки.
          </p>
          <p style={style}>
            Параллельно с изучением техник макияжа, изучаем основы гармонии
            цветов и их сочетаний, основы стилистики образа, нюансы работы
            визажиста на фотопроектах.
          </p>
          <p style={style}>
            В конце каждого занятия дается домашнее задание, которое
            преподаватель разбирает на следующем онлайн занятии и отвечает на
            вопросы слушателей.
          </p>
          <p style={style}>
            В заключение курса подводим итоги, готовим выпускную работу. По
            итогам обучения на курсе и защиты выпускной работы выдается
            Сертификат Высшей школы стилистики.
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
