import React from "react";
import List from "../../components/list";
// import Video from "../../components/video";
// import Paragraph from "../../components/p-array";
// import Ig from "../../components/instagram";
import "../imagemaker-stylist/style.css";
import Link from "react-router-dom/Link";

export const contents = {
  head: {
    left: (
      <div className="imagemaker-stylist-header">
        <h3 style={{ paddingTop: 0 }}>
          Ассоциация выпускников Высшей школы стилистики, дизайна и технологии.
        </h3>
        <p>
          Цель Ассоциации:
          <br />
          &emsp;
        </p>
        <List
          arr={[
            <>
              Популяри&shy;за&shy;ция про&shy;фес&shy;сии
              стилист-имидж&shy;мейкер.
            </>,
            <>
              Обмен опытом и лучшими прак&shy;тика&shy;ми в ра&shy;бо&shy;те
              прак&shy;тику&shy;ющих сти&shy;лис&shy;тов.
            </>,
            <>
              Поддержка стилистов, начи&shy;на&shy;ющих свой
              про&shy;фес&shy;сиональ&shy;ный путь.
            </>,
            <>
              Обсуждение важных воп&shy;росов для рабо&shy;ты
              прак&shy;тику&shy;ющих сти&shy;листов.
            </>,
            <>Взаимодействие с брендами, бутиками, дизайнерами.</>,
            <>Помощь в продвижении в социальных сетях.</>,
            <>
              Организация бизнеса стилиста, содействие моне&shy;тиза&shy;ции
              работы и ведения экс&shy;перт&shy;ного блога.
            </>
          ]}
          classLi="p-constructor"
        />
      </div>
    ),
    right: (
      <div className="imagemaker-stylist-head-logo">
        <img src="/img/root-test2/emblema-3d-5.png" alt="ВШСДТ. Ассоциация" />
        <h3>ВШСДТ. Ассоциация</h3>
      </div>
    )
  },
  contacts: [
    {
      phone: "+7 (910) 721 32 91",
      phoneLink: "+79107213291",
      name: "Евгения",
      tg: "androsenko_style",
      wa: "79107213291"
    }
  ],
  news: [
    {
      date: new Date(2021, 7, 15),
      title: "Анна Гилёва",
      text: (
        <p className="p-constructor">
          Куратор ассоциации: Гилёва Анна. Практикующий стилист-имиджмейкер.
          Выпускница, преподаватель Высшей школы стилистики, дизайна и
          технологии. Юрист, преподаватель с 9-летним опытом, методолог
          образовательных программ.
        </p>
      ),
      image: "/img/stylist-association/17082021.jpg"
    }
  ],
  jobs: (
    <>
      <p>
        Нашим выпускникам мы даем рекомендации при трудоустройстве. Пишите в чат
        ассоциации с запросом на рекомендацию.
      </p>
      <p>
        <strong>16.09.21</strong> Портал Femmie.ru ищет редактора в рубрику о
        моде и бьюти. <Link to="/jobopportunity">Подробнее</Link>
      </p>
    </>
  ),
  schedulePractics: [
    {
      title: "18-19 сентября. Модельный тренинг ВДНХ.ТЕХНОГРАД",
      list: [
        <>
          <strong>Доступно:</strong> посещение мастер-класса, количество
          участников ограничено
        </>,
        <>
          <strong>Организатор:</strong> European Model Academy
        </>
      ]
    },
    {
      title: "24 сентября. Подводная съёмка (взрослые)",
      list: [
        <>
          <strong>Требуется:</strong> ассистенты стилиста, количество участников
          ограничено
        </>,
        <>
          <strong>Куратор:</strong> Евгения Мальцева
        </>,
        <>
          <strong>Фотограф:</strong> Евгений Стулов
        </>
      ]
    },
    {
      title: "25-26 сентября. Модельный тренинг ВДНХ.ТЕХНОГРАД",
      list: [
        <>
          <strong>Доступно:</strong> посещение мастер-класса, количество
          участников ограничено
        </>,
        <>
          <strong>Организатор:</strong> European Model Academy
        </>
      ]
    },
    {
      title: "14 ноября воскресенье Moscow Fashion Week, 3 показа",
      list: [
        <>
          <strong>Требуется:</strong> ассистенты стилиста, количество участников
          ограничено
        </>,
        <>
          <strong>Куратор:</strong> Евгения Мальцева
        </>
      ]
    }
  ]
};
