import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Баталова Татьяна Игоревна",
        family: "Баталова",
        metaKeywords: "",
        metaDescript: "",
        head: "Баталова Татьяна Игоревна",
        image: "/img/graduates/batalova-01.jpg",
        text: {
          edu: [
            <>
              Окончила АНО ДПО «Высшая школа стилистики, дизайна и технологий».
            </>,
            <>Окончила Московский институт психоанализа (годовой курс)</>
          ],
          exp: [
            "Помогаю найти свой стиль.",
            "Решаю имиджевые задачи.",
            "Составляю стилевой диагноз.",
            "Работаю с психологией образа (МАК)."
          ],
          motto: "Меняй жизнь через стиль!"
        }
      }}
    />
  );
}
