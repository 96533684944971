import React from "react";
import Helmet from "react-helmet";
import { contents } from "./contents";
import Page from "../../components/page";
import Layout from "../../patterns/pattern-fs/layout";

const Fashion = props => {
  return (
    <>
      <Helmet>
        <title>{contents.head.head}</title>
        <meta name="keywords" content={"ВШСДТ: " + contents.head.head} />
      </Helmet>
      <Page lable="Деловой костюм" lableMobile="Деловой костюм">
        <Layout content={contents} />
      </Page>
    </>
  );
};

export default Fashion;
