import React from "react";
import Card from "../card";

export default function ModernCard(props) {
  return (
    <Card
      head="Мода и стиль"
      title="Современные методы цветового анализа внешности"
      teacher="Татьяна Фомина"
      descript={<span>Онлайн-лекция,</span>}
      onlineText="Онлайн-лекция"
      date="25 апреля"
      time={<span>19:00</span>}
      duration="45 минут"
      image="/img/modern-fashion-style/schema.jpg"
      text={
        <span>
          Цвет является очень важным инструментом в создании образа. Не
          единственным,но очень важным.Но что же все-таки важнее при выборе
          цветовой палитры образа? Природный колорит человека или его характер
          ?Необходимое впечатление или модные тенденции??? По этому поводу споры
          не прекращаются до сих пор.
          <br />
          Чем характеризуется природный колорит внешности? Как его определить и
          как использовать в своем образе? Существуют разные методики
          определения так называемых «цветотипов»: сезонный, тоновый и другие.
          <br />
          Один из самых точных и прогрессивных &mdash; Дирекционный. Метод очень
          эффективный и логичный. Выявляющий цветовую доминанту внешности.
          Начинаем цикл вебинаров Татьяны Фоминой на эту тему.
          <br />И конечно все примеры из актуальных коллекций сезона FW19/20
        </span>
      }
      part
      // partPrice="600"
      view
      id="hh5ArpHC3kyM8YMiQ"
      setBillingId={props.setBillingId}
    />
  );
}
