import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import { withStyles } from "@material-ui/styles";
import { styles } from "./styles";

const wStyles = theme => styles;

function Programma(props) {
  const { classes } = props;

  return (
    <div className={classes.box}>
      <h2 className={classes.h2}>Программа обучения</h2>
      <h3 className={classes.descriptionTop}>
        Длительность 6 месяцев, 60 занятий, 254 академических часа
      </h3>
      <Paragraph
        style={{ ...styles.p, paddingBottom: "0.5rem" }}
        content={[
          <span>
            Студенты изучают профессию fashion-стилиста, получают навыки
            fashion-съемки, учатся анализировать тренды, реализовывать
            концепции, занимаются поиском референтов и созданием moodboards, а
            так же развивают навыки креативности, изучают основы модной
            журналистики. Студенты получают представления как о теории
            современной моды, так и приобретают практические навыки работы в
            области fashion стилистики.
          </span>,
          <span>Программа интересна тем, что позволяет:</span>,
          <List
            style={styles.p}
            arr={[
              "получить новый профессиональный уровень",
              "развить творческие способности и таланты",
              "расширить свои компетенции и спектр услуг"
            ]}
          />,
          <span>В процессе обучения Вы изучаете темы и направления:</span>,
          <List
            style={styles.p}
            num="none"
            arr={[
              "Мода XX века",
              "Анализ тенденций мировой моды",
              "Анализ фото съемок мировой моды",
              "Сегменты fashion-рынка",
              "Понятие и анализ ДНК брендов",
              "Композиция для стилистов",
              "Эвристика и кастомайзинг",
              "Методики ассоциаций",
              "Референсы и мудборды",
              "Арт дирекшн, работа с командой",
              "Проекты на условиях TFP",
              "Теория и практика fashion съемок",
              "Специфика проектов для рекламы",
              "Специфика проектов с глянцем",
              "Специфика проектов для подиума",
              "Специфика проектов для fashion retail",
              "Авторское право",
              "Формирование портфолио",
              "Практика создания текстов",
              "Продвижение бренда стилиста"
            ]}
          />,
          "В процессе обучения в программу включены:",
          <List
            style={styles.p}
            arr={[
              "Мастер-классы с приглашенными экспертами",
              "Посещение показов и недель моды",
              "Знакомство с будущими партнерами и единомышленниками",
              "Дружеская атмосфера"
            ]}
          />,
          <span>
            <b>Итоговая аттестация</b>
            : защита дипломной работы
          </span>
        ]}
      />
    </div>
  );
}

export default withStyles(wStyles)(Programma);
