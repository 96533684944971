import React from "react";
import Grid from "@material-ui/core/Grid";
import Modern from "../../components/banner-modern-moda-style2";
import Imagemaker from "../../components/banner-imagemaker";
import Interior from "../../components/banner-interior-prof";

export default function Banners(props) {
  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12} md={4}>
        <Modern />
      </Grid>
      <Grid item xs={12} md={4}>
        <Imagemaker />
      </Grid>
      <Grid item xs={12} md={4}>
        <Interior />
      </Grid>
    </Grid>
  );
}
