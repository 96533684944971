import React from "react";
import { Grid } from "@material-ui/core";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Title from "./title-meta";
import Header from "./header-links";

const name = "Екатерина Ваньшина";

const styles = {
  h2: {
    color: "rgb(123,143,87)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "left",
    margin: 0,
    padding: "12px 0"
  },
  text: {
    color: "rgb(100,100,100)",
    textAlign: "justify",
    margin: 0,
    padding: "12px 0"
  }
};

export default function Nazimova() {
  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/vanjshina1.jpeg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              vk="https://www.vk.com/vanushafashion"
              instagram="https://instagram.com/vanshinastyle"
              blogger="https://ekaterinavanshina.tumblr.com/"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={11}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <h2 style={styles.h2}>С детства любила одеваться изысканно</h2>
              <p style={styles.text}>
                У меня всегда было много хороших игрушек, которые родители
                привозили из Германии, но наибольшим моим интересом пользовались
                обычные бумажные куклы, которых я могла переодевать бесконечно.
                Я не могу сказать, что с детства мечтала стать стилистом или
                имиджмейкером, но я с детства любила одеваться изысканно. Из
                Лондона, куда мама попала с рабочей командировкой, мне были
                привезены бархатные перчатки и шляпка-котелок. Этот комплект
                стал моим любимым! Многие сверстники подшучивали надо мной,
                говорили, что я выгляжу не модно и не современно, но мне было
                всё равно. В шляпке и перчатках я чувствовала себя леди.
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 style={styles.h2}>Знакомство с fashion-индустрией</h2>
              <p style={styles.text}>
                Так сложилось, что после школы я пошла учиться на
                информатика-экономиста. Я выбрала то, что получалось у меня хуже
                всего. Все предметы, связанные с математикой, информатикой и в
                принципе с точными науками вызывали у меня большие трудности.
                Параллельно обучению в ВУЗе, я поступила в школу моделей
                Вячеслава Зайцева, это и стало моей отдушиной! Тогда я впервые
                соприкоснулась с fashion-индустрией и это произвело на меня
                неизгладимое впечатление. Тогда я поняла, какой сфере
                деятельности я готова посвятить себя, но еще не знала в чем
                именно смогу себя реализовать.
              </p>
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Вдохновение на изучение стилистики</h2>
              <p style={styles.text}>
                Сразу после окончания института я устроилась в крупную
                ИТ-компанию, несмотря на то, что работа в этой сфере не была мне
                интересна. Это решение было связано с моим желанием жить
                самостоятельно. Так как я продолжала сотрудничать с агентством
                моделей Вячеслава Зайцева, мне поступило предложение
                поучаствовать в передаче «Модный приговор» в рубрике «модные
                советы». На съемках этой передачи я увидела работу настоящих
                профессионалов своего дела, я увидела результат их грандиозной
                работы по преображению героини проекта и это меня вдохновило на
                изучение стилистики в целом.
              </p>
              <div
                style={{
                  background: "rgb(213,209,201)",
                  padding: 12,
                  fontSize: "1.1em"
                }}
              >
                Из множества курсов по стилистике в Москве в Высшей школе
                оказался самый развернутый курс обучения, что и послужило
                определяющим фактором в выборе места моего обучения. С первой же
                лекции я поняла, что сделала правильный выбор.
              </div>
              <p style={styles.text}>
                Наталья Александровна Горских расширила рамки моего сознания, я
                поняла, что очень многому могу у нее научиться. В семье все
                поддержали мое решение обучаться стилистике, я получила от
                родных огромную поддержку! С первых же дней обучения я стала
                применять полученные знания на себе. На улице я стала обращать
                внимание на прохожих, определять их цветотип, тип фигуры,
                оценивать целостность образа. Со временем мне стали поступать
                предложения от друзей и знакомых пройтись с ними по магазинам,
                помочь составить законченный образ для того или иного
                мероприятия. Во время этих походов я отдыхала душой. Наконец-то
                я делала то, что мне нравится!
              </p>
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/vanjshina2.jpg"
                alt={name}
                style={{ maxWidth: "100%", position: "sticky", top: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/vanjshina3.jpg"
                alt={name}
                style={{ maxWidth: "100%", position: "sticky", top: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Русский стиль стал мне ближе</h2>
              <p style={styles.text}>
                За время обучения в Высшей школе стилистики я заново открыла для
                себя русский стиль. Я переосмыслила ценность русской культуры в
                современной моде, я стала интересоваться историей русского
                искусства. Мне бы хотелось донести эти знания и до других,
                показать изящество смешения русского стиля и современных
                тенденций. Павлопосадские платки, кокошники и многие другие
                элементы русского стиля могут быть отличным дополнением
                современного образа.
              </p>
              <h2 style={styles.h2}>Знаний много не бывает!</h2>
              <p style={styles.text}>
                Сейчас я сотрудничаю с модным интернет изданием, в котором веду
                свою экспертную колонку в рубрике fashion, помимо этого работаю
                с частными клиентами и магазинами, веду интернет-блог, который
                конечно же посвящен моде и стилю. На данный момент я понимаю,
                что у меня еще остались пробелы в знаниях по истории искусств.
                Так что свой путь обучения в Высшей школе стилистики я намерена
                продолжать.
              </p>
              <h2 style={styles.h2}>Все люди рождаются творцами</h2>
              <p style={styles.text}>
                В каждом человеке природой заложено творческое начало, но
                немногие реализуют этот потенциал. Очень часто в детском
                возрасте желание творить не находит поддержки в глазах взрослых
                или, еще хуже, подвергается критике, что может привести к утрате
                этого чувства.
              </p>
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 style={styles.h2}>Совет</h2>
              <div
                style={{
                  background: "rgb(213,209,201)",
                  padding: 12,
                  fontSize: "1.1em"
                }}
              >
                Мой совет всем тем, кто еще не потерял тяги к прекрасному —
                воплощайте свои мечты, несмотря ни на что! Ведь в каждом из Вас
                живет творец! В каждом есть потенциал, который можно
                реализовать. Для этого всего лишь нужно получить немного
                стартовых знаний и поверить в свои силы!
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
