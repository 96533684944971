import React from "react";
import Footer from "../../components/footer";
import Grid from "@material-ui/core/Grid";
import Teacher from "../../components/teacher";
import Top from "../../components/topmenu";
import List from "../../components/list";
import Helmet from "react-helmet";

export default function Teach() {
  var content = [
    {
      title: "Образование",
      text: "Казанский государственный университет, исторический факультет"
    },
    {
      title: "Дополнительное образование (курсы)",
      text: (
        <List
          arr={[
            "Особенности работы с прессой (МГУ, факультет государственного управления, г. Москва, 2012)",
            "Информационные технологии в PR (МГУ, факультет государственного управления, г. Москва, 2011)",
            "Реклама и связи с общественностью (АПСО, сертификат о переподготовке и повышении квалификации, 2011)",
            "Технологии имиджмейкинга (Международный Центр ANTES CLUB, г. Москва, 2011)",
            "Методика обучения речевой коммуникации. Деловое общение (Южно-Уральский государственный университет, г. Челябинск, 2008)"
          ]}
        />
      )
    },
    {
      title: "Сотрудничество с вузами",
      text: (
        <List
          arr={[
            "РЭУ им. Г. В. Плеханова",
            "Российский университет дружбы народов",
            "Московский университет им. В. Ю. Витте"
          ]}
        />
      )
    },
    {
      title: "Учебники (в соавторстве) и методические пособия",
      text: (
        <List
          arr={[
            "Реклама и связи с общественностью (М., Изд-во Юрайт, 2013)",
            "Основы интегрированных коммуникаций (М., Изд-во Юрайт, 2014)",
            "Учебный словарь языка по связям с общественностью (М., Изд-во ДРОФА, 2010)",
            "Методические пособия по технике речи и деловым коммуникациям"
          ]}
        />
      )
    },
    {
      title: "Автор-разработчик курсов для ВШС",
      text: (
        <List
          arr={[
            "«Социальный имидж»",
            "«Корпоративный имидж»",
            "«Деловые коммуникации»",
            "«PR-технологии»",
            "«Техника постановки речи»"
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Наталия Швед: Публичный профиль</title>
        <meta name="keywords" content="Наталия Швед: Публичный профиль" />
      </Helmet>
      <Top faqultet="Преподаватели" />
      <Grid container justify="center" alignItems="flex-start">
        <Grid item style={{ padding: 20 }}>
          <Teacher
            head="Наталия Швед"
            short="PR-консультант, бизнес-тренер, кандидат исторических наук, доцент, преподаватель и автор-разработчик курсов для ВШС"
            image="/img/about/natalya-shved.jpg"
            tiles={[
              {
                image: "/img/star.svg",
                title: "Более 15 лет",
                text: "опыт преподавательской деятельности"
              },
              {
                image: "/img/star.svg",
                title: "Более 10 лет",
                text: "Практический опыт проведения тренингов и мастер-классов"
              },
              {
                image: "/img/seal.svg",
                title: "Грамота РАСО",
                text: "«За вклад в развитие PR-образования»"
              }
            ]}
            content={content}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
