import React, { useState } from "react";
import "../styles/style-image-style.css";

function BackOutline(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path
        fill={props.color}
        d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z"
      />
    </svg>
  );
}

function Back(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path
        fill={props.color}
        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
      />
    </svg>
  );
}

function NextOutline(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path
        fill={props.color}
        d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z"
      />
    </svg>
  );
}

function Next(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path
        fill={props.color}
        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
      />
    </svg>
  );
}

function Slider(props) {
  const arr = props.images;
  const len = arr.length;
  const last = len - 1;
  const [count, setCount] = useState(0);
  const [iClass, setClass] = useState(1);

  // if (props.autoplay) {
  //   let c = count;
  //   let timer = setTimeout(function autoplay() {
  //       // lister(1);
  //       c++;
  //       if (c > last) c = 0;
  //       setCount(c);
  //       setClass(1);
  //       timer = setTimeout(autoplay, 2000);
  //     }, 2000);
  // }

  const lister = n => {
    let c;
    setClass(0);
    c = count + n;
    if (c < 0) c = last;
    if (c > last) c = 0;
    setTimeout(() => {
      setCount(c);
      setClass(1);
    }, 200);
  };

  return (
    <div className="slider__box">
      <img
        src={arr[count]}
        alt={count + 1 + " of " + len}
        className={`image_in_slider ${props.imageClassName}`}
        style={{ opacity: iClass, ...props.imageStyle }}
      />
      <div className="slider__control">
        <div
          onClick={
            props.infinity
              ? () => lister(-1)
              : count
                ? () => lister(-1)
                : undefined
          }
          className="button__control"
        >
          {props.buttonOutline ? (
            <BackOutline
              color={
                props.infinity
                  ? "rgb(80,80,100)"
                  : count
                    ? "rgb(80,80,100)"
                    : "rgb(180,180,180)"
              }
            />
          ) : (
            <Back
              color={
                props.infinity
                  ? "rgb(80,80,100)"
                  : count
                    ? "rgb(80,80,100)"
                    : "rgb(180,180,180)"
              }
            />
          )}
        </div>
        <div className="informer__control">
          {count + 1} / {len}
        </div>
        <div
          onClick={
            props.infinity
              ? () => lister(1)
              : count !== last
                ? () => lister(1)
                : undefined
          }
          className="button__control"
        >
          {props.buttonOutline ? (
            <NextOutline
              color={
                props.infinity
                  ? "rgb(80,80,100)"
                  : count === last
                    ? "rgb(180,180,180)"
                    : "rgb(80,80,100)"
              }
            />
          ) : (
            <Next
              color={
                props.infinity
                  ? "rgb(80,80,100)"
                  : count === last
                    ? "rgb(180,180,180)"
                    : "rgb(80,80,100)"
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Slider;
