import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./content";
import Page from "../../components/page";
import { OneNews } from "../root-test2/news";
import { news, findNewsById } from "../news/content";
// import Layout from "../../patterns/pattern-fs/layout";
import {
  BuilderLayout,
  HeadBlock,
  ShessBlock,
  Text3Block,
  TextAndTags,
  Programma,
  Teachers,
  Price,
  SocnetBlock,
  ScrollingLink,
  TitleH2
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Школа юных стилистов — курсы для детей и подростков</title>
        <meta
          name="keywords"
          content="стиль, подростков, детей, юный, стилист, курсы, обучение, мода, имидж, где, учиться, школа"
        />
        <meta
          name="descript"
          content="Программа курса рассчитана на тех, кто только начинает знакомство с миром стиля и красоты. А также для тех, кто хочет научиться разбираться в тонкостях создания своего личного образа, подборе гардероба и формировании имиджа. Помимо специализированных знаний и навыков они будут учиться умению работать в команде, анализировать свои и чужие работы, проводить презентации и создавать портфолио, а опытные преподаватели-практики помогут найти сильные стороны, развить мотивацию, оценить способности, вдохновить на исследование окружающего мира и эксперименты."
        />
      </Helmet>
      <Page lable="Курсы для подростков">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <OneNews content={findNewsById("011021", news)} />,
            <TextAndTags content={{ text: c.text1, tags: false }} />,
            <>
              <TitleH2 content="Расписание встреч осень-зима 21" />
              <TextAndTags
                content={{
                  text: c.schedule,
                  tags: false
                }}
              />
            </>,
            // <ShessBlock
            //   content={{
            //     left: c.screen2.image,
            //     right: c.screen2.text
            //   }}
            //   mediaLeft={true}
            // />,
            <Programma
              content={{
                programma: c.programma,
                variants: false
              }}
            />,
            <Teachers content={{ teachers: c.teachers }} />,
            <>
              <ScrollingLink scrollLink="register" />
              <SocnetBlock />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
