export const dates = {
  masterClasses: {
    // Международный вебинар: 7 шагов к построению и запуску успешного Fashion бизнеса
    bFashionSprint: new Date(2021, 9, 18),
    // невербальные коммуникации в бизнесе
    nonVerbal: new Date(2020, 2, 3),
    // тренды и трендсеттеры, стиль в одежде 2020
    trands: new Date(2020, 3, 1),
    // SMM: всё, что нужно знать …
    smm: new Date(2020, 2, 2),
    // «Бедная» мода: прошедшее будущее 1 и 2 части
    poorFashion1: new Date(2020, 1, 15),
    poorFashion2: new Date(2020, 1, 18),
    // Личный бренд, путь к увеличению прибыли
    personalBrand: new Date(2020, 2, 31),
    // Психология цвета, актуальные цвета 2020
    psyhologyColor: new Date(2020, 2, 30),
    // Подводные камни ремонта
    pitfallsOfRepair: new Date(2020, 1, 12),
    // Личный бренд и продвижение в сети
    personalBrandInNet: new Date(2020, 2, 4),
    // Изменяющийся мир моды
    changingWorld: new Date(2020, 1, 15),
    // PR-атаки
    prAttacks: new Date(2020, 2, 25),
    // Профессия дизайнер интерьеров
    interiorDesigner: new Date(2020, 1, 20),
    // Как с помощью аксессуаров создать эффектный образ и разнообразить гардероб
    spectacularLook: new Date(2020, 1, 27),
    // Как предсказать психологию толпы на работу имиджмейкера
    crowdReaction: new Date(2020, 3, 2),
    srm: {
      presentation: new Date(2020, 1, 24),
      photographing: [new Date(2020, 1, 24), new Date(2020, 1, 25)],
      trands: [new Date(2020, 1, 25), new Date(2020, 1, 26)],
      accessuaries: new Date(2020, 1, 26)
    },
    // тренды в макияже 2020
    trandsInMakeup: new Date(2020, 2, 5),
    // философия японской моды
    japanesFashionPhilosophy: {
      part1: new Date(2020, 2, 3),
      part2: new Date(2020, 2, 5),
      part3: new Date(2020, 2, 10),
      part4: new Date(2020, 2, 17),
      part5: new Date(2020, 2, 25, 11, 0),
      part6: new Date(2020, 2, 27, 11, 0),
      part7: new Date(2020, 2, 31, 11, 0),
      part8: new Date(2020, 3, 2, 11, 0),
      part9: new Date(2020, 3, 7, 11, 0),
      part10: new Date(2020, 3, 9, 11, 0)
    },
    // сам себе стилист
    selfStyle: {
      part1: new Date(2020, 2, 17),
      part2: new Date(2020, 2, 22),
      part3: new Date(2020, 2, 27, 10, 0),
      part4: new Date(2020, 3, 2, 18, 0),
      part5: new Date(2020, 3, 7, 10, 0),
      part6: new Date(2020, 3, 14, 10, 0),
      part7: new Date(2020, 3, 20, 10, 0)
    },
    // кем может работать стилист
    stylisWork: new Date(2020, 2, 18),
    // трагедия и праздник Dries Van Noten SPRINT 2020
    drienVanNoten: new Date(2020, 2, 21),
    // Слабость героической женщины Маккуина. Alexander McQueen FALL 2020
    alexanderMcQueen: new Date(2020, 2, 25),
    // Мечтатель в дневное время. Schiaparelli FALL 2020
    schiaparelli: new Date(2020, 2, 28),
    // Роскошь и спорт буржуазии. Hermès FALL 2020
    hermes: new Date(2020, 3, 1),
    // интервью с Светланой Шабалиной
    intervewShabalina: new Date(2020, 2, 26, 19, 0),
    // интервью с Еленой Прохорович
    intervewProhorovich: new Date(20202, 2, 27, 15, 30),
    // Как скорректировать неудобный торговый зал
    howToAdjust: new Date(2020, 3, 18, 15, 0),
    // Мигранов Chanel
    chanel: new Date(2020, 3, 18, 13, 0),
    // Бедная мода Горских
    futureOfFashion: {
      part1: new Date(2020, 3, 17, 13, 0),
      part2: new Date(2020, 3, 20, 11, 0),
      part3: new Date(2020, 3, 21, 11, 0),
      part4: new Date(2020, 3, 22, 11, 0),
      part5: new Date(2020, 3, 23, 11, 0),
      part6: new Date(2020, 3, 24, 11, 0)
    },
    // Винтаж: современный стиль, индивидуальный подход.
    vintage: new Date(2020, 3, 27, 11, 0),
    // Секрет стиля деним
    denimStyle: new Date(2020, 3, 24, 13, 0),
    // Цвет: смыслы, символы, тренды
    colorMeanings: new Date(2020, 6, 2, 19, 30),
    // Как при помощи цвета создать свой интерьер счастья.
    interiorOfHeppines: new Date(2020, 3, 16, 11, 0),
    // Сценарий освещения – залог комфортного жилья.
    lightingScenario: new Date(2020, 3, 17, 11, 0),
    // Вебинар 7 ноября Ольги Мальцевой
    olgaMaltseva: new Date(2021, 10, 7)
  },
  // Визуальный мерчендайзинг и витринистика
  visualMerchandising: {
    online: new Date("11-22-2022"),
    ofline: false // new Date(2021, 8, 20)
  },
  visualMerchandisingTestDrive: {
    start: new Date(2021, 8, 20),
    finish: new Date(2021, 9, 3)
  },
  // Шопинг стажировка в Милане
  internshipInMilan: new Date(2020, 1, 17),
  // Дизайн и декорирование интерьеров, профподготовка
  designInterior: {
    online: new Date("11-17-2022"),
    // online2: new Date(2022, 1, 1),
    ofline: false // new Date(2021, 8, 13)
  },
  // Дизайн интерьера, интенсив | Дизайн и декорирование интерьера 3 мес.
  // Консультант по подбору интерьера
  interiorDesign: {
    online: new Date(2022, 0, 18),
    online2: new Date(2022, 0, 6),
    ofline: new Date(2021, 8, 18)
  },
  // Имиджмейкинг, репутационные технологии
  imagemakingReputation: {
    online: false,
    // online: new Date(2020, 6, 21),
    ofline: new Date(2020, 9, 12)
  },
  // Декорирование интерьеров, интенсив
  interiorDecor: {
    online: new Date(2020, 5, 22)
  },
  // SMM в индустрии моды
  smmMs: new Date(2020, 1, 15),
  // имидж-консультант
  profashion: {
    online: new Date(2020, 4, 21),
    ofline: new Date(2020, 5, 6)
  },
  // Имидж-консалтинг и Fashion-styling
  // Модный стилист, имидж-консультант
  imagemaker: {
    online: new Date("10-20-2022"),
    ofline: new Date("10-20-2022"),
    openLection: new Date(2020, 6, 8)
  },
  imagemaker2: {
    online: new Date("09-08-2022"),
    ofline: new Date(2021, 1, 2),
    openLection: new Date(2020, 3, 20)
  },
  // базовый курс по имиджу и стилю (Стили в одежде)
  baseImageStyle: {
    online: false, // new Date(2021, 10, 10),
    ofline: new Date(2021, 9, 19)
  },
  // Имидж-консалтинг (индивидуальный и деловой стиль)
  imageConsulting: {
    online: new Date(2021, 2, 10),
    ofline: new Date(2021, 2, 10)
  },
  // Сам себе стилист
  selfStyle: {
    online: false, // new Date(2021, 9, 23),
    // online2: new Date(2022, 5, 7),
    online2: new Date("06-07-2022"),
    ofline: new Date(2021, 9, 12)
  },
  // Социальные и художественные стратегии моды
  // Теория моды
  conceptsOfFashion: new Date(2020, 6, 20),
  milanDesignWeek: "июнь 2020", // new Date(2020, 3, 21)
  // fashion styling (Стилист фотосессий)
  fashionStyling: {
    online: new Date(2022, 6, 1),
    ofline: false // new Date(2022, 1, 14)
  },
  // Персональный бренд (Прохорович)
  personalBrand: {
    online: new Date(2021, 5, 10),
    ofline: new Date(2021, 7, 10)
  },
  // Фэшн франчайзинг
  fashionFranchising: {
    online: new Date(2020, 8, 14),
    // ofline: new Date(),
    ofline: false
  },
  // Главные тенденции современной моды
  trendsOfModernFashion: {
    online: new Date(2021, 3, 20),
    ofline: false
  },
  // Курс Pro motion design
  proMotionDesign: {
    online: new Date(2021, 0, 21)
  },
  // Теория и практика концептуальной моды
  conceptFashionG: {
    online: new Date(2020, 8, 17)
  },
  // Стиль и образ классического и современного искусства
  evolutionStylesG: {
    online: new Date(2020, 8, 7)
  },
  // Костюм «для дела»: правила новой публичности
  businessStyle: {
    online: new Date(2020, 7, 25)
  },
  // PR образовательных услуг
  educationPR: {
    online: new Date(2020, 8, 7)
  },
  // Школа юных стилистов
  youngFashion: {
    online: new Date(2021, 8, 25),
    online2: new Date(2021, 9, 9),
    ofline: false // new Date(2020, 8, 19)
  },
  // Стиль стилистам
  style4stylist: {
    online: new Date(2020, 11, 12),
    ofline: false // new Date(2020, 9, 10)
  },
  // Имидж как коммуникация
  imageCommunication: {
    ofline: new Date(2021, 8, 3),
    online: new Date(2021, 9, 2),
    online2: new Date(2021, 11, 2)
  },
  // психология цвета
  psychologyOfColor: {
    online: new Date(2021, 11, 2),
    ofline: false
  },
  // Консультант по стилю
  styleYourLife: {
    online: false, // new Date(2022, 0, 31),
    ofline: new Date(2022, 5, 9)
  },
  //Консультант по стилю и дизайну интерьера
  interiorYourLife: {
    online: new Date("11-17-2022"),
    ofline: false // new Date(2021, 10, 9)
  },
  // Практики имидж-консультировнаия
  practicImageConsulting: {
    one: [new Date(2021, 4, 17), new Date(2021, 4, 21)],
    two: [new Date(2021, 4, 24), new Date(2021, 4, 28)]
  },
  practicImageConsulting1: {
    start: new Date(2022, 0, 24),
    finish: new Date(2022, 0, 28)
  },
  practicImageConsulting2: {
    start: false, // new Date(2022, 0, 24),
    finish: false // new Date(2022, 0, 28)
  },
  // Бизнес аналитик
  businessAnalitic: {
    online: new Date(2021, 8, 28),
    ofline: false
  },
  // Fashion-каникулы в Тбилиси
  tbilisiFashionWeekend: {
    start: new Date(2021, 4, 12),
    finish: new Date(2021, 4, 17)
  },
  // Political style
  politicalStyle: {
    online: new Date(2021, 9, 12),
    absentia: false // new Date(2021, 9, 20)
  },
  // IDFW
  idfw: {
    online: new Date(2021, 9, 18),
    online2: new Date(2021, 8, 18)
  },
  idfwTestDrive: {
    start: new Date(2021, 8, 18),
    finish: new Date(2021, 8, 26)
  },
  // ChatBot
  chatbot: {
    online: new Date("11-15-2022"),
    ofline: new Date(2021, 9, 20),
    testDriveStart: new Date(2021, 8, 21),
    testDriveFinish: new Date(2021, 9, 5)
  },
  // Управление разработкой диалоговых систем с ИИ, уровень product owner
  // AI bot product owner
  aibotProductowner: {
    online: new Date("07-05-2022"),
    free: new Date("07-01-2022")
  },
  styleConsultantTGU: {
    online: new Date("08-22-2022"),
    online2: new Date("09-22-2022")
  },
  interiorStylistTGU: {
    online: new Date("08-24-2022"),
    online2: new Date("09-24-2022")
  },
  bFashionSprint: {
    online: new Date(2021, 9, 25)
  },
  buyer: new Date(2022, 1, 28),
  // История моды с Русланом Миграновым
  fashionHistory: {
    online: new Date(2022, 4, 24),
    ofline: new Date(2022, 3, 18)
  }
};

export function Month(month) {
  var monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря"
  ];
  if (month < 0 || month > 11) return false;
  return monthNames[month];
}

export function DateToString(date, short = false) {
  if (!date) return " ";
  if (!short) {
    return (
      date.getDate() + " " + Month(date.getMonth()) + " " + date.getFullYear()
    );
  } else {
    return date.getDate() + " " + Month(date.getMonth());
  }
}

export function MonthToXX(date) {
  let l = date.getMonth() + 1;
  if (l < 10) {
    l = "0" + l;
  }
  return l;
}

export function TimeToString(date) {
  let minutes;
  if (date.getMinutes() == "0") {
    minutes = "00";
  } else minutes = date.getMinutes();
  return date.getHours() + ":" + minutes;
}
