import React from "react";
import Page from "../../components/page";
import { Helmet } from "react-helmet";
import {
  BuilderLayout,
  TextAndTags,
  TitleH1
} from "../../patterns/pattern-fs/components";
import { content } from "./content";
import { JobList } from "../jobopportunity/jobs-list";
import { content as jobs } from "../jobopportunity/content";

function Vacancy(props) {
  return (
    <>
      <Helmet>
        <title>Вакансии партнеров и работодателей</title>
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Вакансии: индустрия моды">
        <BuilderLayout
          contents={[
            <TitleH1 content={content.head} style={{ textAlign: "center" }} />,
            <TextAndTags
              content={{
                text: (
                  <>
                    {content.jobs}
                    {content.anonses}
                  </>
                ),
                tags: false
              }}
            />,
            <JobList content={jobs} />
          ]}
        />
      </Page>
    </>
  );
}

export default Vacancy;
