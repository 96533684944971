import React from "react";
import { Grid } from "@material-ui/core";
import Event from "./event";
import List from "../../components/list";

const styles = {
  head: {
    fontSize: "1.8em",
    fontWeight: 700,
    color: "rgb(100,100,100)",
    textAlign: "center"
  },
  text: {
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "left"
  }
};

export default function EventList() {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <h2 style={styles.head}>Ближайшие мероприятия</h2>
          <Event
            date="22 мая в 18:30 по МСК"
            head={
              <span>
                Тема: Правильный подбор нижнего белья
                <br />
                Формат: встреча в московском офисе + online трансляция
                <br />
                Мастер-класс проводит: Марина Крайнева
              </span>
            }
            image="/img/club/kraineva.jpg"
            name="Марина Крайнева"
            text={
              <React.Fragment>
                <List
                  arr={[
                    "Развеем мифы о размерах",
                    <span>
                      Узнаем, что же &laquo;это&raquo; на самом деле и для чего
                      об этом нужно знать
                    </span>,
                    "Научимся правильно делать выбор",
                    "Лайфхаки. Нюансы. Особенности"
                  ]}
                  style={styles.text}
                />
                <p>
                  Дополнительно Елена Казакова проведет мастер-класс
                  &laquo;Обувь на каблуке&raquo;. Узнаем, как выбирать и как
                  носить такую обувь.
                </p>
              </React.Fragment>
            }
          />
          <h2 style={styles.head}>Прошедшие мероприятия</h2>
          <Event
            date="21 апреля в 10:00 по МСК"
            head={
              <span>
                Курс: Медиа маркетинг и продвижение услуг имидж-консультанта
                <br />
                Тема: Маркетинг услуг. Анализ рынка
                <br />
                Формат: вебинар
                <br />
                Лектор: Виктория Белякова
              </span>
            }
            image="/img/club/belyakova.jpg"
            name="Виктория Белякова"
            text={
              <span>
                В процессе занятия рассмотрим методы и инструменты анализа
                &laquo;рынка индустрии&raquo;, в том числе: анализа конкурентов,
                анализа услуг, сильных и слабых сторон существующих игроков
                рынка, рассмотрим действия, которые могут помочь вам в развитии
                Вашего бизнеса. Дополнительно: ответы на вопросы, кейсы из
                практики продвижения стилистов
              </span>
            }
          />
          <Event
            date="26 апреля в 18:30 по МСК"
            head={
              <span>
                Тема: Типы внешности по Ларсен
                <br />
                Формат: встреча в московском офисе + online трансляция
                <br />
                Мастер-класс проводит: Оксана Катаева
              </span>
            }
            text={
              <React.Fragment>
                <p>
                  Методика типирования внешности по системе Двин Ларсон. На
                  мастер-классе мы раскроем понятие &laquo;стилевой
                  типаж&raquo;, сравним различные системы типажей, чтобы
                  определить главные преимущества типологии Двин Ларсон.
                </p>
                <p>
                  С участниками мастер-класса мы поговорим о том, какие типажи
                  внешности существуют в данной системе, обсудим алгоритмы их
                  определения. Также участники узнают особенности одежды для
                  каждого стилевого типажа. В заключение мастер-класса
                  участников ждет практическая часть для закрепления материала.
                </p>
              </React.Fragment>
            }
            name="Оксана Катаева"
            image="/img/club/kataeva.jpg"
          />
          <Event
            date="30 апреля в 10:00 по МСК"
            head={
              <span>
                Курс: Медиа маркетинг и продвижение услуг имидж-консультанта
                <br />
                Тема: Анализ целевой аудитории
                <br />
                Формат: вебинар
                <br />
                Лектор: Виктория Белякова
              </span>
            }
            text={
              <span>
                Аудитория, с которой вы будете работать. Аудитория, которая
                будет покупать или покупает ваши услуги. Кто она? Что еще
                необходимо знать о ней? Зачем? На вебинаре отвечу на вопросы:
                как определить ЦА, ядро и виды ЦА, методы анализа ЦА,
                исследования потребностей ЦА и спроса, создание торгового
                предложения с ориентацией на спрос.
              </span>
            }
            image="/img/club/belyakova.jpg"
            name="Виктория Белякова"
          />
          <Event
            date="22 марта в 18:30 по МСК"
            head={
              <span>
                Тема: Психотип человека и мода
                <br />
                Формат: встреча в московском офисе
                <br />
                Лектор: Александр Аникин
              </span>
            }
            text={
              <span>
                Нам в моде привычен подход, основанный на создании цельного
                образа, отраженного а мифологии. Ценность этих архетипов в
                соответствии представлении значения образа и его места в жизни,
                а именно в важнейших событиях жизни, от которых зависит
                выживание как отдельного человека, так и рода. Эти образы, а
                вернее прообразы (архетипы), лежат в подсознании и действует на
                нас, не требуя осмысления и понимания: подсознание само их
                узнает и формирует у нас эмоциональное отношение к ним и
                стратегию поведения по отношению к ним. А, что может быть важнее
                в общении? С этого все и начинается ...
              </span>
            }
            name="Александр Аникин"
            image="/img/club/anikin.jpg"
          />
          <Event
            date="17 апреля в 19:00 по МСК"
            head={
              <span>
                Тема: Модные приложения: конкуренция или инструмент
                <br />
                Формат: вебинар доступен в записи
                <br />
                Лектор: Галина Чарная
              </span>
            }
            text={
              <span>
                Мы живем в будущем. Виртуальная и дополненная реальность для нас
                уже не фантастика. Смогут ли мобильные приложения и алгоритмы
                вскоре заменить стилистов? Как найти место в новом мире высоких
                технологий и использовать их в качестве инструментов в своей
                работе? 5 лучших мобильных приложений и программ для
                имиджмейкера. Предупреждены значит вооружены.
              </span>
            }
            name="Галина Чарная"
            image="/img/club/charnaya.jpg"
          />
        </Grid>
      </Grid>
    </div>
  );
}
