import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>Курсы стилистов, дизайнеров, имиджмейкеров в Красноярске</title>
        <meta
          name="descript"
          content="Обучение стилистов, дизайнеров, имиджмейкеров в Красноярске. Программы профессиональной переподготовки и повышения квалификации в индустрии моды и дизайна. Академическое образование и реальная очная практика."
        />
        <meta
          name="keywords"
          content="красноярск, курсы, обучение, имиджмейкер, стилист, школа, учиться, где, профессия"
        />
      </Helmet>
      <PageGlobal
        lable="Красноярск"
        filial="Красноярск"
        adress={<>г. Красноярск, ул. Качинская, д.&nbsp;64, стр.&nbsp;9</>}
        phones="+7 (391) 220 65 05"
      >
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
