import React from "react";
import Helmet from "react-helmet";
import { contents } from "./contents";
import Page from "../../components/page";
import Video from "../../components/video";
// import Layout from "../../patterns/pattern-fs/layout";
import {
  BuilderLayout,
  ShessBlock,
  Programma,
  SocnetBlock,
  Teachers,
  HeadBlock
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <>
      <Helmet>
        <title>Курс по философия японской моды</title>
        <meta
          name="keywords"
          content="философия, японская мода, курс, дизайнеры, бренды, стиль, обучение, где, учиться, школа, лекции"
        />
        <meta
          name="descript"
          content="Этот курс поможет вам разобраться, в чем уникальность японского стилеообразования. Вы научитесь находить отличие японского от европейского подхода к моде. Проследите истоки стиля современных японских дизайнеров. В цикле лекций вы узнаете об основных чертах японской философии и эстетики. А также разберете основные японские бренды. По окончании выдается удостоверение о повышенной квалификации."
        />
      </Helmet>
      <Page lable="Японская мода" lableMobile="Японская мода">
        <BuilderLayout
          contents={[
            <HeadBlock content={contents.head} />,
            <ShessBlock
              content={{
                left: <Video link={contents.screen2.video} video />,
                right: contents.screen2.text
              }}
              mediaLeft={true}
              vAlign="center"
            />,
            <Programma content={{ programma: contents.programma }} />,
            <Teachers content={{ teachers: contents.teachers }} />,
            <SocnetBlock />
          ]}
        />
      </Page>
    </>
  );
};

export default Fashion;
