import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import {
  gileva,
  moatti,
  shabalina2 as shabalina
} from "../../contents/teachers";

const online2 = `${dates.imageCommunication.online2.getDate()}  ${Month(
  dates.imageCommunication.online2.getMonth()
)}`;
/*
const online = `${dates.imageCommunication.online.getDate()}  ${Month(
  dates.imageCommunication.online.getMonth()
)}`;
const ofline = `${dates.imageCommunication.ofline.getDate()} ${Month(
  dates.imageCommunication.ofline.getMonth()
)}`;
*/

export const contents = {
  meta: {
    keywords:
      "имидж, PR, стиль, коммуникация, специализация, карьера, обучение, учиться, где, профессия, курсы",
    descript:
      "Цель обучения – определить ключевые позиции личного бренда, сформировать правильный стиль в одежде, связанный со стилем общения и поведения, создать визуальный фото контент для социальных сетей. Выстроить перспективные отношения с командой, клиентами, заказчиками, партнерами. Грамотно презентовать себя. В этом курсе Вы получите определенный набор технологий по личному PR, с целью правильного выстраивания имидж коммуникаций в среде социальных сетей и карьере"
  },
  head: {
    title: "Имидж коммуникация",
    headDescript: "Специализация",
    head: (
      <>
        Имидж-коммуникация как часть
        <br />
        личного PR и продвижения
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      "Онлайн + очная практика",
      "Сертификат",
      "1 месяц, 16 академических часов",
      "Фото сессия для социальных сетей",
      "Консультации с наставниками",
      "Рассрочка, налоговый вычет"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        В этом курсе Вы получите определенный набор технологий по личному PR, с
        целью правильного выстраивания имидж коммуникаций в среде социальных
        сетей и карьере
      </div>
    ),
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/image-communication/bkg.jpg"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online2}</>]
    },
    {
      title: "Формат",
      text: [<>Онлайн занятия, чек листы,</>, <>чат в группе, очная практика</>]
    },
    {
      title: "Спец. условия",
      text: [
        <>Специальные условия для студентов</>,
        <>и общественных организаций</>
      ]
    }
  ],
  screen2: {
    text: (
      <p className="p-constructor">
        Сегодня сложно найти вид деятельности, который будет эффективным без
        использования системы визуальных коммуникаций, важнейшей частью которых
        является презентация собственного образа. Курс поможет тем, кто это
        понимает, создать запоминающийся публичный образ, в том числе в
        интернет-пространстве, с сохранением своей индивидуальности. Итогом, как
        правило, становится подтверждение репутации, повышение лояльности и
        доверия, увеличение ценности личного бренда.
      </p>
    ),
    video: "rYKZAsSnC84"
  },
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неделю Вы изу&shy;ча&shy;ете видео лек&shy;ции. Лек&shy;ции
          от&shy;кры&shy;вают&shy;ся по мере про&shy;хож&shy;де&shy;ния
          груп&shy;пой кур&shy;са. Обу&shy;че&shy;ние идет в ра&shy;бо&shy;чем
          ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние задания.
          Это важная часть обуче&shy;ния, в резуль&shy;тате кото&shy;рой
          форми&shy;руется итого&shy;вая проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Практика",
      text: (
        <>
          Каждую неделю — об&shy;ще&shy;­ние с на&shy;став&shy;ни&shy;ка&shy;ми
          оч­но и он&shy;­лайн, под&shy;­го&shy;­тов&shy;­ка стра&shy;те&shy;гии
          лич&shy;но&shy;го брен&shy;да, кон&shy;цеп&shy;ции
          ин&shy;ди&shy;ви&shy;дуаль&shy;ного и де&shy;ло&shy;во&shy;го
          сти&shy;ля
        </>
      )
    },
    {
      title: "Чек лист + фотосессия",
      text: (
        <>
          В итоге обу&shy;че&shy;ния Вы соз&shy;да&shy;ете
          пер&shy;со&shy;наль&shy;ную пре&shy;зен&shy;та&shy;цию,
          фо&shy;то&shy;кон&shy;тент для со&shy;циаль&shy;ных се&shy;тей и план
          по&shy;зи&shy;циони&shy;ро&shy;ва&shy;ния лич&shy;ного брен&shy;да в
          биз&shy;нес со&shy;общес&shy;тве
        </>
      )
    }
  ],
  programma: {
    head: "Программа",
    content: [
      <p>
        Цель обучения – определить ключевые позиции личного бренда, сформировать
        правильный стиль в одежде, связанный со стилем общения и поведения,
        создать визуальный фото контент для социальных сетей. Выстроить
        перспективные отношения с командой, клиентами, заказчиками, партнерами.
        Грамотно презентовать себя.
      </p>,
      <p>
        <strong>Модуль 1. Концепция личного бренда</strong>
        <br />
        (4 занятия, 2 академических часа)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Образ-бренд – современный способ визуализации информации</>,
          <>Ключевые элементы и носители информации</>
        ]}
      />,
      <p>
        <strong>Модуль 2. Концепция делового стиля</strong>
        <br />
        (8 занятий, 4 академических часа)
      </p>,
      <List
        style={styles.p}
        arr={[
          <>Создание необходимого впечатления и управление им</>,
          <>Выбор вида делового стиля и его основных показателей</>
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <p>
        <strong>Модуль 3. Концепция индивидуального стиля</strong>
        <br />
        (12 занятий, 6 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Соединение делового и индивидуального стиля</>,
          <>Женский индивидуальный стиль: внешность-характер-одежда</>,
          <>Мужской индивидуальный стиль: внешность-характер-одежда</>
        ]}
        num="none"
      />,
      <p>
        <strong>Модуль 4. Концепция продвижения</strong>
        <br />
        (8 занятий, 4 академических часа)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Создание визуального контента для соцсетей</>,
          <>Основы продвижения в инстаграм</>,
          <>Подготовка резюме и личных презентаций для компаний, партнеров</>
        ]}
        num="none"
      />,
      <p>
        <strong>Дополнительно в программе:</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Очная практика шопинга (2 занятия, 4 академических часа)</>,
          <>
            Workshop — участие в фотосессии в качестве модели с итоговым фото
            контентом для соц. сетей (2 дня, 8 академических часов)
          </>
        ]}
        num="none"
      />,
      <p>
        <strong>Итого:</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            В основной программе обучения — 32 занятия, 16 академических часов
          </>,
          <>В дополнительной программе обучения – 12 академических часов</>
        ]}
        num="none"
      />,
      <p>
        <strong>Итоговая аттестация:</strong> не предусмотрена
      </p>
    ]
  },
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – очные практики и консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={
          <>Учебная нагрузка: 4 занятия (4 академических часов) в неделю</>
        }
        text={
          <>
            <p>График занятий:</p>
            <List
              olStyle={{ paddingLeft: "1rem" }}
              arr={[
                <>
                  3 занятия в неделю по 1 академическому часу — лекционный
                  материал с домашними практическими заданиями
                </>,
                <>
                  1 занятие в неделю по 1 академическому часу — семинары с
                  преподавателями, живое общение online
                </>
              ]}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Слушатели, прошедшие обучение на курсе и успешно защитившие проектную
        работу, получают сертификат по программе «Имидж-коммуникация в карьере и
        бизнесе»
      </p>
    ),
    image: (
      <img
        src="/img/sdi/diplom-2.jpg"
        alt="Диплом ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  teachers: [
    gileva,
    { ...moatti, image: "/img/teachers/320x240/moatti.jpg" },
    shabalina
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>1200 &#8381;</>,
    full: <>12 000 &#8381;</>, //  полная стоимость
    discount: false, // со скидкой
    discountSize: (
      <>
        <span style={{ fontSize: "1rem" }}>до</span> 50%
      </>
    ),
    discountDuration: "",
    start: <>20 июля</>,
    title2block: "Оплатить обучение в рассрочку:",
    descript: (
      <>
        Сотрудничаем с Банком Тинькофф. Рассрочка на 12&nbsp;месяцев без первого
        взноса доступна для граждан&nbsp;РФ.
      </>
    ),
    qr: "/img/image-communication/qr-tinkoff.gif",
    qrLink: "https://link.tinkoff.ru/77TDaLsHIgA",
    afterDescript:
      "* Для получения скидки или для единовременной оплаты курса оформите заявку на обучение ниже"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
