import React from "react";
import List from "../../components/list";
import { dates, Month } from "../shedule/dates";
import Video from "../../components/video";
import {
  shabalina2,
  migranov2,
  prohorovich2,
  gorskih2
} from "../../contents/teachers";
import { styles } from "./styles";

export const contents = {
  titlePage: "Консультант по стилю. Быстрый старт",
  headNew: {
    head: {
      type: "Профессиональное образование",
      head: (
        <>
          Консультант по стилю
          <br />
          Быстрый старт
        </>
      ),
      descript: (
        <>
          В этом курсе мы собрали самые нужные знания, чтобы вы могли учиться с
          нуля, в любом возрасте, в любом городе, получая нужный результат: для
          себя, для карьеры дипломированного стилиста, для бизнеса.
        </>
      )
    },
    text: [
      <>Онлайн обучение + очная практика</>,
      <>Диплом установленного госу­дар­ством образца</>,
      <>3.5 месяца, более 60 занятий + очная практика + защита диплома</>,
      <>Первые две недели обучения (тест-драйв) = 900 рублей</>,
      <>Рассрочка на 12 месяцев, налоговый вычет</>,
      <>Живое общение с наставником</>
    ],
    tags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    image: {
      src: "/img/style-your-life/style-your-life-q.jpg",
      alt: "Консультант по стилю.",
      style: {}
    }
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>19 мая—31 августа</>, <>Тест-драйв 19 мая—4 июня</>]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции,</>,
        <>чат в группе, очная практика</>
      ]
    },
    {
      title: "Эксперты",
      text: [
        <>Российские и зарубежные практики —</>,
        <>стилисты, fashion эксперты</>
      ]
    }
  ],
  text3blockWImgs: [
    {
      img: {
        src: "/img/style-your-life/personal32.jpg",
        alt: "Персональный стилист"
      },
      title: "Персональный стилист",
      text: [
        <>
          {" "}
          Вы можете заниматься имидж-консультированием и шоппинг-сопровождением
          из любой точки мира, очно или онлайн
        </>
      ]
    },
    {
      img: {
        src: "/img/style-your-life/clients32.jpg",
        alt: "Клиентский сервис"
      },
      title: "Клиентский сервис",
      text: [
        <>
          Вы уже работаете с клиентами — обучаете персонал, расширяйте свои
          услуги, улучшайте сервис и свое позиционирование
        </>
      ]
    },
    {
      img: {
        src: "/img/style-your-life/media32.jpg",
        alt: "Стилист в медиапространстве"
      },
      title: "Стилист в медиапространстве",
      text: [
        <>
          Работайте со стилем персон для фото и видео съемок, популярных
          программ, онлайн каналов и социальных сетей Программа курса
        </>
      ]
    }
  ],
  text: {
    text: (
      <>
        <p className="constructor-p">
          Цель курса «Консультант по стилю» — дать необходимую систему и навыки
          для создания индивидуального стиля клиентов, научить принципам
          создания имиджевого фото контента и продвижения личного бренда в
          социальных сетях.
        </p>
        <p className="constructor-p">
          Каждой теме уделяется необходимое количество времени, чтобы закрепить
          навыки и получить результат.
        </p>
        <p className="constructor-p">
          Программа обучения рассчитана на 3,5 месяца
        </p>
      </>
    ),
    tags: false
  },
  text1: (
    <>
      <h3
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Курс для Вас, если Вы:
      </h3>
      <List
        arr={[
          <>Любите стиль и моду</>,
          <>В поисках творческой самореализации</>,
          <>Уже помогаете подругам и родным преображаться</>,
          <>Хотите получать от этого процесса дополнительный доход</>,
          <>Готовы к изменениям, которые наступают уже в процессе обучения</>,
          <>Реально хотите преобразить мир и реализовать свои мечты</>
        ]}
      />
    </>
  ),
  // dates: {
  //   online: {
  //     day: dates.styleYourLife.online.getDate(),
  //     month: Month(dates.styleYourLife.online.getMonth()),
  //     type: "заочно"
  //   },
  //   ofline: false
  // },
  screen2: {
    text: (
      <List
        arr={[
          <strong>Тренд на визуализацию личного бренда</strong>,
          <>
            Тренд на визуализацию личного бренда и правильное имидж
            позиционирование стремительно захватывает все области жизни. Сегодня
            как никогда ранее тот, кто профессионально владеет нужными
            креа&shy;тив&shy;ными навыками формирования имид&shy;жа и
            продвижения, можете найти своих клиентов и реализовать свои планы в
            карьере и бизнесе.
          </>,
          <>
            Научитесь не просто подбирать одежду и аксессуары, а раскрывать
            характер и индивидуальность человека. Создавайте классические или
            креативные образы, делайте мир прекраснее, добивайтесь жизненных и
            карьерных целей. Креа&shy;тивные профессии — будущее
          </>
        ]}
        num="none"
        // style={{ paddingBottom: ".3rem" }}
        classLi="p-constructor"
        olStyle={{ paddingLeft: 0 }}
      />
    ),
    video: <Video link="fOZzI3bKXIU" />
  },
  graduates: {
    text: (
      <>
        <p>Татьяна Баталова</p>
        <p>Куратор: преподаватель ВШСДТ, Светлана Шабалина</p>
      </>
    ),
    image: (
      <img src="/img/style-your-life/batalova.jpg" alt="Татьяна Баталова" />
    )
  },
  practic: {
    text: (
      <>
        <h3 className="h3-constructor">Практика шопинга</h3>
        <p className="p-constructor">
          В курсе предусмотрено 6 практик шопинг-сопровождения. На каждом
          занятии Вы отрабатываете подбор одежды под определенную ситуацию
          (запрос клиента), бюджет.
        </p>
      </>
    ),
    image: <img src="/img/style-your-life/practic.jpg" alt="Практика шопинга" />
  },
  photosession: {
    text: (
      <>
        <h3 className="h3-constructor">Фотосессия</h3>
        <p className="p-constructor">
          В конце обучения предусмотрена очная 3-х дневная практика имиджевой
          съемки для продвижения стилиста в социальных сетях: от концепции до
          подбора одежды, съемки.
        </p>
      </>
    ),
    image: <img src="/img/style-your-life/photosession.jpg" alt="Фотосессия" />
  },
  programma: {
    head: "Программа обучения",
    content: [
      <p>
        <strong>Personal Styling. Индивидуальный стиль от А до Я</strong>
        <br />
        (24 занятия, 48 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <>
            Концепция стилевых направлений как система гармонии внутреннего и
            внешнего
          </>,
          <>
            Формирование стиля на основе индивидуальных особенностей в лице,
            фигуре, характере
          </>,
          <>
            Типологии фигур, возможности и способы визуальной коррекции фигур
          </>,
          <>Подбор индивидуальной цветовой палитры, кроя, фактур, рисунков</>,
          <>Аксессуары, правила и способы подбора с учетом fashiontrends</>,
          <>
            Типы современного гардероба, формирование рационального гардероба
          </>,
          <>Особенности работы со стилем мужчин</>,
          <>Принципы планирования и проведения шопинга</>,
          <>Практика создания Style Book (персонального, клиентского)</>,
          <>&emsp;</>,
          <>А так же</>,
          <>Принципы формирования портфолио стилиста</>,
          <>Особенности реализации fashion фото съемок – международный опыт</>
        ]}
      />,

      <p>
        <strong>Fashion trends. Главные тенденции моды ХХ и XXI века</strong>
        <br />
        (20 занятий, 40 академических часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>История моды ХХ века как источник вдохновения</>,
          <>Мода как форма современного искусства. Образный язык моды:</>,
          <>Эстетика провокации в моде. Мода и стили субкультур</>,
          <>Демократизация моды. Взаимодействие высокой и уличной моды</>,
          <>Полистилизм и стилевые прототипы</>,
          <>Приемы и цель использования искусства в создании модного образа</>,
          <>Основные долгосрочные тенденции индустрии моды:</>,
          <>Ugly-fashion и normcore как проявление индвидуальности. </>,
          <>Причины и суть постоянства этники и эко-тренда в коллекциях</>,
          <>Философия тренда разумного потребления. Slow-fashion, ресайклинг</>,
          <>Тренд естественности, простоты, универсальности форм</>,
          <>Структура индустрии и ключевые каналы коммуникации:</>,
          <>
            Корпорации: управление и финансы. Роль дизайнера. Иерархия внутри
            системы
          </>,
          <>Модные показы. Недели моды. Haute couture и prêt-a-porter.</>,
          <>Прогнозирование и важнейшие каналы распространения моды.</>,
          <>Digital-коммуникации, изменившие моду</>
        ]}
      />,
      <p>
        <strong>Принципы создания и продвижения личного бренда стилиста</strong>
        <br />
        (8 занятий, 16 академических часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Где будет успешен fashion консультант, стилист, имиджмейкер</>,
          <>Разработка стратегии продвижения личного бренда</>,
          <>Анализ личного бренда, выявление УТП, конкурентный анализ</>,
          <>Упаковка личного бренда</>,
          <>Визуальная концепция, контент-план, тизеры</>,
          <>
            Принципы создания имиджевого фото и видео контента для соц. сетей
          </>,
          <>Практика продвижения персонального бренда в Instagram</>
        ]}
      />,
      <p>
        <strong>Дополнительно в программе:</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Очная практика шопинга (6 занятий, 12 академических часов)</>,
          <>Workshop — реализация фотосессии (3 дня, 24 академических часа)</>,
          <>
            Предпроектные консультации и мастер-классы (6 занятий, 12
            академических часов)
          </>,
          <>
            Итого: 60 занятий + 3 дня workshop + самостоятельная работа (254
            академичесиких часа в дипломе)
          </>
        ]}
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Курс дает системные и практические знания для старта карь&shy;еры в
        индус&shy;трии моды. После обу&shy;че&shy;ния Вы полу&shy;чите диплом
        уста&shy;новлен&shy;ного госу&shy;­дар&shy;­ством образца о
        про&shy;фес&shy;сиональ&shy;ной пере&shy;под&shy;готов&shy;ке по
        про&shy;грам&shy;ме «Консультант по имиджу и стилю» с правом
        деятель&shy;ности в изу&shy;чен&shy;ной профес&shy;сио&shy;наль&shy;ной
        области.
      </p>
    ),
    paperText: false,
    // (
    //   <>
    //     После обучения на данном курсе Вы сможете понять, что это Ваша
    //     профессия, консультировать клиентов. Потом — продолжить погружение в
    //     имидж технологии, поступив на годовую программу «Имидж-консалтинг и
    //     fashion-styling» с зачетом ранее пройденных дисциплин и специальной
    //     скидкой на обучение. В итоге — получите полноценное обучение на основе
    //     практики и два диплома.
    //   </>
    // ),
    image: (
      <img
        src="/img/sdi/diplom-2.jpg"
        alt="Диплом ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    ),
    imageStyle: { maxWidth: 320 }
  },
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неде&shy;лю Вы изуча&shy;ете видео лекции. Лекции
          откры&shy;ваются по мере про&shy;хож&shy;дения груп&shy;пой курса.
          Обуче&shy;ние дви&shy;жется в ком&shy;форт&shy;ном, рабочем ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние задания.
          Это важная часть обуче&shy;ния, в резуль&shy;тате кото&shy;рой
          форми&shy;руется итого&shy;вая проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;пода&shy;­вате&shy;лем. Препо&shy;­дава&shy;­тель лично
          прове&shy;­ряет зада&shy;­ния, находит ошибки, ком­мен&shy;­тирует и
          помо­гает
        </>
      )
    },
    {
      title: "Практика + Книга стиля",
      text: (
        <>
          В итоге обуче&shy;ния Вы созда&shy;­ете презен&shy;тацию
          персо&shy;наль&shy;ного стиля (Книгу стиля — stylebook),
          прово­&shy;дите шо&shy;пинг, реали­&shy;зуете фото­сес&shy;сию для
          Instagram
        </>
      )
    }
  ],
  formats: {
    head: "Форматы обучения",
    content: [
      <p>
        <strong>
          Форма обучения: заочная с применением дистанционных технологий
        </strong>
      </p>,
      <p>
        Обучение предусматривает изучение основного блока лекционных и
        практических материалов онлайн (дистанционно). В рамках курса проводятся
        практические занятия непосредственно в торговых центрах (бутиках) и
        практика подготовки и реализации фотосъемок.
      </p>,
      <p>Очная практика доступна в Москве, Новосибирске, Одессе, Бишкеке.</p>,
      <p>
        Если вы учитесь только дистанционно, мы дадим вам задания и технологию,
        и вы пройдете такую практику в своем городе самостоятельно.
      </p>,
      <p>
        <strong>Учебная нагрузка</strong>
      </p>,
      <p>График занятий:</p>,
      <List
        olStyle={{ paddingLeft: "1rem" }}
        arr={[
          <>
            3 занятия в неделю по 2 академических часа — лекционный материал с
            домашними практическими заданиями
          </>,
          <>
            1 занятие в неделю по 2 академических часа — практические семинары с
            преподавателями по изученным темам, живое общение online
          </>,
          <>2 занятия в месяц — очная практика шопинга</>,
          <>2 занятия в месяц — предпроектные консультации онлайн</>,
          <>
            3 дня workshop (реализация фотопроекта) — проводиться в конце
            обучения
          </>
        ]}
        num="none"
      />,
      <p>
        <strong>Варианты обучения:</strong>
      </p>,
      <p>
        <strong>Дипломная программа</strong> — в рамках обучения с правом
        получения Диплома о профессиональной переподготовке установленного
        государством образца обязательными требованиями являются выполнение
        домашних заданий, реализация проектной работы и прохождение итогового
        тестирования — экзамена (онлайн)
      </p>,
      <p>
        <strong>Вольный слушатель</strong> — Вы можете учиться без обязательных
        требований по выполнению д/з и практик, без сдачи экзаменов. В таком
        случае вы получите сертификат о прохождении программы и дополнительную
        скидку на обучение.
      </p>
    ]
  },
  pay: [
    {
      title: "Дипломный курс",
      gray: (
        <>
          <strong>5750 ₽ / месяц на 12 месяцев</strong>
          <br />
          Полная стоимость: 69 тыс. руб.
        </>
      ),
      text: (
        <>
          <p style={{ textAlign: "left" }}>Доступны рассрочки:</p>
          <List
            arr={[
              <>через банки партнеры на 12 месяцев (для граждан РФ)</>,
              <>стандартная рассрочка на 3 месяца</>
            ]}
            style={{ textAlign: "left", paddingBottom: ".5rem" }}
          />
          <p style={{ textAlign: "left" }}>Доступны скидки:</p>
          <List
            arr={[
              <>1+1 (учитесь вдвоем) = скидка&nbsp;20%</>,
              <>Раннее бронирование = скидка&nbsp;10%</>,
              <>При единовременной оплате = скидка&nbsp;10%</>
            ]}
            style={{ textAlign: "left", paddingBottom: ".5rem" }}
          />
        </>
      ),
      button: true
    },
    {
      title: "Вольный слушатель",
      gray: (
        <>
          <strong>4600 ₽ / месяц на 12 месяцев</strong>
          <br />
          Полная стоимость: 55,2 тыс. руб.
        </>
      ),
      text: (
        <>
          <p style={{ textAlign: "left" }}>Доступны рассрочки:</p>
          <List
            arr={[
              <>через банки партнеры на 12 месяцев (для граждан РФ)</>,
              <>стандартная рассрочка на 3 месяца</>
            ]}
            style={{ textAlign: "left", paddingBottom: ".5rem" }}
          />
          <p style={{ textAlign: "left" }}>Доступны скидки:</p>
          <List
            arr={[
              <>1+1 (учитесь вдвоем) = скидка&nbsp;20%</>,
              <>При единовременной оплате = скидка&nbsp;10%</>
            ]}
            style={{ textAlign: "left", paddingBottom: ".5rem" }}
          />
        </>
      ),
      button: true
    },
    {
      title: "Тест драйв",
      gray: (
        <>
          <strong>900 ₽ / месяц</strong>
        </>
      ),
      text: (
        <>
          <p style={{ textAlign: "left" }}>Вы получаете:</p>
          <List
            arr={[
              <>
                доступ к 8 тематическим лекциям из области истории моды,
                концептуальной моды, психологии имиджа, формирования мужского
                стиля, стилизации фотосьемок, а так же о перспективах карьеры
                стилиста, имиджмейкера
              </>,
              <>
                полноценный доступ к профессиональному обучению в течение первых
                2-х недель с момента старта обучения группы
              </>
            ]}
            style={{ textAlign: "left", paddingBottom: ".5rem" }}
          />
        </>
      ),
      button: true
    }
  ],
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой",
  message: {
    head: (
      <>
        Тест-драйв: первые две недели обучения
        <br />+ консультация стилиста = 900 руб.
        <br />
        Как это работает?
      </>
    ),
    text: (
      <>
        Зарегистрируйтесь или напишите нам. Получи́те консультацию специалиста
        учебного отдела. Потом оплати́те тест драйв.
        <br />
        Вы получите полноценный доступ к обучению на две недели и консультацию
        стилиста. 100% гарантия возврата: мы вернем Вам деньги, если Вы решите
        отказаться от услуги в течение двух недель с момента оплаты
      </>
    )
  },
  teachers: [shabalina2, gorskih2, migranov2, prohorovich2]
};
