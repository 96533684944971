import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents-old";
import Page from "../../components/page";
import {
  HeadBlock,
  Calendar,
  Teachers,
  Programma,
  TextAndTags,
  SocnetBlock,
  AfterEducation,
  Price,
  DatesBlock, //screen2
  BuilderLayout
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>{c.titlePage}</title>
        <meta name="keywords" content={"ВШСДТ: " + c.titlePage} />
      </Helmet>
      <Page lable="Имидж коммуникация">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Calendar content={c.dates} />,
            <TextAndTags
              content={{
                text: c.text1,
                tags: c.tags1
              }}
            />,
            <DatesBlock content={c.screen2} />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <AfterEducation content={c.afterEducation} />,
            <>
              <Teachers
                content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
              />
              <Price content={{ pay: c.pay, countPay: 2 }} />
            </>,
            <SocnetBlock />
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
