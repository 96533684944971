import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Дистанционное образование",
    head: "Дизайн интерьера — онлайн интенсив",
    headAfterDescript:
      "Создайте интерьер Вашей квартиры за 12 занятий с ведущими преподавателями факультета Дизайна ВШСДТ из России и Европы",
    descript: [
      "Видео курс",
      "Свидетельство об обучении (рус/eng)",
      "Длительность: 12 лекций, 6 академических часов",
      "Рассрочка: 10 месяцев",
      "Стоимость курса: 5000 руб."
    ],
    text: "",
    imageSource: "/img/interior-desighn/image-for-one-screen.webp",
    video: "Pq-58u-jD5U",
    styleHead: {
      fontSize: "3rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: {
    dates: false,
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>
          Специальный проект:
          <br />
          ONLINE КАМПУС ВШСДТ
        </span>
      )
    },
    text: (
      <List
        num="none"
        arr={[
          "Зарегистрируйтесь и сразу смотрите две лекции в открытом формате",
          "Убедитесь, что хотите изучить курс о погрузиться в дизайн"
        ]}
      />
    ),
    video: "Pq-58u-jD5U"
  },
  text1: (
    <>
      <span style={{ fontStyle: "normal", fontWeight: 700 }}>Если Вы</span>
      <List
        arr={[
          "Хотите осознанно и рационально создать интерьер своей квартиры",
          "Вы уже все придумали и надо правильно контролировать процессы",
          "Вы мечтаете о карьере дизайнера и хотите попробовать себя"
        ]}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      />
    </>
  ),
  programma: {
    head: "Программа курса",
    content: [
      <h3 style={styles.p}>
        <strong>Цель обучения</strong>
      </h3>,
      <List
        num="none"
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Получить ответы на основные вопросы о приемах стилизации и организации пространства интерьера",
          "Оптимизировать расходы на ремонт и обустройство квартиры за счет контроля смет и подрядчиков",
          "Сделать первый шаг в профессии, освоить базовые принципы проектирования интерьера",
          "Получить рекомендации профессионалов по дальнейшему развитию"
        ]}
      />,
      <h3 style={styles.p}>
        <strong>Содержание курса</strong>
      </h3>,
      <List
        style={styles.p}
        num="decimal"
        olStyle={{ marginLeft: 0, paddingLeft: "1rem" }}
        arr={[
          "Стили в современном интерьере (Марина Костарнова)",
          "Эргономика пространства (Марина Костарнова)",
          "Цвет и композиция в интерьере (Марина Костарнова)",
          "Разработка концепции пространства (Елена Ламонова)",
          "Строительные нормы и правила (Эрика Ненашева)",
          "Создание планов Вашего помещения (Елена Ламонова)",
          "Зонирование интерьера и организация хранения (Марина Костарнова)",
          "Сценарий освещения (Елена Ламонова)",
          "Электрика (Елена Ламонова)",
          "Декоративно-отделочные материалы (Камила Исламова)",
          "Бренды (Камила Исламова)",
          "Декорирование (Камила Исламова)"
        ]}
      />
    ]
  },
  teachersHead: "Ведущие курса",
  teachers: [
    {
      image:
        "/img/living-interior-design-and-decoration/prepodavatel-marina-kostarnova-2.jpg",
      name: "Марина Костарнова (Бельгия)",
      text: (
        <span>
          Практикующий дизайнер и декоратор. Член Между&shy;народ&shy;ного Союза
          ди&shy;зай&shy;неров.
        </span>
      ),
      instagram: false,
      buttons: {
        detail: "/marina_kostarnova",
        linkInside: true
      }
    },
    {
      image: "/img/living-interior-design-and-decoration/lamonova.jpg",
      name: "Елена Ламонова (Россия)",
      text:
        "Дизайнер. Специалист в области интерьерного, ландшафтного, \
        предметного дизайна, создатель концепций и проектов \
        оформления мероприятий всероссийского уровня, создатель \
        собственного дизайн бюро, преподаватель Высшей школы стилистики",
      instagram: false,
      buttons: {
        detail: "/elena-lamonova",
        linkInside: true
      }
    },
    {
      image: "/img/living-interior-design-and-decoration/nenasheva.jpg",
      name: "Эрика Ненашева (Россия)",
      text:
        "Практикующий дизайнер интерьера. Художник. Технический директор собственной студии дизайна интерьеров ArtStreet Design.",
      instagram: false,
      buttons: {
        detail: "/erika_nenasheva",
        linkInside: true
      }
    },
    {
      image: "/img/living-interior-design-and-decoration/islamova.jpg",
      name: "Камила Исламова (Италия)",
      text: (
        <List
          arr={[
            "Начала обучение дизайну интерьера в Британской Школе Дизайна",
            "Получила диплом Бакалавра University of Hertfordshire в Великобритании (факультет Interior Architecture and Design)",
            "Переехала в Италию. Получила магистерскую степень в Scuola Politecnica di Design в Милане (Master Diploma in Interior Design)",
            "Участвовала в разработках программ, а также проведении занятий для студентов таких миланских школ как Politecnico di Milano и Scuola Politecnica di Design по двум основным предметам: «Концепция проекта» и «Материалы».",
            "Сейчас являюсь руководителем и главным дизайнером ателье интерьеров Dreamers."
          ]}
        />
      ),
      instagram: false,
      buttons: {
        detail: "/islamova",
        linkInside: true
      }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 2 месяца, 26 занятий по 2 академ. часа
            <br />
            Формат обучения: все занятия проходят онлайн с преподавателем
            <br />
            Все занятия доступны в записи в личном кабинете
            <br />
            Обучение проводится в мини группе
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты: 25
                  <sup>000</sup> рублей — единовременный платёж
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>2500 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 25
                        <sup>000</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="25000.00"
                            name="Теория и практика концептуальной моды (онлайн-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>,
                "Студентам и выпускникам ВШСДТ предоставляется скидка",
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </div>
      )
    },
    distant: {
      textButton: "Дистанционное обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: сертификат
            <br />
            Длительность: 26 видео занятий по 2 академических часа
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          19
                          <sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>1990 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 19
                        <sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="19900.00"
                            name="Теория и практика концептуальной моды (дистанционное-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  }
};
