import React from "react";
import List from "../../components/list";
import { Link } from "react-router-dom";
import Forma from "../../components/credit-form";
import { dates, Month } from "../shedule/dates";
import {
  fomina,
  maltseva,
  bahova,
  prohorovich,
  gorskih,
  rodina
} from "../../contents/teachers";

const styles = {
  h1: {
    fontFamily: "Roboto",
    fontSize: "2rem",
    fontWeight: 700,
    color: "rgb(80,80,80)",
    textAlign: "center",
    margin: 0,
    padding: "2rem 0",
    paddingTop: 0
  },
  h2: {
    fontFamily: "Roboto",
    fontSize: "1.6rem",
    fontWeight: 700,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: "1.6rem 0 1.4rem 0"
  },
  h3Big: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    fontWeight: 700,
    color: "rgb(123,143,87)",
    textAlign: "center",
    margin: 0,
    padding: "1em 0"
  },
  h3: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 700,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: "1em 0"
  },
  p: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pJust: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "justify",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pCenter: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pIll: {
    fontFamily: "Roboto",
    fontSize: "0.8em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: 0,
    lineHeight: "1.2em"
  },
  image: {
    maxHeight: 350
  },
  imageBox: {
    textAlign: "center"
  }
};

const stylesPrice = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  titlePage: "Имидж как коммуникация и продвижение",
  head: {
    headDescript: "Профессиональное образование",
    head: "Имидж как коммуникация и продвижение",
    headAfterDescript:
      "Продуманная трансляция информации о себе в сети партнерам, работодателям, миру через одежду, текст, личные страницы, резюме, фото. Применяйте эти знания уже после 3 месяца обучения",
    descript: [
      "Онлайн, индивидуально",
      "Удостоверение о повышении квалификации",
      "6 месяцев, 100 занятий + самостоятельная работа",
      "Стоимость первого месяца — 500 руб.",
      "Материнский капитал, налоговый вычет"
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/profashion/bkg.jpg",
    styleImage: {
      maxHeight: 334
    },
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    },
    styleImageBox: {
      marginTop: "2rem",
      textAlign: "right",
      background: "rgb(237,236,227)",
      padding: 0
    }
  },
  text1: (
    <List
      arr={[
        "Когда важно личное развитие и нужно раскрыть свой потенциал",
        "Когда Вы готовы к изменениям",
        "Когда Вы ищете больше",
        "Научитесь пользоваться имидж-технологиями как инструментом в бизнесе, карьере, коммуникациях"
      ]}
    />
  ),
  tags1: (
    <span>
      <a href="#career">
        <span style={{ color: "rgb(180,180,180)" }}>#чтоговорят&ensp;</span>
      </a>{" "}
      <a href="#price">
        <span style={{ color: "rgb(180,180,180)" }}>#стоимость&ensp;</span>
      </a>{" "}
      <Link to="/imagemaker-stylist">
        <span style={{ color: "rgb(180,180,180)" }}>#сравнитькурсы</span>
      </Link>
    </span>
  ),
  dates: {
    online: {
      day: dates.imageCommunication.online.getDate(),
      month: Month(dates.imageCommunication.online.getMonth()),
      type: "дистанционно"
    },
    ofline: {
      day: dates.imageCommunication.ofline.getDate(),
      month: Month(dates.imageCommunication.ofline.getMonth()),
      type: <span>&ensp;очно</span>
    }
  },
  screen2: {
    dates: false,
    quote: {
      text: "«Всё, что нужно для серьёзного успеха – это быть уникальным»",
      author: "Стинг"
    },
    text: (
      <>
        <strong>После обучения:</strong>
        <List
          arr={[
            "Рационально подбирать одежду по вкусу или ситуации",
            "Организовывать имиджевые сьемки для соц. сетей",
            "Понимать значение моды и искусства в коммуникациях",
            "Использовать принципы networking и культуры общения"
          ]}
        />
      </>
    ),
    video: "rYKZAsSnC84"
  },
  text2: false,
  aboutProfeshion: false,
  principes: false,
  programma: [
    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Цели обучения:
    </h2>,
    <List
      style={styles.p}
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      num="none"
      arr={[
        <>
          Формирование персональной визуальной коммуникации с окружаюшим миром
          через правильный подбор имиджа и стратегии личного продвижения.
        </>
      ]}
    />,
    <List
      style={styles.p}
      num="none"
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      arr={[
        <b>Программа состоит из 5 основных направлений:</b>,
        "Стилистика: имидж индивидуальный, профессиональный",
        "Организация фото и видео съемок для соц сетей",
        "Формирование, продвижение персонального бренда в сети",
        "Деловые коммуникации, резюме, презентация",
        "Современная мода как элемент коммуникации",
        <>&emsp;</>
      ]}
    />,

    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Ключевые дисциплины и темы
    </h2>,
    <List
      style={styles.p}
      num="none"
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      arr={[
        "Практика исследования и восприятия формы, линии, цвета",
        "Колористика и использование цвета в визуальных коммуникациях",
        "Типологии фигур, возможности и способы визуальной коррекции фигур",
        "Подбор индивидуальной цветовой палитры при формировании имиджа",
        "Аксессуары, правила и способы подбора с учетом fashiontrends",
        "Основные группы современных стилей, концепция индивидуального стиля как система создания необходимого впечатления",
        "Мужская стилистика, ее особенности, практика применения и современные тренды",
        "Типы современного гардероба, формирование рационального гардероба",
        "Имиджевые элементы и принципы: дресс-коды, этикет",
        "Организация и продюссирование фото и видеосъемок",
        "Практика реализации фото и видео проектов на условиях TFP",
        "Стратегия и практика создания персонального бренда",
        "Принципы исследования ранка, партнеров и работодателей для определения стратегий продвижения",
        "Правила составления эффективных презентаций и резюме",
        "Особенности своей профессиональной презентации в Instagram",
        "Практика продвижения персонального бренда в Instagram с использованием имиджевого фото и видео контента",
        "Современная мода и современное искусство как коммуникация и как бизнес",
        "Принципы формирования креативного мышления и использования современных тенденций в продвижении персонального бренда"
      ]}
    />,
    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Результат успешной учебы
    </h2>,
    <List
      style={styles.p}
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      arr={[
        "Защита проектный работы",
        "Сформированный индивидуальный стиль",
        "Стратегия развития персонального бренда"
      ]}
      num="none"
    />
  ],
  variants: false,
  afterEducation: {
    head: "Карьера, бизнес, профессия",
    videos: ["fOZzI3bKXIU", "2_fcNTHeV3g", "NzsSXUy2GMk"]
  },
  aboutEducation: (
    <span>
      Программа обучения в очном и онлайн форматах состоит из 125&nbsp;занятий
      по 2&nbsp;академических часа.
      <br />
      График проведения занятий: по 2&nbsp;занятия 2-3&nbsp;раза в неделю.
      <br />
      Длительность всего курса — 5+&nbsp;месяцев.
    </span>
  ),
  formats: false,
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость и характеристики очного обучения</h3>
          <p style={stylesPrice.text}>
            <strong>Характеристики:</strong>
          </p>
          <List
            style={stylesPrice.text}
            arr={[
              "Документ об окончании: Свидетельство об обучении / Удостоверение о повышении квалификации",
              "Длительность: 6 месяцев, 100 онлайн-занятий по 45 минут + самостоятельная работа",
              "Формат обучения: последовательный доступ к 3 онлайн лекциям в неделю плюс живое общение с куратором курса 1 раз в неделю, итого 4 занятия в неделю",
              "Все занятия доступны в записи в личном кабинете в процессе обучения и в течение 6 месяцев после обучения",
              "Экзамены: проектные работы, тесты"
            ]}
          />
          <p style={stylesPrice.text}>
            <strong>Стоимость:</strong>
          </p>
          <List
            style={stylesPrice.text}
            num="decimal"
            arr={[
              <div>
                Вариант оплаты: рассрочка на время обучения
                <List
                  style={stylesPrice.text}
                  arr={[
                    <>
                      <strong>500 руб.</strong> — первый месяц обучения
                    </>,
                    <>
                      25
                      <sup>000</sup> рублей — платежи во 2-й и 4-й месяцы
                      обучения
                    </>,
                    <>
                      итого полная стоимость обучения — 50
                      <sup>500</sup> рублей
                    </>
                  ]}
                />
              </div>,
              <div>
                Вариант оплаты: банковская рассрочка без переплаты на 10 месяцев
                <List
                  style={stylesPrice.text}
                  arr={[
                    <span>
                      <b>
                        5<sup>000</sup> рублей
                      </b>{" "}
                      ежемесячный платеж
                    </span>,
                    <span>
                      итого 10 платежей в сумме — 50
                      <sup>000</sup>
                      рублей
                    </span>,
                    "возможно оформление кредита на более длительный срок на условиях банка партнера"
                  ]}
                />
                <Forma
                  sum="50000.00"
                  name="Имидж-консалтинг, курс профессиональной переподготовки (Дипломная программа, гибкий формат)"
                  test={false}
                  yellow
                />
              </div>
            ]}
          />
          <p style={stylesPrice.text}>
            <strong>Важно:</strong>
          </p>
          <List
            style={stylesPrice.text}
            arr={[
              "по запросу мы предоставляем документы для налогового вычета",
              "можно оплатить обучение материнским капиталом"
            ]}
          />
        </>
      )
    },
    free: {
      textButton: "Дисциплины по выбору",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость обучения на отдельных дисциплинах</h3>
          <List
            style={stylesPrice.text}
            num="decimal"
            arr={[
              "Стоимость обучения для вольного слушателя соответствует стоимости выбранного формата обучения",
              "Стоимость освоения отдельных модулей в дистанционном формате определяется на основании ставки от 500 рублей за академический час",
              "Стоимость индивидуальных консультаций в рамках образовательных программ составляет 2000 рублей за академический час",
              "Итоговую стоимость обучения в выбранном формате по отдельным дисциплинам уточняйте у специалистов учебного отдела"
            ]}
          />
        </div>
      )
    }
  },
  info: false,
  teachers: [fomina, maltseva, bahova, prohorovich, gorskih, rodina],
  review: false,
  examples: false,
  reviewImgs: false,
  videos: [
    {
      link: "fOZzI3bKXIU",
      text:
        "Цикл интервью преподавателя Высшей Школы Стилистики, Дизайна и Технологий, дизайнера, имидж — стилиста Татьяны Фоминой @fominafashionstyle. Интервью с выпускницей #ВШСДТ, имидж-стилистом Лидой Акимовой @lididilisa. Тема беседы: доступная мода."
    },
    {
      link: "2_fcNTHeV3g",
      text:
        "Интервью с имидж-стилистом, организатором светских мероприятий, выпускницей ВШСДТ Мариной Федоровой. В чем принципиальная разница между подходом к моде и стилю в России и Германии? Как внешние изменения способствуют смене жизненного курса? На что в первую очередь нужно ориентироваться при подборе своего индивидуального и узнаваемого образа? Обсудим с имидж-стилистом, организатором светских мероприятий, выпускницей ВШСДТ Мариной Федоровой (Германия)."
    },
    {
      link: "NzsSXUy2GMk",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Софией Саввиди. Цикл интервью преподавателя Высшей Школы Стилистики, Дизайна и Технологий, дизайнера, имидж-стилиста Татьяны Фоминой @fominafashionstyle. Интервью с выпускницей #ВШСДТ, семейным имидж-стилистом Софией Саввиди@sofi_savvidistyle. Тема беседы: качественное образование стилиста."
    },
    {
      link: "rYKZAsSnC84",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с основателем ВШС в Новосибирске Надеждой Исхаковой. Интервью преподавателя Высшей Школы Стилистики, Дизайна и Технологий, дизайнера, имидж-стилиста Татьяны Фоминой с международным fashion экспертом, основателем Высшей Школы Стилистики в Новосибирске Надеждой Исхаковой. Тема беседы: карьерный рост для стилистов."
    }
  ],
  videosAfter: [
    "0s4k1IH3Pik",
    "9Ns4tVxzE_8",
    "VG3HCLBilGc",
    "fR9JZaYZLOU",
    "gZeWO85gha0",
    "npJ2B4syBMk"
  ],
  notAbout: true,
  notExample: true,
  notFirstForm: true
};
