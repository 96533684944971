import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Concept from "../../components/banner-conceptual";
import Butique from "../../components/banner-business-boutique";
import Visual from "../../components/banner-fashion-visual-merchandising";
import Management from "../../components/banner-management-is-in-fashion";
import ProfiJournalist from "../../components/banner-profi-fashion-journalist";
import Psyhology from "../../components/banner-psychology-style";
import PsychologyOfStyle from "../../components/banner-psychology-of-style";
import Makeup from "../../components/banner-make-up";
import Foto from "../../components/banner-foto-course";
import PressSecretary from "../../components/banner-press-secretary";
import Digital from "../../components/banner-fashion-media-digital";
import ModernFashionStyle from "../../components/banner-modern-moda-style";
import { Link } from "react-router-dom";
import Page from "../../components/page";

const name = "Архив";

const Archive = props => {
  return (
    <div>
      <Helmet>
        <title>{name}</title>
        <meta name="keywords" content={"ВШС: " + name} />
      </Helmet>
      <Page lable="Архив">
        <Grid
          container
          alignItems="flex-start"
          spacing={4}
          style={{ padding: 12 }}
        >
          <Grid item xs={12} md={12}>
            <h2
              style={{
                font: '700 2em "Roboto"',
                textAlign: "left",
                margin: 0,
                padding: "30px 0 50px 0"
              }}
            >
              {name}
            </h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <Concept />
          </Grid>
          <Grid item xs={12} md={6}>
            <Butique />
          </Grid>
          <Grid item xs={12} md={6}>
            <Visual />
          </Grid>
          <Grid item xs={12} md={6}>
            <Management />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfiJournalist />
          </Grid>
          <Grid item xs={12} md={6}>
            <Psyhology />
          </Grid>
          <Grid item xs={12} md={6}>
            <Makeup />
          </Grid>
          <Grid item xs={12} md={6}>
            <Foto />
          </Grid>
          <Grid item xs={12} md={6}>
            <PressSecretary />
          </Grid>
          <Grid item xs={12} md={6}>
            <Digital />
          </Grid>
          <Grid item xs={12} md={6}>
            <ModernFashionStyle />
          </Grid>
          <Grid item xs={12} md={6}>
            <PsychologyOfStyle />
          </Grid>
          <Grid item xs={12} md={12}>
            <Link to="/styleschool">Школа стиля</Link>
            <br />
            <Link to="/fashionschool">Школа моды</Link>
            <br />
            <Link to="/artschool">Школа искусств</Link>
            <br />
            <Link to="/schoolofdesign">Школа дизайна</Link>
            <br />
            <Link to="/schoolofmanagement">Школа управления</Link>
            <br />
            <Link to="/teachers-old">Преподаватели</Link>
            <br />
            <Link to="/image-communication-old">Имидж, как коммуникация</Link>
            <br />
            <Link to="/political-style-old">Имидж публичной персоны</Link>
            <br />
            <Link to="/career">Центр карьеры</Link>
            <br />
            <Link to="/internship-in-milan">
              Практика на Milan Fashion Week
            </Link>
            <br />
            <Link to="/tbilisi-fashion-weekend">
              Практика Fashion-каникулы в Тбилиси
            </Link>
            <br />
            <Link to="/stylist-association">Ассоциация выпускников</Link>
            <br />
            <Link to="/young-fashion">Юношеская студия</Link>
            <br />
            <Link to="/krasnoyarsk">Красноярск</Link>
            <br />
            <Link to="/voronezh">Воронеж</Link>
            <br />
            <Link to="/smolensk">Смоленск</Link>
            <br />
            <Link to="/kazahstan">Казахстан</Link>
            <br />
            <a href="http://bishkek.styleschool.ru/">Киргизия</a>
            <br />
            <Link to="/career">Центр карьеры</Link>
            <br />
            <Link to="/tatiana_fomina">Татьяна Фомина</Link>
            <br />
            <Link to="/aibot-productowner">
              Управление разработкой диалоговых систем с ИИ
            </Link>
            <br />
            <Link to="/bfashionsprint">
              Business Fashion Sprint: акселератор для предпринимателей
            </Link>
            <br />
            <Link to="/milan-design-week">Практика. Milan Design Week</Link>
            <br />
            <Link to="/mastery-of-writing-text">
              Мастерство создания текста
            </Link>
            <br />
            <Link to="/aibot-productowner-old">
              Профессия: Дизайнер чат-ботов с искусственным интеллектом
            </Link>
          </Grid>
        </Grid>
      </Page>
    </div>
  );
};

export default Archive;
