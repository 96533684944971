import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { Tiles } from "../../components/tiles/tiles";
import { MiniForm } from "../../components/forms";
import {
  HeadBlock,
  BuilderLayout,
  Text3Block,
  ShessBlock,
  HowIsTheTrainingGoing,
  TitleH2
} from "../../patterns/pattern-fs/components";

const SelfStyle = props => {
  return (
    <div>
      <Helmet>
        <title>Сам себе стилист. Мастерская Светланы Шабалиной</title>
        <meta
          name="keywords"
          content="сам, себе, стилист, шопинг, купить, модная, стильная, одежда, имидж, тренд, обучение, учиться, курс"
        />
        <meta
          name="descript"
          content="Практический курс по созданию индивидуального стиля с преподавателем, обучающим профессиональных стилистов-имджмейкеров, практикующим имидж-стилистом Светланой Шабалиной. Вы научитесь создавать свой Stylebook с персо­наль­ной палит­рой, подхо­дящими фасо­нами и комплек­тами на все случаи жизни. Вы обретете навык подбора в магази­нах своих вещей без лишних поку­пок. Сможете помогать подру­гам и давать стиль­ные советы"
        />
      </Helmet>
      <Page lable="Сам себе стилист">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} vAlign="flex-start" noButtons />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                left: c.text1.image,
                right: c.text1.text
              }}
              mediaLeft={true}
              mobileMediaUp={true}
              vAlign="center"
            />,
            <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />,
            <>
              <TitleH2 content="Программа курса" scroll="programma" />
              <Tiles content={c.tilesProgramma} />
            </>,
            <>
              <TitleH2 content="Преподаватель" scroll="teachers" />
              <ShessBlock
                content={{
                  left: c.screen2.image,
                  right: c.screen2.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content="Купите курс и консультируйтесь со стилистом каждый месяц" />
              <ShessBlock
                content={{
                  left: c.curent.image,
                  right: c.curent.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content="Варианты обучения, стоимость" scroll="price" />
              {c.insertAfterDates}
            </>,
            <>
              <TitleH2 content={c.miniFormHead} />
              <MiniForm formName="miniforma-self-style" />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default SelfStyle;
