import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Teacher from "../../components/teacher";
import List from "../../components/list";
import Page from "../../components/page";

export default function Teach() {
  var content = [
    {
      title: "",
      text: (
        <span>
          Преподаватель курса &laquo;Основные стратегии современной модной
          индустрии&raquo; в составе программы профессиональной переподготовки
          &laquo;Имиджмейкер. Второе высшее&raquo;, авторского курса
          &laquo;Концептуальное искусство и концептуальная мода&raquo;, циклов
          лекций по искусству и художественному образу
        </span>
      )
    },
    {
      title: "Образование",
      text: (
        <span>
          Томский Государственный Университет, Филологический Факультет
          <br />
          Кандидат филологических наук по специальности &laquo;Русская
          литература&raquo;
        </span>
      )
    },
    {
      title: "Дополнительное образование",
      text:
        "Международный сертификат по программе: «Управление проектами – развитие в XXI веке. Стандарт ANSI PMI PMBOK Guide 2000»"
    },
    {
      title: "Опыт работы",
      text: (
        <div>
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1em",
              fontWeight: 700,
              color: "rgb(140,140,140)",
              textAlign: "left"
            }}
          >
            Административная деятельность
          </h3>
          <p>ВШСДТ:</p>
          <List
            arr={[
              "разработка, обновление и внедрение новых программ обучения, разработка регламентов, принципов обучения, ведения учебного процесса, планирование и контроль учебного процесса, управление коммуникациями очного и онлайн обучения",
              "реализация программ профессиональной переподготовки и повышения квалификации совместно с факультетом дополнительного образования государственных Вузов, разработка УМК программ обучения"
            ]}
          />
          <p>
            Санкт-Петербургское отделение Института Проектного Менеджмента (PMI)
          </p>
          <List
            arr={[
              "планирование и контроль учебного процесса дистанционного формата обучения по программе «Управление проектами. Стандарт ANSI PMI PMBOK Guide 2000»",
              "актуализация методических и экзаменационных материалов, управление коммуникациями (слушатели, преподаватели)"
            ]}
          />
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1em",
              fontWeight: 700,
              color: "rgb(140,140,140)",
              textAlign: "left"
            }}
          >
            Преподавательская деятельность
          </h3>
          <List
            arr={[
              "ВШСДТ: преподаватель курсов: «Индустрия моды: вопросы анализа коллекций»,               «Стилевая система моды», «Концептуальное искусство и концептуальная мода»,               «Концепции профессионального стиля», «Художественный образ: от классики к               метамодерну», цикл лекций по стилям в искусстве",
              "СПБ PMI: преподаватель курса « Базовый курс по управлению проектами на основе Стандарта ANSI PMI PMBOK Guide 4th Edition» дистанционного формата обучения, семинаров по теме «Управление человеческими ресурсами», «Управление коммуникациями» очного формата обучения",
              "КГПА: преподаватель курсов «Введение в литературоведение», «Зарубежная литература XVII-XVIII веков», «Философия культуры», «Вещь как символ»"
            ]}
          />
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1em",
              fontWeight: 700,
              color: "rgb(140,140,140)",
              textAlign: "left"
            }}
          >
            Экспертная деятельность
          </h3>
          <List
            arr={[
              "Курсы повышения квалификации и мастер-классы для корпоративных клиентов и преподавателей Вузов (Бишкек)",
              "Спикер и член жюри Форума Бизнеса Моды Fashion-Management (Новосибирск)",
              "Аналитические статьи и публикации для журналов и соцсетей"
            ]}
          />
        </div>
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Наталья Александровна Горских</title>
        <meta
          name="keywords"
          content="Наталья Горских, философия, японская мода, прерафаэлиты, искусство, стиль"
        />
        <meta
          name="descript"
          content="Наталья Горских — кандидат филологических наук, преподаватель ВШСДТ курса «Основные стратегии современной модной индустрии» в составе программы профессиональной переподготовки «Имидж консалтинг и fashion styling» и «Консультант по стилю», авторского курса «Концептуальное искусство и концептуальная мода», циклов лекций по искусству и художественному образу, «Философия японской моды», Прерафаэлиты (из цикла история стилей)."
        />
      </Helmet>
      <Page lable="Наталья Горских" lableMobile="Н. Горских">
        <Grid container justify="center" alignItems="flex-start">
          <Grid item style={{ padding: 20 }}>
            <Teacher
              head="Наталья Александровна Горских"
              short="Заместитель руководителя ВШСДТ, преподаватель ВШСДТ, к.филол.наук"
              image="/img/about/natalya-gorskih.jpg"
              tiles={[
                {
                  image: "/img/star.svg",
                  title: "Более 20 лет",
                  text: "опыт преподавательской деятельности"
                },
                {
                  image: "/img/account-group.svg",
                  title: "член PMI",
                  text:
                    "Международная организация для профессионалов в области управления проектами"
                }
              ]}
              content={content}
            />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
