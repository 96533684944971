import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";

function StudentsResume(props) {
  const title = "База резюме студентов ВШСДТ";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="keywords" content={"ВШСДТ: " + title} />
      </Helmet>
      <Page lable="" lableMobile="" />
    </div>
  );
}

export default StudentsResume;
