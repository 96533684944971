import React from "react";
import { Grid } from "@material-ui/core";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Title from "./title-meta";
import Header from "./header-links";

const name = "Гульсара Осорова";

const styles = {
  h2: {
    color: "rgb(123,143,87)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "left",
    margin: 0,
    padding: "12px 0"
  },
  text: {
    color: "rgb(100,100,100)",
    textAlign: "justify",
    margin: 0,
    padding: "12px 0"
  }
};

export default function Nazimova() {
  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/osorova.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              facebook="https://www.facebook.com/profile.php?id=620599256"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Детские мечты</h2>
              <p style={styles.text}>
                Профессия мечты в детском возрасте &mdash; кинорежиссер. Я
                выросла в средней Азии, и родители не считали, что эта профессия
                сможет приносить мне стабильный доход в будущем. В итоге учиться
                я пошла в педагогический институт. Сознательно я не выбирала
                свой дальнейший путь профессионального развития, и так
                случилось, что от творчества моя деятельность была крайне
                далека.
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Вопросы без ответов</h2>
              <p style={styles.text}>
                Меня всегда интересовало, почему девушки на обложках глянцевых
                журналов так кардинально отличаются от девушек, которых я вижу
                на улицах? За счет чего образ героинь голливудских фильмов
                настолько гармоничен, в чем секрет? В юности у меня не было
                времени и возможностей искать ответы на эти вопросы.
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 style={styles.h2}>Уделить время себе</h2>
              <p style={styles.text}>
                Все свое свободное время сначала я посвящала обучению, потом
                работе координатора в детском фонде ООН или в бельгийском
                проекте по кризисам и конфликтам. Потом на свет появился сын,
                через два года &mdash; дочка. И только сейчас, когда дети
                подросли, я смогла уделить время и себе. Я понимала, что мой
                гардероб далек от идеала, но что именно в нем не так, я понять
                не могла. И я решила это исправить. Я начала искать курсы по
                стилю.
              </p>
            </Grid>
          </Grid>
          <div
            style={{
              fontSize: "1.2em",
              color: "rgb(100,100,100)",
              background: "rgb(210,205,196)",
              padding: 12
            }}
          >
            В центральной Азии нет альтернатив Высшей школе стилистики. Там
            вообще о стиле мало кто знает. Поэтому я стала рассматривать для
            себя вариант дистанционного обучения. В Высшей школе стилистики как
            раз была возможность обучаться дистанционно, плюс к этому оказалась
            наиболее подходящая мне программа. Так я остановила свой выбор на
            ВШС.
          </div>
          <div style={{ height: 32 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/osorova2.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.h2}>Творческая реализация</h2>
              <p style={styles.text}>
                После первой же лекции я твердо для себя решила, что весь мой
                творческий потенциал, который до сих пор не был никак
                реализован, я направлю на развитие себя как имиджмейкера.
                Обучение в школе пролетело, как один день. Я узнала для себя то,
                что перевернуло мое представление о моде в принципе. Никаких
                сложностей с дистанционным обучением не возникало, это только
                ускоряло процесс обучения. Первыми «подопытными» в моем деле
                стали, конечно же, мои дети. Мое стремление к обучению
                подстегивало еще то, что у меня растет дочка, которой мне
                хотелось с детства привить чувство прекрасного.
              </p>
              <h2 style={styles.h2}>Судьбоносное решение</h2>
              <p style={styles.text}>
                В Киргизии специалистов в области имиджа и стиля практически
                нет. Еще в процессе обучения я начала задумываться о том, чтобы
                открыть в Бишкеке свою школу имиджа и стиля. Большую роль в
                принятии этого решения сыграл мой муж. Когда я сообщила ему, что
                больше не хочу заниматься международными проектами, а хочу
                профессионально развиваться в области имиджмейкинга, он одобрил
                мое решение! Его поддержка была неоценима в этот момент, так как
                я сама не была уверена в том, что моя идея со школой имиджа и
                стиля может воплотиться в реальный бизнес.
              </p>
              <h2 style={styles.h2}>Страх &mdash; мой катализатор</h2>
              <p style={styles.text}>
                Очень страшно было после окончания обучения начинать заниматься
                имиджмейкингом профессионально. В Кыргызстане это направление не
                пользуется популярностью. Когда я начинала организовывать свою
                школу, мало кто понимал, чем я собираюсь заниматься, да и сейчас
                не так много людей, которые воспринимают мою деятельность
                серьезно. Но, несмотря на все трудности, сейчас я являюсь
                руководителем успешной школы имиджа и стиля BISHKEK STYLE GROUP.
                Мне больно смотреть на тех женщин, которые приходят на мои
                занятия. Это женщины от 35 до 50 лет, прожив практически
                половину своей жизни, они не знают, как быть красивыми! И я
                выражаю огромную благодарность Высшей школе стилистики за те
                знания, которые я там получила и которые теперь могу передавать
                другим.
              </p>
            </Grid>
          </Grid>
          <div
            style={{
              fontSize: "1.2em",
              color: "rgb(100,100,100)",
              background: "rgb(210,205,196)",
              padding: 12
            }}
          >
            Обучение стилистики не только откроет перед Вами новые
            профессиональные горизонты, но и изменит Ваше мироощущение, а,
            значит, и Ваш внешний вид. Любой женщине пойдут на пользу знания о
            том, как должны сочетаться фактуры, цвета и стили в её гардеробе.
          </div>
          <div style={{ height: 32 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <h2 style={styles.h2}>Далеко идущие планы</h2>
              <p style={styles.text}>
                В дальнейших моих планах &mdash; создание социальной группы
                людей в городе Бишкек, которым не просто интересно, как красиво
                одеться, а интересно изучать моду с точки зрения социального
                феномена. Хочу донести до людей идею о том, что имиджмейкинг
                &mdash; не забава для гламурных девиц, а серьезная наука. Если
                говорить о долгосрочных планах, то это популяризация стилистики
                в Кыргызстане и личностное развитие в сфере политического
                имиджа.
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 style={styles.h2}>Иди за мечтой, невзирая на возраст!</h2>
              <p style={styles.text}>
                Верьте в себя! Это то, что я говорю всем своим студентам.
                Абсолютно не важно, что у Вас за плечами уже есть два высших
                образования, стабильная работа и двое, а может быть, и трое
                детишек. Если Вы хотите изменить свою жизнь, то попробуйте
                начать с профессиональной сферы.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
