import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "../../components/list";

export default function Columns() {
  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item xs={12} md={12}>
        <h3 style={{ fontSize: "1.7em", textAlign: "left" }}>
          Цель, содержание и результат курса – вопросы, на которые попытаемся
          дать ответ, не претендуя на истинность:
        </h3>
        <List
          arr={[
            "что есть концептуальное искусство и современное искусство в целом и можно ли его называть искусством",
            "в чем суть концептуальной моды и как относится к одежде, которая не принимается и не используется большинством",
            "каковы признаки концептуальности бренда и где граница между собственно творчеством и успешной маркетинговой стратегией",
            "в чем сходство и различие концептуальных дизайнеров и брендов, зачем моде инновационный текстиль и технологии, о каком будущем говорит костюм и кто сможет прочитать эти послания."
          ]}
          style={{ fontSize: "1.2em" }}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <h3 style={{ fontSize: "1.7em", textAlign: "left" }}>
          Результаты курса:
        </h3>
        <List
          arr={[
            "глубокий уровень освоения истории современного искусства второй половины XX века, и концептуализма в частности",
            "профессиональная ориентация в основных направлениях авангардных художественных практик во взаимосвязи с современной концептуальной модой",
            "знание концептуальных мировых дизайнеров и брендов с делением на национальные школы, понимание особенностей презентации их коллекций во взаимодействии с фотографами и на основе цифровых технологий",
            "понимание «языка» концептуального подхода к проектированию костюма (очень подробно &ndash; на примере Maison Martin Margiela и его создателя, а также японских дизайнеров)",
            "развитие необходимых навыков эффективного построения эстетической базы восприятия и трактовки концептуального искусства и моды для дальнейшего профессионального роста и личного творчества"
          ]}
          style={{ fontSize: "1.2em" }}
        />
      </Grid> */}
    </Grid>
  );
}
