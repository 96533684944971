import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import List from "../../components/list";
import { Link } from "react-router-dom";
import Tile from "./tile";
import { styles } from "./style";
import "./style.css";
// import { content } from "../../pages/about/best-teachers";

const wStyles = theme => styles;

function Layout(props) {
  const { classes } = props;
  const c = props.content;
  const [hash, setHash] = useState("default");
  const toogleHash = h => {
    setHash(h);
    console.log(h);
  };
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <h1 className={classes.h1} style={{ fontFamily: styles.h1.fontFamily }}>
        {c.head}
      </h1>
      {c.hash ? (
        <div className="hash-block">
          <span onClick={() => toogleHash("default")} className="hash-text">
            #всекурсы&ensp;
          </span>
          {c.hash.map((el, key) => (
            <span onClick={() => toogleHash(el.link)} className="hash-text">
              {el.text}
              &ensp;
            </span>
          ))}
        </div>
      ) : null}
      {c.before ? (
        <div style={{ paddingBottom: "4rem" }}>{c.before}</div>
      ) : null}
      <Grid container justify="center" alignItems="stretch" spacing={2}>
        {hash == "default"
          ? c.tiles.map((el, key) => (
              <Grid item xs={12} md={6}>
                <Tile
                  key={key}
                  head={el.head}
                  typeEducation={el.typeEducation}
                  duration={el.duration}
                  specialisation={el.specialisation}
                  price={el.price}
                  process={el.process}
                  diplom={el.diplom}
                  link={{
                    type: el.link.type,
                    text: el.link.text,
                    link: el.link.link
                  }}
                  link2={
                    el.link2
                      ? {
                          type: el.link2.type,
                          text: el.link2.text,
                          link: el.link2.link
                        }
                      : null
                  }
                  space={el.space ? el.space : false}
                  isNotSpoler={el.isNotSpoler}
                />
              </Grid>
            ))
          : c.hash[hash].list.map((el, key) => (
              <Grid item xs={12} md={6}>
                <Tile
                  key={key}
                  head={el.head}
                  typeEducation={el.typeEducation}
                  duration={el.duration}
                  specialisation={el.specialisation}
                  price={el.price}
                  process={el.process}
                  diplom={el.diplom}
                  link={{
                    type: el.link.type,
                    text: el.link.text,
                    link: el.link.link
                  }}
                  link2={
                    el.link2
                      ? {
                          type: el.link2.type,
                          text: el.link2.text,
                          link: el.link2.link
                        }
                      : null
                  }
                  space={el.space ? el.space : false}
                  isNotSpoler={el.isNotSpoler}
                />
              </Grid>
            ))}
        {c.descript ? (
          <Grid item xs={12} md={12}>
            <p className={classes.p}>{c.descript}</p>
          </Grid>
        ) : null}
        {c.subMenu ? (
          <Grid item xs={12} md={12}>
            <h2
              className={classes.h2}
              style={{ textAlign: "left", paddingLeft: "2.3rem" }}
            >
              Перейти в раздел
            </h2>
            <List
              style={{
                fontSize: "1rem",
                color: "rgb(100,100,100)",
                padding: 0,
                paddingBottom: ".5rem",
                lineHeight: "2rem"
              }}
              num="none"
              arr={[
                <Link to="/pr-education">
                  <span className={classes.link}>
                    Репутационные технологии, PR
                  </span>
                </Link>,
                <Link to="/fashion-education">
                  <span className={classes.link}>
                    История моды, креативные технологии
                  </span>
                </Link>,
                <Link to="/interior-education">
                  <span className={classes.link}>
                    Дизайн интерьера, креативные технологии
                  </span>
                </Link>,
                <Link to="/fashion-retail">
                  <span className={classes.link}>
                    Fashion retail, технологии продаж
                  </span>
                </Link>
              ]}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default withStyles(wStyles)(Layout);
