import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Phones from "./phones-icons";
import { eventLinks } from "../analitics";
import { VerticalForm } from "./forms";

const styles = theme => ({
  phones: {
    textAlign: "left"
  },
  h2: {
    fontFamily: "Roboto",
    fontSize: "1.8em",
    textAlign: "center",
    fontWeight: 500,
    margin: 8,
    padding: "24px 0"
  },
  button: {
    padding: ".5rem 1rem",
    width: "max-content",
    background: "rgb(255,255,255)",
    color: "rgb(100,100,100)",
    transition: "color .5s, background .5s",
    fontSize: "1rem",
    fontFamily: "Roboto",
    margin: "0 auto",
    cursor: "pointer",
    border: "1px solid rgb(100,100,100)",
    "&:hover": {
      background: "rgb(0,0,0)",
      color: "rgb(255,255,255)"
    }
  },
  "@media (max-width: 959px)": {
    phones: {
      textAlign: "center"
    }
  }
});

function SocnetsComponent(props) {
  const { classes } = props;

  return (
    <Grid container justify="center" alignItems="center" style={{}}>
      <Grid item xs={12} md={props.fullWidth || 6} style={{ maxWidth: 500 }}>
        {props.noSpace ? null : <div style={{ height: 40 }} />}
        {props.noHead ? null : (
          <h2
            className={classes.h2}
            style={{
              fontFamily: props.fontHead || "inherit", // "Lora",
              color: props.colorHead || "rgb(100,100,100)",
              ...props.styleHead
            }}
          >
            Хотите узнать подробнее?
          </h2>
        )}
        <div style={{ margin: "0 auto", padding: 20, textAlign: "center" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: "1em",
              color: "rgb(21,101,192)",
              fontWeight: 400
            }}
          >
            {props.noMessengers ? null : (
              <>
                {/* <p>
                  <Phones
                    name="Общий чат"
                    size={20}
                    color="rgb(0,0,100)"
                    link="https://chat.whatsapp.com/DEuV6RqRKOA7J7lQcGCy5o"
                    // https://t.me/joinchat/IHm0-xQ3XczI43YpD0ZbLg
                    linkForTelegram="IHm0-xQ3XczI43YpD0ZbLg"
                  />
                </p> */}
                <p>
                  <Phones
                    size={20}
                    name="Евгения"
                    color="rgb(0,0,100)"
                    link="79160580092"
                    nicTgm="evgeniya_pimenova"
                  />
                </p>
                <p>
                  <Phones
                    size={20}
                    name="Иван"
                    color="rgb(0,0,100)"
                    link="79255067675"
                    nicTgm="gulienko"
                  />
                </p>
              </>
            )}
            {props.noForm ? null : (
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <VerticalForm />
              </div>
            )}
            {props.noMskPhone ? null : (
              <p>
                <a
                  href="tel:+74952218935"
                  style={{ color: "rgb(21,101,192)" }}
                  onClick={() =>
                    eventLinks(`click_phone_evgeniya2_${props.target}_complete`)
                  }
                >
                  +7 (495) 221-89-35
                </a>
              </p>
            )}
            <p>
              <a href="tel:+78005507893" style={{ color: "rgb(21,101,192)" }}>
                8 (800) 550-78-93
              </a>
            </p>
          </div>
        </div>
        {props.noSpace ? null : <div style={{ height: 40 }} />}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SocnetsComponent);
