import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Рожнова Жанна Сергеевна",
        family: "Рожнова",
        metaKeywords: "",
        metaDescript: "",
        head: "Рожнова Жанна Сергеевна",
        image: "/img/about/rozhnova.jpg",
        text: {
          edu: "Закончила ВШСДТ, курс «Имидж-консалтинг и fashion-styling»",
          exp: [
            "Проходила в ВШСДТ Имидж-консалдинг",
            "С 2019 г. по настоящее время, практикующий fashion стилист, преподаватель ВСШДТ",
            "Сотрудничество с крупными Российскими брендами."
          ],
          motto:
            "Но иногда на пути могут возникать препятствия и трудности, в этих ситуациях я всегда себе говорю: «Это нас огорчит, но не остановит»"
        }
      }}
    />
  );
}
