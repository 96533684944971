import React from "react";
import List from "../../components/list";
import Ig from "../../components/instagram";
import "./style.css";

export const contents = {
  meta: {
    keywords: "продвижение, бренд, одежда, digital, fashion, week, IDFW",
    descript:
      "Продвижение бренда одежды на международный рынок: что нужно знать при подготовке коллекции для показов на международных цифровых площадках, как оформить документацию к размещению на ведущей платформе International Digital Fashion Week (IDFW)"
  },
  head: {
    title:
      "Практики ВШСДТ с Евгением Стуловым. Обложка и фотосессия в журнале Malvie Magazine, август 2021 (Франция)",
    headDescript: "В портфолио стилиста",
    head: (
      <>Обложка и фотосессия в журнале Malvie Magazine, август 2021 (Франция)</>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      <>Фотосессия организована преподавателями ВШСДТ</>,
      <>Евгением Стуловым и Евгенией Мальцевой.</>,
      <>В съемках ассистентами стилиста выступили</>,
      <>выпускницы ВШСДТ Мария Стецурина и Евгения Веретина</>
    ],
    headAfterDescript: <>&emsp;</>,
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "практики_solaris_foto"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/stylistportfolio-malviemagazine-aug21/malvie.jpg",
    imageAlt: "Обложка журнала Malvie Magazine, август 2021 (Франция)",
    styleImage: {
      maxHeight: 380
    }
  },
  text: (
    <>
      <p>Евгения Мальцева:</p>
      <p className="quote-text">
        «Мне нравиться работать с девушками, особенно с Евгенией, мы работаем во
        множестве проектов и я вижу, как серьезно наши выпускницы подходят к
        проектам, ответственно выполняют работу стилиста на площадке. Отмечу их
        качества: пунктуальность, всегда на связи, всегда на площадке, всегда в
        деле, всегда помогают.
      </p>
      <p className="quote-text">
        Съемка, опубликованная в журнале, это высокая оценка работы нашей
        команды, а так же труда выпускниц ВШСДТ на все 100 баллов.
      </p>
      <p className="quote-text">
        Съемка была сложная, живые бабочки (разлетались), живые цветы (требовали
        внимания), каждая модель в руках команды стилистов ...и девушки
        максимально включились в процесс, примеряли, заменяли одежду, ловили
        (бабочек :), помогали во всем. В конце всем бонус - приятная усталость и
        удовлетворение от результата!»
      </p>
    </>
  ),
  teacherHead: "Кураторы практики / преподаватели",
  teachers: [
    {
      image: "/img/fashion-styling/stulov-st.jpg",
      name: "Евгений Стулов (Россия, Москва)",
      text: (
        <List
          arr={[
            <>
              Официальный представитель International Digital Fashion Week в
              России
            </>,
            <>Креативный директор Siberian Fashion Week</>,
            <>
              Фотограф, музыкальный продюсер, композитор, основатель и участник
              электронного проекта SOLARIS
            </>,
            <>Преподаватель курсов профессиональной переподготовки ВШСДТ</>
          ]}
        />
      ),
      instagram: "solaris_foto",
      buttons: {
        detail: false,
        video: "Fps72ns-MyI"
      }
    },
    {
      image: "/img/fashion-styling/maltseva-st.jpg",
      name: "Евгения Мальцева (Россия, Москва)",
      text: (
        <List
          arr={[
            <>Президент Siberian Fashion Week</>,
            <>CEO / Founder European Model Academy</>,
            <>Режиссер-постановщик модных показов</>,
            <>Профессиональный коуч по дисциплинам: дефиле, фотопозирование.</>,
            <>
              Автор мастер-классов для профессиональных моделей и директоров
              модельных агентств
            </>,
            <>Преподаватель курсов профессиональной переподготовки ВШСДТ</>
          ]}
        />
      ),
      instagram: "evmaltseva",
      buttons: false
    }
  ],
  slides: [
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/01.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/02.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/03.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/04.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/05.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/06.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/07.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/08.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/09.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/10.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    },
    {
      i: "/img/stylistportfolio-malviemagazine-aug21/11.jpg",
      o: 1,
      alt: "Malvie Magazine aug21"
    }
  ],
  descriptSlides: (
    <List
      arr={[
        <>
          Журнал: Malvie Magazine <Ig a="malviemag" />
        </>,
        <>Съёмка: Natural beauty</>,
        <>
          Photo: Евгений Стулов <Ig a="solaris_foto" />
        </>,
        <>
          Cr Dir: Евгения Мальцева <Ig a="evmaltseva" />
        </>,
        <>
          Stylist: Русудан Миронова <Ig a="r_mironova_style" />
        </>,
        <>
          Assistant: Мария Стецурина <Ig a="mariastetsurina" />
        </>,
        <>
          MUA: Анастасия Норкина <Ig a="muah_bynastya" />
        </>,
        <>
          Assistant: Евгения Веретина <Ig a="everetina_official" />
        </>,
        <>
          Hair: Татьяна Трубинова <Ig a="tatiana_t007" />
        </>,
        <>
          Assistant: Екатерина Жандаева <Ig a="katerina_ema_" />
        </>,
        <>
          Model: <Ig a="anna_nazarenko_official" />
        </>,
        <>
          Model: <Ig a="starygina_masha" />
        </>,
        <>
          Model: <Ig a="eva_shipunova_model" />
        </>,
        <>
          Model: <Ig a="trebinaanastasia" />
        </>,
        <>
          Model: <Ig a="danausova_margarita_official" />
        </>,
        <>
          Video: Екатерина Жандаева <Ig a="katerina_ema_" />
        </>
      ]}
      num="none"
    />
  ),
  stylistsOnPractice: {
    head: "Стилисты на практике",
    stylists: [<>Евгения Веретина</>, <>Мария Стецурина</>]
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
