import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { BuilderLayout, TitleH2 } from "../../patterns/pattern-fs/components";
import { Grid4x } from "../../components/grid4x/grid4x";
import { news } from "./content";

export default function NewsList(props) {
  return (
    <div>
      <Helmet>
        <title />
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Новости">
        <BuilderLayout
          contents={[
            <>
              <TitleH2 content="Новости" />
              <Grid4x
                content={news.sort(
                  (a, b) => b.date.getTime() - a.date.getTime()
                )}
              />
            </>
          ]}
        />
      </Page>
    </div>
  );
}
