import React from "react";

export const content = [
  {
    name: "Рожнова Жанна Сергеевна",
    text: "Практикующий fashion стилист, преподаватель ВШСТД",
    education: (
      <>
        Высшее образование. (Годы учебы 1999-2004) Вологодский педагогический
        университет по специальности «Педагог-психолог», «Специалист по
        социальной работе». Закончила в ВШСДТ Имидж-консалдинг
      </>
    ),
    instagram: "zhannarozhnova"
  },
  {
    name: "Акимова Лидия Владимировна",
    text: "Практикующий имидж-стилист, преподаватель ВШСТД",
    education: (
      <>
        Московский Медицинский коледж №30 /2002-2005/; Столичная Финансовая
        Гуманитарная академия (НОУ СГИ) факультет «Психология, педагогика и
        право» /2005- 2009/; Высшая Школа Стилистики дизайна и технологий
        /2019/. Школа Art-имидж «Имидж-стилист»; Онлайн-курс «Консультант по
        стилю»; Онлайн-курс «Колористика»; Онлайн мастер-классы Элли Хомицк
        «Формирование трендов».
      </>
    ),
    instagram: "lididilisa"
  },
  {
    name: "Кузина Татьяна Олеговна",
    text: (
      <>
        Практикующий стилист-имиджмейкер, fashion эксперт. Татьяна является
        куратором совместного проекта ВШСДТ и ТЕХНОГРАД (инновационного
        комплекса на ВДНХ, предлагающего образовательные курсы по
        государственной программе).
      </>
    ),
    education: <>В 2021 году закончила ВШСДТ</>,
    instagram: "tatianastylelife"
  },
  {
    name: "Сотникова Алина Сергеевна",
    text: (
      <>
        Практикующий имидж-стилист, стилизую индивидуальные и творческие съёмки.
        SMM- менеджер ВШСДТ, занимаюсь развитием социальных сетей школы.
      </>
    ),
    education: (
      <>
        2015-2019 — Национальный исследовательский университет «Высшая школа
        экономики» (НИУ ВШЭ), факультет «Государственное и муниципальное
        управление». Выпускница Высшей школы стилистики, дизайна и технологий,
        Имидж-консалтинг и fashion-styling.
      </>
    ),
    instagram: "alinaas.diary"
  },
  {
    name: "Баталова Татьяна Игоревна",
    text: "Выпускница ВШСДТ, практикующий стилист-имиджмейкер.",
    education: (
      <>
        Закончила курс в ВШСДТ «Консультант по стилю. Быстрый старт» в марте
        2021 г.
      </>
    ),
    instagram: "mrs.tatyanabatalova"
  }
  /*
  ,
  {
    name: "",
    text: "",
    education: "",
    instagram: ""
  },
  */
];
