import { dates } from "./dates";
import "./style.css";

export const datas = [
  /*
  {
    date: dates.imagemaker.online,
    name: "Профессия: стилист-имиджмейкер как второе высшее",
    duration: "Годовое дистанционное обучение.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/second-degree-imagemaker",
    theme: "fashion",
    d: "6+"
  },
  */
  {
    date: dates.masterClasses.bFashionSprint,
    name:
      "Международный вебинар: 7 шагов к построению и запуску успешного Fashion бизнеса",
    duration: "вебинар",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/master-class"
  },
  {
    date: dates.imagemaker.ofline,
    name: "Профессия: стилист-имиджмейкер как второе высшее",
    duration: "Годовое очное/дистанционное обучение.",
    start: "", // "Вводное занятие",
    isOnline: 0,
    page: "/second-degree-imagemaker",
    theme: "fashion",
    d: "6+"
  },
  // Визуальный мерчендайзинг и витринистика
  {
    date: dates.visualMerchandising.online,
    name: "Визуальный мерчендайзинг и витринистика",
    duration: "Дистанционное обучение, 6 месяцев",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/visual-merchandising-and-showcases-design",
    theme: "fashion",
    d: "56"
  },
  {
    date: dates.baseImageStyle.online,
    name: "Стили одежды: правила подбора для женщин",
    duration: "Дистанционное обучение, 4 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/image-style",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.baseImageStyle.ofline,
    name: "Стили одежды: правила подбора для женщин",
    duration: "Очное обучение, 4 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/image-style",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.trendsOfModernFashion.online,
    name: "Главные стратегии современной моды",
    duration: "Дистанционное обучение, 6 месяцев.",
    start: false, // "Вводное занятие",
    isOnline: 1,
    page: "/trends-of-modern-fashion",
    theme: "fashion",
    d: "56"
  },
  //Консультант по стилю и дизайну интерьера
  {
    date: dates.interiorYourLife.online,
    name: "Интерьерный стилист",
    duration: "Дистанционное обучение, 3.5 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/interior-your-life",
    theme: "interior",
    d: "34"
  },
  {
    date: dates.interiorYourLife.ofline,
    name: "Интерьерный стилист",
    duration: "Очное обучение, 3.5 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 0,
    page: "/interior-your-life",
    theme: "interior",
    d: "34"
  },
  {
    date: dates.designInterior.online,
    name: "Профессия: дизайнер интерьера",
    duration: "Дистанционное обучение, 9 месяцев.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/living-interior-design-and-decoration",
    theme: "interior",
    d: "6+"
  },
  {
    date: dates.politicalStyle.online,
    name: "Формирование имиджа лидера. Инструменты политтехнологий",
    duration: "Дистанционное обучение, 2 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/political-style",
    theme: "reputation",
    d: "12"
  },
  {
    date: dates.politicalStyle.absentia,
    name: "Формирование имиджа лидера. Инструменты политтехнологий",
    duration: "Очное обучение, 2 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 0,
    page: "/political-style",
    theme: "reputation",
    d: "12"
  },
  {
    date: dates.youngFashion.online,
    name: "Школа юных стилистов и дизайнеров",
    duration: "Онлайн обучение, 8 месяцев.",
    start: "Вводное занятие",
    isOnline: 1,
    page: "/young-fashion",
    theme: "fashion",
    d: "6+"
  },
  {
    date: dates.youngFashion.online2,
    name: "Школа юных стилистов и дизайнеров",
    duration: "Очное обучение, открытые занятия. Тема: теория цвета",
    start: "",
    isOnline: 1,
    page: "/young-fashion",
    theme: "fashion",
    d: "6+"
  },
  {
    date: dates.selfStyle.online,
    name: "Сам себе стилист",
    duration: "Практикум-преображение, 1 месяц.",
    start: false,
    isOnline: 1,
    page: "/self-style",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.selfStyle.ofline,
    name: "Сам себе стилист",
    duration: "Очно, 1 месяц.",
    start: false,
    isOnline: 0,
    page: "/self-style",
    theme: "fashion",
    d: "12"
  },

  {
    date: dates.styleYourLife.online,
    name: "Консультант по стилю. Быстрый старт",
    duration: "Онлайн обучение + очная практика, 3,5 месяца",
    start: "",
    isOnline: 1,
    page: "/style-your-life",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.styleYourLife.ofline,
    name: "Консультант по стилю",
    duration: "Дистанционное/заочное обучение, 3.5 месяца.",
    start: "", // "Вводное занятие",
    isOnline: 0,
    page: "/style-your-life",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.style4stylist.online,
    name: "Стиль для стилиста",
    duration: "Практикум, 20 часов.",
    start: "", // "Вводное занятие",
    isOnline: 1,
    page: "/style4stylist",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.fashionStyling.online,
    name: "Профессия: стилист фотоссесий",
    duration: "Дистанционно/очно, 3.5 месяца.",
    start: "",
    isOnline: 1,
    page: "/fashion-styling",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.fashionStyling.ofline,
    name: "Профессия: стилист фотоссесий",
    duration: "Очное обучение, 3.5 месяца.",
    start: "",
    isOnline: 0,
    page: "/fashion-styling",
    theme: "fashion",
    d: "34"
  },
  {
    date: dates.practicImageConsulting1.start,
    name: "Практика имидж-консультирования #1",
    duration: "Очно. 5 дней с 10:00 до 17:30",
    start: "",
    isOnline: 0,
    page: "/practice-image-consulting-1",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.practicImageConsulting2.start,
    name: "Практика имидж-консультирования #2",
    duration: "Очно. 5 дней с 10:00 до 17:30",
    start: "",
    isOnline: 0,
    page: "/practice-image-consulting-2",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.proMotionDesign.online,
    name: "Основы motion design и анимации",
    duration: "Дистанционно. 1 месяц",
    start: "",
    isOnline: 1,
    page: "/pro-motion-design",
    theme: "it",
    d: "12"
  },
  {
    date: dates.businessAnalitic.online,
    name: "Бизнес аналитик",
    duration: "Дистанционно. 2 месяца",
    start: "",
    isOnline: 1,
    page: "/business-analitic",
    theme: "it",
    d: "12"
  },
  {
    date: dates.tbilisiFashionWeekend.start,
    name: "Fashion-каникулы в Тбилиси",
    duration: "Очно. 6 дней",
    start: "",
    isOnline: 0,
    page: "/tbilisi-fashion-weekend",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.chatbot.online,
    name: "Профессия: Дизайнер чат-ботов с искусственным интеллектом",
    duration: "Онлайн. 2 месяца",
    start: "",
    isOnline: 1,
    page: "/chatbot",
    theme: "it",
    d: "12"
  },
  {
    date: dates.idfw.online2,
    name: "International Digital Fashion Week как заявить о себе на весь мир",
    duration: "Онлайн. 1 месяца",
    start: "",
    isOnline: 1,
    page: "/idfw",
    theme: "clothes",
    d: "12"
  },
  {
    date: dates.idfw.online,
    name: "International Digital Fashion Week как заявить о себе на весь мир",
    duration: "Онлайн. 1 месяца",
    start: "",
    isOnline: 1,
    page: "/idfw",
    theme: "clothes",
    d: "12"
  },
  {
    date: dates.imageCommunication.online,
    name: "Имидж-коммуникация как часть личного PR и продвижения",
    duration: "Онлайн. 1 месяц",
    start: "",
    isOnline: 1,
    page: "/image-communication",
    theme: "reputation",
    d: "12"
  },
  {
    date: dates.imageCommunication.online2,
    name: "Имидж-коммуникация как часть личного PR и продвижения",
    duration: "Онлайн. 1 месяц",
    start: "",
    isOnline: 1,
    page: "/image-communication",
    theme: "reputation",
    d: "12"
  },
  {
    date: dates.imageCommunication.ofline,
    name: "Имидж-коммуникация как часть личного PR и продвижения",
    duration: "Очно. 1 месяц",
    start: "",
    isOnline: 0,
    page: "/image-communication",
    theme: "reputation",
    d: "12"
  },
  {
    date: dates.aibotProductowner.online,
    name:
      "Управление разработкой диалоговых систем с ИИ, уровень product owner",
    duration: "Онлайн, 6 месяцев",
    start: "",
    isOnline: 1,
    page: "/aibot-productowner",
    theme: "it",
    d: "56"
  },
  {
    date: dates.styleConsultantTGU.online,
    name: "Консультант по стилю. Бесплатное обучение",
    duration: "Онлайн, 2.5 месяца",
    start: "",
    isOnline: 1,
    page: "/style-consultant-tgu",
    theme: "interior",
    d: "12"
  },

  {
    date: dates.styleConsultantTGU.online2,
    name: "Консультант по стилю. Бесплатное обучение",
    duration: "Онлайн, 2.5 месяца",
    start: "",
    isOnline: 1,
    page: "/style-consultant-tgu",
    theme: "interior",
    d: "12"
  },
  {
    date: dates.interiorStylistTGU.online,
    name: "Интерьерный стилист. Бесплатное обучение",
    duration: "Онлайн, 2.5 месяца",
    start: "",
    isOnline: 1,
    page: "/interior-stylist-tgu",
    theme: "interior",
    d: "12"
  },
  {
    date: dates.interiorStylistTGU.online2,
    name: "Интерьерный стилист. Бесплатное обучение",
    duration: "Онлайн, 2.5 месяца",
    start: "",
    isOnline: 1,
    page: "/interior-stylist-tgu",
    theme: "interior",
    d: "12"
  },
  {
    date: dates.interiorDesign.online,
    name: "Сам себе дизайнер. Стилистика интерьера",
    duration: "Дистанционно, 1 месяц",
    start: "",
    isOnline: 1,
    page: "/interior-design",
    theme: "interior",
    d: "12"
  },
  {
    date: dates.selfStyle.online2,
    name: "Сам себе стилист",
    duration: "Дистанционно, 1 месяц",
    start: "",
    isOnline: 1,
    page: "/self-style",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.psychologyOfColor.online,
    name: "Психология цвета",
    duration: "Видео курс, 15 лекций, 8 акад. часов",
    start: "",
    isOnline: 1,
    page: "/psychology-of-color",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.masterClasses.olgaMaltseva,
    name:
      "Влияние модных тенденций на выбор одежды для деловых коммуникаций. Психология восприятия образа.",
    duration: "Вебинар",
    start: "",
    isOnline: 1,
    page: "/master-class",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.buyer,
    name: "Менеджмент в индустрии моды. Профессия: Байер",
    duration: "Очно / онлайн. 8 месяцев",
    start: "",
    isOnline: 1,
    page: "/buyer",
    theme: "fashion",
    d: "6+"
  },
  {
    date: dates.fashionHistory.ofline,
    name: "История моды с Русланом Миграновым ",
    duration: "Очно",
    start: "",
    isOnline: 0,
    page: "/fashion-history",
    theme: "fashion",
    d: "12"
  },
  {
    date: dates.fashionHistory.online,
    name: "История моды с Русланом Миграновым ",
    duration: "Онлайн",
    start: "",
    isOnline: 1,
    page: "/fashion-history",
    theme: "fashion",
    d: "12"
  }
  /*
  ,
  {
    date: dates.,
    name: "",
    duration: "",
    start: "",
    isOnline: 1,
    page: "/",
    theme: "",
    d: "",
  },
  */
];
