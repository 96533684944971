import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./content-new";
import Page from "../../components/page";
import Papers from "../../components/papers/papers";
import { MiniForm } from "../../components/forms";
import { HeadBlock } from "../../components/head-block/head-block";
import {
  BuilderLayout,
  TextAndTags,
  Programma,
  Teachers,
  HowIsTheTrainingGoing,
  Text3Block,
  ShessBlock,
  TitleH2
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>{c.titlePage}</title>
        <meta name="keywords" content={"ВШСДТ: " + c.titlePage} />
      </Helmet>
      <Page lable="Консультант по стилю">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.headNew} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content="Доступная мода" />
              <ShessBlock
                content={{
                  left: c.screen2.video,
                  right: c.screen2.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <TextAndTags content={c.text} />,
            <>
              <TitleH2 content="Как проходит обучение" />
              <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            </>,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,

            <>
              <TitleH2 content="Диплом после обучения" scroll="diplom" />
              {/* <Rgsu content={c.rgsu} noHead={true} /> */}
              <ShessBlock
                content={{
                  left: c.rgsu.text,
                  right: c.rgsu.image
                }}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content="Профессиональное применение" />
              <Text3Block content={c.text3blockWImgs} />
            </>,
            <>
              <TitleH2 content="Рекомендуем выпускников курса" />
              <ShessBlock
                content={{
                  left: c.graduates.image,
                  right: c.graduates.text
                }}
                mediaLeft={true}
                mobileMediaUp={true}
                // vAlign="center"
              />
            </>,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            <>
              <TitleH2 content="Варианты обучения, стоимость" scroll="price" />
              {/* <PriceTile content={c.pay} /> */}
              <Papers content={c.pay} />
            </>,
            // <SocnetBlock />
            <>
              <TitleH2 content={c.miniFormHead} />
              <MiniForm formName="miniforma-self-style" />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
