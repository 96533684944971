import React from "react";
import Page from "../../components/page";
import Helmet from "react-helmet";
import Layout from "./layout";
import { content } from "./content";

function Thanks(props) {
  return (
    <div>
      <Helmet>
        <title>{content.head.head}</title>
        <meta name="keywords" content={"ВШСДТ: " + content.head.head} />
      </Helmet>
      <Page lable={content.head.lable} lableMobile={content.head.lable}>
        <Layout contents={content} />
      </Page>
    </div>
  );
}

export default Thanks;
