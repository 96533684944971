import React from "react";
import { Grid } from "@material-ui/core";
import Tile from "./tile";

export default function Process() {
  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={12} md={12}>
        <h2
          style={{
            fontSize: "1.8em",
            color: "rgb(100,100,100)",
            fontWeight: 700,
            textAlign: "center",
            margin: 0,
            padding: "24px 0"
          }}
        >
          КАК УСТРОЕН ОБРАЗОВАТЕЛЬНЫЙ ПРОЦЕСС
        </h2>
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: 20 }}>
        <Tile
          head="ДОСТУП К УЧЕБНОМУ САЙТУ"
          text="каждый студент имеет доступ к личному кабинету, в котором есть вся необходимая информация для планирования обучения"
          image={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="48"
              height="48"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgb(123,143,87)"
                d="M19,2L14,6.5V17.5L19,13V2M6.5,5C4.55,5 2.45,5.4 1,6.5V21.16C1,21.41 1.25,21.66 1.5,21.66C1.6,21.66 1.65,21.59 1.75,21.59C3.1,20.94 5.05,20.5 6.5,20.5C8.45,20.5 10.55,20.9 12,22C13.35,21.15 15.8,20.5 17.5,20.5C19.15,20.5 20.85,20.81 22.25,21.56C22.35,21.61 22.4,21.59 22.5,21.59C22.75,21.59 23,21.34 23,21.09V6.5C22.4,6.05 21.75,5.75 21,5.5V7.5L21,13V19C19.9,18.65 18.7,18.5 17.5,18.5C15.8,18.5 13.35,19.15 12,20V13L12,8.5V6.5C10.55,5.4 8.45,5 6.5,5V5Z"
              />
            </svg>
          }
        />
        <div style={{ height: 24 }} />
        <Tile
          head="ЗАЧЕТЫ И ЭКЗАМЕНЫ"
          text="сдаются в он-лайн формате в виде практических заданий, промежуточных тестов и итогового экзамена (для программ профессиональной переподготовки)"
          image={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="48"
              height="48"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgb(123,143,87)"
                d="M6,2C4.9,2 4,2.9 4,4V20C4,21.1 4.9,22 6,22H18C19.1,22 20,21.1 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z"
              />
            </svg>
          }
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: 20 }}>
        <Tile
          image={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="48"
              height="48"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgb(123,143,87)"
                d="M21,16V4H3V16H21M21,2C22.1,2 23,2.9 23,4V16C23,17.1 22.1,18 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
              />
            </svg>
          }
          head="ИЗУЧЕНИЕ МАТЕРИАЛА"
          text="с помощью видеолекций, записанных каждым преподавателем в соответствии с необходимыми и достаточными часами для освоения дисциплины и с возможностью посещать лекции и практические занятия в он-лайн формате в установленные дни и время"
          style={{ position: "sticky", top: 1 }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: 20 }}>
        <Tile
          image={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="48"
              height="48"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgb(123,143,87)"
                d="M15,4V11H5.17L4,12.17V4H15M16,2H3C2.45,2 2,2.45 2,3V17L6,13H16C16.55,13 17,12.55 17,12V3C17,2.45 16.55,2 16,2M21,6H19V15H6V17C6,17.55 6.45,18 7,18H18L22,22V7C22,6.45 21.55,6 21,6Z"
              />
            </svg>
          }
          head="ОБЩЕНИЕ С ПРЕПОДАВАТЕЛЯМИ"
          text="осуществляется через форум, а также посредством практических вебинаров"
        />
        <div style={{ height: 24 }} />
        <Tile
          image={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="48"
              height="48"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgb(123,143,87)"
                d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M12,8C9.79,8 8,9.79 8,12C8,14.21 9.79,16 12,16C14.21,16 16,14.21 16,12C16,9.79 14.21,8 12,8Z"
              />
            </svg>
          }
          head="ПОДДЕРЖКА СПЕЦИАЛИСТА УМО"
          text="наполнение семестров необходимыми учебными материалами, контроль вебинаров и исполнения расписания, ответы на вопросы, связанные с учебными процессами сайта дистанционного обучения"
        />
      </Grid>
    </Grid>
  );
}
