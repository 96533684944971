import React from "react";
import Banner, { BannerBadge } from "./banner";

export default function Component(props) {
  const text = false;
  const slide = (
    <BannerBadge>
      <img
        src="/img/calendar.svg"
        alt=""
        style={{ height: 16, marginBottom: -3 }}
      />{" "}
      1&nbsp;мес.
    </BannerBadge>
  );

  return (
    <Banner
      head={
        <span>
          Имидж-коммуникация как часть
          <br />
          личного PR и продвижения
        </span>
      }
      bkgImage="url(/img/image-communication/bkg.jpg)"
      bkgHead="rgba(209,232,232,1.0)"
      content={text}
      slide={slide}
      link="/image-communication"
    />
  );
}
