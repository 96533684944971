import React from "react";
import Card from "../card";

export default function TheCard(props) {
  return (
    <Card
      head="Мода и стиль"
      title="Карта стилей"
      teacher="Инесса Трубецкова"
      descript={<span>Мастер-класс, очно,</span>}
      onlineText="Мастер-класс, очно"
      date="20, 21 апреля"
      time="10:00-16:30"
      duration="2 дня"
      image="/img/modern-fashion-style/stylemap.jpg"
      imageHeight
      text={
        <span>
          Исторические предпосылки для формирования современных стилей,
          тенденций и субкультур. Исторические корни и основные постулаты в
          развитии классического, романтического, драматического и спортивного
          течений. Классификация современных стилей и субкультур на основе
          исторических стилевых направлений. Эволюция стилей и ее отражение на
          карте стилей – рабочий инструмент для работы стилиста. Принципы
          сочетаний близких по духу и противоположных стилей, особенности
          каждого из 30 стилей.
        </span>
      }
      price="6 900"
      crown
      id="FT6g7RdQNwjGQuXfv"
      setBillingId={props.setBillingId}
    />
  );
}
