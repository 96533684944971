import React from "react";
import Helmet from "react-helmet";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { contents } from "./contents";
import Page from "../../components/page";
import { PriceNoQR as Price } from "../../components/price-with-action-test/price";
import { MiniForm } from "../../components/forms";
import Banners from "./banners";
import {
  BuilderLayout,
  HeadBlock,
  TitleH2,
  DatesBlock,
  Programma,
  Rgsu,
  Teachers,
  HowIsTheTrainingGoing,
  ShessBlock,
  Text3Block,
  SpaceElement,
  TextAndTags
} from "../../patterns/pattern-fs/components";

const InteriorYourLife = props => {
  return (
    <div>
      <Helmet>
        <title>Дизайнер интерьера</title>
        <meta
          name="keywords"
          content="стилист, декоратор, курсы, обучение, школа, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Интерьерный стилист — актуально о самом важном для реализации идей в интерьере квартиры или загородного дома. Получения навыка составления Design Project folder и реализации своих идей в дизайне интерьера. После обучения Вы научитесь получать дополнительный доход от консультирования по стилю и декорированию интерьера."
        />
      </Helmet>
      <Page lable="Стиль в интерьере">
        <BuilderLayout
          contents={[
            <HeadBlock
              content={{ ...contents.head, videos: contents.videos }}
            />,
            <Text3Block content={contents.text3block} />,
            <DatesBlock content={contents.screen2} scrolling="continue" />,
            <>
              <TitleH2
                content="Записаться на пробные занятия"
                style={{ paddingTop: 0 }}
              />
              <MiniForm formName="miniform-interior-your-life" />
            </>,
            <Programma content={{ programma: contents.programma }} />,
            <HowIsTheTrainingGoing
              content={contents.howIsTheTrainingGoing}
              head="В процессе обучения"
            />,
            <Rgsu content={contents.rgsu} />,
            <Teachers content={{ teachers: contents.teachers }} />,
            <ShessBlock content={contents.shessBlcok} />,
            // <>
            //   <TitleH2 content="Стоимость обучения" scroll="price" />
            //   <Price content={contents.price} />
            // </>,
            <>
              <TitleH2 content={contents.miniFormHead} />
              <MiniForm formName="miniforma-interior-your-life" />
            </>,
            <>
              <TitleH2 content="Открытое образование" scroll="freeeducation" />
              <ShessBlock
                content={{
                  left: contents.bot.image,
                  right: contents.bot.text
                }}
                mediaLeft={true}
              />
              <div style={{ height: "4rem" }} />
              <ShessBlock
                content={{
                  left: contents.text1,
                  right: contents.bannerTgu
                }}
                allWhite
              />
              ,
              <Link to="/openedu">
                <div className="button">
                  Смотреть все курсы открытого образования
                </div>
              </Link>
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
              <SpaceElement />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default InteriorYourLife;
