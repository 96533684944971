import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>ВШСДТ — Смоленск</title>
        <meta
          name="descript"
          content="Курсы имиджмейкеров, стилистов, дизайнеров в Смоленске проводят специалисты Высшей школы стилистики, дизайна и технологий как в очном формате, так и в дистанционном формате. Здесь Вы получите необходимые знания и навыки для реализации своих возможностей в бизнесе, социальной сфере, для воплощения своих идей, развития карьеры и реализации проектов."
        />
        <meta
          name="keywords"
          content="Смоленск, стилист, имиджмейкер, шопер, курсы, обучение, школа, имидж, стиль"
        />
      </Helmet>
      <PageGlobal lable="Смоленск" filial="Смоленск">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
