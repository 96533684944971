import React from "react";
import List from "../../components/list";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import {
  shabalina3,
  bonetskaya,
  moatti,
  gorskih3
} from "../../contents/teachers";
import Link from "react-router-dom/Link";

const date1 = dates.styleConsultantTGU.online;
const date2 = dates.styleConsultantTGU.online2;

const styles = {
  h1: {
    fontFamily: "Roboto",
    fontSize: "2rem",
    fontWeight: 700,
    color: "rgb(80,80,80)",
    textAlign: "center",
    margin: 0,
    padding: "2rem 0",
    paddingTop: 0
  },
  h2: {
    fontFamily: "Roboto",
    fontSize: "1.6rem",
    fontWeight: 700,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: "1.6rem 0 1.4rem 0"
  },
  h3Big: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    fontWeight: 700,
    color: "rgb(123,143,87)",
    textAlign: "center",
    margin: 0,
    padding: "1em 0"
  },
  h3: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 700,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: "1em 0"
  },
  p: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pJust: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "justify",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pCenter: {
    fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingBottom: "0.5em",
    lineHeight: "1.5em"
  },
  pIll: {
    fontFamily: "Roboto",
    fontSize: "0.8em",
    fontWeight: 400,
    color: "rgb(100,100,100)",
    textAlign: "center",
    margin: 0,
    padding: 0,
    lineHeight: "1.2em"
  },
  image: {
    maxHeight: 350
  },
  imageBox: {
    textAlign: "center"
  }
};

export const contents = {
  titlePage: "Консультант по стилю. Бесплатное обучение",
  headNew: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Консультант по стилю
        <br />
        Бесплатное обучение
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },

    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Интенсивное погружение в профессию: коммуникация, креативное мышление,
        владение актуальными трендами, осознанное использование имидж-технологий
        в зависимости от запроса клиент, содействие в занятости
      </div>
    ),
    descript: [
      <>Онлайн обучение</>,
      <>Удостоверение о повышении квалификации</>,
      <>
        Обучение проводиться на базе НИ ТГУ в рамках федерального проекта
        «Содействие занятости»
      </>,
      <>до 2.5 месяцев, 44 занятия + защита проекта</>,
      <>Живое общение с преподавателями</>
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#singup",
        text: "записаться"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      }
    ],
    imageSource: "/img/style-consultant-tgu/cover.png"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        <>
          Онлайн с {date1.getDate()} {Month(date1.getMonth())}
        </>,
        <>
          Онлайн с {date2.getDate()} {Month(date2.getMonth())}
        </>
      ]
    },
    {
      title: "Формат",
      text: [<>Живые вебинары,</>, <>чат в группе</>]
    },
    {
      title: "Эксперты",
      text: [
        <>Российские и зарубежные практики —</>,
        <>стилисты, fashion эксперты</>
      ]
    }
  ],
  text: {
    text: (
      <>
        <p className="constructor-p">
          Цель курса «Консультант по стилю» — дать необходимую систему и навыки
          для создания индивидуального стиля клиентов, научить принципам
          создания имиджевого фото контента и продвижения личного бренда в
          социальных сетях.
        </p>
        <p className="constructor-p">
          Каждой теме уделяется необходимое количество времени, чтобы закрепить
          навыки и получить результат.
        </p>
        <p className="constructor-p">
          Программа обучения рассчитана на 3,5 месяца
        </p>
      </>
    ),
    tags: false
  },
  screen2: {
    text: (
      <List
        arr={[
          <>
            Национальный исследовательский Томский государственный университет
            является оператором Федерального проекта «Содействие занятости» и
            приглашает Вас принять участие в проекте, в рамках которого Вы
            можете получить допол&shy;ни&shy;тель&shy;ное профес&shy;сиональное
            обра&shy;зова&shy;ние по одной из более 50 про&shy;грамм, повысить
            свою востре&shy;бован&shy;ность на рынке труда, пере&shy;обучиться
            на новую специаль&shy;ность, а также полу&shy;чить помощь с
            трудо&shy;устрой&shy;ством в Центре карьеры Феде&shy;раль&shy;ного
            опера&shy;тора НИ ТГУ.
          </>,
          <>
            Проект осуществляется в рамках национальной программы «Демография» с
            2021 по 2024 гг. (включительно) и реализуется при поддержке
            Министерства труда и социальной защиты РФ, Мини&shy;стер&shy;ства
            науки и высшего обра&shy;зова&shy;ния РФ и Министер&shy;ства
            просве&shy;щения РФ.
          </>
        ]}
        num="none"
        // style={{ paddingBottom: ".3rem" }}
        classLi="p-constructor"
        olStyle={{ paddingLeft: 0 }}
      />
    ),
    // video: <Video link="fOZzI3bKXIU" />
    image: (
      <img
        src="/img/style-consultant-tgu/tgu.jpg"
        alt="Томский государственный университет"
      />
    )
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Программа курса повышения квалификации «Консультант по стилю»
        разработана на основе программы профессиональной переподготовки{" "}
        <Link to="/style-your-life">«Консультант по стилю. Быстрый старт»</Link>
      </p>,
      <p>
        <strong>Программа направлена на формирование компетенций:</strong>
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <>
            консультанта по имиджу и стилю, оказывающего услуги частным
            клиентам;
          </>,
          <>
            стилиста-консультанта для онлайн проектов: онлайн магазинов и
            тематических порталов;
          </>,
          <>
            продавца-стилиста и консультанта по клиентскому сервису на
            предприятиях индустрии моды;
          </>,
          <>
            консультанта по модным трендам для дизайнеров, предприятий торговли,
            СМИ;
          </>
        ]}
      />,
      <p>
        <strong>
          В результате обучения выпускник программы будет способен:
        </strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            создавать индивидуальный стиль клиента в соответствии с
            fashion-трендами и индивидуальными особенностями внешности;
          </>,
          <>
            выявлять потребности клиентов, консультировать клиентов с учетом
            личных и профессиональных задач на основе принципов эффективной
            коммуникации;
          </>,
          <>
            разбираться в методиках и подходах создания современного стильного
            образа, применять принцип вариативности;
          </>,
          <>
            анализировать fashion-тренды, визуальные коды костюма, стилевое
            разнообразие и уметь применять их в условиях локального рынка;
          </>,
          <>
            знать элементы стильного образа, уметь объединять их в структуру в
            соответствии с идеей образа;
          </>,
          <>
            оформлять рекомендации дял клиентов в формате презентации или
            персонального Style Book
          </>
        ]}
      />,
      <p>
        <strong>В программе</strong>
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Методики определения индивидуальной цветовой палитры</>,
          <>Методики типирования фигур и их визуальной коррекции</>,
          <>Профессиональная терминология и материаловедение</>,
          <>Методики работы с основными группами стилей в одежде</>,
          <>Модные тренды и модный образ. Мода как коммуникация</>,
          <>Профессиональный стиль</>,
          <>Формирование рационального гардероба</>,
          <>Презентация концепция стиля: оформление клиентского Style Book</>
        ]}
      />
    ]
  },
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неде­лю Вы смот&shy;рите онлайн лекции. Лекции доступны по мере
          про&shy;­хож&shy;­дения груп­&shy;пой курса. Обу&shy;че­ние
          дви&shy;­жет&shy;ся в интен&shy;сив&shy;ном ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние
          за&shy;да&shy;ния. Это важная часть обу&shy;че&shy;ния, в
          резуль&shy;тате кото&shy;рой форми&shy;руется итого&shy;вая
          проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;по&shy;да&shy;­вате&shy;лем. Препо&shy;­да&shy;ва&shy;­тель
          лично прове&shy;­ряет зада&shy;­ния, находит ошибки,
          ком­мен&shy;­тирует и помо­гает
        </>
      )
    },
    {
      title: "Практика + Книга стиля",
      text: (
        <>
          В итоге Вы созда­­ете проект персо­наль­ного стиля (Книгу стиля —
          stylebook), про&shy;во­­дите шо­пинг, подби&shy;раете парт&shy;неров и
          рабо&shy;тода&shy;телей
        </>
      )
    }
  ],
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={<>Форма обучения: онлайн</>}
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Альтернативные программы: 4 варианта</>}
        text={
          <List
            arr={[
              <>
                <Link to="/second-degree-imagemaker">
                  Имидж-консалтинг и fashion-styling
                </Link>{" "}
                (диплом), 1 год
              </>,
              <>
                <Link to="/style-your-life">
                  Консультант по стилю. Быстрый старт
                </Link>{" "}
                (диплом), 3.5 месяца
              </>,
              <>
                <Link to="/fashion-styling">Профессия: стилист фотосессий</Link>{" "}
                (повышение квалификации), 4 месяца
              </>,
              <>
                <Link to="/self-style">Сам себе стилист</Link> (базовый курс), 1
                месяц
              </>
            ]}
          />
        }
      />
    ]
  },
  bot: {
    image: "/img/imagemaker-stylist/chat-bot-ill.jpg",
    text: (
      <>
        <p className="p-constructor">
          <strong>Открытое обра­зо­ва­ние ВШСДТ</strong>
        </p>
        <List
          arr={[
            "Бот Валерия подбирает контент в зависимости от предпочтений",
            "Регулярно открывает доступ к онлайн лекциям и курсам",
            "Без оплат и условий. Общается вежливо. Как начать?",
            "Подпишитесь, нажмите «старт» и выбирайте интересующие темы"
          ]}
          num="none"
          olStyle={{ marginLeft: 0, paddingLeft: 0 }}
          classLi="p-constructor"
        />
        <a href="https://t.me/imagemakerpro_bot">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  textNote: (
    <>
      <p className="p-constructor">
        Подробнее о проекте «Содействие занятости» и Информацию по категориям
        слушателей, которым доступно обучение, смотрите на{" "}
        <a
          href="https://trudvsem.ru/information-pages/support-employment"
          target="_blank"
        >
          trudvsem.ru
        </a>
      </p>
      <p className="p-constructor">
        Для предварительной регистрации подпишитесь на группу Открытого
        образования в Телеграмм
      </p>
    </>
  ),
  miniFormHead: "Записаться на обучение",
  teachers: [gorskih3, shabalina3, moatti, bonetskaya]
};
