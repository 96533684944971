import React from "react";
import "./style.css";

export function Head1(props) {
  return (
    <div className="head-container">
      <h1>{props.content}</h1>
    </div>
  );
}

export function Head1Text(props) {
  const c = props.content;
  return (
    <div className="head-container">
      <h1>{c.title}</h1>
      <p>{c.text}</p>
    </div>
  );
}
