import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Ig from "../../components/instagram";
import List from "../../components/list";
import { Slides } from "../about/photosessions-list";
import {
  BuilderLayout,
  TextAndTags,
  TitleH1,
  TitleH2
} from "../../patterns/pattern-fs/components";
import Paragraph from "../../components/p-array";

export default function Photosession(props) {
  return (
    <>
      <Helmet>
        <title>Фотосессия выпускницы Лейсан Каримовой</title>
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Фотосессия">
        <BuilderLayout
          contents={[
            <TitleH1 content="Фотосессия выпускницы Лейсан Каримовой" />,
            <>
              <TitleH2 content="Фотосессия выпускницы Лейсан Каримовой была итоговой работой к учебному блоку Евгения Стулова, который был посвящен fashion фотографии." />
              <TextAndTags
                content={{
                  text: (
                    <>
                      <Paragraph
                        content={[
                          <>Лейсан Каримова:</>,
                          <>
                            «
                            <i>
                              Идея фотосессии Le Vent Les Portera (Ветер их
                              развеет) — прощание со старым оставленным домом по
                              соседству, который будут сносить. Этот дом
                              является символом постоянства, хранителем тайн, и
                              теперь, когда его сносят, с ним уходит часть
                              нашего прошлого.
                            </i>
                          </>,
                          <>
                            <i>
                              Фотосессия была полезна и тем, что позволила мне
                              попробовать себя в качестве не только стилиста, но
                              и продюсера, визажиста-парикмахера, а также
                              модели. Фотографом выступил мой муж, не
                              профессиональный фотограф. Несмотря на все
                              трудности, фотосессия прошла отлично!
                            </i>
                            »
                          </>
                        ]}
                        classElement="p-constructor"
                      />
                    </>
                  ),
                  tages: false
                }}
              />
            </>,
            <Slides
              images={[
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-00.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                },
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-01.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                },
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-02.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                },
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-03.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                },
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-04.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                },
                {
                  i: "/img/stylistportfolio-leysan-karimova/slide-05.jpg",
                  o: 1,
                  alt: "Фотосессия выпускницы Лейсан Каримовой"
                }
              ]}
              infinity
              white
              descript={
                <List
                  arr={[
                    <>Photo: Pauli Salmi</>,
                    <>
                      Photosession idea: Лейсан Каримова{" "}
                      <Ig a="leysan.karimova" />
                    </>,
                    <>
                      Stylist & hairstylist & makeup: Лейсан Каримова{" "}
                      <Ig a="leysan.karimova" />
                    </>,
                    <>
                      Model: Лейсан Каримова <Ig a="leysan.karimova" />
                    </>
                  ]}
                  num="none"
                  olStyle={{
                    paddingLeft: 0,
                    marginLeft: 0
                  }}
                />
              }
            />
          ]}
        />
      </Page>
    </>
  );
}
