import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Person from "../../components/persones2";
import { content } from "./content";
import { styles } from "./styles";
import Block from "./block";
import { Persons } from "../../components/person/person";
import { graduates as graduatesTop } from "../career-test/graduates";
import { TileAndSpoler } from "../../components/tile-and-spoler/tiles-and-spoler";
import { graduates } from "./other-graduates";
import { ScrollingLink } from "../../patterns/pattern-fs/components";

export default function Teachers() {
  return (
    <div>
      <Helmet>
        <title>Выпускники</title>
        <meta name="keywords" content="ВШС: Выпускники" />
      </Helmet>
      <Page lable="Отзывы" lableMobile="Отзывы">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12}>
            <TileAndSpoler
              short={{
                image: "/img/root-cards/group2.jpg",
                text: "Выпускники"
              }}
              sideImage={"right"}
              content={
                <Persons
                  head="Лучшие выпускники"
                  content={graduatesTop}
                  link={["Другие выпускники", "#other-graduates"]}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Block title={content.review.head} text={content.review.text} />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <ScrollingLink scrollLink="other-graduates" />
            <h2 style={styles.h2}>Другие выпускники</h2>
            <Person teachers={graduates} style={{}} />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
