import React from "react";
import { Grid } from "@material-ui/core";
import Link from "react-router-dom/Link";
import "./style.css";
import { DateToString } from "../../pages/shedule/dates";

export function TileGrid(props) {
  const c = props.content;
  return (
    <div className="g4x-tile-container">
      {c.link ? (
        <a href={c.link} target="_blank">
          <img src={c.image} alt="" style={{ maxWidth: 320, height: "auto" }} />
        </a>
      ) : (
        <Link to={`/news/${c.id}`}>
          <img src={c.image} alt="" style={{ maxWidth: 320, height: "auto" }} />
        </Link>
      )}
      {props.dateShow && <p className="g4x-date">{DateToString(c.date)}</p>}
      {c.link ? (
        <a href={c.link} target="_blank">
          <p className="g4x-title-link">{c.title}</p>
        </a>
      ) : (
        <Link to={`/news/${c.id}`}>
          <p className="g4x-title-link">{c.title}</p>
        </Link>
      )}
      <p className="g4x-gray-text">
        {c.grayText}{" "}
        {c.link ? (
          <a href={c.link} target="_blank">
            Подробнее…
          </a>
        ) : (
          <Link to={`/news/${c.id}`}>Подробнее…</Link>
        )}
      </p>
    </div>
  );
}

export function Grid4x(props) {
  const c = props.content;

  return (
    <Grid container justify="space-evenly" alignItems="stretch" spacing={3}>
      {c.map((el, key) => (
        <Grid item xs={12} md={4} key={`g4x-k-${key}`}>
          <TileGrid content={el} dateShow={props.dateShow} />
        </Grid>
      ))}
    </Grid>
  );
}
