import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { Spoler } from "../../components/spoler/spoler";
// import Forma from "../../components/credit-form";
// import Img from "./img";
// import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";

const online = `${dates.politicalStyle.online.getDate()}  ${Month(
  dates.politicalStyle.online.getMonth()
)}`;
/*
const absentia = `${dates.politicalStyle.absentia.getDate()} ${Month(
  dates.politicalStyle.absentia.getMonth()
)}`;
*/

export const contents = {
  meta: {
    keywords:
      "имидж, лидер, бизнес, политик, стратегия, обучение, где, курсы, школа",
    descript:
      "Обучение технологиям формирования имиджа публичных персон: политиков, представителей государственных компаний и структур, руководителей бизнеса и лидеров общественного мнения. Вы научитесь моделировать имидж стратегии для публичных персон: от замысла до реализации, основываясь на запросах и ожиданиях общества и элит, целей и задач политического или бизнес-проекта. Особое внимание в процессе обучения уделяется личностным данным персон, характеру и типу поведения, влияющими на грамотный выбор имидж-стратегии."
  },
  head: {
    title: "Курс по формированию имиджа лидера: преподаватели — политтехнологи",
    headDescript: "Специализация",
    head: (
      <>
        Формирование имиджа лидера
        <br />
        Инструменты политтехнологий
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      "Онлайн, очно",
      "Удостоверение о повышении квалификации",
      "2 месяца, 72 академических часа",
      "Подготовка имидж-стратегии лидера",
      "Консультации с наставниками",
      "Рассрочка, налоговый вычет"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Обучение технологиям формирования имиджа публичных персон: политиков,
        представителей государственных компаний и структур, руководителей
        бизнеса и лидеров общественного мнения, с учетом ожиданий общества и
        элит
      </div>
    ),
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/political-style/fullscreen.jpg"
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online}</> /*<>очно с {absentia}</>*/]
    },
    {
      title: "Формат",
      text: [<>Онлайн занятия, чек листы,</>, <>чат в группе, очная практика</>]
    },
    {
      title: "Спец. условия",
      text: [
        <>Специальные условия для</>,
        <>преподавателей вузов и студентов</>
      ]
    }
  ],
  screen2: {
    text: (
      <List
        arr={[
          <>
            Изучив инструментарий политтехнологов в области PR-стратегий и
            формирования имиджа лидеров, Вы научитесь моделировать имидж
            стратегии для публичных персон: от замысла до реализации,
            основываясь на запросах и ожиданиях общества и элит, целей и задач
            политического или бизнес-проекта.
          </>,
          <>
            Особое внимание в процессе обучения уделяется личностным данным
            персон, характеру и типу поведения, влияющими на грамотный выбор
            имидж-стратегии.
          </>
        ]}
        num="none"
        style={{ paddingBottom: "1rem" }}
        classLi="p-constructor"
      />
    ),
    video: ""
  },
  howIsTheTrainingGoing: [
    {
      title: "Онлайн лекции",
      text: (
        <>
          Каждую неделю Вы изу&shy;ча&shy;ете видео лек&shy;ции. Лек&shy;ции
          от&shy;кры&shy;вают&shy;ся по мере про&shy;хож&shy;де&shy;ния
          груп&shy;пой кур&shy;са. Обу&shy;че&shy;ние идет в ра&shy;бо&shy;чем
          ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          По основ&shy;ным темам курса Вы полу&shy;чаете домаш&shy;ние задания.
          Это важная часть обуче&shy;ния, в резуль&shy;тате кото&shy;рой
          форми&shy;руется итого&shy;вая проект&shy;ная работа.
        </>
      )
    },
    {
      title: "Онлайн семинары",
      text: (
        <>
          Каждую неделю — живое обще&shy;­ние с груп&shy;­пой и
          пре­&shy;пода&shy;­вате&shy;лем.
          Пре&shy;по&shy;да&shy;ва&shy;те&shy;ли помо&shy;га&shy;ют Вам с
          под&shy;го&shy;тов&shy;кой стра&shy;те&shy;гии, от&shy;ве&shy;чают на
          ак&shy;туаль&shy;ные во&shy;про&shy;сы
        </>
      )
    },
    {
      title: "Стратегия лидера",
      text: (
        <>
          В итоге обу&shy;че&shy;ния Вы соз&shy;да&shy;ете
          пер&shy;со&shy;наль&shy;ную пре&shy;зен&shy;та&shy;цию и
          стра&shy;тегию или стра&shy;те&shy;гию и пре&shy;зен&shy;та&shy;цию
          для ре&shy;аль&shy;ной пуб&shy;лич&shy;ной пер&shy;со&shy;ны из
          биз&shy;нес сооб&shy;щес&shy; тва
        </>
      )
    }
  ],
  aboutProfeshion: {
    title: "О Профессии fashion-стилиста",
    short:
      "Стилист — это профессионал, чья деятельность связана с созданием и продвижением модного продукта посредством образных решений…",
    detail: [
      <span>
        Это может быть текст о коллекции или визуальный образ коллекции
        (стильные фотосессии), это может быть концепция показа коллекции или
        концепция индивидуального творческого образа.
        <br />
        Fashion-стилист должен уметь выполнять как техническую задачу — подбор
        одежды и аксессуаров с учетом концепции fashion-съемки, так и быть
        художником, интерпретирующим модную тенденцию, рассказывающим
        определенную историю через визуальные образы.
        <br />В современном мире коммуникаций fashion-стилист является важнейшим
        звеном, отвечающим за процесс обмена информацией между fashion-брендом и
        потребителем. Правильно понятая, удачно найденная и выраженная идея
        стилизации съемки становится ценностью, напрямую связанная, как с
        личными профессиональными качествами, так и с качествами fashion-бренда.
        Именно потому сегодня творческий профессиональный успех — это умение
        соединять творческие субъективные решения с объективными законами
        создания образа и с маркетинговой, рекламной стратегией.
      </span>
    ]
  },
  text1: (
    <>
      <p>
        Цель курса — раскрыть Ваши таланты, сделать Вас проводником в мир
        осознанной красоты, чтобы Вы открывали окружающим прекрасное в
        повседневном, сближали мир реальный и мир, преображенный творчеством,
        несли потребителям послание модных брендов через визуальные образы.
        Чтобы Вы занимались любимым делом и это помогало Вам в карьере и бизнесе
      </p>
      <p>
        Курс ориентирован на дизайнеров, фотографов, визажистов,
        стилистов-парикмахеров и других специалистов, кто постоянно развивается
        и кому необходимо повысить свою квалификацию, освоить принципы и
        практику стилизации фотосессий
      </p>
    </>
  ),
  text2: {
    title: "Ищете идеальное образование?",
    text:
      "Вы можете выбрать интересующие Вас темы и форматы их освоения. Здесь Вы найдете как формат классического образования — в составе группы и единой программы, так и возможность индивидуальной траектории обучения. Мы даем полноценные курсы профессиональной переподготовки и повышения квалификации в индустрии моды. Мы — Автономная некоммерческая организация Дополнительного профессионального образования «Высшая школа стилистики, дизайна и технологий», одна из ведущих образовательных площадок в индустрии"
  },
  programma: {
    head: "Программа",
    content: [
      <p>
        Цель курса – дать современные знания, сформировать практические навыки,
        необходимые для работы с имиджем публичных персон: политиков,
        представителей государственных компаний и структур, руководителей
        бизнеса и лидеров общественного мнения, с учетом политической и
        организационной культуры, специфики восприятия фигуры лидера обществом
      </p>,
      <p>
        <strong>Модуль 1. Что такое публичный имидж. Вводный модуль</strong>
        <br />
        (4 занятия, 8 академических часов)
      </p>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Компоненты публичного имиджа</>,
          <>Публичный имидж как коммуникация.</>,
          <>Формирование общественного мнения и создание публичной карьеры</>,
          <>
            Лучшие практики создания имиджей бизнесменов, политиков,
            государственных служащих.
          </>
        ]}
      />,
      <p>
        <strong>Модуль 2. Инструментарий создания публичного имиджа.</strong>
        <br />
        (4 занятия, 8 академических часов)
      </p>,
      <List
        style={styles.p}
        arr={[
          <>Социологические исследования: виды и типы. Выбор исполнителей</>,
          <>
            Контроль проведения и интерпретации исследований: качественные и
            количественные исследования.
          </>,
          <>
            Анализ примеров имиджевых проектов в зависимости от результатов
            социологических исследований
          </>
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <p>
        <strong>Модуль 3. Содержательные компоненты публичного имиджа.</strong>
        <br />
        (6 занятий, 12 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            Идеологическое пространство современного мира: политика, бизнес,
            государство, философия
          </>,
          <>
            Социальная и политическая организация общества. Способы анализа
            идеологии в рамках проектирования публичного имиджа
          </>,
          <>
            Ожидания и потребности общества как компоненты публичного имиджа.
            Ценностные установки и стереотипы
          </>,
          <>
            Анализ потенциальной группы поддержки лидера и ее психологических
            особенностей
          </>,
          <>
            Технология создания идеологии для публичной персоны на основе данных
            социологических исследований
          </>
        ]}
        num="none"
      />,
      <p>
        <strong>Модуль 4. Поведенческие компоненты публичного имиджа.</strong>
        <br />
        (7 занятий, 14 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            Личность как основа имиджа. Визуальный образ и его роль в публичном
            имидже.
          </>,
          <>Особенности визуального восприятия лидера в различных ситуациях</>,
          <>
            Когнитивный диссонанс: возможные ошибки при формировании целостного
            имиджа
          </>,
          <>
            Внешние атрибуты статуса и мотивации: внимание к деталям в одежде и
            образе
          </>,
          <>
            Поведенческие особенности презентации имиджа: формирования стиля
            публичного выступления
          </>,
          <>Продукция визуального уровня: фото, видео, текст.</>
        ]}
        num="none"
      />,
      <p>
        <strong>
          Модуль 5. Моделирование публичного имиджа: создание проекта,
          тестирование, корректировка
        </strong>
        <br />
        (8 занятий, 16 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Теория социального влияния. Управление социальными группами</>,
          <>Психологические и социологические теории лидерства</>,
          <>
            Общественное мнение и методы его оценки. Ожидания и запрос на лидера
          </>,
          <>Выбор имиджевой стратегии.</>,
          <>Имидж проект: разработка, тестирование, корректировка</>,
          <>Анализ имидж-проектов публичных персон</>
        ]}
        num="none"
      />,
      <p>
        <strong>Модуль 6. Динамика развития публичного имиджа</strong>
        <br />
        (7 занятий, 14 академических часов)
      </p>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Презентация публичного имиджа.</>,
          <>Оптимальное использование и практика применения PR-инструментов.</>,
          <>Компоненты информационных войн.</>,
          <>Продвижение публичного имиджа в сети интернет.</>,
          <>Принципы формирования новостной и информационной политики.</>,
          <>
            Актуальные вопросы развития: репутация, политическое влияние,
            лоббирование
          </>
        ]}
        num="none"
      />,
      <p>
        <strong>Итоговая аттестация:</strong> зачет (защита проектной работы)
      </p>
    ]
  },
  /*
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – очные практики и консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={
          <>Учебная нагрузка: 4 занятия (8 академических часов) в неделю</>
        }
        text={
          <>
            <p>График занятий:</p>
            <List
              olStyle={{ paddingLeft: "1rem" }}
              arr={[
                <>
                  3 занятия в неделю по 2 академических часа — лекционный
                  материал с домашними практическими заданиями
                </>,
                <>
                  1 занятие в неделю по 2 академических часа — семинары с
                  преподавателями, живое общение online
                </>
              ]}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  */
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Учебная нагрузка: 4 занятия в неделю по 90 минут, 4 недели</>}
        text={
          <>
            <p>График занятий:</p>
            <List
              olStyle={{ paddingLeft: "1rem" }}
              arr={[
                <>
                  3 занятия в неделю по 90 минут — мастер-классы с домашними
                  практическими заданиями
                </>,
                <>
                  1 занятие в неделю — семинары с преподавателями, живое общение
                  online
                </>
              ]}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Слушатели, прошедшие обучение на курсе и успешно защитившие проектную
        работу, получают удостоверение о повышении квалификации установленного в
        РФ образца по программе «Формирование имиджа лидера. Инструменты
        политтехнологий»
      </p>
    ),
    image: (
      <img
        src="/img/sdi/diplom-2.jpg"
        alt="Диплом ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  info: {
    head: "Информационные материалы и документация",
    iconDocs: (
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path
          fill="rgb(100,100,100)"
          d="M16 0H8C6.9 0 6 .9 6 2V18C6 19.1 6.9 20 8 20H20C21.1 20 22 19.1 22 18V6L16 0M20 18H8V2H15V7H20V18M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4Z"
        />
      </svg>
    ),
    iconMonitor: (
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path
          fill="rgb(100,100,100)"
          d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
        />
      </svg>
    )
  },
  teachers: [
    {
      image: "/img/political-style/teacher02.jpg",
      name: "Валерий Чередниченко",
      text: (
        <>
          Кандидат экономических наук, социолог, бизнес-тренер, политтехнолог
          Директор Института стратегических исследований и проектов.
          <br />
          Заведующий кафедрой партийной школы «Единой России» в Московской
          области Автор более 130 статей, монографий и методических пособий в
          области социологии, общественного мнения, политической рекламы и
          имиджа.
          <br />В течение 20 лет участвовал в различных политических кампаниях
          по выборам президентов России, Украины, Монголии, Абхазии, депутатов
          Государственной Думы, мэров городов и глав районов
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/valery_cherednichenko",
        linkInside: true
      }
    },
    {
      image: "/img/political-style/teacher01.jpg",
      name: "Мария Родина",
      text: (
        <>
          Кандидат политических наук, психолог, бизнес-тренер, политтехнолог
          <br />
          Директор фонда «Общественных инициатив»
          <br />
          Провела более 30 исследовательских проектов в области изучения
          политического и социального пространства
          <br />
          Разработала и реализацовала несколько десятков политических проектов,
          проектов в области политической рекламы и имиджа
          <br />
          Руководила кампаниями по выбору депутатов ГД, глав районов Московской
          области
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/maria_rodina",
        linkInside: true
      }
    }
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>2500 &#8381;</>,
    full: <>30 000 &#8381;</>, //  полная стоимость
    discount: false, // <>48 000 &#8381;</>, // со скидкой
    durationInstallment: "12",
    discountSize: "20%",
    discountDuration: "при записи 1+1",
    qrLink: "https://link.tinkoff.ru/35cTm12MrhG"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
