import React from "react";
import { Grid } from "@material-ui/core";
import { DateToString } from "../shedule/dates";

export function JobList(props) {
  const c = props.content;

  return (
    <Grid container>
      {c.map((el, key) => (
        <>
          <Grid item xs={12} md={4} key={`job-img-${key}`}>
            <img src={el.image} alt={el.title} />
          </Grid>
          <Grid item xs={12} md={8} key={`job-txt-${key}`}>
            <p>{DateToString(el.date)}</p>
            <h3>{el.title}</h3>
            {el.text}
          </Grid>
        </>
      ))}
    </Grid>
  );
}
