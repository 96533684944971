import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Maps from "./map";
import Button from "../../components/button-messages";
import Page from "../../components/page";
import List from "../../components/list";
import { TitleH2 } from "../../patterns/pattern-fs/components";

export default function Contacts() {
  return (
    <div>
      <Helmet>
        <title>Контакты</title>
        <meta name="keywords" content="ВШСДТ,контакты" />
      </Helmet>
      <Page lable="Контакты" lableMobile="Контакты">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={3}
          style={{ maxWidth: "100%", margin: "0 auto", padding: 20 }}
        >
          <Grid item xs={12} md={6}>
            <TitleH2
              content={
                <>
                  Автономная некоммерческая организация
                  <br />
                  дополнительного профессионального образования
                  <br />
                  «Высшая школа стилистики, дизайна и технологий»
                </>
              }
              style={{ fontSize: "1.4rem", paddingTop: "1rem" }}
            />
            <p className="p-constructor center">
              ИНН 9717068337, КПП 771701001, ОГРН 1187700009056
            </p>
            <h3 style={{ paddingTop: "2rem" }}>
              {" "}
              По вопросам поступления и образовательных услуг:
            </h3>
            <List
              arr={[
                <>+7 (495) 221-89-35</>,
                <>&emsp;</>,
                <strong>Учебный отдел</strong>,
                <>
                  Киара Роуз: <a href="tel:+79160580091">+7 916 058-00-91</a>
                </>,
                <>
                  Евгения Пименова:{" "}
                  <a href="tel:+79160580092">+7 916 058-00-92</a>
                </>
              ]}
              num="none"
              olStyle={{ paddingLeft: 0, marginLeft: 0 }}
              classLi="p-constructor"
            />
            <Button
              linkType="component"
              componentLink={
                <div
                  className="button-white-in-black"
                  style={{ margin: "2rem 0" }}
                >
                  Запись на обучение
                </div>
              }
            />
            <h3 style={{ paddingTop: "2rem" }}>Адрес:</h3>
            <List
              arr={[
                <>
                  129085, Москва, ул. Проспект Мира, д. 101, стр. 1, пом. 210
                </>,
                <>для почтовой корреспонденции: а/я 2-18</>,
                <>
                  e-mail:{" "}
                  <a href="mailto:info@styleschool.ru">info@styleschool.ru</a>
                </>
              ]}
              num="none"
              olStyle={{ paddingLeft: 0, marginLeft: 0 }}
              classLi="p-constructor"
            />
            <h3 style={{ paddingTop: "2rem" }}>
              Время работы учебного отдела:
            </h3>
            <List
              arr={[
                <>Пн-Пт с 09:30 до 18:30 (мск)</>,
                <strong>Время проведения занятий:</strong>,
                <>Пн-Пт с 10:00 до 22:00 (мск)</>,
                <>Сб с 10:00 до 18:00</>
              ]}
              num="none"
              olStyle={{ paddingLeft: 0, marginLeft: 0 }}
              classLi="p-constructor"
            />
            <h3 style={{ paddingTop: "2rem" }}>Аренда аудиторий:</h3>
            <p className="p-constructor" style={{ paddingTop: 0 }}>
              +7 (915) 034-20-20
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ padding: 24, textAlign: "center" }}>
              <img
                src="/img/contacts/fasad.jpg"
                alt="офис ВШСДТ"
                style={{
                  maxWidth: "100%"
                }}
              />
            </div>
            <Maps />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
