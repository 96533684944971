import React, { useState } from "react";
import Video from "../../components/video";
import "./style.css";

export function TileAndSpoler(props) {
  const [open, setOpen] = useState(false);
  const side = props.sideImage;
  const el = props.short;
  const content = props.content;

  return (
    <>
      <div className="box" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <div className={side === "left" ? "grid1" : "grid2"}>
          <div className="grid-image">
            {el.video ? (
              <div className="box-image">
                <Video link={el.video} h={240} w={320} />
              </div>
            ) : (
              <div className="box-image">
                <img src={el.image} alt="" className="image" />
              </div>
            )}
          </div>
          <div className="grid-text">
            {el.text}
            <div className="button" onClick={() => setOpen(!open)}>
              {open ? "свернуть" : "подробнее"}
            </div>
          </div>
        </div>
      </div>
      <div
        className={open ? "spoler-opened" : "spoler-rolled"}
        style={{ backgroundColor: "rgb(250,250,250)" }}
      >
        <div style={{ padding: "2rem 1rem" }}>
          {content}
          <div
            className="button"
            style={{ margin: "0 auto" }}
            onClick={() => setOpen(false)}
          >
            свернуть
          </div>
        </div>
      </div>
    </>
  );
}
