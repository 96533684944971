import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Стиль и образ классического и современного искусства",
    headAfterDescript:
      "Повышение квалификации специалистов индустрии моды и красоты, а также преподавателей по соответствующим направлениям",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 1 месяц, 18 академических часа",
      "Рассрочка: 10 мес. Налоговый вычет",
      "Стоимость курса: 6900 руб."
    ],
    text: "",
    imageSource: "/img/evolution-styles-g/evolution.jpg",
    noVideo: true,
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: {
    dates: [
      {
        d: dates.evolutionStylesG.online.getDate(),
        t: Month(dates.evolutionStylesG.online.getMonth()),
        c: "дистанционно"
      }
      // {
      //   d: dates.trendsOfModernFashion.ofline.getDate(),
      //   t: Month(dates.trendsOfModernFashion.ofline.getMonth()),
      //   c: <span>&ensp;очно</span>
      // }
    ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>Для кого</span>
      )
    },
    text:
      "Курс будет интересен дизайнерам, фотографам, тем, кто пишет об искусстве, всем тем, кто интересуется вопросами современного арт-процесса",
    video: "rYKZAsSnC84"
  },
  text1:
    "Мы традиционно воспринимаем искусство как способ авторского отражения мира в образах, причем понятных, связанных с категорией красоты и  прекрасного. Мы точно знаем, что художники, скульпторы, архитекторы знают секреты мастерства и обладают талантом и даже гениальностью. Как же тогда оценить современное искусство, начиная с XX века, особенно, искусство 2-й половины XX века и начала XXI века? Как понять непонятное и увидеть смысл в, казалось бы, бессмысленном, «пустом», «некрасивом»?",
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель обучения
          </h2>,
          "Цель курса — анализ основных систем стилей и образов в искусстве, причин и закономерностей их эволюции — от канонических, классических до постмодерна.",
          "Мы рассмотрим каждую систему стиля как мирообраз той эпохи, которой он принадлежит, и попытаемся разобраться, как и почему произошел разрыв с классическими формами художественного образа и возникли именно такие формы современного искусства.",
          "Рассмотрим, почему система романтизма стала определяющей, а искусство XX века — эксперимент со всеми стилями, как меняется понятие «красивое» и почему современное искусство, являясь хорошо отлаженным рыночным механизмом, не лишено идей, образов и внутреннего потенциала.",
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Содержание курса
          </h2>,
          <List
            style={styles.p}
            num="none"
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            arr={[
              "Художественный образ – коммуникация между автором и зрителем",
              "Стиль и образ канонических систем. Древнеегипетский стиль",
              "Структура и цель создания классического художественного образа",
              "Классицизм и романтизм — два стиля жизни и искусства от Возрождения до искусства «здесь и сейчас»",
              "Классицизм как система: идеи, конфликт, композиция и ее элементы",
              "Романтизм как система: идеи, конфликт, композиция и ее элементы",
              "Философские идеи и экспрессия формы (барокко, готика и др.)",
              "Эволюция романтизма в искусстве нач.XX века (супрематизм, кубизм, экспрессионизм, абстракционизм, сюрреализм и др.)",
              "Спор и взаимодействие систем классицизма и романтизма",
              "Сочетание несочетаемого – форма и образ искусства 2-й пол.XX века",
              "Структура и цель создания художественного образа постмодерна",
              "Основные отличия образа современного искусства от классического"
            ]}
          />,
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            В процессе обучения
          </h2>,
          <List
            style={styles.p}
            arr={[
              "Онлайн семинары с автором",
              "Домашние задания и практика анализа стилей и их трансформаций",
              "Практика создания текстов"
            ]}
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            num="none"
          />,
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Результат обучения
          </h2>,
          <List
            style={styles.p}
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            arr={[
              "Понимание причин и следствий в развитии современного искусства",
              "Умение формировать мнение, приближающееся к экспертному"
            ]}
            num="none"
          />
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 1 месяц, 9 занятий по 2 академ. часа
            <br />
            Формат обучения: все занятия проходят онлайн с преподавателем
            <br />
            Экзамен: проектная работа
            <br />
            Все занятия доступны в записи в личном кабинете
            <br />
            Обучение проводится в мини группе
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты:{" "}
                  <strong>
                    6<sup>900</sup> рублей
                  </strong>{" "}
                  — единовременный платёж
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>690 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 6<sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="6900.00"
                            name="Стиль и образ классического и современного искусства (онлайн-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>,
                "Студентам и выпускникам ВШСДТ предоставляется скидка",
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </div>
      )
    },
    distant: {
      textButton: "Дистанционное обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: сертификат
            <br />
            Длительность: 9 видео занятий по 2 академических часа
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          6<sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>690 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 6<sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="6900.00"
                            name="Стиль и образ классического и современного искусства (дистанционное-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  }
};
