import React from "react";
import { styles } from "./styles";
import List from "../../components/list";
import Description from "../../components/descript4courses";
import { withStyles } from "@material-ui/styles";

const wStyles = theme => styles;

function Descript(props) {
  const { classes } = props;
  return (
    <Description
      block1={
        <div style={{ paddingBottom: "1.4em" }}>
          <p className={classes.p}>
            Курс «Международные коммуникации, презентация бренда на глобальном
            рынке», это возможность получить современную востребованную
            специальность в индустрии моды и дизайна.
          </p>
        </div>
      }
      block2={
        <div>
          <h3 className={classes.h3} style={{ paddingTop: 0, marginTop: 0 }}>
            Для кого:
          </h3>
          <List
            arr={[
              "Предприниматели, которые имеют или собираются начать бизнес в индустрии моды и дизайна (производства, магазины, шоу-румы и пр.)",
              "Представители fashion индустрии и бизнес сообщества",
              "Люди, которые хотят занять свое место в индустрии моды и дизайна",
              "Дизайнеры у которых есть продукт или идея в сфере моды",
              "Рекламисты, PR-специалисты, маркетологи",
              "Блоггеры, фотографы, видеографы которые хотят работать в индустрии моды и дизайна",
              "Стилисты, имиджмейкеры",
              "Байеры"
            ]}
            style={styles.p}
          />
          <h3 className={classes.h3}>Что будет:</h3>
          <List
            arr={[
              "Принципы бизнес – коммуникации, специфика международного нетворкинга в индустрии моды и дизайна.",
              "Как вести международные деловые переговоры и продаваться на глобальном рынке.",
              "Как взаимодействовать с иностранными партнерами / поставщиками / заказчиками / контрагентами.",
              "Как и где эффективно представлять себя / бренд / услугу на мировом рынке.",
              "Как разработать стратегию выведения бренда на глобальный рынок.",
              "Визуальные коммуникации бренда.",
              "Технологии рекламы и PR в индустрии моды и дизайна."
            ]}
            style={styles.p}
          />
        </div>
      }
      img="/img/international-communications/ill2.jpg"
      styleImage={{
        maxHeight: 600
      }}
    />
  );
}

export default withStyles(wStyles)(Descript);
