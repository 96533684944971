import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import Page from "../../components/page";
import Paragraph from "../../components/p-array";
import Helmet from "react-helmet";

function Illustration(props) {
  return (
    <figure
      style={{
        textAlign: "center",
        padding: 0
      }}
    >
      <img src={props.image} alt="" style={{ width: props.width, margin: 0 }} />
      <figcaption
        style={{
          fontFamily: "Lora",
          fontSize: "0.7em",
          fontStyle: "italic",
          color: "rgb(255,0,0)",
          textAlign: "center"
        }}
      >
        {props.text}
      </figcaption>
    </figure>
  );
}

export default function Dior() {
  return (
    <>
      <Helmet>
        <title>Кутюрье мечты. Руслан Мигранов</title>
        <meta
          name="descript"
          content="Статья Руслана Мигранова «Кутюрье мечты» посвященная выставке Кристиану Диору. Стиль Диор стал классикой непреложной элегантности и, даже спустя семьдесят лет после открытия Дома, продолжает ассоциироваться с вневременной женственностью и шиком, а имя кутюрье — с образом изысканности."
        />
        <meta
          name="keywords"
          content="Руслан Мигранов, кутюрье, Кристиан Диор, модный дом, мода, стиль"
        />
      </Helmet>
      <Page lable="Статьи">
        <Grid container justify="center" alignItems="flex-start">
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <h1
              style={{
                padding: 4,
                fontFamily: "Roboto",
                fontSize: "1.6em",
                color: "rgb(100,100,100)",
                fontWeight: 700,
                textAlign: "center",
                margin: 0
              }}
            >
              Кутюрье мечты
            </h1>
            <h2
              style={{
                padding: 4,
                paddingBottom: 12,
                fontFamily: "Roboto",
                fontSize: "1.4em",
                color: "rgb(130,130,130)",
                fontWeight: 700,
                textAlign: "center",
                margin: 0
              }}
            >
              <Link to="/ruslan_migranov" style={{ color: "rgb(130,130,130)" }}>
                Руслан Мигранов
              </Link>
            </h2>
            <div style={{}}>
              <img
                src="/img/blog/dior/red.jpg"
                alt=""
                style={{
                  width: "100%"
                }}
              />
            </div>
            <Hidden implementation="css" smDown>
              <div
                style={{
                  position: "relative",
                  left: 0,
                  top: -80,
                  width: "60%",
                  padding: "4px 8px 6px",
                  fontFamily: "Roboto",
                  fontSize: "1em",
                  color: "rgb(50,50,50)",
                  background: "rgba(255,255,255,0.8)",
                  marginBottom: -80
                }}
              >
                «Я обожаю красный, это цвет жизни», — говорил господин Диор о
                своем цвете-талисмане, который он советовал носить всем
                женщинам.
              </div>
            </Hidden>
            <Hidden implementation="css" mdUp>
              <div
                style={{
                  padding: "4px 10px",
                  fontFamily: "Roboto",
                  fontSize: "1em",
                  color: "rgb(50,50,50)",
                  background: "rgba(255,128,128,1)"
                }}
              >
                «Я обожаю красный, это цвет жизни», — говорил господин Диор о
                своем цвете-талисмане, который он советовал носить всем
                женщинам.
              </div>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={7}>
            <p
              style={{
                fontFamily: "Lora",
                fontStyle: "italic",
                fontSize: "1em",
                textAlign: "center",
                color: "rgb(100,100,100)"
              }}
            >
              В парижском Музее декоративных искусств открылась выставка
              ретроспектива «Кристиан Диор: кутюрье мечты», приуроченная к
              70-летию первой коллекции французского дома Dior, к рождению
              прославленного стиля «new look».
            </p>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "20px 10px 20px 20px" }}>
            <Paragraph
              content={[
                "По ходу своей работы, мне часто приходится посещать разные города и модные выставки по всему миру. Скажу, что эта выставка, которую я посетил с группой в рамках наших экскурсий в Париже, определенно одна из лучших и по оформлению и по представленной экспозиции. Ведь со всего мира на нее съехались более 300 платьев haute couture, и для многих — это первое появление на публике за долгие годы. Выставка занимает два главных крыла музея, а это больше 3 тыс. квадратных метров — ни одну экспозицию в этих стенах еще так не баловали. Сейчас имя Кристиан Диор для многих это символ Парижа и Франции в целом, таинственный и прекрасный мир моды, который манит и притягивает. И заядлые модники и просто любопытные, готовы часами стоять в очереди, чтобы попасть на эту выставку.",
                "Но отношение к Диору, не всегда было таким.",
                "«Сожжем Диора», «Кристиан Диор, go home!», с такими плакатами встречали Диора жительницы Чикаго в далеком 1947 г. А фермер из Айдахо, возмущенно писал ему «Ваш так называемый гений изуродовал мою жену. Хотите, пришлю вам остаток от нее?».",
                "Но почему женщины, так встречали Диора?"
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "20px 20px 20px 10px" }}>
            <Paragraph
              content={[
                <span>
                  Да потому что в еще не оправившейся от войны Европе &ndash;
                  экономика была в плачевном состоянии и существовала система
                  выдачи продуктов по карточкам. И тут, как гром среди ясного
                  неба, Диор предлагает платья на которые идет расход ткани до
                  30 метров. Конечно, они были красивы, но и очень дороги и не
                  всем нравилась такая расточительность.
                </span>,
                "Кристиан Диор был очень суеверным человеком, носил кусочек дерева в кармане, амулеты и еще в детстве гадалка предсказала ему судьбу. «У вас не будет денег, и помогут вам женщины, с их помощью к вам придет успех», тогда он воспринял это скептически. Но много лет спустя начиная писать автобиографию, он начал с ее предсказания.",
                <span>
                  Его знаменитый стиль New look впервые представленный 12
                  февраля 1947г., сразу покорил женщин. Здесь шла речь о
                  возвращении в моду самой женственности, романтичности и почти
                  забытой в военное время элегантности. Это стиль
                  женщины-цветка, с покатыми плечами, подчеркнутой грудью,
                  утянутой талией. Основной принцип на котором строился New look
                  &ndash; это контраст, чтобы подчеркнуть тонкую талию, вокруг
                  создается объем.
                </span>
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <Paragraph
              content={[
                <span>
                  «Элегантность &ndash; это всегда простота», утверждал Диор. И
                  самым простым украшением считал обычную черную пуговицу с
                  четырьмя дырочками. Именно при нем войдет в моду золотое
                  правило «тон в тон»: все аксессуары должны быть одного цвета.
                  Это правило неукоснительно, соблюдалось много лет. Он никогда
                  не предлагал юбки выше коленей, считая это неприличным.
                </span>,
                "Стиль Диор стал классикой непреложной элегантности и, даже спустя семьдесят лет после открытия Дома, продолжает ассоциироваться с вневременной женственностью и шиком, а имя кутюрье — с образом изысканности. Он впервые ввел лицензирование на свои модели, устав бороться с плагиатом.",
                "Давайте вместе со мной совершим экскурсию на эту великолепную выставку!",
                "Первый зал —полностью посвящен Кристиану Диору, его семье, вещам, успехам, странностям и страстям."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} style={{ paddingLeft: 20 }}>
            <Illustration
              image="/img/blog/dior/ill-01.jpg"
              text="Модели платьев разных лет, в любимом стиле Кристиана Диора «New Look»"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={7} style={{ paddingRight: 20 }}>
            <Paragraph
              content={[
                "Есть его первые эскизы для французских газет, есть фотографии американские феминисток, проклинающих new look и разрывающих платья на манекенщицах, есть обожающие его киноактрисы — красотки Рита Хейворт, Марлен Дитрих, Брижит Бардо. Есть фото Кристиана в годовалом возрасте в пышном платье для девочек, а рядом фото — великого кутюрье, в костюме царя зверей и с львиной мордой. Он обожал парижские кабаре, театры и выставки — это были его университеты.",
                "Далее следуют нескольких залов, которые называются «Colorama», которые представляют собой увлекательнейший микс всего на свете. Флаконы, платья, шляпы, платки, сумки, обувь, крошечные миниатюры — сотни предметов собраны по цветам с таким вкусом и так элегантно. Одна из витрин отдана красному цвету, к нему он питал особую любовь. Так описывал он, свое восприятие этого цвета «Красный – цвет страсти, цвет любви, цвет жизни» - и это был один из доминирующих цветов в его творчестве. Тут нельзя не сказать о легендарной, культовой и просто роскошной — красной помаде Dior Rouge 999 с 60-летней историей."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} style={{ padding: 10, paddingLeft: 20 }}>
            <Paragraph
              content={[
                <span>
                  Но больше всего на свете он любил розовый &ndash; цвет
                  надежды, цвет счастья и женственности. Что-нибудь розовое в
                  гардеробе полезно иметь всем, утверждал Диор. На выставке,
                  этим двум любимым цветам Диора, отдано две больших витрины,
                  где вы можете лицезреть все оттенки и розового и красного,
                  которые когда-либо использовались в Доме Диор в платьях,
                  костюмах и аксессуарах, созданные начиная самим Диором и
                  заканчивая Марии Грации Кьюри. Любовь к розовому возникла в
                  раннем детстве, т. к. дом в котором он вырос был выкрашен в
                  розовый с оттенками серого, а в саду росли розовые розы.
                  Полезно знать, что на востоке розовый &ndash; к деньгам!
                </span>
              ]}
            />
          </Grid>
          <Grid item xs={12} md={7} style={{ padding: 10, paddingRight: 20 }}>
            <Illustration
              image="/img/blog/dior/ill-02.jpg"
              text="Розовый любимый цвет Диора, ведь в эти оттенки был выкрашен дом в котором он вырос в Гранвилле. Слева платье Opéra bouffe, Christian Dior, из коллекции haute couture осень-зима, 1956"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingLeft: 20 }}>
            <Illustration
              image="/img/blog/dior/ill-03.jpg"
              text="«Довима и слоны», фото Ричард Аведон, 1955"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: 10, paddingRight: 20 }}>
            <Paragraph
              content={[
                "Экспозиция богато украшена фотографиями и иллюстрациями, в том числе не побоюсь этого слова шедеврами, фотографиями вне времени! Например, отдельной стены заслужило знаменитое фото Ричарда Аведона «Довима и слоны», 1955г. На нем модель Довима изображена в элегантном черном платье , но мало кто знает, что это первая работа тогда никому неизвестного 19-летнего юноши, которого мэтр Диор недавно взял в ассистенты. Звали юношу Ив Сен-Лоран! Возможно, именно благодаря его появлению в Доме Моды Dior, войдет в моду новая линия Y.",
                <Illustration
                  image="/img/blog/dior/ill-04.jpg"
                  text="Зал вдохновленный стилем Людовика XVI и королевской портретистки Элизабет Вижи-Лебрен"
                  width="100%"
                />
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} style={{ paddingRight: 20 }}>
            <Paragraph
              content={[
                "На выставке есть зал, оформленный в стиле «Сады Диора», не секрет, что цветы и женщины были главным источником вдохновения для кутюрье. В этой экспозиции гармонично сочетаются платья из новых коллекций дома Диор и платья, созданные самим кутюрье начиная с 1947г. Сам дизайнер всегда говорил, что всего лишь хотел вернуть утраченный рай детства. И больше всего на свете он любил и боготворил свою маму. После первого показа, он произнес такую фразу «Если бы мама была жива, я бы никогда не осмелился»."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Illustration
              image="/img/blog/dior/ill-05.jpg"
              text="Зал посвященный «Садам Диора»"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={11}>
            <Paragraph
              content={[
                "Второе главное крыло музея, открывается знаменитым костюмом «Бар». Он появился на свет потому, что модельер считал, что каждой женщине необходим элегантный жакет для посещения коктейльных вечеринок. Он стал настолько популярен, что под него была разработана специальная диета, которая помогала обрести «осиную» талию!",
                <Illustration
                  image="/img/blog/dior/ill-06.jpg"
                  text="Знаменитый костюм «Бар» для коктейльных вечеринок"
                  width="100%"
                />
              ]}
            />
          </Grid>
          <Grid item xs={12} md={7} style={{ paddingLeft: 20 }}>
            <Paragraph
              content={[
                "Дальше открывается святая святых любого Дома моды — ателье и мастерская вышивки, в случае Диор — это экспозиция высотой в несколько этажей! Для Кристиана Диора всегда был важен момент, когда пробивал час сверки мечты с реальностью, путь от эскиза к первой форме, далее выборе ткани, и наконец самому платью. Вместе с первой коллекцией одежды он также выпустил флакон духов Miss Dior, флакон которых представлен на выставке."
              ]}
            />
            <div
              style={{
                background: "rgb(220,220,220)",
                margin: 10,
                padding: 6
              }}
            >
              <p
                style={{
                  fontFamily: "Lora",
                  fontSize: "1em",
                  fontStyle: "italic",
                  textAlign: "center",
                  color: "rgb(80,80,80)",
                  padding: "4px 20px",
                  margin: 0
                }}
              >
                &laquo;Присутствие Диора ощутимо и сегодня. Время от времени я
                мысленно обращаюсь к нему за советом, но как бы я хотел знать
                его лично &raquo;
              </p>
              <p
                style={{
                  fontFamily: "Lora",
                  fontSize: "1em",
                  fontStyle: "italic",
                  textAlign: "right",
                  color: "rgb(80,80,80)",
                  padding: "4px 20px",
                  margin: 0
                }}
              >
                Джон Гальяно
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <Illustration
              image="/img/blog/dior/ill-07.jpg"
              text="Ателье и мастерская вышивки, в случае Диор — это экспозиция высотой в несколько этажей!"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <Paragraph
              content={[
                "Галерея дизайнеров, которые стояли во главе Дома после ухода Диор также впечатляет соединением индивидуальности каждого творца и верностью стилю Дома: «маленький принц» высокой моды Ив Сен Лоран, Марк Боан, архитектор Джанфранко Ферре и экстравагантный гибралтарец Джон Гальяно, минималист Раф Симонс и нынешний дизайнер Мария Грация Кюри. Каждому из них на выставке выделено по небольшому залу, где представлены их самые лучшие модели для Дома Диор."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} style={{ paddingLeft: 20 }}>
            <Illustration
              image="/img/blog/dior/ill-08.jpg"
              text="Зал бальных платьев, просто поражает посетителей красотой нарядов!"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={7} style={{ paddingRight: 20 }}>
            <Paragraph
              content={[
                <span>
                  В завершение выставки посетители попадают на бал Диор,
                  экспозиция сразу поражает своей роскошью, объемом вложенного
                  труда и невероятной красотой. Здесь можно увидеть бальные
                  платья Элизабет Тейлор, Софи Лорен, принцессы Дианы, Шарлиз
                  Терон и других поклонниц Dior. И не просто так этот зал
                  последний, ведь каждый свой показ Диор заканчивал бальными и
                  свадебными платьями. Просто он отлично понимал, что хотят
                  женщины, он знал, что каждая девушка в детстве читала сказку о
                  золушке. Вспомните ее платье на балу. Какая девушка в детстве
                  не мечтала о наряде золушки? Ведь, для многих самое главное
                  &ndash; удачно выйти замуж! Диор воплощал эту детскую мечту,
                  дарил сказку! Зрители ходят, задрав голову (высота нефа не
                  менее десяти метров), и не верят своим глазам. Такое увидишь
                  не каждый день!!!
                </span>
              ]}
            />
          </Grid>
        </Grid>
      </Page>
    </>
  );
}
