import React from "react";
import YouTube from "react-youtube";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  video: {
    margin: "0 auto",
    width: 320,
    height: 240
  },
  image: {
    margin: "0 auto",
    width: 320,
    height: "auto"
  },
  maxWidth: {
    margin: "0 auto",
    maxWidth: "100%"
  },
  imageWithButton: {
    position: "returnlative"
  },
  buttonInImage: {
    position: "abslolute",
    top: "50%",
    left: "50%",
    "&:hover": {
      filter: "contrast(150%)"
    }
  },
  "@media (max-width: 959px)": {
    image: {
      width: 240
    },
    video: {
      width: 240,
      height: 180
    }
  }
});

function _onReady(event) {
  event.target.pauseVideo();
}

function Image(props) {
  const { classes } = props;
  return (
    <img
      src={props.image || `/img/videos/${props.link}.jpg`}
      alt=""
      className={props.wMax ? classes.maxWidth : classes.image}
    />
  );
}

function ImageWithButton(props) {
  const { classes } = props;
  return (
    <div className={classes.imageWithButton}>
      <Image image={props.image} classes={props.className} link={props.link} />
    </div>
  );
}

function VideoLocal(props) {
  return (
    <video
      src={props.linkVideo}
      controls
      autoplay
      width={props.w}
      height={props.h}
    />
  );
}

function Videos(props) {
  const { classes } = props;
  const [video, imageToVideo] = React.useState(false);
  const size = {
    max: classes.maxWidth,
    small: classes.video
  };
  return (
    <div
      style={{
        maxWidth: "max-content",
        margin: "0 auto",
        cursor: "pointer",
        ...props.style
      }}
      onClick={() => imageToVideo(true)}
    >
      {video || props.onlyVideo ? (
        props.link[0] === "/" ? (
          <VideoLocal
            linkVideo={props.link}
            w={props.wVideo || "auto"}
            h={props.hVideo || "auto"}
          />
        ) : (
          <YouTube
            videoId={props.link}
            onReady={_onReady}
            opts={{
              playerVars: {
                autoplay: 1,
                start: 0.0
              }
            }}
            className={size[props.size] || classes.video}
          />
        )
      ) : (
        <Image
          image={props.image}
          link={props.link}
          classes={classes}
          wMax={props.wMax}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(Videos);
