import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DateToString, TimeToString } from "./dates";
import { datas } from "./datas";
import "./style.css";

export function CreateScheduleElement(props) {
  return (
    <div className="element-psevdolist">
      <Link to={props.page}>
        <h3 className="head-psevdolist">
          {`${DateToString(props.date, false)}. ${props.name}`}
        </h3>
      </Link>
      <p className="paragraph-psevdolist">
        {props.start
          ? `${props.duration} ${props.start} ${TimeToString(
              props.date
            )} по мск`
          : props.duration}
      </p>
    </div>
  );
}

// сравнение переменной v с массивом arr (если хотя бы одно значение равно)
function searchInArray(v, arr) {
  let r = false;
  const l = arr.length;
  for (let i = 0; i < l; i++) {
    if (v === arr[i]) r = true;
  }
  return r;
}

export function selectedFromArrayOneVal(arr1, v, field) {
  // arr1 — массив, в котором искать
  // v — что искать
  // fieldName — имя поля, которое сопоставлять
  const l = arr1.length;
  let tArr = [];
  for (let i = 0; i < l; i++) {
    if (arr1[i][field] == v) {
      tArr.push(arr1[i]);
    }
  }
  return tArr;
}

export function search(t, f1, d, f2) {
  // t — theme
  // d — duration
  // f1, f2 — поля для поиска соответственно
  let result = [];
  if (t == "") {
    result = search2(datas, d, f2);
  } else if (t == "all") result = search2(datas, d, f2);
  // else if (searchFromArr(t, "all")) result = search2(datas, d, f2);
  else {
    result = search2(selectedFromArrayOneVal(datas, t, f1), d, f2);
  }
  console.dir(result);
  return result;
}

export function search2(arr, d, f) {
  if (d == "" || d == "all") return arr;
  return selectedFromArrayOneVal(arr, d, f);
}

export function CreateListSchedule(props) {
  let tmp = [];
  const start = props.start || new Date();
  const last = props.last;
  props.datas.map(el => {
    if (el.date) {
      if (start <= el.date.getTime()) {
        if (last && el.date.getTime() <= last) {
          tmp.push(el);
        }
      }
    }
  });
  let content = tmp.sort((a, b) => a.date.getTime() - b.date.getTime());
  if (content.length == 0) {
    return <p>По данным параметрам ничего не найдено</p>;
  }
  return content.map(el => (
    // tmp.map((el) => (
    <CreateScheduleElement
      page={el.page}
      date={el.date}
      name={el.name}
      duration={el.duration}
      start={el.start}
    />
  ));
}

export function Form(props) {
  const [theme, setTheme] = useState("all");
  const [duration, setDuration] = useState("");
  const [themeOpen, setOpenTheme] = useState(false);
  const [dOpen, setOpenDuration] = useState(false);

  const openTheme = () => {
    setOpenTheme(!themeOpen);
  };
  const openDuration = () => {
    setOpenDuration(!dOpen);
  };
  const handleChangeTheme = val => {
    // setTheme(optionsToArr(e.target.selectedOptions));
    setTheme(val);
  };

  const handleChangeDuration = val => {
    setDuration(val);
  };

  return (
    <>
      <div className="select-box">
        <div className="selects">
          <div className="selects-title">Изучить:</div>
          <div className="selects-theme">
            <div className="button-select" onClick={openTheme}>
              <div className="title-button">
                <div className="title-text">Темы</div>
                <div className="title-icon">
                  <div
                    className={themeOpen ? "expanded-open" : "expanded-close"}
                  >
                    ∟
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                themeOpen
                  ? "select-component-container open"
                  : "select-component-container"
              }
            >
              <ul>
                <li
                  className={theme == "all" ? "selected" : ""}
                  onClick={() => handleChangeTheme("all")}
                >
                  все курсы
                </li>
                <li
                  className={theme == "fashion" ? "selected" : ""}
                  onClick={() => handleChangeTheme("fashion")}
                >
                  мода и стиль
                </li>
                <li
                  className={theme == "interior" ? "selected" : ""}
                  onClick={() => handleChangeTheme("interior")}
                >
                  дизайн интерьера
                </li>
                <li
                  className={theme == "clothes" ? "selected" : ""}
                  onClick={() => handleChangeTheme("clothes")}
                >
                  дизайн одежды
                </li>
                <li
                  className={theme == "reputation" ? "selected" : ""}
                  onClick={() => handleChangeTheme("reputation")}
                >
                  имидж репутация
                </li>
                <li
                  className={theme == "it" ? "selected" : ""}
                  onClick={() => handleChangeTheme("it")}
                >
                  IT профессия
                </li>
              </ul>
            </div>
          </div>
          <div className="selects-duration">
            <div className="button-select" onClick={openDuration}>
              <div className="title-button">
                <div className="title-text">Продолжительность</div>
                <div className="title-icon">
                  <div className={dOpen ? "expanded-open" : "expanded-close"}>
                    ∟
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                dOpen
                  ? "select-component-container open"
                  : "select-component-container"
              }
            >
              <ul>
                <li
                  className={duration == "all" ? "selected" : ""}
                  onClick={() => handleChangeDuration("all")}
                >
                  все курсы
                </li>
                <li
                  className={duration == "12" ? "selected" : ""}
                  onClick={() => handleChangeDuration("12")}
                >
                  1-2 месяца
                </li>
                <li
                  className={duration == "34" ? "selected" : ""}
                  onClick={() => handleChangeDuration("34")}
                >
                  3-4 месяца
                </li>
                <li
                  className={duration == "56" ? "selected" : ""}
                  onClick={() => handleChangeDuration("56")}
                >
                  5-6 месяцев
                </li>
                <li
                  className={duration == "6+" ? "selected" : ""}
                  onClick={() => handleChangeDuration("6+")}
                >
                  более 6 месяцев
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CreateListSchedule
        start={new Date() - 1000 * 60 * 60 * 24 * 10}
        last={new Date(2022, 11, 31)}
        datas={search(theme, "theme", duration, "d")}
      />
    </>
  );
}
