import React from "react";
import Paragraph from "../../components/p-array";
import { styles } from "../../patterns/pattern-educations/style";

export const chatBot = {
  head: (
    <>
      Профессия: Дизайнер чат-ботов
      <br />с искусственным интеллектом
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "3 мес. Онлайн",
  specialisation: (
    <>
      Создание чат-ботов, реагирующих на естественно-языковые обращения, в
      системе онлайн коммуникаций (мессенджеров)
      {/* Высокая вероятность трудоустройства в IT сфере: создании чат-ботов на
      конструкторе Aimylogic */}
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>Основы компьютерной лингвистики</>,
        <>Основы разработки диалоговых ассистентов с элементами NLP, ML/DL</>,
        <>Создание диалоговых ассистентов для текстовых каналов коммуникаций</>,
        <>
          Выполнение реальных проектов с обоснованной экономической
          эффективностью
        </>
      ]}
    />
  ),
  diplom: <>удостоверение о повышении ква&shy;ли&shy;фи&shy;ка&shy;ции МФТИ</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/chatbot"
  }
};

export const idfw = {
  head: (
    <>
      IDFW: вывод бренда одежды
      <br />
      на международный цифровой подиум
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "1 мес. Онлайн",
  specialisation: (
    <>
      Практические алгоритмы презентации бренда на International Digital Fashion
      Week. Подача заявки на IDFW
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>Что нужно знать, чтобы коллекция «зашла»</>,
        <>Digital-каналы и инструменты digital-маркетинга</>,
        <>Коммуникации и продажи в международной практике</>,
        <>Производство контента (для презентация бренда одежды)</>,
        <>Стратегии продвижения бренда одежды</>,
        <>Заполнение технической документации и подача заявки на IDFW</>
      ]}
    />
  ),
  diplom: <>удостоверение о повышении ква&shy;ли&shy;фи&shy;ка&shy;ции</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/idfw"
  }
};

export const imagemakerSecondDegree = {
  head: (
    <>
      Профессия: стилист-имиджмейкер
      <br />
      как второе высшее
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "1 год. Очно или Онлайн",
  specialisation: (
    <>
      Консультирование клиен&shy;тов по стилю. Стили&shy;ация и
      продюс&shy;сиро&shy;вание фото проектов. Работа с жур&shy;налами,
      дизай&shy;нерами, мод&shy;ными брен&shy;дами
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>Система созда&shy;ния имид&shy;жа и стиля от А до Я</>,
        <>Алгоритмы имидж-консуль&shy;тиро&shy;вания клиен&shy;тов</>,
        <>Маршруты и особен&shy;ности шопинга в России, Европе</>,
        <>Практика стили&shy;зации фото, ТВ, мод&shy;ных проек&shy;тов</>,
        <>Возможности коллабораций с дизайнерами, бути&shy;ками</>,
        <>Пути развития своего биз&shy;неса, карьеры</>,
        <>Создание и прод&shy;виже&shy;ние брен&shy;да стилиста</>
      ]}
    />
  ),
  diplom: <>диплом установ&shy;ленного госу&shy;дарс&shy;твом образца</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/second-degree-imagemaker"
  }
};

export const imageStyle = {
  head: (
    <>
      Стили одежды.
      <br />
      Правила подбора для женщин
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "4 мес. Очно или Дистанционно",
  specialisation: (
    <>
      Система созда&shy;ния стиль&shy;ных и мод&shy;ных комплек&shy;тов в
      одеж&shy;де и аксес&shy;суарах. Техно&shy;логии для расши&shy;рения
      спек&shy;тра услуг и сервиса.
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Колористика, цветовой анализ внешности",
        "Типология фигур, визуальная коррекция фигур",
        <>
          Изучение современных стилей как системы транс&shy;лиро&shy;вания
          необ&shy;ходимого впе&shy;чат&shy;ления
        </>,
        <>
          Правила подбора образа, стиля в одежде, аксес&shy;суарах, прическе,
          макияже
        </>,
        <>
          Принципы форми&shy;рования гар&shy;де&shy;роба и правила
          эффектив&shy;ного шо&shy;пинга
        </>
      ]}
    />
  ),
  diplom: <>удостоверение о повышении ква&shy;ли&shy;фи&shy;кации</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/image-style"
  }
  // space: "3.55rem"
};

export const fashionStyling = {
  head: (
    <>
      Профессия:
      <br />
      Стилист фотосессий
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "4 мес. Очно или Онлайн",
  specialisation:
    "Создавать образы и стилизовать фотосессии, организовывать, продюсировать. Для журналов, fashion-брендов, рекламы, портфолио.",
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "История моды и стилей",
        "Система современной индустрии моды",
        "Методики развития креативности для стилистов",
        "Арт дирекшн, работа с командой",
        "Теория и практика fashion съемок",
        "Специфика проектов для журналов, ТВ, fashion retail",
        "Формирование портфолио стилиста"
      ]}
    />
  ),
  diplom: <>удостоверение о повышении ква&shy;ли&shy;фи&shy;ка&shy;ции</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/fashion-styling"
  }
};

export const fashionTheory = {
  head: <>Теория моды. Социальные и художественные концепции</>,
  typeEducation: "Повышение квалификации",
  duration: "3 месяца. Очно или Дистанционно",
  specialisation:
    "Курс предназначен для повышения квалификации специалистов индустрии моды, преподавателей. А так же для интеллектуалов",
  price: (
    <span>
      10
      <sup>000</sup> руб. в месяц
    </span>
  ),
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Мода в мире креативной индивидуальности, сетевых коммуникаций и продаж, новых форм социальных свобод и запретов",
        "Кто создает моду — профессионалы или дилетанты, чьи образы транслируют дизайнеры, кому предназначены высказывания моды и что оказывает воздействие на аудиторию"
      ]}
    />
  ),
  diplom: <span>удостоверение о повышении ква&shy;лифи&shy;кации</span>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/concepts_of_fashion"
  }
  // space: "1rem"
};

export const conceptFashion = {
  head: <>Теория и практика концептуальной моды</>,
  typeEducation: "Повышение квалификации",
  duration: "1 мес. Дистанционно",
  specialisation: "Аналитика моды. Для специалистов индустрии моды и красоты",
  price: (
    <span>
      от 2<sup>500</sup> руб. в месяц
    </span>
  ),
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Суть концептуальной моды",
        "Деконструктивизм как одна из форм проектирования концептуального костюма",
        "Способы презентации коллекций на Неделях моды. Перформанс, его виды",
        "Мировые школы концептуального дизайна",
        "Создатель нового «языка» моды — Мартин Маржела"
      ]}
    />
  ),
  diplom: "удостоверение о повышении квалификации",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/concept-fashion-g"
  },
  space: "1.4rem"
};

export const imagemakingPR = {
  head: <span>Ииджмейкинг. Репутационные технологии</span>,
  typeEducation: "Профессиональная переподготовка",
  duration: "2 года. Очно или Онлайн",
  specialisation:
    "Работа с частными и корпоративными заказчиками в области моды и рекламы, PR; формирование имиджа лидеров, публичных персон, корпораций",
  price: (
    <span>
      от 30
      <sup>000</sup> руб. в месяц
    </span>
  ),
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        "Изучение дисциплин в области имидж консалтинга",
        "Изучение дисциплин в области стилизации проектов",
        "Психология лидерства",
        "Методики управления репутацией",
        "Основы формирования общественного мнения",
        "Подготовка и проведение PR мероприятий",
        "Практика работы в fashion и PR проектах под руководством наставника"
      ]}
    />
  ),
  diplom: "два диплома — ВШСДТ и РГСУ",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  space: "1.4rem"
};

export const imageConsulting = {
  head: <>Имидж-консалтинг. Персональный, деловой стиль</>,
  typeEducation: "Профессиональная переподготовка",
  duration: "6 месяцев. Очно или Дистанционно",
  specialisation: (
    <>
      Развитие лич&shy;ного стиля для карь&shy;еры, биз&shy;неса.
      Кон&shy;сульти&shy;рова&shy;ние клиен&shy;тов по стилю в одеж&shy;де,
      управ&shy;ление впечат&shy;лением
    </>
  ),
  price: false,
  process: (
    <>
      Правила создания стиля от А до Я: в одеж&shy;де, аксес&shy;суарах,
      прическе (макияже). Подбор рацио&shy;нального гар&shy;де&shy;ро&shy;ба.
      Создание Style Book. Мужская и женская стилистика. Дресс-коды. Практика
      эффек&shy;тивного шопинга. Психоло&shy;гичес&shy;кие особен&shy;ности
      разра&shy;ботки имид&shy;жа и рабо&shy;ты с клиен&shy;тами.
      Управ&shy;ле&shy;ние впе&shy;чат&shy;лением. Имид&shy;жевое
      воспри&shy;ятие и инстру&shy;менты влияния
    </>
  ),
  diplom: "диплом установленного государством образца",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/image-consulting"
  }
};

export const personalBrand = {
  head: <>Личный бренд. Профессиональное продвижение</>,
  typeEducation: "Повышение квалификации",
  duration: "1 месяц. Очно или Дистанционно",
  specialisation: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={["Создание личного бренда", "Продвижение личного бренда"]}
    />
  ),
  price: false,
  process: (
    <>
      Этапы создания личного бренда, фор&shy;миро&shy;вание личной
      айден&shy;тики и профес&shy;сиональ&shy;ного стиля, разра&shy;ботка
      стра&shy;тегии про&shy;движения брен&shy;да и услуг,
      моне&shy;ти&shy;за&shy;ция бренда
    </>
  ),
  diplom: <span>удостоверение о повышении ква&shy;лифи&shy;кации</span>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/personal-brand"
  }
  // space: "2.7rem"
};

export const polittechnology = {
  head: <>Формирование имиджа лидера. Инструменты политтехнологий</>,
  typeEducation: "Повышение квалификации",
  duration: "3 месяца. Очно или Дистанционно",
  specialisation: (
    <>
      Консульти&shy;рование клиентов — ра&shy;бота с пуб&shy;личными
      персо&shy;нами, поли&shy;тиками, гос&shy;служащими
    </>
  ),
  price: false,
  process: (
    <>
      Необходимые ин&shy;стру&shy;менты для работы с имид&shy;жем пуб&shy;личных
      персон с уче&shy;том поли&shy;тичес&shy;кой и орга&shy;низа&shy;ционной
      культуры, специ&shy;фики вос&shy;приятия лиде&shy;ров
      совре&shy;мен&shy;ным общес&shy;твом
    </>
  ),
  diplom: <span>удостоверение о повышении ква&shy;лифи&shy;кации</span>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/political-style"
  }
};

export const designAndDecoration = {
  head: (
    <>
      Профессия:
      <br />
      Дизайнер интерьера
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "9 мес. Очно или Онлайн",
  specialisation: (
    <>
      Консультирование клиен&shy;тов по стилю в интерьере.
      Профес&shy;сио&shy;наль&shy;ная разра&shy;ботка интерь&shy;ера жилых
      поме&shy;щений, 2D и 3D визуали&shy;зация
    </>
  ),
  price: false,
  process: (
    <span>
      4 уровня с прак&shy;тичес&shy;кими блоками, на кото&shy;рых студен&shy;ты
      закреп&shy;ляют полученные знания и навыки, используя
      профес&shy;сиональ&shy;ные инстру&shy;менты и профиль&shy;ные
      компью&shy;терные прог&shy;раммы. Оцен&shy;кой полу&shy;ченных знаний и
      навыков являются про&shy;межу&shy;точные тести&shy;рования и
      диплом&shy;ная работа.
      <br />
      Курс разра&shy;ботан на основе между&shy;народ&shy;ных
      стан&shy;дар&shy;тов профес&shy;сии
    </span>
  ),
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/living-interior-design-and-decoration"
  },
  diplom: "диплом установленного государством образца"
  // space: "3.8rem"
};

export const visualMerchendesign = {
  head: (
    <>
      Визуальный мерчендайзинг и витринистика
      <br />в индустрии моды
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "6 мес. Очно или Онлайн",
  specialisation: (
    <>
      Реализация техно&shy;логий для орга&shy;низа&shy;ции и
      сти&shy;мули&shy;рования продаж в розничных мага&shy;зинах мод&shy;ной
      инду&shy;стрии
    </>
  ),
  price: false,
  process: (
    <Paragraph
      style={{ ...styles.p, padding: 0 }}
      content={[
        <>
          Основы визуаль&shy;ного мер&shy;чен&shy;дай&shy;зинга и
          витри&shy;нис&shy;тики
        </>,
        <>Организация простран&shy;ства ма&shy;га&shy;зина</>,
        <>Мерчендайзинг как инстру&shy;мент успеш&shy;ных продаж</>,
        <>Витрина как коммуни&shy;кация: эстетика и продажи</>,
        <>Женская стилис&shy;тика, муж&shy;ская сти&shy;лис&shy;тика</>,
        <>Карьера и бизнес. Лич&shy;ный брен&shy;динг</>
      ]}
    />
  ),
  diplom: "диплом установленного государством образца",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/visual-merchandising-and-showcases-design"
  }
};

export const fashionFranshising = {
  head: <>Fashion франчайзинг. Стратегии выбора</>,
  typeEducation: "Повышение квалификации",
  duration: "2 дня. Очно или Дистанционно",
  specialisation:
    "Практические аспекты франчайзинга на fashion-рынке России, параметры выбора и успешного владения франшизой",
  price: (
    <span>
      10
      <sup>000</sup> руб.
    </span>
  ),
  process:
    "Обучающий модуль создан для предпринимателей, которые хотят открыть свой бизнес по франшизе или уже управляют розницей. Особенное внимание – практике франчайзинга на fashion-рынке России, параметрам выбора и успешного владения франшизой.",
  diplom: "удостоверение о повышении квалификации",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/fashion-franchising"
  }
  // space: "1.4rem"
};

export const aristotwist = {
  head: (
    <>
      Имидж и этикет:
      <br />
      светское и деловое позиционирование
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "1 мес. Дистанционно",
  specialisation:
    "Персо­ны, бизнес, лидеры мнений — практика традиционных коммуникаций на высоком уровне.",
  price: false,
  process: (
    <>
      Самопрезентация, дресс-коды, манеры за столом, принципы гостеприимства,
      эстетизация пространства, корпоративный этикет, нетворкинг, подарки в
      бизнесе.
    </>
  ),
  diplom: "удостоверение о повышении ква­лифи­кации",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/aristotwist"
  }
};

export const styleYourLife = {
  head: (
    <>
      Консультант по стилю.
      <br />
      Быстрый старт
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "4 мес. Очно или Дистанционно",
  specialisation:
    "Консультирование частных клиентов по имиджу и стилю с учетом индивидуальных особенностей и модных тенденций. Психология монетизации знаний",
  price: false,
  process: (
    <>
      Концепция стилевых направлений
      <br />
      Формирование индивидуального стиля
      <br />
      Силуэты: способы визуальной коррекции фигур
      <br />
      Колористика и психология цвета
      <br />
      Формирование рационального гардероба
      <br />
      Современная мода как коммуникация и как бизнес
    </>
  ),
  diplom: "диплом государственного университета",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/style-your-life"
  }
};

export const proMotionDesign = {
  head: (
    <>
      Основы motion design
      <br />и анимации
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "1 мес. Дистанционно",
  specialisation:
    "Создание креативных анимационных роликов с нуля: от идеи до готовой работы.",
  price: false,
  process: "",
  diplom: "удостоверение о повышении ква­лифи­кации",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/pro-motion-design"
  }
};

export const interiorYourLife = {
  head: (
    <>
      Интерьерный стилист
      <br />
      Декоратор
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "3,5 мес. Дистанционно",
  specialisation:
    "Для начинающих — это первый шаг к профессии дизайнера или к осознанному оформлению своего пространства",
  price: false,
  process: (
    <>
      Стили, колористика, композиция, освещение, мате­риалы
      <br />
      Зонирование интерьера, эрго­но­мика
      <br />
      Подготовка сметы, бюд­жети­рова­ние
      <br />
      Проектирование и визуализация
      <br />
      Работа со специалистами из смежных областей
      <br />
      Коучинг наставника: как найти баланс с самим собой, клиентом, подрядчиками
    </>
  ),
  diplom: "диплом о профессиональной переподготовке",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/interior-your-life"
  }
};

export const businessAnalitic = {
  head: (
    <>
      Бизнес аналитик
      <br />в IT сфере
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "2 мес. Дистанционно",
  specialisation:
    "Обучение продуктовой методологии в IT, ведению фреймворками Scrum, Kanban, работе в Agile команде. 80% трудоустройство от 100К",
  price: false,
  process: false,
  diplom: false,
  isNotSpoler: true,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/business-analitic"
  }
};

export const aibotProductowner = {
  head: (
    <>
      Управление разработкой
      <br />
      диалоговых систем с ИИ
    </>
  ),
  typeEducation: "Профессиональная переподготовка",
  duration: "6 мес. Онлайн",
  specialisation:
    "Обучение управлению разработками и созданием автоматизированных диалоговых систем с элементами NLP, ML/DL (искусственного интеллекта)",
  price: false,
  process: (
    <>
      Основы компьютерной лингвистики
      <br />
      Проектирование диалоговых систем
      <br />
      Программирование на платформе JAICP
      <br />
      Машинное обучение
      <br />
      Тестирование
      <br />
      Анализ эффективности диалоговых систем
      <br />
      Управление разработкой диалоговых систем
    </>
  ),
  diplom: <>диплом установ­ленного образца о профессиональной переподготовке</>,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/aibot-productowner"
  }
};

export const bFashionSprint = {
  head: (
    <>
      Business Fashion Sprint:
      <br />
      акселератор для предпринимателей
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "2 мес. Онлайн",
  specialisation:
    "Для предпринимателей, дизайнеров, проектов, сервисов. Эффективные инструменты, образ мышления, вход в мировое fashion бизнес-сообщество.",
  price: false,
  process: (
    <>
      Определение каналов продаж, схемы запуска бизнеса в США и Европе
      <br />
      Бизнес модель и финансовый план
      <br />
      Первые продажи и презентация проекта на Demo Day
      <br />
      Запуск своего NFT
      <br />
      Создание презентации для инвесторов
      <br />
      Возможность выиграть денежный и другие призы партнеров
    </>
  ),
  diplom: "удостоверение о повышении ква­ли­фи­ка­ции",
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "insideLink",
    text: "подробнее",
    link: "/bfashionsprint"
  }
};

/*
export const nameCourse = {
  head: "",
  typeEducation: "",
  duration: "",
  specialisation: "",
  price: "",
  process: (<></>),
  diplom: "",
  link: {
    type: "",
    text: "",
    link: ""
  },
  link2: {
    type: "",
    text: "",
    link: ""
  }
};
*/
