import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import {
  BuilderLayout,
  HeadBlock,
  Programma,
  Teachers,
  TextAndTags,
  TitleH2
} from "../../patterns/pattern-fs/components";
import { HorizontalForm } from "../../components/forms";

const Fashion = () => {
  return (
    <div>
      <Helmet>
        <title>Обучение символике цвета и психология его восприятия</title>
        <meta
          name="keywords"
          content="психология, цвета, тренд, культура, обучение, где, учиться, школа, курсы"
        />
        <meta
          name="descript"
          content="Курс Марины Бонецкой по психологии цвета — цикл иллюстрированных лекций, посвященных семантике цветов в мировой культуре. Цель обучения — понять воздействие цвета на человека и умение применять это знание, осознать влияние цвета на моду и социум в целом. Вы научитесь гармонизировать свою жизнь и жизнь близких людей. По окончании обучения выдается сертификат."
        />
      </Helmet>
      <Page lable="Психология цвета" lableMobile="Психология цвета">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <TextAndTags
              content={{
                text: c.text1,
                tags: false
              }}
            />,
            <Programma content={{ programma: c.programma }} />,
            <Teachers content={{ teachers: c.teachers, teacherHead: false }} />,
            // c.pay,
            <>
              <TitleH2 content="Получите квалифицированную консультацию специалиста учебного отдела" />
              <HorizontalForm />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
