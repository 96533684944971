import React from "react";
import { Grid } from "@material-ui/core";
import Modern from "../../components/banner-modern-moda-style2";
import Interior from "../../components/banner-interior-your-life";
import Bot from "../../components/banner-bot-valeriya";

function Banners(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Modern />
      </Grid>
      <Grid item xs={12} md={4}>
        <Interior />
      </Grid>
      <Grid item xs={12} md={4}>
        <Bot />
      </Grid>
    </Grid>
  );
}

export default Banners;
