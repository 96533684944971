import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>Курсы стилистов, дизайнеров, имиджмейкеров в Казахстане</title>
        <meta
          name="descript"
          content="Обучение стилистов, дизайнеров, имиджмейкеров в Казахстане. Программы профессиональной переподготовки и повышения квалификации в индустрии моды и дизайна. Академическое образование и реальная очная практика."
        />
        <meta
          name="keywords"
          content="казахстан, нур-султан, астана, алматы, караганда, курсы, обучение, имиджмейкер, стилист, школа, учиться, где, профессия"
        />
      </Helmet>
      <PageGlobal lable="Казахстан" filial="Казахстан">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
