import React from "react";
import Page from "../../patterns/pattern-educations/page";
import { content } from "./content";

function Education(props) {
  return (
    <Page
      title="Открытое образование ВШСДТ"
      lable="Открытое образование"
      keyword="открытое образование, бесплатно, курсы, обучение, лекции"
      description="Открытое образование ВШСДТ: лекции и курсы в открытом доступе без условий и оплаты, обучение профессиям и навыкам по грантам и социальным программам"
      content={content}
    />
  );
}

export default Education;
