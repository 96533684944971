import React from "react";
import { stylesDark, styles } from "../imagemaker-stylist/styles";
import { withStyles } from "@material-ui/styles";
import { contents } from "./content";
import { OneNews } from "../root-test2/news";
import { news, findNewsById } from "../news/content";
import {
  BuilderLayout,
  TitleH2,
  CardImage,
  CardImageText,
  ShessBlock,
  SocnetBlock,
  Actions,
  Text3Block,
  MessageOnPaper,
  Cards3,
  VideosTile,
  ScrollingLink,
  Message
} from "../../patterns/pattern-fs/components";
import { MiniForm } from "../../components/forms";
import { StringContacts } from "../../components/string-contact/string-contact";
import Paragraph from "../../components/p-array";
import { Grid } from "@material-ui/core";
import { DateToString } from "../shedule/dates";
import { schedule } from "../../contents/schedule-filials";

const wStyles = theme => ({
  ...stylesDark,
  ...styles
});

function Top(props) {
  return (
    <div>
      <>
        <BuilderLayout
          contents={[
            <>
              <ShessBlock
                content={{
                  left: contents.head.left,
                  right: contents.head.right
                }}
              />
              <StringContacts
                title="По вопросам обучения:"
                contacts={contents.contacts}
              />
            </>,
            <Text3Block content={contents.text3block} />,
            <>
              <TitleH2 content="Официальный представитель в Каннах" />
              <ShessBlock
                content={{
                  left: contents.teachers.moatti.text,
                  right: contents.teachers.moatti.image
                }}
              />
            </>,
            <Grid container justify="center">
              <Grid item xs={12} md={10}>
                <Paragraph
                  content={contents.text}
                  classElement="p-constructor"
                />
              </Grid>
            </Grid>,
            <>
              <TitleH2 content="Расписание курсов" />
              {schedule
                .sort((a, b) => a.date.getTime() - b.date.getTime())
                .map(el => (
                  <>
                    <p className="p-constructor">
                      <strong>
                        {DateToString(el.date)} {el.bold}
                      </strong>
                      <br />
                      {el.normal}
                    </p>
                  </>
                ))}
              {/* <CreateListSchedule start={new Date(2021, 8, 1)} last={new Date(2021, 8, 30)} /> */}
              <TitleH2 content="Новости ВШСДТ" />
              <OneNews content={findNewsById("160921", news)} />
              <OneNews content={findNewsById("17082021", news)} />
              <OneNews content={findNewsById("0002", news)} />
            </>,
            <>
              <TitleH2
                content="Профессиональное образование"
                scroll="programma"
              />
              <ShessBlock
                content={{
                  left: <CardImage content={contents.cardImagemaker.image} />,
                  right: (
                    <CardImageText content={contents.cardImagemaker.text} />
                  )
                }}
                mediaLeft={true}
                mobileMediaUp={true}
              />
            </>,
            <ShessBlock
              content={{
                right: (
                  <CardImage content={contents.cardConsultingStyle.image} />
                ),
                left: (
                  <CardImageText content={contents.cardConsultingStyle.text} />
                )
              }}
              mediaLeft={false}
              mobileMediaUp={true}
            />,
            <ShessBlock
              content={{
                left: (
                  <CardImage
                    content={contents.cardLivingInteriorDesign.image}
                  />
                ),
                right: (
                  <CardImageText
                    content={contents.cardLivingInteriorDesign.text}
                  />
                )
              }}
              mediaLeft={true}
              mobileMediaUp={true}
            />,
            <ShessBlock
              content={{
                right: (
                  <CardImage content={contents.cardInteriorYourLife.image} />
                ),
                left: (
                  <CardImageText content={contents.cardInteriorYourLife.text} />
                )
              }}
              mediaLeft={false}
              mobileMediaUp={true}
            />,
            <>
              <TitleH2
                content="Записаться на пробные занятия, получить программы курсов или квалифицированную консультацию"
                scroll="register"
              />
              <MiniForm formName="miniforma-smolensk" />
            </>,
            <>
              <TitleH2 content="Что дает обучение? Изменения в жизни, реализацию планов или творческое удовлетворение?" />
              {/*<ShessBuilder content={contents.textAndMedia} vAlign="center" />*/}
              <ShessBlock
                content={{
                  left: contents.textAndMedia[0].media,
                  right: contents.textAndMedia[0].text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <ShessBlock
              content={{
                left: contents.textAndMedia[1].text,
                right: contents.textAndMedia[1].media
              }}
              vAlign="center"
            />,
            <>
              <ScrollingLink scrollLink={contents.textAndMedia[2].scroll} />
              <ShessBlock
                content={{
                  left: contents.textAndMedia[3].media,
                  right: contents.textAndMedia[3].text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <MessageOnPaper content={contents.garanty} />,
            <>
              <TitleH2 content="О школе" scroll="about" />
              <ShessBlock
                content={{
                  left: contents.about.text,
                  right: contents.about.image
                }}
              />
            </>,
            <Actions content={contents.teachersAndGraduatesInProjects} />,
            contents.education,
            <>
              <TitleH2 content="Форматы обучения. Стоимость" scroll="price" />
              <Cards3 content={contents.formats} />
            </>,
            <>
              <TitleH2
                content="Смотрите больше историй успеха"
                scroll="graduates"
              />
              <VideosTile content={contents.videos} />
            </>,
            <Message content={contents.youtube} />,
            <SocnetBlock noMessengers noMskPhone noSpace />
          ]}
        />
      </>
    </div>
  );
}

export default withStyles(wStyles)(Top);
