import React from "react";
import "./style.css";

export function InfoGraphica(props) {
  return (
    <div className={`${props.noShess ? "no-shess " : ""} infographica-box`}>
      <div
        className={
          props.noShess ? "pseudo-graphica-no-shess" : "pseudo-graphica"
        }
      >
        {props.picture}
      </div>
      <div
        className={`${
          props.noShess ? "text-no-shess" : "text-hover"
        } text-infographica-f-p-m`}
      >
        {props.text}
      </div>
    </div>
  );
}

export function ShessBuilder(props) {
  const { picture } = props;
  let flag = false;
  let n = 1;
  let flagNS = props.noShess;
  let strPicture = picture.toString();
  let word = props.pictureWord || "";
  if (strPicture === "digital") flag = true;
  if (strPicture.indexOf("start") >= 0) {
    n = +strPicture[5];
    flag = true;
  }
  let noShessClass = "shess-item-no-shess-large";
  // if (props.noShessLarge) noShessClass = "shess-item-no-shess-large"
  return (
    <div className="shess-box">
      {props.datas.map((el, k) => (
        <div
          className={
            flagNS ? noShessClass : k % 2 ? "shess-item-1" : "shess-item-2"
          }
          key={`shess-${new Date()}-${k}`}
        >
          <InfoGraphica
            picture={
              flag ? (
                <div
                  className="digital"
                  style={{ fontFamily: "Playfair Display" }}
                >
                  <div className="digital-word">{word}</div>
                  {k + n}
                </div>
              ) : (
                picture
              )
            }
            text={el}
            noShess={flagNS}
          />
        </div>
      ))}
    </div>
  );
}
