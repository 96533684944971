import React from "react";
import Grid from "@material-ui/core/Grid";

import Expand from "../../components/expanded";
import H2 from "../../components/head1";

export default function Programma() {
  var style = {
    summary: {
      fontSize: "1em",
      fontWeight: 700,
      textAlign: "left",
      padding: "0 10px",
      background: "rgba(230,230,230,1)",
      color: "rgb(80,80,80)"
    },
    details: {
      fontSize: "1em",
      fontWeight: 400,
      textAlign: "left",
      padding: "0 10px",
      background: "rgba(240,240,240,1)",
      color: "rgb(80,80,80)"
    }
  };

  var content = [
    {
      summary: "1. Офисный блок работы",
      details: (
        <div>
          <p>
            <b>Ассортимент.</b>
             Принципы деления ассортимента на категории: гендерный,
            функциональный, коллекционный, брендовый, ценовой. Формирование
            приоритетности и значимости каждой из категорий. Формирование
            товарного запаса. Определение размерной линейки. Смена коллекций,
            сезонные распродажи, стимулирование продаж отдельных категорий
            товара.
          </p>
          <p>
            <b>Контрольные документы.</b>
             Мерчандайзинг бук: функция и назначение, содержание, объем, форма
            подачи информации, этапы создания, для кого создается, периодичность
            обновления, взаимосвязь с концепт-буком и бренд-буком, примеры
            мерчандайзинг-буков. Чеклист: принципы создания, обязательные
            пункты, дополнительные пункты. Мерчандайзинг-паспорт: для чего
            нужен, этапы создания, наполнение и содержание. Планограмма:
            определение, назначение, создание, периодичность обновления.
            Спецификация торгового оборудования и POSM: формат и назначение
            документа, кто использует данный документ и для каких целей,
            обновление документа и поддержание его в актуальном состоянии.
          </p>
          <p>
            <b>Обучения стандартам ВМ для персонала.</b>
             Календарь обучения, программа обучения, способы обучения, контроль
            знаний, программа развития персонала, мотивация на обучение.
          </p>
          <p>
            <b>Аналитика и статистика, расчет количественных характеристик.</b>
             Продажи по категории, поартикульные продажи, остатки
            промо-ассортимента, продажи с квадратного метра, коэффициент
            установочной площади, коэффициент экспозициальной площади, «доля
            полки», оборачиваемость ассортимента, вместимость, наполняемость,
            длина траектории движения покупателя, площадь видимой зоны
            ассортимента с ключевых точек магазина, площадь «золотого
            треугольника», периодичность точек фокуса, конверсия, средний чек,
            количество позиций в чеке, проходимость, среднее время нахождения
            покупателя в магазине.
          </p>
        </div>
      )
    },
    {
      summary: "2. Полевой блок работы",
      details: (
        <div>
          <p>
            <b>Витрина.</b>
             Принципы проектирования и оформления витрины, технические
            требования к витринам, вывеска как часть оформления витрины.
            Градация витрин, степень важности. Использование манекенов в
            витрине, использование современных технологий при оформлении
            витрины, использование графики. Сезонная смена витрин, витрины для
            распродаж, календарь смены витрины, подборка ассортимента для
            витрин, информационное наполнение витрины.
          </p>
          <p>
            <b>Торговый зал.</b>
             Принципы организации торгового пространства, принципы зонирования,
            принципы организации и распределения покупательских потоков,
            использование манекенов в торговом зале, особенности формирования
            входной группа, принципы организации и размещения зоны примерочных и
            кассовой зоны, горячие холодные зоны в магазине. Точки фокуса в
            торговом зале. Создание зон распродаж в магазине. Сезонное и
            событийное перезонирование. Использование декора в оформлении
            торгового зала.
          </p>
          <p>
            <b>Торговое оборудование.</b>
             Типы торгового оборудования. Торговое оборудование для различных
            зон магазина: примерочные, кассы, входная группа. Размеры торгового
            оборудования, материалы торгового оборудования, цветовое решение
            торгового оборудования.
          </p>
          <p>
            <b>Освещение.</b>
             Освещение в торговом зале и на витрине – принципы и основы.
            Особенности восприятия и передачи цвета при использовании разных
            типов светильников. Минимально необходимая освещенность, общее,
            точечное и другие виды освещения. Выставление света (подсветка
            ассортимента). Особенности восприятия насыщенности (яркости) света.
            Влияние света (его основных характеристик: яркость, холодность,
            спектральность, направленность) на поведение покупателей в сравнении
            с аромокоммуникацией и аудиокоммуникацией.
          </p>
          <p>
            <b>Выкладка.</b>
             Принципы выкладки на островном оборудовании, принципы выкладки на
            пристенном оборудовании, выкладка в прикассовой зоне. Особенности
            выкладки разных категорий товара (текстиль, нижнее белье, верхняя
            одежда, обувь, сумки, аксессуары). Перекрестная выкладка, принципы
            выкладки импульсного и сопутствующего товара, использование POSM при
            выкладке, особенности выкладки капсульных коллекций,
            дисконт-выкладка.
          </p>
        </div>
      )
    },
    {
      summary: "3. Проектный блок работы",
      details: (
        <div>
          <p>
            <b>Открытие нового магазина.</b>
             Подготовка данных для отдела закупок. Подготовка данных для отдела
            строительства. Подготовка данных для отдела эксплуатации. Этапы,
            сроки.
          </p>
          <p>
            <b>Разработка торгового оборудования.</b>
             Разработка технического задания &mdash; основные моменты. Эскизы:
            способы подготовки. Пилотные образцы и их производство. Выбор
            поставщика/производителя, сроки поставок, договор поставки, оплата.
            Монтаж и шеф-монтаж торгового оборудования, гарантия.
          </p>
          <p>
            <b>Реконструкция&nbsp;/&nbsp;перезонирование магазина.</b>
             Причины реконструкции/перезонирования: сезонность,
            увеличение/уменьшение площади, новая концепция/дизайн, оптимизация.
            График работ по перезонированию, график поставок и вывоза
            ассортимента, определение трудозатрат, планируемые результаты.
          </p>
        </div>
      )
    },
    {
      summary: "4. Разбор ситуаций и домашних заданий",
      details: (
        <div>
          <p>
            <b>Визуальный блок.</b>
             Стиль, красота, гармония, эстетика, чувственность, эмоциональность,
            яркость. Что легло в основу идеи, какие принципы, правила стиля,
            комбинаторики, колористки, ритма можно применять или используем
            принцип "вне всех правил &mdash; творческий бунт". В рамках данного
            блока будет рассматриваться домашнее задание по подготовке
            предложения по оформлению витрины, выкладки, расстановки торгового
            оборудования, концепции торгового оборудования (материал, изучаемый
            в разделе «Полевой блок работы»).
          </p>
          <p>
            <b>Коммерческий блок.</b>
             Экономическая эффективность зонирования и расстановки торгового
            оборудования, соответствие между «долей полки» и долей продажи,
            расчет коэффициентов установочной площади, длительность пребывания
            покупателя в магазине, количество сопутствующего и импульсного
            товара в чеке. В рамках данного блока будут рассматриваться домашние
            задание по оценке эффективности мероприятий по мерчандайзингу
            (материал, изучаемый в разделе «Офисный блок работы»).
          </p>
          <p>
            <b>Организационный блок.</b>
             Последовательность действий, набор документов, принципы организации
            мероприятий по мерчандайзингу. В рамках данного блока будут
            рассматриваться задание по подготовке списка последовательности
            действий при открытии магазина, содержания (разделы) мерчандайзинг
            бука, формирования чек-листа по мерчандайзингу (материал, изучаемый
            в разделах «Офисный блок работы» и «Проектный блок работы»).
          </p>
        </div>
      )
    }
  ];

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <H2 content="Содержание курса" />
      </Grid>
      {content.map((el, index) => (
        <Grid item xs={12} md={10}>
          <Expand
            summary={{
              text: el.summary,
              style: style.summary
            }}
            details={{
              text: el.details,
              style: style.details
            }}
            key={index}
          />
        </Grid>
      ))}
    </Grid>
  );
}
