import React from "react";
import { Link } from "react-router-dom";
import { dates } from "../pages/shedule/dates";

export const schedule = [
  {
    date: dates.imagemaker.online,
    bold: (
      <>
        <Link to="/second-degree-imagemaker">
          Профессия: стилист-имиджмейкер как второе высшее
        </Link>
      </>
    ),
    normal: "Годовое очное / дистанционное обучение."
  },
  /*
  {
    date: dates.baseImageStyle.online,
    bold: (
      <>
        <Link to="image-style">Стили одежды: правила подбора для женщин</Link>
      </>
    ),
    normal: "Заочное / дистанционное обучение, 3 месяца."
  },
  */
  {
    date: dates.designInterior.online,
    bold: (
      <>
        <Link to="/living-interior-design-and-decoration">
          Профессия: дизайнер интерьера
        </Link>
      </>
    ),
    normal: "Дистанционное обучение, 9 месяцев."
  }
  /*
  {
    date: dates.idfw.online,
    bold: (
      <>
        <Link to="/idfw">
          International Digital Fashion Week как заявить о себе на весь мир
        </Link>
      </>
    ),
    normal: "Заочное / дистанционное обучение, 1 месяц."
  },
  {
    date: dates.visualMerchandising.online,
    bold: (
      <>
        <Link to="/visual-merchandising-and-showcases-design">
          Визуальный мерчендайзинг и витринистика
        </Link>
      </>
    ),
    normal: "Заочное / дистанционное обучение, 6 месяцев."
  },
  {
    date: dates.interiorYourLife.online,
    bold: (
      <>
        <Link to="/interior-your-life">Профессия: интерьерный стилист</Link>
      </>
    ),
    normal: "Заочное / дистанционное обучение, 3.5 месяца."
  },
  {
    date: dates.styleYourLife.online,
    bold: (
      <>
        <Link to="/style-your-life">Консультант по стилю. Быстрый старт</Link>
      </>
    ),
    normal: "Заочное / дистанционное обучение, 3.5 месяца."
  }
  */
];
