import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { dates, Month } from "../shedule/dates";
import { style } from "./style";

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Главные тенденции современной моды",
    descript: [
      "Онлайн",
      "Новые идеи за 1 месяц регулярных занятий в мини группе",
      "Длительность — 16 занятий по полтора часа",
      "Стоимость 30 тыс. рублей"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Аналитика моды. Для спе­циа­листов индус­трии мо­ды и красоты
      </div>
    ),
    text: "",
    imageSource: "/img/trends-of-modern-fashion/trends-of-fashion.jpg",
    video: "7jVcZHuP4MU",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    }
  },
  // dates: {
  //   online: {
  //     day: dates.trendsOfModernFashion.online.getDate(),
  //     month: Month(dates.trendsOfModernFashion.online.getMonth()),
  //     type: "дистанционно"
  //   },
  //   ofline: false
  // },
  screen2: {
    dates: false,
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>Для кого</span>
      )
    },
    text: (
      <List
        arr={[
          "Специалистов и профессионалов индустрии моды и дизайна",
          "Имиджмейкеров / Стилистов / Визажистов",
          "Блоггеров / Фотографов / Видеографов",
          "Людей, которые хотят занять свое место в fashion индустрии"
        ]}
      />
    ),
    video: "7jVcZHuP4MU",
    videoComment: true
  },
  text1:
    "Краткий курс дает представление об основных стратегиях развития индустрии моды эпохи торжества технологий, относительности границ и множества способов самопрезентации. Создание дизайнерами образа – процесс почти сродни искусству самым непосредственным образом связан с социальными процессами и маркетинговыми стратегиями.",
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель курса
          </h2>,
          "Цель курса — исследовать суть и направления современного искусства и рекламы, открытия и разработки в области текстиля, огромные возможности интернет-технологий, изменение отношения к потреблению и экологической безопасности, освоение новых рынков продаж и интерес к локальным брендам, движение от сложности и иронии и искренности и простоте — именно эти и иные процессы «перерабатывает» мода, когда создает визуальный образ настоящего и будущего.",
          <>
            Мода как форма доступного искусства.
            <br />
            <List
              style={styles.p}
              arr={[
                "«Экономика впечатлений» и «общество художников»: образ и эмоция",
                "Основные стили дизайна костюма. Эклектика",
                "Метод переработки классики и создания иного – деконструктивизм.",
                "Свобода самовыражения: полистилизм, ugly fashion, normcore",
                "«Уличная» мода. Субкультуры как источник образов",
                "Творчество и экономика. Место и роль дизайнера в новой системе"
              ]}
            />
          </>,
          "Тенденция «бедности» моды и образов",
          "Тенденция разумного потребления: non-fashion, slow fashion",
          "Эко-направление. Ресйклинг.",
          "Постоянство этники. Развитие рынка «мусульманской» моды.",
          "Модные показы: суть изменений."
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения:
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Записи лекций",
          "Онлайн семинары с автором",
          "Консультации по выполнению практических работ",
          "Проверка / тестирование",
          "Практическая работа"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат обучения
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Знание внутренних и внешних механизмов развития моды, выраженных в трендах и тенденциях",
          "Умение анализировать коллекции на основе знаний о ведущих тенденциях и писать о них"
        ]}
        num="none"
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  teacherHead: "Преподаватель",
  pay: {
    distant: {
      textButton: "Дистанционное обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: сертификат
            <br />
            Длительность: 12 видео занятий по 2 академических часа
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          9<sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>990 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 9<sup>900</sup>
                        рублей
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  },
  payOpen: 3
};
