import React from "react";
import Video from "../../components/video";
import { styles } from "./styles";
import Paragraph from "../../components/p-array";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Space from "./space-mobile";

const wStyles = theme => ({
  ...styles,
  link: {
    color: "rgb(80,0,0)",
    transition: "color .5s",
    "&:hover": {
      color: "rgb(160,0,0)"
    }
  }
});

const ItemLinks = withStyles(wStyles)(props => {
  const { classes } = props;

  return (
    <div style={{ paddingLeft: "3rem", paddingBottom: "3rem" }}>
      <Link to={props.link}>
        <p style={{ ...styles.text, ...styles.titleBig }}>{props.title}</p>
      </Link>
      <p className={classes.text}>{props.duration}</p>
      <p className={classes.text}>
        {props.descript}
        &ensp;
        <Link to={props.link}>
          <span className={classes.link}>подробнее…</span>
        </Link>
      </p>
    </div>
  );
});

export const content = {
  screen1: {
    left: <Video link="fOZzI3bKXIU" h={360} w={400} />,
    right: (
      <div>
        <h3 style={{ ...styles.head, paddingLeft: "3rem" }}>
          Профессиональное обучение
        </h3>
        <Paragraph
          style={styles.text}
          content={[
            <ItemLinks
              link="/second-degree-imagemaker"
              title="Профессия: стилист-имиджмейкер"
              duration="Годовое профессиональное обучение. Диплом"
              descript="Сложная и насыщенная программа: от персональной стилистики до продюсирования fashion проектов "
            />,
            <ItemLinks
              link="/living-interior-design-and-decoration"
              title="Профессия: дизайнер интерьеров"
              duration="Профподготовка 9 месяцев. Диплом"
              descript="Вектор: проектирование и стилизация интерьеров жилых помещений от идеи до сметы и проекта в Archiсad и 3Dmax"
            />,
            <ItemLinks
              link="/fashion-styling"
              title="Профессия: стилист фотосессий"
              duration="Повышение квалификации, 3.5 месяцев."
              descript="Вектор: стилизация фотосессий, модных показов, сотрудничество с фэшн брендами. Практика фотосессий в процессе обучения"
            />,
            <ItemLinks
              link="/visual-merchandising-and-showcases-design"
              title="Профессия: fashion визуальный мерчендайзинг"
              duration="Профподготовка 6 месяцев. Диплом"
              descript="Технологии для организации пространства магазина и стимулирования продаж с помощью визуальных коммуникаций в модной индустрии"
            />,
            // <Link to="/pro-education">
            //   <p style={{ ...styles.text, paddingTop: "2rem" }}>
            //     Перейти в раздел профессионального образования
            //   </p>
            // </Link>,
            <Space />
          ]}
        />
      </div>
    )
  },
  titleProjects: "Проекты & Партнеры 2022",
  titleScr2: (
    <h3 style={{ ...styles.head }}>Профессиональное образование 2022</h3>
  ),
  profEdu: [
    // {
    //   link: "/imagemaker-stylist",
    //   title: "Профессия: стилист-имиджмейкер",
    //   duration: "Профподготовка от 3,5 до 12 месяцев. Диплом",
    //   descript:
    //     "Сложная и насыщенная программа: от персональной стилистики и Style book до стилизации и продюсирования fashion фотосессий."
    // },
    {
      link: "/imagemaker-stylist",
      title: "Профессия: стилист, тренд-аналитик",
      duration: "Профподготовка от 3,5 до 12 месяцев. Диплом",
      descript:
        "Программы от базового до продвинутого уровня по направлениям: консультант по стилю, стилист-имиджмейкер, стилист фотосессий, стилист-аналитик."
    },
    {
      link: "/interior-design-courses",
      title: "Профессия: интерьерный стилист, дизайнер",
      duration: "Профподготовка от 3,5 месяцев. Диплом",
      descript:
        "От базовых знаний к проектированию и стилизации интерьеров жилых помещений, от идеи до сметы до проектов в Archiсad и 3Dmax."
    },
    {
      link: "/chatbot",
      title: "IT профессия: дизайнер чат-ботов",
      duration: "Повышение квалификации, 2 месяца",
      descript:
        "Создание чат-ботов на базе конструктора с ИИ. Потенциал трудоустройства с окладом от 60К. Документ ВУЗа с международным рейтингом."
    },
    {
      link: "/business-analitic",
      title: "IT профессия: бизнес аналитик",
      duration: "Повышение квалификации, 2 месяца",
      descript:
        "Лучшим поддержка в трудоустройстве с окладом от 100К. Обучение продуктовой методологии и бизнес аналитике в IT проектах."
    }
  ],
  screen2: {
    right: <Video link="8s_7e-79VUI" h={360} w={400} />,
    left: (
      <div>
        <h3 style={{ ...styles.head, paddingLeft: "3rem" }}>
          Краткосрочные курсы
        </h3>
        <Paragraph
          style={styles.text}
          content={[
            <ItemLinks
              link="/image-style"
              title="Стили в одежде. Принципы подбора"
              duration="Повышение квалификации, 3 месяца."
              descript="Путь в индустрии моды через профессиональный подбор одежды и аксессуаров. Системное, осознанное создание модных комплектов. Доступен для начинающих."
            />,
            <ItemLinks
              link="/self-style"
              title="Сам себе стилист"
              duration="Практикум-преображение, 1 месяц"
              descript="Чтобы уверенно говорить о моде, давать советы близким и друзьям, быть свободной и следовать своему стилю, меняться и вдохновлять."
            />,
            <ItemLinks
              link="/image-communication"
              title="Имидж как коммуникация и продвжение"
              duration="Прокачка soft skill, 6 месяцев"
              descript="Соответствовать статусу Топ специалиста. Правильно представлять себя через костюм, фото контент, текст, соц сети."
            />,
            <ItemLinks
              link="/interior-design"
              title="Консультант по дизайну интерьера"
              duration="Интенсивный курс, 3 месяца."
              descript="Для начинающих - это первый шаг в профессию или к осознанному оформлению своих помещений: через идею, moodboard, проект"
            />
          ]}
        />
      </div>
    )
  },
  // videos: ["fOZzI3bKXIU", "8s_7e-79VUI", "OGMn5EEjw44"],
  videos: ["fOZzI3bKXIU", "rYKZAsSnC84", "gZeWO85gha0"],
  links: [
    {
      link: "/pro-education",
      text: "Профессиональное образование"
    },
    {
      link: "/video",
      text: "Краткосрочные курсы"
    },
    {
      link: "/practice",
      text: "Практики, стажировки"
    },
    {
      link: "/young-fashion",
      text: "Детская студия"
    },
    {
      link: "/master-class",
      text: "Эфиры, встречи"
    }
  ]
};
