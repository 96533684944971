import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Гилёва Анна Викторовна",
        family: "Гилёва",
        metaKeywords: "",
        metaDescript: "",
        head: "Гилёва Анна Викторовна",
        image: "/img/graduates/gilyova-anna.jpg",
        text: {
          edu: "Закончила ВШСДТ в 2021 году",
          exp: false,
          motto: "Стиль – это не про одежду, это про личность"
        }
      }}
    />
  );
}
