import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import Expand from "../../components/expanded";
import List from "../../components/list";
import { Slide } from "react-reveal";

let style = {
  summary: {
    // fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 700,
    textAlign: "left",
    color: "rgb(100,100,100)",
    background: "rgb(230,230,230)"
  },
  details: {
    // fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    textAlign: "left",
    color: "rgb(100,100,100)",
    background: "rgb(245,245,245)"
  },
  text: {
    // fontFamily: "Roboto",
    fontSize: "1em",
    fontWeight: 400,
    textAlign: "left",
    color: "rgb(100,100,100)"
  }
};

let content = [
  {
    summary: {
      text: "1. Современные медиакоммуникации в глобальном мировом пространстве"
    },
    details: {
      text: (
        <List
          arr={[
            "Коммуникация как процесс. Теории коммуникаций",
            "Основные тенденции развития медиа",
            "Интернет как глобальное пространство информации",
            "Персонификация и социализация"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "2. Fashion-журналистика в пространстве культуры"
    },
    details: {
      text: (
        <List
          arr={[
            "Мировые и российские fashion издательства и журналы",
            "История модной журналистики: редакторы, фотографы, модели, стилисты",
            "Модная фотография: история и современность",
            "Механизм работы модного журнала"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "3. Главные лица и тенденции развития современной моды"
    },
    details: {
      text: (
        <List
          arr={[
            "Мода как коммуникация",
            "Мода как бизнес",
            "Мода как искусство",
            "Сегментация брендов: haute couture, lux, pret-a-porte, fast-fashion",
            "«Послание» потребителям – Недели моды в разных столицах мира",
            "Модные коллекции: от идеи до продажи",
            "Что такое модный тренд",
            "Трендсеттеры и гейткиперы моды"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "4. Коммуникация моды через искусство и время"
    },
    details: {
      text: (
        <List
          arr={[
            "История стилей в искусстве",
            "История домов моды XX века",
            "Анализ стилей и их проявлений в современной моде",
            "Анализ модных тенденций и прогнозирование в моде"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "5. Художественный образ как «язык» стиля"
    },
    details: {
      text: (
        <List
          arr={[
            "Структура художественного образа. Стиль и образ",
            "Характеристика классического художественного образа",
            "Особенности художественного образа нач.-середины XX века",
            "Характеристика образа постмодерна. Искусство, мода и текст"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "6. Практика создания современного модного/стильного образа"
    },
    details: {
      text: (
        <List
          arr={[
            "Композиция создания образа. Законы и основные элементы",
            "Создание стильного образа на основе художественного образа. Соотношение формы и содержания",
            "Образно-ассоциативный подход к созданию стильного образа",
            "Модный образ и художественный образ. Визуальные коды"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "7. Создание журналистского произведения"
    },
    details: {
      text: (
        <List
          arr={[
            "Текст как высказывание. Специфика жанра",
            "Структура текста и его составляющие",
            "Тема, идея, проблема",
            "Авторская позиция",
            "Способы организации произведения. Композиция",
            "Образность и выразительность",
            "Критерии оценки"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "8. Создание текста о моде и про моду"
    },
    details: {
      text: (
        <List
          arr={[
            "ыбор жанра текста в зависимости от цели",
            "Fashion-репортажи, статьи, новости, комментарии: практика создания",
            "Как написать статью о модном тренде: от кутюр до уличной моды",
            "Где брать идеи и ваша речевая «маска»",
            "Работа с творческим источником",
            "Выбор заголовка и визуального ряда"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "9. Работа с дизайнерами и дизайнерскими домами"
    },
    details: {
      text: (
        <List
          arr={[
            "Современная fashion индустрия в России",
            "Известные российские fashion-бренды: имена, стили, особенности",
            "Дизайнерский бренд: циклы жизни, экономика",
            "Создание и планирование коллекций: современные материалы, технологии, производство",
            "Как написать статью о дизайнере или бренде. ДНК бренда",
            "Технологии интервью. Виды fashion-интервью",
            "Как написать статью о коллекции и модном тренде",
            "Сотрудничество с дизайнерами и дизайнерскими компаниями",
            "Пресс-дни для fasion-журналистов. Участие в Неделях моды"
          ]}
          style={style.text}
        />
      )
    }
  },
  {
    summary: {
      text: "10. Работа в fashion-компаниях, в ритейле"
    },
    details: {
      text: (
        <List
          arr={[
            "Бренды масс-маркета и люкса: циклы жизни, экономика",
            "Текст как продвижение модного продукта",
            "Статьи для прессы. Особенности рекламной статьи",
            "Как написать статью для сайта компании",
            "Структура и план текста. Поиск авторского стиля. Фотоматериал",
            "Новости и инфоповоды для продвижения. Fashion-события",
            "Работа с партнерскими брендами",
            "Работа с соцсетями и Instagram",
            "Технологии копирайтинга",
            "Участие в разработке и реализации концепции съемки для рекламной фотосессии продукта",
            "Идея, способы и процесс съемки",
            "Работа с командой на площадке (стилист, модель, фотограф, визажист)"
          ]}
          style={style.text}
        />
      )
    }
  }
];

export default function Programm() {
  return (
    <div>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item xs={12} md={12}>
          <h2
            style={{
              fontFamily: "Roboto",
              fontSize: "2em",
              fontWeight: 700,
              textAlign: "center",
              color: "rgb(100,100,100)",
              padding: "30px 0",
              margin: 0
            }}
          >
            Программа курса «Журналистика в индустрии моды»
          </h2>
        </Grid>
        <Grid item xs={12} md={8}>
          {content.map((el, i) => (
            <Slide top key={i}>
              <Expand
                summary={{
                  text: el.summary.text,
                  style: style.summary
                }}
                details={{
                  text: el.details.text,
                  style: style.details
                }}
                key={i}
                expand={true}
              />
            </Slide>
          ))}
        </Grid>
        <Grid item md={4}>
          <Hidden smDown>
            <div style={{ height: "100%" }}>
              <img
                src="/img/profi-fashion-journalist/img2.jpg"
                alt=""
                style={{ maxWidth: "100%", position: "sticky", top: 1 }}
              />
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
}
