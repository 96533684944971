import React from "react";
import Zoom from "react-reveal";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Paragraph from "../../components/p-array";
import Head from "../../components/head-articles";

export default function Kushatj() {
  return (
    <div>
      <Helmet>
        <title>ВШС: Софья Зозуля: Кушать подано… на подиум</title>
        <meta
          name="keywords"
          content="ВШС: Софья Зозуля: Кушать подано… на подиум"
        />
      </Helmet>
      <Top />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ maxWidth: 1000, margin: "0 auto" }}
        spacing={2}
      >
        <Grid xs={12} md={12} style={{ padding: 20 }}>
          <Head
            head="Кушать подано… на подиум"
            author="Софья Зозуля"
            linkAuthor="/sofjya_zozulya"
            vk="https://www.vk.com/sofia.chesnokova"
            fb="https://www.facebook.com/profile.php?id=100002113906771"
            instagram="https://instagram.com/sofyazozulya"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20, paddingTop: 8 }}>
          <Zoom>
            <Paragraph
              j
              content={[
                "Для современного человека еда уже давно стала чем-то большим, чем средством утоления голода. Кулинарные шоу учат нас, что пища должна быть не только вкусной, но и красивой, а мишленовские повара возвели ее приготовление в ранг искусства. Мы проводим досуг в кулинарных клубах и непростительно много времени тратим на обсуждение новейших диет и сложных систем питания. Еда стала отдельной и очень важной частью нашей жизни, поэтому ничего удивительного нет в том, что кулинария смело вышла из кухни на модные подиумы.",
                "Однако, хотя особенно ярко эта тема проявилась в последние десятилетия, началось все гораздо раньше, когда Эльза Скиапарелли, среди прочих авангардных мод."
              ]}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20, textAlign: "center" }}>
          <Zoom>
            <img
              src="/img/blog/kushatj/img1.jpg"
              alt=""
              style={{ maxWidth: "90%" }}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={8} style={{ padding: "40px 20px 10px 20px" }}>
          <Zoom>
            <Grid
              container
              alignItems="stretch"
              spacing={2}
              style={{
                borderTop: "3px solid rgb(200,200,200)",
                borderBottom: "3px solid rgb(200,200,200)"
              }}
            >
              <Grid item xs={12} md={6}>
                <img
                  src="/img/blog/kushatj/img2.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Paragraph
                  j
                  content={[
                    "Сегодня, чтобы получить порцию вдохновения, достаточно просто заглянуть в холодильник. Возможно, так и поступила дизайнер Гретхен Роерс при создании своих оригинальных эскизов, не лишенных хорошей порции юмора. Чего стоят только настоящие брюки-бананы!"
                  ]}
                />
              </Grid>
            </Grid>
          </Zoom>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: "30px 20px 12px 20px" }}>
          <Zoom>
            <div
              style={{
                backgroung: "rgb(255,255,247)",
                border: "3px solid rgb(200,200,200)"
              }}
            >
              <Paragraph
                c
                content={[
                  <img
                    src="/img/blog/kushatj/img3.jpg"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />,
                  "А эта девушка явно не боится лишних килограммов!"
                ]}
              />
            </div>
          </Zoom>
        </Grid>
        <Grid item xs={12} md={8}>
          <Zoom>
            <Paragraph
              j
              content={[
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Еще дальше зашли фотограф Фульвио Бонавиа и повар Пета Матиас, которые не просто использовали лежащие под рукой продукты, а создали в 2008 году целую книгу кулинарного дизайна. Примечательно, что изображенные на фото объекты созданы реально, а не с помощью компьютерной графики. Надо сказать, все аксессуары смотрятся очень эффектно, даже немного жаль, что в баклажановых балетках не пройти и пары шагов!",
                <img
                  src="/img/blog/kushatj/img4.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "За материалами для своих нарядов в ближайший супермаркет заглянула и дизайнер из Нью-Йорка Эмми Гудхарт. Так у нее получились, например, вафельные брюки, хлебная сумка или юбка из мясной нарезки. К слову, свое знаменитое мясное платье Леди Гага надела только через год после того, как эта коллекция увидела свет.",
                "Название «сумка-багет» обретает буквальный смысл!",
                <img
                  src="/img/blog/kushatj/img5.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Возможно, вдохновившись ироничным проектом Гудхарт, свою интерпретацию съедобных костюмов в 2010 году предложила кореянка Сен Йон Чжу. Ее продуктовая коллекция «Wearable foods» оказалась настолько успешной, что девушка до сих пор гастролирует с ней по мировым модным и кулинарным выставкам. Выглядят ее творения, действительно, изысканно и вдохновляюще.",
                <img
                  src="/img/blog/kushatj/img6.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              ]}
            />
          </Zoom>
        </Grid>

        <Grid item xs={12} md={10}>
          <div
            style={{
              width: "100%",
              borderTop: "4px dotted rgb(200,200,200)",
              height: 0,
              margin: "30px 0"
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Zoom>
            <Paragraph
              j
              style={{ padding: 30 }}
              content={[
                "Не заставил себя ждать и уже известный нам Фульвио Бонавиа, в 2011 году создавший совместно со стилистом Джованной Батталья для журнала Garage потрясающие интерпретации нарядов известных брендов: Alexander McQueen из листьев салата, Louis Vuitton из грецких орехов, Prada из апельсинов и Moncler из анчоусов. Успешность этого проекта подтверждается тем, что стилистика брендов читается даже за столь необычными материалами.",
                <span style={{ fontSize: "1.1em" }}>
                  Где-то мы уже видели эти анчоусы!..
                </span>
              ]}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={5}>
          <Zoom>
            <img
              src="/img/blog/kushatj/img7.jpg"
              alt=""
              style={{ maxWidth: "95%" }}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Zoom>
            <Paragraph
              j
              content={[
                <img
                  src="/img/blog/kushatj/img8.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                "Когда слова «мода» и «еда» звучат вместе, нельзя не вспомнить про главного шутника от кутюр Франко Москино. Возможно, Франко любил поработать за завтраком – мы этого не узнаем, но вот уже двадцать лет изображение яичницы-глазуньи является визитной карточкой бренда."
              ]}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Zoom>
            <Paragraph
              j
              content={[
                <img
                  src="/img/blog/kushatj/img9.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                "Продолжатели дела Франко, отдавая дань традициям, создают в 2012 году сумку с ручкой, имитирующей изогнутую вилку – ностальгия по эпатажному смокингу, с успехом продемонстрированному в начале девяностых."
              ]}
            />
          </Zoom>
        </Grid>
        <Grid item xs={12} md={8} style={{ paddingBottom: 30 }}>
          <Zoom>
            <Paragraph
              j
              content={[
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Для модниц, следящих за фигурой, овощной салат приготовили в 2012 году дизайнеры Dolche & Gabbana. Наши бабушки наверняка порадуются, если мы наденем платья из этой весенне-летней коллекции – в них столько витаминов! Кроме того, наряды дополнены вместительными сумками, откровенно напоминающими пластмассовые плетеные корзины родом из СССР – этакие советские сумки-шоперы.",
                <img
                  src="/img/blog/kushatj/img10.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Dolche & Gabbana продолжили растительную тему в уходящем сезоне весна-лето 2016, поменяв огородную роскошь на пышные сады. На фоне марокканских узоров красуются сицилийские лимоны и апельсины: настоящая dolce vita в лучших традициях бренда.",
                <img
                  src="/img/blog/kushatj/img11.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Однако самую удивительную и, не побоюсь этого слова, «вкусную» коллекцию продемонстрировал в 2016 году возрожденный бренд Скиапарелли. Здесь все готово к приему пищи: от тарелок и приборов до горячего супа и ароматного кофе. Платья выглядят так, словно вместо ткани Бертран Гийон использовал скатерть-самобранку. Пожалуй, в рамках такой коллекции и шляпа «баранья отбивная» уже не смотрелась бы так шокирующе, как в конце 30х годов.",
                <img
                  src="/img/blog/kushatj/img12.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />,
                <div
                  style={{
                    width: "100%",
                    borderTop: "4px dotted rgb(200,200,200)",
                    height: 0,
                    margin: "30px 0"
                  }}
                />,
                "Фотографы и дизайнеры играют с едой, как дети; Кутюрье расставляют воображаемые тарелки на юбках своих моделей, а творцы попроще печатают пончики и гамбургеры на свитшотах для обычных людей с улиц. Мы готовим еду, мы говорим о еде, мы носим еду. Значит ли это, что мы, как римские патриции, культивируем чревоугодие, проявляя тем самым в полной мере свою греховную природу? И да, и нет. Обостренный инстинкт самосохранения в наше неспокойное время заставляет человека цепляться за жизнь любым способом. Быть живым – в тренде, а если я ем – значит, я живу. Мода, как зеркало, всегда готова наглядно продемонстрировать, что творится с нашим миром. И, если рядом с нарядами в стиле милитари расцветают сочные апельсиновые сады – значит, мы еще живы и готовы за эту жизнь побороться."
              ]}
            />
          </Zoom>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
