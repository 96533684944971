import React from "react";
import List from "../../components/list";
import { dates, Month /* MonthToXX */ } from "../shedule/dates";
import { Link } from "react-router-dom";

const p = "p-constructor";
const h = "h3-constructor";

const online = `${dates.aibotProductowner.online.getDate()} ${Month(
  dates.aibotProductowner.online.getMonth()
)}`;

const free = dates.aibotProductowner.free.getMonth();

export const content = {
  title: "Профессия: Владелец продукта / Conversation AI product owner",
  keywords: "", // meta
  descript: "", // meta
  lable: "Владелец продукта",
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Профессия: Владелец продукта /<br />
        Conversation AI product owner
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        IT профессии. Профессиональная подготовка специалистов в области
        планирования и организации вывода IT-продуктов на рынок со
        специализацией на продуктах в области разговорного искусственного
        интеллекта. Диплом МФТИ
      </div>
    ),
    descript: [
      <>Выбор траектории обучения из 4 модулей</>,
      <>Полный цикл обучения: 4 месяца</>,
      <>Формат очно и онлайн. Практика</>,
      <>Доступны: рассрочка, кредит, скидки</>,
      <>Подготовительный курс без оплаты</>,
      <>Налоговый вычет после обучения</>
    ],
    imageComment: false,
    // imageSource: "/img/chatbot/robots.jpg",
    imageSource: "/img/aibot-productowner/cover-light.jpg",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      },
      {
        phone: "+7 (985) 745 52 40",
        phoneLink: "tel:+79857455240",
        name: "Сергей",
        tg: false,
        wa: false
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online}</>, <>содействие занятости — июль</>]
    },
    {
      title: "Формат",
      text: [<>Встречи, вебинары, видеолекции,</>, <>чат в группе, практика</>]
    },
    {
      title: "База",
      text: [
        <>Обучение реализуется совместно</>,
        <>специалистами ВШСДТ и МФТИ</>
      ]
    }
  ],
  mfti: {
    image: {
      src: "/img/partners-pro/mfti.png", //"/img/chatbot/mfti-logo.webp"
      alt: "МФТИ",
      style: { width: 200, height: "auto" },
      block: {
        backgroundColor: "white" // "rgb(1,114,192)"
      }
    },
    text: (
      <>
        Официальный партнер проекта. Слушатели проходят обучение на базе
        Московского физико-технического института. После успешного изучения
        курса и защиты проектной работы, выпускники получат удостоверение о
        повышении квалификации МФТИ по программе «Основы компьютерной
        лингвистики и машинного обучения для создания диалоговых ассистентов»
        <br />
        <a
          href="https://mipt.ru/cdpo/programs/software/supervised_learning.php?ELEMENT_ID=1969392"
          target="_blank"
        >
          <strong>mfti.ru</strong>
        </a>
      </>
    ),
    link: "https://mipt.ru/cdpo/news/chatbot_course_announcement"
  },
  vshsdt: {
    image: {
      src: "/img/chatbot/vshsdt.png",
      alt: "ВШСДТ",
      style: {
        height: 150
      },
      block: {
        backgroundColor: "white" // "rgb(250,250,250)",
      }
    },
    text: (
      <>
        Инициатор проекта. Цель — внедрение цифровых технологий и коммуникаций
        для малого бизнеса в креативных индустриях. В частности, слушатели курса
        получат возможность изучить дисциплины, связанные со структурой и
        технологиями современной моды, в процессе обучения реализовать проекты в
        данной области.
      </>
    )
  },
  gosgrant: {
    image: (
      <Link to="/chatbot-gospro">
        <img src="/img/chatbot/gosgrant.jpg" />
      </Link>
    ),
    text: (
      <p className="p-constructor">
        Вы можете пройти обучение по направлению проектирования, разработки
        диалоговых систем и вывода на рынок IT продуктов в области разговорного
        искусственного интеллекта в рамках федерального проекта «Содействия
        занятости» национальной программы «Демография», реализуемой с 2021 по
        2024 гг. Программа реализуется при поддержке Министерства труда и
        социальной защиты РФ, Мини­стер­ства науки и высшего обра­зова­ния РФ и
        Министер­ства просве­щения РФ. Обучение в рамках проекта «Содействия
        занятости» реализуется на базе РАНХиГС, МФТИ
      </p>
    )
  },
  text1: {
    title: <>Профессия Дизайнер чат-ботов с ИИ</>,
    text: (
      <>
        Дизайнер чат-ботов с искусственным интеллектом создаёт умные решения для
        автоматизации рутинных задач. В последние годы устойчиво растёт спрос на
        создание умных диалоговых ассистентов для бизнеса, государства,
        образования, культуры, медицины и прочих сфер жизни общества. Поэтому
        профессионалы, способные создавать и обслуживать умные диалоговые
        системы, имеют высокие перспективы на рынке труда. В частности зарплата
        дизайнера диалоговых систем на основе данных hh.ru составляет: junior от
        50 000 руб., middle от 100 000 руб., senior от 170 000 руб.
      </>
    )
  },
  basis: {
    image: <img src="/img/chatbot/base.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Основа обучения</h3>
        <p className={p}>
          Освоение профессии дизайнера чат-ботов с искусственным интеллектом для
          текстовых каналов коммуникаций базируется на программе курса «Основы
          компьютерной лингвистики и машинного обучения для создания диалоговых
          ассистентов» (длительность 70 академических часов, 2 месяца). Курс
          разработан в рамках соглашения о сотрудничестве МФТИ и ВШСДТ от 02
          апреля 2021 года. Курирует программу технический директор «0110 ЛАБ»,
          разработчик чат-ботов, Сергей Серебров.
        </p>
      </>
    )
  },
  fashionProjects: {
    image: (
      <img src="/img/chatbot/for-fashion.jpg" className="image-for-shess" />
    ),
    text: (
      <>
        <h3 className={h}>
          Выполнение проектных работ для предприятий индустрии моды
        </h3>
        <p className={p}>
          В рамках данного обучения по выбору слушатели получают возможность
          освоить знания по программе «Структура и система современной индустрии
          моды» (длительность 30 акаде­мических часов) и выполнить проектную
          работу на основе стажировки на предприятии индустрии моды. Слушатели,
          успешно сдавшие зачет по данной дисциплине, получат удостоверение о
          повышении квалификации ВШСДТ по программе «Структура и система
          современной индустрии моды»
        </p>
      </>
    )
  },
  career: {
    image: <img src="/img/chatbot/career.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Профессиональное и карьерное продвижение</h3>
        <p className={p}>
          В данном проекте предусмотрена программа содействия в трудоустройстве
          на основе запросов профильных компаний. В&nbsp;целях повышения
          эффективности процесса трудоустройства и дальнейшего карьерного роста,
          слушатели курса проходят цикл дополнительных онлайн семинаров в
          области фор&shy;ми&shy;ро&shy;вания личного профессионального стиля,
          фор&shy;ми&shy;ро&shy;вания про&shy;фессиональной репутации в сети
          интернет, нюансов со&shy;став&shy;ления резюме и прохождения
          собеседований в IT компаниях
        </p>
      </>
    )
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Программа курса предусматривает освоения слушателями следующих модулей
      </p>,
      <List
        classLi={p}
        arr={[
          <>Модуль 1. Цикл разработки диалоговых систем</>,
          <>Модуль 2. Введение в NLP, разработка разговорных ассистентов</>,
          <>Модуль 3. Анализ требований</>,
          <>Модуль 4. Управление продуктом</>
        ]}
      />,
      <p className={p}>
        <strong>Обучение направлено на:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          "изучение основных принципов и современных методов NLU, ML/DL;",
          "овладение специальной терминологией, используемой в области NLU, ML/DL;",
          "изучение технических средств, применяемых в разработке решений с элементами NLU, ML/DL",
          "овладение современными способами анализа, тестирования результатов и исправления ошибок;",
          "отработки навыков решения практических задач при разработке требований, обоснования решений и управления ими;",
          "отработки навыков оценки экономической эффективности диалоговых систем.",
          "отработки навыков управления процессом разработки диалоговых систем с элементами NLU, ML/DL в частности, а также в области управления продуктами в IT проектах в целом."
        ]}
      />,
      <p className={p}>
        Все занятия проводятся в интерактивном режиме, с максимальным
        вовлечением слушателей в активный процесс усвоения знаний и отработки
        навыков. Каждое занятие включает в себя теоретическую, семинарскую и
        практическую части с отработкой необходимых навыков.
      </p>,
      <p className={p}>
        <strong>Вы будете знать:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Этапы разработки NLU систем с использованием языков
            программирования, разработки компонентов программных продуктов,
            систем управления базами данных и операционных систем, организации
            разработки программного обеспечения, интеграции разработанного
            программного обеспечения
          </>,
          <>
            Принципы обработки естественного языка, основные различия алгоритмов
            классификации запросов пользователей, принципы работы с обучающим
            классификаторами и процессы вывода решений
          </>,
          <>
            Этапы бизнес-анализа и управления продуктовыми исследованиями.
            Работа с инструментами сбора требований, приоритизация требований,
            анализа рынка, проведения конкурентного анализа, формирования
            ценностного предложения
          </>,
          <>
            Этапы разработки планов развития и продвижения программного
            обеспечения, поддержки продаж, управления требованиями и управления
            изменениями
          </>
        ]}
      />,
      <p className={p}>
        <strong>Вы научитесь:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Управлять жизненным циклом продуктов в области разговорного
            искусственного интеллекта с целью достижения планируемых
            экономических показателей
          </>
        ]}
      />,
      <p className={p}>
        <strong>Требования для зачисления:</strong>
      </p>,
      <p className={p}>
        Обучение реализуется совместно ВШСДТ и МФТИ. К зачислению допускаются
        слушатели, обладающие высшим или средне профессиональным образованием
        (предоставляете копию диплома), а так же студенты вузов и колледжей
        (предоставляете справку из образовательной организации).
      </p>,
      <p className={p}>
        Обучение так же доступно в формате вольного слушателя без предоставление
        диплома о высшем образовании или СПО. В таком случае по итогам обучения
        выдается сертификат.
      </p>,
      <p className={p}>
        <strong>Полная программа курса высылается по запросу.</strong>
      </p>
    ]
  },
  bot: {
    image: <img src="/img/other/open_lesson.jpg" alt="Бот Валерия" />,
    text: (
      <>
        <p className="p-constructor">
          <strong>Телеграмм группа открытого образования ВШСДТ</strong>
        </p>
        <p className="p-constructor">
          В группе Открытого образования публикуем информацию об открытых курсах
          и мероприятиях, или социальных проектах
        </p>
        <p className="p-constructor">
          Здесь Вы узнаете, когда и как большую часть материалов можно изучить
          без оплат и условий
        </p>
        <a href="https://t.me/+Sfmarwgq2CtCji81">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  openEdu: {
    text: (
      <p>
        IT профессии. Открытое обучение и трудоустройство по направлению
        Conversation AI — свободный доступ к лекциям, практическим заданиям и
        карьерным консультациям. На портале{" "}
        <a href="https://open-ai.ru/" target="_blank">
          OPEN-AI.RU
        </a>{" "}
        доступны курсы в области проектирования диалоговых систем с ИИ и продаж
        IT проектов в области Conversation AI
      </p>
    ),
    image: (
      <img src="/img/chatbot/open-edu.png" alt="" style={{ maxWidth: 320 }} />
    )
  },
  prices: {
    head: "Стоимость обучения",
    content: [
      <List
        arr={[
          <>Подготовительный курс онлайн — без оплаты</>,
          <>
            Обучение в рамках федерального проекта «Содействие занятости» — без
            оплаты*
          </>,
          <>Стоимость курса (онлайн): 120000 рублей</>,
          <>Стоимость очной практики (1 раз в неделю) — 40 тыс.рублей</>
        ]}
        num="none"
      />,
      <center>
        <strong>Доступны:</strong>
      </center>,
      <List
        num="none"
        arr={[
          <>
            рассрочки от 3 до 12 платежей, кредиты на условиях банков-партнеров
          </>,
          <>скидка при раннем бронировании* — до 10 тыс.рублей</>,
          <>скидки по программе лояльности* — до 20%</>,
          <List
            arr={[
              <>
                Условия предоставления скидок, рассрочек, кредитов уточняйте у
                специалистов учебного отдела
              </>,
              <>
                Кто может учиться в рамках федерального проекта «Содействие
                занятости» национального проекта «Демография», смотрите на
                портале{" "}
                <a
                  href="https://trudvsem.ru/information-pages/support-employment/"
                  target="_blank"
                >
                  trudvsem.ru
                </a>
              </>,
              <>
                Полная стоимость обучения в очном или дистанционном форматах
                рассчитывается в зависимости от выбранной траектории обучения с
                учетом скидок и рассрочек, закрепляется в договоре на обучение
              </>
            ]}
          />
        ]}
      />
    ]
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой",
  diplom: {
    title: "Документ об образовании",
    image: (
      <img
        src="/img/aibot-productowner/diplom-mfti.jpg"
        alt="Диплом о профессиональной переподготовке"
        style={{ maxWidth: 320 }}
      />
    ),
    text: (
      <p class="p-constructor">
        Слушатели, успешно прошедшие обучение, выполнившие учебный план в
        соответствии с рабочей программой, сдавшие проектные работы, не имеющие
        академической задолженности и представившие все необходимые документы,
        получают документы установленного государством образца о повышении
        квалификации МФТИ по программам «Conversation AI product owner:
        управление проектами в команде разработки диалоговых систем с
        искусственным интеллектом»
      </p>
    )
  }
};
