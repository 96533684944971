import React from "react";

export const migranov = {
  meta: {
    keywords:
      "Руслан Мигранов, историк моды, мерчензайзер, витринист, стилист, имиджмейкер, курс, школа",
    descript:
      "Руслан Мигранов — историк моды, визуальный мерчендайзер, витринист, член творческого союза дизайнеров и имиджмейкеров. Преподаватель ВШСДТ курса «Консультант по стилю. Быстрый старт», авторского курса «История моды с Русланом Миграновым», курс «Визуальный мерчендайзинг и витринистика», ведущий отдельных мастер-классов по моде и витринистике, а также выездного арт-тура в Венецию «Мода и искусство Венеции»."
  },
  name: "Руслан Мигранов",
  image: "/img/about/ruslan-migranov.jpg",
  text: [
    {
      title: "Образование:",
      text: [
        "МГУ им. М.В. Ломоносова по программе «МВА-индустрия моды» (квали­фи­кация «Мастер делового администрирования»)",
        "Курс «Модный мерчендайзинг» в Fashion Institute of Technology (Нью-Йорк)",
        "Изучал Fashion Design/Modeling в IIDC Intermark International Design College (Шанхай) Курс «Знакомство с индийскими дизайнерами» (г. Бомбей, Дели, Агра)",
        "Школа Александра Васильева (Венеция)"
      ]
    },
    {
      title: "Преподавательская деятельность:",
      text: [
        "Институт моды, дизайна и технологий (с 2010 г.)",
        "Московский художественно-промышленный институт (МХПИ) — куратор зарубежных стажировок-программ в Венеции и Амстердаме и преподаватель на кафедре «Дизайн среды».",
        "Высшая школа стилистики, дизайна и технологий (с 2012 г.)",
        "Европейская академия имиджа — проведение отдельных мастер-классов и лекций.",
        "Выездные лекции и мастер-классы в Новосибирске, Красноярске, Воронеже, Вологде, Ростове-на-Дону, Баден-Бадене (Германия), Сочи (лектор на сьезде стилистов в 2017 г.), Челябинске.",
        "Выездные арт-туры в Венецию, Флоренцию и Париж."
      ]
    },
    {
      title: "Старший мерчендайзер, витринист, стилист, бренд-менеджер:",
      text: [
        "в компании «Кашемир и шелк» (Brunello Cucinelli, Missoni, Henry Beguelin, Agnona, Malo)",
        "в компании Feavinci (итальянская одежда класса premium) и др.",
        "Бренд-менеджер в компании «Мода альянс»"
      ]
    },
    {
      title: "Опыт работы в сфере fashion:",
      text: [
        "Опыт написания мерчбуков, составления лукбуков",
        "Формирование ретро-образа, подбор костюма для показов, съемок в клипах, для стилизованных вечеринок",
        "Организация и проведение дефиле и фотосессий",
        "Автор статей о моде и путешествиях для журналов «Дорогое удовольствие», Fashion Collection Novosibirsk и VMODE MAGAZINE и др.",
        "Куратор выставки в Музее Моды «Путешествия как искусство» в 2017г",
        "Приглашенный эксперт на радио, а также телеканалы Рен ТВ, ОРТ, Культура, ОТР. Участник и эксперт фильма «Русский бал» на ТВ КУЛЬТУРА, а также фильма «Народный фасон» из цикла Легенды Крыма на канале ОТР."
      ]
    }
  ]
};

export const zhilova = {
  meta: {
    keywords:
      "Эмилия Жилова, продюсер, фотосъемки, мужской стилист, имиджмейкер, мода, стиль, школа",
    descript:
      "Эмилия Жилова — beauty/fashion фотограф, продюсер фотосъемок, мужской стилист-имиджмейкер, колумнист Designers fromRussia. Преподаватель ВШСДТ профессиональной переподготовки курса «Имидж консалтинг и fashion styling»"
  },
  name: "Жилова Эмилия Игоревна",
  image: "/img/about/zhilova.jpg",
  text: [
    {
      title: "Образование:",
      text: [
        "2 высших образования, в том числе MBA в РЭУ им. Плеханова и долгое время работала на руководящих должностях, в том числе в крупнейшей девелоперской компании России MIRAX GROUP.",
        "В 2010 году начала изучение fashion и beauty фотографии в московских фотошколах (Академия классической фотографии, школа Whish On, PhotoPlay и т.д.).",
        "С 2018 года изучаю сферу имиджа и стиля в Высшей Школе Стилистики, Европейской академии имиджа, REBEL School, Fashion Factory School, а также на многочисленных профильных мастер-классах (курс ИГИТИ НИУ ВШЭ «Советская массовая культура: жанровое кино», обучение в Академии Barber Expert по теме «Мужские стрижки» и т.д.)"
      ]
    },
    {
      title: "Профессиональный опыт:",
      text: [
        "Приглашенный эксперт на шоу «Чай с Кальяном» радиостанции «Кальян FM»",
        "В 2014 году издана книга с моими съемками («Модные косы. Великолепные прически и хитросплетения из кос» АСТ-2014) www.labirint.ru",
        "Колумнист портала о российских дизайнерах DESIGNERS FROM RUSSIA"
      ]
    },
    {
      title: "Профессиональная деятельность:",
      text: [
        "2016 - настоящее время – преподаватель ВШСТД, преподаватель",
        "2018 - настоящее время – мужской стилист-имиджмейкер",
        "2018 - настоящее время – продюсер фотосъемок",
        "2010 - 2020 – beauty/fashion фотограф",
        "2008 - 2015 – владелец сети туристических агентств",
        "2006 - 2008 – Экономист MIRAX GROUP, г. Москва",
        "2004 - 2006 – личный помощник руководителя «Компания БАМАРД», г. Москва",
        "1998 - 2004 – стилист по прическам, г. Москва"
      ]
    },
    {
      title: "Сотрудничество:",
      text: [
        "Лидия Соселия, Элеонора Амосова, Ольга Осипенко, Махмудов Джамал, Ксения Бородина, Диана Павловская, Zdar, «Love and light», Elena Fedel, Stella DI Mare, Maria Rybalchenko, Strellson и многими другими, участвовать в съемках с селебрити и известными личностями.",
        "Публикации выходили в российских и зарубежных изданиях: ArtAbout (Италия), Allseason, ТВ Парк, RabbitMagazine, Невеста, Eclectic, Hairs, и на модных порталах: Modern-S, ModeHaus, The Look, Fashion Time."
      ]
    },
    {
      title: "Награды:",
      text: [
        "1-е место в Международном конкурсе фотографии, проходившем в рамках Российской Недели Искусств 2011 (RussianArtWeek 2011). Номинация: Мода.",
        "«Honorable Mention» международного фотоконкурса International Photography Awards (IPA) 2012 в четырехноминациях (Self-Promotion, Fashion, Fine Art и Other_AD)",
        "«Honorable Mention» MOSCOW INTERNATIONAL FOTO AWARDS 2015 (Advertising-Beauty)",
        "Мужской стилист-имиджмейкер года 2021"
      ]
    }
  ]
};

export const prohorovich = {
  meta: {
    keywords:
      "Елена Прохорович, fashion-коммуникации, стиль, мода, реклама, PR, стилист, курсы, стажировки, школа",
    descript:
      "Елена Прохорович — специалист по fashion-коммуникациям, рекламе и PR, коммуникационный тренер, ТВ-эксперт, предприниматель. Преподаватель ВШСДТ профессиональной переподготовки курса «Имидж консалтинг и fashion styling», «Личный бренд в индустрии моды и дизайна». Куратор стажировок «Milan fashion week», «Milan design week и практики», «Fashion каникулы в Тбилиси»"
  },
  name: "Прохорович Елена Владимировна",
  image: "/img/about/prohorovich.jpg",
  text: [
    {
      title: "Образование:",
      text: [
        "Специалист по рекламе – Санкт-Петербургский государственный университет промышленных технологий и дизайна.",
        "Магистратура «Реклама и связи с общественностью в сфере моды и дизайна» — Санкт-Петербургский государственный университет промышленных технологий и дизайна, красный диплом.",
        "Диссертация на тему: «Арт-коммуникации как инструмент формирования имиджа современного города».",
        "Аспирантура по специальности «Журналистика» – СПГУПТД."
      ]
    },
    {
      title:
        "Основатель международных коммуникационных образовательных проектов:",
      text: [
        "Стажировки на Milan fashion week / Milan design week.",
        "Summer fashion school in Italy.",
        "Fashion-практика в Париже.",
        "Fashion-практика в Грузии.",
        <>&emsp;</>,
        "Более 10 лет консультирую компании/экспертов по продвижению брендов и занимаюсь организацией профессиональных мероприятий в сфере моды и дизайна, а также маркетинговыми и бизнес исследованиями."
      ]
    },
    {
      title: "Профессиональный опыт:",
      text: [
        "2014 - 2017 — Основатель коммуникационного агентства «Cult Communi­cation».",
        "2017 - 2018 – участник International fashion community и проекта «Fashion Profi».",
        "Участвовала в роли эксперта на 1 канале, программа «Прямой эфир», про меня снимали репортаж на телеканале МИР, участвовала в шоу «Игра в кино» — выиграла его.",
        "С 2015 - по настоящее время разрабатываю и провожу международные образовательные проекты в fashion & design индустрии. Идеолог и основатель международных коммуникационных образовательных проектов: Стажировки на Milan fashion week / Milan design week; Summer fashion school in Italy; Fashion-практика в Париже; Fashion-практика в Грузии.",
        "С 2019 - по настоящее время преподаватель, автор курсов в Высшей школе стилистики, дизайна и технологий."
      ]
    },
    {
      title: "Сотрудничество:",
      text: [
        "Сотрудничала с французским университетом ModArt, итальянским университетом LIUC, Milan Fashion week, журнал Собака, Правительство Санкт-Петербурга, Центр развития и поддержки предпринимателей, SPb Fashion Week, Александринский театр, Modul fashion loft, Музей-усадьба Остафьево – «Русский парнас», Санкт-Петербургский яхт-клуб «Геркулес», Milan Design week."
      ]
    }
  ]
};

export const shabalina = {
  meta: {
    keywords: "Светлана Шабалина, имидж, стилист, дизайнер, курс, школа",
    descript:
      "Светлана Шабалина — практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения. Преподаватель ВШСДТ профессиональной переподготовки «Имидж консалтинг и fashion styling» курса «Консультант по стилю. Быстрый старт» «Стиль для стилиста, авторская мастерская Светланы Шабалиной»."
  },
  name: "Шабалина Светлана Александровна",
  image: "/img/about/shabalina.jpg",
  text: [
    {
      title: "Образование:",
      text: [
        "1997-2002г. — Юридический факультет Орловский Государственный Университет, юриспруденция."
      ]
    },
    {
      title: "Повышение квалификации:",
      text: [
        "2005 — НОУ «Образование и карьера», модельер-конструктор одежды.",
        "2011 — курс «Имиджмейкер: профессиональная подготовка», Высшая школа стилистики;",
        "2012 — «Стилист-имиджмейкер. Повышение квалификации», Высшая школа стилистики;",
        "2012 — «Стилистика образа», Школа Стиля Наталии Туркенич;",
        "2012 – «Техника составления луков», Школа Стиля Наталии Туркенич;",
        "2013 — «Создание образа в фэшн-фотографии 20в.», Школа Стиля Наталии Туркенич;",
        "2014 — «Аrt-подготовка», студия Любови Михайловны Поповой;",
        "2014 — «Мода как искусство», студия Любови Михайловны Поповой;",
        "2014 - 2015 — «Большие исторические стили», студия Любови Михайловны Поповой;",
        "2016 — «Искусство комплекта», Студия образных решений «Idea-class»;",
        "2017 — «Секреты от кутюр», Студия образных решений «Idea-class»;",
        "2018 - 2019 — «Коуч-стилист» — Студия образных решений «Idea-class»."
      ]
    },
    {
      title: "Профессиональный опыт:",
      text: [
        "2015 - настоящее время – преподаватель ВШСТД, практикующий коуч-стилист.",
        "2013 - 2016 – преподаватель имиджа и стиля в Санкт-Петербургской школе телевидения.",
        "2012 - 2015 – сотрудничество с издательствами «Вечерняя Москва», «Москвичка», «Худеем правильно».",
        "2011 - 2021 – частная практика в имидж консультировании, шопинг-сопровождении, постановке стильных фотосессий.",
        "2006 - 2010 – дизайнер одежды."
      ]
    }
  ]
};

export const bonetskaya = {
  meta: {
    keywords: "Марина Бонецкая, художник, дизайнер, мода, стиль",
    descript:
      "Марина Бонецкая — художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров». Преподаватель ВШСДТ профессиональной переподготовки курса «Имидж консалтинг и fashion styling», а также преподаватель авторского курса «Психология цвета»"
  },
  name: "Бонецкая Марина Анатольевна",
  image: "/img/about/bonetskaya.jpg",
  text: [
    {
      title: "Образование:",
      text: [
        "1977 - 1982, Художественно-техно­ло­гичес­кий факультет Московского Техноло­гичес­кого Института (ныне РГУТиС) по специальности «Художественное оформление и моделирование изделий текстильной и легкой промышленности»"
      ]
    },
    {
      title: "Профессиональный опыт:",
      text: [
        "1982 - 1984 – художник-модельер Экспериментальной лаборатории «Мособл­обувьбыт»",
        "2002 - 2013 — руководитель Отделения Прикладной Эстетики Факультета Искусства и Индустрии Моды НОУ Национальный Институт Моды.",
        "2012 - 2015 – преподаватель кафедры Дизайна Московского государ­ствен­ного педаго­ги­ческого университета.",
        "2016 - 2017 – преподаватель имиджелогии в Московском государ­ствен­ном ин­сти­туте культуры.",
        "2016 - настоящий момент — преподаватель «Основ изобразительного твор­чес­тва» в Учебном Центре кино и телевидения UHD.",
        "2018 - настоящее время – преподаватель ВШСТД."
      ]
    },
    {
      title: "Сотрудничество:",
      text: [
        "С 2007 г. подготовила 18 молодых дизайнеров, занявших призовые (с 1 по 3) места на профессиональных конкурсах («Ассамблея Моды», «Будущее Шапо», «Адмиралтейская игла», «Перспектива», «Золотая линия», «Кубок мэра Москвы»)."
      ]
    },
    {
      title: "Профессиональный девиз:",
      text: [<i>Образ – это не результат, это процесс.</i>]
    }
  ]
};

export const oMaltseva = {
  meta: {
    keywords: "Ольга, Мальцева, ",
    descript: ""
  },
  name: "Ольга Мальцева",
  image: "/img/teachers/olga-maltseva.jpg",
  text: [
    {
      title: "",
      text: [
        "Консультант по имиджу. Практикующий стилист-имиджмейкер. Окончила высшую школу стилистики, а также прошла дополнительные курсы повышения квалификации. В своей работе использует метод подробного анализа внешности, образа жизни и характера клиента, составляя его индивидуальную формулу стиля."
      ]
    }
  ]
};

/*
export const teacher = {
  meta: {
    keywords: "",
    descript: ""
  },
  name: "",
  image: "",
  text: [
    {
      title: "",
      text: []
    },
  ]
}
*/
