import React from "react";
import Page from "../../components/page";
import Helmet from "react-helmet";
import {
  BuilderLayout,
  ScrollingLink,
  TitleH2
} from "../../patterns/pattern-fs/components";
import { content as c } from "./content";
import { Tile } from "../../components/tiles-w-button/tiles";
import { Block } from "../../components/block-w-static-bkg/block";
import {
  ButtonScroll,
  TitleButtonItems
} from "../../components/title-text/title-text";
import { ListWTitle } from "../../components/list-w-icon/list";
import { MiniForm } from "../../components/forms";
import { NumberList6x2 } from "../../components/number-list/list";
import { Head1Text } from "../../components/heads/head1";
import { TileImages } from "./partners";

export default function Partners(props) {
  return (
    <>
      <Helmet>
        <title>Партнерская программа ВШСДТ</title>
        <meta
          name="keywords"
          content="партнер, программа, бизнес, образование, сотрудничество"
        />
        <meta
          name="descript"
          content="Цель партнерской программы — создание синергетического эффекта от сотрудничества проверенных временем ведущих вузов и инновационных образовательных проектов. Наша сила в союзе творческой энергии авторов образовательных продуктов и мощи некоммерческих, государственных, корпоративных и профессиональных ресурсов"
        />
      </Helmet>
      <Page lable="Партнерская программа">
        <BuilderLayout
          contents={[
            <Head1Text content={c.head} />,
            <Tile content={c.nav} />,
            <>
              <ScrollingLink scrollLink="mission" />
              <Block
                content={c.mission.content}
                background={c.mission.background}
              />
            </>,
            <>
              <ScrollingLink scrollLink="freedom" />
              {/* <Title title={c.freedom.title} /> */}
              <TitleButtonItems content={c.freedom} />
              <ButtonScroll
                link={c.freedom.link}
                outLink
                textLink="стать партнером"
              />
            </>,
            <>
              <ScrollingLink scrollLink="safety" />
              <ListWTitle content={c.safety} />
              <ButtonScroll
                link={c.freedom.link}
                outLink
                textLink="стать партнером"
              />
            </>,
            <>
              <ScrollingLink scrollLink="safety" />
              <ListWTitle content={c.power} />
              <ButtonScroll
                link={c.freedom.link}
                outLink
                textLink="стать партнером"
              />
            </>,
            <>
              <TitleH2 content="Почему мы?" />
              <NumberList6x2 content={c.whyus} />
            </>,
            <>
              <TitleH2 content="Ключевые партнеры" />
              <TileImages content={c.partens} />
            </>,
            <>
              <TitleH2 content="Стать партнером" scroll="feedback" />
              <MiniForm formName="miniform-partners-pro" />
            </>
          ]}
        />
      </Page>
    </>
  );
}
