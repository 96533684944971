import React from "react";
import List from "../../components/list";
import Video from "../../components/video";
import Paragraph from "../../components/p-array";
import Ig from "../../components/instagram";
import Slider from "react-slick";
import "./style.css";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const contents = {
  titlePage: "Консультант по стилю и дизайну интерьера. Быстрый старт",
  head: {
    left: (
      <div className="imagemaker-stylist-header">
        <h3 style={{ paddingTop: 0 }}>Обучение профессиям</h3>
        <p>
          Имиджмейкер-стилист <sup>1 год</sup>
        </p>
        <p>
          Консультант по стилю <sup>3,5 месяца</sup>
        </p>
        <p>
          Стилист фотосессий <sup>4 месяца</sup>
        </p>
        <h3>Личное развитие</h3>
        <p>
          Сам себе стилист <sup>1,5 месяца</sup>
        </p>
        <h3>Открытые лекции</h3>
        <div className="imagemaker-stylist-tags">
          <a href="#free" className="red-link">
            #free
          </a>
          &emsp;
          <a href="#about">#ошколе</a>
          &emsp;
          <a href="#price">#стоимость</a>
        </div>
      </div>
    ),
    right: (
      <div className="imagemaker-stylist-head-logo">
        <img src="/img/root-test2/emblema.png" alt="ВШСДТ Факультет моды" />
        <h3>Факультет моды</h3>
      </div>
    )
  },
  aboutProfeshion: {
    title: "Что дает обучение на имиджмейкера-стилиста",
    short:
      "Обучения имиджмейкеров-стилистов дает возможность освоить область имидж-консультирования и стилизации проектов через изучение тех технологий, приемов и принципов, которые позволяют сделать человека адекватным себе, а его существование в социуме – осмысленным и комфортным. Процесс как сложный, так и захватывающий.…",
    detail: [
      <span>
        Вы научитесь работать с человеком как с личностью, имеющим свой
        индивидуальный стиль одежды и поведения, как с личностью, которая живет
        в современности и хочет соответствовать современным критериям красоты и
        успешности, а также как с личностью, которая имеет определенную
        профессию и статус.
        <br />
        Как отобразить все эти грани в человеке? Именно эти знания и
        практические навыки составляют основу образования имиджмейкера-стилиста
        в ВШСДТ: развитие образного мышления, чутье и технологии создания стиля,
        анализ моды и модного образа, использование имидж-технологий, помогающих
        Вашему успеху в профессии и бизнесе.
        <br />
        И, конечно, после обучения, обладая полнотой знаний и компетенций в
        индустрии моды, Вы сможете реализовать себя как личность и как
        профессионала в творческих и коммерческих проектах в индустрии моды.
      </span>
    ]
  },
  principes: [
    "Компиляция актуальных практических знаний как базовой системы в создании индивидуального и делового стиля женщин и мужчин, с учетом социального ожидания и психологических особенностей людей.",
    "Изучение осознанных подходов в разработке привлекательного и убедительного внешнего образа, используя возможности моды и искусства.",
    "Методики исследования и творческого эксперимента – эвристика, кастомайзинг, образно-ассоциативный подход, референсы и мудборды, нацеленные на развитие эстетического чувства, личного вкуса и визуальной культуры.",
    "Навыки разработки концепции стиля в проектах, определение элементов стилизации, опыт прочтения и интерпретации тенденций и идей через изучение моды XX века и тенденций современной моды, анализ ДНК брендов, законов и принципов композиции.",
    "Определение и применение принципов управления процессами создания fashion-съемок на основе изучения специфики проектов для рекламы, для глянца, для подиума, для fashion retail.",
    "Развитие организационных и коммуникационных навыков для осуществления коллабораций с единомышленниками, дизайнерами, фотографами, редакторами и блогерами, формирование портфолио для продвижения своих профессиональных творческих проектов"
  ],
  bot: {
    image: "/img/imagemaker-stylist/chat-bot-ill.jpg",
    text: (
      <>
        <p className="p-constructor">
          <strong>Открытое обра­зо­ва­ние ВШСДТ</strong>
        </p>
        <List
          arr={[
            "Бот Валерия подбирает контент в зависимости от предпочтений",
            "Регулярно открывает доступ к онлайн лекциям и курсам",
            "Без оплат и условий. Общается вежливо. Как начать?",
            "Подпишитесь, нажмите «старт» и выбирайте интересующие темы"
          ]}
          num="none"
          olStyle={{ marginLeft: 0, paddingLeft: 0 }}
          classLi="p-constructor"
        />
        {/* Ai_Style_Bot */}
        <a href="https://t.me/imagemakerpro_bot">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    ),
    banner: (
      <a href="/style-consultant-tgu" target="_blank">
        <img
          src="/img/second-degree-imagemaker/tgu-education.png"
          alt="Консультант по стилю. Бесплатное обучение по гос гранту"
          style={{ border: "1px solid black" }}
        />
      </a>
    ),
    textBanner: (
      <>
        <p className="p-constructor">
          Вам может быть доступно обучение новой профессии в области
          имидж-консалтинга и дизайна интерьера в рамках федерального проекта
          «Содействие занятости», если Вы подходите под определенную категорию
          граждан или обратитесь в центр занятости с целью повышения
          квалификации. Обучение полностью оплачивает государство.
          <br />
          <a href="/style-consultant-tgu" target="_blank">
            Консультант по стилю ТГУ
          </a>
        </p>
      </>
    )
  },
  cardConsultingStyle: {
    image: {
      title: (
        <>
          КОНСУЛЬТАНТ ПО СТИЛЮ
          <br />
          БЫСТРЫЙ СТАРТ
        </>
      ),
      image: "/img/imagemaker-stylist/consulting-style.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: 3,5 месяца",
      specialisation:
        "Консультирование частных клиентов по имиджу и стилю с учетом индивидуальных особенностей и модных тенденций.",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            "Personal Styling. Индивидуальный стиль от А до Я",
            "Практика создания персонального Style Book",
            "Особенности реализации фото съемок",
            "Главные тенденции моды ХХ и XXI века",
            "Принципы формирования портфолио стилиста",
            "Продвижение личного бренда стилиста"
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/style-your-life",
        text: "подробнее"
      }
    }
  },

  cardImagemaker: {
    image: {
      title: (
        <>
          ИМИДЖМЕЙКЕР, СТИЛИСТ
          <br />
          ПОЛНОЕ ПОГРУЖЕНИЕ
        </>
      ),
      image: "/img/imagemaker-stylist/imagemaker.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: от 8 месяцев до 1 года",
      specialisation:
        "Консультирование частных и корпоративных клиентов, работа с фото проектами, журналами, дизайнерами",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>Профессиональные методики создания имиджа и стиля</>,
            <>
              Психология и стилевые предпочтения. Алгоритмы
              имидж-консультирования клиентов
            </>,
            <>Маршруты и особенности шопинга в России и Европе</>,
            <>Практика стилизации фото и ТВ проектов, модных показов</>,
            <>Возможности коллабораций с дизайнерами, бутиками</>,
            <>Пути создания своего модного бизнеса</>,
            <>Создание и продвижение бренда стилиста</>
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/second-degree-imagemaker",
        text: "подробнее"
      }
    }
  },
  cardPhotoStylist: {
    image: {
      title: <>СТИЛИСТ ФОТОСЕССИЙ</>,
      image: "/img/stylistportfolio-malviemagazine-aug21/malvie.jpg" // "/img/imagemaker-stylist/photo-stylist.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: 4 месяца",
      specialisation:
        "Стилист фотосессий, стилист модных проектов. Работа с журналами, дизайнерами, модными брендами",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>История моды и стилей</>,
            <>Система современной индустрии моды</>,
            <>Методики развития креативности для стилистов</>,
            <>Арт дирекшн, работа с командой</>,
            <>Теория и практика fashion съемок</>,
            <>Специфика проектов для журналов, ТВ, fashion retail</>,
            <>Формирование портфолио стилиста</>
          ]}
        />
      ),
      formats: "онлайн, очно",
      link: {
        l: "/fashion-styling",
        text: "подробнее"
      }
    }
  },
  // girl-with-diplom-vsh
  cardTestDrive: {
    image: {
      title: (
        <>
          Профессиональное образование
          <br />
          пробные занятия
        </>
      ),
      image: "/img/imagemaker-stylist/girl-with-diplom-vsh.jpg"
    },
    text: {
      title: (
        <span style={{ lineHeight: "2rem" }}>
          Акция Тест-драйв — первые
          <br />
          две недели обучения
          <br />
          на профессиональных курсах
          <br /> = 900 рублей
        </span>
      ),
      diplom: false,
      duration: false,
      text: (
        <>
          Можно погрузится в профессию имиджмейкера-стилиста уже сейчас. Надо
          выбрать один из профессиональных курсов и просто начать учится.
          Почувствовать атмосферу, познакомиться с преподавателями, понять, что
          все получится. Это точно то, что поможет убедиться в правильном
          выборе.
        </>
      ),
      textStyle: {
        lineHeight: "1.8rem",
        padding: "2rem 0 4rem"
      }
    }
  },
  cardSelfStyle: {
    image: {
      title: <>САМ СЕБЕ СТИЛИСТ</>,
      image: "/img/imagemaker-stylist/self-style.jpg"
    },
    text: {
      title: "Интенсив",
      diplom: "Сертификат",
      duration: "Длительность обучения: 1,5 месяца",
      specialisation:
        "Найти свой стиль, отвлечься и погрузиться в моду, получить удовольствие от процесса",
      process: (
        <Paragraph
          content={[
            <>
              Современные стилевые направления выстроятся в стройную систему, и
              вы поймете, как их смешивать, чтобы получать стильные эксклюзивные
              комплекты.
            </>,
            <>
              Все привычные и навязанные обществом стереотипы будут стерты,
              останется только индивидуальность каждого человека в разных ее
              проявлениях.
            </>
          ]}
        />
      ),
      formats: "онлайн, очно",
      actions: false,
      link: {
        l: "/self-style",
        text: "подробнее"
      }
    }
  },

  cardConsultingStyleAndDesign: {
    image: {
      title: (
        <>
          САМ СЕБЕ ДИЗАЙНЕР
          <br />
          ИНТЕНСИВ
        </>
      ),
      image: "/img/imagemaker-stylist/consulting-style-design.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Сертификат",
      duration: "Длительность обучения: 1,5 месяца",
      specialisation: (
        <>
          Научитесь проектировать интерьеры не выходя из дома. Всего за 12
          занятий с лучшими преподавателями ВШСДТ из России и Европы
        </>
      ),
      process: (
        <List
          arr={[
            <>
              Как создать гармоничный дизайн своей среды и жизни, используя
              современные приемы стилизации и организации пространства интерьера
            </>,
            <>
              Как с помощью полученных знаний оптимизировать расходы на ремонт
              за счет осознанного контроля смет, подрядчиков и материалов
            </>
          ]}
          num="none"
          olStyle={{ paddingLeft: 0 }}
        />
      ),
      formats: "онлайн, очно",
      actions: false,
      link: {
        l: "/interior-your-life",
        text: "подробнее"
      }
    }
  },
  about: {
    image: (
      <div style={{ textAlign: "center" }}>
        <img
          src="/img/imagemaker-stylist/about.webp"
          alt="about"
          style={{ maxWidth: "100%", transform: "scale(-1,1)", maxHeight: 350 }}
        />
      </div>
    ),
    text: (
      <>
        <Paragraph
          content={[
            <>
              ВШСДТ — АНО ДПО «Высшая школа стилистики, дизайна и технологий»
              одна из ведущих образовательных площадок, основатели которой
              работают в индустрии с 2002 года.
            </>,
            <>
              Мы даем полноценные курсы профессиональной переподготовки и
              повышения квалификации в индустрии моды и дизайна как очно, так и
              дистанционно.
            </>,
            <>
              Мы предоставляем широкий спектр возможностей освоения программ:
              долгосрочные и краткосрочные программы, интенсивы, практикумы и
              многое другое.
            </>
          ]}
          style={{ paddingBottom: "1rem", lineHeight: "2rem" }}
        />
      </>
    ),
    head: "О нас"
  },
  headMiddleForm: (
    <>
      Есть вопросы?
      <br />
      <span
        style={{
          fontFamily: "Roboto",
          fontSize: "1.2rem",
          fontWeight: 400,
          color: "rgb(80,80,80)",
          textShadow: "none"
        }}
      >
        Пишите в мессенджер или отправьте запрос через форму обратной связи
      </span>
    </>
  ),
  teachersAndGraduatesInProjects: (
    <>
      <h3 className="h3-constructor">
        Наши преподаватели и выпускники в проектах:
      </h3>
      <p className="p-constructor">
        La Botanica Magazine (Франция), Faddy Magazine (Италия), Feroce Magazine
        (Великобритания), Imirage Magazine (Канада), F.R.W.L. Magazine (Россия),
        L'affaire Magazine (Великобритания), Surreal Magazine (Германия),
        Scorpio Jin Magazine (США), BeautyMute Magazine (Франция), Voque
        (Италия), Baby Moda Magazine (Россия), LM Magazine (Россия), The Look
        (www), Fashion Time (www). А также на Russian Fashion Week, в Музее
        Моды, в Музее Музыки, на радио и ТВ, на Всероссийском Съезде
        Стилистов-Имиджмейкеров, с корпоративными и частными клиентами.
      </p>
    </>
  ),
  lessions: [
    {
      image: {
        image: "/img/imagemaker-stylist/prohorovich.jpg",
        alt: "Елена Прохорович",
        vertical: true
      },
      title: "Какие перспективы у профессии",
      text: (
        <>
          <p>
            Узнайте, сколько перспектив для раз&shy;вития есть в профес&shy;сии
            стилиста, имидж&shy;мейкера. Смотрите успеш&shy;ные кейсы и истории
            изме&shy;нения жизни. Оцените, какие ниши и сег&shy;менты можно
            занять в индус&shy;трии моды. Убедитесь, что это по-настоящему
            по&shy;тряса&shy;ющая и творческая работа.
          </p>
          <p>
            Спикер — Елена Прохорович <Ig a="elenacult_com" />
          </p>
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    },
    {
      image: {
        image: "/img/imagemaker-stylist/openls.jpg",
        alt: "Открытые лекции",
        vertical: false
      },
      title: "Открытые лекции в партнерском проекте Арт ЛекториУм.",
      text: (
        <>
          <p>Сегодня доступны:</p>
          <List
            arr={[
              <>
                Louis Vuitton. Путе&shy;шес&shy;твия с рос&shy;кошью (Руслан
                Миг&shy;ранов)
              </>,
              <>Стильный бодипозитив. Одежда +size (Татьяна Фомина)</>,
              <>
                Манипуляция цветом. Психологический аспект и неожиданный эффект
                (Лиана Бахова)
              </>,
              <>
                Индивидуальный мор&shy;ской стиль (Свет&shy;лана Шаба&shy;лина)
              </>
            ]}
            classLi="p"
            olStyle={{ marginLeft: 0, paddingLeft: "1rem" }}
          />
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    },
    {
      image: {
        image: "/img/imagemaker-stylist/action.jpg",
        alt: "Тест-драйв проф",
        vertical: false
      },
      title: "Тест-драйв проф",
      text: (
        <>
          <p>
            Акция Тест-драйв — первые две недели обу&shy;че&shy;ния на
            профес&shy;сиональ&shy;ных курсах = 900 рублей. Можно
            погру&shy;зиться в профес&shy;сию имидж&shy;мейкера-стилиста уже
            сейчас. Почув&shy;ствовать атмосферу, позна&shy;комить&shy;ся с
            препо&shy;дава&shy;телями, понять, что все получится. Это точно то,
            что поможет сделать пра&shy;виль&shy;ный выбор
          </p>
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    }
  ],
  textAndMedia: [
    {
      text: (
        <>
          <h3 className="h3-constructor">Делайте мир прекрасным!</h3>
          <p className="p-constructor">
            Вы тоже сможете, как и наши выпускницы, заниматься любимым делом,
            стать свободными в своем выборе, понимать моду и создавать
            индивидуальный стиль.
          </p>
          <p className="p-constructor">
            Посмотрите интервью с успешным имидж-стилистом Лидой Акимовой{" "}
            <a href="https://instagram.com/lididilisa" target="_blank">
              @lididilisa
            </a>{" "}
            Лида активно консультирует клиентов, заряжает всех супер позитивной
            энергией, проводит мастер-классы и теперь она наставник и ведущая
            специальных очных практик для студентов в ВШСДТ
          </p>
        </>
      ),
      media: <Video link="fOZzI3bKXIU" />
    },

    {
      text: (
        <>
          <h3 className="h3-constructor">Реальная практика</h3>
          <p className="p-constructor no-padding">
            В процессе и после обучения в ВШСДТ публикации в международных
            журналах — это реально!
          </p>
          <p className="p-constructor no-padding">
            Жанна Рожнова <Ig a="zhannarozhnova" />, Имидж-стилист, визажист:
          </p>
          <List
            arr={[
              "стилизации фотосессий и публикации в международных журналах"
            ]}
            style={{ color: "rgb(100,100,100)" }}
          />
          <p className="p-constructor no-padding">
            Мария Стецурина <Ig a="mariastetsurina" /> и<br />
            Евгения Веретина <Ig a="everetina_official" />
          </p>
          <List
            arr={["участие в фотосессии для обложки французского журнала"]}
            style={{ color: "rgb(100,100,100)" }}
          />
          <p className="p-constructor">
            Смотрите{" "}
            <a href="/news/17082021" target="_blank">
              подробнее…
            </a>
          </p>
        </>
      ),
      media: (
        <div
          style={{
            width: 320,
            height: 240,
            margin: "0 auto"
          }}
        >
          <Slider {...settings}>
            <div>
              <Video link="YVpzoQ9aIfI" />
            </div>
            <div>
              <img src="/img/news/17082021_malvie.jpg" />
            </div>
          </Slider>
        </div>
      )
    },
    {
      scroll: "diploms"
    },
    {
      text: (
        <>
          <div className="block-left-space">
            <h3 className="h3-constructor">Диплом</h3>
            <p className="p-constructor">
              Отличайтесь от множества конкурентов с сертификатами — обладайте
              статусом дипло&shy;миро&shy;ван&shy;ного специа&shy;листа
            </p>
            <p className="p-constructor">
              Подготовьтесь к общению с адекватными клиентами и с люксом —
              получите опыт, личный бренд, помощь настав&shy;ников и участие в
              проектах
            </p>
            <p className="p-constructor">
              NB: Диплом — документ строгой отчетности, выдается только на
              основе лицензии, в нем прописаны изученные дисцип&shy;лины и право
              деятель&shy;ности в изученной области
            </p>
          </div>
        </>
      ),
      media: (
        <img
          src={"/img/imagemaker-stylist/diplom-2.jpg"}
          style={{ width: 320, height: "auto" /* maxWidth: "100%" */ }}
          alt="диплом Высшей школы стилистики, дизайна и технологий"
        />
      )
      // side: true
    },
    {
      text: (
        <>
          <div className="block-left-space">
            <h3 className="h3-constructor">Какие перспективы у профессии</h3>
            <p className="p-constructor">
              Смотрите вебинар о перспективах раз­вития в профес­сии стилиста,
              имидж­мейкера: специализации в профессии, успеш­ные кейсы и
              истории. Оцените, какие ниши и сег­менты можно занять в индус­трии
              моды. Убедитесь, что это по-настоящему по­тряса­ющая и творческая
              работа.
            </p>
            <p className="p-constructor">
              Спикер — Елена Прохорович <Ig a="elenacult_com" />
            </p>
          </div>
        </>
      ),
      media: <Video link="AGcaa7kGFLE" />,
      side: true
    }
  ],
  boldText: {
    boldText: <>Набор групп на профессиональное обучение открыт с</>,
    text: false
  },
  garanty: {
    title: "Финансовая гарантия",
    text: (
      <>
        В программе действует безусловная гарантия на возврат 100% денег в
        течении 14 дней. Это значит, что если вы по какой-то причине передумаете
        учавствовать, мы вернем всю сумму
      </>
    )
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Стратегический партнер ВШСДТ — Российский государст&shy;венный
        соци&shy;аль&shy;ный университет.
      </p>
    ),
    paperText: (
      <>
        <p className="p-constructor">
          Получите два диплома, ВШСДТ и РГСУ, выбирая образо&shy;вание как
          Второе высшее.
        </p>
        <p className="p-constructor">
          Программа двух дипломов для тех, кто ориентируется на максимальный
          результат и погружение. Более 12 месяцев обучения, 620
          академи&shy;ческих часов, полный набор модулей.
        </p>
      </>
    ),
    slides: [
      "/img/imagemaker-stylist/diplom.jpg",
      "/img/imagemaker-stylist/diplom-2.jpg"
    ]
  },
  digitPuntcs: [
    <>
      Любите стиль и моду, сейчас в поисках себя или в поиске творческой
      профессии, хотите получать дополнительный доход
    </>,
    <>
      У Вас бизнес в индустрии моды и красоты, вы совершенствуете сервис и
      методики продаж для клиентов
    </>,
    <>
      Вы понимаете, что правильно сформированный стиль помогает монетизировать
      свой личный бренд
    </>,
    <>
      Вы практикующий консультант, преподаватель, и планируете расширить
      компетенции и спектр услуг
    </>
  ],
  dates: {
    ofline: {
      day: 1,
      month: "июня"
    },
    online: {
      day: 19,
      month: "мая"
    }
  },
  tesises: {
    head: "Чему вы научитесь",
    content: [
      {
        title: "Преображаться и удивлять",
        text: (
          <>
            Идеально формировать свой стиль, рационально подбирать гардероб,
            изменяться по настроению и получать удовлетворение от шопинга
          </>
        )
      },
      {
        title: "Создавать стиль для окружающих",
        text: (
          <>
            Осознанно подбирать ком­плекты одежды в соот­­вет­ствии с модными
            тенден­циями, индивидуальным стилевым вектором и психотипом человека
          </>
        )
      },
      {
        title: "Ориентироваться в fashion-индустрии",
        text: (
          <>
            Понимать модные тенденции, находить fashion-хиты и супер идеи для
            новых сезонов, создавать яркие образы и креативные концепции
          </>
        )
      },
      {
        title: "Общаться с командой и fashion-брендами",
        text: (
          <>
            Подбирать команду для fashion проектов, стилизовать съемки, работать
            с модными брендами и шоурумами, создавать креативную атмосферу
          </>
        )
      },
      {
        title: "Продвигать себя и свои услуги",
        text: (
          <>
            Формулировать стратегию личного бренда, прокачивать себя,
            зарабатывать и полноценно консуль­­­тировать женщин и мужчин по
            имиджу и стилю
          </>
        )
      },
      {
        title: "Изменять мир вокруг себя",
        text: (
          <>
            Использовать изученные имидж технологии для успеха в карьере,
            бизнесе и личной жизни, выходить на более дорогие уровни
            коммуникаций
          </>
        )
      }
    ]
  },
  education: (
    <>
      <div style={{ maxWidth: 600, margin: "4rem auto" }}>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Обучение в ВШСДТ — это отличная теоретическая подготовка в сочетании с
          формированием творческого мышления и регулярной практикой создания
          модных и стильных образов
        </p>
        <div style={{ margin: "1rem auto", textAlign: "center" }}>
          <svg width={64} height={64} viewBox="0 0 24 24">
            <path
              fill="rgb(160,160,160)"
              d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"
            />
          </svg>
        </div>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Получение системных знаний, новые возможности профессионального
          развития, технологии работы с клиентами и модными трендами.
        </p>
      </div>
    </>
  ),
  formats: [
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z"
          />
        </svg>
      ),
      title: "Банковская рассрочка",
      price: false,
      text: (
        <>
          от 5750 рублей в месяц
          <br />
          рассрочка на год без переплат
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
          />
        </svg>
      ),
      title: "Онлайн / Заочно",
      price: false,
      text: (
        <>
          от 15000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            d="M20,17C21.1,17 22,16.1 22,15V4C22,2.9 21.1,2 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9C1.5,7.9 2.4,7 3.5,7H15M8,4C8,5.1 7.1,6 6,6C4.9,6 4,5.1 4,4C4,2.9 4.9,2 6,2C7.1,2 8,2.9 8,4Z"
            fill="rgb(122,101,89)"
          />
        </svg>
      ),
      title: "Очное обучение",
      price: false,
      text: (
        <>
          от 20000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            d="M15.46 18.12L16.88 19.54L19 17.41L21.12 19.54L22.54 18.12L20.41 16L22.54 13.88L21.12 12.46L19 14.59L16.88 12.46L15.46 13.88L17.59 16M14.97 11.62C14.86 10.28 13.58 8.97 12 9C10.3 9.04 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.39 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.62M13 16H7C7 14.9 6.1 14 5 14V10C6.1 10 7 9.1 7 8H17C17 9.1 17.9 10 19 10V10.05C19.67 10.06 20.34 10.18 21 10.4V6H3V18H13.32C13.1 17.33 13 16.66 13 16Z"
            fill="rgb(122,101,89)"
          />
        </svg>
      ),
      title: "Бесплатное обучение",
      price: false,
      text: (
        <>
          Доступны открытые лекции и<br />
          обучение по гос.программе
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    }
  ],
  youtube: {
    head: false,
    text: (
      <>
        <p className="p-constructor text-center">
          Подписывайтесь на Youtube канал ВШСДТ ТВ:
          <br />
          интервью с преподавателями, экспертами, выпускниками, открытые лекции,
          обзоры трендов
        </p>
        <p className="text-center">
          <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
            Перейти на Youtube канал &gt;&gt;&gt;
          </a>
        </p>
      </>
    )
  },
  papersTitle: "Три варианта обучения, стоимость",
  videos: [
    {
      video: "rYKZAsSnC84",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с основателем ВШС в Новосибирске Надеждой Исхаковой"
    },
    {
      video: "gZeWO85gha0",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Риберой Делаварес"
    },
    {
      video: "9Ns4tVxzE_8",
      text: "Грузинские дизайнеры. Эксперты: Татьяна Фомина, Татьяна Ярина."
    },
    {
      video: "VG3HCLBilGc",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Мариной Никулиной"
    },
    {
      video: "0s4k1IH3Pik",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Еленой Малюшиной"
    },
    {
      video: "npJ2B4syBMk",
      text:
        "Интервью преподавателя ВШСДТ Татьяны Фоминой с выпускницей Эвелиной Коломыцыной"
    }
  ]
};
