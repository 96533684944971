import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import Expand from "../../components/expanded";
import List from "../../components/list";
import Facultativ from "./facultativ";
let styles = {
    list: {
      font: "400 1em 'Roboto'",
      color: "rgb(100,100,100)"
    },
    summary: {
      background: "rgb(240,240,240)",
      font: "700 1em 'Roboto'",
      color: "rgb(100,100,100)"
    },
    details: {
      background: "rgb(240,240,240)",
      font: "400 1em 'Roboto'",
      color: "rgb(100,100,100)"
    }
  },
  content = [
    {
      summary: "Профессия визажиста: история и современность",
      details: (
        <List
          arr={[
            "История возникновения профессии",
            "История макияжа от древних времени до наших дней",
            "История моды XX века. Иконы стиля",
            "Основные направления, идеи и тенденции в современном макияже",
            "Мировые make-up artists, школы макияжа",
            "Особенности современной модной индустрии"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Профессиональная терминология, инструменты и материалы",
      details: (
        <List
          arr={[
            "Инструменты (кисти): виды, назначение, сравнительная характеристика",
            "Уход и средства гигиены",
            "Современные косметические средства и декоративная косметика",
            "Бренды и сегментация: массмаркет, люкс, проф. Преимущество и недостатки",
            "Профессиональный язык визажиста"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Цвет как средство выразительности. Колористика",
      details: (
        <List
          arr={[
            "Психология цвета. Цвет и эмоция",
            "Цветовые круги. Круг Иттена",
            "Контраст как один из основных приемов выразительности",
            "Цветотипирование. Современные концепции цветотипов. Вариативность применения"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Теоретические основы работы с лицом",
      details: (
        <List
          arr={[
            "Типы лиц, формы глаз, бровей, скул, губ",
            "Понятие пропорции и коррекции. Моделирование лица",
            "Понятие «идеального лица». Композиционный центр и его роль в макияже",
            "Методы и способы коррекции",
            "Коррекция бровей: построение формы, художественное оформление, окрашивание, инструменты",
            "Контурирование лица. Коррекция носа, скул, формы лица. Виды техники: кремовая, сухая, стробинг, nude",
            "Стрелки: виды, создание формы глаз, материалы для создания, рисование",
            "Свет, тень и цвет в макияже"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Основа макияжа. Работа с кожей.",
      details: (
        <List
          arr={[
            "Типы кожи и их характеристика. Способы работы с разными типами",
            "Современные текстуры и средства по уходу",
            "Макияж без макияжа. Макияж «Just Skin»"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Основные виды современного макияжа",
      details: (
        <List
          arr={[
            "Дневной макияж: классический, маккиях в 3-х и в 5-ти цветной палетке",
            "Вечерний макияж: вертикальная техника, классический smoky eyes, макияж в кремовой технике, smoky eyes в кремовой технике, арабский макияж, использование декоративных элементов",
            "Свадебный макияж: виды и особенности работы",
            "Возрастной макияж: лифтинг-макияж и особенности работы",
            "Мужской макияж: макияж-коррекция, маккиях для фото и видео-съемки"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Основные техники современного макияжа",
      details: (
        <List
          arr={[
            "Карандашная техника: классический дневной макияж, smoky eyes в карандашной технике, макияж Кошачий глаз, макияж Птичка, макияж Петля, Ретро",
            "Креативные техники: акварельная, air-brush"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Стиль и создание образа",
      details: (
        <List
          arr={[
            "Основные стилевые направления в современной моде: исторические стили, ретро-стили, субкультурные стили, авангардные стили, уличные стили",
            "Создание стилизованного образа на основе микростилей XX века",
            "Идея индивидуального стиля: одежда и макияж"
          ]}
          style={styles.list}
        />
      )
    },
    {
      summary: "Профессиональное продвижение",
      details: (
        <List
          arr={[
            "Портфолио визажиста: съемка портфолио, макияж для фото и видео-съемки, обработка фотографий, взаимодействие с партнерами и моделями",
            "Самопозиционирование и поиск клиентов. Психология общения с клиентами",
            "Создание и продвижение сайта, ведение групп в соцсетях",
            "Реклама и продвижение услуг",
            "Открытие собственного бизнеса"
          ]}
          style={styles.list}
        />
      )
    }
  ];

export default function Programma() {
  return (
    <div>
      <Grid container justify="center" alignItems="stretch" spacing={3}>
        <Grid item xs={12} md={8}>
          <h2
            style={{
              font: "500 1.8em 'Lora'",
              textAlign: "center",
              color: "rgb(80,80,80)",
              margin: 0,
              padding: 24
            }}
          >
            Содержание программы
          </h2>
          {content.map((el, i) => (
            <Expand
              summary={{
                text: i + 1 + ". " + el.summary,
                style: styles.summary
              }}
              details={{
                text: el.details,
                style: styles.details
              }}
              expand={true}
              key={i}
            />
          ))}
          <Facultativ />
        </Grid>
        <Grid item xs={12} md={4}>
          <Hidden smDown>
            <div
              style={{
                height: "100%",
                paddingTop: 100,
                boxSizing: "border-box"
              }}
            >
              <img
                src="/img/make-up/img-side.jpg"
                alt=""
                style={{
                  position: "sticky",
                  maxWidth: "100%",
                  top: 4
                }}
              />
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
}
