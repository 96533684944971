import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="Мода и стиль"
      title="Индивидуальный морской стиль"
      teacher="Светлана Кононец"
      descript={<span>on-line</span>}
      online
      date="23 июля"
      time="10:00"
      duration=""
      image="/img/modern-fashion-style/sea-style.jpg"
      text={
        <span>
          В летнем гардеробе, пожалуй, нет более распространённого и всеми
          любимого стиля, чем морской. Даже в городах, расположенных далеко от
          морского побережья, тельняшка, белые брюки и парусиновые тапочки
          помогают почувствовать атмосферу Лазурного берега. К сожалению,
          контрастная полоска и строгие силуэты морской формы украшают далеко не
          любую внешность. Как можно создать свой индивидуальный морской стиль,
          погружающий в атмосферу летнего морского отдыха и с выгодной стороны
          подчеркивающий внешность и фигуру, мы и поговорим на встрече.
        </span>
      }
      q
      part
      color="rose"
      id="9h63szTeZxGaP7c9Q"
      setBillingId={props.setBillingId}
    />
  );
}
