import React from "react";
import Card from "../card";

export default function Colors(props) {
  return (
    <Card
      head="Психология имиджа"
      title="Манипуляция цветом. Психологический аспект и неожиданный эффект"
      teacher="Лиана Бахова"
      descript={<span>Онлайн-лекция,</span>}
      online
      date="8 апреля"
      time={<span>20:00</span>}
      duration="45 минут"
      image="/img/modern-fashion-style/colors.jpg"
      text={
        <span>
          Мы часто слышим о делении цветов, например, на теплые и холодные. Но
          задумываемся ли о том, каким мощным манипулятивным свойством обладает
          температурная классификация? Как цвет в целом влияет на принятые нами
          решения? И наконец, психология цвета – это лженаука или мощный
          инструмент влияния?
          <br />
          На эти и многие другие вопросы мы найдем ответы во время данного
          вебинара. Проанализируем результаты уникальных исследований и
          разберем, как выставляются «цветовые ловушки».
        </span>
      }
      // q
      part
      view
      // partPrice="600"
      id="7SLcoi9wrWmsmBGe9"
      setBillingId={props.setBillingId}
    />
  );
}
