import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import Price from "../../components/price-with-action-test/price";
import { MiniForm } from "../../components/forms";
import Video from "../../components/video";
import {
  HeadBlock,
  Teachers,
  Programma,
  Text3Block,
  ShessBlock,
  HowIsTheTrainingGoing,
  BuilderLayout,
  TitleH2
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Имидж-коммуникация — личный PR и продвижения</title>
        <meta name="keywords" content={c.meta.keywords} />
        <meta name="descript" content={c.meta.descript} />
      </Helmet>
      <Page lable="Имидж коммуникация" lableMobile="Имидж коммуникация">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                left: <Video link={c.screen2.video} />,
                right: c.screen2.text
              }}
              mediaLeft
              mobileMediaUp
              vAlign="center"
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <>
              <TitleH2 content="Как проходит обучение" />
              <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            </>,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,
            <>
              {/* <TitleH2 content="Диплом после обучения" scroll="diplom" /> */}
              <ShessBlock
                content={{
                  left: c.rgsu.text,
                  right: c.rgsu.image
                }}
                vAlign="center"
              />
            </>,
            <Teachers
              content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
            />,
            // <>
            //   <TitleH2
            //     content="Стоимость обучения в рассрочку на 12 месяцев"
            //     scroll="price"
            //   />
            //   <Price content={c.price} />
            // </>,
            <>
              <TitleH2 content={c.miniFormHead} scroll="singup" />
              <MiniForm formName="miniforma-image-communication" />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
