import React from "react";
import Helmet from "react-helmet";
import { contents } from "./contents";
import Page from "../../components/page";
import Layout from "../../patterns/pattern-fs/layout";

function Style4Stylist(props) {
  return (
    <>
      <Helmet>
        <title>
          Стиль для стилиста, авторская мастерская Светланы Шабалиной
        </title>
        <meta
          name="keywords"
          content="профессиональный, стиль, имидж, стилист, имиджмейкер, консультант, шопер"
        />
        <meta
          name="descript"
          content="Авторский курс Светланы Шабалиной «Стиль для стилиста» помогает найти свой индивидуальный стиль. В процессе обучения преподаватель не только проверяет домашнюю работу, но и корректирует, тем самым дает обратную связь. Таким образом, разработка собственного стиля осуществляется преподавателем с участием студента. В течение обучение будет создана персо­нальная книга стиля, разобран гардероб и проведен шопинг."
        />
      </Helmet>
      <Page lable="Стиль стилиста">
        <Layout content={contents} />
      </Page>
    </>
  );
}

export default Style4Stylist;
