import React from "react";
import { OtherTile } from "../tiles-w-button/tiles";
import { Title } from "../title-w-svg-icon/title";
import "./style.css";

export function TitleText(props) {
  return (
    <div className="title-text-container">
      {props.title && <h2>{props.title}</h2>}
      {props.afterTitle && (
        <p className="title-text-after-title">{props.afterTitle}</p>
      )}
      <div className="content">{props.content && props.content}</div>
    </div>
  );
}

export function ButtonScroll(props) {
  return (
    <a
      href={props.outLink ? props.link : `#${props.link}`}
      target={props.outLink ? "_blank" : null}
    >
      <div className="button">{props.textLink}</div>
    </a>
  );
}

export function TitleButtonItems(props) {
  const c = props.content;

  return (
    <TitleText
      title={c.title}
      afterTitle={c.afterTitle}
      content={
        <OtherTile
          content={c.content.map(el => (
            <div className="tile-item">
              <Title check title={el.title} />
              <p>{el.text}</p>
            </div>
          ))}
        />
      }
    />
  );
}
