import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Paragraph from "../../components/p-array";
import ColorBlock from "../../components/color-block";
import Head from "../../components/head-articles";
import Dot from "../../components/dotted-line";

export default function Irony() {
  return (
    <div>
      <Helmet>
        <title>
          ВШС: Софья Зозуля: Ирония судьбы – или еще одна статья о новогодних
          нарядах
        </title>
        <meta
          name="keywords"
          content="ВШС: Софья Зозуля: Ирония судьбы – или еще одна статья о новогодних нарядах"
        />
        <meta
          name="title"
          content="Ирония судьбы – или еще одна статья о новогодних нарядах"
        />
        <meta
          name="description"
          content="Астрологи советуют встречать год Петуха в красном атласном платье. Но как быть, если вы не любите красный, или красный цвет вам не идет? Есть масса вариантов!"
        />
      </Helmet>
      <Top />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={4}
        style={{ maxWidth: 1000, padding: 20, margin: "0 auto" }}
      >
        <Grid item xs={12} md={12} style={{ padding: 20 }}>
          <Head
            head={
              <span>
                Ирония судьбы – или еще одна статья
                <br />о новогодних нарядах
              </span>
            }
            author="Софья Зозуля"
            linkAuthor="/sofjya_zozulya"
            vk="https://www.vk.com/sofia.chesnokova"
            fb="https://www.facebook.com/profile.php?id=100002113906771"
            instagram="https://instagram.com/sofyazozulya"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Если, прочитав название статьи, вы сразу ее не закрыли, значит, вы еще верите в людей. Я постараюсь оправдать ваши ожидания! То, что астрологи советуют встречать год Петуха в красном атласном платье, вы, наверняка, знаете и без меня. Но как быть, если вы не любите красный, или красный цвет вам не идет – в конце концов – если вы просто не хотите покупать еще одно платье «на один раз»? Спешу вас обрадовать: вариантов масса. Вариантов свежих, модных и легко трансформируемых в повседневные образы. О них сегодня и поговорим."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 20, paddingTop: 20 }}>
          <div>
            <img
              src="/img/blog/irony/klara-bou.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Но, для начала, дабы поймать настроение, давайте вспомним некоторые новогодние наряды знаменитых киноактрис.",
              "Посмотрите на эту чудесную открытку. Это, конечно, раскрашенная черно-белая фотография, и ее главная героиня – Клара Боу, секс-символ Голливуда 30-х годов, первая IT-girl. В розовой шелковой пижаме, она исполняет здесь роль ребенка рождественским утром…  Вам ничего не напоминает этот милый наряд? О бельевой моде мы обязательно поговорим чуть позже."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ paddingTop: 20 }}>
          <ColorBlock
            image="/img/blog/irony/dubina.jpg"
            color="rgb(230,240,255)"
            text="А это чудесное платье Дины Дурбин? Родом из 40-х, оно украшено шарами на манер новогодней елки – чем вам не идея для новогодней фотосессии?"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              "Потрясающий стиль Нью Лук подарил миру Кристиан Диор в конце 40-х годов. Посмотрите на этих восхитительных актрис – Элизабет Тейлор и Людмилу Гурченко – на них платья именно такого силуэта. Его отличительная черта – узкая талия, которая дополнительно подчеркивается широкой юбкой, – создает очень женственный и немного наивный образ. Такой силуэт платья стал классикой женственности, поэтому смело берите его на заметку для создания новогоднего наряда!",
              <img
                src="/img/blog/irony/teylor-gurchenko.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="/img/blog/irony/loren-mendsfield.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              "А вот Софи Лорен и Джейн Мэнсфилд в те же годы предпочли изысканные платья в пол."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ColorBlock
            image="/img/blog/irony/brilska.jpg"
            text={
              <Paragraph
                j
                content={[
                  "Обратите внимание на это скромное, но очень элегантное платье Нади Шевелевой из известного всем фильма 1975 года.",
                  "Что важно в нем отметить: первое – воротник рубашечного кроя. Это – нестареющая классика, которая смотрится всегда элегантно и благородно, кроме того, такой вырез визуально удлиняет шею. Второе – цвет платья, про который вы можете сказать, что он блеклый, невзрачный и совсем не подходит для новогодней ночи, и, отчасти, будете правы, но, вот, на что я прошу обратить ваше внимание: такой приглушенный горчичный цвет прекрасно оттеняет теплую красоту блондинки Барбары Брыльска, он не перетягивает на себя внимание, и зритель полностью фокусируется на лице, на глазах актрисы. Я хочу, чтобы вы тоже отметили для себя этот важный принцип: не вы существуете для платья, а платье – для вас. Платье – это только красивая рама для вашего обаяния."
                ]}
              />
            }
            color="rgb(250,245,240)"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ColorBlock
            image="/img/blog/irony/yakovleva.jpg"
            text={
              <Paragraph
                j
                content={[
                  "А на этом кадре из фильма «Чародеи» 1982 года я хочу обратить ваше внимание не столько на платье Александры Яковлевой, сколько на костюм Александра Абдулова и на то, как чудесно они сочетаются.",
                  "Сейчас мы называем это family-look. Помните о том, что, если вы приходите на торжество парой, вы должны, как минимум, совпадать со своим мужчиной стилистически. Если вы в вечернем платье – на мужчине должен быть костюм, и никак не джинсы или рубашка с коротким рукавом. Или, к примеру, джинсы и трикотажное поло на мужчине предполагают такой же расслабленный образ для девушки – и тут уже вы не сможете позволить себе ничего блестящего, яркого – да и просто нарядного. Высший пилотаж – когда пара не просто одета в одном стиле и цветовой гамме, а составляет единую стилевую композицию."
                ]}
              />
            }
            color="rgb(225,230,240)"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Dot />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Теперь, надеюсь, хорошее настроение не покинет больше вас, и вы готовы перейти к основной части статьи. Нужно понимать, что праздничная мода совершенно логичным образом отражает общие модные тенденции, и сейчас мы рассмотрим те из них, которые проще всего адаптировать к встрече Нового года.",
              "Итак, первая модная тенденция, которая будет на волне еще очень долго, это мода на Восток. Надо сказать, что Европа увлеклась Востоком еще в XVII веке, и, с тех пор, эта тенденция, то вспыхивая, то угасая, никогда не покидала окончательно модных подиумов. Сегодня мы можем трактовать этот тренд очень широко: в моде и арабский тюрбан, и японское кимоно, и узбекский халат. Дополнив хотя бы одной такой вещью свой образ, вы сразу почувствуете некую расслабленность и неспешность, кроме того, одежда с восточным колоритом всегда выглядит очень элегантно и обращает на себя внимание."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src="/img/blog/irony/img1.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Накиньте на плечи яркое кимоно – и оно раскрасит даже самый простой образ с джинсами и топом праздничными красками. Особенно актуально это будет для хозяйки дома, которая весь новогодний вечер вынуждена порхать из столовой на кухню – и обратно. Поверьте, любуясь переливами вашего кимоно, ни один гость не подумает, что вы в течение пяти часов строгали оливье и варили холодец.",
              "Узбекский халат из натурального шелка произведет настоящий фурор на званом ужине, а тюрбан, дополняющий наряд для корпоратива, привлечет к вам не только восхищенные взгляды мужчин, но и завистливые – девушек."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "1.1em",
              textAlign: "center",
              margin: 0,
              padding: 8,
              borderTop: "4px dotted rgb(200,200,200)",
              borderBottom: "4px dotted rgb(200,200,200)",
              color: "rgb(80,80,80)"
            }}
          >
            Вторая столь же крупная тенденция – это мода на андрогинность. Если
            описать этот стиль в двух словах, то одежда мужчин становится более
            женственной, а женщин – более мужественной.
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src="/img/blog/irony/img2.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              "Несомненно, дамы, наши с вами родные мужчины ни за что не примерят на себя рубашку с жабо. А вот мы, женщины, гораздо более вольны в своем выборе. Так почему же, к примеру, вам, милые девушки, не надеть вместо коктейльного платья пиджак-смокинг? С немного укороченными брюками и лаконичными туфлями на шпильке, смокинг создаст для вас просто незабываемый образ. Добавьте гладко убранные волосы, сделайте акцент на губы – и на любой  вечеринке вы абсолютно точно не останетесь незамеченной!"
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Третья модная тенденция, на которой я бы хотела заострить внимание – это бельевой стиль. Еще лет пять назад модницы, выходившие в свет в шелковых комбинациях, просто шокировали окружающих или, как минимум, вызывали усмешки. Теперь и платье-сорочка, и костюм в пижамном стиле, и платье-халат смотрятся очень модно, свежо и совсем-совсем чуточку дерзко. Я предлагаю вам, не раздумывая, взять этот тренд на вооружение, тем более что все эти вещи в шелке или бархате выглядят очень нарядно, элегантно и дадут окружающим понять, что вы – дама, относящаяся к себе слегка с иронией. Умение не принимать себя всерьез – это то, без чего в наши дни не может обойтись ни один думающий человек. Ирония – это, пожалуй, самый важный и самый глобальный модный тренд последних десятилетий."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img3.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Платье сорочка выручит вас и на корпоративе (наденьте ее на шелковую футболку контрастного цвета и дополните грубыми ботинками), и на романтическом ужине (тогда компаньонами могут выступить туфли на шпильке и длинные серьги). Ваш мужчина наверняка оценит и шелковый костюм в пижамном стиле, слегка ироничный – и очень соблазнительный. Тот же самый пижамный костюм, но выполненный, к примеру, в бархате, станет отличным вариантом для семейного праздника, а платье-халат, несомненно, выручит хозяйку дома и позволит ей оставаться самой элегантной среди приглашенных дам, даже если ей все-таки удалось съесть ложечку селедки под шубой и кусочек картошки по-французски."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "1.1em",
              textAlign: "center",
              margin: 0,
              padding: 8,
              borderTop: "4px dotted rgb(200,200,200)",
              borderBottom: "4px dotted rgb(200,200,200)",
              color: "rgb(80,80,80)"
            }}
          >
            Пожалуй, только ленивый не слышал, что в текущем сезоне на пике моды
            стиль диско – сам по себе очень яркий, праздничный и идеально
            подходящий для встречи Нового года. Возьмем из него обувь на
            платформе, брюки-клеш, блестящие ткани, яркие цвета и их контрастные
            сочетания. И, если уж мы заговорили о диско, в котором все сверкает
            и блестит, то я бы хотела обратить ваше внимание на следующие
            блестящие тренды.
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="/img/blog/irony/img4.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              "Первый тренд – это люрекс. Какое счастье, что его можно снова носить и не бояться выглядеть старомодно! Люрекс сейчас правит бал, люрекс просто на пике популярности! Это отличный вариант для тех, кто хочет выглядеть нарядно и в то же время чувствовать себя комфортно, например, если вы проводите новогоднюю ночь дома в кругу родных – или в загородном доме. Обычные брюки, плюс футболка с люрексом, плюс красивые серьги – равно комфортный, модный, нарядный образ. Так что, если вы любите люрекс, возможно, эта новогодняя ночь – ваш шанс? Но поторопитесь, такие яркие тренды проходят так же стремительно, как появляются."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img5.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Вторая блестящая тенденция – это пайетки. И, если в повседневных комплектах я порекомендую приглушить юбку в пайетках свитером простого кроя и обувью на плоском ходу, то в новогоднюю ночь – дам вам карт-бланш: в платье в пайетках вы будете звездой вечеринки. Если же вы опасаетесь стать похожей на диско-шар, выберите юбку в пайетках или майку в пайетках – или даже просто тонкий шарф в пайетках. Успех обеспечен в любом случае!"
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img6.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Еще один очень красивый, статусный и благородный тренд сезона – это жаккардовая ткань. Новогодняя ночь – это тот момент, когда вы можете надеть жаккардовый брючный костюм – и выглядеть сногсшибательно даже на светском приеме. В повседневном комплекте я посоветую ограничиться чем-то одним: или юбкой, или жакетом, или брюками, но в новогоднюю ночь можно все! Жаккард – прекрасный вариант для тех, кому пайетки и пижамы кажутся слишком молодежными. Нарядно, благородно – и очень, очень актуально!"
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img7.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Есть и более спокойные, но от этого не менее модные и привлекательные тренды, которые мы с вами можем прекрасно использовать в новогодних комплектах. Например, бархат!",
              "Мода сейчас любит бархат настолько, что бархатным может быть все, что угодно: и жакет, и платье, и юбка, и платье-сорочка, и топ, и пижамный костюм, и платье-халат (здесь, как видите, я намекаю на то, что модные тренды не обязательно использовать по одному, очень здорово будет их сочетать!), и обувь, и сумочка и…! Очень благодатный и благодарный тренд: бархат комбинируется и с хлопком, и с шифоном, и с шелком – все, что позволит ваша фантазия."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img8.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Еще один материал, который сейчас на пике популярности, это кружево. Будет ли кружевным все платье – или только юбка, а, может, отделка на вашем бельевом топе – решать вам. Кружевная вещь, в зависимости от того, какая одежда его обрамляет, может выглядеть и празднично, и повседневно, но, несомненно, кружево всегда привлекает внимание. Отличный выбор для веселой дружеской вечеринки с танцами и конкурсами – расклешенная кружевная юбка."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src="/img/blog/irony/img9.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              "Не любите кружево? Не беда: остановите свой выбор на юбке из фатина (только проследите за тем, чтобы фатин на вашей юбочке лежал ровно в один слой: пышные юбки-пачки – уже вчерашний день), или на плиссированной юбке, которая впоследствии выручит вас в практически любой жизненной ситуации. Вообще, если вы девушка не только яркая, активная, но и практичная, юбка будет для вас лучшим выбором: ведь ее можно будет дополнить более спокойными вещами и создать отличный smart-casual образ."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img10.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Если все же символ будущего года – петух – не дает вам покоя, то вашему вниманию тренд, словно созданный для встречи года петуха – перья! Как изысканно, как игриво смотрятся перышки , расположенные по краю жакета, топа, юбки… Создается впечатление, что девушка сейчас взлетит! Чудесно будут смотреться и, к примеру, серьги с перьями."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              <img
                src="/img/blog/irony/img11.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              "Кроме того, очень актуально и оригинально вы будете выглядеть, если просто дополните свой наряд брошкой (или сетом из брошей!): не обязательно в виде петуха или пера, но, если хочется соблюсти символику праздника, то почему бы нет. Тем более что разноцветное оперение петушиного хвоста подойдет к наряду совершенно любого цвета! Особенно выигрышно будет смотреться такая брошь, если вы придерживаетесь консервативных взглядов, и решили надеть в новогоднюю ночь беспроигрышный вариант – маленькое черное платье."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "1.1em",
              textAlign: "justify",
              margin: 0,
              padding: 8,
              borderTop: "4px dotted rgb(200,200,200)",
              borderBottom: "4px dotted rgb(200,200,200)",
              color: "rgb(80,80,80)"
            }}
          >
            Итак, мы познакомились с основными праздничными тенденциями, и я
            буду очень рада, если вы нашли для себя что-то новое и отказались от
            красного платья в пользу, например, пижамного костюма того же цвета.
            Но, какой бы наряд вы для себя ни выбирали, помните, что он должен
            быть, в первую очередь, уместен в обстановке праздника (если
            ожидается разудалая вечеринка с танцами, возможно, стоит отказаться
            от платья в пол и шпилек?), и, во вторую, подчеркивать вашу
            природную красоту. Самое главное – на праздник идете именно вы, а не
            ваше платье. И, если покупка новогоднего туалета не вписывается в
            ваши планы, наденьте любимые брюки, лодочки на каблуке, белую
            рубашку, дополните цветным колье или серьгами, яркой помадой – и ваш
            сияющий взгляд засверкает ярче любых пайеток. Счастливого Нового
            года и Рождества!
          </p>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
