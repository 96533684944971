import React from "react";
import "./style.css";

export function ListItem(props) {
  return (
    <div className="item-list-container">
      <div className="icon">{props.icon}</div>
      <div className="text">{props.text}</div>
    </div>
  );
}

export function ListWIcon(props) {
  return (
    <div className="list-container">
      {props.content.map(el => (
        <ListItem icon={el.icon} text={el.text} />
      ))}
    </div>
  );
}

export function ListWTitle(props) {
  const c = props.content;

  return (
    <div className="list-w-title-container">
      <h2>{c.title}</h2>
      <p>{c.afterTitle}</p>
      <ListWIcon content={c.content} />
    </div>
  );
}
