import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { MiniForm } from "../../components/forms";
import "./style.css";
import { Grid } from "@material-ui/core";
import Banners from "./banners";
import BlocksComponent from "./blocks";
import {
  BuilderLayout,
  HeadBlock,
  TextAndTags,
  Programma,
  TitleH2,
  Teachers,
  ShessBlock,
  ShessBuilder,
  ScrollingLink,
  AfterEducation,
  SpaceElement,
  Text3Block,
  MessageOnPaper
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Стилист-имиджмейкер — курс обучения как второе высшее</title>
        <meta
          name="keywords"
          content="имиджмейкер, стилист, курсы, обучение, школа, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Максимальная годовая программа обучения. Полное погружение в атмосферу моды и имидж-консультирования клиентов, стилизации фотосъемок и продюссирования fashion проектов. Комплексная программа обучения в области имидж-технологий и стилистики. Уникальное сочетание теоретической базы c развитием творческого мышления в ходе регулярной практики создания модных и стильных образов. Системные знания, новые возможности профессионального развития, технологии работы с клиентами и модными трендами."
        />
      </Helmet>
      <Page lable="Стилист-имиджмейкер">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content={c.academy.title} />
              <ShessBlock
                content={{
                  left: c.academy.image,
                  right: c.academy.text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} style={{ paddingTop: 0 }} />
              <MiniForm formName="miniform-second-degree-imagemaker" />
            </>,
            <Programma content={{ programma: c.programma, variants: false }} />,
            <ShessBlock
              content={{
                right: c.shess[0].media,
                left: c.shess[0].text
              }}
              mediaLeft={false}
              vAlign="center"
              allWhite={true}
            />,
            <>
              <TitleH2 content={c.textAndMedia.head} />
              <ShessBuilder content={c.textAndMedia.content} vAlign="center" />
            </>,
            <AfterEducation content={c.afterEducation} />,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,
            <>
              <ScrollingLink scrollLink="price" />
              <Programma
                noScroll
                content={{ programma: c.prices }}
                styleHead={{ textAlign: "center" }}
              />
              <div style={{ height: "4rem" }} />
              <MessageOnPaper content={c.garanty} />
              <div style={{ height: "4rem" }} />
              <p className="p-constructor center">
                <strong>
                  Раннее бронирование: на обучение с сентября 2022 года договора
                  заключаются со скидкой
                </strong>
              </p>
            </>,
            <>
              <TitleH2 content="Открытое образование" scroll="freeeducation" />
              <ShessBlock
                content={{
                  left: c.bot.image,
                  right: c.bot.text
                }}
                mediaLeft={true}
              />
              <div style={{ height: "4rem" }} />
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <div className="box-news">{c.text1}</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  {c.text1Media}
                </Grid>
              </Grid>
              <Link to="/openedu">
                <div className="button">
                  Смотреть все курсы открытого образования
                </div>
              </Link>
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
              <BlocksComponent />
            </>,
            <SpaceElement />
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
