import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Head from "../../components/head-articles";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Paragraph from "../../components/p-array";
import Expand from "../../components/expanded";

export default function Vremya() {
  var styles = {
    summary: {
      fontFamily: "Roboto",
      fontSize: "1em",
      fontWeight: 700,
      color: "rgb(80,80,80)",
      background: "rgb(230,230,230)"
    },
    details: {
      background: "rgb(245,245,245)"
    }
  };

  var content = [
    {
      summary: "1. Классика и/или минимализм",
      details: (
        <Paragraph
          content={[
            <span>
              Возврат к подчеркнуто строгой, минималистичной классике (Breguet,
              Carl F.&nbsp;Bucherer, Glashuette Original, Hermes,&nbsp;Rado).
              Почти полностью пропала экстравагантность, сменившаяся классикой с
              элементами ретро из 60-х, 70-х, 40-х годов: отказ от лишних
              счетчиков в пользу&nbsp;чистоты циферблата, возрождение маленькой
              секундной стрелки (элемент ретро), более плоские механизмы.
              Единственное «украшение» в часах для мужчин: окошко с датой и три
              обязательные стрелки. Стандартный круглый корпус, циферблат
              светлого оттенка, хорошо просматриваемые цифры, ремешок –
              коричневый или черный.
            </span>,
            <span>
              Как микротенденцию можно отметить миниатюризацию женских моделей:
              практически все марки выпустили «малышек» (16-мм у Longines, 21 мм
              у Blancpain) с использованием кварцевого механизма. Кварц поднялся
              в статусе и стал носителем традиционных часовых&nbsp;ценностей.
              Этому способствовали и собственно технологии – батарейки, служащие
              до 10 лет.
            </span>
          ]}
        />
      )
    },
    {
      summary: "2. Ретро и винтаж",
      details: (
        <Paragraph
          content={[
            "Нестареющая тенденция в большинстве направлений фешн-индустрии. В основе циферблата – старые чертежи (характерный почерк сегмента luxury). Самое популярное усложнение – хронограф и мировое время. Бренд Patek Philippe создает часы, которые усложнены хронографом и показывают своему владельцу время во всём мире. Бренд Bell&Ross выпускает линейку часов в стиле винтаж, но с высокоточным механизмом внутри. Каждая модель этой линейки посвящена определенному этапу в авиастроении и эпохе 60-х."
          ]}
        />
      )
    },
    {
      summary: "3. Удобство и спорт",
      details: (
        <Paragraph
          content={[
            "Заметно популярными становятся часы со светящимися стрелками, черными циферблатами, черными корпусами из керамики или из специально обработанного титана, а также наборы съемных ремешков разных цветов и фасонов. В коллекции Vacheron Constantin есть классика и спорт в нескольких вариантах: с тремя стрелками, с малой секундной, ультратонкие с вечным календарем и без него, с каучуковом или кожаным ремешком, либо с металлическим браслетом. Владелец сам может менять их благодаря специально разработанной системе замены ремешка."
          ]}
        />
      )
    },
    {
      summary: "4. Smart часы и новые материалы",
      details: (
        <Paragraph
          content={[
            "Развивается как технология «умных» часов и все больше брендов предлагают подобные модели, так и исследования в области материалов. Мануфактуры и Дома все чаще разрабатывают и патентуют собственные материалы и сплавы для изготовления корпусов и деталей механизмов, а также смело экспериментируют с циферблатами. Особую популярность у дизайнеров обрели такие материалы, как золото (белое в тренде) и сталь, объединённые в одной модели. Сапфир становится самым востребованным часовым материалом, потеснив карбоны и карбиды."
          ]}
        />
      )
    },
    {
      summary: "5. Синий цвет",
      details: (
        <Paragraph
          content={[
            "Сегодня – это  «новый черный», использующийся во множестве оттенков в циферблатах и ремешках, гармонично сочетающийся с платиной и белым золотом."
          ]}
        />
      )
    },
    {
      summary: "6. Ювелирная роскошь",
      details: (
        <Paragraph
          content={[
            "Изящный циферблат, браслет, щедро усыпанные бриллиантами – беспроигрышный ход. Ювелиры Audemars Piguet оформили оникс и бриллиантовое паве как грани метеорита. У Cartier бриллианты на циферблате танцуют в такт биению вашего сердца. Красоту вечной роскоши демонстрируют знаменитые плавающие бриллианты от Chopard, Rolex – королевскую недосягаемость."
          ]}
        />
      )
    },
    {
      summary: "7. Часы с тематическим дизайном",
      details: (
        <Paragraph
          content={[
            "Далеко не новый тренд, с постоянной и обновляющейся тематикой. Цветы как символ женственности и романтизма – тренд наряду с классикой: особенно популярен циферблат-картинка, выполненная с помощью эмали и перламутра. Природные или философские мотивы: под стеклом корпусов моделей Van Cleef & Arpels порхают бабочки, парят облака, дни сменяются ночами. Линейка хронографов Vacheron предлагает изысканные цветочные и ориентальные узоры на циферблатах, повторяющих сложную вязь восточных вышивок или священных манускриптов. Будущее и тайна представлена темой космоса – символы зодиака, звездного цикла выложены бриллиантами по перламутру: у Baume & Mercier – лунный календарь, у Jaeger-leCoultre – фазы Луны, а у Montblanc – часовые пояса и время в крупных городах мира."
          ]}
        />
      )
    },
    {
      summary: "8. Этника",
      details: (
        <Paragraph
          content={[
            "Еще один постоянный тренд фешн-индустрии. Одна из линий Vacheron Constantin создана по мотивам традиционных декоративных орнаментов разных культур: архитектура оттоманской Турции (резные ставни и карнизы мушараби), узорная цветочная вязь индийских рукописей эпохи Великих Моголов, китайская вышивка шелком и французские кружева."
          ]}
        />
      )
    },
    {
      summary: "9. Концептуальность",
      details: (
        <Paragraph
          content={[
            <span>
              Коллобарации художников, дизайнеров и производителей – новый
              взгляд на время как на искусство технологий. Seiko выпускает
              лимитированную серию с длинными&nbsp;ремешками, украшенную
              принтами по&nbsp;мотивам работ фотографов Нобуйоси Араки&nbsp;и
              Диадо Мориямы. Corum делает еще более смелый шаг, пригласив
              художника Дани Оливье оформить циферблаты в стиле «светового ню».
              Несомненно, запомнится артистическая (просто и эффектно)
              коллабрация&nbsp;Raymond Weil&nbsp; в честь Beatles: циферблат
              украшают названия всех 13 альбомов группы.
            </span>,
            <span>
              Сюрреалистичный образ времени знаменитой картины Сальвадора Дали в
              цифровую эпоху становится реальностью. И не случайно мастера
              люксового бренда Jaeger-LeCoultre разместили на оборотной стороне
              своей главной модели часов репродукцию картины «Вероломство
              образов» не менее знаменитого сюрреалиста Рене Магритта.
            </span>
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>ВШС: Наталья Горских: Мода на время</title>
        <meta name="keywords" content="ВШС: Наталья  Горских: Мода на время" />
      </Helmet>
      <Top />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ maxWidth: 1000, margin: "0 auto", padding: 20 }}
        spacing={2}
      >
        <Grid item xs={12} md={12}>
          <Head
            head="Мода на время"
            author="Наталья Горских"
            linkAuthor="/natalya_gorskyh"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingTop: 20 }}>
          <img
            src="/img/blog/vremya/coverage.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paragraph
            j
            content={[
              "Как бы ни менялось время, у человека остается несомненной ценностью именно оно. Сколько написано стихов и философских трактатов на тему времени, сколько песен спето, сколько фильмов снято, сколько слов друг другу сказано… Однако, тема оказалась «вечной»: она соединяет в настоящем прошлое и неостановимый ход будущего. Мгновениями оно, правда, останавливается: одна из самых непостоянных и переменных – мода фиксирует время и предлагает увидеть его ход… На мировых выставках часового искусства и технологий. Например, BaselWorld 2016."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "В этом году креативные технологии в часах словно пытались спорить с временем и судьбой: впервые за много лет в официальных данных, а не только в неофициальных разговорах, появился не рост продаж, не доходы «около ноля», а их настоящее снижение. Однако, именно мудрость понятия «время» как «все проходит и все переживем» позволила мастерам часовых дел дерзать, экспериментировать и фантазировать. На тему счастливого будущего, конечно."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              "Базель демонстрирует как внутренние тренды собственно моды, так и тенденции-пути развития цивилизации. Первая тенденция – удивлять и поражать, желательно простыми средствами. Не концептом будущего, а вполне готовыми к продаже моделями, но необычными, знакомыми и незнакомыми одновременно."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 20 }}>
          <img
            src="/img/blog/vremya/baselworld_kfkf.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>
      <div style={{ background: "rgb(0,0,0)", padding: 20 }}>
        <Grid
          container
          alignItems="flex-start"
          spacing={2}
          style={{ maxWidth: 1000, margin: "0 auto" }}
        >
          <Grid item xs={12} md={4}>
            <img
              src="/img/blog/vremya/clock1.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paragraph
              j
              color="rgb(255,255,255)"
              content={[
                "С этой целью активно используются кварцевые механизмы, такую же роль выполняют яркие материалы, как у бренда MB&F, пригласившего к сотрудничеству химика Джейсона Томпсона для создания фосфоресцирующего эффекта. Желанием привлечь внимание потребителя чем-то необычным продиктовано и решение Longines украсить циферблат известной своей строгостью, почти «иконы классики» модели La Grande Classique розовыми и голубыми перламутровыми цветами. Это же решение поддерживает бренд Golden Bridge, поместивший традиционный и узнаваемый одномостный механизм Corum в круглый корпус. Одна из самых известных марок с «аристократической родословной» Jaquet Droz осталась верна своей традиционной высоте и не поддалась искушению «ярких» изменений, однако, поменяла местами «большую секунду» и основной циферблат в фирменной «восьмерке»."
              ]}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        style={{ maxWidth: 1000, margin: "0 auto", padding: 20 }}
      >
        <Grid item xs={12} md={4}>
          <Paragraph
            j
            content={[
              <p
                style={{
                  color: "rgb(255,255,255)",
                  background: "rgb(50,50,50)",
                  padding: 8
                }}
              >
                А можно обойтись вообще без стрелок и без механизма, только
                шариком, катающимся по циферблату.
              </p>,
              "И, наконец, можно сделать уже по определению непростую концепцию турбийона еще более необычной. Поразить панорамностью, ювелирным оформлением или высокими технологиями в сочетании с довольно невысокой ценой."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paragraph
            j
            content={[
              "Вторая тенденция – воздействие не на эмоции, воображение, а на разум, на стремление к ясности и точности. Производители часов, к примеру, бренд Omega, делающие ставку на «практичность», «удобство в использовании», «хорошая цена» акцентируют внимание на разработке собственных мануфактурных механизмов, улучшении механики, противоударных харатеристик, точности и пр. Описание свойств и качеств этих механизмов сродни инструкциям сложных промышленных приборов: зачастую непонятные слуху рядового покупателя технические термины, химические названия и сокращения. Как можно больше. Что, кстати, не отменяет стилистического подхода: сегодня, чтобы показать магию микромеханики, недостаточно просто скелетонировать механизм. Необходимо четко объяснить стилистические коды мануфактуры, ее видение часового искусства, как это сделал бренд Girard-Perreqaux."
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ background: "url(/img/blog/vremya/pattern.jpg) repeat" }}>
        <Grid
          container
          alignItems="flex-start"
          style={{ maxWidth: 1000, margin: "0 auto", padding: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <Paragraph
              j
              content={[
                <img
                  src="/img/blog/vremya/clock2.jpg"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paragraph
              j
              content={[
                "И производители добиваются иного, связанного с продажами, существенного эффекта: новые технологии используется для оптимизации и тем самым удешевления производства. Впервые самыми громкими сенсациям выставки в Базеле стали модели не самые дорогие, а самые дешевые в своем классе."
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paragraph
              j
              content={[
                "А дальше журналисты и PR-специалисты в области моды начинают заниматься своим обычным делом – продвижением продукта через определение модных тенденций. Вполне закономерно, что все модные тренды будут связаны как с первой (образной, собственно дизайнерской), так и со второй – инженерно-технологической."
              ]}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto", padding: 20 }}
      >
        <Grid item xs={12} md={10}>
          {content.map((el, i) => (
            <Expand
              summary={{
                text: el.summary,
                style: styles.summary
              }}
              details={{
                text: el.details,
                style: styles.details
              }}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
