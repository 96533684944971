import React, { useState } from "react";

const Instagram = function(props) {
  const [hover, setHover] = useState(false);

  const color = props.color || "rgb(160,160,160)";
  const hoverColor = props.hoverColor || "rgb(191,159,37)";

  return (
    <a
      href={props.link || "https://www.instagram.com/styleschool_msk/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width={props.size || "20px"}
        height={props.size || "20px"}
        viewBox="0 0 24 24"
        style={{ marginBottom: -4, ...props.style }}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
      >
        <path
          fill={hover ? hoverColor : color}
          d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2C22,19.4 19.4,22 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8C2,4.6 4.6,2 7.8,2M7.6,4C5.61,4 4,5.61 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4C18.39,20 20,18.39 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5C17.94,5.5 18.5,6.06 18.5,6.75C18.5,7.44 17.94,8 17.25,8C16.56,8 16,7.44 16,6.75C16,6.06 16.56,5.5 17.25,5.5M12,7C14.76,7 17,9.24 17,12C17,14.76 14.76,17 12,17C9.24,17 7,14.76 7,12C7,9.24 9.24,7 12,7M12,9C10.34,9 9,10.34 9,12C9,13.66 10.34,15 12,15C13.66,15 15,13.66 15,12C15,10.34 13.66,9 12,9Z"
        />
      </svg>
    </a>
  );
};

export default Instagram;
