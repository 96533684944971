import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import { Slides } from "../about/photosessions-list";
import Page from "../../components/page";
import {
  HeadBlock,
  Teachers,
  // Programma,
  // ShessBlock,
  // HowIsTheTrainingGoing,
  BuilderLayout,
  TitleH2,
  TextAndTags
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>{c.head.title}</title>
        <meta name="keywords" content={c.meta.keywords} />
        <meta name="descript" content={c.meta.descript} />
      </Helmet>
      <Page lable="Портфолио">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <TextAndTags content={{ tags: false, text: c.text }} />,
            <>
              <TitleH2 content="Фотосессия" />
              <Slides
                images={c.slides}
                infinity
                white
                descript={c.descriptSlides}
              />
            </>,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teacherHead }}
            />,
            <>
              <TitleH2 content={c.stylistsOnPractice.head} />
              <p>{c.stylistsOnPractice.stylists[0]}</p>
              <p>{c.stylistsOnPractice.stylists[1]}</p>
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
