import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Ig from "../../components/instagram";
import List from "../../components/list";
import { Slides } from "../about/photosessions-list";
import {
  BuilderLayout,
  TextAndTags,
  TitleH1,
  TitleH2
} from "../../patterns/pattern-fs/components";
import Paragraph from "../../components/p-array";

export default function Photosession(props) {
  return (
    <>
      <Helmet>
        <title>Forward to the future, куратор Марина Подлесных</title>
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Фотосессия">
        <BuilderLayout
          contents={[
            <>
              <TitleH2 content="Фотосессия стилистов-выпускников" />
              <TitleH1 content="Forward to the future, куратор Марина Подлесных" />
            </>,
            <>
              <TextAndTags
                content={{
                  text: (
                    <>
                      <Paragraph
                        content={[
                          <>Марина Подлесных:</>,
                          <>
                            «
                            <i>
                              Перед девушками стояла задача подобрать для себя
                              образы в соответствии со своим позиционированием
                              себя как стилиста.
                            </i>
                          </>,
                          <>
                            <i>
                              Выпускницы ответственно подошли к решению
                              поставленной задачи. Подготовили референсы,
                              собрали образы, продумали позы. На съёмочной
                              площадке были вовремя, помогали друг другу и
                              поддерживали. Будущие стилисты ищут свое
                              направление в профессии. Фотосессия помогла им
                              лучше понять себя и свои желания. Кому-то
                              требовалось преодолеть свою скованность, другие же
                              были свободны и естесственны. Сказывался опыт
                              работы на камеру.
                            </i>
                          </>,
                          <>
                            <i>
                              Все образы получились очень разными.
                              Экстравагантные, в меру смелые, актуальные. Яркие,
                              дерзкие, динамичные. Индивидуальные, сексуальные и
                              транслирующие молодость. Главной наградой для
                              группы стали эмоции радости, приятная усталость
                              после проделанной работы и качественные снимки!
                            </i>
                            »
                          </>
                        ]}
                        classElement="p-constructor"
                      />
                    </>
                  ),
                  tages: false
                }}
              />
            </>,
            <Slides
              images={[
                {
                  i: "/img/backstage/shilina/slide-1.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/shilina/slide-2.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                }
              ]}
              infinity
              white
              descript={
                <List
                  arr={[
                    <>
                      Организатор: <Ig a="styleschool_msk" />
                    </>,
                    <>
                      Куратор практики / преподаватель: Подлесных Марина{" "}
                      <Ig a="marina_lefay" />
                    </>,
                    <>
                      Photo: Евгений Стулов
                      <Ig a="solaris_foto" />
                    </>,
                    <>
                      Stylist & Model: Шилина Анастасия{" "}
                      <Ig a="stasya.shilina" />
                    </>
                  ]}
                  num="none"
                  olStyle={{ paddingLeft: 0, marginLeft: 0 }}
                />
              }
            />,
            <Slides
              images={[
                {
                  i: "/img/backstage/sotnikova/slide-1.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/sotnikova/slide-2.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/sotnikova/slide-3.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/sotnikova/slide-4.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/sotnikova/slide-5.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                }
              ]}
              infinity
              white
              descript={
                <List
                  arr={[
                    <>
                      Организатор: <Ig a="styleschool_msk" />
                    </>,
                    <>
                      Куратор практики / преподаватель: Подлесных Марина{" "}
                      <Ig a="marina_lefay" />
                    </>,
                    <>
                      Photo: Евгений Стулов
                      <Ig a="solaris_foto" />
                    </>,
                    <>
                      Stylist & Model: Сотникова Алина <Ig a="alinaas.diary" />
                    </>
                  ]}
                  num="none"
                  olStyle={{ paddingLeft: 0, marginLeft: 0 }}
                />
              }
            />,
            <Slides
              images={[
                {
                  i: "/img/backstage/yakupova/slide-1.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/yakupova/slide-2.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/yakupova/slide-3.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/yakupova/slide-4.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                }
              ]}
              infinity
              white
              descript={
                <List
                  arr={[
                    <>
                      Организатор: <Ig a="styleschool_msk" />
                    </>,
                    <>
                      Куратор практики / преподаватель: Подлесных Марина{" "}
                      <Ig a="marina_lefay" />
                    </>,
                    <>
                      Photo: Евгений Стулов
                      <Ig a="solaris_foto" />
                    </>,
                    <>
                      Stylist & Model: Якупова Евгения{" "}
                      <Ig a="yakupova__evgenia" />
                    </>
                  ]}
                  num="none"
                  olStyle={{ paddingLeft: 0, marginLeft: 0 }}
                />
              }
            />,
            <Slides
              images={[
                {
                  i: "/img/backstage/zinenko/slide-1.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/zinenko/slide-2.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/zinenko/slide-3.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/zinenko/slide-4.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                },
                {
                  i: "/img/backstage/zinenko/slide-4.jpg",
                  o: 1,
                  alt: "Фотосессия стилистов-выпускников"
                }
              ]}
              infinity
              white
              descript={
                <List
                  arr={[
                    <>
                      Организатор: <Ig a="styleschool_msk" />
                    </>,
                    <>
                      Куратор практики / преподаватель: Подлесных Марина{" "}
                      <Ig a="marina_lefay" />
                    </>,
                    <>
                      Photo: Евгений Стулов
                      <Ig a="solaris_foto" />
                    </>,
                    <>
                      Stylist & Model: Зиненко Алёна <Ig a="aliona_mladi" />
                    </>
                  ]}
                  num="none"
                  olStyle={{ paddingLeft: 0, marginLeft: 0 }}
                />
              }
            />
          ]}
        />
      </Page>
    </>
  );
}
