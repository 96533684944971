import React from "react";
import "./style.css";

export function OneTile(props) {
  const el = props.content;
  return (
    <div className="tile-item">
      <div className="tile-image">
        <img src={el.image} alt={el.title} />
      </div>
      <h3>{el.title}</h3>
      <p>{el.text}</p>
      <a href={`#${el.link}`}>
        <div className="tile-button">{el.linkText || "подробнее"}</div>
      </a>
    </div>
  );
}

export function Tile(props) {
  const c = props.content;

  return (
    <div className="tile-container">
      {c.map(el => (
        <OneTile content={el} />
      ))}
    </div>
  );
}

export function OtherTile(props) {
  return <div className="tile-container">{props.content}</div>;
}
