import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const grayBkg = "rgb(220,220,220)";

const wStyles = theme => ({
  leftText: {
    background: `linear-gradient(to right, rgb(255,255,255) 0%,\
      rgb(255,255,255) 80%, ${grayBkg} 80%, ${grayBkg} 100%)`,
    padding: "1rem"
  },
  rightText: {
    background: `linear-gradient(to right, ${grayBkg} 0%, ${grayBkg} 20%,\
      rgb(255,255,255) 20%, rgb(255,255,255) 100%)`,
    padding: "1rem"
  },
  leftTextGlo: {
    background: `linear-gradient(to right, rgb(255,255,255) 0%,\
      rgb(255,255,255) 40%, ${grayBkg} 40%, ${grayBkg} 100%)`,
    padding: "1rem"
  },
  rightTextGlo: {
    background: `linear-gradient(to right, ${grayBkg} 0%, ${grayBkg} 60%,\
      rgb(255,255,255) 60%, rgb(255,255,255) 100%)`,
    padding: "1rem"
  },
  white: {
    background: "rgb(255,255,255)",
    padding: "1rem"
  },
  mediaBlock: {
    background: grayBkg,
    padding: "1rem",
    textAlign: "center"
  },
  mediaBlockWhite: {
    background: "rgb(255,255,255)",
    padding: "1rem",
    textAlign: "center"
  },
  "@media (max-width: 959px)": {
    leftText: {
      background: "rgb(255,255,255)"
    },
    rightText: {
      background: "rgb(255,255,255)"
    },
    leftTextGlo: {
      background: "rgb(255,255,255)"
    },
    rightTextGlo: {
      background: "rgb(255,255,255)"
    },
    white: {
      paddingTop: "3rem"
    }
  }
});

function TwoBlocks(props) {
  const { classes } = props;
  const classesMedia = props.allWhite
    ? classes.mediaBlockWhite
    : classes.mediaBlock;
  const backgroundGrid = props.allWhite
    ? classes.white
    : props.textOnLeft
      ? classes.leftTextGlo
      : classes.rightTextGlo;

  return (
    <div>
      <Grid
        container
        justify="center"
        alignItems={props.vertical || "stretch"}
        className={backgroundGrid}
      >
        <Hidden mdUp>
          <React.Fragment>
            {props.mobileMediaUp ? (
              <>
                {props.mobileNotRight ? null : (
                  <Grid item xs={12} sm={12} className={classesMedia}>
                    {props.textOnLeft ? props.right : props.left}
                  </Grid>
                )}
                {props.mobileNotLeft ? null : (
                  <Grid item xs={12} sm={12} className={classes.white}>
                    {props.textOnLeft ? props.left : props.right}
                  </Grid>
                )}
              </>
            ) : (
              <>
                {props.mobileNotLeft ? null : (
                  <Grid item xs={12} sm={12} className={classes.white}>
                    {props.textOnLeft ? props.left : props.right}
                  </Grid>
                )}
                {props.mobileNotRight ? null : (
                  <Grid item xs={12} sm={12} className={classesMedia}>
                    {props.textOnLeft ? props.right : props.left}
                  </Grid>
                )}
              </>
            )}
          </React.Fragment>
        </Hidden>
        <Hidden smDown>
          <React.Fragment>
            <Grid
              item
              xs={12}
              md={props.sizeLeft || 6}
              className={
                props.textOnLeft
                  ? props.white
                    ? classes.white
                    : ""
                  : classesMedia
              }
            >
              {props.left}
            </Grid>
            <Grid
              item
              xs={12}
              md={props.sizeRight || 6}
              className={
                props.textOnLeft
                  ? classesMedia
                  : props.white
                    ? classes.white
                    : ""
              }
            >
              {props.right}
            </Grid>
          </React.Fragment>
        </Hidden>
      </Grid>
    </div>
  );
}

export default withStyles(wStyles)(TwoBlocks);
