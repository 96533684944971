import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";

export default function Contents() {
  const content = [
    {
      title: "Тело как улика. Конституциональный анализ внешности.",
      text: (
        <Paragraph
          content={[
            "Тело – всегда самый надежный информатор. Оно может помочь нам сообщить то, что мы хотели бы передать, но не решились сказать. А может предательски выдать все наши тщательно скрываемые секреты. Разоблачаем тело."
          ]}
        />
      )
    },
    {
      title:
        "Диагностика человека по внешнему виду. Одежда как система знаков.",
      text: (
        <Paragraph
          content={[
            "Иногда гардероб может рассказать о человеке больше, чем самое подробное досье."
          ]}
        />
      )
    },
    {
      title:
        "Структура имиджа. Стильный путь от конфликта к гармонии. Эволюция имиджа.",
      text: (
        <List
          arr={[
            "Составляющие имиджа.",
            "Как и почему мы меняемся. Внутренний мир диктует внешние изменения или наоборот?",
            "Где кроется конфликт между «реальным Я» и «идеальным Я»?"
          ]}
        />
      )
    },
    {
      title: "Аксессуары на языке эмоций и символов.",
      text: (
        <Paragraph
          content={[
            "Рассмотрим аксессуары как знак взаимоотношений между чувственным миром человека и социумом.  Выясним,  как связаны телесно-ориентированная терапия и наш выбор украшений. Вспомним их культурно-историческую роль и на примере актуальных трендов прочитаем современный язык  аксессуарных символов."
          ]}
        />
      )
    },
    {
      title: "Психология цвета.",
      text: (
        <List
          arr={[
            "Цветовые шкалы и психологическое воздействие.",
            "Манипуляция цветом. Проанализируем результаты исследований и рассмотрим, как выставляются цветовые ловушки."
          ]}
        />
      )
    },
    {
      title:
        "Сексуальность в образе и тенденции сексуального поведения в жизни.",
      text: (
        <List
          arr={[
            "Почему привлекательность тесно связана с сексуальным здоровьем и что такое «сексуальное здоровье»?",
            "Где граница между соблазнением и вульгарностью?",
            "Стиль «унисекс». ЗА и ПРОТИВ.",
            "Инь и Ян. Модные тенденции или внутренняя гармония. Законы совмещения и комбинаторики."
          ]}
        />
      )
    },
    {
      title:
        "Возраст и мода. Как связаны выбор одежды и наши социальные роли. Территория мужчин и женщин.",
      text: false
    },
    {
      title:
        "Стиль как часть психодиагностики. Кто скрывается за стилевыми предпочтениями и как не потерять себя в погоне за модой. Заложники стереотипов.",
      text: false
    },
    {
      title: "Отработка в группе практических техник психодиагностики. ",
      text: false
    }
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2
        style={{
          fontSize: "1.6em",
          textAlign: "center",
          color: "rgb(100,100,100)"
        }}
      >
        Содержание курса
      </h2>
      {content.map((el, k) => (
        <div>
          <p
            style={{
              fontSize: "1em",
              fontWeight: 700,
              textAlign: "left",
              color: "rgb(100,100,100)"
            }}
          >
            {k + 1}. {el.title}
          </p>
          {el.text ? el.text : null}
        </div>
      ))}
    </div>
  );
}
