import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import "./style.css";

function TopDigitalDesktop(props) {
  return (
    <>
      <Grid container alignItems="center" spacing="0">
        <Grid item md={5}>
          <div
            className={props.dg === 0 ? ".first-last-line" : "middle-line"}
          />
        </Grid>
        <Grid item md={2}>
          <div className="digit-in-circle">{props.dg + 1}</div>
        </Grid>
        <Grid item md={5}>
          <div
            className={props.dg === 3 ? ".first-last-line" : "middle-line"}
          />
        </Grid>
      </Grid>
    </>
  );
}

function TopDigitalMobile(props) {
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        <Grid item xs={12}>
          <div className="digit-in-circle">{props.dg + 1}</div>
          <div
            className="mobile-line"
            style={{ width: props.dg === 3 ? 0 : 1 }}
          />
        </Grid>
      </Grid>
    </>
  );
}

function Sequence({ content }) {
  return (
    <>
      <Grid container alignItems="stretch" justify="space-between">
        {content.map((el, key) => (
          <>
            <Grid item xs={12} md={3}>
              <Grid container alignItems="stretch">
                <Hidden smDown>
                  <Grid item xs={2} md={12}>
                    <TopDigitalDesktop dg={+key} />
                  </Grid>
                  <Grid item md={12}>
                    <h3 className="h3-sequence">{el.title}</h3>
                    <div className="text-sequence">{el.text}</div>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item xs={2} style={{ maxHeight: "100%" }}>
                    <TopDigitalMobile dg={+key} />
                  </Grid>
                  <Grid item xs={10}>
                    <h3 className="h3-sequence">{el.title}</h3>
                    <div className="text-sequence">{el.text}</div>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}

export default Sequence;
