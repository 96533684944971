import React from "react";
// import { Grid } from "@material-ui/core";
import "./style.css";

export function Text(props) {
  return <div className="text">{props.text}</div>;
}

export function Image(props) {
  return (
    <div className="image" style={props.blockStyle}>
      <img src={props.image} alt={props.imageAlt} style={props.imageStyle} />
    </div>
  );
}

export function ImageText(props) {
  const { content } = props;
  const image = (
    <Image
      image={content.image.src}
      imageAlt={content.image.alt}
      imageStyle={content.image.style}
      blockStyle={content.image.block}
    />
  );
  return (
    <div
      className={props.classBox ? `t-i-cnt ${props.classBox}` : "t-i-cnt"}
      style={props.styleBox}
    >
      {content.link ? (
        <a href={content.link} target="_blank">
          {image}
        </a>
      ) : (
        image
      )}
      <Text text={content.text} />
    </div>
  );
}
