import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { stylesDark, styles } from "../imagemaker-stylist/styles";
import { withStyles } from "@material-ui/styles";
import { contents } from "./content";
import {
  BuilderLayout,
  TitleH2,
  CardImage,
  CardImageText,
  ShessBlock,
  SocnetBlock,
  ShessArray,
  ButtonsWaAndTg,
  MessageOnPaper,
  Cards3,
  ScrollingLink,
  Message
} from "../../patterns/pattern-fs/components";
import { MiniForm } from "../../components/forms";

const wStyles = theme => ({
  ...stylesDark,
  ...styles
});

function Top(props) {
  return (
    <div>
      <>
        <Helmet>
          <title>Профессия: интерьерный стилист, декоратор</title>
          <meta name="keywords" content="" />
          <meta name="descript" content="" />
        </Helmet>
        <Page lable="Дизайн интерьера">
          <BuilderLayout
            contents={[
              <ShessBlock
                content={{
                  left: contents.head.left,
                  right: contents.head.right
                }}
              />,
              <>
                <TitleH2
                  content="Профессиональное образование"
                  scroll="programma"
                />
                <ShessBlock
                  content={{
                    left: (
                      <CardImage
                        content={contents.cardInteriorYourLife.image}
                      />
                    ),
                    right: (
                      <CardImageText
                        content={contents.cardInteriorYourLife.text}
                      />
                    )
                  }}
                  mediaLeft={true}
                  mobileMediaUp={true}
                />
              </>,
              <ShessBlock
                content={{
                  right: (
                    <CardImage
                      content={contents.cardLivingInteriorDesign.image}
                    />
                  ),
                  left: (
                    <CardImageText
                      content={contents.cardLivingInteriorDesign.text}
                    />
                  )
                }}
                mediaLeft={false}
                mobileMediaUp={true}
              />,
              <ShessBlock
                content={{
                  left: <CardImage content={contents.cardTestDrive.image} />,
                  right: <CardImageText content={contents.cardTestDrive.text} />
                }}
                mediaLeft={true}
                mobileMediaUp={true}
              />,
              <>
                <TitleH2 content="Личный рост" scroll="forself" />
                <ShessBlock
                  content={{
                    left: (
                      <CardImage content={contents.cardInteriorDesign.image} />
                    ),
                    right: (
                      <CardImageText
                        content={contents.cardInteriorDesign.text}
                      />
                    )
                  }}
                  mediaLeft={true}
                  mobileMediaUp={true}
                />
              </>,
              <>
                <p className="p-constructor text-center p-b">
                  Новости об открытых мероприятий публикуем в наших группах
                </p>
                <ButtonsWaAndTg
                  wa="https://chat.whatsapp.com/DEuV6RqRKOA7J7lQcGCy5o"
                  tg="tg://join?invite=IHm0-xQ3XczI43YpD0ZbLg"
                />
              </>,
              <>
                <TitleH2
                  content="Записаться на пробные занятия, получить программы курсов или квалифицированную консультацию"
                  scroll="register"
                />
                <MiniForm formName="miniforma" />
              </>,
              <MessageOnPaper content={contents.garanty} />,
              // <SocnetBlock head={contents.headMiddleForm} />,

              // <MiniForm formName="have-questions" />,
              <>
                <ScrollingLink scrollLink="about" />
                <ShessBlock
                  content={{
                    title: contents.about.head,
                    left: contents.about.text,
                    right: contents.about.image
                  }}
                />
              </>,
              contents.education,
              <>
                <TitleH2 content="Вам будет интересно и полезно учиться в ВШСДТ, если Вы:" />
                <ShessArray content={contents.digitPuntcs} />
              </>,
              <>
                <TitleH2 content="Форматы обучения. Стоимость" scroll="price" />
                <Cards3 content={contents.formats} />
              </>,
              <SocnetBlock />
            ]}
          />
        </Page>
      </>
    </div>
  );
}

export default withStyles(wStyles)(Top);
