import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Header from "./header";
import { TileNotSpoler } from "../../components/tile-and-spoler/tiles-not-spoler";
import Page from "../../components/page";

export default function About(params) {
  return (
    <>
      <Helmet>
        <title>Высшая школа стилистики, дизайна и технологий</title>
        <meta
          name="keywords"
          content="вшсдт, высшая, школа, стилистика, стиль, преподаватели, отзывы, прайс лист, студенты, выпускники, новости, мероприятия, информация"
        />
        <meta
          name="descript"
          content="Высшая школа стилистики, дизайна и технологий — школа, в которой учащиеся получают необходимые знания и навыки для реализации своих возможностей в бизнесе, социальной деятельности, для воплощения своей карьеры и проектов. В Высшей школе стилистики, дизайна и технологий соединяются творчество и разум, искусство и современность, профессиональное обучение и практические мастер-классы для всех желающих. Образование можно получить как очно, так и на дистанционной платформе. Студенты проходят практику в течении профессиональной программы, и получают возможность стажироваться в лучших fashion агенствах. Обучаясь в ВШСДТ, занимаясь творчеством и черпая творческие идеи, студенты приобщаются к искусству и к красоте, к вдохновению и осваивают новые возможности в индустрии моды."
        />
      </Helmet>
      <Page lable="О школе" lableMobile="О школе">
        <Header />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: 0 }}>
            <TileNotSpoler
              short={{
                image: "/img/about/teachers.jpg",
                text: "Наши преподаватели",
                link: "/teachers"
              }}
              sideImage="right"
            />
            <TileNotSpoler
              sideImage="left"
              short={{
                image: "/img/about/backstage-1.jpg",
                text: "Бэкстейджи / Фотосессии",
                link: "/backstages"
              }}
            />
            <TileNotSpoler
              sideImage="right"
              short={{
                image: "/img/about/graduates.jpg",
                text: "Выпускники",
                link: "/graduates"
              }}
            />
            <TileNotSpoler
              sideImage="left"
              short={{
                image: "/img/about/review.jpg",
                text: "Отзывы",
                link: "/reviews"
              }}
            />
            <TileNotSpoler
              sideImage="right"
              short={{
                image: "/img/about/publics.jpg",
                text: "Рецензии. Литература. Публикации",
                link: "/publics"
              }}
            />
            <div style={{ height: "2rem" }} />
          </Grid>
        </Grid>
      </Page>
    </>
  );
}
