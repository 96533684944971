import React from "react";

import List from "../../components/list";
import Expand from "../../components/expanded";

export default function Contents() {
  const content = [
    {
      summary:
        "I Искусство 2-й половины XX века: концептуализм и иные художественные практики",
      details: (
        <List
          arr={[
            "Понятие «современное искусство». Разрыв с классикой.",
            "Эволюция модели визуальности: от зрения к телу.",
            "Эволюция пространственных форм: от плоскости – к объему и трехмерности.",
            "Принципы работы с пространством и зрителем. Перформативные практики: акционизм, перформанс, хеппиниг, энвайронмент, видео-арт.",
            <React.Fragment>
              Знаковая природа современного искусства. Код постиндустриальной
              эпохи
              <List
                arr={[
                  "от формы – к концепту, от образа – к знаку",
                  "от презентации – к выставленности",
                  "что скрывает контекст и кто автор",
                  "онтологические вопросы и художественные провокации",
                  "интеллектуальная игра и вопросы без ответов",
                  "коммуникация как ценность, «спектакулярность»"
                ]}
              />
            </React.Fragment>
          ]}
          num="decimal"
          style={{ fontSize: "1.2em" }}
        />
      )
    },
    {
      summary:
        "II Знаковая природа костюма: исследование тела и костюма, костюма и социокультурной среды, моды и личности",
      details: (
        <List
          arr={[
            <React.Fragment>
              Суть концептуальной моды: о чем, как и для кого
              <List
                arr={[
                  "источники концептуального подхода к костюму",
                  "идеи, а не вещи",
                  "костюм – текст и контекст",
                  "«креативный индивидуализм», коммерческий и некоммерческий посыл",
                  "идея движущейся формы и инновационные технологии: материалы и показы",
                  "концептуальный дизайн сфер от-кутюр и прет-а-порте",
                  "костюмный энвайронмент, wearable art"
                ]}
              />
            </React.Fragment>,
            "Деконструктивизм как одна из форм проектирования концептуального костюма. Суть метода в искусстве и дизайне.",
            "Способы презентации коллекций на Неделях моды. Перформанс, его виды.",
            <React.Fragment>
              Мировые школы концептуального дизайна:
              <List
                arr={[
                  "бельгийская школа",
                  "датские и нидерландские бренды концептуального дизайна",
                  "японский концептуализм и деконструктивизм"
                ]}
              />
            </React.Fragment>,
            <React.Fragment>
              Создатель нового «языка» моды – Мартин Маржела.
              <List
                arr={[
                  "практичное искусство, одежда об одежде",
                  "аноним и яркая личность, авангардист и ремесленник",
                  "«коды» и концепты, определившие современную моду",
                  "знаковые стилистические решения и последователи в «упрощенной» форме"
                ]}
              />
            </React.Fragment>,
            "Итоговая творческая работа: анализ самостоятельно выбранной коллекции"
          ]}
          num="decimal"
          style={{ fontSize: "1.2em" }}
        />
      )
    }
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2 style={{ fontFamily: "Roboto", fontSize: "2em", textAlign: "left" }}>
        Содержание курса
      </h2>
      <div style={{ padding: 16 }}>
        {content.map((el, i) => (
          <Expand
            summary={{
              text: el.summary,
              style: {
                color: "rgb(100,100,100)",
                fontWeight: 600,
                background: "rgb(245,245,245)"
              }
            }}
            details={{
              text: el.details,
              style: {
                color: "rgb(100,100,100)",
                fontWeight: 400
              }
            }}
            key={i}
            expand={true}
          />
        ))}
      </div>
    </div>
  );
}
