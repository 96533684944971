import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Акимова Лидия Владимировна",
        family: "Акимова",
        metaKeywords: "",
        metaDescript: "",
        head: "Акимова Лидия Владимировна",
        image: "/img/about/akimova.jpg",
        text: {
          edu: "Закончила ВШСДТ в 2019 году",
          exp: [
            "С 2010 г. по настоящее время практикующий Имидж-стилист.",
            "С 2020 г. по настоящие время преподаватель практики ВШСДТ.",
            "Сотрудничество с KIABI; Мега Белая дача; TopTop.ru."
          ],
          motto: "Мода для реальной жизни!"
        }
      }}
    />
  );
}
