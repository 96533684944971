import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { TilesBuilder } from "./reviews";
import { facebook, other } from "./content";
import { BuilderLayout, TitleH1 } from "../../patterns/pattern-fs/components";

export default function Backstages(props) {
  return (
    <>
      <Helmet>
        <title>Отзывы студентов и выпускников</title>
        <meta name="keywords" content="ВШСДТ: отзывы студентов и выпускников" />
      </Helmet>
      <Page lable="Отзывы">
        <BuilderLayout
          contents={[
            <TitleH1
              content={"Отзывы студентов и выпускников"}
              style={{ textAlign: "center" }}
            />,
            <TilesBuilder content={facebook} />,
            <TilesBuilder content={other} />,
            <></>
          ]}
        />
      </Page>
    </>
  );
}
