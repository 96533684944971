import React from "react";
import Description from "../../components/descript4courses";

function Descript(props) {
  return (
    <Description
      block1={props.block1}
      block2={props.block2}
      slider
      sliderRK
      slides={[
        "/img/tbilisi-fashion-weekend/slide01.jpg",
        "/img/tbilisi-fashion-weekend/slide02.jpg",
        "/img/tbilisi-fashion-weekend/slide03.jpg",
        "/img/tbilisi-fashion-weekend/slide04.jpg",
        "/img/tbilisi-fashion-weekend/slide05.jpg",
        "/img/tbilisi-fashion-weekend/slide06.jpg",
        "/img/tbilisi-fashion-weekend/slide07.jpg",
        "/img/tbilisi-fashion-weekend/slide08.jpg",
        "/img/tbilisi-fashion-weekend/slide09.jpg",
        "/img/tbilisi-fashion-weekend/slide10.jpg",
        "/img/tbilisi-fashion-weekend/slide11.jpg"
      ]}
      styleImageBox={{
        margin: "0 auto"
      }}
    />
  );
}

export default Descript;
