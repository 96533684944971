import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>Курсы имиджмейкеров</title>
        {/* <meta name="descript" content="" />
        <meta name="keywords" content="" /> */}
      </Helmet>
      <PageGlobal lable="Курсы имиджмейкеров">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
