import React from "react";

export default function About(props) {
  return (
    <p
      style={{
        fontFamily: props.font ? props.font : "Futura-Normal",
        fontSize: "1.1em"
      }}
    >
      Эволюция и многообразие стилей в 20 веке, образы десятилетий, переменчивый
      маятник моды, стилизация, копирование и эклектика. Детальный разбор и
      понимание предпосылок появления каждого отдельного стиля, субкультуры или
      направления. Их посыл и воздействие на окружающих, составляющие и
      узнаваемые Fashion-маркеры: одежда, обувь, аксессуары, макияж и прически.
      Современные стили – необходимая база для работы в области стиля, имиджа,
      стилистики и моды, чтобы уметь безошибочно идентифицировать современные
      стилизации и создавать нужные образы для жизни или для съемки. Основы
      стилей и их влияние на моду – постоянный источник творческого вдохновения
      и экспериментов в работе стилистов.
    </p>
  );
}
