import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { PriceNoQR as Price } from "../../components/price-with-action-test/price";
import { MiniForm } from "../../components/forms";
import {
  HeadBlock,
  Teachers,
  Programma,
  Text3Block,
  ShessBlock,
  // HowIsTheTrainingGoing,
  BuilderLayout,
  TitleH2
} from "../../patterns/pattern-fs/components";

export default function BusinessAnalitic(props) {
  return (
    <div>
      <Helmet>
        <title>Обучение бизнес аналитиков с трудоустройством</title>
        <meta
          name="descript"
          content="На курсе бизнес-аналитик вы с &quot;нуля&quot; освоите новую профессию. Вы научитесь основам проектной методологии; современным гибким фреймворкам ведения проектов Scrum, Kanban; оценивать продукт с позиции финансовой выгоды; анализировать востребованность нового продукта или сервиса на рынке; правильно использовать метрики и показатели эффективности проекта; формировать бэклог продукта и работать в Agile команде. Обучение основам профессии с перспективами трудоустройства. Замечательная возможность войти в ИТ сферу и решительно изменить свою жизнь."
        />
        <meta
          name="keywords"
          content="бизнес, аналитика, обучение, учиться, где, профессия, курсы, школа, трудоустройство"
        />
      </Helmet>
      <Page lable="Бизнес аналитик">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                left: <img src={c.screen2.image} />,
                right: c.screen2.text
              }}
              mediaLeft
              mobileMediaUp
              vAlign="center"
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            // <>
            //   <TitleH2 content="Как проходит обучение" />
            //   <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            // </>,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,
            <>
              <ShessBlock
                content={{
                  left: c.rgsu.text,
                  right: c.rgsu.image
                }}
                vAlign="center"
              />
            </>,
            <Teachers
              content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
            />,
            // <>
            //   <TitleH2
            //     content="Стоимость обучения в рассрочку на 10 месяцев"
            //     scroll="price"
            //   />
            //   <Price content={c.price} />
            // </>,
            <>
              <TitleH2 content={c.miniFormHead} scroll="singup" />
              <MiniForm formName="miniforma-idfw" />
            </>
          ]}
        />
      </Page>
    </div>
  );
}
