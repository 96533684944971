import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { styles } from "./style";
import { withStyles } from "@material-ui/styles";
import LinkCreater, { Linker } from "./links";

const wStyles = theme => styles;
const font = { fontFamily: "PT Sans Narrow" };

function Spoler(props) {
  const [open, setOpen] = useState(false);
  const { classes } = props;

  return (
    <div>
      <div>{props.noSpoler}</div>
      {props.isNotSpoler || (
        <>
          <div className={classes.spolerIconBox}>
            <div
              className={
                open ? classes.spolerIconOpen : classes.spolerIconClose
              }
              onClick={() => setOpen(!open)}
            >
              <svg width={24} height={24} viewBox="0 0 24 24">
                <path
                  fill="rgb(100,100,100)"
                  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                />
              </svg>
            </div>
          </div>
          <div className={open ? classes.spoler : classes.spolerClose}>
            {props.spoler}
          </div>
        </>
      )}
    </div>
  );
}

function Tile(props) {
  const { classes } = props;

  return (
    <div style={{ padding: "1rem", height: "100%", boxSizing: "border-box" }}>
      <div className={classes.tile}>
        <Linker link={props.link2 ? props.link2 : false}>
          <h2 className={classes.h2} style={font}>
            {props.head}
          </h2>
        </Linker>
        <Spoler
          noSpoler={
            <>
              <h3 className={classes.h3}>{props.typeEducation}</h3>
              <div className={classes.duration}>{props.duration}</div>
              <p className={classes.p}>
                <b>Специализация</b>
                <br />
                {props.specialisation}
              </p>
              {props.price && (
                <p className={classes.p}>
                  <b>Стоимость:</b> {props.price}
                </p>
              )}
            </>
          }
          spoler={
            <>
              <p className={classes.p}>
                <b>В процессе обучения</b>
                <br />
                {props.process}
              </p>
              {props.diplom ? (
                <p className={classes.p}>
                  <b>Получите:</b> {props.diplom}
                </p>
              ) : null}
              {props.space ? (
                <Hidden smDown>
                  <div style={{ height: props.space }} />
                </Hidden>
              ) : null}
            </>
          }
          isNotSpoler={props.isNotSpoler}
          classes={classes}
        />
        <div style={{ padding: "1rem 0" }}>
          {props.link2 ? (
            <Grid container>
              <Grid item xs={6}>
                <LinkCreater link={props.link} />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "1rem" }}>
                <LinkCreater link={props.link2} />
              </Grid>
            </Grid>
          ) : (
            <LinkCreater link={props.link} />
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(wStyles)(Tile);
