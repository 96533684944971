import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Header from "./header-links";

const styles = {
  head: {
    color: "rgb(123,143,87)",
    textAlign: "left",
    fontSize: "1.6em",
    fontWeight: 700,
    margin: 0,
    padding: "12px 0"
  },
  text: {
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontSize: "1em",
    fontWeight: 400,
    margin: 0,
    padding: 0
  }
};

export default function Minkina() {
  return (
    <div>
      <Helmet>
        <title>Алена Минкина: Публичный профиль</title>
        <meta name="keywords" content="Алена Минкина: Публичный профиль" />
      </Helmet>
      <Top />
      <Grid
        container
        justify="space-around"
        spacing={4}
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/minkina1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head="Алена Минкина"
              instagram="https://instagram.com/alena_minkina"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={11}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} style={{ paddingBottom: 24 }}>
              <h2 style={styles.head}>Предыстория</h2>
              <p style={styles.text}>
                Несколько лет назад, до поступления в Высшую Школу Стилистики, я
                работала мастер-тренером в фитнес-клубе. Я вела групповые и
                индивидуальные занятия с клиентами различных возрастных групп, а
                также проводила консультации по правильному питанию и ЗОЖ. Потом
                я ушла в декретный отпуск и вместе с сестрой стала заниматься
                организацией и проведением свадебных и концертных мероприятий.
              </p>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingBottom: 24 }}>
              <h2 style={styles.head}>Мечта с детства</h2>
              <p style={styles.text}>
                Учиться в области моды и стиля я мечтала еще с детства, но жизнь
                так складывалась, что все силы я отдавала профессиональному
                спорту. На него у меня ушло 15 лет, и в это время идти учиться
                еще на что-то всерьез просто не было сил и возможности.Твердое
                решение получить образование в сфере моды и стиля я приняла уже
                после того, как стала мамой.
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ background: "rgb(213,209,201)" }}
            >
              <p
                style={{
                  fontSize: "1.1em",
                  textAlign: "justify",
                  magrin: "0 auto"
                }}
              >
                Я долго выбирала школу и, почитав отзывы, изучив сайт Высшей
                Школы Стилистики &laquo;от и до&raquo;, остановила свой выбор
                именно на ней. О чем до сих пор ни капли не жалею.
              </p>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={12}>
                <div style={{ height: 42 }} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={7}>
              <h2 style={styles.head}>
                Познание нового &mdash; огромное удовольствие.
              </h2>
              <p style={styles.text}>
                Обучение в Высшей Школе Стилистики &mdash; это, в первую
                очередь, огромное количество новой информации. В самом начале я
                даже немного растерялась от этого, но со временем все детали
                складывались воедино. Может быть, поэтому процесс обучения не
                казался мне чем-то трудным и требующим напряжения. Мне это
                доставляло огромное удовольствие и каждое занятие не было
                обременительным, а наоборот, открывало перспективы, оставляло
                ощущение чего-то нового и невероятно интересного.
              </p>
              <p style={styles.text}>
                Лично на мой взгляд все, чему нас учили в школе, оказалось
                полезным, настолько, что даже сложно выделить что-то отдельное.
                Любая, даже совсем маленькая тема, была очень нужной. И порой,
                прокручивая материал в голове, я позже понимала, что без той
                маленькой темы не сложилась бы сегодняшняя большая картина.
              </p>
              <div style={{ height: 24 }} />
              <div
                style={{
                  background: "rgb(213,209,201)",
                  padding: 12,
                  fontSize: "1.1em"
                }}
              >
                Обучение в ВШС &mdash; это как процесс перезагрузки. Мой мир
                перевернулся на 360 градусов.
              </div>
              <div style={{ height: 24 }} />
              <p style={styles.text}>
                На многие вещи я стала смотреть с другой стороны. Не только на
                моду, но и вообще на мир в целом. Можно сказать, что жизнь
                заиграла новыми красками. И уже в процессе обучения я поняла,
                что профессия имиджмейкер &mdash; это моя жизнь. Сегодня мне уже
                трудно представить, как можно считать свою работу просто
                работой, отделять ее от жизни! Для меня это одно единое целое.
              </p>
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/minkina2.jpg"
                alt="Алена Минкина"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="/img/graduates/minkina3.jpg"
                alt="Алена Минкина"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <h2 style={styles.head}>Мои достижения</h2>
              <p style={styles.text}>
                Я сама разработала дизайн своей квартиры &mdash; это одно из
                моих серьезных достижений. На создание и воплощение этого
                дизайна ушло около двух лет. Создавая его, я руководствовалась
                скорее не пониманием стилей, а своими ощущениями и желаниями
                всей моей семьи. Важно, чтобы пространство было не только
                красивым, но и удобным, учитывающим, что именно хотели бы видеть
                мои близкие люди. Потом, уже учась в Высшей Школе Стилистики я
                поняла, что дизайн я разработала в своем любимом стиле барокко.
                Тогда я еще не имела представления, что это за стиль, но на
                интуитивном уровне ощутила его правила. И получилось именно то,
                о чем я всегда мечтала.
              </p>
              <p style={styles.text}>
                Еще одно мое достижение &mdash; текущий проект. Я продумываю
                ежедневник для каждого клиента. Это будет не просто ежедневник,
                в котором мы привыкли делать записи, а целый справочник с
                полезной информацией, начиная от советов в области стиля и
                заканчивая тем, как правильно питаться.
              </p>
              <div style={{ height: 24 }} />
              <div
                style={{
                  background: "rgb(213,209,201)",
                  padding: 12,
                  fontSize: "1.1em"
                }}
              >
                Профессия имиджмейкер &mdash; это моя жизнь!
              </div>
              <div style={{ height: 24 }} />
              <p style={styles.text}>
                Сегодня быть имиджмейкером &mdash; это смысл моей жизни. Я не
                буду останавливаться на достигнутом и буду идти только вперед,
                подниматься на самую вершину этой профессии. У меня большие
                планы. Например, сейчас я хочу работать над совмещением моды,
                стиля, правильного питания и ЗОЖ &mdash; так, чтобы человек
                менялся не только в одежде, но и в жизненных привычках.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
