import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
// import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Практическое образование",
    head: "Стиль для стилиста",
    headAfterDescript: "Авторская мастераская Светланы Шабалиной",
    descript: [
      "Практикум",
      "Длительность: 20 часов",
      "Дни: по субботам",
      "Формат: очно, онлайн",
      "Стоимость курса: 25000 руб."
    ],
    text: "",
    imageSource: "/img/style4stylist/one-screen.jpg",
    video: "a1T_ln5fDlE",
    styleHead: {
      fontSize: "3rem",
      lineHeight: "2.5rem"
    },
    spaceHeadText: "4rem"
  },
  screen2: {
    dates: [
      {
        d: dates.style4stylist.online.getDate(),
        t: Month(dates.style4stylist.online.getMonth()),
        c: "дистанционно"
      }
      //       {
      //         d: dates.style4stylist.ofline.getDate(),
      //         t: Month(dates.style4stylist.ofline.getMonth()),
      //         c: <span>&ensp;очно</span>
      //       }
    ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>Программа</span>
      )
    },
    text: (
      <List
        arr={[
          <>Формулирование идеи своего образа</>,
          <>Составление своей уникальной фор&shy;мулы стиля</>,
          <>Подготовка персональной книги стиля</>,
          <>Разбор гардероба и составление шо&shy;пинг листа</>,
          <>Шопинг.</>,
          <>Защита проведен&shy;ного шопин&shy;га</>,
          <>Ответы на вопросы</>
        ]}
        num="none"
      />
    ),
    // video: "hy3YcutF0pw"
    image: {
      source: "/img/style4stylist/illustration01.jpg"
    }
  },
  text1:
    "Курс нацелен на практическую проработку своего индивидуального стиля. Его отличие в том, что все выполняемые домашние задания преподаватель не только проверяет и дает обратную связь, но и вносит исправления. Таким образом, разработка собственного стиля осуществляется преподавателем с участием студента.",
  programma: {
    head: "Как проходит обучение",
    content: [
      <List
        num="none"
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>Обучение проходит в мини группах до 6-ти человек</>,
          <>Каждому обеспечен индиви&shy;дуальный подход</>,
          <>Практикум состоит из 6-ти занятий, которые проходят раз в неделю</>,
          <>Длительность каждого занятия от 1,5 до 3 часов</>,
          <>
            На каждом занятии объяс&shy;няется тема и Вы получаете задание,
            которое необ&shy;ходимо выполнить в течение 5-ти дней
          </>,
          <>
            На каждом следующем занятии препо&shy;даватель анали&shy;зирует
            работы с уже внесен&shy;ными правками и дает следу&shy;ющее домашнее
            задание
          </>,
          <>
            В течение практикума будет создана персо&shy;нальная книга стиля,
            разобран гардероб и проведен шопинг
          </>,
          <>
            Такой формат полезен еще тем, что слушая детальный разбор и
            пояснения, Вы получаете взгляд «со стороны» и индиви&shy;дуальные
            рекомендации препода&shy;вателя по созданию Вашего
            профес&shy;сиональ&shy;ного стиля
          </>
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/teachers/320x240/svetlana-kononets.jpg",
      imageFilter: true,
      name: "Светлана Шабалина",
      text:
        "Практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения, преподаватель Высшей школы стилистики.",
      instagram: false,
      buttons: {
        detail: "/svetlana_kononets",
        linkInside: true,
        video: "a1T_ln5fDlE"
      }
    }
  ],
  pay: false
};
