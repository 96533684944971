import React from "react";
import Card from "../card";
import Links from "../links";

export default function CardModern(props) {
  return (
    <Card
      head="Мода и стиль"
      title={
        <span>
          «Бедная» мода: прошедшее будущее (часть&nbsp;
          {props.part})
        </span>
      }
      teacher="Наталья Горских"
      descript="Лекция, online"
      descriptString
      onlineText="Лекция, online"
      date={props.date}
      online
      onlineIcon={props.onlineIcon ? true : false}
      time={props.time}
      duration=""
      image="/img/master-class/poor-fashion.jpg"
      text={
        <div>
          <Links
            link="/future_of_fashion"
            text="Перейти на страницу мероприятия"
          />
          В 60-е годы XX века высокое искусство подверглось испытанию. В
          классических музеях появились произведения, материалом которых стали
          предметы «улицы», самые ничтожные и малые свидетели жизни каждого дня
          человека. Этот процесс изменил как искусство, так и повседневность.
          Такой же процесс — проникновение «улицы», гоп-стиля, нарочито бедных
          тканей и состарившихся фактур, non-fashion, концепта «черного
          квадрата» в костюме и т.п. подверг испытанию и изменил традиционную
          систему моды, провозгласил иные модные стандарты и типы «культурного
          тела» человека постиндустриальной эпохи.
          <br />
          Лекция о том, что кроется за понятием «бедная» мода, временное ли это
          явление или постоянный тренд будущего, что обретает модный образ,
          отказываясь от «игры по правилам», означает ли «бедность» форм
          «бедность» содержания, в чем суть послания одного из самых одиозных
          современных дизайнеров — Maurizio Altieri и почему его послание так
          напоминает послание творцов итальянского искусства arte povera
        </div>
      }
      q
      part
      noView
      partPrice="0"
      free
      color="rose"
    />
  );
}
