import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./content";
import Page from "../../components/page";
import { MiniForm } from "../../components/forms";
import {
  BuilderLayout,
  Programma,
  Teachers,
  HowIsTheTrainingGoing,
  Text3Block,
  ShessBlock,
  TitleH2,
  HeadBlock,
  TextAndTags
} from "../../patterns/pattern-fs/components";
import Banners from "./banners";
import Phones from "../../components/phones-icons";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Интерьерный стилист. Бесплатное обучение</title>
        <meta
          name="keywords"
          content="интерьер, стиль, обучение, школа, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Консультант по имиджу и стилю — курсы с &quot;нуля&quot;, емко и актуально, 100% результата в индивидуальном стиле. Можно учиться в любом возрасте, в любом городе, для себя или для карьеры дипломированного стилиста."
        />
      </Helmet>
      <Page lable="Интерьерный стилист">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.headNew} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content="О проекте" />
              <ShessBlock
                content={{
                  left: c.screen2.image,
                  right: c.screen2.text
                }}
                mobileMediaUp={true}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            <>
              <TitleH2 content="Как проходит обучение" />
              <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            </>,
            <Programma
              content={{ programma: c.formats, variants: false }}
              noScroll
            />,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teachersHead }}
            />,
            <>
              <TitleH2 content="Записаться на обучение" scroll="singup" />
              <TextAndTags
                content={{
                  text: c.textNote,
                  tags: false
                }}
              />
              <div style={{ margin: "3rem auto" }}>
                <Phones
                  name={false}
                  size={20}
                  color="rgb(0,0,100)"
                  link={false} // "https://chat.whatsapp.com/DEuV6RqRKOA7J7lQcGCy5o"
                  linkForTelegram="IHm0-xQ3XczI43YpD0ZbLg"
                />
              </div>
              <MiniForm formName="miniforma-style-your-life" />
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
