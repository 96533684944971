import React from "react";
import Helmet from "react-helmet";
import { BuilderLayout, TitleH2 } from "../../patterns/pattern-fs/components";
import Page from "../../components/page";
import List from "../../components/list";
import "./style.css";

export function Text(props) {
  const c = props.content;
  return (
    <>
      {c.edu && (
        <>
          <p>
            <strong>Образование</strong>
          </p>
          {Array.isArray(c.edu) ? (
            <List
              arr={c.edu}
              num="none"
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              classLi="p-constructor"
            />
          ) : (
            <p>{c.edu}</p>
          )}
        </>
      )}
      {c.exp && (
        <>
          <p>
            <strong>Профессиональный опыт</strong>
          </p>
          <List
            arr={c.exp}
            num="none"
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            classLi="p-constructor"
          />
        </>
      )}
      {c.motto && (
        <>
          <p>
            <strong>Профессиональный девиз</strong>
          </p>
          <p>
            <i>{c.motto}</i>
          </p>
        </>
      )}
    </>
  );
}

export default function Graduate(props) {
  const c = props.content;

  return (
    <div>
      <Helmet>
        <title>{c.title}</title>
        <meta name="keywords" content={c.metaKeywords} />
        <meta name="descript" content={c.metaDescript} />
      </Helmet>
      <Page lable={c.family}>
        <BuilderLayout
          contents={[
            <>
              <TitleH2 content={c.head} />
              <div className="graduates-image">
                <img src={c.image} alt={c.title} />
              </div>
            </>,
            <Text content={c.text} />
          ]}
        />
      </Page>
    </div>
  );
}
