import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Head from "../../components/head-articles";
import Dot from "../../components/dotted-line";
import Paragraph from "../../components/p-array";

export default function Meshok() {
  return (
    <div>
      <Helmet>
        <title>ВШС: Софья Зозуля: Мода в мешке</title>
        <meta name="keywords" content="ВШС: Софья Зозуля: Мода в мешке" />
        <meta
          name="title"
          content="Образы Oversize от XV века до современных тенденций"
        />
        <meta
          name="description"
          content="Образы oversize выглядят так свежо и современно, будто они придуманы молодыми дизайнерами всего несколько лет назад. Но тяга к масштабности - далеко не новая модная мания."
        />
      </Helmet>
      <Top />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid item xs={12} md={12} style={{ padding: 20 }}>
          <Head
            head="Мода в мешке"
            author="Софья Зозуля"
            linkAuthor="/sofjya_zozulya"
            vk="https://www.vk.com/sofia.chesnokova"
            fb="https://www.facebook.com/profile.php?id=100002113906771"
            instagram="https://instagram.com/sofyazozulya"
          />
        </Grid>
        <Grid item xs={12} md={7} style={{ padding: 20 }}>
          <Paragraph
            content={[
              <span>
                Образы oversize выглядят так свежо и современно, что, кажется,
                будто они придуманы молодыми дизайнерами всего несколько лет
                назад… Однако тяга к масштабности &ndash; далеко не новая модная
                мания.
              </span>,
              "Еще в XV веке итальянские модники носили короткие штаны, по-французски «о-де-шосс», а по-английски «транк хоуз»  – «штаны-сундук».  Вы поймете, почему они так назывались, если узнаете, что их объем увеличивали за счет подкладок из тряпок, пакли и даже отрубей. Анекдот XVI века повествует о молодом человеке, «который в пылу оживленной беседы с несколькими дамами зацепил своими штанами за гвоздь, и оттуда посыпались отруби, а штаны внезапно опали, к ужасу их владельца и радости дам». При столь большом объеме штанов – пурпуэн (мужская куртка) носил прилегающий вид, а плащ был коротким. Такая тенденция ничего не напоминает вам?"
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: 20 }}>
          <img
            src="/img/blog/meshok/im2.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: 20 }}>
          <img
            src="/img/blog/meshok/im3.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={7} style={{ padding: 20 }}>
          <Paragraph
            content={[
              "А чего стоит испанская фреза XVI века! Вы всегда узнаете этот выросший из некогда изящного рюша круглый воротник, который был настолько крупным и жестким, что не давал владелице даже наклонить голову.",
              "И, конечно, непременно на ум приходят прически эпохи рококо, выраставшие на метр над головой своей хозяйки, походившие то на садовые клумбы, то на картины морских баталий. Вот где мода сверхразмеров!",
              "Как видите, тенденция не нова, и, по сравнению с XVIII веком во Франции, мода нас с вами щадит, а oversize наших дней выглядит просто детским лепетом по сравнению с платьями на огромных обручах и неестественно объемными фижмами."
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Paragraph
            content={[
              "Но откуда же растут ноги, простите за каламбур, у пальто-не-по-размеру образца 2016 года?",
              "Пожалуй, главная революция в истории женского костюма произошла в 20-е годы ХХ века. Первая мировая война сделала для дам работу насущной необходимостью, и женщина, приняв на себя мужскую роль, избавилась от корсетов, платьев в пол и длинных волос. Это было время восходящей звезды, имя которой вы, безусловно, без труда угадаете. Неповторимая Габриэль Шанель подарила женщинам свободу движения и позволила выглядеть актуально – как даме из высшего общества, так и продавщице цветов – за счет использования недорогих тканей и свободных силуэтов. Мода на мальчишескую фигуру лишила короткие платья талии, изменив еще недавно такой популярный женственный приталенный силуэт до неузнаваемости. Чем вам не оверзайз?"
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <img
            src="/img/blog/meshok/im4.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Dot />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Paragraph
            content={[
              <img
                src="/img/blog/meshok/im5.jpg"
                alt=""
                style={{ maxWidth: "100%" }}
              />,
              <div style={{ background: "rgb(255,250,240)", padding: 8 }}>
                Но решающий удар по традиционной женственности нанесли в 80-е
                годы не европейские кутюрье, а японские самураи от моды: Такада
                Кензо, Еджи Ямамото, Рей Кавакубо и другие. Марка Кавакубо так и
                называется «Comme des Garsons» – «Как мальчики». Отличный от
                европейского угол зрения позволил этим модельерам представить
                миру совершенно новые образы – образы асимметричные, ломаные и,
                конечно, оверсайзные.
              </div>
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Paragraph
            content={[
              "Вернув себе былые позиции в 40-е и дойдя до крайней стадии в 50-е, приталенный силуэт в 60-е сдал свои права: на подиум вновь вышли модели в прямых и расклешенных платьях и пальто. И снова не обошлось без политики: именно эти годы ознаменовались масштабными демонстрациями за права женщин. Дамы желали равной свободы, равного веселья, равной с мужчинами оплаты труда, но, как водится, получили только новые платья. Других фасонов – для разнообразия.",
              "Это был звездный час неподражаемого Кристобаля Баленсиаги. Его sack dress (платье-мешок) и пальто-кокон с покатыми плечами образца 1957-го года произвели настоящий фурор и смотрелись очень ново и свежо, в особенности, рядом с традиционным силуэтом new look Кристиана Диора. Это была новая «победа» женщины над «корсетом»! Тут уж, пожалуй, ни у кого не возникнет сомнений, чьи идеи беззастенчиво эксплуатируют современные дизайнеры."
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <img
            src="/img/blog/meshok/im6.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Paragraph
            content={[
              "Женщины, тем временем, стали занимать все более высокие посты, наступала эра «цветных» моделей, эра хип-хопа с его безразмерными штанами и футболками – эра безраздельной свободы всего и во всем…",
              "Как видите, игры с размерами для человечества давно не в новинку. Чем туже мужчина затягивал пояс на женской талии, тем сильнее женщина желала этот пояс разорвать. И вот, надев кроссовки и пальто кокон, современная девочка-эмансипе бежит с учебы на работу, за детьми, продуктами, следуя плану свободы, который приготовили для нее бабушка и мама."
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <Paragraph
            content={[
              "Oversize – это уже не столько дань моде, сколько вынужденная необходимость, oversize – это стиль жизни.",
              <div style={{ background: "rgb(240,255,250)", padding: 8 }}>
                Дорогие девушки, мы с вами все-таки добились своего, и теперь
                остается только расслабиться (если получится) и получать
                удовольствие. И пускай оно, как и ваше платье-мешок, будет –
                оверсайзным.
              </div>
            ]}
            j
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 20 }}>
          <img
            src="/img/blog/meshok/im7.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
