import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Grid } from "@material-ui/core";
import Page from "../../components/page";
import { content as c } from "./content";
import { MiniForm } from "../../components/forms";
import {
  ScrollingLink,
  BuilderLayout,
  HeadBlock,
  TextAndTags,
  TitleH2,
  ShessBlock,
  Programma,
  Text3Block
} from "../../patterns/pattern-fs/components";
// import { PriceNoQR as Price } from "../../components/price-with-action-test/price";

function ChatBot(props) {
  return (
    <>
      <Helmet>
        <title>{c.title}</title>
        <meta name="keywords" content={c.keywords} />
        <meta name="descript" content={c.descript} />
      </Helmet>
      <Page lable={c.lable}>
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <ShessBlock
              content={{
                right: c.gosgrant.text,
                left: c.gosgrant.image
              }}
              mediaLeft={true}
              vAlign="center"
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: false
              }}
            />,
            <>
              <TitleH2 content={c.diplom.title} />
              <ShessBlock
                content={{
                  right: c.diplom.image,
                  left: c.diplom.text
                }}
                mediaLeft={false}
                vAlign="center"
              />
            </>,
            <>
              <ScrollingLink scrollLink="price" />
              <Programma
                noScroll
                content={{ programma: c.prices }}
                styleHead={{ textAlign: "center" }}
              />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} scroll="singup" />
              <MiniForm formName="miniforma-chatbot" />
            </>,
            <ShessBlock
              content={{
                left: c.career.image,
                right: c.career.text
              }}
              vAlign="center"
              mediaLeft={true}
            />,
            <>
              <TitleH2 content={c.text1.title} />
              <TextAndTags
                content={{
                  text: c.text1.text,
                  tags: false
                }}
              />
            </>,
            <>
              <TitleH2 content="Открытое образование" scroll="freeeducation" />
              <ShessBlock
                content={{
                  left: c.bot.image,
                  right: c.bot.text
                }}
                mediaLeft={true}
              />
              <div style={{ height: "4rem" }} />
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <div className="box-news">{c.openEdu.text}</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  {c.openEdu.image}
                </Grid>
              </Grid>
              <Link to="/openedu">
                <div className="button">
                  Смотреть все курсы открытого образования
                </div>
              </Link>
            </>
            // <>
            //   <TitleH2
            //     content="Стоимость обучения в рассрочку на 10 месяцев"
            //     scroll="price"
            //   />
            //   <Price content={c.price} />
            // </>,
            // <>
            //   <ScrollingLink scrollLink="singup" />
            //   <SocnetBlock head={c.socnetTitle} />
            // </>,
          ]}
        />
      </Page>
    </>
  );
}

export default ChatBot;
