import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Pattern, { styles } from "../../components/pattern-page-of-course";

const Typology = props => {
  return (
    <Pattern
      title="Как выбрать франшизу и успешно с ней работать"
      faq="Курсы"
      short="Курс | 1½ месяца | 10 занятий | 40 академ. часов | ONLINE"
      teacher={{
        text: (
          <Paragraph
            content={[
              <span>
                Ведущая курса &ndash; Письменская Елена. Основатель
                консалтингового агентства Kids Fashion Retail.
              </span>,
              <span>
                По статистике 20% франчайзи работают и зарабатывают, а 30%
                франчайзи закрывают приобретенный бизнес в течение года. Как
                оказаться в группе первых 20%?! Курс предназначен для
                предпринимателей, кто уже является франчайзи или хочет открыть
                свой бизнес по франчайзингу и подбирает себе партнера.
              </span>,
              <span>
                На курсе будет представлен обзор рынка франшиз, бизнес-процессы
                розничной торговли и подробно рассмотрено чем должен управлять
                франчайзи и как выстроить процесс, чтобы повысить эффективность
                бизнеса.
              </span>
            ]}
            style={styles.text}
          />
        ),
        img: "/img/fashion-franchising/ill01.jpg",
        name: "Письменская Елена"
      }}
      leftTile={
        <div>
          <h3
            style={{
              fontFamily: "Roboto",
              fontSize: "1.6em",
              color: "rgb(100,100,100)",
              textAlign: "center",
              fontWeight: 500,
              margin: 0,
              padding: "12px 0"
            }}
          >
            Очно
          </h3>
          <p
            style={{
              background: "rgb(240,240,240)",
              fontSzie: "1em",
              textAlign: "center",
              color: "rgb(80,80,80)"
            }}
          >
            27 января 2020
          </p>
          <p
            style={{
              fontSize: "0.9em",
              textAlign: "center",
              color: "rgb(100,100,100)"
            }}
          >
            Внесите аванс 2000 рублей
            <br />
            Оформите документы
            <br />
            Забронируйте место в группе
          </p>
          <a href="https://billing.styleschool.ru/event/BbNkNiZRieZYGypMZ">
            <div
              style={{
                background: "rgb(123,143,87)",
                color: "rgb(255,255,255)",
                fontSize: "0.9em",
                textAlign: "center",
                padding: 8,
                borderRadius: 4,
                width: "fit-content",
                margin: "0 auto"
              }}
            >
              забронировать
            </div>
          </a>
        </div>
      }
      rightTile={
        <div>
          <h3 style={styles.h3}>Стоимость</h3>
          <p style={styles.price}>
            50
            <sup>000</sup> руб.
          </p>
          <p style={styles.p}>
            Для записи на курс внесите аванс и оформите договор.
          </p>
          <p style={styles.p}>
            В случае отказа от обучения аванс возвращается.
          </p>
        </div>
      }
      programm={
        <Paragraph
          content={[
            <List
              arr={[
                "Обзор рынка франшиз fashion рынка. Тренды и  текущее состояние отрасли.",
                "Критерии выбора партнера: что важно знать о компании франчайзи и как найти информацию.",
                "Бизнес-модель франчайзинга. Штатное расписание. Организация бизнеса.",
                "Торговая площадь. Критерии выбора площадки. Важные пункты договора с арендодателем. Зонирование.",
                "Торговое оборудование. Виды торгового оборудования и Правила мерчандайзинга в fashion магазине.",
                "Как следовать инструкциям мерчбука.",
                "Маркетинг для франчайзера: методы продвижения. Трейд-маркетинговый календарь. Работа с клиентской базой.",
                "Организация работы продавцов. Основные критерии отбора. Обучение и мотивация продавцов. Тренинги по продажам. 5 этапов продаж.",
                "Работа с Ассортиментом: Правила закупки коллекции. Работа с коллекцией в сезоне. Маржинальность. Скидки и акции. Остатки коллекции – варианты решения проблемы.",
                "Ошибки работы франчайзера. Что важно предпринять, если…"
              ]}
              style={styles.list}
            />,
            "Практикум: поездка в шоу-рум франчайзинговой компании для обсуждения условий и просмотра коллекций.",
            "Практическая работа: Создание и защита бизнес-плана по открытию магазина по франшизе."
          ]}
        />
      }
      additional
      addTitle="Письменская Елена"
      addText={
        <p style={styles.text}>
          Основатель консалтингового агентства Kids Fashion Retail. Эксперт в
          создании новых и развитии существующих брендов детской одежды.
          Постоянный спикер отраслевых выставок и форумов. Преподаватель в
          Fashion Factory Education, Русской школе стиля, МГУ им Ломоносова,
          Центре креативных индустрий программа МВА &ndash; Индустрия Мода.
        </p>
      }
      addImg="/img/fashion-franchising/teacher.jpg"
      addName="Письменская Елена"
    />
  );
};

export default Typology;
