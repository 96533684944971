import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Header from "./header";
import Targets from "./targets";
import Interes from "./interes";
import Tiles from "./tiles";
import Dates from "./dates";
import Teacher from "./teacher";
import Programma from "./programma";
import Price from "./price";
import Steps from "./steps";
import { HorizontalForm } from "../../components/forms";
import Banners from "./banners";
import Page from "../../components/page";

export default function Component() {
  return (
    <div>
      <Helmet>
        <title>Мастерство создания текста</title>
        <meta name="keywords" content="ВШС: мастерство создания текста" />
        <meta
          name="title"
          content="Мастерство создания текста. Искусство самовыражения"
        />
        <meta
          name="keywords"
          content="создания, текста, коппирайтинг, искусство, мастерство, текст, курс, обучение, где, учиться, школа, писать"
        />
        <meta
          name="description"
          content="Курс погружает слушателя в создание текстов и с выполнением практических заданий. Научитесь управлять своими мыслями и словами, развивать искусство самовыражения. Курс поможет справиться с страхом «чистого листа», ленью и отсутствием мотивации, с неумением начать или закончить текст. По окончании курса «Мастерство создания текста» Вы сможете легко и с удовольствием подбирать тему, жанр и название тексту в любой ситуации, научитесь структурно и грамотно выражать свои мысли."
        />
      </Helmet>
      <Page lable="Научиться писать" lableMobile="Научиться писать">
        <Header />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <Targets />
          </Grid>
        </Grid>
        <Interes />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: 20 }}>
            <Tiles />
            <Dates />
            <Teacher />
            <Programma />
            <Price />
            <Steps />
            <HorizontalForm />
            <Banners />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
