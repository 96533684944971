import React from "react";

import Head from "../../components/header-title";

export default function Header() {
  return (
    <Head
      head="Декорирование интерьера. Интенсив"
      subtitle={<span>3 месяца | 28 занятий | 96 академ. часов | ONLINE</span>}
    />
  );
}
