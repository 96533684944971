import React from "react";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import { Link } from "react-router-dom";
import Banners from "./banners";
import {
  BuilderLayout,
  HeadBlock,
  TextAndTags,
  Programma,
  TitleH2,
  Teachers,
  ShessBlock,
  BoldText,
  // Calendar,
  ScrollingLink,
  VideoLocalInLine,
  SpaceElement
} from "../../patterns/pattern-fs/components";
import Examples from "../../patterns/pattern-fs/examples";
import {
  shabalina3,
  bonetskaya,
  bahova,
  migranov
} from "../../contents/teachers";

const ImageConsulting = props => {
  return (
    <div>
      <Helmet>
        <title>
          Fashion практика для стилистов — имидмейкеров от ВШСДТ (после 6
          месяцев обучения)
        </title>
        <meta
          name="keywords"
          content="практика, workshop, имидж, стиль, шопинг, стилист, имиджмейкер, консультант"
        />
        <meta
          name="descript"
          content="Высшая школа стилистики, дизайна и технологий проводит очную практику в Москве дл ястилистов — имиджмейкеров. Студенты могут пройти дополнительную 5-ти дневную практику или 2-х дневный Workshop. Программа практики рассчитана на студентов курсов дистанционной формы обучения, прошедших обучение на курсе «Консультант по стилю. Быстрый старт» или прошедших 6 месяцев обучения на курсе «Имидж-консалтинг и fashion-styling»."
        />
      </Helmet>
      <Page lable="Практика для стилистов">
        <BuilderLayout
          contents={[
            <HeadBlock
              content={{
                ...c.head,
                imageComponent: (
                  <Examples
                    video={true}
                    content={c.examplesVideo}
                    small={"horizontal"}
                  />
                )
              }}
            />,
            <>
              <BoldText
                content={c.boldText}
                styleBox={{ marginBottom: "3rem" }}
              />
              <ScrollingLink scrollLink="dates" />
              {/* <Calendar content={c.dates} /> */}
            </>,
            <Programma
              content={{ programma: c.programma, variants: c.variants }}
            />,
            <Teachers
              content={{
                teachers: [shabalina3, bonetskaya, bahova, migranov],
                teachersHead: c.teachersHead
              }}
            />,
            <>
              <TitleH2 content={c.videoHead} />
              <VideoLocalInLine content={c.videoLocal} />
            </>,
            <>
              <TitleH2 content={"Какие перспективы у профессии"} />
              <ShessBlock
                content={{
                  left: c.perspectives.text,
                  right: c.perspectives.media
                }}
              />
            </>,
            <TextAndTags content={{ text: c.afterEducation, tags: false }} />,
            <>
              <TitleH2 content="Вас могут заинтересовать" />
              <Banners />
            </>,
            <>
              <Link to="/backstages">
                Работы наших выпускников &gt;&gt;&gt;
              </Link>
              <SpaceElement />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default ImageConsulting;
