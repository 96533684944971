import React from "react";
import { Grid } from "@material-ui/core";
import Top from "../../components/topmenu";
import Footer from "../../components/footer";
import Title from "./title-meta";
import Header from "./header-links";
import Paragraph from "../../components/p-array";

const name = "Гульвира Налибаева";

const styles = {
  h2: {
    color: "rgb(142,138,130)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "center",
    margin: 0,
    padding: "12px 0"
  }
};

export default function Nazimova() {
  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid
        container
        justify="center"
        style={{ maxWidth: 1000, margin: "0 auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/nalibaeva1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              facebook="https://www.facebook.com/profile.php?id=100003125742055"
              instagram="https://instagram.com/gulvira_nalibaeva"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <h2 style={styles.h2}>Стиль мужа изменил всё</h2>
          <Paragraph
            j
            content={[
              "До поступления в Высшую школу стилистики я занималась деятельностью, никак не связанной с творческой сферой. Мысль специализироваться на мужской стилистике пришла ко мне совершенно неожиданно. Я неоднократно предпринимала попытки изменить стиль своего мужа в ту или иную сторону, на что он регулярно отвечал, что для начала ему необходимо посмотреть фотографии его будущего образа, вжиться в него и только потом я смогу отправиться с ним на шоппинг для реализации задуманного.",
              "На просторах интернета я встретила необычайно много различных стильных фотографий мужских образов. Но что именно подобрать конкретному мужчине в соответствии с его родом занятий, образом жизни, телосложением, темпераментом, психотипом и еще много чем? Требования, предъявляемые обществом к внешнему виду мужчины, как в профессиональной деятельности, так и в личной жизни, достаточно высоки.",
              "На этом этапе я пришла к выводу, что для того, чтобы разобраться со всеми возникшими вопросами, необходимы профессиональные знания, навыки и опыт. Так я решила стать специалистом в области мужского стиля, чтобы профессионально помогать мужчинам выявлять их стилевые предпочтения, формировать гардероб с учетом целей и задач, которые они перед собой ставят."
            ]}
          />
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <img
                src="/img/graduates/nalibaeva2.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="/img/graduates/nalibaeva3.jpg"
                alt={name}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
          <h2 style={styles.h2}>Впечатления от учебы</h2>
          <Paragraph
            j
            content={[
              "Выбор места обучения пал на Высшую школу стилистики, где я получила образование по специальности «Имидж-консалтинг и fachion-styling». Дополнительное образование получаю и в настоящее время (являюсь слушателем курса «Мужской стиль: made in Italy», участвую в семинарах и мастер-классах по мужскому стилю).",
              "От учебы в Высшей школе стилистики у меня остались очень хорошие впечатления. Это касается как системы преподавания, так и преподавательского коллектива школы. Все мои знания и опыт, которыми я располагала до поступления в школу, гармонично влились в общий поток вновь полученных знаний, были систематизированы и разложены «по полочкам». Однако были моменты, когда мое сознание переворачивалось, потому что какие-то вещи не вписывались в мою систему координат и приходилось менять годами устоявшиеся стереотипы.",
              "Во время обучения в ВШС я познакомилась с новыми людьми, с которыми продолжаю общаться и по окончании школы. На сегодняшний день могу сказать, что мы стали настоящими друзьями. У нас общие интересы и даже появились традиции, например, раз в неделю мы встречаемся, чтобы вместе сходить в музей, посетить выставку.",
              "В настоящее время я являюсь членом Московского центра стилистов. Используя знания и навыки, полученные во время обучения в школе, а также свой личный опыт и вкус, я помогаю клиентам создавать индивидуальный стиль, и получаю от этого огромное удовольствие!"
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
