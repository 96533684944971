import React, { useState } from "react";
import "./style.css";

export function Spoler(props) {
  const [open, setOpen] = useState(false);

  const opener = () => setOpen(!open);
  return (
    <>
      <div onClick={opener} className="title-spoler">
        <div className="title-spoler-text">{props.title}</div>
        <div className="open-close-spoler">
          <div className={open ? "spoler-open" : "spoler-close"}>+</div>
        </div>
      </div>
      <div className={open ? "spoler-text-open" : "spoler-text-close"}>
        {props.text}
      </div>
    </>
  );
}

export function Spolers(props) {
  const c = props.content;
  return c.map((el, key) => (
    <Spoler title={el.title} text={el.text} key={`spoler${key}`} />
  ));
}
