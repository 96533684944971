import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { dates, Month } from "../shedule/dates";
import Link from "react-router-dom/Link";

const date = dates.buyer.getDate() + " " + Month(dates.buyer.getMonth());

export const contents = {
  noForms: true,
  head: {
    headDescript: "Профессиональное образование",
    title: "Курсы имиджмейкеров",
    head: (
      <>
        Менеджмент в индустрии моды
        <br />
        Профессия: Байер
      </>
    ),
    headAfterDescript: (
      <>
        Программа профессиональной переподготовки с максимальным погружением в
        индустрию моды, ее внутренних механизмов продвижения и продаж модного
        продукта, управления закупками и рисками, прогнозирования трендов и
        спроса
      </>
    ),
    descript: [
      <div style={{ paddingTop: "1rem" }}>Очно, онлайн</div>,
      <div style={{ textAlign: "left" }}>
        Диплом о профессиональной подготовке РГУ
        им.&nbsp;А.&nbsp;Н.&nbsp;Косыгина
      </div>,
      <>Продолжительность обучения – 8 месяцев, 504 ак.часа</>,
      <>Стоимость обучения 161 760 руб., рассрочка</>,
      <>Налоговый вычет после обучения</>
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/buyer/screen1.jpg",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      }
    ],
    noVideo: true,
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "tel:+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>очно / дистанционно</>, <>с {date}</>]
    },
    {
      title: "Форматы",
      text: ["Классическое очное,", "дистанционное (онлайн)"]
    },
    {
      title: "Преподаватели",
      text: [
        "Совместно преподаватели РГУ",
        <>им.&nbsp;А.&nbsp;Н.&nbsp;Косыгина и ВШСДТ</>
      ]
    }
  ],
  academy: {
    title: "Академическое образование + практические кейсы",
    text: (
      <>
        <p className="p-constructor">
          Программа нацелена на качественную подготовку обучающихся к
          профессиональной деятельности байера, бизнес-консультанта в области
          закупок одежды для предприятий индустрии моды.
        </p>
        <p className="p-constructor">
          В программе предусмотрена достаточная теоретическая подготовка для
          формирования профессионального мышления и достаточная практическая
          подготовка для приобретения навыков управления, коммуникации,
          прогнозирования, стилевой и финансовой аналитики.
        </p>
      </>
    ),
    image: (
      <img
        src="/img/buyer/screen2.jpg"
        alt="Академическое образование"
        style={{}}
      />
    )
  },
  text1: (
    <>
      <p className="p-constructor">
        Обучение реализуется Институтом дополнительного образования РГУ им. А.Н.
        Косыгина. Официальная информацию о программе и условиях зачисления
        размещена на странице{" "}
        <a href="http://www.ipk-design.com/management/" target="_blank">
          www.ipk-design.com/management
        </a>
      </p>
      <p className="p-constructor">
        Очное обучение проводиться по адресу: г. Москва, метро Шаболовская, ул.
        Малая Калужская, д. 1 (здание РГУ им. А.Н. Косыгина)
      </p>
      <p className="p-constructor">
        Дистанционное обучение (живое преподавание онлайн) доступно по всему
        миру.
      </p>
    </>
  ),
  text1Media: (
    <>
      <div>
        <a href="/style-consultant-tgu" target="_blank">
          <img
            src="/img/second-degree-imagemaker/tgu-education.png"
            alt="Консультант по стилю. Бесплатное обучение по гос гранту"
            style={{ border: "1px solid black" }}
          />
        </a>
      </div>
    </>
  ),
  shess: [
    {
      media: (
        <img
          src="/img/buyer/diplom.jpg"
          style={{ maxWidth: 400, height: "auto" }}
        />
      ),
      text: (
        <p className="p-constructor">
          Слушателям, успешно завершившим обучение, вручается диплом о
          профессиональной переподготовке на право ведения нового вида
          профессиональной деятельности по направлению «Менеджмент организации.
          Специализация байер»
        </p>
      )
    }
  ],
  miniFormHead: (
    <>
      Записаться на пробные занятия, получить полную программу курса или
      консультацию по обучению
    </>
  ),
  text2: (
    <List
      arr={[
        <>
          ВШСДТ — АНО ДПО «Высшая школа стилистики, дизайна и технологий» одна
          из ведущих образовательных площадок, основатели которой работают в
          индустрии с 2002 года.
        </>,
        <>
          Мы даем полноценные курсы профессиональной переподготовки и повышения
          квалификации в индустрии моды и дизайна как очно, так и дистанционно.
        </>,
        <>
          Мы предоставляем широкий спектр возможностей освоения программ:
          долгосрочные и краткосрочные программы, интенсивы, практикумы и онлайн
          эфиры.
        </>,
        <>Смотрите информацию в разделах</>,
        <Link to="/pro-education">Профессиональное образование</Link>,
        <Link to="/video">Краткосрочные курсы</Link>,
        <Link to="/practice">Практики, стажировки</Link>,
        <Link to="/young-fashion">Детская студия</Link>,
        <Link to="/master-class">Эфиры, встречи</Link>
      ]}
      style={{
        paddingBottom: ".5rem",
        paddingLeft: 0,
        marginLeft: 0,
        lineHeight: "1.4em"
      }}
      num="none"
    />
  ),
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Обучение основано на комплексном подходе, как и требует суть актуальной
        для современного рынка профессия байера — знания по менеджменту и
        маркетингу, логистике и организационных моделях, современном состоянии
        модной индустрии: трендах и инновациях в текстиле, ДНК брендов и стилей
        в одежде, ассортименте и ключевых технологиях производства, Одним из
        достоинств программы является развитие стилистического чутья в условиях
        ограничений: финансовых, ресурсных, поведенческих моделей потребителя.
      </p>,
      <p>
        <strong>Модуль 1. Основы менеджмента</strong>
      </p>,
      <p>
        Методы принятия управленческих решений. Функции байера. Секреты
        успешности байера. Контакты с поставщиками. Работа с подразделениями
        внутри компании. Деловое общение. Принципы взаимодействия между
        структурными подразделениями внутри компании.
      </p>,
      <p>
        <strong>Модуль 2. Маркетинг</strong>
      </p>,
      <p>
        Особенности маркетинга для байеров. Маркетинговые коммуникации.
        Поведение потребителей. Мерчандайзинг.
      </p>,
      <p>
        <strong>Модуль 3. Бренды и организационное поведение</strong>
      </p>,
      <p>
        Особенности работы Fashion рынка. Ключевые бренды в мире моды.
        Отличительные особенности модных брендов.
      </p>,
      <p>
        <strong>Модуль 4. Управленческие решения</strong>
      </p>,
      <p>
        Ценообразование. Ценовая политика предприятия. Ценовая стратегия
        предприятия. Методы расчета цен. Точка безубыточности.
      </p>,
      <p>
        <strong>Модуль 5. Логистика</strong>
      </p>,
      <p>
        Система закупок. Анализ продаж предыдущего сезона. Основы
        бизнес-планирования. Планирование бюджета. Сравнительный шопинг.
        Особенности закупки образцов-прототипов. Управление ассортиментом.
      </p>,
      <p>
        <strong>Модуль 6. Феномен моды. Мировые модные тенденции.</strong>
      </p>,
      <p>
        Сбор информации для определения модных тенденций. Прогнозирование модных
        тенденций. Планирование модных тенденций.
      </p>,
      <p>
        <strong>Модуль 7. История моды, стилей и костюма</strong>
      </p>,
      <p>
        История моды, стилей и костюма. Изучение существующих стилей одежды.
      </p>,
      <p>
        <strong>Модуль 8. Ассортиментная политика.</strong>
      </p>,
      <p>
        Ассортиментный план. Ассортиментная матрица. Сезонные продажи. Принятие
        цветовых решений. Факторы, влияющие на успешность модного ассортимента.
      </p>,
      <p>
        <strong>
          Модуль 9. Инновационные технологии в текстильной индустрии.
        </strong>
      </p>,
      <p>
        Классификация материалов и изделий. Отличительные особенности волокон,
        переплетений. Структура материалов. Виды принтов, крашения и отделки.
        Ассортимент материалов для одежды и обуви. Органолептическая оценка и
        идентификация материалов. Оценка качества текстильных и кожевенных
        материалов.
      </p>,
      <p>
        <strong>Модуль 10.Экспертиза однородных групп товаров</strong>
      </p>,
      <p>
        Понятие качества товара. Методы экспертизы. Оценка качества. Сортировка
        изделий. Идентификация товаров. Виды и методы идентификации. Экспертиза
        подлинности. Идентификация степени соответствия. Понятие о
        фальсификации. Виды и методы обнаружения фальсификации. Последствия
        фальсификации. Акт экспертизы, принципы составления.
      </p>,
      <p>
        <strong>
          Модуль 11. Основы технологии производства одежды, обуви, аксессуаров.
        </strong>
      </p>,
      <p>
        Технологии производства одежды, обуви, аксессуаров. Классификация
        одежды, обуви, аксессуаров. Виды и разновидности товаров. Наименование
        деталей одежды и обуви.
      </p>,
      <p>
        <strong>Итоговая аттестация – защита работы</strong>
      </p>,
      <p>
        <strong>Освоив профессию, Вы сможете:</strong>
      </p>,
      <List
        arr={[
          <>
            отбирать одежду, обувь, которые гарантировано будут пользоваться
            спросом у покупателей
          </>,
          <>
            формировать коллекции для различных магазинов и определять их
            окупаемость
          </>,
          <>
            работать как с магазинами, бутиками, шоу-румами, так и с
            дизайнерскими брендами
          </>,
          <>
            иметь возможности для постоянного профессионального и финансового
            роста, поскольку ниша профессиональных байеров еще мало заполнена.
          </>
        ]}
        olStyle={{ listStyle: "url('/img/orange-check.svg')" }}
        style={styles.p}
      />,
      <p>
        <strong>Что дает обучение:</strong>
      </p>,
      <List
        arr={[
          "опыт специалистов, авторские методики и массу профессиональных тонкостей",
          "владение комплексным подходом и умение учитывать все составляющие",
          "универсальность: программа подходит как новичкам, так и уже работающим специалистам в этой области, готовых к развитию и новым перспективам"
        ]}
        style={styles.p}
      />,
      <p>
        Слушатели, успешно прошедшие обучение, выполнившие учебный план по
        программе «Менеджмент в организации. Профессия — байер» (8 месяцев),
        сдавшие проектные работы, не имеющие академической задолженности и
        представившие все необходимые документы, получают диплом установленного
        государством образца о профессиональной переподготовке с правом
        деятельности в изученной профессиональной области.
      </p>
    ]
  }
};
