import React from "react";
import { Link } from "react-router-dom";
import List from "../../components/list";
import Papers from "./papers";
import { dates, Month } from "../shedule/dates";
import Button from "../../components/button-messages";

export const contents = {
  titlePage: "Сам себе стилист. Мастерская Светланы Шабалиной",
  head: {
    headDescript: "Теория и практика",
    head: (
      <>
        Сам себе стилист.
        <br />
        Мастерская Светланы Шабалиной
      </>
    ),
    headAfterDescript: (
      <>
        Практический курс по созданию индивидуального стиля с преподавателем
        курсов профессиональной переподготовки{" "}
        <Link to="/imagemaker-stylist">стилистов-имджмейкеров</Link>,
        имидж-стилистом Светланой Шабалиной
      </>
    ),
    descript: [
      "Очно, дистанционно",
      "Бонус: персональные консультации со стилистом",
      "Преображение за 1 месяц регулярных занятий в мини группе",
      "Живое общение, открытый чат",
      <>Поддержка после обучения</>
      // <>Рассрочка без переплат от 500 &#8381; в месяц</>
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/self-style/shabalina-first-ill.jpg",
    styleImage: {
      maxHeight: 334
    },
    spaceHeadText: 32,
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    },
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        /*
        <>
          Онлайн с{" "}
          {dates.selfStyle.online.getDate() +
            " " +
            Month(dates.selfStyle.online.getMonth())}
        </>,
        */
        <>
          Онлайн с{" "}
          {dates.selfStyle.online2.getDate() +
            " " +
            Month(dates.selfStyle.online2.getMonth())}
        </>
      ]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции,</>,
        <>чат в группе, очная практика</>
      ]
    },
    {
      title: "Видео курс",
      text: [<>Доступен видео курс</>, <>для самостоятельного изучения</>]
    }
  ],
  text1: {
    text: (
      <>
        <h3 className="p-constructor bold">
          Что вы получите по результатам курса?
        </h3>
        <List
          arr={[
            <>
              Stylebook с персо&shy;наль&shy;ной палит&shy;рой, подхо&shy;дящими
              фасо&shy;нами и комплек&shy;тами на все случаи жизни
            </>,
            <>
              Свободу от ненуж&shy;ных вещей и идеаль&shy;ный порядок в
              гарде&shy;робе
            </>,
            <>
              Навык подбора в магази&shy;нах своих вещи без лишних поку&shy;пок
            </>,
            <>
              Алгоритм состав&shy;ления стиль&shy;ных комп&shy;лектов
              само&shy;стоя&shy;тель&shy;но
            </>,
            <>
              Возможность помогать подру&shy;гам и давать стиль&shy;ные советы
            </>
          ]}
          classLi="p-constructor"
        />
      </>
    ),
    image: (
      <div className="image-bkg" style={{ backgroundImage: "none" }}>
        <img
          src="/img/self-style/i01.jpg"
          alt="Сам себе стилист"
          className="h"
          // style={{height: 240, width: 320}}
        />
      </div>
    )
  },
  howIsTheTrainingGoing: [
    {
      title: "Видео уроки",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;ча­ете видео уроки в сво&shy;бод&shy;ном,
          ком&shy;­форт&shy;­ном ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          После каждого урока полу&shy;­чаете до&shy;маш­&shy;ние зада&shy;ния
          по эле&shy;мен&shy;там своего стиля.
        </>
      )
    },
    {
      title: "Онлайн общение",
      text: (
        <>
          Регулярно — живое обще&shy;­­ние, помощь в соз&shy;дании обра&shy;зов
          на каждый день
        </>
      )
    },
    {
      title: "Книга стиля",
      text: (
        <>
          В итоге обуче&shy;ния Вы созда&shy;­ете персо&shy;наль&shy;ную Книгу
          стиля — mini stylebook
        </>
      )
    }
  ],
  screen2: {
    text: (
      <>
        <strong>Светлана Шабалина</strong>
        <p className="p-constructor">
          Частное консультирование клиентов с 2011 года, организация и
          постановка стильных фотосессий, сотрудничество в качестве
          имидж-эксперта с изданиями «Вечерняя Москва», «Мос&shy;квич&shy;ка»,
          «Худеем правильно». Преподавательская деятельность с 2014 года, с 2016
          года — преподаватель курсов про&shy;фес&shy;сио&shy;наль&shy;ной
          подготовки стилистов-имиджмейкеров ВШСДТ. Автор книги «Быть стильной,
          оставаясь собой. Как рассказать о себе с помощью одежды» 2021г.
        </p>
      </>
    ),
    image: (
      <div
        className="image-bkg"
        style={{
          backgroundImage: "url('/img/self-style/shabalina-dates-ill.jpg')"
        }}
      >
        <img
          src="/img/self-style/shabalina-dates-ill.jpg"
          // style={{ maxWidth: 320 }}
          className="v"
          alt="Светлана Шабалина"
        />
      </div>
    )
  },
  curent: {
    text: (
      <>
        <p className="p-constructor">
          <strong>Изучайте видео уроки и советуйтесь со стилистом</strong>
        </p>
        <p className="p-constructor">
          Оплатившим курс доступны консультации стилистов. На консультации Вы
          получите рекомендации по определению стилевого типажа, а так же
          рекомендации по подходящим Вам стилям одежды, силуэтам, фактурам
          тканей, причёскам и макияжу.
        </p>
        <p className="p-constructor">
          А еще мы включим Вас в группу, где Вы будете получать информацию о
          новых мастер-классах и задавать инте&shy;ресую&shy;щие вопросы по
          индивидуальному стилю автору курса и другим практикующим стилистам
        </p>
      </>
    ),
    image: (
      <div
        className="image-bkg"
        style={{ backgroundImage: "url('/img/self-style/batalova-i01.jpg')" }}
      >
        <img
          src="/img/self-style/batalova-i01.jpg"
          alt="Стилист Баталова Татьяна"
          className="v"
        />
      </div>
    )
  },
  insertAfterDates: (
    <>
      <Papers
        content={[
          {
            title: "Видео курс",
            gray: false,
            text: (
              <>
                <List
                  arr={[
                    <>9 часов видео уроков (12 тем)</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Домашние задания</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>Персональная консультация стилиста</>,
                    <>Доступ к курсу сразу после оплаты</>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          },
          {
            title: "Online курс",
            gray: (
              <>
                <strong>25000 &#8381;</strong>
              </>
            ),
            text: (
              <>
                <List
                  arr={[
                    <>9 часов видео уроков (12 тем)</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Домашние задания</>,
                    <>Проверка домашних заданий</>,
                    <>9 часов онлайн консультаций</>,
                    <>Живое общение онлайн в мини группе</>,
                    <>Обратная связь по каждому ДЗ</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>Общение с коллегами в закрытой группе</>,
                    <>Сертификат после обучения</>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          },
          {
            title: "Очный курс",
            gray: <strong>32000 &#8381;</strong>,
            text: (
              <>
                <List
                  arr={[
                    <>12 оффлайн встреч по полтора часа</>,
                    <>Включая подбор новых образов на шопинге</>,
                    <>Проверка домашних заданий</>,
                    <>Живое общение оффлайн в мини группе</>,
                    <>Обратная связь по каждому ДЗ</>,
                    <>Доступны 9 часов видео уроков (12 тем)</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>Общение с коллегами в закрытой группе</>,
                    <>Сертификат после обучения</>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          }
        ]}
      />
    </>
  ),
  tilesProgramma: [
    {
      image: {
        image: "/img/self-style/less-01.jpg",
        alt: "Занятие 1",
        vertical: true
      },
      title: "Занятие 1",
      text: (
        <>
          Стиль и имидж: как влияют на нашу жизнь и отношения. В чем отличие
          «звезд­ного» стиля от еже­днев­ного. Как строится стиль­ный образ.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-02.jpg",
        alt: "Занятие 2",
        vertical: true
      },
      title: "Занятие 2",
      text: (
        <>
          Значение цвета в одежде. Как влияет цвет на наше нас­троение и на
          окру­жающих нас людей. Правила гармо­ничных цветовых сочетаний.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-03.jpg",
        alt: "Занятие 3",
        vertical: true
      },
      title: "Занятие 3",
      text: (
        <>
          Индивидуальная цветовая палитра. Как научиться выбирать подхо­дящие
          оттенки и находить их в магазинах.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-04.jpg",
        alt: "Занятие 4",
        vertical: true
      },
      title: "Занятие 4",
      text: (
        <>
          Типология фигур. Нет плохих фигур — есть непра­вильная одежда!
          Определяем свои выигрыш­ные фасоны.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-05.jpg",
        alt: "Занятие 5",
        vertical: true
      },
      title: "Занятие 5",
      text: (
        <>
          Идеальный силуэт. Как добавить или убавить объем, постройнеть на два
          размера и сгармо­низи­ровать пропорции.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-06.jpg",
        alt: "Занятие 6",
        vertical: false
      },
      title: "Занятие 6",
      text: (
        <>Современные стили в одежде. Клас­сифи­кация стилевых направлений.</>
      )
    },
    {
      image: {
        image: "/img/self-style/less-07.jpg",
        alt: "Занятие 7",
        vertical: true
      },
      title: "Занятие 7",
      text: (
        <>
          Эклектика и смешение стилей. Как правильно смешивать разные стили и
          создавать совре­мен­ные комплекты. Принципы подбора и комплек­тования
          аксессуаров.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-08.jpg",
        alt: "Занятие 8",
        vertical: true
      },
      title: "Занятие 8",
      text: (
        <>
          Индивидуальность — синоним шика. Как учесть модные тренды, образ
          жизни, комфорт и выгля­деть индиви­дуально. Прописываем персо­нальную
          формулу стиля.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-09.jpg",
        alt: "Занятие 9",
        vertical: true
      },
      title: "Занятие 9",
      text: (
        <>
          Правила составления стиль­ных ком­плектов. Как научиться соеди­нять
          вещи в единый образ, не следуя шаблон­ным советам и не тратя пол дня
          на один комплект.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-10.jpg",
        alt: "Занятие 10",
        vertical: true
      },
      title: "Занятие 10",
      text: (
        <>
          Рациональный гардероб. Как правильно разобрать гардероб и навести в
          нем идеаль­ный порядок. Принципы создания базового гарде­роба.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-11.jpg",
        alt: "Занятие 11",
        vertical: true
      },
      title: "Занятие 11",
      text: (
        <>
          Индивидуальный гардероб. Как избежать удобного, но скучного
          гарде­роба. Как сохранить свою индиви­дуаль­ность при кап­сульном
          подходе. С чего начинать: первые покупки для нового гардероба.
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-12.jpg",
        alt: "Занятие 12",
        vertical: true
      },
      title: "Занятие 12",
      text: (
        <>
          Секреты удачного шопинга. Как не уставать на шопинге, не тратить пол
          дня на примерки и возвра­щаться домой с выгод­ными покупками, которые
          впишутся в гардероб.
        </>
      )
    }
  ],
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой"
};
