import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { dates, Month } from "../shedule/dates";
import Papers from "../../components/papers/papers";
import Button from "../../components/button-messages";

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Психология цвета",
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Символика цвета и психология его восприятия"
      </div>
    ),
    descript: [
      "Очно, онлайн",
      "Новые идеи за 1 месяц регулярных занятий в мини группе",
      "Длительность — 16 занятий по полтора часа",
      "Стоимость 25 тыс.рублей"
      // <>
      //   Ближайшая дата — с{" "}
      //   {dates.psychologyOfColor.online.getDate() +
      //     " " +
      //     Month(dates.psychologyOfColor.online.getMonth())}
      // </>
    ],
    text: "",
    imageSource: "/img/psychology-of-color/bkg.jpg",
    video: "hy3YcutF0pw",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    }
  },
  text1:
    "Иллюстрированные лекции, посвященные семантике цветов в мировой культуре, а также психофизиологии их восприятия, дают понимание того, как воздействует цвет, помогают оптимизировать общение, осмысливать моду как социальное явление и в целом сделать жизнь более гармоничной",
  programma: {
    head: "Программа курса",
    content: [
      <h3 style={styles.p}>
        <strong>Цель обучения</strong>
      </h3>,
      <List
        num="none"
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Понять воздействие цвета на человека и умение применять это знание",
          "Осознать влияние цвета на моду и социум в целом",
          "Гармонизировать свою жизнь и жизнь близких людей"
        ]}
      />,
      <h3 style={styles.p}>
        <strong>Содержание курса</strong>
      </h3>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Первобытная цветовая триада и ее семантика.  Черный, белый, красный.",
          "Эстетика черного в дизайне.",
          "Белый и визуальное восприятие.",
          "Оттенки, смыслы, тренды. Красный.",
          "Оттенки, смыслы, тренды. Оранжевый.",
          "Оттенки, смыслы, тренды. Желтый.",
          "Оттенки, смыслы, тренды. Зеленый.",
          "Оттенки, смыслы, тренды. Голубой.",
          "Оттенки, смыслы, тренды. Синий.",
          "Оттенки, смыслы, тренды. Фиолетовый.",
          "Многообразие и воздействие серого.",
          "Эстетика пастельных цветов.",
          "«Неоновые» цвета: назначение и воздействие.",
          "«Земли»: ассоциативное восприятие  и тренды.",
          "Сочетания цветов и визуальное впечатление."
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/teachers/320x240/marina-bonetskaya.jpg",
      name: "Марина Бонецкая",
      text:
        "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
      instagram: false,
      buttons: {
        detail: "/marina_bonetskaya",
        linkInside: true,
        video: "IUMo8Cp92Kk"
      }
    }
  ],
  pay: (
    <>
      <h2 style={styles.h2}>Варианты обучения, стоимость</h2>
      <Papers
        content={[
          {
            title: "Видео курс",
            gray: (
              <>
                <strong>5000 &#8381;</strong>
              </>
            ),
            text: (
              <>
                <List
                  arr={[
                    <>Доступ ко всем 8 видео занятиям</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>
                      Доступна банковская рассрочка без переплат на 10 месяцев
                    </>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          },
          {
            title: "Online курс",
            gray: (
              <>
                <strong>20000 &#8381;</strong>
              </>
            ),
            text: (
              <>
                <List
                  arr={[
                    <>Доступ ко всем 8 видео занятиям</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>Домашние задания</>,
                    <>Обратная связь по каждому ДЗ</>,
                    <>4 персональные часовые консультации с преподавателем</>,
                    <>Сертификат после обучения</>,
                    <>
                      Доступна банковская рассрочка без переплат на 10 месяцев
                    </>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          }
        ]}
      />
    </>
  )
};
