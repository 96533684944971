import React from "react";
import { ShessBlock } from "../../patterns/pattern-fs/components";

function Text(props) {
  return (
    <>
      <h3>{props.title}</h3>
      {props.text}
    </>
  );
}

export function News(props) {
  const c = props.content;
  let t = [];
  t = c.sort((a, b) => a.date.getTime() - b.date.getTime());

  return (
    <>
      {t.map((el, k) => (
        <div style={{ padding: "2rem 0" }}>
          <ShessBlock
            content={{
              right:
                k % 2 ? (
                  <Text text={el.text} title={el.title} />
                ) : (
                  <img src={el.image} alt={el.title} />
                ),
              left:
                k % 2 ? (
                  <img src={el.image} alt={el.title} />
                ) : (
                  <Text text={el.text} title={el.title} />
                )
            }}
            mediaLeft={k % 2 ? true : false}
            vAlign="center"
          />
        </div>
      ))}
    </>
  );
}
