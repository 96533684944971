import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
import Paragraph from "../../components/p-array";
// import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "PR образовательных услуг",
    headAfterDescript:
      "Повышение квалификации педагогического состава организаций среднего и средне-специального образования и преподавателей частной",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 1 месяц, 24 академических часа",
      "Рассрочка: 10 мес. Налоговый вычет",
      "Стоимость курса: 9900 руб."
    ],
    text: "",
    imageSource: "/img/concept-fashion-g/concept-i.jpg",
    noVideo: true,
    styleHead: {
      fontSize: "3rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: {
    dates: [
      {
        d: dates.educationPR.online.getDate(),
        t: Month(dates.educationPR.online.getMonth()),
        c: "дистанционно"
      }
      // {
      //   d: dates.trendsOfModernFashion.ofline.getDate(),
      //   t: Month(dates.trendsOfModernFashion.ofline.getMonth()),
      //   c: <span>&ensp;очно</span>
      // }
    ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal" }}>
          Все, что соответствует требованиям времени, на практике:
        </span>
      )
    },
    text: (
      <List
        arr={[
          "PR образовательных услуг",
          "PR учебной организации",
          "PR преподавателей"
        ]}
      />
    ),
    video: "rYKZAsSnC84"
  },
  text1: (
    <>
      Этот курс для Вас отвечаете за публичное позиционирование и продвижение
      образовательных услуг и являетесь:
      <List
        arr={[
          <>сотрудником муниципальной или частной школы/колледжа</>,
          <>
            руководителем и педагогом центра дополнительного образования для
            детей
          </>,
          <>преподавателем частной практики</>
        ]}
      />
    </>
  ),
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель обучения
          </h2>,
          <p style={styles.p}>
            Получение практических знаний в области PR и рекламы в сети интернет
            для разработки мер по повышению престижности и укреплению
            позитивного имиджа вашей образовательной организации или частной
            педагогической практики в кругу учащихся, родителей и органов
            местного, регионального и федерального управления.
          </p>
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Исследование текущего положения образовательной организации, исследование конкурентов, SWOT-анализ",
          "Сегментирование и исследование целевой аудитории",
          "Разработка коммуникационной стратегии и планирование ее реализации",
          "Сайт как инструмент продвижения",
          "Генерация инфоповодов и сторителлинг",
          "Структура и правила написания пресс-релиза",
          "Продвижение в социальных сетях — секреты создания востребованного контента",
          "Работа с негативом и фейкньюз в сети интернет"
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Записи лекций",
          "Онлайн семинары с автором",
          "Консультации по выполнению практических работ",
          "Проверка / тестирования",
          "Практическая работа"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат обучения
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Умение рассказать о вас, вашей школе или колледже так, чтобы все о ней заговорили",
          "Умение генерировать информационные поводы из ничего и наращивать аудиторию без бюджета",
          "Навыки создания контент-плана и продвижения востребованного контента в социальных сетях",
          "Управление репутацией в условиях постоянного негатива в сети"
        ]}
        num="none"
      />
    ]
  },
  teachers: [
    {
      image: "/img/teachers/320x240/mariya-markova.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Мария Маркова
        </>
      ),
      text: (
        <Paragraph
          content={[
            "Эксперт в области коммуникаций, бизнес-тренер",
            "15 лет опыта в коммерческих и федеральных проектах.",
            "Входит в ТОП-10 руководителей коммуникационных агентств федерального рейтинга ТОП COMM-2019.",
            "Входит в ТОП-25 директоров по корпоративным коммуникациям России федерального рейтинга ТОП COMM-2020.",
            "Действующий член Ассоциации преподавателей в области связей с общественностью (АПСО)."
          ]}
        />
      ),
      instagram: false,
      buttons: false
      // {
      //   detail: "/natalya_gorskyh",
      //   linkInside: true
      // }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={style.text}>
            <strong>Характеристики:</strong>
          </p>
          <p style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 1 месяц, 12 занятий по 2 академ. часа
            <br />
            Формат обучения: записи лекций + онлайн семинары (живое общение)
            <br />
            Экзамен: тестирование
            <br />
            Все занятия доступны в записи в личном кабинете
          </p>
          <p style={style.text}>
            <strong>Стоимость:</strong>
          </p>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты: 9<sup>900</sup> рублей — единовременный платёж
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>900 рублей</strong> ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 9<sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="9900.00"
                            name="PR образовательных услуг (онлайн-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>,
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </div>
      )
    }
  }
};
