import React from "react";

export default function Header() {
  return (
    <h1
      style={{
        fontFamily: "Roboto",
        fontSize: "1.6em",
        fontWeight: 700,
        color: "rgb(100,100,100)",
        textAlign: "left",
        margin: 0,
        padding: "20px 0"
      }}
    >
      Профподготовка
    </h1>
  );
}
