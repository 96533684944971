import React from "react";

import H2 from "../../components/head1";
import Spoler from "../../components/spoler";

export default function Programma() {
  var content = [
    {
      short: "Профессия пресс-секретарь",
      detail:
        "Современная информационная система общества: история формирования, особенности функционирования.  Информационное общество как новый этап общественного развития. Современная журналистика и современные СМИ: типы, виды, особенности. Общественное мнение. Роль и место профессии пресс-секретаря: сущность работы, пределы профессиональной ответственности, профессиональная компетентность. Организация работы пресс-службы: состав, должностные обязанности. Критерии эффективности работы пресс-службы и пресс-секретарей."
    },
    {
      short: "Организация  взаимодействия со средствами массовой информации",
      detail:
        "Создание и управление положительной репутацией. Взаимодействие со СМИ: выбор каналов распространения информации, создание журналистского пула, подготовка медиа поводов. Раскрутка медийной активности. Организация информационных мероприятий: пресс-конференция, брифинг и т.д. Социальная реклама, информационные общественные мероприятия, выставки и конференции. Лоббирование интересов в государственных органах."
    },
    {
      short: "Медиаконтакт: общие положения",
      detail:
        "Цели и задачи медиаконтакта. Корректная постановка цели. Как выбрать содержание и стиль выступления в зависимости от характера мероприятия. Техники проведения медиаконтактов (интервью, пресс-конференций, брифингов). Подготовка спикеров к выступлению / общению со СМИ. Тренировка управления медиаконтактом."
    },
    {
      short: "Медиаконтакт: выступления, личные контакты, работа с аудиторией",
      detail:
        "Как заинтересовать аудиторию. Как удерживать аудиторию. Как завершить контакт. Медиаповедение и управление медиаконтактом. Ораторское мастерство. Умение приветствовать интервьюера и аудиторию. Установление и поддержание зрительного контакта в процессе проведения медиаконтакта. Сектор обзора. Контакт с интервьюером, контакт с оппонентом, контакт с виртуальным зрителем. Законы цвета в кадре. Детали костюма. Make up. Законы фотосессии. Сопроводительные материалы: фото, видео, аудио, логотип, презентация, прессволл. Постановка позы и жестикуляции. Положение сидя, положение стоя. Принципы жестикуляции. Отсечение нефункциональной жестикуляции. Речь. Дыхание. Голос. Техника и логика речи. Интонация. Ударение. Работа над дикцией. Монолог и диалог в эфире. Дискуссия. Интервью. Ток-шоу."
    },
    {
      short:
        "Организация мероприяий: (пресс-конференций, брифингов, круглых столов)",
      detail:
        "Стратегический подход к организации мероприятий: от идеи к воплощению. Актуальные потребности компании. Форматы мероприятий и их функциональные задачи. Выбор нужного формата. Оценка внутренних ресурсов компании для проведения мероприятия. Готовность базы контактов СМИ, партнеров, клиентов компании. Стратегический план мероприятия. Цель проведения. Тематика мероприятия. Дерево задач, график подготовки материалов, распределение трудовых ресурсов, зоны ответственности. Работа со средствами массовой информации (приглашение, аккредитация, этика взаимодействия, подготовка информационных материалов для СМИ). Работа с партнерами и клиентами компании."
    },
    {
      short: "Оценка эффективности взаимодействия со СМИ",
      detail:
        "Бизнес-показатели эффективности работы пресс-секретаря. Типичные сложности при оценке взаимодействия со СМИ. Качественные и количественные методы оценки эффективности. Формы отчетности по взаимодействию со СМИ."
    },
    {
      short: "Формирования имиджа руководителя и кампании",
      detail:
        "PR – технологии создания и управления публичной репутацией. Методы PR и их отличие от других подходов по созданию и управлению информационными потоками.  Оценка эффективности PR – деятельности. Анализ практик PR кампаний. – Целевые аудитории мероприятий по связям с общественностью. Каналы коммуникации и номенклатура рекламных носителей."
    },
    {
      short:
        "Формирование стратегии и тактики информационной активности компании",
      detail:
        "Управление информационными потоками и  повышение информированности. Противодействие искажению информации. Модель PESO , социальные доказательства и социальная ответственность. Противодействие искажению информации.  Анализ корпоративных практик. Информационная стратегия кампании: определение цели, планирование, разработка. Тактика: выбор средств реализации, тактические подходы. Информационные каналы. Анализ примеров различных информационных кампаний."
    },
    {
      short:
        "Общественное мнение: технологии работы и формирования общественного мнения",
      detail:
        "Общественное мнение: сущность явления. История исследований общественного мнения. Теория информационного контроля общества. Элементы общественного сознания и работа по формированию общественного мнения. Пропаганда, PR, социальная реклама, информационный маркетинг, новые подходы к  информационному контролю общественных процессов. Интернет и СМИ особенности  развития."
    },
    {
      short: "Методы информационной атаки и информационного противодействия",
      detail:
        "Особенности профессионального поведения в ситуации информационного противостояния и информационной атаки.  Предупреждение информационных атак, работа на опережение, уменьшение последствий информационных атак. Стратегия и тактика информационной войны. Формирование скандала. Управление негативными информационными потоками."
    },
    {
      short: "Информационная работа в Интернете",
      detail:
        "Принципы формирования новостной и информационной политики. Эффективные формы подачи информации. Поддержание правильной обратной связи. Работа с различными средами: молодежь, государственные организации, неформальные объедения Способы распространения информации в интернете. Оптимальное использование и практика применения PR-инструментов. Новые PR-инструменты: вирусное видео, экспертное видео, видеопрезентация. Социальные и геоинформационные PR-технологии."
    },
    {
      short: "Информационная работа в Интернете",
      detail: (
        <span>
          Организация работы в социальных сетях. Создание пабликов в сетях:
          Facebook, Vkontakte, Instagram, Odnoklassniki. Продвижение страниц:
          Платные и бесплатные инструменты. Программы автоматизации: постинга,
          парсинга, увеличения подписчиков. SEO-продвижение. Контекстная
          реклама.
          <br />
          <b>Семинары</b>
        </span>
      )
    },
    {
      short: "Разработка информационной политики",
      detail:
        "Медиапланирование: методы и подходы. Оптимизация размещения по охвату, целевой аудитории, стоимости и другим характеристикам. Примеры медиапланов."
    },
    {
      short: "Медиаконтакт: выступления, личные контакты, работа с аудиторией",
      detail: ""
    },
    {
      short:
        "Организация мероприятий: (пресс-конференций, брифингов, круглых столов)",
      detail: ""
    },
    {
      short: "Журналистский текст",
      detail:
        "Особенности языка, информационные жанры. Заметка, информационное сообщение. Интерактивные тексты, технология создания: интервью. Правка и редактирование текстов. Стилистические, лексические и синтаксические нормы русского языка."
    },
    {
      short:
        "Анализ текстовой продукции. Способы определения качества материалов: социологические опросы, различные подходы к исследованию текстов.",
      detail:
        "Методы анализа текстовой продукции. Выработка навыка использования различных методов анализа текстовой продукции."
    },
    {
      short:
        "Подготовка дополнительных материалов для СМИ: пресс-портфолио (фото),  пресс-релиз, биография, материалы для статей. Технология создания",
      detail: ""
    },
    {
      short: "Спричрайтинг",
      detail:
        "Виды текстовых материалов. Особенности работы над текстом. Технологии подготовки текста. Методы анализа качества текста. Методы анализа эффективности текста."
    },
    {
      short: "Организация и проведение фото-сессии. Технология",
      detail: ""
    },
    {
      short: "Медиаконтакт: Работа на камеру",
      detail:
        "Особенности работы с невидимой аудиторией. Приемы работы на камеру. Преодоление страха эфирного выступления. Техника ответов на вопросы и преодоления возражений. Кризисные ситуации, провокации, как реагировать. «Без комментариев». Видеоблоги и видеоканалы коммуникации. Использование видео- или аудиозаписей, вебинаров и подкастов. Подготовка иллюстраций. Использование фотографий. Видеоинтервью. Лайф-стримы и сториз. 8 основных рубрик и тем видеотрансляций. Что важно во время трансляций. Что нужно делать после трансляций. Практикум «Использование видео, лайф-стримов и сториз»."
    },
    {
      short: "Ньюсмейкинг",
      detail:
        "Примерная структура проектирования события. Герой и история. Другие типы информационных материалов:авторская статья, обзорная статья. Мониторинг других СМИ (тематические досье, клипинг, анализ медиа-образа, пресс-обзоры). Долговременность и кратковременность. Глобальные кампании. Виды долговременных акций (в рамках информационной кампании). Реализация информационной кампании. Примерная структура плана информационной кампании в рамках нбюзмейкинга. Примеры информационных кампаний. Привлечение внимания аудитории. Скандалы: создание и управление."
    },
    {
      short: "Методы анализа эффективности деятельности пресс-секретаря",
      detail:
        "Бизнес-показатели эффективности работы пресс-секретаря. Типичные сложности при оценке взаимодействия со СМИ. Качественные и количественные методы оценки эффективности. Формы отчетности по взаимодействию со СМИ."
    },
    {
      short:
        "Нефинансовая отчетность кампаний и работа пресс-секретаря в рамках ее формирования",
      detail:
        "Понятие нефинансовой отчетности компаний. История вопроса. Российский опыт, разбор кейсов. Особенности работы по формированию PR – кампаний и благотворительной деятельности."
    }
  ];

  return (
    <div style={{ padding: 20, paddingLeft: 48 }}>
      <H2 content="Программа курса" />
      {content.map((element, index) => (
        <Spoler
          short={element.short}
          detail={element.detail}
          color="rgb(96,175,130)"
          key={index}
        />
      ))}
    </div>
  );
}
