import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
// import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Теория и практика концептуальной моды",
    headAfterDescript:
      "Повышение квалификации специалистов индустрии моды и красоты, а также преподавателей по соответствующим направлениям",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 2 месяца, 52 академических часа",
      "Рассрочка: 10 мес. Налоговый вычет",
      "Стоимость курса: 25000 руб."
    ],
    text: "",
    imageSource: "/img/concept-fashion-g/concept-i.jpg",
    video: "hy3YcutF0pw",
    styleHead: {
      fontSize: "3rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: {
    dates: [
      {
        d: dates.conceptFashionG.online.getDate(),
        t: Month(dates.conceptFashionG.online.getMonth()),
        c: "дистанционно"
      }
      // {
      //   d: dates.trendsOfModernFashion.ofline.getDate(),
      //   t: Month(dates.trendsOfModernFashion.ofline.getMonth()),
      //   c: <span>&ensp;очно</span>
      // }
    ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>Для кого</span>
      )
    },
    text:
      "Курс предназначен для тех, кого интересуют современные процессы развития искусства и моды и их проекция на собственные творческие и профессиональные задачи.",
    video: "hy3YcutF0pw"
  },
  text1:
    "Курс посвящен одной из самых актуальных тем, о которой в настоящее время говорят fashion-эксперты и арт-критики, дизайнеры и галеристы, маркетологи и искусствоведы – гибридность форм «мода, искусство, дизайн и технологии», стыком которых и является концептуальная мода, арт-объект, wearable art.",
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель обучения
          </h2>,
          <>
            Цель курса — дать ответы на самые основные вопросы, касающиеся идеи
            и формы концептуализма в моде:
            <br />
            <List
              style={styles.p}
              arr={[
                "в чем суть этого направления и как относится к одежде, которая не принимается и не используется большинством,",
                "каковы признаки концептуальности бренда и где граница между собственно творчеством и успешной маркетинговой стратегией,",
                "в чем сходство и различие концептуальных дизайнеров и брендов,",
                "зачем моде инновационный текстиль, промышленные технологии, Марсель Дюшан и ресайклинг,",
                "о каком будущем говорит костюм и какой интеллектуальный контекст создают дизайнеры."
              ]}
            />
          </>
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <>
            Суть концептуальной моды: о чем, как и для кого
            <br />
            <List
              arr={[
                "источники концептуального подхода к костюму",
                "идеи, а не вещи",
                "костюм — текст и контекст",
                "«креативный индивидуализм», коммерческий и некоммерческий посыл",
                "идея движущейся формы и инновационные технологии: материалы и показы",
                "концептуальный дизайн сфер от-кутюр и прет-а-порте",
                "костюмный энвайронмент, wearable art"
              ]}
              style={styles.p}
            />
          </>,
          "Деконструктивизм как одна из форм проектирования концептуального костюма. Суть метода в искусстве и дизайне.",
          "Способы презентации коллекций на Неделях моды. Перформанс, его виды.",
          <>&emsp;</>,
          <>
            Мировые школы концептуального дизайна:
            <br />
            <List
              arr={[
                "бельгийская школа",
                "датские и нидерландские бренды концептуального дизайна",
                "японский концептуализм и деконструктивизм"
              ]}
              style={styles.p}
            />
          </>,
          <>&emsp;</>,
          <>
            Создатель нового «языка» моды — Мартин Маржела.
            <br />
            <List
              arr={[
                "практичное искусство, одежда об одежде",
                "аноним и яркая личность, авангардист и ремесленник",
                "«коды» и концепты, определившие современную моду",
                "знаковые стилистические решения и последователи в «упрощенной» форме"
              ]}
              style={styles.p}
            />
          </>
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Онлайн семинары с автором",
          "Домашние задания и практика анализа выбранной коллекции"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат обучения
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Понимание роли и места концептуальных брендов в общей системе брендов современной моды",
          "Использование идей концептуальной моды для создания персонального бренда, стилевой стратегии"
        ]}
        num="none"
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 2 месяца, 26 занятий по 2 академ. часа
            <br />
            Формат обучения: все занятия проходят онлайн с преподавателем
            <br />
            Все занятия доступны в записи в личном кабинете
            <br />
            Обучение проводится в мини группе
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты: 25
                  <sup>000</sup> рублей — единовременный платёж
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>2500 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 25
                        <sup>000</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="25000.00"
                            name="Теория и практика концептуальной моды (онлайн-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>,
                "Студентам и выпускникам ВШСДТ предоставляется скидка",
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </div>
      )
    },
    distant: {
      textButton: "Дистанционное обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: сертификат
            <br />
            Длительность: 26 видео занятий по 2 академических часа
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          19
                          <sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>1990 рублей ежемесячный платеж</>,
                      <>
                        итого 10 платежей в сумме — 19
                        <sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="19900.00"
                            name="Теория и практика концептуальной моды (дистанционное-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  }
};
