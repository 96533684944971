import React from "react";
import Grid from "@material-ui/core/Grid";
import { styles } from "./styles";
import List from "../../components/list";

export default function Article() {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      // style={{ padding: 20 }}
    >
      <Grid item xs={12} md={12}>
        <h2 style={styles.h2}>Программа курса</h2>
      </Grid>
      <Grid item xs={12} md={12}>
        <p style={styles.p}>
          Курс-интенсив, посвященный функционированию fashion-индустрии и ее
          симбиозу с медиа в digital-эпоху. Программа практико-ориентирована и
          выстроена на анализе практических глобальных и отечественных кейсов, а
          также прогнозировании и оценочном анализе.
        </p>
        <p style={styles.p}>
          Наряду с этим предусмотрено погружение в необходимую теорию,
          позволяющую вникнуть в суть механизмов функционирования индустрии моды
          в условиях текущих реалий.
        </p>
        <p style={styles.p}>Курс состоит из модулей:</p>
        <List
          arr={[
            "Введение",
            "Глянцевая журналистика: текст и редактирование",
            "Глянцевая журналистика: визуальная составляющая контента. Продакшн и постпродакшен",
            "Практический блок (завершающие проекты студентов)"
          ]}
          num="digital"
          style={styles.p}
        />
        <p style={styles.p}>Основные темы курса:</p>
        <List
          arr={[
            "Иерархия в индустрии моды и ключевые игроки рынка",
            "Бренды и сегментация рынка",
            "Фотография: жанры, стили, тренды 2019",
            "Основные форматы текста — для печатного издания; для онлайн-издания; для соц.сетей",
            "Репутация и рабочая этика в индустрии моды: как построить империю благодаря личным коммуникациям"
          ]}
          style={styles.p}
        />
        <p style={styles.p}>Область, которую охватывает курс</p>
        <List
          arr={[
            "изучение моды как системы с индустриальной и креативной точки зрения;",
            "специфика функционирования традиционных и новых медиа в контексте индустрии моды;",
            "характерные особенности различных форматов медиа в зависимости от медиаплатформ, на которых они представлены и многое другое."
          ]}
          style={styles.p}
        />
        <p style={styles.p}>Курс также обращается к узким темам, таким как</p>
        <List
          arr={[
            "непосредственно написание различных форматов материалов для традиционных и новых медиа;",
            "визуализация в условиях дигитализации;",
            "основные тенденции развития fashion-медиа;",
            "традиционный PR и гибридные форматы продвижения."
          ]}
          style={styles.p}
        />
      </Grid>
    </Grid>
  );
}
