import React from "react";
import { Grid } from "@material-ui/core";
import Helmet from "react-helmet";
import Head from "./head";
import About from "./about";
import For from "./for-whom";
import Programma from "./programma";
import Practica from "./practica";
import Format from "./format";
import Page from "../../components/page";

const Style4Make = () => {
  return (
    <div>
      <Helmet>
        <title>Стилистика для визажистов</title>
        <meta name="keywords" content="ВШС: Стилистика для визажистов" />
      </Helmet>
      <Page lable="Стилистика для визажистов" lableMobile="Бьюти стилистика">
        <Grid container>
          <Grid item xs={12} md={12}>
            <Head />
            <About />
            <For />
            <Programma />
            <Practica />
            <Format />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
};

export default Style4Make;
