import React from "react";
import Link from "react-router-dom/Link";

export const graduates = [
  {
    photo: "/img/about/rozhnova-sm.jpg",
    text: (
      <>
        <h3>Рожнова Жанна Сергеевна</h3>
        <p>Практикующий fashion стилист, преподаватель ВШСТД</p>
        <Link to="/graduates/rozhnova">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Жанна Рожнова"
  },
  {
    photo: "/img/about/akimova-sm.jpg",
    text: (
      <>
        <h3>Акимова Лидия Владимировна</h3>
        <p>Практикующий имидж-стилист/блогер/преподаватель ВШСДТ.</p>
        <Link to="/graduates/akimova">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Лида Акимова"
  },
  {
    photo: "/img/about/kuzina-sm.jpg",
    text: (
      <>
        <h3>Кузина Татьяна Олеговна</h3>
        <p>
          Практикующий стилист-имиджмейкер, fashion эксперт. Являюсь куратором
          совместного проекта ВШСДТ и ТЕХНОГРАД (инновационного комплекса на
          ВДНХ, предлагающего образовательные курсы по государственной
          программе)
        </p>
        <Link to="/graduates/kuzina">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Татьяна Кузина"
  },
  {
    photo: "/img/career/sotnikova-sm.jpg",
    text: (
      <>
        <h3>Сотникова Алина Сергеевна</h3>
        <p>
          Практикующий имидж-стилист, стилизую индивидуальные и творческие
          съёмки. SMM- менеджер ВШСДТ, занимаюсь развитием социальных сетей
          школы
        </p>
        <Link to="/graduates/sotnikova">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Сотникова Алина Сергеевна"
  },
  {
    photo: "/img/graduates/batalova-01-sm.jpg",
    text: (
      <>
        <h3>Баталова Татьяна Игоревна</h3>
        <p>Практикующий стилист-имиджмейкер</p>
        <Link to="/graduates/batalova">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Баталова Татьяна Игоревна"
  },
  {
    photo: "/img/graduates/gilyova-anna-sm.jpg",
    text: (
      <>
        <h3>Гилёва Анна Викторовна</h3>
        <p>
          Практикующий имидж-стилист, преподаватель ВШСДТ Основы юридических
          знаний для стилиста, куратор Ассоциации выпускников ВШСДТ
        </p>
        <Link to="/graduates/gilyova">
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </>
    ),
    photoAlt: "Гилёва Анна Викторовна"
  },
  {
    photo: "/img/teachers/320x240/olga-maltseva.jpg",
    text: (
      <>
        <h3>Ольга Мальцева</h3>
        <p>
          Консультант по имиджу. Практикующий стилист-имиджмейкер. Окончила
          высшую школу стилистики, а также прошла дополнительные курсы повышения
          квалификации. В своей работе использует метод подробного анализа
          внешности, образа жизни и характера клиента, составляя его
          индивидуальную формулу стиля.
        </p>
      </>
    ),
    photoAlt: "Ольга Мальцева"
  }
  /*
   {
    photo: "",
    text: (<></>),
    photoAlt: ""
  },
  */
];
