import React from "react";
import Card from "../card";
import { dates, DateToString, TimeToString } from "../../shedule/dates";

export default function CardModern(props) {
  return (
    <Card
      head="Мода и стиль"
      title="Chanel"
      teacher="Руслан Мигранов"
      descript={<span>Лекция онлайн</span>}
      onlineText="Лекция онлайн"
      descriptString
      onlineIcon
      date={DateToString(dates.masterClasses.chanel, true)}
      time={TimeToString(dates.masterClasses.chanel)}
      duration=""
      image="/img/master-class/chanel.jpg"
      text={
        <span>
          <b>Chanel</b>
          <br />В этой лекции мы проследим творческий путь Coco Chanel от ее
          увлечения дизайном женских шляпок до поездки в Голливуд в начале 30-х
          и далее до сексуальной революции. Как менялась мода от эпохи модерна
          до «свингующих шестидесятых» и почему Chanel сопутствовал успех. Путь
          с подмостков сцены до всемирной славы! Правда ли, что именно Chanel
          была законодательницей моды на загар? И в чем просматривается русский
          след при создании самых знаменитых духов №5? Затронем ее дружбу с
          Дягилевым и другими русскими и их влияние на ее творчество. Самые
          знаменитые творения великой мадемуазель и источники их вдохновлявшие.
          Долгий роман с журналом Voguе и его роль в ее карьере. Правда ли, что
          именно Chanel была законодательницей моды, на ношение брюк? Ее
          отношения с русскими манекенщицами. Почему Chanel не любила и не
          признавала мини-юбки. Чем вдохновлялась, при создании своего
          знаменитого твидового костюма и знаменитой сумочки 2.55. Самые
          знаменитые творения великой мадемуазель и источники их вдохновлявшие.
          Все это и многое другое о творчестве Chanel, вы узнаете из этой
          лекции!
        </span>
      }
      q
      part
      noView
      free
      partPrice="0"
      color="rose"
    />
  );
}
