import React from "react";

import Paragraph from "../../components/p-array";
import Blog from "./template";

export default function Myslitel() {
  return (
    <Blog
      title="Андреева А.Н. Дизайнерские бренды в фэшн-бизнесе. Авторское пособие «fashion-индустрии»"
      descript="Автор пособия «Дизайнерские бренды в фэшн-бизнесе» дает свое видение основ концепции портфеля дизайнерских брендов в fashion-бизнесе. Актуальность книги несомненна, т.к. в ней рассматривается обширный круг тем, связанный с дизайнерскими брендами и их управлением: вводятся базовые определения – fashion-индустрии, fashion-бизнеса, дизайнерских брендов, анализируются структура модного бизнеса через наиболее показательные примеры (кейсы) крупнейших мировых модных брендов: LVMH, Gucci Group, Armani Group, Versace."
      keywords="бренды, fashion, бизнес, fashion индустрия, дизайнер, стиль"
      head="Андреева А.Н. Дизайнерские бренды в фэшн-бизнесе."
      author="Наталья Горских"
      link="/natalya_gorskyh"
      image="/img/blog/book-andreeva.jpg"
      content={
        <Paragraph
          content={[
            "Автор дает свое видение основ концепции портфеля дизайнерских брендов в fashion-бизнесе. Актуальность книги несомненна, т.к. в ней рассматривается обширный круг тем, связанный с дизайнерскими брендами и их управлением: вводятся базовые определения – fashion-индустрии, fashion-бизнеса, дизайнерских брендов, анализируются структура модного бизнеса через наиболее показательные примеры (кейсы) крупнейших мировых модных брендов: LVMH, Gucci Group, Armani Group, Versace."
          ]}
        />
      }
    />
  );
}
