import { Grid, Paper } from "@material-ui/core";
import React from "react";
import Helmet from "react-helmet";
import Link from "react-router-dom/Link";
import Page from "../../components/page";
import { TitleH1, TitleH2 } from "../../patterns/pattern-fs/components";
import { content, articles } from "./content";
import "./style.css";

export function ListCards(props) {
  const content = props.content;
  return (
    <Grid container justify="flex-start" alignItems="stretch" spacing={3}>
      {content.map(el => (
        <Grid item xs={12} md={3}>
          <Link to={el.link}>
            <Paper className="blog-paper">
              <div className="blog-image-block">
                <img src={el.cover} alt={el.title} />
              </div>
              <h3 className="blog-h3">{el.title}</h3>
              <p className="">{el.descript}</p>
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default function Blog(props) {
  return (
    <>
      <Helmet>
        <title />
        <meta name="keywords" content="" />
        <meta name="descript" content="" />
      </Helmet>
      <Page lable="Публикации">
        <TitleH1
          content="Рецензии. Литература. Публикации"
          style={{ textAlign: "center" }}
        />
        <TitleH2 content="Литература" />
        <ListCards content={content} />
        <TitleH2 content="Публикации" />
        <ListCards content={articles} />
        <div style={{ height: "3rem" }} />
      </Page>
    </>
  );
}
