import React from "react";
import List from "../../components/list";
import { styles } from "./styles";

const ForWhom = function(props) {
  return (
    <div>
      <p style={styles.p}>Курс разработан для тех, кто:</p>
      <List
        arr={[
          "хочет стать автором глянцевых изданий – писать для различных fashion-медиа и построить карьеру независимого автора",
          "стать редактором моды (культуры /красоты) в глянцевом издании",
          "заниматься SMM и сотрудничать с брендами одежды, аксессуаров и ювелирных изделий в качестве SMM-специалиста или контент-менеджера",
          "имеет собственную марку и самостоятельно занимается формированием контента",
          "является начинающим фешн- / бьюти-блогером и планирует прокачать свои навыки создания текстового и визуального контента"
        ]}
        style={styles.p}
      />
      <p style={styles.p}>
        Курс также будет интересен тем, кто питает любовь к индустрии моды, но
        пока ещё не направил свои силы и знания в конструктивное русло и
        находится в поиске своего предназначения
      </p>
    </div>
  );
};

export default ForWhom;
