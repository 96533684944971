import React from "react";
import Button from "../button-messages";
import "./style-price.css";

export function Discount(props) {
  return (
    <div className="t-disk">
      <p className="t-discount">{props.discount}</p>
      <p className="t-duration">{props.discountDuration}</p>
    </div>
  );
}

export function BlockAndBlock(props) {
  return (
    <div className="t-block-block">
      <div className="t-left-bb">{props.left}</div>
      <div className="t-right-bb">{props.right}</div>
    </div>
  );
}

export function TextAndPrice(props) {
  return (
    <div className="t-pr-els">
      <p className={`t-${props.type}`}>{props.text}</p>
      <p className={`t-${props.type}-pr`}>
        {props.price}
        {props.type === "installment" && (
          <span className="t-pr-text">&ensp;/&ensp;мес.</span>
        )}
      </p>
    </div>
  );
}

export function Prices(props) {
  return (
    <div className="t-price">
      {/* <h2>{props.title}</h2> */}
      <TextAndPrice
        type="installment"
        text={
          "Рассрочка на " + (props.durationInstallment || "12") + " месяцев"
        }
        price={props.installment}
      />
      <BlockAndBlock
        left={
          props.discount ? (
            <TextAndPrice
              type="discount"
              text="Стоимость со скидкой"
              price={props.discount}
            />
          ) : (
            <TextAndPrice
              type="discount"
              text="Полная стоимость"
              price={props.full}
            />
          )
        }
        right={
          props.discount ? (
            <TextAndPrice
              type="full"
              text="Полная стоимость"
              price={props.full}
            />
          ) : (
            <>&emsp;</>
          )
        }
      />
      {props.start && (
        <p className="t-installment">Старт курса: {props.start}</p>
      )}
    </div>
  );
}

export function Installment(props) {
  return (
    <div className="t-installment">
      <h3>{props.title}</h3>
      <div className="t-qr-code">
        <img src={props.qr} />
      </div>
      <a href={props.link}>
        <div className="t-btn-installment">оплатить в рассрочку</div>
      </a>
      <p className="t-descript">{props.descript}</p>
    </div>
  );
}

export function InstallmentNoQR(props) {
  return (
    <div className="t-installment">
      <h3>Оплата курса</h3>
      <p>
        {props.textPercent || (
          <>
            Производиться единовременно или в рассрочку на{" "}
            {props.durationInstallment}
            &nbsp;месяцев
          </>
        )}
      </p>
      <h3>Спец. условия</h3>
      <p>
        Для студентов и преподавателей ВУЗов действуют специальные условия
        оплаты
      </p>
      {props.noPartners ? null : (
        <>
          <h3>Рассрочка от банка-партнера</h3>
          <p>
            {props.bankText || (
              <>
                Мы сотрудничаем с Банком Тинькофф, рассрочка на{" "}
                {props.durationInstallment}
                &nbsp;месяцев без первого взноса, без переплат доступна для
                граждан РФ.
              </>
            )}
          </p>
        </>
      )}
    </div>
  );
}

export function PriceNoQR(props) {
  const { content } = props;
  return (
    <>
      <h2 className="t-sh-mob">{content.title}</h2>
      <div className={content.noButtons ? "t-pr-cont-nButton" : "t-pr-cont"}>
        <div className="t-left">
          <Prices
            title={content.title}
            installment={content.installment}
            discount={content.discount}
            full={content.full}
            start={content.start}
            durationInstallment={content.durationInstallment}
          />
          {/* <p className="t-medium c pt"> */}
          {content.afterDescript ? (
            <p className="t-descript pt">{content.afterDescript}</p>
          ) : null}
        </div>
        <div className="t-medial">
          <Discount
            discount={content.discountSize}
            discountDuration={content.discountDuration}
          />
        </div>
        <div className="t-right">
          <InstallmentNoQR
            durationInstallment={content.durationInstallment}
            textPercent={content.textPercent}
            bankText={content.bankText}
            noPartners={content.noPartners}
          />
        </div>
        {content.noButtons ? null : (
          <>
            <div className="t-button1">
              <Button
                linkType="component"
                componentLink={<div className="button">записаться</div>}
              />
            </div>
            {content.noButtonInstallment ? null : (
              <div className="t-button2">
                <a href={content.qrLink} target="_blank">
                  <div className="button">оплатить в рассрочку</div>
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default function Price(props) {
  const { content } = props;
  return (
    <>
      <h2 className="t-sh-mob">{content.title}</h2>
      <div className="t-pr-cont">
        <div className="t-left">
          <Prices
            title={content.title}
            installment={content.installment}
            discount={content.discount}
            full={content.full}
            start={content.start}
            durationInstallment={content.durationInstallment}
            textPercent={content.textPercent}
            bankText={content.bankText}
            noButtonInstallment={content.noButtonInstallment}
          />
          {/* <p className="t-medium c pt"> */}
          <p className="t-descript pt">
            {content.afterDescript ||
              "* Для единовременной оплаты курса оформите заявку на обучение ниже"}
          </p>
        </div>
        <div className="t-medial">
          <Discount
            discount={content.discountSize}
            discountDuration={content.discountDuration}
          />
        </div>
        <div className="t-right">
          <Installment
            // start={content.start}
            title={content.title2block}
            qr={content.qr}
            link={content.qrLink}
            descript={content.descript}
          />
        </div>
      </div>
    </>
  );
}
