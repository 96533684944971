import React from "react";
import { Link } from "react-router-dom";
import List from "../../components/list";
import { dates, Month } from "../shedule/dates";
import Button from "../../components/button-messages";

export const contents = {
  titlePage: "Сам себе дизайнер. Мастерская идей для жилых интерьеров",
  head: {
    headDescript: "Теория и практика",
    head: (
      <>
        Сам себе дизайнер.
        <br />
        Мастерская идей для жилых интерьеров
      </>
    ),
    headAfterDescript: (
      <>
        Практический курс по планированию пространства и визуализации идей своей
        квартиры от преподавателей программ профессиональной переподготовки{" "}
        <Link to="/living-interior-design-and-decoration">
          «Дизайн и декорирование интерьера»
        </Link>{" "}
        и <Link to="/interior-design">«Интерьерный стилист»</Link>
      </>
    ),
    descript: [
      "Очно, дистанционно",
      "Бонус: персональные консультации дизайнера",
      "Идеи для среды обитания уже в 1-й месяц занятий",
      "Живое общение, открытый чат",
      <>Поддержка после обучения</>
      // <>Рассрочка без переплат от 500 &#8381; в месяц</>
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/imagemaker-stylist/consulting-style-design.jpg",
    styleImage: {
      maxHeight: 450
    },
    spaceHeadText: 32,
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    },
    insideContacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        <>
          Онлайн с 5 июня
          {/*dates.selfStyle.online.getDate() +
            " " +
          Month(dates.selfStyle.online.getMonth())*/}
        </>,
        <>
          Очно с 9 июня
          {/*dates.selfStyle.ofline.getDate() +
            " " +
          Month(dates.selfStyle.ofline.getMonth())*/}
        </>
      ]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции,</>,
        <>чат в группе, очная практика</>
      ]
    },
    {
      title: "Видео курс",
      text: ["Доступен видео курс", "для самостоятельного изучения"]
    }
  ],
  text1: {
    text: (
      <>
        <h3 className="p-constructor bold">
          Что вы получите по результатам курса?
        </h3>
        <List
          arr={[
            <>
              Styleboard с персо­&shy;наль­ной палит­рой, подхо­дя&shy;щими
              интерь&shy;ерами и ме&shy;бе&shy;лью
            </>,
            <>
              Свободу от ненуж­ных трат и оши&shy;боч&shy;ных реше&shy;ний при
              пла&shy;ни&shy;рова&shy;нии ремонта
            </>,
            <>Навык осознанного выбора решений по цвету, стилю, эргономике</>,
            <>
              Алгоритм контроля смет и рег&shy;ла&shy;мен&shy;тов для
              под&shy;ряд&shy;чиков
            </>,
            <>
              Возможность помогать подру&shy;­гам и давать сове&shy;ты по
              интерь&shy;ерам
            </>
          ]}
          classLi="p-constructor"
        />
      </>
    ),
    image: [
      {
        i: "/img/interior-stylist-decorator/slide01.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide02.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide03.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide04.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide05.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide06.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide07.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide08.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      },
      {
        i: "/img/interior-stylist-decorator/slide09.jpg",
        o: 0,
        alt: "Сам себе дизайнер"
      }
    ]
    // (
    //   <div className="image-bkg" style={{ backgroundImage: "none" }}>
    //     <img
    //       src="/img/self-style/i01.jpg"
    //       alt="Сам себе стилист"
    //       className="h"
    //       // style={{height: 240, width: 320}}
    //     />
    //   </div>
    // )
  },
  howIsTheTrainingGoing: [
    {
      title: "Видео уроки",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;ча­ете видео уроки в сво&shy;бод&shy;ном,
          ком&shy;­форт&shy;­ном ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          После каждого урока полу&shy;­чаете до&shy;маш­&shy;ние зада&shy;ния
          по эле&shy;мен&shy;там своего стиля.
        </>
      )
    },
    {
      title: "Онлайн общение",
      text: (
        <>
          Регулярно — живое обще&shy;­­ние, помощь в соз&shy;дании обра&shy;зов
          на каждый день
        </>
      )
    },
    {
      title: "Книга идей",
      text: (
        <>
          В итоге обуче&shy;ния Вы созда&shy;­ете персо&shy;наль&shy;ную Книгу
          идей — mini stylebook
        </>
      )
    }
  ],
  teachers: [
    {
      image: "/img/teachers/kostarnova.jpg",
      name: <>Марина Костарнова (Бельгия), преподаватель online курса</>,
      text: (
        <>
          <p>Дизайнер & декоратор & coach</p>
          <p>Практик и теоретик дизайна</p>
          <p>Специалист в области концептуального средового дизайна</p>
          <p>Член международного союза дизайнеров</p>
        </>
      ),
      instagram: "mara_van_brussels",
      buttons: {
        detail: "/marina_kostarnova",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/lamonova_g.jpg",
      name: "Елена Ламонова (Москва), преподаватель очного курса",
      text: (
        <>
          Дизайнер. Специалист в области интерь&shy;ерного, ландшафтного,
          предмет&shy;ного ди&shy;зайна, создатель концепций и проектов
          оформ&shy;ления меро&shy;приятий всерос&shy;сий&shy;ского уровня,
          создатель собст&shy;венного дизайн бюро, преподаватель Высшей школы
          стилистики
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/elena-lamonova",
        linkInside: true
      }
    }
  ],
  curent: {
    text: (
      <>
        <p className="p-constructor">
          <strong>Изучайте видео уроки и советуйтесь со стилистом</strong>
        </p>
        <p className="p-constructor">
          Оплатившим курс доступны консультации по дизайну интерьера. На
          консультации Вы получите рекомендации по определению оформления, а так
          же рекомендации по подходящим Вам материалам, мебели, световым
          решениям, трендовым предметам интерьера и новым идеям.
        </p>
        <p className="p-constructor">
          А еще мы включим Вас в группу, где Вы будете получать информацию о
          новых мастер-классах и задавать инте­ресую­щие вопросы по стилю
          интерьера автору курса и другим практикующим дизайнерам
        </p>
      </>
    ),
    image: (
      <div
        className="image-bkg"
        style={{ backgroundImage: "url('/img/self-style/batalova-i01.jpg')" }}
      >
        <img
          src="/img/self-style/batalova-i01.jpg"
          alt="Стилист Баталова Татьяна"
          className="v"
        />
      </div>
    )
  },
  insertAfterDates: [
    {
      title: "Видео курс",
      gray: (
        <>
          <strong>6600 &#8381;</strong>
        </>
      ),
      text: (
        <>
          <List
            arr={[
              <>9 часов видео уроков (12 тем)</>,
              <>Просмотр занятий в удобное время</>,
              <>Домашние задания</>,
              <>Доступ к курсу в течение года после покупки</>,
              <>Персональная консультация стилиста</>,
              <>Доступ к курсу сразу после оплаты</>,
              <>Доступна рассрочка на 12 месяцев</>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    },
    {
      title: "Online курс",
      gray: (
        <>
          <strong>25000 &#8381;</strong>
        </>
      ),
      text: (
        <>
          <List
            arr={[
              <>9 часов видео уроков (12 тем)</>,
              <>Просмотр занятий в удобное время</>,
              <>Домашние задания</>,
              <>Проверка домашних заданий</>,
              <>9 часов онлайн консультаций</>,
              <>Живое общение онлайн в мини группе</>,
              <>Обратная связь по каждому ДЗ</>,
              <>Доступ к курсу в течение года после покупки</>,
              <>Общение с коллегами в закрытой группе</>,
              <>Сертификат после обучения</>,
              <>Доступна рассрочка на 12 месяцев</>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    },
    {
      title: "Очный курс",
      gray: <strong>32000 &#8381;</strong>,
      text: (
        <>
          <List
            arr={[
              <>12 оффлайн встреч по полтора часа</>,
              <>Включая подбор новых образов на шопинге</>,
              <>Проверка домашних заданий</>,
              <>Живое общение оффлайн в мини группе</>,
              <>Обратная связь по каждому ДЗ</>,
              <>Доступны 9 часов видео уроков (12 тем)</>,
              <>Доступ к курсу в течение года после покупки</>,
              <>Общение с коллегами в закрытой группе</>,
              <>Сертификат после обучения</>,
              <>Доступна рассрочка на 12 месяцев</>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    }
  ],
  tilesProgramma: [
    {
      image: {
        image: "/img/self-style/less-01.jpg",
        alt: "Занятие 1",
        vertical: true
      },
      title: "Занятие 1",
      text: <>Дом мечты с нуля</>
    },
    {
      image: {
        image: "/img/self-style/less-02.jpg",
        alt: "Занятие 2",
        vertical: true
      },
      title: "Занятие 2",
      text: <>Стилистика интерь&shy;ера и цвето&shy;вые соче&shy;тания</>
    },
    {
      image: {
        image: "/img/self-style/less-03.jpg",
        alt: "Занятие 3",
        vertical: true
      },
      title: "Занятие 3",
      text: (
        <>
          Текстуры и сов&shy;ре&shy;мен&shy;ные мате&shy;ри&shy;алы для
          интерь&shy;ера
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-04.jpg",
        alt: "Занятие 4",
        vertical: true
      },
      title: "Занятие 4",
      text: <>Как найти тему и идею интерьера</>
    },
    {
      image: {
        image: "/img/self-style/less-05.jpg",
        alt: "Занятие 5",
        vertical: true
      },
      title: "Занятие 5",
      text: (
        <>
          Планировка и эрго&shy;но&shy;мика комфорт&shy;ного прос&shy;транства
        </>
      )
    },
    {
      image: {
        image: "/img/self-style/less-06.jpg",
        alt: "Занятие 6",
        vertical: false
      },
      title: "Занятие 6",
      text: <>Графическое офор&shy;мле&shy;ние идеи интерь&shy;ера в коллаже</>
    },
    {
      image: {
        image: "/img/self-style/less-07.jpg",
        alt: "Занятие 7",
        vertical: true
      },
      title: "Занятие 7",
      text: (
        <>Программы для гра&shy;фи&shy;чес&shy;кого вопло&shy;ще&shy;ния идеи</>
      )
    },
    {
      image: {
        image: "/img/self-style/less-08.jpg",
        alt: "Занятие 8",
        vertical: true
      },
      title: "Занятие 8",
      text: (
        <>
          Проектная до&shy;ку&shy;мен&shy;тация для реали&shy;за&shy;ции
          про&shy;екта
        </>
      )
    }
  ],
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой"
};
