import React from "react";
import { style } from "./styles";
import List from "../../components/list";
import Description from "../../components/descript4courses";
import { withStyles } from "@material-ui/styles";

const styles = theme => style;

function Descript(props) {
  const { classes } = props;
  return (
    <Description
      block1={
        <div style={{ paddingBottom: "1.4em" }}>
          <p className={classes.p}>
            На курсе-интенсиве «Руководство по продвижению модного бренда на
            глобальном рынке» вы узнаете как вывести торговую марку / бренд /
            услугу на международный рынок. Как вести деловые переговоры. На
            каких мировых площадках представлять бренд / торговую марку. Правила
            организации и участия в профессиональных мероприятиях. Узнаете
            секреты мирового нетворкинга, и как с помощью эффективных
            коммуникаций сделать свой бизнес успешным и прибыльным..
          </p>
          <h3 className={classes.h3}>Для кого:</h3>
          <List
            arr={[
              "Предприниматели индустрии моды и дизайна, которые планируют выходит на международный рынок",
              "Представители fashion индустрии и бизнес сообщества",
              "Люди, которые хотят занять свое место в индустрии моды и дизайна",
              "Дизайнеры, у которых есть продукт или идея в сфере моды и дизайна",
              "Рекламисты, PR-специалисты, маркетологи",
              "Стилисты / Имиджмейкеры…",
              "Байеры"
            ]}
            style={style.p}
          />
        </div>
      }
      block2={
        <div>
          <h3 className={classes.h3} style={{ paddingTop: 0, marginTop: 0 }}>
            Что будет:
          </h3>
          <List
            arr={[
              "Профессиональные B2B и B2C мероприятия, как способ презентации модного бренда (международные выставки, ярмарки, презентации, показы, работа с шоу-румами, организация специальных мероприятий)",
              "Принципы организации международных мероприятий и принципы участия в них.",
              "Подготовка визуальной концепции бренда / Атрибуты бренда",
              "Прогнозирование трендов в fashion бизнесе",
              "Позиционирование бренда",
              "Разработка стратегии презентации бренда на мировом рынке",
              "Бюджет",
              "Международные коллаборации / кросс-маркетинг (разбор кейсов)",
              "Правила и секреты бизнес-нетворкинга"
            ]}
            style={style.p}
          />
        </div>
      }
      img="/img/fashion-brand-promotion-gid/i01.jpg"
    />
  );
}

export default withStyles(styles)(Descript);
