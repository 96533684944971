import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Page from "../../components/page";
import Header from "./header";
import About from "./about";
import Price from "./price";
import Programma from "./programma";
import Teacher from "./teacher";
import { HorizontalForm } from "../../components/forms";
import Banners from "./banners";

export default function Component() {
  const title = "Модный ассортимент";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="keywords" content={`ВШС: ${title}`} />
      </Helmet>
      <Page lable={title} lableMobile={title}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12}>
            <Header />
            <About />
            <Price />
            <Programma />
            <Teacher />
            <HorizontalForm />
            <Banners />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
}
