import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
// import { Link } from "react-router-dom";
import { dates, Month } from "../shedule/dates";
import Paragraph from "../../components/p-array";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Костюм «для дела»: правила новой публичности",
    headAfterDescript:
      "Деловой стиль, деловой костюм, деловая репутация — факторы успешности. Получите актуальные знания для дела",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 1 месяц, 18 академических часа",
      "Рассрочка: 10 мес. Налоговый вычет",
      "Стоимость курса: 9900 руб."
    ],
    text: "",
    imageSource: "/img/business-style-g/business-style.jpg",
    video: "mWjpmmp17vc",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: {
    dates: [
      {
        d: dates.businessStyle.online.getDate(),
        t: Month(dates.businessStyle.online.getMonth()),
        c: "дистанционно"
      }
      // {
      //   d: dates.trendsOfModernFashion.ofline.getDate(),
      //   t: Month(dates.trendsOfModernFashion.ofline.getMonth()),
      //   c: <span>&ensp;очно</span>
      // }
    ],
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>Для кого</span>
      )
    },
    text: (
      <List
        arr={[
          "Для бизнесменов и предприимателей",
          "Для руководителей",
          "Для перспективных сотрудников",
          "Для Вас, кто понимает, что одним из каналов успешных коммуникаций является стиль в одежде и его рациональное применение"
        ]}
      />
    ),
    video: "mWjpmmp17vc"
  },
  text1: (
    <Paragraph
      content={[
        "Зачем говорить о деловом стиле в одежде, если в современном мире все чаще отменяются стандарты и не соблюдаются нормы даже в когда-то строго регламентированных областях деятельности? Если творческий подход, как к одежде, так и к решению профессиональных задач стал привычной характеристикой нашего времени?",
        "Однако, есть негласные правила и внутренние установки, которые никто не отменял. Роль и возможности костюма для самопрезентации и коммуникации в любой профессии или собственного бизнес-проекта, не стоит как переоценивать, так и недооценивать."
      ]}
    />
  ),
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель обучения
          </h2>,
          "Цель курса – дать Вам понять, какая стилевая позиция может стать для Вас правильным каналом профессиональной коммуникации и почему это позволит получить стратегическое преимущество.",
          "Курс лишен назидательности и однозначности в оценке, что можно, а что нельзя. Если мы и будем говорить о нормах и правилах костюмного образа, то только в контексте их целесообразности для создания индивидуального и одновременно профессионального текста о себе."
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Причины изменения и разрушения системы делового стиля в одежде.",
          <>
            Философия дресс-кода в современном мире:
            <br />
            <List
              arr={[
                "Запреты или свобода",
                "Отъединенность и вовлеченность",
                "Индивидуальность и социальность"
              ]}
              style={styles.p}
            />
          </>,
          "Концепции выбора «одежды для дела»",
          "Связь «человек-костюм-профессия» и ее признаки для разных видов деятельности",
          <>
            Классика – показатель прошлого или будущего? Суть и ее преимущества
            в эпоху «без правил»
            <br />
            <List
              arr={["Эволюция мужской классики", "Эволюция женской классики"]}
              style={styles.p}
            />
          </>,
          "Размывание границ: условно-деловой стиль. Суть и показатели",
          "Коммуникация как профессия. Суть и показатели",
          "Творчество как профессия. Суть и показатели",
          "Специфика российской организационной культуры и ментальных установок."
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        В процессе обучения
      </h2>,
      <List
        style={styles.p}
        arr={[
          "Онлайн семинары с автором",
          "Домашние задания и практика формирования делового стиля",
          "Консультации автора"
        ]}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Результат обучения
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "Гибкое владение инструментами подбора стиля для любой профессии",
          "Концепция делового стиля обучающегося"
        ]}
        num="none"
      />
    ]
  },
  teachers: [
    {
      image: "/img/about/natalya-gorskih.jpg",
      name: (
        <>
          Автор и ведущая курса:
          <br />
          Наталья Горских
        </>
      ),
      text:
        "К.филол.наук, преподаватель, специалист в области современных коммуникаций посредством визуального образа: художественного, модного, социального.",
      instagram: false,
      buttons: {
        detail: "/natalya_gorskyh",
        linkInside: true
      }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 1 месяц, 9 занятий по 2 академ. часа
            <br />
            Формат обучения: все занятия проходят онлайн с преподавателем
            <br />
            Все занятия доступны в записи в личном кабинете
            <br />
            Обучение проводится в мини группе
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты:{" "}
                  <strong>
                    19
                    <sup>900</sup> рублей
                  </strong>{" "}
                  — единовременный платёж
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>1990 рублей</strong> ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 19
                        <sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="19900.00"
                            name="Костюм «для дела»: правила новой публичности (онлайн-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>,
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </div>
      )
    },
    distant: {
      textButton: "Дистанционное обучение",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: сертификат
            <br />
            Длительность: 9 видео занятий по 2 академических часа
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          9<sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>990 рублей</strong> ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 9<sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="9900.00"
                            name="Костюм «для дела»: правила новой публичности (дистанционное-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  }
};
