import React from "react";
import Banner, { BannerBadge } from "./banner";

export default function Component(props) {
  const text = false;
  const slide = (
    <BannerBadge>
      <img
        src="/img/calendar.svg"
        alt=""
        style={{ height: 16, marginBottom: -3 }}
      />{" "}
      3.5&nbsp;мес.
    </BannerBadge>
  );

  return (
    <Banner
      head={
        <span>
          Интерьерный стилист
          <br />
          Декоратор
        </span>
      }
      bkgImage="url(/img/banners/interior-your-life.jpg)"
      bkgHead="rgba(209,200,232,1.0)"
      content={text}
      slide={slide}
      link="/interior-your-life"
    />
  );
}
