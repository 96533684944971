import React from "react";
import "./style.css";

export function TileImages(props) {
  return (
    <div className="tile-image-container">
      {props.content.map(el => (
        <div className="tile-image-item">
          <div className="image">
            <img src={el.image} alt={el.text} />
          </div>
          <div className="text">{el.text}</div>
        </div>
      ))}
    </div>
  );
}
