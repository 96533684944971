import React from "react";
import List from "../../components/list";

export function Schedule(props) {
  const c = props.content;

  return (
    <>
      {c.map(el => (
        <div style={{ marginBottom: "2rem" }}>
          <h3>{el.title}</h3>
          <List arr={el.list} num="none" classLi="p-constructor" />
        </div>
      ))}
    </>
  );
}
