import React from "react";
// import { Grid, Paper } from "@material-ui/core";

export default function Short() {
  return (
    <div>
      <h3
        style={{
          textAlign: "center",
          fontSize: "1.4em",
          fontWeight: 700,
          color: "rgb(110,110,110)"
        }}
      >
        Курс: 3 месяца | 24 занятия | 96 академ.&nbsp;часов | ОЧНО | ONLINE
      </h3>
    </div>
    // <Grid
    //   container
    //   justify="center"
    //   alignItems="center"
    //   style={{ padding: 20 }}
    // >
    //   <Grid item xs={12} md={4} style={{ padding: 20 }}>
    //     <Paper style={{ padding: 10 }}>
    //       <p style={styles.title}>Регулярность занятий</p>
    //       <p style={styles.text}>2 раза в неделю по 4 академ.ч.</p>
    //     </Paper>
    //   </Grid>
    //   <Grid item xs={12} md={4} style={{ padding: 20 }}>
    //     <Paper style={{ padding: 10 }}>
    //       <p style={styles.title}>Длительность</p>
    //       <p style={styles.text}>3 месяца, 24 занятия, 96 академ.ч.</p>
    //     </Paper>
    //   </Grid>
    //   <Grid item xs={12} md={4} style={{ padding: 20 }}>
    //     <Paper style={{ padding: 10 }}>
    //       <p style={styles.title}>Ближайший набор</p>
    //       <p style={styles.text}>март 2018</p>
    //     </Paper>
    //   </Grid>
    // </Grid>
  );
}
