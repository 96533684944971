import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="Мода и стиль"
      title="Шить или покупать?"
      teacher="Светлана Михаленко"
      descript={<span>Онлайн-лекция</span>}
      onlineText="Онлайн-лекция"
      date=""
      time="20:00"
      duration="45 минут"
      image="/img/modern-fashion-style/autor.jpg"
      text={
        <span>
          На пороге запуска моего собственного проекта, я столкнулась с
          дилеммой: что же я, в итоге, открываю: магазин, с возможностью шить
          одежду персонально, или всё-таки ателье, с магазином при нём.
          <br />В эру консюмеризма и он-лайн продаж премиум-товаров настоящей
          роскошью становятся timeless fashion. Одежда вне времени,
          произведенная штучно. Одежда, которую нужно ждать. Однако, как
          избавиться от советских стереотипов об ателье? Как спрогнозировать
          конечный результат? Как получать то, что хотелось, а не то, что
          получилось? Как избавиться от страха, что вся одежда из ателье –
          кустарная? И главное: что именно стоит шить, а что – покупать,
          расскажу на вебинаре.
        </span>
      }
      free
      view
      id="MpRRCCA968sAuWe5a"
      setBillingId={props.setBillingId}
    />
  );
}
