import React from "react";

export const migranov = {
  title: "Руслан Мигранов",
  text: (
    <>
      Историк моды, куратор МВЦ «Музей Моды» в Москве, член творческого союза
      дизайнеров и имиджмейкеров, преподаватель «Высшей школы стилистики,
      дизайна и технологий», визуальный мерчендайзер, витринист. Ведущий лекций
      по теме «История моды XX века» в рамках программы профессиональной
      переподготовки «Современные технологии в работе имиджмейкера: персональный
      и корпоративный стиль».
      <br />
      Преподаватель курса «Визуальный мерчендайзинг и витринистика», ведущий
      отдельных мастер-классов по моде и витринистике, а также выездных
      арт-туров в Венецию «Мода и искусство Венеции», Флоренцию и Париж.
    </>
  ),
  link: "/ruslan-migranov",
  image: "/img/teachers/320x240/ruslan-migranov.jpg"
};

export const zhilova = {
  title: "Жилова Эмилия Игоревна",
  text:
    "Мужской стилист-имиджмейкер, beauty/fashion фотограф, продюсер фотосъемок, колумнист Designers fromRussia, преподаватель Высшей школы стилистики. Преподаватель дисциплины, связанные с техническими, творческими и философскими аспектами фотографии, освещает актуальные особенности визуального контента, о продюсировании съемок и о мужском имидже и стиле.",
  link: "/emilia_zhilova",
  image: "/img/teachers/320x240/zhilova.jpg"
};

export const shabalina = {
  title: "Шабалина Светлана Александровна",
  text:
    "Практикующий имидж-стилист, коуч, преподаватель Высшей школы стилистики, автор книги «Быть стильной, оставаясь собой». Преподаватель дисциплины — индивидуальная стилистика образа (Классификация стилевых направлений, смешение стилей и эклектика. Характер линейности в лице и одежде. Профессиональная колористика в стиле. Ассоциации, как метод индивидуальной образности). Автор книги «Быть стильной, оставаясь собой. Как с помощью одежды рассказать о своей уникальности». www.directmedia.ru",
  link: "/svetlana-shabalina",
  image: "/img/teachers/320x240/svetlana-shabalina.jpg"
};

export const bonetskaya = {
  title: "Бонецкая Марина Анатольевна",
  text:
    "Художник-дизайнер, доцент, преподаватель ВШСДТ, член Международной Организации «Союз дизайнеров», практикующий имидж-стилист. Психология цвета, Украшения, Профтерминология, Эвристика, Ассоциативный метод в стилистике.",
  link: "/marina-bonetskaya",
  image: "/img/teachers/320x240/marina-bonetskaya.jpg"
};

export const oMaltseva = {
  title: "Ольга Мальцева",
  text:
    "Консультант по имиджу. Практикующий стилист-имиджмейкер. Окончила высшую школу стилистики, а также прошла дополнительные курсы повышения квалификации. В своей работе использует метод подробного анализа внешности, образа жизни и характера клиента, составляя его индивидуальную формулу стиля.",
  link: "/olga-maltseva",
  image: "/img/teachers/320x240/olga-maltseva.jpg"
};

/*
export const teacher = {
  title: "",
  text: "",
  link: "",
  image: "/img/"
}
*/
