import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Banner1 from "../../components/banner-imagemaker";
import Banner2 from "../../components/banner-personal-brand";
import Banner3 from "../../components/banner-advertising-as-a-communication";
import { styles } from "./styles";

var lStyles = {
  paragraph: {
    fontFamily: "Roboto",
    fontSize: "1rem",
    color: "rgb(100,100,100)",
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingBottom: "1em",
    lineHeight: "1.4em"
  },
  list: {
    lineHeight: "1.5em"
  }
};

export const content = {
  head: "SMM в индустрии моды",
  shortHead: {
    month: "3 месяца",
    classes: "30 занятий",
    hours: "80 академ. часов",
    online: "ONLINE"
  },
  about: (
    <Paragraph
      content={[
        <List
          arr={[
            "Если Вы планируете самостоятельно вести соц сети",
            "Если Вам нужен наставник для запуска своего аккаунта",
            "Если Вы выбираете погружение в полноценный процесс обучения",
            "Учитесь и в результате получите готовый аккаунт бренда, шоурума, стилиста, фотографа, специалиста и знания, необходимые для его продвижения. После обучения Вам остаётся просто выделить бюджет на продвижение. А все нюансы создания, ведения аккаунта и  настройки таргета освоите на занятиях."
          ]}
          style={styles.p}
        />,
        <a href="#programma">Программа курса</a>,
        <a href="#price">Стоимость обучения</a>
      ]}
    />
  ),
  duration: false,
  programmaList: [
    "Что такое контент и с чем его едят: видео, фото, текст, иконки и символы",
    "Все инструменты создания креативного и продающего контента",
    "Бизнес-стратегия и креативные концепции: как спланировать и внедрить их так, чтобы креатив не мешал росту бизнеса и наоборот",
    "Целевая аудитория и конкуренты: кого где искать, как привлекать и что анализировать",
    "Платные и бесплатные способы продвижения",
    "Бюджетирование и таргетинг: как настроить эффективную рекламу с минимальными затратами",
    "Управление командой: какие специалисты нужны для реализации SMM-стратегии и как контролировать фрилансеров",
    <span>
      <b>NB</b>: Конечно же, структура курса предполагает общий экскурс по
      ключевым терминам, исследование и анализ всех соцсетей, их отличий и
      особенностей.
    </span>
  ],
  list: true,
  total: false,
  author: {
    title: "Мери Саргсян",
    text: (
      <List
        arr={[
          "Продюсер моды и современного искусства",
          "Креативный консультант испанского ювелирного бренда Paola Vilas",
          "Приглашенный лектор НИУ ВШЭ",
          "Колумнист Buro24/7 | итальянского издания Zoe Magazine | Harper's Bazaar"
        ]}
        style={styles.pSmall}
      />
    ),
    image: "/img/about/meri-sargsyan.jpg",
    link: "/mary_sargsyan"
  },
  target: (
    <Paragraph
      content={[
        "Цель курса – обозначить основные стратегии развития индустрии моды эпохи постмодерна, изменившей подход дизайнеров к проектированию костюма и созданию визуальных образов, вынужденных работать на стыке социальных, маркетинговых и художественных стратегий, соединять историю с актуальной реальностью, коллективную мифологию с индивидуальной.",
        "Основные вопросы, которые сегодня обсуждают действующие лица мировой индустрии моды, станут вопросами курса: что такое мода и одежда сегодня, кто создает моду — профессионалы или дилетанты, чьи образы транслируют дизайнеры, кому предназначены высказывания моды, что оказывает воздействие на аудиторию — оригинальность, хулиганство и «вау-эффект» или ориентация на вечное, классику и высокое мастерство.",
        "Особое внимание будет уделено понятию «концептуальная» мода, ее месту в коммерческой моде, ее структурным признакам, связанных с концептуальным искусством 2-й половины XX века: как относится к одежде, которая не принимается и не используется большинством, каковы признаки концептуальности бренда и какова роль личности дизайнера, зачем концептуальной моде Марсель Дюшан, микробиология, ресайклинг, инновационный текстиль и технологии."
      ]}
      style={styles.p}
    />
  ),
  price: {
    title: "Учитесь от 5000 руб. в месяц в рассрочку на 10 месяцев",
    list: (
      <List
        arr={[
          "Полная стоимость обучения: 50000 рублей",
          <span>
            Как платить: Вы вносите аванс, бронируете место, начинаете учится;
            полную оплату курса необходимо внести до 3&nbsp;занятия.
          </span>,
          "Доступна банковская рассрочка на 10 месяцев: 5000 рублей в месяц. (без переплаты)",
          "Доступна скидка 10% при внесении оплаты за курс в течение 48 часов с момента бронирования места"
        ]}
        style={lStyles.list}
      />
    )
  },
  reviews: "",
  courses: [<Banner1 />, <Banner2 />, <Banner3 />]
};
