import React from "react";
import { Link } from "react-router-dom";
import List from "../../components/list";
import Video from "../../components/video";
import Slider from "../../components/slider-rk";
import Button from "../../components/button-messages";
import { dates, Month } from "../shedule/dates";
import { kostarnova, lamonova } from "../../contents/teachers";
import { styles, stylesPrice } from "./styles";

const slides = () => {
  let arr = [];
  for (let i = 1; i < 34; i++) {
    arr.push(`/img/interior-your-life/slide${i}.jpg`);
  }
  return arr;
};

const online = `${dates.interiorYourLife.online.getDate()} ${Month(
  dates.interiorYourLife.online.getMonth()
)}`;
/*
const ofline = `${dates.interiorYourLife.ofline.getDate()} ${Month(
  dates.interiorYourLife.ofline.getMonth()
)}`;
*/

export const contents = {
  titlePage: "Консультант по стилю и дизайну интерьера. Быстрый старт",
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Профессия: Интерьерный стилист
        <br />
        Декоратор
      </>
    ),
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Ёмко и насыщенно о самом важном для реализации идей в интерьере квартиры
        или загородного дома
      </div>
    ),
    descript: [
      "Дистанционное обучение",
      "Диплом установленного государством образца",
      "Длительность 4 месяца, более 72 занятий",
      <a href="#freeeducation">Открытые лекции и курсы</a>,
      "Стоимость курса 60 тыс. рублей",
      "Налоговый вычет после обучения"
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/interior-your-life/illustration-001.jpg",
    imageSourceWeb: "/img/interior-your-life/illustration-001.webp",
    video: "JeAfgyqAwOk",
    styleImage: {
      maxHeight: 380
    },
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "+79150342020",
        name: "Иван",
        tg: false,
        wa: "79150342020"
      }
    ],
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    }
  },
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой",
  text3block: [
    {
      title: "Даты",
      text: [<>Дистанционно</>, <>с {online}</>]
    },
    {
      title: "Формат",
      text: [
        <>Живые вебинары, видеолекции,</>,
        <>чат в группе, проект квартиры</>
      ]
    },
    {
      title: "Эксперты",
      text: [
        <>Российские и зарубежные практики —</>,
        <span style={{ letterSpacing: "-.5px" }}>
          практикующие дизайнеры, преподаватели
        </span>
      ]
    }
  ],
  bot: {
    image: (
      <img src="/img/imagemaker-stylist/chat-bot-ill.jpg" alt="Бот Валерия" />
    ),
    text: (
      <>
        <p className="p-constructor">
          <strong>Открытое образование ВШСДТ</strong>
        </p>
        <List
          arr={[
            "Бот Валерия подбирает контент в зависимости от предпочтений",
            "Регулярно открывает доступ к онлайн лекциям и курсам",
            "Без оплат и условий. Общается вежливо. Как начать?",
            "Подпишитесь, нажмите «старт» и выбирайте интересующие темы"
          ]}
          num="none"
          olStyle={{ marginLeft: 0, paddingLeft: 0 }}
          classLi="p-constructor"
        />
        <a href="https://t.me/imagemakerpro_bot">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  text1: (
    <>
      <p className="p-constructor">
        Обучение по программе «Интерьерный стилист» по государственному гранту.
      </p>
      <p className="p-constructor">
        Обучение реализуется в онлайн формате с живым общением с преподавателями
        на базе Национального исследовательского Томского государственного
        университета совместно с АНО ДПО Высшая школа стилистики, дизайна и
        технологий.
      </p>
      <p>
        <Link to="/interior-stylist-tgu">Подробнее…</Link>
      </p>
    </>
  ),
  bannerTgu: (
    <>
      <div>
        <a href="/interior-stylist-tgu" target="_blank">
          <img
            src="/img/interior-stylist-tgu/interior-stylist-tgu.jpg"
            alt="Интерьерный стилист. Бесплатное обучение по гос гранту"
            style={{ border: "1px solid black" }}
          />
        </a>
      </div>
    </>
  ),
  tags1: (
    <span>
      <a href="#continue">
        <span style={{ color: "rgb(180,180,180)" }}>#даты&ensp;</span>
      </a>{" "}
      <a href="#programma">
        <span style={{ color: "rgb(180,180,180)" }}>#программа&ensp;</span>
      </a>{" "}
      <a href="#diplom">
        <span style={{ color: "rgb(180,180,180)" }}>#диплом&ensp;</span>
      </a>{" "}
      <a href="#price">
        <span style={{ color: "rgb(180,180,180)" }}>#стоимость&ensp;</span>
      </a>{" "}
    </span>
  ),
  screen2: {
    head: "Вы получите",
    dates: false,
    quote: false,
    text: (
      <>
        <List
          arr={[
            <>Обучение в удобном графике</>,
            <>Доступ к курсу в личном каби&shy;нете</>,
            <>Обратную связь от препо&shy;давателя</>,
            <>&emsp;</>,
            "а так же",
            <>&emsp;</>,
            <>Идеи и визуали&shy;зацию кон&shy;цепции Вашего интерь&shy;ера</>,
            <>
              Коллажи, отра&shy;жающие стилис&shy;тику и цве&shy;товое
              реше&shy;ние поме&shy;щения
            </>,
            <>
              Чертежи с обмерным планом, рас&shy;станов&shy;кой мебели, схемой
              осве&shy;щения
            </>
          ]}
          num="none"
          style={{ paddingBottom: ".3rem" }}
          olStyle={{ paddingLeft: 0 }}
        />
      </>
    ),
    media: (
      <>
        <Slider images={slides()} imageClassName="slider__for__dates__block" />
        <p
          style={{
            fontSize: "0.8em",
            textAlign: "center",
            fontStyle: "italic"
          }}
        >
          Примеры работ студентов курса
        </p>
      </>
    )
  },

  schedule: (
    <>
      <p>
        группа 6.017 (онлайн обучение, будни утро/вечер), с 10.02.22, идет набор
      </p>{" "}
    </>
  ),
  programma: [
    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Цели обучения:
    </h2>,
    <List
      style={styles.p}
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      num="none"
      arr={[
        <>
          Получения навыка составления Design Project folder и реализации своих
          идей в дизайне интерьера
        </>,
        <>
          Получение дополнительного дохода от консультирования по стилю и
          декорированию интерьера.
        </>
      ]}
    />,
    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Ключевые дисциплины и темы курса
    </h2>,
    <List
      style={styles.p}
      num="none"
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      arr={[
        "Концепция современного интерьера",
        "Стилистические особенности формирования интерьера",
        "Цветопсихология и колористика",
        "Основы композиции в дизайне интерьера",
        "Эргономика — основа комфортного интерьера",
        "Анализ предпроектной ситуации",
        "Замеры фотофиксация техническое задание",
        "Выявление потребностей и составление клиентского профиля",
        "Формирование концепции интерьера на основе индивидуальных предпочтений и предпроектной ситуации",
        "Выявление «темы» и создание индивидуального настроения интерьера",
        "Подбор мебели и аксессуаров декора",
        "Планировочное решение-оптимальное расположение мебели",
        "Основы скетчинга",
        "Коллажирование - разработка «moodboard» «simple board» 3D",
        "Составление проектной папки"
      ]}
    />,
    <h2
      style={{
        ...styles.h2,
        fontFamily: "PT Sans Narrow",
        textAlign: "left"
      }}
    >
      Практические работы — Design Project folder
    </h2>,
    <List
      style={styles.p}
      num="none"
      olStyle={{ marginLeft: 0, paddingLeft: 0 }}
      arr={[
        <strong>Предпроектный этап:</strong>,
        "Презентация предпроектной ситуация",
        "Клиентский профиль",
        "Аналоговый ряд",
        <>&emsp;</>,
        <strong>Проектный этап:</strong>,
        "Style book — стиль, цвето-текстурная карта интерьера",
        "Moodboard — «simple board» — 3D collage",
        "Планировочное решение",
        "Ведомость отделочных работ и материалов",
        "Ведомость мебели и декора",
        "Design Project folder — позволит вам внедрить вашу идею в реальном интерьере"
      ]}
    />
  ],
  rgsu: {
    text: (
      <>
        Курс дает системные и практические знания для старта карьеры дизайнера,
        а диплом установленного государством образца закрепляет ваше право вести
        профессиональную деятельность.
      </>
    ),
    paperText: (
      <>
        После изучения курса «Консультант по стилю и дизайну интерьера. Быстрый
        старт» Вы можете начать работать с клиентами и продолжить погружение в
        профессию на годовой программе{" "}
        <Link to="/living-interior-design-and-decoration">
          «Дизайн и декорирование интерьера»
        </Link>
        , и получить второй диплом.
      </>
    ),
    image: "/img/sdi/diplom-2.jpg"
  },
  howIsTheTrainingGoing: [
    {
      title: "Смотрите уроки",
      text: (
        <>
          Записи уроков открываются по мере прохождения груп&shy;пой курса.
          Обучение дви&shy;жется в комфортном, рабо&shy;чем ритме
        </>
      )
    },
    {
      title: "Выполняете задания",
      text: (
        <>
          В конце основных уроков даются задания. На про&shy;тя&shy;же&shy;нии
          всего курса препо&shy;дава&shy;тель проверяет, нахо&shy;дит ошибки,
          ком&shy;мен&shy;тиру&shy;ет, отвечает на воп&shy;росы.
        </>
      )
    },
    {
      title: "Создаете проект",
      text:
        "Продумываете концепцию Вашего будущего интерьера, создаете визуализацию и комплект чертежей для строителей"
    },
    {
      title: "Реализуете проект",
      text:
        "Делаете ремонт без ошибок и головной боли, говорите со строителями на равных"
    }
  ],
  variants: false,
  afterEducation: false,
  // {
  //   head: "Карьера, бизнес, профессия",
  //   videos: ["fOZzI3bKXIU", "2_fcNTHeV3g", "NzsSXUy2GMk"]
  // },
  aboutEducation: (
    <span>
      Программа обучения в очном и онлайн форматах состоит из 125&nbsp;занятий
      по 2&nbsp;академических часа.
      <br />
      График проведения занятий: по 2&nbsp;занятия 2-3&nbsp;раза в неделю.
      <br />
      Длительность всего курса — 5+&nbsp;месяцев.
    </span>
  ),
  shessBlcok: {
    title:
      "Возможность: получите дизайн проект одной из комнат Вашего дома, сделанный студентом ВШСДТ",
    left: (
      <>
        <List
          arr={[
            <>
              Как это будет выглядеть? Смотрите далее видео защиты одной из
              проектных работ
            </>,
            <>
              Как получить консультацию дизайнера и дизайн-проект Вашего дома?
            </>,
            <>
              <List
                arr={[
                  <>Запишитесь ниже на «тест-драйв»</>,
                  <>Посмотрите лекции и напишите отзыв</>,
                  <>Опубликуйте отзыв в соц сетях</>,
                  <>Мы организуем Вам консультацию и проект</>
                ]}
                num="decimal"
                style={{ paddingBottom: ".6rem" }}
              />
            </>
          ]}
          style={{ paddingBottom: ".6rem" }}
          num="none"
        />
      </>
    ),
    right: (
      <>
        <Video
          link={"JeAfgyqAwOk"}
          h={300}
          onlyVideo={false}
          // wVideo={props.content.videoWidth}
        />
      </>
    )
  },
  papersTitle: "Три варианта обучения, стоимость",
  papers: [
    {
      title: "Тест-драйв",
      gray: (
        <>
          <strong>2000 &#8381;</strong>
        </>
      ),
      text: (
        <>
          <p className="p-constructor">
            Когда Вы хотите просто попробовать и проверить
          </p>
          <p className="p-constructor">Вы получите:</p>
          <List
            arr={[
              <>Доступ к первым двум неделям обучения</>,
              <>Просмотр занятий в удобное время</>,
              <>
                Возможность получить дизайн-проект одной из комнат Вашего дома,
                сделанный студентами ВШСДТ
              </>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    },
    {
      title: "Сам себе дизайнер",
      gray: (
        <>
          <strong>36000 &#8381;</strong>
        </>
      ),
      text: (
        <>
          <p className="p-constructor">
            Если Вы приобрели недвижимость или просто хотите сделать ремонт и
            преобразить свое пространство
          </p>
          <p className="p-constructor">Вы получите:</p>
          <List
            arr={[
              <>Доступ к 36 занятиям по 2 академ.часа в течение обучения</>,
              <>Доступ к 12 практическим занятиям с преподавателем</>,
              <>Доступ к курсу в течение трех месяцев после обучения</>,
              <>Общение с коллегами в закрытой группе</>,
              <>Проверку домашних заданий</>,
              <>Обратная связь по каждому ДЗ</>,
              <>Сертификат после обучения</>,
              <>Доступна банковская рассрочка без переплат на 10 месяцев</>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    },
    {
      title: "Дипломный курс",
      gray: <strong>69000 &#8381;</strong>,
      text: (
        <>
          <p className="p-constructor">
            <strong>Профессиональный</strong>
          </p>
          <p className="p-constructor">Вы получите:</p>
          <List
            arr={[
              <>Доступ к 36 занятиям по 2 академ.часа в течение обучения</>,
              <>Доступ к 12 практическим занятиям с преподавателем</>,
              <>Доступ к курсу в течение шести месяцев после обучения</>,
              <>Практические домашние задания</>,
              <>
                Проверка и обратная связь от преподавателя по домашним заданиям
              </>,
              <>Общение с преподавателем во время занятий</>,
              <>Общение с коллегами в закрытой группе</>,
              <>Защиту дипломной работы</>,
              <>Включение в проекты и практику работы с клиентами</>,
              <>
                Диплом о профес&shy;сиональной перепод&shy;готовке Автономной
                неком&shy;мерчес&shy;кой организации Дополни&shy;тельного
                профес&shy;сиональ&shy;ного образо&shy;вания «Высшая школа
                стилис&shy;тики, дизайна и технологий»
              </>,
              <>Доступна банковская рассрочка без переплат на 10 месяцев</>
            ]}
            style={{
              textAlign: "left",
              paddingBottom: ".6rem"
            }}
            olStyle={{
              marginTop: "2rem"
            }}
          />
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "1rem auto" }}
              >
                записаться
              </div>
            }
          />
        </>
      )
    }
  ],
  actions: (
    <>
      Приглашайте друзей,
      <br />
      1+1 = скидка 20%
    </>
  ),
  pay: {
    online: {
      textButton: "Акции",
      text: (
        <>
          <List
            style={stylesPrice.text}
            num="decimal"
            arr={[
              <>«Тест-драйв» доступ на две недели обучения = 900 рублей</>,
              <>Приглашайте друзей, 1+1 = скидка 20%</>,
              <>Вольный слушатель = скидка до 50%</>
            ]}
          />
          <p style={stylesPrice.text}>
            Все подробности об обучении и действующих акциях уточняйте у
            специалистов учебного отдела
          </p>
        </>
      )
    },
    free: {
      textButton: "Стоимость обучения",
      text: (
        <div>
          <p style={{ ...stylesPrice.text, marginTop: "2rem" }}>
            Вариант оплаты: оплата в два платежа
          </p>
          <List
            arr={[
              <>
                полная стоимость — 69
                <sup>000</sup> рублей (890 долл США / 750 евро)
              </>,
              <>
                33 <sup>000</sup> рублей — 2 платежа, в первый и второй месяцы
                обучения
              </>
            ]}
          />
          <p style={{ ...stylesPrice.text, marginTop: "1rem" }}>
            Вариант оплаты: банковская рассрочка без переплаты на 10 месяцев
          </p>
          <List
            arr={[
              <>
                6<sup>900</sup> рублей ежемесячный платеж
              </>,
              <>
                итого 10 платежей в сумме — 69
                <sup>000</sup> рублей
              </>
            ]}
          />
          {/* <p style={stylesPrice.text}>
            <strong>Важно:</strong>
          </p>
          <List
            style={stylesPrice.text}
            arr={[
              "по запросу мы предоставляем документы для налогового вычета"
            ]}
          /> */}
        </div>
      )
    }
  },
  price: {
    title: "Стоимость обучения",
    installment: <>5000 &#8381;</>,
    full: <>60 000 &#8381;</>, //  полная стоимость
    discount: false, // <>11 940 &#8381;</>, // со скидкой
    discountSize: <>20%</>,
    discountDuration: "при записи 1+1",
    qrLink: "https://link.tinkoff.ru/1YXZl7Cpsi2",
    durationInstallment: "12",
    noButtons: true
  },
  info: false,
  teachers: [kostarnova, lamonova]
};
