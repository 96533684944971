import React from "react";
import { stylesDark, styles } from "../imagemaker-stylist/styles";
import { withStyles } from "@material-ui/styles";
import { contents } from "./content";
import { Schedule } from "./schedule";
import {
  BuilderLayout,
  ShessBlock,
  TextAndTags,
  TitleH2
} from "../../patterns/pattern-fs/components";
import { News } from "./news";

const wStyles = theme => ({
  ...stylesDark,
  ...styles
});

function Top(props) {
  return (
    <div>
      <>
        <BuilderLayout
          contents={[
            <>
              <ShessBlock
                content={{
                  left: contents.head.left,
                  right: contents.head.right
                }}
              />
              {/* <StringContacts
                title="По вопросам обучения:"
                contacts={contents.contacts}
              /> */}
            </>,
            <TextAndTags
              content={{
                tags: false,
                text: (
                  <p
                    className="p-constructor center"
                    style={{ lineHeight: "2rem" }}
                  >
                    Анонсы встреч и мероприятий Ассоциации выпускников ВШСДТ — в
                    телеграм канале:{" "}
                    <a href="https://t.me/styleschoolmsk">
                      <img
                        src="/img/telegram_color.svg"
                        style={{
                          height: 24,
                          width: "auto",
                          verticalAlign: "middle"
                        }}
                        alt=""
                      />
                      &nbsp;@styleschoolmsk
                    </a>
                  </p>
                )
              }}
            />,
            <News content={contents.news} />,
            <>
              <TitleH2 content="Вакансии" />
              <TextAndTags
                content={{
                  text: contents.jobs,
                  tags: false
                }}
              />
            </>,
            <>
              <TitleH2
                content="Fashion практики"
                style={{ paddingBottom: 0 }}
              />
              <p
                className="p-constructor center"
                style={{ paddingBottom: "2rem" }}
              >
                для студентов и выпускников, членов ассоциации
              </p>
              <Schedule content={contents.schedulePractics} />
              <p
                className="p-constructor center"
                style={{ paddingTop: "2rem" }}
              >
                Чтобы записаться на практику, пишите в телеграмм канал{" "}
                <a href="https://t.me/styleschoolmsk">
                  <img
                    src="/img/telegram_color.svg"
                    style={{
                      height: 24,
                      width: "auto",
                      verticalAlign: "middle"
                    }}
                    alt=""
                  />
                  &nbsp;@styleschoolmsk
                </a>
              </p>
            </>
          ]}
        />
      </>
    </div>
  );
}

export default withStyles(wStyles)(Top);
