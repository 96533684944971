import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
import HistoryXX from "./historyxx";
import { style } from "./style.js";

export const contents = {
  head: {
    title: "История моды с Русланом Миграновым",
    headDescript: "Дистанционное образование",
    head: (
      <>
        История моды
        <br />с Русланом Миграновым
      </>
    ),
    headAfterDescript:
      "Мода XX века: путешествие от модерна до конца тысячелетия",
    descript: [
      "Очно, онлайн",
      "Новые идеи за 1 месяц регулярных занятий в мини группе",
      "Длительность — 8 занятий по полтора часа",
      "Стоимость 25 тыс. рублей"
    ],
    text: "",
    imageSource: "/img/history/fullscreen.jpg",
    video: "Pq-58u-jD5U",
    styleHead: {
      fontSize: "3rem",
      lineHeight: "2.5rem"
    }
  },
  screen2: false,
  text1:
    "Как и почему меняется мода, мода как взгляд на эпоху, основные характеристики костюма каждого десятилетия, создатели моды, их идеи и образы, иконы стиля и «уличная» мода, развитие моды как развитие идей искусства и технологий XX века, суть творческого метода французских, итальянских, британских и американских дизайнеров-кутюрье — все это и еще многое другое в авторском цикле лекций",
  programmaOutComponent: (
    <HistoryXX
      headComponent={
        <h2
          style={{
            fontSize: "1.6em",
            fontWeight: 700,
            color: "rgb(122,101,89)",
            textAlign: "center",
            margin: 0,
            padding: "3.2rem 1rem 1.4rem",
            fontFamily: "PT Sans Narrow"
          }}
        >
          ИСТОРИЯ МОДЫ XX ВЕКА
        </h2>
      }
    />
  ),
  teachersHead: "Ведущий курса",
  teachers: [
    {
      image: "/img/fashion-styling/migranov.jpg",
      name: "Руслан Мигранов",
      text:
        "Ведущий лекций по теме «История моды XX века» в рамках \
      программы профессиональной переподготовки «Современные технологии \
      в работе имиджмейкера: персональный и корпоративный стиль». \
      Преподаватель курса «Визуальный мерчендайзинг и витринистика», \
      ведущий отдельных мастер-классов по моде и витринистике, а \
      также выездного арт-тура в Венецию «Мода и искусство Венеции»",
      instagram: false,
      buttons: {
        detail: "/ruslan_migranov",
        linkInside: true
      }
    }
  ],
  pay: false
};
