import React from "react";
import List from "../../components/list";

export default function Technology() {
  return (
    <div>
      <h2
        style={{
          fontSize: "1.8em",
          color: "rgb(100,100,100)",
          fontWeight: 700,
          textAlign: "left",
          margin: 0,
          padding: "24px 0"
        }}
      >
        ТЕХНОЛОГИЯ ОБУЧЕНИЯ
      </h2>
      <List
        arr={[
          "Обучение осуществляется в заочной форме с применением дистанционных технологий в соответствии с требованиям к дистанционной программе по ФЗ «Об образовании в РФ» от 29.12.2012 (№273-ФЗ).",
          "После заключения Договора на обучение и предоставления всех необходимых документов (см. Документы, кликабельно) Вы в составе своей группы обучения получаете доступ к образовательной СДО, где будут содержатся лекции и видеоконтент, алгоритм отправки квитанции о платежах, алгоритм загрузки домашних и практических заданий, методические рекомендации, список рекомендуемой литературы по курсу, расписание на каждый семестр обучения, тесты, форумы преподавателей и специалистов Школы.",
          "Содержание материалов каждой программы профессиональной переподготовки определяется учебным планом и заверено ФДПО государственного Вуза, партнера ВШС в реализации выбранной программы профпереподготовки.",
          "Изучать материалы курса в виде записи лекции (видеолекции) можно в любое время суток, в любой точке мира. Изучать материалы лекции в формате вебинаров (он-лайн) и посещать практические занятия в формате вебинаров (он-лайн) можно в любой точке мира, но в соответствии с графиком обучения Вашей группы. Дни и время обучения указываются в Договоре и отражены в расписании.",
          "Записи лекций и практических занятий, проведенных в формате вебинара в определенные дни и время, также остаются в системе дистанционного обучения и доступны для изучения в течение всего периода обучения.",
          <span>
            Контроль знаний осуществляется в форме практических заданий,
            обязательных к выполнению обучающимися для освоения программы, и в
            формате тестирования. Практические задания должны выполняться
            обучающимся в срок, отведенный преподавателем, и быть предоставлены
            на проверку к дате проведения практического вебинара, что отражено в
            расписании.
            <br />
            Формой итоговой аттестационной работы является экзамен (для программ
            профессиональной переподготовки)
          </span>,
          "По окончании обучения и успешной сдачи экзамена (для программ профессиональной переподготовки) Вы получаете диплом установленного образца государственного Вуза (форма диплома, кликабельно) или сертификат ВШС (по программам базовых курсов или курсов повышения квалификации) (форма сертификата, кликабельно).",
          "Вы можете получить указанные документы после успешного окончания обучения лично или посредством заказного письма, или посредством курьерской доставки."
        ]}
        style={{
          fontSize: "1em",
          color: "rgb(100,100,100)",
          textAlign: "justify"
        }}
      />
    </div>
  );
}
