import React from "react";
import Helmet from "react-helmet";
import Footer from "../../components/footer";
import Grid from "@material-ui/core/Grid";
import Teacher from "../../components/teacher";
import Top from "../../components/topmenu";
import List from "../../components/list";

export default function Teach() {
  var content = [
    {
      title: "",
      text: (
        <List
          arr={[
            "Более 10 лет практики в индустрии дизайна интерьеров и строительства",
            "Более 7 лет практики в качестве специалиста по выстраиванию и позиционированию бренда, организатора информационных поводов в области дизайна интерьера",
            "Более 8 лет работы в качестве пиар-директора медийных лиц из области дизайна и декорирования, в том числе работа пиар-директором программы Фазенда на Первом канале",
            "Более 5 лет практики в области управления репутацией компаний индустрии дизайна интерьера и строительства"
          ]}
        />
      )
    },
    {
      title: "Преподавание в Высшей школе стилистики",
      text: (
        <List
          arr={[
            <span>
              Куратор и преподаватель курса профессиональной переподготовки
              &laquo;Дизайн и декорирование интерьеров&raquo;
            </span>
          ]}
        />
      )
    },
    {
      title: "Образование",
      text: (
        <List
          arr={[
            "Закончила бакалавриат Российского университета дружбы народов по направлению «Международная журналистика»",
            "Закончила магистратуру Российского университета дружбы народов по направлению «Управление массовыми коммуникациями»",
            "Закончила Школу Декораторов Марата Ка"
          ]}
        />
      )
    },
    {
      title: "Профессиональный опыт:",
      text: (
        <List
          arr={[
            "PR-директор, Лаборатория Марата Ка. Являлась личным руководителем декоратора Марата Ка. Управляла всеми публичными и рекламными проектами его компаний (интерьерной, производственной, издательства). Создавала видео, книги, профильные мероприятия, сайты. Управляла выводом на рынок с точки зрения позиционирования бренда малярных материалов. До сих пор занимается рядом проектов, связанных с деятельностью Марата Ка и его компаний.",
            "Сооснователь, управляющий, Лекторий «Чувство Дома». Вместе с Машей Шаховой (продюсер интерьерных программ) основала профильный образовательный лекторий для дизайнеров интерьеров и широкой аудитории на площадке Музея ДПИ и библиотеки Тургенева. Создала бренд с нуля. Создала сайт и айдентику бренда с нуля. Полностью вела стратегию вывода на рынок и продвижения компании. Готовила каждую лекцию от брендинга и копирайтинга до взаимодействия со спикером и набора слушателей. Продюсировала создание видео. Взаимодействовала с партнерами. Участвовала в создании выставки молодых предметных дизайнеров в рамках одной из образовательных программ.",
            "PR-директор, программа «Фазенда» Первый канал. Являлась личным пиар-директором владельца и продюсера программы «Фазенда» о дизайне интерьера дач и загородных домов (Первый канал). Управляла сайтом проекта с момента его создания. Управляла социальными сетями проекта от разработки позиционирования до реализации стратегии, проводила специальные мероприятия, выстраивала коммуникации с дизайнерами, партнерами и поставщиками программы. Снимала рекламные видео и видео-интеграции.",
            "Владелец коммуникационного агентства в сфере дизайна интерьеров. Основала компанию, которая занимается управлением проектами в сфере продвижения компаний из области строительства, дизайна интерьеров и декорирования. Выстраивала коммуникации в таких компаниях как Лаборатория Марата Ка, управляющая компания ЭМАКС, салоны Артдекориум и Мастердом, мебельные фабрики Мирадо и The Birch. Осуществляла проекты в сотрудничестве с Международной Школой дизайна, школой прикладного декорирования «Пространство», световой компанией Донолюкс, мебельным центром Твинстор и многими другими. Организует профильные мероприятия, создает сайты, выстраивает стратегии в социальных сетях, выпускает книги и учебники по дизайну, профильные рекламные материалы, видео в разных жанрах."
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Ольга Олюшина: Публичный профиль</title>
        <meta name="keywords" content="Ольга Олюшина: Публичный профиль" />
      </Helmet>
      <Top faqultet="Преподаватели" />
      <Grid container justify="center" alignItems="flex-start">
        <Grid item style={{ padding: 20 }}>
          <Teacher
            head="Ольга Олюшина"
            short={
              <span>
                Специалист по массовым коммуникациям и построению бренда
              </span>
            }
            image="/img/teachers/oljga-olyushina.jpg"
            tiles={[]}
            content={content}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}
