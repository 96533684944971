import React from "react";
import Bot from "../../components/bot-valeriya";

export const styleConsultant = {
  head: (
    <>
      Консультант по стилю
      <br />
      обучение за счет гос гранта
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "2,5 месяца. Онлайн",
  specialisation:
    "Консультирование частных клиентов по имиджу и стилю с учетом индивидуальных особенностей и модных тенденций",
  price: false,
  process: false,
  diplom: false,
  isNotSpoler: true,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/style-consultant-tgu"
  }
};

export const interiorStylist = {
  head: (
    <>
      Интерьерный стилист
      <br />
      обучение за счет гос гранта
    </>
  ),
  typeEducation: "Повышение квалификации",
  duration: "2,5 месяца. Онлайн",
  specialisation:
    "Для начинающих — первый шаг к профессии дизайнера и навыки осознанного оформления жилых пространств",
  price: false,
  process: false,
  diplom: false,
  isNotSpoler: true,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/interior-stylist-tgu"
  }
};

export const yourinvest = {
  head: (
    <>
      Первые Инвестиции
      <br />
      Школа Инессы Трубецковой
    </>
  ),
  typeEducation: "Открытое образование",
  duration: "1 месяц. Онлайн",
  specialisation:
    "Для начинающих — способы применения личных стратегий для получения дополнительной прибыли и преумножения капитала",
  price: false,
  process: false,
  diplom: false,
  isNotSpoler: true,
  link: {
    type: "form",
    text: "записаться",
    link: false
  },
  link2: {
    type: "outerLink",
    text: "подробнее",
    link: "/yourinvest"
  }
};

export const content = {
  head: "Открытое образование ВШСДТ",
  before: (
    <Bot
      image="/img/other/open_lesson.jpg"
      text={
        <>
          <p className="p-constructor">
            <strong>Телеграмм группа открытого образования ВШСДТ</strong>
          </p>
          <p className="p-constructor">
            В группе Открытого образования публикуем информацию об открытых
            курсах и мероприятиях, или социальных проектах
          </p>
          <p className="p-constructor">
            Здесь Вы узнаете, когда и как большую часть материалов можно изучить
            без оплат и условий
          </p>
          <a href="https://t.me/+Sfmarwgq2CtCji81">
            <div
              style={{
                background: "rgb(40,168,233)",
                padding: ".5rem 1rem",
                color: "rgb(255,255,255)",
                textAlign: "center",
                margin: "2rem auto 0 auto",
                maxWidth: "max-content"
              }}
            >
              <img
                src="/img/telegram-bw.svg"
                style={{ height: "1rem", width: "1rem" }}
              />
              &ensp;
              <span>Подписаться</span>
            </div>
          </a>
        </>
      }
    />
  ),
  tiles: [styleConsultant, interiorStylist, yourinvest]
};
