import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import Helmet from "react-helmet";
import Head from "./header";
import Descript from "./description";
import Page from "../../components/page";
import Price from "./price";
import Ig from "../../components/instagram";
import { style } from "../../components/stylesCourse";
import Want from "../../components/want-to-know";
import { content } from "./content";
import { Calendar } from "../../patterns/pattern-fs/components";
import { dates, Month } from "../shedule/dates";

const styles = theme => style;

const Component = props => {
  const { classes } = props;
  return (
    <div style={{ fontSize: 16 }}>
      <Helmet>
        <title>Fashion тур-каникулы в Тбилиси</title>
        <meta
          name="keywords"
          content="тур, практика, Грузия, стажировка, шоурумы, дизайнерские магазины, стиль, имиджмейкер, стилист, школа"
        />
        <meta
          name="descript"
          content="Уникальный модный тур в Тбилиси от экспертов Высшей школы стилистики, дизайна и технологий. Повысите свою экспертность в мире моды, познакомитесь с представителями мирового фэшн коммьюнити (дизайнерами, модными экспертами, стилистами, фотографами, визажистами и пр.). Вы узнаете особенности индустрии в Грузии, познакомитесь с аутентичными брендами, посетите модные шоурумы, концепт-сторы, дизайнерские магазины. Fashion тур подойдет для экспертов индустрии моды и дизайна: стилистов, дизайнеров, имиджмейкеров, фэшн блогеров, инфлюэнсеров, фотографов, байеров и просто фанатов моды."
        />
      </Helmet>
      <Page lable="Тбилиси fashion weekend">
        <Head head={content.head.head} descript={content.head.descript} />
        <div style={{ height: "4rem" }} />
        <Calendar
          content={{
            ofline: {
              type: "с",
              day: dates.tbilisiFashionWeekend.start.getDate(),
              month: Month(dates.tbilisiFashionWeekend.start.getMonth())
            },
            online: {
              type: "по",
              day: dates.tbilisiFashionWeekend.finish.getDate(),
              month: Month(dates.tbilisiFashionWeekend.finish.getMonth())
            }
          }}
        />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={12} style={{ padding: "0 20px" }}>
            <div style={{ height: "1.4em" }} />
            <Descript
              block1={content.descript.block1}
              block2={content.descript.block2}
            />
            <Price />
            <h2 className={classes.h2small}>По всем вопросам</h2>
            <p className={classes.p}>
              (как доехать, что нужно и пр.) пишите куратору поездки
            </p>
            <br />
            <p className={classes.p}>
              Прохорович Елене <a href="tel:+79017751464">+7 (901) 775-14-64</a>
            </p>
            <p className={classes.p}>
              Вотсап{" "}
              <a href="whatsapp://send?phone=+393471862673">+393471862673</a>
            </p>
            <p className={classes.p}>
              Инстаграм <Ig a="elenacult_com" />
            </p>
            <h2
              className={classes.h2small}
              style={{ marginTop: "2rem", textAlign: "center" }}
            >
              Задать вопрос
            </h2>
            <Want noHead />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
};

export default withStyles(styles)(Component);
