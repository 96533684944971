import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";

const online = `${dates.businessAnalitic.online.getDate()} ${Month(
  dates.businessAnalitic.online.getMonth()
)}`;

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        IT-профессия:
        <br />
        Бизнес аналитик
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    descript: [
      "Онлайн, практика",
      "Удостоверение о повышении квалификации",
      "2 месяца, 50 академических часов",
      "Трудоустройство с окладом от 100К",
      "Консультации с наставником",
      "Рассрочка, налоговый вычет",
      "Удостоверение о повышении квалификации"
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Обучение основам профессии с перспективами трудоустройства. Отличная
        возможность войти в ИТ сферу и круто изменить свою жизнь.
      </div>
    ),
    text: "",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    imageSource: "/img/business-analitic/ill-01-01.jpg",
    imageAlt: "IT-профессия: Бизнес аналитик",
    styleImage: {
      maxHeight: 380
    }
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online}</>]
    },
    {
      title: "Формат",
      text: [<>Blended learning</>]
    },
    {
      title: "Трудоустройство",
      text: [<>Вероятность 99%</>]
    }
  ],
  screen2: {
    text: (
      <>
        <p className="center">
          <strong>Чему Вы научитесь</strong>
        </p>
        <List
          arr={[
            <>основам проектной методологии</>,
            <>современным гибким фреймворкам ведения проектов Scrum, Kanban</>,
            <>оценивать продукт с позиции финансовой выгоды</>,
            <>
              анализировать востребованность нового продукта или сервиса на
              рынке
            </>,
            <>
              правильно использовать метрики и показатели эффективности проекта
            </>,
            <>формировать бэклог продукта и работать в Agile команде</>,
            <>
              работать с требованиями заказчика и делать постановки на
              разработку
            </>
          ]}
          classLi="p-constructor"
        />
      </>
    ),
    image: "/img/business-analitic/ill-02-01.jpg"
  },
  programma: {
    head: "Программа",
    content: [
      <p>
        <strong>Что будет</strong>
      </p>,
      <List
        style={styles.p}
        arr={[
          <>Основы продуктового мышления</>,
          <>Роли и участники проекта</>,
          <>
            Методологии проектного управления Waterfall, Agile, Scrum, Kanban
          </>,
          <>Продуктовые метрики и коэффициенты эффективности проекта</>,
          <>Основы UX / UI дизайна</>,
          <>Финансовые показатели проекта</>,
          <>Подготовка проекта, планирование, контроль, завершение</>,
          <>Работа с рисками проекта и их анализ</>,
          <>
            Работа в команде проекта: психология командной работы, психотипы,
            типологии личности, стили управления, работа с конфликтами
          </>,
          <>Тестирование и сдача продукта</>
        ]}
      />
    ]
  },
  formats: {
    head: "Форматы обучения",
    content: [
      <Spoler
        title={
          <>Форма обучения: заочная с применением дистанционных технологий</>
        }
        text={
          <>
            Обучение предусматривает изучение основного блока лекционных и
            практических материалов онлайн (дистанционно). В рамках курса
            проводятся практические занятия – онлайн консультации с
            преподавателями и специалистами.
          </>
        }
      />,
      <Spoler
        title={<>Учебная нагрузка: интенсивная, много самостоятельной работы</>}
        text={
          <List
            olStyle={{ paddingLeft: "1rem" }}
            arr={[
              <>
                Длительность: 30 онлайн уроков + постоянные задания на
                самостоятельное изучение материала в источниках
              </>,
              <>Доступ к курсу: 180 дней доступа ко всем материалам курса</>,
              <>Сложные домашние задания и обратная связь по заданиям</>,
              <>Ответы на вопросы и поддержка в чате курса</>,
              <>Составление и размещение резюме на профильных ресурсах</>,
              <>Скрипт (памятка) по продвижению специалиста в ИТ сообществах</>,
              <>
                Дополнительные 3 урока от автора курса в режиме реального
                времени
              </>,
              <>Итоговая аттестация</>
            ]}
            num="none"
          />
        }
      />,
      <Spoler
        title={
          <>
            Варианты обучения: сертифицированная программа или вольный слушатель
          </>
        }
        text={
          <>
            <p>
              <strong>Сертифицированная программа</strong> — в рамках обучения с
              правом получения Удостоверения о повышении квалификации
              установленного государством образца. Обязательными требованиями
              являются выполнение домашних заданий, реализация и защита
              проектной работы
            </p>
            <p>
              <strong>Вольный слушатель</strong> — Вы можете учиться без
              обязательных требований по выполнению д/з и практик, без сдачи
              экзаменов. В таком случае вы получите сертификат о прохождении
              программы и дополнительную скидку на обучение.
            </p>
          </>
        }
      />
    ]
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Слушатели, прошедшие обучение на курсе, получают удостоверение о
        повышении квалификации установленного государством образца по программе
        «Бизнес аналитик», 50&nbsp;академических часов
      </p>
    ),
    image: (
      <img
        src="/img/udostoverenie-o-povyshenii-kvalifikatsii.png"
        alt="Удостоверение о повышении квалификации ВШСДТ"
        style={{ maxWidth: 320 }}
      />
    )
  },
  teachers: [
    {
      image: "/img/pro-motion-design/anashkina-sq.jpg",
      name: (
        <>
          Автор и преподаватель курса:
          <br />
          Анашкина Елена
        </>
      ),
      text: (
        <List
          arr={[
            "IT-эксперт, который обеспечивает людей самыми востребованными профессиями.",
            "Основатель проекта «Одна точка».",
            "Более 14 лет в области цифрового дизайна и анимации.",
            "Более 4 лет занимается аналитикой, работой с данными, проектированием и программированием систем.",
            "С легкостью развеет миф о том, что «IT не для девочек»."
          ]}
          num="none"
        />
      ),
      instagram: false,
      buttons: false
    }
  ],
  price: {
    title: "Стоимость обучения",
    installment: <>9490 &#8381;</>,
    full: <>94 900 &#8381;</>, //  полная стоимость
    discountSize: "10%",
    discountDuration: "при записи 1+1",
    qrLink: "https://link.tinkoff.ru/9lmmSt4ZxFh",
    durationInstallment: "10"
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение "
};
