import React from "react";
import List from "../../components/list";
import Video from "../../components/video";
import Paragraph from "../../components/p-array";
import Ig from "../../components/instagram";
import "../imagemaker-stylist/style.css";

export const contents = {
  head: {
    left: (
      <div className="imagemaker-stylist-header">
        <h3 style={{ paddingTop: 0 }}>Обучение профессиям</h3>
        <p>
          Дизайнер интерьера <sup>9 месяцев</sup>
        </p>
        <p>
          Интерьерный стилист <sup>3,5 месяца</sup>
        </p>
        <h3>Личное развитие</h3>
        <p>
          Сам себе дизайнер <sup>1,5 месяца</sup>
        </p>
        <h3>Открытые лекции</h3>
        <div className="imagemaker-stylist-tags">
          <a href="#programma">#программы</a>
          &emsp;
          <a href="#about">#ошколе</a>
          &emsp;
          <a href="#price">#стоимость</a>
        </div>
      </div>
    ),
    right: (
      <div className="imagemaker-stylist-head-logo">
        <img
          src="/img/root-test2/emblema-3d-5.png"
          alt="ВШСДТ Факультет дизайна"
        />
        <h3>Факультет дизайна</h3>
      </div>
    )
  },
  cardInteriorYourLife: {
    image: {
      title: (
        <>
          ИНТЕРЬЕРНЫЙ СТИЛИСТ
          <br />
          ДЕКОРАТОР
        </>
      ),
      image: "/img/interior-design-courses/interior-your-life.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: 3,5 месяца",
      specialisation:
        "Старт в профессии для новичков. Ёмко и насыщенно о самом важном для реализации идей в интерьере квартиры или загородного дома",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            "Концепция современного интерьера",
            "Стилистические особенности формирования интерьера",
            "Цветопсихология и колористика",
            "Основы композиции в дизайне интерьера",
            "Эргономика — основа комфортного интерьера",
            "Замеры, фотофиксация, техническое задание",
            "Формирование концепции интерьера на основе индивидуальных предпочтений клиента"
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/interior-your-life",
        text: "подробнее"
      }
    }
  },

  cardLivingInteriorDesign: {
    image: {
      title: (
        <>
          ПРОФЕССИЯ:
          <br />
          ДИЗАЙНЕР ИНТЕРЬЕРА
        </>
      ),
      image: "/img/interior-design-courses/living-interior-design.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом",
      duration: "Длительность обучения: от 9 месяцев",
      specialisation:
        "База знаний и практик для работы с заказчиками и жилым пространством, включая проектирование в ArchiCAD и 3d визуализации",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>Архитектурные и интерьерные стили</>,
            <>Методики развития креативности</>,
            <>Эргономика, цвет и композиция в интерьере</>,
            <>Строительные нормы и правила</>,
            <>Подготовка сметы и работа с бюджетом</>,
            <>Создание планов помещения: 2D, 3D</>,
            <>Декорирование интерьера</>
          ]}
        />
      ),
      formats: "онлайн, очно, проф, вольный слушатель",
      actions: false,
      link: {
        l: "/living-interior-design-and-decoration",
        text: "подробнее"
      }
    }
  },
  // girl-with-diplom-vsh
  cardTestDrive: {
    image: {
      title: (
        <>
          Профессиональное образование
          <br />
          пробные занятия
        </>
      ),
      image: "/img/imagemaker-stylist/girl-with-diplom-vsh.jpg"
    },
    text: {
      title: (
        <span style={{ lineHeight: "2rem" }}>
          Акция Тест-драйв — первые
          <br />
          две недели обучения
          <br />
          на профессиональных курсах
          <br /> = 900 рублей
        </span>
      ),
      diplom: false,
      duration: false,
      text: (
        <>
          Можно погрузится в профессию дизайнера интерьера уже сейчас. Надо
          выбрать один из профессиональных курсов и просто начать учится.
          Почувствовать атмосферу, познакомиться с преподавателями, понять, что
          все получится. Это точно то, что поможет убедиться в правильном
          выборе.
        </>
      ),
      textStyle: {
        lineHeight: "1.8rem",
        padding: "2rem 0 4rem"
      }
    }
  },
  cardInteriorDesign: {
    image: {
      title: <>САМ СЕБЕ ДИЗАЙНЕР</>,
      image: "/img/imagemaker-stylist/consulting-style-design.jpg"
    },
    text: {
      title: "Интенсив",
      diplom: "Сертификат",
      duration: "Длительность обучения: 1,5 месяца",
      specialisation:
        "Посмотреть по-новому на свой интерьер, избежать разочарований от сотрудничества с подрядчиками, сделать ремонт рационально и с удовольствием",
      process: (
        <List
          num="none"
          olStyle={{ paddingLeft: 0 }}
          arr={[
            <>
              Анализ собственного интерьера и оценка собственных потребностей
            </>,
            <>
              Технологии поиска планировочных решений и цветографическая карта
              интерьера
            </>,
            <>
              Техники и инструменты для выражения собственных идей, доски
              настроения
            </>,
            <>
              Основы эргономики. Какие параметры необходимо соблюдать при
              организации пространства
            </>,
            <>Как составить правильное техническое задание для подрядчика</>
          ]}
        />
      ),
      formats: "онлайн, очно",
      actions: false,
      link: {
        l: "/interior-design",
        text: "подробнее"
      }
    }
  },

  cardConsultingStyleAndDesign: {
    image: {
      title: (
        <>
          САМ СЕБЕ ДИЗАЙНЕР
          <br />
          ИНТЕНСИВ
        </>
      ),
      image: "/img/imagemaker-stylist/consulting-style-design.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Сертификат",
      duration: "Длительность обучения: 1,5 месяца",
      specialisation: (
        <>
          Научитесь проектировать интерьеры не выходя из дома. Всего за 12
          занятий с лучшими преподавателями ВШСДТ из России и Европы
        </>
      ),
      process: (
        <List
          arr={[
            <>
              Как создать гармоничный дизайн своей среды и жизни, используя
              современные приемы стилизации и организации пространства интерьера
            </>,
            <>
              Как с помощью полученных знаний оптимизировать расходы на ремонт
              за счет осознанного контроля смет, подрядчиков и материалов
            </>
          ]}
          num="none"
          olStyle={{ paddingLeft: 0 }}
        />
      ),
      formats: "онлайн, очно",
      actions: false,
      link: {
        l: "/interior-your-life",
        text: "подробнее"
      }
    }
  },
  about: {
    image: (
      <div style={{ textAlign: "center" }}>
        <img
          src="/img/imagemaker-stylist/about.webp"
          alt="about"
          style={{ maxWidth: "100%", transform: "scale(-1,1)", maxHeight: 350 }}
        />
      </div>
    ),
    text: (
      <>
        <Paragraph
          content={[
            <>
              ВШСДТ — АНО ДПО «Высшая школа стилистики, дизайна и технологий»
              одна из ведущих образовательных площадок, основатели которой
              работают в индустрии с 2002 года.
            </>,
            <>
              Мы даем полноценные курсы профессиональной переподготовки и
              повышения квалификации в индустрии моды и дизайна как очно, так и
              дистанционно.
            </>,
            <>
              Мы предоставляем широкий спектр возможностей освоения программ:
              долгосрочные и краткосрочные программы, интенсивы, практикумы и
              многое другое.
            </>
          ]}
          style={{ paddingBottom: "1rem", lineHeight: "2rem" }}
        />
      </>
    ),
    head: "О нас"
  },
  headMiddleForm: (
    <>
      Есть вопросы?
      <br />
      <span
        style={{
          fontFamily: "Roboto",
          fontSize: "1.2rem",
          fontWeight: 400,
          color: "rgb(80,80,80)",
          textShadow: "none"
        }}
      >
        Пишите в мессенджер или отправьте запрос через форму обратной связи
      </span>
    </>
  ),
  lessions: [
    {
      image: {
        image: "/img/imagemaker-stylist/prohorovich.jpg",
        alt: "Елена Прохорович",
        vertical: true
      },
      title: "Какие перспективы у профессии",
      text: (
        <>
          <p>
            Узнайте, сколько перспектив для раз&shy;вития есть в профес&shy;сии
            стилиста, имидж&shy;мейкера. Смотрите успеш&shy;ные кейсы и истории
            изме&shy;нения жизни. Оцените, какие ниши и сег&shy;менты можно
            занять в индус&shy;трии моды. Убедитесь, что это по-настоящему
            по&shy;тряса&shy;ющая и творческая работа.
          </p>
          <p>
            Спикер — Елена Прохорович <Ig a="elenacult_com" />
          </p>
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    },
    {
      image: {
        image: "/img/imagemaker-stylist/openls.jpg",
        alt: "Открытые лекции",
        vertical: false
      },
      title: "Открытые лекции в партнерском проекте Арт ЛекториУм.",
      text: (
        <>
          <p>Сегодня доступны:</p>
          <List
            arr={[
              <>
                Louis Vuitton. Путе&shy;шес&shy;твия с рос&shy;кошью (Руслан
                Миг&shy;ранов)
              </>,
              <>Стильный бодипозитив. Одежда +size (Татьяна Фомина)</>,
              <>
                Манипуляция цветом. Психологический аспект и неожиданный эффект
                (Лиана Бахова)
              </>,
              <>
                Индивидуальный мор&shy;ской стиль (Свет&shy;лана Шаба&shy;лина)
              </>
            ]}
            classLi="p"
            olStyle={{ marginLeft: 0, paddingLeft: "1rem" }}
          />
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    },
    {
      image: {
        image: "/img/imagemaker-stylist/action.jpg",
        alt: "Тест-драйв проф",
        vertical: false
      },
      title: "Тест-драйв проф",
      text: (
        <>
          <p>
            Акция Тест-драйв — первые две недели обу&shy;че&shy;ния на
            профес&shy;сиональ&shy;ных курсах = 900 рублей. Можно
            погру&shy;зиться в профес&shy;сию имидж&shy;мейкера-стилиста уже
            сейчас. Почув&shy;ствовать атмосферу, позна&shy;комить&shy;ся с
            препо&shy;дава&shy;телями, понять, что все получится. Это точно то,
            что поможет сделать пра&shy;виль&shy;ный выбор
          </p>
          <p>
            <a href="#register">Записаться &gt;&gt;&gt;</a>
          </p>
        </>
      )
      // button: true,
    }
  ],
  boldText: {
    boldText: <>Набор групп на профессиональное обучение открыт с</>,
    text: false
  },
  garanty: {
    title: "Финансовая гарантия",
    text: (
      <>
        В программе действует безусловная гарантия на возврат 100% денег в
        течении 14 дней. Это значит, что если вы по какой-то причине передумаете
        учавствовать, мы вернем всю сумму
      </>
    )
  },
  rgsu: {
    text: (
      <p className="p-constructor">
        Стратегический партнер ВШСДТ — Российский государст&shy;венный
        соци&shy;аль&shy;ный университет.
      </p>
    ),
    paperText: (
      <>
        <p className="p-constructor">
          Получите два диплома, ВШСДТ и РГСУ, выбирая образо&shy;вание как
          Второе высшее.
        </p>
        <p className="p-constructor">
          Программа двух дипломов для тех, кто ориентируется на максимальный
          результат и погружение. Более 12 месяцев обучения, 620
          академи&shy;ческих часов, полный набор модулей.
        </p>
      </>
    ),
    slides: [
      "/img/imagemaker-stylist/diplom.jpg",
      "/img/imagemaker-stylist/diplom-2.jpg"
    ]
  },
  digitPuntcs: [
    <>
      Любите стиль и дизайн, сейчас в поисках себя или в поиске творческой
      профессии, хотите получать дополнительный доход
    </>,
    <>
      У Вас бизнес в индустрии дизайна среды, вы совершенствуете сервис и
      методики продаж для клиентов
    </>,
    <>
      Вы практикующий специалист, преподаватель, и планируете расширить
      компетенции и спектр услуг
    </>
  ],
  dates: {
    ofline: {
      day: 1,
      month: "июня"
    },
    online: {
      day: 19,
      month: "мая"
    }
  },
  tesises: {
    head: "Чему вы научитесь",
    content: [
      {
        title: "Преображаться и удивлять",
        text: (
          <>
            Идеально формировать свой стиль, рационально подбирать гардероб,
            изменяться по настроению и получать удовлетворение от шопинга
          </>
        )
      },
      {
        title: "Создавать стиль для окружающих",
        text: (
          <>
            Осознанно подбирать ком­плекты одежды в соот­­вет­ствии с модными
            тенден­циями, индивидуальным стилевым вектором и психотипом человека
          </>
        )
      },
      {
        title: "Ориентироваться в fashion-индустрии",
        text: (
          <>
            Понимать модные тенденции, находить fashion-хиты и супер идеи для
            новых сезонов, создавать яркие образы и креативные концепции
          </>
        )
      },
      {
        title: "Общаться с командой и fashion-брендами",
        text: (
          <>
            Подбирать команду для fashion проектов, стилизовать съемки, работать
            с модными брендами и шоурумами, создавать креативную атмосферу
          </>
        )
      },
      {
        title: "Продвигать себя и свои услуги",
        text: (
          <>
            Формулировать стратегию личного бренда, прокачивать себя,
            зарабатывать и полноценно консуль­­­тировать женщин и мужчин по
            имиджу и стилю
          </>
        )
      },
      {
        title: "Изменять мир вокруг себя",
        text: (
          <>
            Использовать изученные имидж технологии для успеха в карьере,
            бизнесе и личной жизни, выходить на более дорогие уровни
            коммуникаций
          </>
        )
      }
    ]
  },
  education: (
    <>
      <div style={{ maxWidth: 600, margin: "4rem auto" }}>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Обучение в ВШСДТ — это отличная теоретическая подготовка в сочетании с
          формированием творческого мышления и регулярной практикой создания
          модных и стильных образов
        </p>
        <div style={{ margin: "1rem auto", textAlign: "center" }}>
          <svg width={64} height={64} viewBox="0 0 24 24">
            <path
              fill="rgb(160,160,160)"
              d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"
            />
          </svg>
        </div>
        <p
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            lineHeight: "2rem"
          }}
        >
          Получение системных знаний, новые возможности профессионального
          развития, технологии работы с клиентами и модными трендами.
        </p>
      </div>
    </>
  ),
  formats: [
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z"
          />
        </svg>
      ),
      title: "Банковская рассрочка",
      price: false,
      text: (
        <>
          от 5750 рублей в месяц
          <br />
          рассрочка на год без переплат
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            fill="rgb(122,101,89)"
            d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
          />
        </svg>
      ),
      title: "Онлайн / Заочно",
      price: false,
      text: (
        <>
          от 15000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    },
    {
      image: (
        <svg width={64} height={64} viewBox="0 0 24 24">
          <path
            d="M20,17C21.1,17 22,16.1 22,15V4C22,2.9 21.1,2 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9C1.5,7.9 2.4,7 3.5,7H15M8,4C8,5.1 7.1,6 6,6C4.9,6 4,5.1 4,4C4,2.9 4.9,2 6,2C7.1,2 8,2.9 8,4Z"
            fill="rgb(122,101,89)"
          />
        </svg>
      ),
      title: "Очное обучение",
      price: false,
      text: (
        <>
          от 20000 рублей в месяц
          <br />
          оплата по договору
        </>
      ),
      textStyle: {
        textAlign: "center",
        paddingTop: "2rem"
      }
    }
  ],
  youtube: {
    head: false,
    text: (
      <>
        <p className="p-constructor text-center">
          Подписывайтесь на Youtube канал ВШСДТ ТВ:
          <br />
          интервью с преподавателями, экспертами, выпускниками, открытые лекции,
          обзоры трендов
        </p>
        <p className="text-center">
          <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
            Перейти на Youtube канал &gt;&gt;&gt;
          </a>
        </p>
      </>
    )
  },
  papersTitle: "Три варианта обучения, стоимость"
};
