// import React from "react";
import SimpleSchema from "simpl-schema";
import SimpleSchema2Bridge from "uniforms/SimpleSchema2Bridge";
// import { utm } from "url-utm-params";
// import { Redirect } from "react-router";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

// import Window from "./components/submit-message-window";

const axios = require("axios");

export const Rules = {
  fullname: {
    label: "Ваше имя",
    type: String,
    optional: true
  },
  phone: {
    label: "Телефон",
    type: String,
    regEx: SimpleSchema.RegEx.Phone
  },
  email: {
    type: String,
    label: "E-mail",
    regEx: SimpleSchema.RegEx.EmailWithTLD,
    optional: true
  },
  consent: {
    type: Boolean,
    allowedValues: [true],
    defaultValue: true
  },
  comment: {
    type: String,
    optional: true
  },
  target: {
    type: String
  },
  formName: {
    type: String
  }
};

export const onSubmit = doc => {
  const options = {
    method: "post",
    url: "/_forms-submit",
    data: {
      title: document.title,
      user: doc,
      userId: localStorage.userId,
      location: {
        href: window.location.href,
        pathname: window.location.pathname
      }
      // utms: utm(window.location.href)
    }
  };

  const callTouchOptions = {
    method: "get",
    url:
      "https://api-node13.calltouch.ru/calls-service/RestAPI/requests/35712/register/",
    params: {
      subject: window.location.href.split("?")[0],
      sessionId: window.call_value,
      fio: doc.fullname,
      phoneNumber: doc.phone,
      email: doc.email,
      comment: doc.comment
    }
  };

  // https://api.styleschool.ru/api/webhooks/styleschool
  const telegramBot = {
    method: "post",
    url: "https://api.styleschool.ru/api/webhooks/styleschool",
    data: {
      comment: doc.comment,
      email: doc.email,
      form: doc.formName, // "feedback", // form-id,
      name: doc.fullname,
      phone: doc.phone,
      url: window.location.href
    }
  };

  console.log(doc);
  // console.log(callTouchOptions);

  if (doc.target != "0") {
    ym("reachGoal", doc.target);
    ReactGA.event({
      category: doc.target,
      action: "click",
      label: "main"
    });
    ReactPixel.track("SubmitApplication", doc.target);
  }

  axios(options);
  axios(callTouchOptions).then(response => console.log(response));
  axios(telegramBot)
    .then(response => console.log(`axios telegramBot: ${response}`))
    .catch(error => console.log(error));
  setTimeout(() => {
    // document.location = "/thankyou-page";
  }, 2000);
};

export const Schema = new SimpleSchema(Rules);

export const Bridge = new SimpleSchema2Bridge(Schema);
