import React from "react";
import List from "../../components/list";
import Papers from "../self-style/papers";
import Button from "../../components/button-messages";
import { dates, Month } from "../shedule/dates";
import { ScrollingLink } from "../../patterns/pattern-fs/components";
import { styles2 as styles } from "./styles";

const online = `${dates.interiorDesign.online.getDate()} ${Month(
  dates.interiorDesign.online.getMonth()
)}`;
/*
const online2 = `${dates.interiorDesign.online2.getDate()} ${Month(
  dates.interiorDesign.online2.getMonth()
)}`;
const ofline = `${dates.interiorDesign.ofline.getDate()} ${Month(
  dates.interiorDesign.ofline.getMonth()
)}`;
*/

export const contents = {
  titlePage: "Стилист-декоратор интерьера. Интенсив",
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Сам себе дизайнер
        <br />
        Стилистика интерьера
      </>
    ),
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Вы сможете посмотреть по-новому на свой интерьер, сделать его
        персонализированным и отражающим ваш внутренний мир, избежать финансовых
        потерь и разочарований от сотрудничества с подрядчиками
      </div>
    ),
    descript: [
      <>Онлайн курс: видео + живые занятия</>,
      <>Новые идеи за 1 месяц регулярных занятий в мини группе</>,
      <>Длительность — 8 занятий по полтора часа</>,
      <>Стоимость 25 тыс. рублей</>
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/imagemaker-stylist/consulting-style-design.jpg",
    imageBackground: true,
    styleImage: {
      maxHeight: 334
    },
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      }
      // {
      //   link: "#price",
      //   text: "стоимость"
      // }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ]
  },
  text3block: [
    {
      title: "Даты",
      text: [<>Онлайн с {online}</> /*, <>Онлайн с {online2}</>*/]
    },
    {
      title: "Формат",
      text: [<>Теория и практика,</>, <>чат в группе, идеи для квартиры</>]
    },
    {
      title: "Спец. условия",
      text: [<>Специальные условия</>, <>и общественных организаций</>]
    }
  ],
  teachers: [
    {
      image: "/img/teachers/kostarnova.jpg",
      name: <>Марина Костарнова (Бельгия), преподаватель online курса</>,
      text: (
        <>
          <p>Дизайнер & декоратор & coach</p>
          <p>Практик и теоретик дизайна</p>
          <p>Специалист в области концептуального средового дизайна</p>
          <p>Член международного союза дизайнеров</p>
        </>
      ),
      instagram: "mara_van_brussels",
      buttons: {
        detail: "/marina_kostarnova",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/lamonova_g.jpg",
      name: "Елена Ламонова (Москва), преподаватель очного курса",
      text: (
        <>
          Дизайнер. Специалист в области интерь&shy;ерного, ландшафтного,
          предмет&shy;ного ди&shy;зайна, создатель концепций и проектов
          оформ&shy;ления меро&shy;приятий всерос&shy;сий&shy;ского уровня,
          создатель собст&shy;венного дизайн бюро, преподаватель Высшей школы
          стилистики
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/elena-lamonova",
        linkInside: true
      }
    }
  ],
  text1: {
    text: (
      <>
        <h3 className="p-constructor bold">На курсе Вы узнаете</h3>
        <List
          arr={[
            <>
              Что такое «настроение»интерьера и почему так важно его чувствовать
            </>,
            <>Новые стили и тенденции интерьера 2021-2025</>,
            <>Что такое цветовой круг и колористика</>,
            <>Как работают цвет и текстуры в интерьере</>,
            <>Почему композиция — основа стильного и уютного интерьера</>,
            <>Познакомимся с понятием «moodboord» и узнаем зачем он нужен</>,
            <>
              Какие есть доступные программы и приложения для графического
              проекта интерьера
            </>,
            <>Создадим «moodboard» и «stylboard»для своего интерьера</>
          ]}
          classLi="p-constructor"
        />
      </>
    ),
    image: (
      <div
        className="image-bkg"
        style={{ backgroundImage: "none", margin: "0 auto" }}
      >
        <img src="/img/interior-design/how-you.jpg" className="h" />
      </div>
    )
  },
  news: {
    image: "/img/news/021021.png",
    title: "В январе 2022 года курс по специальной цене — 2000 руб.",
    link:
      "https://technograd.moscow/corps/online/vebinary/6082.html?utm_source=styleschool.ru",
    noLink: true,
    grayText: (
      <>
        В январе данный курс мы запускаем совместно с нашим партнером,
        иновационно-образовательным комплексом Техноград. Занятия будут
        проходить онлайн на платформе Технограда по вторникам и четвергам с
        18:00-19:30 по мск. Участникам занятия будут доступны в записи. Название
        курса в Технограде: Дизайн интерьера. Предварительная запись обязательна{" "}
        <a
          href="https://technograd.moscow/corps/online/vebinary/6082.html?utm_source=styleschool.ru"
          target="_blank"
        >
          здесь &gt;&gt;&gt;
        </a>
      </>
    )
  },
  howIsTheTrainingGoing: [
    {
      title: "Видео уроки",
      text: (
        <>
          Каждую неде­лю Вы изу&shy;ча­ете видео уроки в сво&shy;бод&shy;ном,
          ком&shy;­форт&shy;­ном ритме.
        </>
      )
    },
    {
      title: "Домашние задания",
      text: (
        <>
          После каждого урока полу&shy;­чаете до&shy;маш­&shy;ние зада&shy;ния
          по изуча&shy;емым темам.
        </>
      )
    },
    {
      title: "Онлайн общение",
      text: (
        <>
          Регулярно — живое обще&shy;­­ние, помощь в соз&shy;дании обра&shy;зов
          на каждый день
        </>
      )
    },
    {
      title: "Stylboard",
      text: (
        <>
          В итоге — соз&shy;да­­е&shy;те презен&shy;та&shy;цию идей для
          свое&shy;го ин&shy;терь&shy;ера и счи&shy;таете смету
        </>
      )
    }
  ],
  insertAfterDates: (
    <>
      <ScrollingLink scrollLink="price" />
      <div style={{ height: "4rem" }} />
      <h2 style={styles.h2}>Варианты обучения, стоимость</h2>
      <Papers
        content={[
          {
            title: "Видео курс",
            gray: (
              <>
                <strong>5000 &#8381;</strong>
              </>
            ),
            text: (
              <>
                <List
                  arr={[
                    <>Доступ к 8 видео занятиям</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>
                      Доступна банковская рассрочка без переплат на 10 месяцев
                    </>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          },
          {
            title: "Online курс",
            gray: (
              <>
                <strong>20000 &#8381;</strong>
              </>
            ),
            text: (
              <>
                <List
                  arr={[
                    <>Доступ к 8 видео занятиям</>,
                    <>Просмотр занятий в удобное время</>,
                    <>Доступ к курсу в течение года после покупки</>,
                    <>Домашние задания</>,
                    <>Обратная связь по каждому ДЗ</>,
                    <>4 персональные часовые консультации с преподавателем</>,
                    <>Сертификат после обучения</>,
                    <>
                      Доступна банковская рассрочка без переплат на 10 месяцев
                    </>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          },
          {
            title: "Очный курс",
            gray: <strong>30000 &#8381;</strong>,
            text: (
              <>
                <List
                  arr={[
                    <>Доступ к 8 видео занятиям</>,
                    <>Плюс 8 занятий по 2 часа в очном формате</>,
                    <>Практические домашние задания</>,
                    <>
                      Проверка и обратная связь от преподавателя по домашним
                      заданиям
                    </>,
                    <>Защита проектной работы</>,
                    <>Сертификат после обучения</>,
                    <>
                      Доступна банковская рассрочка без переплат на 10 месяцев
                    </>
                  ]}
                  style={{
                    textAlign: "left",
                    paddingBottom: ".6rem"
                  }}
                  olStyle={{
                    marginTop: "2rem"
                  }}
                />
                <Button
                  linkType="component"
                  componentLink={
                    <div
                      className="button-white-in-black"
                      style={{ margin: "1rem auto" }}
                    >
                      записаться
                    </div>
                  }
                />
              </>
            )
          }
        ]}
      />
      <div style={{ height: "6rem" }} />
      <h2 style={styles.h2}>Акции</h2>
      <div
        style={{
          border: "2px solid rgb(240,240,240)",
          borderRadius: 6,
          margin: "0 auto",
          padding: "2rem",
          background: "rgb(200,200,200)",
          textAlign: "center",
          color: "rgb(255,255,255)",
          fontWeight: 700,
          fontFamily: "Roboto",
          fontSize: "1.5rem",
          lineHeight: "3rem"
        }}
      >
        Приглашайте друзей,
        <br />
        1+1 = скидка 20%
      </div>
    </>
  ),
  tilesProgramma: [
    {
      image: {
        image: "/img/interior-design/less1.png",
        alt: "Занятие 1",
        vertical: false
      },
      title: "Занятие 1",
      text: <>Стили и тенденции современного интерьера</>
    },
    {
      image: {
        image: "/img/interior-design/less2.png",
        alt: "Занятие 2",
        vertical: false
      },
      title: "Занятие 2",
      text: <>Цвет и композиция в интерьере</>
    },
    {
      image: {
        image: "/img/interior-design/less3.png",
        alt: "Занятие 3",
        vertical: true
      },
      title: "Занятие 3",
      text: <>Текстуры и современные материалы в интерьере</>
    },
    {
      image: {
        image: "/img/interior-design/less4.png",
        alt: "Занятие 4",
        vertical: false
      },
      title: "Занятие 4",
      text: <>Как найти тему и идею вдохновения</>
    },
    {
      image: {
        image: "/img/interior-design/less5.png",
        alt: "Занятие 5",
        vertical: false
      },
      title: "Занятие 5",
      text: <>Moodboard — настроение интерьера</>
    },
    {
      image: {
        image: "/img/interior-design/less6.png",
        alt: "Занятие 6",
        vertical: false
      },
      title: "Занятие 6",
      text: <>Графическое программы для реализации идеи</>
    },
    {
      image: {
        image: "/img/interior-design/less7.png",
        alt: "Занятие 7",
        vertical: false
      },
      title: "Занятие 7",
      text: <>Графическое оформление идеи в коллаже</>
    },
    {
      image: {
        image: "/img/interior-design/less8.png",
        alt: "Занятие 8",
        vertical: false
      },
      title: "Занятие 8",
      text: <>Создание STYLBOORD для вашего интерьера</>
    }
  ],
  slides: [
    {
      i: "/img/interior-stylist-decorator/slide01.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide02.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide03.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide04.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide05.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide06.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide07.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide08.jpg",
      o: 0
    },
    {
      i: "/img/interior-stylist-decorator/slide09.jpg",
      o: 0
    }
  ]
};
