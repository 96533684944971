import React from "react";

export const content = {
  title: "Прайс лист",
  lable: "Прайс лист",
  head: "Прайс лист",
  before: [
    <>
      <strong>Доступные курсы:</strong> тест-драйв 900 &#8381; за 2 недели
    </>,
    <>
      Экспресс онлайн курс по имиджу и стилю 5 000 &#8381; (12 занятий по 45
      минут)
    </>,
    <>Онлайн курс дизайнер интерьера 5 000 рублей (8 занятий по 1,5 часа)</>
  ],
  courses: [
    {
      title: "Стилист-имиджмейкер",
      duration: "12 месяцев",
      formats: "очно, онлайн",
      price: {
        ofline: <>250 000 &#8381; / 3400 $ / 2700 €</>,
        online: <>180 000 &#8381; / 2400 $ / 1945 €</>
      },
      credit: <>15 000 &#8381; (в месяц) до 12 месяцев</>
    },
    {
      title: "Консультант по стилю",
      duration: <>3.5&nbsp;месяца</>,
      formats: "онлайн",
      price: <>69 000 &#8381; / 920 $ / 745 €</>,
      credit: <>5 750 &#8381; (в месяц) на 12 месяцев</>
    },
    {
      title: "Профессия интерьерный стилист, декоратор",
      duration: <>3.5&nbsp;месяца</>,
      formats: "онлайн",
      price: <>69 000 &#8381; / 920 $ / 745 €</>,
      credit: <>5 750 &#8381; (в месяц) на 12 месяцев</>
    },
    {
      title: "Профессия стилист фотосессий",
      duration: <>4&nbsp;месяца</>,
      formats: "очно, онлайн",
      price: {
        ofline: <>90 000 &#8381; / 1200 $ / 973 €</>,
        online: <>60 000 &#8381; / 800 $ / 649 €</>
      },
      credit: <>7 500 &#8381; (в месяц) на 12 месяцев</>
    },
    {
      title: "Сам себе дизайнер",
      duration: <>1&nbsp;месяц</>,
      formats: "очно, онлайн",
      price: {
        ofline: <>32 000 &#8381; / 400 $ / 346 €</>,
        online: <>20 000 &#8381; / 270 $ / 216 €</>
      },
      creditOne: (
        <>
          <strong>Рассрочка</strong> онлайн обучения: 2 500 &#8381; (в месяц) на
          12 месяцев
        </>
      )
    },
    {
      title: (
        <>
          Сам себе стилист
          <br />
          мастерская Светланы Шабалиной
        </>
      ),
      duration: <>1&nbsp;месяц</>,
      formats: "очно, онлайн",
      price: {
        ofline: <>32 000 &#8381; / 427 $ / 346 €</>,
        online: <>20 000 &#8381; / 262 $ / 216 €</>
      },
      creditOne: (
        <>
          <strong>Рассрочка</strong> онлайн обучения: 2 500 &#8381; (в месяц) на
          12 месяцев
        </>
      )
    }
  ],
  after: <>*Обучение как вольный слушатель — скидка 10%</>
};
