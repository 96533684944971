import React from "react";
import Page from "../../components/page";
import Helmet from "react-helmet";
import { BuilderLayout, TitleH2 } from "../../patterns/pattern-fs/components";
import { Grid } from "@material-ui/core";
import List from "../../components/list";
import { Link } from "react-router-dom";
import "./style.css";

function Text(props) {
  const c = props.content;
  return (
    <>
      {c.map(el => (
        <>
          <p className="p-constructor">
            <strong>{el.title}</strong>
          </p>
          <List arr={el.text} classLi="p-constructor" />
        </>
      ))}
    </>
  );
}

export function Person(props) {
  const c = props.content;
  return (
    <>
      <Helmet>
        <title>{c.name}</title>
        <meta name="keywords" content={c.meta.keywords} />
        <meta name="descript" content={c.meta.descript} />
      </Helmet>
      <Page lable={c.lable}>
        <BuilderLayout
          contents={[
            <>
              <TitleH2 content={c.name} />
              <div className="image-container">
                <img src={c.image} alt={c.name} />
              </div>
              <Text content={c.text} />
            </>
          ]}
        />
      </Page>
    </>
  );
}

export function Short(props) {
  const c = props.content;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <h3>{c.title}</h3>
        <p className="p-constructor">{c.text}</p>
        <Link to={c.link}>
          <div
            className="button"
            style={{ marginLeft: 0, textDecoration: "none" }}
          >
            подробнее
          </div>
        </Link>
      </Grid>
      <Grid item xs={12} md={4}>
        <img src={c.image} alt={c.title} style={{}} />
      </Grid>
    </Grid>
  );
}
