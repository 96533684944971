import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
// import { Link } from "react-router-dom";
import { dates, Month, DateToString } from "../shedule/dates";
import Slider from "../../components/slider-rk";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0,
    lineHeight: "1.4rem"
  }
};

export const contents = {
  head: {
    title: "Школа юных стилистов и дизайнеров. Style School Kids.",
    headDescript: "Дополнительное образование",
    head: <>Школа юных стилистов и дизайнеров. Style School Kids.</>,
    descript: [
      <>Очно, онлайн</>,
      <>Очные занятия проводятся бесплатно 2 раза в месяц по субботам</>,
      <>Онлайн занятия запланированы на 2022 год</>,
      <>Документ: сертификат</>,
      <>Длительность 1 занятия: 2 часа</>
    ],
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Вкус надо воспитывать с детства!
        <br />
        Мы погружаем подростков 12-16 лет в мир моды и развиваем креативные
        навыки
      </div>
    ),
    text: "",
    imageSource: "/img/young-fashion/img-02.jpg",
    // video: "7jVcZHuP4MU",
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#register",
        text: "запись"
      }
    ],
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (916) 058 00 29",
        phoneLink: "+79160580029",
        name: "Ксения",
        tg: "Ksenyastyle",
        wa: "79160580029"
      }
    ],
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    sizeVideo: true
  },
  screen2: {
    text: (
      <>
        <h3>Для кого</h3>
        <p>
          Программа курса рас&shy;счи&shy;тана на тех, кто толь&shy;ко начинает
          знакомство с миром стиля и красоты. А также для тех, кто хочет
          научиться разбираться в тонкостях создания своего личного образа,
          подборе гардероба и формировании имиджа.
        </p>
      </>
    ),
    image: <img src="/img/young-fashion/img-01.jpg" style={{ maxWidth: 320 }} />
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [
        <>
          очно с{" "}
          {dates.youngFashion.online.getDate() +
            " " +
            Month(dates.youngFashion.online.getMonth())}
        </>
      ]
    },
    {
      title: "Преподаватели",
      text: [<>Преподаватели ВШСДТ</>, <>Практикующие стилисты</>]
    },
    {
      title: "Место проведения",
      text: [<>ВДНХ</>, <>Техноград</>]
    }
  ],
  text1:
    "Мы погружаем подростков в мир моды через познание основ фэшн стилистики и подбора имиджа, использования методов развития креативности, принципов разработки коллекций одежды, реализации фото и рекламных проектов, работы с фэшн брендами и селебрити",
  schedule: (
    <>
      <p>
        9 октября, суббота, 13:00, Урбан.Техноград, 3 этаж, Л3. Тема: Теория
        цвета. Занятие проводят: Наталья Устиненкова. Стилист,
        имидж-консультант, персональный шоппер. Анна Гилёва.
        Стилист-имиджмейкер, преподаватель ВШСДТ.{" "}
        <a
          href="https://technograd.moscow/corps/poster/shkola_junogo_stilista/6706.html"
          target="_blank"
        >
          Запись открыта
        </a>
      </p>
      <p>
        <strong>Запланированные дни занятий:</strong>
      </p>
      <p>23 октября, 6 ноября, 20 ноября, 4 декабря, 18 декабря 2021 года</p>
    </>
  ),
  programma: {
    head: "Программа занятий на 2021-2022 гг.",
    content: [
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Вы узнаете
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "основы фэшн стилистики и подбора имиджа",
          "использование методов развития креативности",
          "принципы разработки коллекций одежды",
          "реализации фото и рекламных проектов",
          "как работать с фэшн брендами и селебрити"
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Вы получите
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          "способность развить собственный вкус и индивидуальный стиль.",
          "практические навыки и необходимый опыт в fashion индустрии",
          "возможность карьерного развития в профессиях: дизайнера одежды, стилиста, менеджера fashion проектов.",
          "практические занятия и воркшопы от экспертов индустрии"
        ]}
      />,
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Цель курса
          </h2>,
          "В течение учебного года погрузить Ваших детей в занятия различными направлениями: работой с цветом и композицией, креативными техниками и фотосъемкой, творческой «наукой» формирования собственного стиля, основами создания одежды и fashion-иллюстрации, созданием текстов о моде стиле.",
          "Помимо специализированных знаний и навыков они будут учиться умению работать в команде, анализировать свои и чужие работы, проводить презентации и создавать портфолио, а опытные преподаватели-практики помогут найти сильные стороны, развить мотивацию, оценить способности, вдохновить на исследование окружающего мира и эксперименты."
        ]}
      />,
      <h2
        style={{
          ...styles.h2,
          fontFamily: "PT Sans Narrow",
          textAlign: "left"
        }}
      >
        Содержание курса
      </h2>,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Мода, история и современность.</strong>,
          "Стили и образы «подиумной» и «уличной» моды. Правила сочетания стилей. Сезонные тренды и кто их формирует."
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Цвет и цветовые сочетания.</strong>,
          "Основные характеристики цвета. Психология цвета. Как правильно подобрать свои цвета."
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>
            Одежда, которая тебя украшает/Основа твоего гардероба.
          </strong>,
          "Выбор силуэта, формы деталей, соответствующих фигуре и внешности.",
          "Выбор ткани, рисунка и принтов. Правила выбора аксессуаров и украшений"
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Креативные техники в создании визуальных образов.</strong>,
          "Кастомайзинг — индивидуальность своими руками. Идеи и техники декорирования.",
          "Создание мудборода. Лучшие мировые проекты как источник вдохновения"
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Основы дизайна одежды.</strong>,
          "Как создается коллекция: от ткани и разработки образов до продажи.",
          "Особенности и возможности современных тканей и техник в создании одежды",
          "Производители и производство коллекций."
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Fashion-иллюстрация, скетчинг.</strong>,
          "Основы дизайнерского рисунка.",
          "Источники вдохновения. Стили и техника."
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Основы fashion съёмки.</strong>,
          "Организация фотосъемки. Выбор идеи. Создание мудборда. Выбор образа, локации, команды. Практическое пробное занятие-фотосъемка «Основы фотосессии»."
        ]}
      />,
      <List
        style={styles.p}
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        num="none"
        arr={[
          <strong>Личный бренд и карьера.</strong>,
          "Оформление соц сетей. Тексты о стиле и моде. Правила создания коротких текстов и постов. Практические занятия по созданию визуального образа (фото и текст) в Инстаграм."
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/fashion-styling/maltseva-st.jpg",
      name: "Евгения Мальцева",
      text: (
        <List
          arr={[
            "Режиссер-постановщик модных показов",
            "Преподаватель курса «Стилизация модных показов» в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
            "Президент Siberian Fashion Week. CEO / Founder European Model Academy",
            "Профессиональный коуч по дисциплинам: дефиле, фотопозирование. Автор мастер-классов для профессиональных моделей и директоров модельных агентств"
          ]}
        />
      ),
      instagram: "evmaltseva",
      buttons: false
    },
    {
      image: "/img/teachers/320x240/marina-bonetskaya.jpg",
      name: "Марина Бонецкая",
      text:
        "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
      instagram: false,
      buttons: {
        detail: "/marina_bonetskaya",
        linkInside: true,
        video: "IUMo8Cp92Kk"
      }
    },
    {
      image: "/img/fashion-styling/stulov-st.jpg",
      name: "Евгений Стулов",
      text: (
        <List
          arr={[
            "Фотограф",
            "Преподаватель курса по специфике работы стилиста на fashion съемках; истории и практической стороне фотографии в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
            "Креативный директор Siberian Fashion Week",
            "Музыкальный продюсер, композитор, основатель и участник электронного проекта SOLARIS"
          ]}
        />
      ),
      instagram: "solaris_foto",
      buttons: {
        detail: false,
        video: "Fps72ns-MyI"
      }
    },
    {
      image: "/img/teachers/320x240/tatiyana-fomina.jpg",
      name: "Татьяна Фомина",
      text: (
        <List
          arr={[
            "Имидж-стилист, дизайнер",
            "Куратор курса и старший преподаватель дисциплин  имиджа и стилистики в Высшей школе стилистики дизайна и технологий",
            "Спикер «3-го Международного Съезда Стилистов»…"
          ]}
        />
      ),
      instagram: false,
      buttons: {
        detail: "/tatiana_fomina",
        linkInside: true,
        video: "8s_7e-79VUI",
        videoText: "видео "
      }
    }
  ],
  pay: {
    online: {
      textButton: "Онлайн обучение",
      text: (
        <div>
          <h3 style={styles.h3}>Стоимость и характеристики онлайн обучения</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: свидетельство об обучении
            <br />
            Длительность: октябрь-май, субботы, 2 занятия по 45 минут
            <br />
            Формат обучения: занятия проходят онлайн с преподавателем
            <br />
            Все занятия доступны в записи в личном кабинете
            <br />
            Стоимость 1 занятия: 650 рублей
            <br />
            Возможно внесение единовременной оплаты за месяц со скидкой 10%
          </div>
          {/* <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты:{" "}
                  <strong>
                    9<sup>900</sup> рублей
                  </strong>{" "}
                  — ежемесячные платежи
                </>,
                <>Вариант оплаты: единовременно за 7 месяцев со скидкой 10%</>,
                "Важно: по запросу мы предоставляем документы для налогового вычета"
              ]}
              style={style.text}
              num="digital"
            />
          </div> */}
        </div>
      )
    }
  }
};
