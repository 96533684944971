import React from "react";
import Pattern from "./pattern";
import { style } from "./style";

const Standart = props => {
  return (
    <div>
      <Pattern
        title="Образовательные стандарты"
        content={
          <p style={style.text}>
            В соответствии с Федеральным законом от 29 декабря 2012 г. N 273-ФЗ
            «Об образовании в Российской Федерации» (далее — Федеральный закон N
            273-ФЗ) содержание дополнительных профессиональных программ,
            разрабатываемых АНО ДПО ВШСДТ учитывает профессиональные стандарты,
            квалификационные требования, указанные в квалификационных
            справочниках по соответствующим должностям, профессиям и
            специальностям (часть 9 статьи 76 Федерального закона N 273-ФЗ). При
            этом программы профессиональной переподготовки разработаны на
            основании установленных квалификационных требований,
            профессиональных стандартов и требований соответствующих ФГОС
            среднего профессионального и (или) высшего образования к результатам
            освоения образовательных программ (часть 10 статьи 76 Федерального
            закона N 273-ФЗ).
          </p>
        }
      />
    </div>
  );
};

export default Standart;
