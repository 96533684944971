import React from "react";

export default function Descript() {
  return (
    <div
      style={{
        width: "100%",
        border: "5px dashed rgb(232,232,232)",
        padding: 12,
        boxSizing: "border-box"
      }}
    >
      <p
        style={{
          font: "400 1em 'Roboto'",
          color: "rgb(100,100,100)",
          textAlign: "justify",
          padding: 0,
          margin: 0
        }}
      >
        Программа &laquo;Профессиональный make-up: стилистика и
        технологии&raquo; как программа дополнительного профессионального
        образования визажистов-стилистов разработана специалистами НОЧУ ЦДО
        &laquo;Высшая школа стилистики&raquo; совместно с образовательным
        проектом &laquo;ProMakeupArtist School Prague&raquo; (Прага, Чехия) и
        утверждена Российским государственным университетом туризма и сервиса в
        апреле 2017 года. По итогам обучения и успешной сдачи экзаменов
        слушатели получают диплом установленного образца Университета с правом
        деятельности в указанной области. Подробнее о приеме на обучение и
        процессе обучения Вы можете узнать у наших специалистов, используя форму
        обратной связи или по указанным контактам.
      </p>
    </div>
  );
}
