import React, { useState } from "react";
import { Grid, Hidden, Paper, Modal } from "@material-ui/core";
import TwoBlocks from "../../components/two-blocks-with-bkg";
import List from "../../components/list";
import TwoBlocks2 from "./two-blocks";
import { stylesDark } from "../../components/styles";
import Paragraph from "../../components/p-array";
import FormatsElement from "./formats";
import Papers from "./table";
import Block from "./block";
import Socnet from "../../components/want-to-know";
import Pay from "./pay";
import ProgrammaElement from "./programma";
import Button from "../../components/button-messages";
import ButtonLink from "./button-black";
import ButtonVideo from "./button-video";
import DigitText from "./digit-and-text";
import Video from "../../components/video";
import Space from "./space";
import ScrollableAnchor from "react-scrollable-anchor";
import Tags from "./tags";
import TextBlock from "./text-block";
import Slider from "../../components/slider-rk";
import SliderVideo from "./slider-for-video";
import SliderInHeader from "./slider-in-header";
import ListInfo from "./list";
import Teacher from "./teacher-element";
import GridDates from "./grid-dates";
import GridProg from "./grid-programma";
import SpolerNew from "./spoler-new";
import Sequence from "../../components/sequence/sequence";
import { ShessBuilder as ShessBuilderInfographica } from "../../components/infographica/infographica";
import "./style.css";
import "./style-for-constructor.css";
import StringContact, {
  StringContacts
} from "../../components/string-contact/string-contact";
import { VerticalForm } from "../../components/forms";
import SocNetIcons from "../../components/socnet-icons";

const fontTitle = "PT Sans Narrow";
const font = "Roboto";
const fontCursive = {
  fontFamily: "Lora",
  fontStyle: "italic"
};
const gold = "rgb(122,101,89)";
const styles = {
  ...stylesDark,
  h1: {
    fontFamily: fontTitle,
    fontSize: "4rem",
    fontWeight: 400,
    color: gold,
    textShadow: "4px 4px 0px rgba(0,0,0,0.15)",
    padding: 0,
    margin: "1rem 0",
    lineHeight: "4rem"
  },
  small: {
    fontFamily: font,
    fontSize: "0.8rem",
    color: "rgb(100,100,100)",
    fontWeight: 400,
    padding: 0,
    margin: 0
  },
  h2: {
    fontSize: "1.6em",
    fontWeight: 700,
    color: gold,
    textAlign: "center",
    margin: 0,
    padding: "3.2rem 1rem 1.4rem"
  },
  p: {
    fontFamily: font,
    fontSize: "1rem",
    textAlign: "justify",
    color: "rgb(100,100,100)",
    lineHeight: "1.4rem",
    margin: 0,
    padding: "1rem 0"
  },
  imageHead: {
    margin: 0,
    padding: "3rem 1rem"
  },
  imageHeadPicture: {
    maxWidth: "100%",
    verticalAlign: "middle"
  },
  box: {
    maxWidth: "100vw",
    padding: 0,
    margin: 0,
    boxSizing: "border-box"
  },
  programma: {
    borderTop: "1px solid rgb(240,240,240)"
  },
  imageDate: {
    margin: "0.25rem",
    maxWidth: 500
  },
  "@media (max-width: 959px)": {
    imageHead: {
      padding: "1rem 1rem"
    },
    imageHeadPicture: {
      width: 280
    },
    box: {
      padding: "0 1rem"
    },
    h2: {
      paddingLeft: "1rem",
      paddingRight: "1rem"
    },
    programma: {
      borderTop: "none"
    },
    imageDate: {
      margin: 0,
      width: 280
    }
  }
};
// const wStyles = theme => styles;

/**
 * HeadBlock (content)
 * TextAndTags (content)
 * DatesBlock (content)
 * ScrollingLink (scrollLink <string>) link ID #link
 * PapersModul (content, head)
 * Information (content)
 * Information1 (content)
 * Information2 (content)
 * Programma (content {propgramma, variants, programmaOutComponent})
 * HowIsTheTrainingGoing (content)
 * AfterEducation (content)
 * Teachers (content {teachers[], teachersHead})
 * GraduatesWork (content[])
 * Formats (content)
 * Rgsu (content)
 * Price (content {pay, countPay, payTitle, payOpen})
 * Text2 (content {title, text})
 * LinkOnFormats ()
 * AboutProfession (content {title, short, detail})
 * Principes (content {text, paperText})
 * SocnetBlock (content {reveiw, examples})
 * BuildLayout (arr)
 * ShessBlock (content {title, right, left})
 * TitleH2 (content)
 * Actions (content, head)
 **/

export function PapersElement(props) {
  return (
    <>
      <Grid container spacing={3} alignItems="stretch" justify="space-between">
        {props.content.map((el, k) => (
          <Grid item xs={12} md={4}>
            <Paper style={{ paddingTop: "1rem", height: "100%" }}>
              <h3
                style={{
                  fontFamily: "Roboto",
                  color: "rgb(100,100,100)",
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  padding: 0,
                  marginBottom: "2rem"
                }}
              >
                {el.title}
              </h3>
              <div
                style={{
                  background: "rgb(240,240,240)",
                  color: "rgb(80,80,80)",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "1rem",
                  padding: "1rem",
                  fontWeight: 400
                }}
              >
                {el.gray}
              </div>
              <div
                style={{
                  color: "rgb(100,100,100)",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "1rem",
                  padding: "1rem",
                  fontWeight: 400
                }}
              >
                {el.text}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export function GrayBlock(props) {
  const { content } = props;
  return <Block title={content.title} text={content.text} />;
}

export function TitleH2(props) {
  const { content } = props;
  return (
    <>
      {props.scroll && <ScrollingLink scrollLink={props.scroll} />}
      <h2
        className="h2-constructor"
        style={{ fontFamily: fontTitle, ...props.style }}
      >
        {content}
      </h2>
    </>
  );
}

export function TitleH1(props) {
  const { content } = props;
  return (
    <>
      <h2
        className="h1-constructor"
        style={{ fontFamily: fontTitle, ...props.style }}
      >
        {content}
      </h2>
    </>
  );
}

export function ScrollingLink(props) {
  return (
    <ScrollableAnchor id={props.scrollLink}>
      <div style={{ height: 1 }} />
    </ScrollableAnchor>
  );
}

export function CardImage({ content }) {
  return (
    <div className=".card-image-container">
      <div className="card-image-title">{content.title}</div>
      <div className="card-image-picture-box">
        <img
          src={content.image}
          alt={content.title}
          className="card-image-img"
        />
      </div>
    </div>
  );
}

export function CardImageText(props) {
  const { content } = props;
  return (
    <div className="card-image-text-box">
      <h3 className="card-image-text-title">{content.title}</h3>
      <p className="card-image-text-diplom">{content.diplom}</p>
      <p className="card-image-text-duration">{content.duration}</p>
      {content.specialisation && (
        <p className="card-image-text-p">
          <strong>Специализация:</strong>
          &ensp;
          {content.specialisation}
        </p>
      )}
      {content.process && (
        <p className="card-image-text-p">
          <strong>В процессе обучения:</strong>
          <br />
          {content.process}
        </p>
      )}
      {content.formats && (
        <p className="card-image-text-p">
          <strong>Форматы:</strong> {content.formats}
        </p>
      )}
      {content.actions && (
        <p className="card-image-text-p">
          <strong>Акции:</strong> {content.actions}
        </p>
      )}
      {content.text && (
        <p className="card-image-text-p" style={{ ...content.textStyle }}>
          {content.text}
        </p>
      )}
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item xs={6} md={6}>
          <Button
            linkType="component"
            componentLink={
              <div
                className="button-white-in-black"
                style={{ margin: "0 auto" }}
              >
                записаться
              </div>
            }
          />
        </Grid>
        {content.modal ? (
          <Grid item xs={6} md={6}>
            <ButtonModal
              buttonText="подробнее"
              modalContent={
                <ModalContent
                  head={content.modal.head}
                  text={content.modal.text}
                />
              }
            />
          </Grid>
        ) : content.link ? (
          <Grid item xs={6} md={6}>
            <ButtonLink
              link={content.link.l}
              linkInside
              text={content.link.text}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export function SpaceElement(props) {
  return <Space inside />;
}

export function DatesBlock(props) {
  /**
   * content {
   *   head <string>
   *   dates <array>
   *   video <string> video link
   *   videoOnly <bool>
   *   videoWidth <digit>
   *   media <Element>
   *   image {source, comment, style, quote {text, author}}
   * }
   */

  const { content } = props;
  return (
    <>
      {props.scrolling && <ScrollingLink scrollLink={props.scrolling} />}
      {content.head && (
        <h2
          style={{
            ...styles.h2,
            // textAlign: "left",
            paddingTop: 0,
            fontFamily: fontTitle
          }}
        >
          {content.head}
        </h2>
      )}
      <GridDates
        date={
          content.dates[0] ? (
            <React.Fragment>
              <DigitText
                digit={content.dates[0].d}
                text={content.dates[0].t}
                comment={content.dates[0].c}
              />
              {content.dates[1] ? (
                <DigitText
                  digit={content.dates[1].d}
                  text={content.dates[1].t}
                  comment={content.dates[1].c}
                />
              ) : null}
            </React.Fragment>
          ) : (
            false
          )
        }
        video={
          content.video ? (
            <div style={{ margin: "2rem 0" }}>
              <Video
                link={content.video}
                h={300}
                onlyVideo={content.videoOnly}
                wVideo={content.videoWidth}
              />
              {content.videoComment && (
                <ButtonVideo
                  linkVideo={content.video}
                  slider={false}
                  text="смотрите видео >>"
                  oneText={true}
                  style={{ cursor: "pointer", textAlign: "right" }}
                />
              )}
            </div>
          ) : content.media ? (
            <>{content.media}</>
          ) : (
            <div
              style={{
                padding: "0.5rem"
              }}
            >
              <img
                src={content.image.source}
                alt={content.image.comment}
                className="image-date-constructor"
                style={content.image.style}
              />
              {content.image.comment && (
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "0.8rem",
                    color: "rgb(80,80,80)",
                    textAlign: "right"
                  }}
                >
                  {content.image.comment}
                </p>
              )}
            </div>
          )
        }
        text={
          <div>
            <p
              className="p-constructor"
              style={{
                ...fontCursive,
                paddingBottom: 0
              }}
            >
              {content.quote.text}
            </p>
            <p
              className="p-constructor"
              style={{
                textAlign: "right",
                fontFamily: font,
                paddingTop: 0
              }}
            >
              {content.quote.author}
            </p>
            <p
              className="p-constructor"
              style={{
                textAlign: "justify",
                paddingTop: "1rem",
                fontFamily: font
              }}
            >
              {content.text}
            </p>
          </div>
        }
        image={content.image}
      />
    </>
  );
}

export function HeadBlock(props) {
  const { content } = props;
  return (
    <>
      <TwoBlocks
        vertical={props.vAlign}
        textOnLeft={!props.mediaLeft}
        left={
          props.mediaLeft ? (
            <MediaBlockForTwoBlock content={content} />
          ) : (
            <TextBlockForTwoBlock
              content={content}
              contact={
                content.contactInside && (
                  <StringContact
                    {...content.contactInside}
                    style={{ padding: 0 }}
                  />
                )
              }
              forma={props.forma}
              headForm={props.headForm}
              noButtons={props.noButtons}
            />
          )
        }
        right={
          props.mediaLeft ? (
            <TextBlockForTwoBlock
              content={content}
              forma={props.forma}
              headForm={props.headForm}
              noButtons={props.noButtons}
            />
          ) : (
            <MediaBlockForTwoBlock content={content} />
          )
        }
        mobileNotLeft={content.notLeft}
        mobileNotRight={content.notRight}
      />
      {content.contact && <StringContact {...content.contact} />}
      {content.contacts && <StringContacts contacts={content.contacts} />}
    </>
  );
}

export function HashTagsInHead(props) {
  const c = props.content;
  let res = "";
  let l = c.length;
  res = c.map((e, k) => (
    <>
      <a href={e.link} className="link-constructor">
        #{e.text}
      </a>
      {k == l - 1 ? null : <>&emsp;</>}
    </>
  ));
  return res;
}

export function TextBlockForTwoBlock(props) {
  const { content } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={props.forma ? 6 : 12}>
        {content.headDescript && (
          <h1 className="small-constructor">{content.headDescript}</h1>
        )}
        <h1
          className={content.head ? "h1-constructor" : "h1-constructor-empty"}
          style={{ fontFamily: fontTitle, ...content.styleHead }}
        >
          {content.head}
        </h1>
        {content.headAfterDescript ? (
          <div className="small-constructor">{content.headAfterDescript}</div>
        ) : null}
        <div
          className="space-head"
          style={{ height: content.spaceHeadText || "inherit" }}
        />
        <Paragraph
          style={{
            ...styles.p,
            padding: "0.2rem 0",
            color: "rgb(100,100,100)",
            ...content.styleDescript
          }}
          classElement={content.classDescript}
          content={content.descript}
        />
        <div style={{ height: "2rem" }} />
        <Grid
          container
          spacing={2}
          justify={props.forma ? "start-flex" : "center"}
        >
          {props.contact && (
            <Grid item xs={12} md={12}>
              {props.contact}
            </Grid>
          )}
          {props.forma || content.hashTags || props.noButtons ? null : (
            <Grid item xs={12} md={4}>
              <Button
                linkType="component"
                componentLink={
                  <div className="button-white-in-black">записаться</div>
                }
              />
            </Grid>
          )}
          {content.register && (
            <Grid item xs={12} md={4}>
              <Button
                linkType="component"
                componentLink={
                  <div className="button-white-in-black">записаться</div>
                }
              />
            </Grid>
          )}
          {content.hashTags ? (
            <Grid item xs={12} md={12} style={{ textAlign: "left" }}>
              <HashTagsInHead content={content.hashTags} />
            </Grid>
          ) : props.noButtons ? null : (
            <>
              <Grid item xs={12} md={props.forma ? 12 : 4}>
                <ButtonLink
                  link="#programma"
                  text={content.textButton || "программа"}
                  style={{ fontFamily: font, ...content.styleButton }}
                />
              </Grid>
              {content.noVideo ? null : (
                <Grid item xs={12} md={4}>
                  <ButtonVideo
                    linkVideo={content.video ? content.video : false}
                    slider={
                      content.video ? (
                        false
                      ) : (
                        <SliderVideo videos={content.videos} />
                      )
                    }
                    text="видео"
                    sizeAdaptive={true}
                  />
                </Grid>
              )}
            </>
          )}
          {content.insideContacts && (
            <Grid item xs={12} md={12}>
              <StringContacts contacts={content.insideContacts} inside={true} />
            </Grid>
          )}
          {content.messagesLinks && (
            <div style={{ margin: "2rem auto 2rem 0" }}>
              <SocNetIcons color="rgb(100,100,100)" size={32} />
            </div>
          )}
        </Grid>
      </Grid>
      {props.forma && (
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: "2rem" }}
          className="forma-on-head"
        >
          {props.headForm && (
            <h2 className="h4-constructor">{props.headForm}</h2>
          )}
          <VerticalForm formName={props.forma} noComment />
        </Grid>
      )}
    </Grid>
  );
}

export function MediaBlockForTwoBlock(props) {
  const { content } = props;
  return content.slider ? (
    <div className="image-head-constructor">
      <SliderInHeader slides={content.slides} />
    </div>
  ) : content.imageComponent ? (
    content.imageComponent
  ) : (
    <figure
      className={
        content.imageBackground
          ? "image-head-constructor image-head-background"
          : "image-head-constructor"
      }
      style={{
        backgroundImage: content.imageBackground
          ? `url('${content.imageSource}')`
          : "none",
        ...content.styleImageBox
      }}
    >
      <picture>
        {content.imageSourceWeb && (
          <source type="image/webp" srcset={content.imageSourceWeb} />
        )}
        <source type="image/jpeg" srcset={content.imageSource} />
        <img
          src={content.imageSource}
          alt={content.imageAlt}
          style={{
            ...content.styleImage
          }}
          className="image-head-picture-constructor"
        />
      </picture>
      <figcaption
        className="small-constructor"
        style={{
          textAlign: "right"
        }}
      >
        {content.imageComment}
      </figcaption>
    </figure>
  );
}

export function TextAndTags(props) {
  const { content } = props;
  return (
    <>
      {content.text && (
        <TextBlock
          content={<p className="p-constructor">{content.text}</p>}
          sizeBlock={props.sizeBlock}
        />
      )}
      {content.tags && (
        <>
          <Space inside />
          <Tags text={content.tags} />
        </>
      )}
    </>
  );
}

export function TextArr(props) {
  const c = props.content;
  return c.map(el => <p style={props.style}>{el}</p>);
}

export function Text3Block(props) {
  const c = props.content;
  let classBlock = "text-3-block";
  if (c.length == 2) classBlock = "text-3-block-2";
  return (
    <div className={classBlock}>
      {c.map((el, key) => (
        <div id={`text-3-block-${key}`}>
          {el.img && <img src={el.img.src} alt={el.img.alt} />}
          <h6 style={props.hStyle}>{el.title}</h6>
          {el.text && <TextArr content={el.text} style={props.pStyle} />}
        </div>
      ))}
    </div>
  );
}

export function ButtonUny(props) {
  return (
    <a href={props.link}>
      <div className="uny-btn">{props.text}</div>
    </a>
  );
}

export function OpenPay(props) {
  const { content } = props;
  return (
    <>
      {props.head && <TitleH2 content={props.head} />}
      {props.tags && <Tags text={props.tags} />}
      {content.map((el, index) => (
        <div className="pay-element-box">
          <ScrollingLink scrollLink={el.ancer} />
          <h3 className="h3-constructor">{el.title}</h3>
          {el.text}
        </div>
      ))}
    </>
  );
}

export function PapersModul(props) {
  const { content } = props;
  return (
    <>
      {props.scrolling && <ScrollingLink scrollLink={props.scrolling} />}
      {props.head && <TitleH2 content={props.head} />}
      <PapersElement content={content} />
    </>
  );
}

export function Information1(props) {
  const { content } = props;
  return (
    <>
      {content.head && <TitleH2 content={content.head} />}
      <ShessBuilderInfographica
        picture={content.pictures}
        datas={content.texts}
        noShess={content.noShess}
      />
    </>
  );
}

export function Information2(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="info">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      <div style={{ marginBottom: "4rem" }}>
        {content.head && <TitleH2 content={content.head} />}
        <TwoBlocks2
          left={
            <ShessBuilderInfographica
              picture={content.picture}
              datas={content.texts}
              noShess={content.noShess}
              noShessLarge={true}
              pictureWord={content.pictureWord}
            />
          }
          right={content.infos3}
        />
      </div>
    </>
  );
}

export function Programma(props) {
  const { content } = props;
  return (
    <>
      {props.noScroll ? null : (
        <ScrollableAnchor id="programma">
          <div style={{ height: 1 }} />
        </ScrollableAnchor>
      )}
      {content.programmaOutComponent || (
        <GridProg
          programma={
            <ProgrammaElement
              fontHead={fontTitle}
              styleHead={{
                paddingTop: "1.5rem",
                ...props.styleHead
              }}
              head={content.programma.head ? content.programma.head : false}
              content={content.programma.content || content.programma}
            />
          }
          variants={
            content.variants ? (
              <Papers
                head={content.variants.head}
                content={content.variants.content}
                size={12}
                styleHead={{
                  textAlign: "right",
                  paddingRight: "1rem",
                  paddingTop: "1.5rem"
                }}
                styleTitle={{
                  textAlign: "right"
                }}
                fontHead={fontTitle}
                styleBox={{
                  position: "sticky",
                  top: 0
                }}
              />
            ) : (
              false
            )
          }
          descript={""}
        />
      )}
    </>
  );
}

export function HowIsTheTrainingGoing(props) {
  const { content } = props;
  return (
    <>
      <TitleH2 content={props.head} />
      <Sequence content={content} />
    </>
  );
}

export function NumBackground(props) {
  // const { content } = props;
  return (
    <>
      <div className="digit-background-container">
        <div
          className="digit-background-digit"
          style={{ fontFamily: "Playfair Display" }}
        >
          {props.digit}
        </div>
        <div className="digit-background-text">
          <p className={props.classNumText}>{props.text}</p>
        </div>
      </div>
    </>
  );
}

export function SocnetBlock(props) {
  return (
    <>
      <TitleH2
        content={
          props.head ||
          "Получите квалифицированную консультацию специалиста учебного отдела"
        }
      />
      <Socnet
        target="fashion_styling_complete"
        noHead
        noMessengers={props.noMessengers}
        noMskPhone={props.noMskPhone}
        noSpace={props.noSpace}
      />
    </>
  );
}

export function ShessArray(props) {
  const { content } = props;
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <Grid container>
          {content.map(
            (el, key) =>
              key % 2 ? (
                <>
                  <Hidden smDown>
                    <Grid item md={4} />
                  </Hidden>
                  <Grid item xs={12} md={8}>
                    <NumBackground
                      digit={`0${key + 1}`}
                      text={el}
                      classNumText={props.classNumText}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={8}>
                    <NumBackground
                      digit={`0${key + 1}`}
                      text={el}
                      classNumText={props.classNumText}
                    />
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={4} />
                  </Hidden>
                </>
              )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function ShessNoBkg(props) {
  const { content } = props;
  return (
    <>
      <Grid container>
        {content.map((el, key) => (
          <>
            <Grid item xs={12} md={6}>
              {key % 2 ? el.text : el.media}
            </Grid>
            <Grid item xs={12} md={6}>
              {key % 2 ? el.media : el.text}
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}

export function ShessBuilder(props) {
  const { content } = props;
  return content.map((el, key) => (
    <div style={{ margin: "4rem 0" }}>
      <ShessBlock
        content={{
          left: el.side ? el.media : key % 2 ? el.text : el.media,
          right: el.side ? el.text : key % 2 ? el.media : el.text
        }}
        mediaLeft={el.side ? el.side : key % 2 ? false : true}
        vAlign={props.vAlign}
        scroll={el.scroll}
      />
    </div>
  ));
}

export function Rgsu(props) {
  const { content } = props;
  return (
    <>
      <ScrollingLink scrollLink="diplom" />
      {props.noHead ? null : <TitleH2 content="Диплом после обучения" />}
      <Grid
        container
        spacing={4}
        alignItems="center"
        className="diplom-container"
      >
        <Grid item xs={12} md={6}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontWidth: 400,
              fontFamily: "Roboto",
              fontSize: "1rem",
              color: "rgb(100,100,100)",
              lineHeight: "1.5em",
              marginBottom: "2rem"
            }}
          >
            {content.text}
          </p>
          {content.paperText && (
            <Paper
              style={{
                background: "rgba(245,223,223,1.0)",
                padding: ".5rem 1rem",
                font: "400 0.9em",
                color: "rgb(100,100,100)"
              }}
            >
              <p style={{ textAlign: "justify" }}>{content.paperText}</p>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {content.slides ? (
            <Slider images={content.slides} imageStyle={{ maxWidth: "100%" }} />
          ) : (
            <img
              src={
                content.image || "/img/style-your-life/diplom-rgutis-500.jpg"
              }
              style={{ ...content.imageStyle }}
              alt="Диплом РГСУ"
              className="diplom"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export function VideoLocalInLine(props) {
  const { content } = props;
  return (
    <>
      {content.head && (
        <TitleH2 content={content.head} style={{ fontSize: "1.4rem" }} />
      )}
      <div className="box-constructor">
        <Grid container alignItems="stretch" justify="center" spacing={2}>
          {content.videos.map((el, key) => (
            <Grid
              item
              xs={12}
              md={4}
              key={`video-block-${key}`}
              style={{ textAlign: "center" }}
            >
              {/* <video src={el.video} controls autoplay className="video-element-vertical" >
                <source src={el.video} type={el.type || "video/mp4; codecs=\"avc1.42E01E, mp4a.40.2\""} />
              </video> */}
              <video
                src={el.video}
                controls
                className={content.className || "video-local-in-line"}
                style={content.style}
              >
                <source
                  src={el.video}
                  type={el.type || 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'}
                />
              </video>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export function AfterEducation(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="career">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      <TitleH2 content={content.head} />
      {content.title && <h3 className="h3-constructor">{content.title}</h3>}
      <div className="box-constructor">
        <Grid container alignItems="stretch" justify="center" spacing={2}>
          {content.videos.map((el, k) => (
            <Grid item xs={12} md={4} key={k}>
              <Video
                link={el}
                w={300}
                h={300}
                style={{
                  margin: "0 auto",
                  width: "max-content"
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={10}>
            <p className="p-constructor" style={{ textAlign: "center" }}>
              Подписывайтесь на Youtube канал ВШСДТ ТВ:
              <br />
              интервью с преподавателями, экспертами, выпускниками, открытые
              лекции, обзоры трендов
            </p>
            <p className="p-constructor" style={{ textAlign: "center" }}>
              <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
                Перейти на Youtube канал &gt;&gt;&gt;
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export function Teachers(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="teachers">
        <div style={{ height: 1 }}> </div>
      </ScrollableAnchor>
      <TitleH2
        content={
          content.teachersHead
            ? content.teachersHead
            : content.teachers.length > 1
              ? "Преподаватели"
              : "Преподаватель"
        }
      />
      {content.teachers.map((el, key) => (
        <div key={key}>
          <TwoBlocks
            left={key % 2 ? Teacher(el).text : Teacher(el).image}
            right={key % 2 ? Teacher(el).image : Teacher(el).text}
            textOnLeft={key % 2 ? true : false}
          />
          <Space inside />
        </div>
      ))}
    </>
  );
}

export function GraduatesWork(props) {
  const { content } = props;
  return (
    <>
      <TitleH2 content="Работы выпускников" />
      <Grid container justify="space-between" alignItems="flex-end">
        {content.map((el, key) => (
          <Grid item xs={12} md={6} key={`grWork_${key}`}>
            <Video
              link={el.link}
              hVideo={el.hVideo}
              onlyVideo={true}
              wVideo={el.wVideo}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export function Formats(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="formats">
        <div style={{ height: 1 }}> </div>
      </ScrollableAnchor>
      <FormatsElement
        head={content.head}
        content={content.content}
        columns={content.columns}
      />
    </>
  );
}

export function Price(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="price">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      <Space after />
      {content.pay && (
        <Pay
          content={content.pay}
          pay={content.countPay}
          title={content.payTitle}
          open={content.payOpen}
          afterPrice={content.afterPrice}
        />
      )}
    </>
  );
}

export function PriceTile(props) {
  const { content } = props;
  return (
    <>
      <Grid container spacing={3} alignContent="stretch">
        <Grid item xs={12} md={6}>
          <Paper
            style={{
              padding: "1.2rem",
              color: "rgb(100,100,100)",
              height: "100%"
            }}
          >
            <h3 style={{ ...styles.h3, textAlign: "center" }}>
              {content.online.textButton}
            </h3>
            {content.online.text}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            style={{
              padding: "1.2rem",
              color: "rgb(100,100,100)",
              height: "100%"
            }}
          >
            <h3 style={{ ...styles.h3, textAlign: "center" }}>
              {content.free.textButton}
            </h3>
            {content.free.text}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export function Message(props) {
  const { content } = props;
  return (
    <div className="message">
      {content.head && <div className="message-head">{content.head}</div>}
      <div className="message-text">{content.text}</div>
    </div>
  );
}

export function Text2(props) {
  const { content } = props;
  return (
    <TextBlock
      content={
        <div>
          <TitleH2 content={content.title} />
          <div className="p-constructor">{content.text}</div>
        </div>
      }
    />
  );
}

export function LinkOnFormats() {
  return (
    <a href="#formats">
      <p
        style={{
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "1rem",
          color: "rgb(110,110,110)",
          paddingTop: "1rem"
        }}
      >
        <sup>*</sup>
        Подробнее о форматах обучения &gt;&gt;&gt;
      </p>
    </a>
  );
}

export function AboutProfession(props) {
  const { content } = props;
  return (
    <>
      <ScrollableAnchor id="profession">
        <div style={{ height: 1 }} />
      </ScrollableAnchor>
      <>
        <TextBlock
          content={
            <div>
              <h2
                style={{
                  ...styles.h2,
                  textAlign: "left",
                  paddingTop: 0,
                  paddingLeft: 0,
                  fontFamily: fontTitle
                }}
              >
                {content.title}
              </h2>
              <SpolerNew
                short={content.short}
                detail={
                  content.detail
                    ? content.detail.map((el, k) => (
                        <div className="p-constructor" key={k}>
                          {el}
                        </div>
                      ))
                    : false
                }
              />
            </div>
          }
        />
      </>
    </>
  );
}

export function Principes(props) {
  const { content } = props;
  return (
    <TextBlock
      content={
        <div>
          <h2
            className="h2-constructor"
            style={{
              fontFamily: fontTitle,
              paddingLeft: 0,
              paddingTop: 0,
              textAlign: "left"
            }}
          >
            Принципы обучения
          </h2>
          <ListInfo arr={content} />
        </div>
      }
    />
  );
}

export function Information(props) {
  const { content } = props;
  return (
    <>
      <h2 className="h2-constructor" style={{ fontFamily: fontTitle }}>
        {content.head}
      </h2>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "1rem" }}>
            <Grid container>
              <Grid item xs={2} md={1}>
                {content.iconDocs}
              </Grid>
              <Grid item xs={10} md={11}>
                <a href="/educationdoc" target="_blank">
                  <span style={{ color: "rgb(100,100,100)" }}>
                    Порядок заключения договора и зачисления на обучение
                  </span>
                </a>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "1rem" }}>
            <Grid container>
              <Grid item xs={2} md={1}>
                {content.iconMonitor}
              </Grid>
              <Grid item xs={10} md={11}>
                <a href="/educationonline" target="_blank">
                  <span style={{ color: "rgb(100,100,100)" }}>
                    Особенности реализации программ дистанционного обучения
                  </span>
                </a>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export function Actions(props) {
  const { content } = props;
  return (
    <>
      {props.head && <TitleH2 content={props.head} />}
      <div
        style={{
          border: "2px solid rgb(240,240,240)",
          borderRadius: 6,
          margin: "0 auto",
          padding: "2rem",
          background: "rgb(200,200,200)",
          textAlign: "center",
          color: "rgb(255,255,255)",
          fontWeight: 700,
          fontFamily: "Roboto",
          fontSize: "1.5rem",
          lineHeight: "3rem"
        }}
      >
        {content}
      </div>
    </>
  );
}

export function MessageOnPaper(props) {
  const { content } = props;
  return (
    <div className="message-on-paper">
      {content.title && <h3 className="h3-constructor">{content.title}</h3>}
      <p className="p-constructor">{content.text}</p>
    </div>
  );
}

export function ButtonsWaAndTg(props) {
  return (
    <div className="whatsapp-telegram">
      <a href={props.wa}>
        <div className="whatsapp">
          <img src="/img/whatsapp.svg" />
          <p>Whats App</p>
        </div>
      </a>
      <a href={props.tg}>
        <div className="telegram">
          <img src="/img/telegram-bw.svg" />
          <p>Telegram</p>
        </div>
      </a>
    </div>
  );
}

export function ShessBlock(props) {
  const { content } = props;
  return (
    <>
      {content.title ? <TitleH2 content={content.title} /> : null}
      {props.scroll ? (
        <ScrollingLink scrollLink={props.scroll} />
      ) : (
        <TwoBlocks
          textOnLeft={!props.mediaLeft}
          left={content.left}
          right={content.right}
          vertical={props.vAlign}
          mobileMediaUp={props.mobileMediaUp}
          white={props.white}
          allWhite={props.allWhite}
        />
      )}
    </>
  );
}

export function BoldText(props) {
  const { content } = props;
  return (
    <div className="text-bold-box" style={{ ...props.styleBox }}>
      {content.boldText && (
        <p className="text-bold" style={{ fontFamily: "PT Sans Narrow" }}>
          {content.boldText}
        </p>
      )}
      {content.text && <p className="text-bold-normal">{content.text}</p>}
    </div>
  );
}

export function P(props) {
  let cls = "p-constructor";

  if (props.center) {
    cls = "p-constructor text-center";
  }
  if (props.big) {
    cls = "text-bold-normal";
  }

  return <p className={cls}>{props.children}</p>;
}

export function Calendar(props) {
  const { content } = props;
  const Block = props => {
    return (
      <div
        style={{
          margin: "0 auto 1rem auto",
          padding: "2rem 0",
          boxShadow: "0 1px 10px 3px rgba(160,160,160,.2)"
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: 400,
            color: "rgb(100,100,100)",
            fontSize: "1.5rem",
            margin: 0,
            padding: 0,
            paddingTop: "2rem",
            borderTop: "3px dotted rgb(230,230,230)"
          }}
        >
          {props.type}
        </p>
        <p
          style={{
            fontFamily: "Playfair Display",
            fontSize: "6rem",
            textAlign: "center",
            color: props.colorDay || "rgb(252,168,48)",
            fontWeight: 700,
            lineHeight: "6rem",
            margin: 0,
            padding: 0
          }}
        >
          {props.day}
        </p>
        <p
          style={{
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: 700,
            color: "rgb(100,100,100)",
            fontSize: "1.5rem",
            margin: 0,
            marginTop: "2rem",
            padding: 0
          }}
        >
          {props.month}
        </p>
      </div>
    );
  };

  const ofline = content.ofline ? (
    <Grid item xs={6} md={3}>
      <Block
        type={content.ofline.type || "очно"}
        day={content.ofline.day}
        month={content.ofline.month}
      />
    </Grid>
  ) : (
    false
  );

  const online = content.online ? (
    <Grid item xs={6} md={3}>
      <Block
        type={content.online.type || "онлайн"}
        day={content.online.day}
        month={content.online.month}
      />
    </Grid>
  ) : (
    false
  );

  return (
    <Grid container justify="center" spacing={3}>
      {props.invert ? content.online && online : content.ofline && ofline}
      {props.invert ? content.ofline && ofline : content.online && online}
    </Grid>
  );
}

export function Card(props) {
  return (
    <>
      <div className="text-center">{props.image}</div>
      {props.title && <div className="title-constructor">{props.title}</div>}
      {props.text && (
        <div className="p-constructor" style={props.textStyle}>
          {props.text}
        </div>
      )}
      {props.price && <div className="title-constructor">{props.price}</div>}
    </>
  );
}

export function Cards3(props) {
  const { content } = props;
  return (
    <Grid container spacing={2}>
      {content.map((el, index) => (
        <Grid item xs={12} md={props.md || 4} key={index}>
          <Card
            image={el.image}
            title={el.title}
            text={el.text}
            price={el.price}
            textStyle={el.textStyle}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export function ButtonModal(props) {
  const [open, setOpen] = useState(false);
  const opener = () => setOpen(true);
  const closer = () => setOpen(false);
  const [hover, setHover] = useState(false);
  const hoverer = () => setHover(true);
  const outer = () => setHover(false);
  return (
    <>
      <div className="button-constructor" onClick={opener}>
        {props.buttonText}
      </div>
      <Modal open={open} onClose={closer}>
        <Paper className="paper-modal-constructor">
          <div
            onClick={closer}
            style={{
              margin: ".5rem",
              position: "relative",
              left: "calc(100% - 44px)",
              cursor: "pointer"
            }}
            onMouseOver={hoverer}
            onMouseOut={outer}
          >
            <svg width={24} height={24} viewBox="0 0 24 24">
              <path
                fill={hover ? "rgb(160,160,160)" : "rgb(200,200,200)"}
                d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
              />
            </svg>
          </div>
          {props.modalContent}
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="block-center" style={{ marginBottom: "2rem" }}>
                <Button
                  linkType="component"
                  componentLink={
                    <div className="button-white-in-black block-center">
                      записаться
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="button-white-in-black block-center margin-bot-mobile"
                onClick={closer}
              >
                закрыть
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export function ModalContent(props) {
  return (
    <>
      <TitleH2 content={props.head} style={{ marginTop: "1rem" }} />
      <List
        arr={props.text}
        num="none"
        olStyle={{ paddingLeft: 0 }}
        style={{ paddingBottom: ".5rem", lineHeight: "1.4rem" }}
      />
    </>
  );
}

export function VideosTile(props) {
  const { content } = props;
  return (
    <div className="box-constructor">
      <Grid container spacing={4}>
        {content.map((el, key) => (
          <>
            <Grid
              xs={12}
              md={6}
              key={`videos-tile-${key}`}
              style={{ paddingTop: "2rem" }}
            >
              <Video link={el.video} />
              <p
                className="p-constructor"
                style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
              >
                {el.text}
              </p>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}

export function BuilderLayout({ contents }) {
  return (
    <div className="constructor-cnt">
      {contents.map((element, index) => (
        <div
          className={index === 0 ? "space-quarter" : "space-constructor"}
          key={`layoutElement${index}`}
        >
          {element}
        </div>
      ))}
    </div>
  );
}
