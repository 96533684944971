import React from "react";
import List from "../../components/list";
import { dates, Month /* MonthToXX */ } from "../shedule/dates";
import { Link } from "react-router-dom";

const p = "p-constructor";
const h = "h3-constructor";

const online = `${dates.chatbot.online.getDate()} ${Month(
  dates.chatbot.online.getMonth()
)}`;

export const content = {
  title:
    "Профессия: Дизайнер чат-ботов с искусственным интеллектом для текстовых каналов коммуникаций на конструкторе Aimylogic",
  keywords: "", // meta
  descript: "", // meta
  lable: "Чат-боты",
  head: {
    headDescript: "Профессиональное образование",
    head: (
      <>
        Профессия: Дизайнер чат-ботов
        <br />с искусственным интеллектом
      </>
    ),
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    headAfterDescript: (
      <div
        style={{
          paddingBottom: "2rem",
          paddingTop: ".5rem"
        }}
      >
        Вы научитесь создавать чат-ботов, реагирующих на естественно-языковые
        обращения, в системе онлайн коммуникаций (мессенджеров). По итогам —
        документ ВУЗа, который котируется во всем мире
      </div>
    ),
    descript: [
      <>Форма обучения: онлайн</>,
      <>Документы: удостоверение о повышении квалификации</>,
      <>Длительность: 2 месяца, 70 академических часов</>,
      <>Стоимость 50 тыс. рублей</>,
      <>Налоговый вычет после обучения</>,
      <>Лучших трудоустроим</>
    ],
    imageComment: false,
    // imageSource: "/img/chatbot/robots.jpg",
    imageSource: "/img/chatbot/il-01.jpg",
    hashTags: [
      {
        link: "#partners",
        text: "партнеры"
      },
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    contacts: [
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      },
      {
        phone: "+7 (985) 745 52 40",
        phoneLink: "tel:+79857455240",
        name: "Сергей",
        tg: false,
        wa: false
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>онлайн с {online}</>, <>содействие занятости — июль</>]
    },
    {
      title: "Формат",
      text: [<>Живые вебинары, видеолекции,</>, <>чат в группе, практика</>]
    },
    {
      title: "База",
      text: [
        <>Обучение реализуется совместно</>,
        <>специалистами ВШСДТ и МФТИ</>
      ]
    }
  ],
  mfti: {
    image: {
      src: "/img/partners-pro/mfti.png", //"/img/chatbot/mfti-logo.webp"
      alt: "МФТИ",
      style: { width: 200, height: "auto" },
      block: {
        backgroundColor: "white" // "rgb(1,114,192)"
      }
    },
    text: (
      <>
        Официальный партнер проекта. Слушатели проходят обучение на базе
        Московского физико-технического института. После успешного изучения
        курса и защиты проектной работы, выпускники получат удостоверение о
        повышении квалификации МФТИ по программе «Основы компьютерной
        лингвистики и машинного обучения для создания диалоговых ассистентов»
        <br />
        <a
          href="https://mipt.ru/cdpo/programs/software/supervised_learning.php?ELEMENT_ID=1969392"
          target="_blank"
        >
          <strong>mfti.ru</strong>
        </a>
      </>
    ),
    link: "https://mipt.ru/cdpo/news/chatbot_course_announcement"
  },
  vshsdt: {
    image: {
      src: "/img/chatbot/vshsdt.png",
      alt: "ВШСДТ",
      style: {
        height: 150
      },
      block: {
        backgroundColor: "white" // "rgb(250,250,250)",
      }
    },
    text: (
      <>
        Инициатор проекта. Цель — внедрение цифровых технологий и коммуникаций
        для малого бизнеса в креативных индустриях. В частности, слушатели курса
        получат возможность изучить дисциплины, связанные со структурой и
        технологиями современной моды, в процессе обучения реализовать проекты в
        данной области.
      </>
    )
  },
  gosgrant: {
    image: (
      <Link to="/chatbot-gospro">
        <img src="/img/chatbot/gosgrant.jpg" />
      </Link>
    ),
    text: (
      <p className="p-constructor">
        Вы можете пройти обучение по направлению проектирования, разработки
        диалоговых систем и вывода на рынок IT продуктов в области разговорного
        искусственного интеллекта в рамках федерального проекта «Содействия
        занятости» национальной программы «Демография», реализуемой с 2021 по
        2024 гг. Программа реализуется при поддержке Министер&shy;ства труда и
        социальной защиты РФ, Мини­стер­ства науки и высшего обра­зова­ния РФ и
        Министер­ства просве­щения РФ. Обучение в рамках проекта «Содействия
        занятости» реали&shy;зуется на базе РАНХиГС, МФТИ
      </p>
    )
  },
  text1: {
    title: <>Профессия Дизайнер чат-ботов с ИИ</>,
    text: (
      <>
        Дизайнер чат-ботов с искусственным интеллектом создаёт умные решения для
        автоматизации рутинных задач. В последние годы устойчиво растёт спрос на
        создание умных диалоговых ассистентов для бизнеса, государства,
        образования, культуры, медицины и прочих сфер жизни общества. Поэтому
        профессионалы, способные создавать и обслуживать умные диалоговые
        системы, имеют высокие перспективы на рынке труда. В частности зарплата
        дизайнера диалоговых систем на основе данных hh.ru составляет: junior от
        50 000 руб., middle от 100 000 руб., senior от 170 000 руб.
      </>
    )
  },
  basis: {
    image: <img src="/img/chatbot/base.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Основа обучения</h3>
        <p className={p}>
          Освоение профессии дизайнера чат-ботов с искусственным интеллектом для
          текстовых каналов коммуникаций базируется на программе курса «Основы
          компьютерной лингвистики и машинного обучения для создания диалоговых
          ассистентов» (длительность 70 академических часов, 2 месяца). Курс
          разработан в рамках соглашения о сотрудничестве МФТИ и ВШСДТ от 02
          апреля 2021 года. Курирует программу технический директор «0110 ЛАБ»,
          разработчик чат-ботов, Сергей Серебров.
        </p>
      </>
    )
  },
  fashionProjects: {
    image: (
      <img src="/img/chatbot/for-fashion.jpg" className="image-for-shess" />
    ),
    text: (
      <>
        <h3 className={h}>
          Выполнение проектных работ для предприятий индустрии моды
        </h3>
        <p className={p}>
          В рамках данного обучения по выбору слушатели получают возможность
          освоить знания по программе «Структура и система современной индустрии
          моды» (длительность 30 акаде­мических часов) и выполнить проектную
          работу на основе стажировки на предприятии индустрии моды. Слушатели,
          успешно сдавшие зачет по данной дисциплине, получат удостоверение о
          повышении квалификации ВШСДТ по программе «Структура и система
          современной индустрии моды»
        </p>
      </>
    )
  },
  career: {
    image: <img src="/img/chatbot/career.jpg" className="image-for-shess" />,
    text: (
      <>
        <h3 className={h}>Профессиональное и карьерное продвижение</h3>
        <p className={p}>
          В данном проекте предусмотрена программа содействия в трудоустройстве
          на основе запросов профильных компаний. В&nbsp;целях повышения
          эффективности процесса трудоустройства и дальнейшего карьерного роста,
          слушатели курса проходят цикл дополнительных онлайн семинаров в
          области фор&shy;ми&shy;ро&shy;вания личного профессионального стиля,
          фор&shy;ми&shy;ро&shy;вания про&shy;фессиональной репутации в сети
          интернет, нюансов со&shy;став&shy;ления резюме и прохождения
          собеседований в IT компаниях
        </p>
      </>
    )
  },
  programma: {
    head: "Программа курса",
    content: [
      <p>
        Программа курса предусматривает освоения слушателями следующих модулей
      </p>,
      <List
        classLi={p}
        arr={[
          <>Модуль 1. Цикл разработки диалоговых систем</>,
          <>Модуль 2. Введение в NLP для разработки разговорных ассистентов</>
        ]}
      />,
      <p className={p}>
        <strong>Обучение направлено на:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          "изучение основных принципов и современных методов NLP, ML/DL;",
          "овладение специальной терминологией, используемой в области NLP, ML/DL;",
          "изучение технических средств, применяемых в разработке решений с элементами NLP, ML/DL",
          "отработки устойчивых навыков решения практических задач с использованием методов NLP, ML/DL;",
          "овладение современными способами анализа, тестирования результатов и исправления ошибок;",
          "отработка навыков оценки экономической эффективности диалоговых ассистентов."
        ]}
      />,
      <p className={p}>
        Все занятия проводятся в интерактивном режиме, с максимальным
        вовлечением слушателей в активный процесс усвоения знаний и отработки
        навыков. Каждое занятие включает в себя теоретическую, семинарскую и
        практическую части с отработкой необходимых навыков.
      </p>,
      <p className={p}>
        <strong>Вы научитесь:</strong>
      </p>,
      <List
        classLi={p}
        arr={[
          <>
            Понимать и анали­зиро­вать наме­ре­ние поль­зова­теля, опре­делять
            леммы, рабо­тать с кор­пуса­ми текстов и линг­вис­ти­чес­кими
            ин­струмен­тами, вла­деть сов­ремен­ными спо­собами раз­метки текста
          </>,
          <>
            Поль­зовать­ся кате­горий­ным ап­пара­том, уметь исполь­зовать
            инстру­мен­тарий для обра­бот­ки ес­тес­твен­ного языка и работы с
            НТТР запро­сами, владеть тех­ничес­кими и про­грам­мными сред­ствами
            разра­ботки систем с эле­мен­тами NLP и ML/DL, опре­де­лять усло­вия
            и функ­ции в диало­говых ас­сис­тен­тах
          </>,
          <>
            Пользоваться програм­мны­ми сред­ства­ми для кон­струи­рова­ния
            диало­говых ассис­тен­тов, мето­дами поиска и ис­прав­ления ошибок,
            уметь работать с дан­ными, владеть со­времен­ными спо­со­бами
            ана­лиза и раз­метки тек­стов
          </>
        ]}
      />,
      <p className={p}>
        <strong>Требования для зачисления:</strong>
      </p>,
      <p className={p}>
        Обучение реализуется совместно ВШСДТ и МФТИ. К зачислению допускаются
        слушатели, обладающие высшим или средне профессиональным образованием
        (предоставляете копию диплома), а так же студенты вузов и колледжей
        (предоставляете справку из образовательной организации).
      </p>,
      <p className={p}>
        Обучение так же доступно в формате вольного слушателя без предоставление
        диплома о высшем образовании или СПО. В таком случае по итогам обучения
        выдается сертификат.
      </p>,
      <p className={p}>
        <strong>Полная программа курса высылается по запросу.</strong>
      </p>
    ]
  },
  bot: {
    image: <img src="/img/other/open_lesson.jpg" alt="Бот Валерия" />,
    text: (
      <>
        <p className="p-constructor">
          <strong>Телеграмм группа открытого образования ВШСДТ</strong>
        </p>
        <p className="p-constructor">
          В группе Открытого образования публикуем информацию об открытых курсах
          и мероприятиях, или социальных проектах
        </p>
        <p className="p-constructor">
          Здесь Вы узнаете, когда и как большую часть материалов можно изучить
          без оплат и условий
        </p>
        <a href="https://t.me/+Sfmarwgq2CtCji81">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  openEdu: {
    text: (
      <p>
        IT профессии. Открытое обучение и трудоустройство по направлению
        Conversation AI — свободный доступ к лекциям, практическим заданиям и
        карьерным консультациям. На портале{" "}
        <a href="https://open-ai.ru/" target="_blank">
          OPEN-AI.RU
        </a>{" "}
        доступны курсы в области проектирования диалоговых систем с ИИ и продаж
        IT проектов в области Conversation AI
      </p>
    ),
    image: (
      <img src="/img/chatbot/open-edu.png" alt="" style={{ maxWidth: 320 }} />
    )
  },
  prices: {
    head: "Стоимость обучения",
    content: [
      <List
        arr={[
          <>Подготовительный курс онлайн — без оплаты</>,
          <>
            Обучение в рамках федерального проекта «Содействие занятости» — без
            оплаты*
          </>,
          <>Стоимость курса (онлайн): 50000 рублей</>,
          <>Стоимость очной практики (1 раз в неделю) — 20 тыс.рублей</>
        ]}
        num="none"
      />,
      <center>
        <strong>Доступны:</strong>
      </center>,
      <List
        num="none"
        arr={[
          <>
            рассрочки от 3 до 12 платежей, кредиты на условиях банков-партнеров
          </>,
          <>скидка при раннем бронировании* — до 5 тыс.рублей</>,
          <>скидки по программе лояльности* — до 20%</>,
          <List
            arr={[
              <>
                Условия предоставления скидок, рассрочек, кредитов уточняйте у
                специалистов учебного отдела
              </>,
              <>
                Кто может учиться в рамках федерального проекта «Содействие
                занятости» национального проекта «Демография», смотрите на
                портале{" "}
                <a
                  href="https://trudvsem.ru/information-pages/support-employment/"
                  target="_blank"
                >
                  trudvsem.ru
                </a>
              </>,
              <>
                Полная стоимость обучения в очном или дистанционном форматах
                рассчитывается в зависимости от выбранной траектории обучения с
                учетом скидок и рассрочек, закрепляется в договоре на обучение
              </>
            ]}
          />
        ]}
      />
    ]
  },
  socnetTitle:
    "Записаться на открытые занятия, получить полную программу курса или квалифицированную консультацию",
  miniFormHead:
    "Остались вопросы, получите консультацию менеджера и персональное предложение со скидкой",
  diplom: {
    title: "Документ об образовании",
    image: (
      <img
        src="/img/chatbot/mfti_example.jpg"
        alt="Удостоверение о повышении квалификации"
        style={{ maxWidth: 320 }}
      />
    ),
    text: (
      <p class="p-constructor">
        Слушатели, успешно прошедшие обучение, выполнившие учебный план в
        соответствии с рабочей программой, сдавшие проектные работы, не имеющие
        академической задол&shy;жен&shy;ности и представившие все необходимые
        документы, получают документы установленного государством образца о
        повышении квалификации МФТИ по программам «Conversation&nbsp;AI: дизайн
        и проектирование диалоговых систем с искусствен&shy;ным интеллектом»
      </p>
    )
  }
};
