import React from "react";
import Grid from "@material-ui/core/Grid";

export default function Columns() {
  var column1 = (
    <React.Fragment>
      <h3
        style={{ fontFamily: "Roboto", fontSize: "1.7em", textAlign: "left" }}
      >
        Цель курса &ndash; ответ на вопросы:
      </h3>
      <ul style={{ fontFamily: "Roboto", fontSize: "1em", textAlign: "left" }}>
        <li>
          что послужило причиной перехода от классического «что-то говорящего»
          искусства к современному искусству «для смотрящих»
        </li>
        <li>
          в чем отличие искусства модернизма от искусства «здесь и сейчас»
        </li>
        <li>
          какие формы принимает искусство 2-й половины XX века и какая роль
          отводится автору и зрителю
        </li>
        <li>
          какова суть концептуального искусства и концептуального подхода к
          проектированию костюма и что требуется, чтобы удачно продавать идеи
        </li>
        <li>
          как происходит процесс творческого влияния искусства на моду и моды на
          искусство и что является общим источником их формирования и развития
        </li>
        <li>
          какие признаки отличают концептуальную моду от конвенциональной и в
          чем сходство и различие известных мировых концептуальных брендов и
          дизайнеров
        </li>
        <li>
          каковы основные инструменты для воплощения концепции костюма: источник
          идей, технологии кроя, специфика материала, способы презентации
          коллекции и визуальной коммуникации со зрителем
        </li>
      </ul>
    </React.Fragment>
  );
  // var column2 = (
  //   <React.Fragment>
  //     <h3
  //       style={{ fontFamily: "Roboto", fontSize: "1.7em", textAlign: "left" }}
  //     >
  //       Результаты курса:
  //     </h3>
  //     <ul style={{ fontFamily: "Roboto", fontSize: "1em", textAlign: "left" }}>
  //       <li>
  //         глубокий уровень освоения истории современного искусства второй
  //         половины XX века, и концептуализма в частности
  //       </li>
  //       <li>
  //         профессиональная ориентация в основных направлениях авангардных
  //         художественных практик во взаимосвязи с современной концептуальной
  //         модой
  //       </li>
  //       <li>
  //         знание концептуальных мировых дизайнеров и брендов с делением на
  //         национальные школы, понимание особенностей презентации их коллекций во
  //         взаимодействии с фотографами и на основе цифровых технологий
  //       </li>
  //       <li>
  //         понимание «языка» концептуального подхода к проектированию костюма
  //         (очень подробно &ndash; на примере Maison Martin Margiela и его
  //         создателя, а также японских дизайнеров)
  //       </li>
  //       <li>
  //         развитие необходимых навыков эффективного построения эстетической базы
  //         восприятия и трактовки концептуального искусства и моды для
  //         дальнейшего профессионального роста и личного творчества
  //       </li>
  //     </ul>
  //   </React.Fragment>
  // );
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {column1}
      </Grid>
      {/* <Grid item xs={12} md={6}>
        {column2}
      </Grid> */}
    </Grid>
  );
}
