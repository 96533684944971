import React from "react";
import Title from "./title-meta";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Header from "./header-links";
import Top from "../../components/topmenu";
import Paragraph from "../../components/p-array";
import Footer from "../../components/footer";

const name = "Ирина Варлакова";

const styles = makeStyles({
  container: {
    maxWidth: 1000,
    margin: "0 auto"
  },
  h2: {
    color: "rgb(123,143,87)",
    fontSize: "1.8em",
    fontWeight: 700,
    textAlign: "center",
    margin: 0,
    padding: "12px 0"
  },
  h3: {
    color: "rgb(100,100,100)",
    fontSize: "1.2em",
    fontWeight: 700,
    textAlign: "left",
    margin: 0,
    padding: "12px 0"
  },
  text: {
    fontSize: "1em",
    color: "rgb(100,100,100)",
    margin: 0,
    padding: "12px 0",
    textAlign: "justify"
  }
});

export default function Varlakova() {
  const classes = styles();

  return (
    <div>
      <Title title={name} public />
      <Top />
      <Grid container justify="center" className={classes.container}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            background:
              "url(/img/graduates/varlakova1.jpg) top center / cover no-repeat",
            padding: 0
          }}
        >
          <div style={{ padding: 0, paddingTop: "calc(100vh - 154px)" }}>
            <Header
              head={name}
              instagram="https://www.instagram.com/stilno_irina/"
              vk="https://vk.com/stilist_irina_varlakova"
              phone="tel:+7-996-561-05-01"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={11}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={12}>
              <h2 className={classes.h2}>Хобби стало профессией</h2>
              <Paragraph
                content={[
                  <React.Fragment>
                    В детстве у меня было хобби – я перешивала мамину одежду и
                    устраивала экстравагантные показы мод. Мне казалось, чем
                    более не понятный и замысловатый наряд, тем моднее.
                    Постепенно хобби перерастало в осознанное обучение и
                    получение практических навыков. Я окончила курсы закройщика,
                    шила для себя и всех знакомых. В мечтах я представляла себя
                    именитым дизайнером.
                  </React.Fragment>
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
              <img
                src="/img/graduates/varlakova2.jpg"
                alt={name}
                style={{
                  maxWidth: "100%"
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Paragraph
                content={[
                  <React.Fragment>
                    Настало время выбирать ВУЗ для получения специальности. В то
                    время в Пермском Политехническом Университете открывали
                    новую специальность &mdash; «художественная обработка
                    материалов» &mdash; и я попала в первую набранную группу.
                    Специальность эта была необычная и предвещала интересную
                    творческую работу, связанную с дизайном, прикладным
                    искусством, ювелирным делом – кому что по душе. Моей
                    дипломной работой была разработка дизайна и изготовление
                    ювелирного гарнитура для невесты. Получились невероятно
                    нежные серьги и кулон.
                  </React.Fragment>,
                  <React.Fragment>
                    После учебы я попробовала поработать на заводе технологом в
                    литейном цехе. Без опыта сложно было куда-либо устроиться, и
                    выбирать особо непришлось. Поработав год, я поняла, что это
                    не моё – душа требовала творчества. Я решила не отступать от
                    своей мечты и получила специальность «конструктор швейных
                    изделий» в Ивановской Текстильной Академии. Вот там-то я и
                    погрузилась в атмосферу творчества и модной индустрии.
                    Всегда ставила перед собой высокие цели. Если преддипломная
                    практика, &mdash; так не на кафедре, а в модном доме Игоря
                    Чапурина (сама позвонила, договорилась и поехала). На
                    дипломную коллекцию представила не 2-3 образа (чего было
                    достаточно), а 6. Участвовала в конкурсах и показах со
                    своими коллекциями, разрабатывала дизайн и лекала, шила. Я
                    понимала, что это моё и мне безумно нравилось!
                  </React.Fragment>,
                  <React.Fragment>
                    Я успела поработать конструктором в модном доме, где
                    создавалась эксклюзивная дизайнерская одежда, закройщиком на
                    производстве нижнего белья, постоянно работала с
                    индивидуальными клиентами.
                  </React.Fragment>
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 className={classes.h2}>Всё в жизни не случайно</h2>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <img
                src="/img/graduates/varlakova3.jpg"
                alt={name}
                style={{
                  maxWidth: "50%"
                }}
              />
              <Paragraph
                content={[
                  "И вот пришло время развиваться дальше. В связи с профессией мужа мы часто переезжаем из одного города в другой. Мне стало сложно постоянно искать новое место работы в новых городах.",
                  "Я верю, что всё в жизни неслучайно. Однажды я познакомилась с интересной женщиной, которая сказала, что у меня врожденное чувство стиля и мне стоит попробовать себя в профессии стилиста. Для меня это было открытием! Да, я неплохо одевалась, помогала подругам и родным с выбором одежды. Но мне казалось, в этом нет ничего особенного. Ведь я не слежу за модой, не в курсе модных трендов, одеваюсь, как все."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paragraph
                content={[
                  "Поразмыслив, я поняла, что для меня это прекрасный вариант в плане дальнейшего развития. Ирина Варлакова Стилист может работать сам на себя, к тому же свободный график – это то, что мне нужно. И творчество опять же. Я приняла решение пройти обучение. Конечно, у меня уже были определенные знания по колористике, эстетике образа, дизайну, истории костюма – всё жея получила хорошие базовые знания. Но вот применить это к гардеробу, к внешнему виду человека мне было затруднительно.",
                  <img
                    src="/img/graduates/varlakova4.jpg"
                    alt={name}
                    style={{
                      maxWidth: "100%"
                    }}
                  />
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 className={classes.h2}>Основательный подход</h2>
              <Paragraph
                content={[
                  "Изучив большой объем информации в интернете по многочисленным школам, предлагающим обучение, я остановилась на Высшей Школе Стилистики. Она привлекла меня своим серьезным и основательным подходом, разнообразием программ и длительностью обучения. Я понимала, что за месяц, окончив курсы, нельзя стать хорошим специалистом.В ВШС курс длился 10 месяцев плюс практика. По окончанию выдается диплом государственного образца – это также сыграло решающую роль. И, безусловно, для меня важна была возможность дистанционного обучения.",
                  "Обучение оказалось очень продуктивным. Пройдя, второй семестр, я уже начала практиковать и применять новые знания. Мне нравилось, что все вопросы можно тут же обсудить с преподавателями, которые всегда готовы помочь. С некоторыми мы продолжаем общение до сих пор, они дают советы по работе и делятся опытом.",
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="/img/graduates/varlakova5.jpg"
                      alt={name}
                      style={{
                        maxWidth: "100%"
                      }}
                    />
                  </div>,
                  "Все мои знания, полученные ранее, дополнились и сформировались в единую систему благодаря курсу по стилю в ВШС. Теперь я не просто знаю, как скроить и сшить костюм, а еще и понимаю, какой он должен быть именно для данного клиента. Помогаю выстроить гармоничный и рациональный гардероб, отражающий индивидуальность.",
                  "Сейчас я занимаюсь персональным консультированием и развиваюсь в этой области. Моя специализация – рациональный гардероб. Также я занимаюсь разработкой авторского обучающего курса, который поможет женщинам научиться самостоятельно разбираться в особенностях своего гардероба и его формировании. Веду активную деятельность в соц.сетях, пишу статьи, делаю интересные публикации."
                ]}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <div style={{ textAlign: "center" }}>
                Мой аккаунт в Instagram{" "}
                <a href="https://www.instagram.com/stilno_irina/">
                  stilno_irina
                </a>
                , группа в{" "}
                <a href="https://vk.com/stilist_irina_varlakova">VKontakte</a>,
                <br />
                <a href="tel:+7-996-561-05-01">+7 (996) 561-05-01</a>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                borderTop: "1px solid rgb(230,230,230)",
                borderBottom: "1px solid rgb(230,230,230)"
              }}
            >
              <h3 className={classes.h3}>
                Рекомендации будущим стилистам-имиджмейкерам:
              </h3>
              <p className={classes.text}>
                Основываясь на своем опыте, могу порекомендовать подходить к
                обучению ответственно, выполнять все практические задания,
                получая обратную связь от преподавателей, и сразу же применять
                на практике полученные знания, не ожидая окончания обучения.
                Теория без практики не даст хорошего результата.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: 32 }} />
      <Footer />
    </div>
  );
}
