import React from "react";
import { Link } from "react-router-dom";

export const content = {
  head: "Вакансии партнеров и работодателей",
  jobs: (
    <p>
      Нашим выпускникам мы даем рекомендации при трудоустройстве. Пишите в чат
      ассоциации с запросом на рекомендацию.
    </p>
  ),
  anonses: (
    <p className="p-constructor center" style={{ lineHeight: "2rem" }}>
      Анонсы встреч и мероприятий Ассоциации выпускников ВШСДТ — в телеграм
      канале:{" "}
      <a href="https://t.me/styleschoolmsk">
        <img
          src="/img/telegram_color.svg"
          style={{
            height: 24,
            width: "auto",
            verticalAlign: "middle"
          }}
          alt=""
        />
        &nbsp;@styleschoolmsk
      </a>
    </p>
  )
};
