import React from "react";

export const facebook = {
  title: "Отзывы со страницы ВШСДТ в facebook",
  images: [
    { image: "/img/reviews/review-facebook/review-from-facebook-01.jpg" },
    { image: "/img/reviews/review-facebook/review-from-facebook-02.jpg" },
    { image: "/img/reviews/review-facebook/review-from-facebook-03.jpg" },
    { image: "/img/reviews/review-facebook/review-from-facebook-04.jpg" },
    { image: "/img/reviews/review-facebook/review-from-facebook-05.jpg" },
    { image: "/img/reviews/review-facebook/review-from-facebook-06.jpg" }
  ]
};

export const other = {
  title: "Отзывы из других социальных сетей",
  images: [{ image: "/img/reviews/review-001.jpg" }]
};
