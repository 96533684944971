import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/styles";
import { style } from "./styles";

const wStyles = theme => style;

const Price = function(props) {
  const { classes } = props;
  return (
    <div style={{ paddingTop: "1.4em" }}>
      <Paper
        style={{
          padding: "1.4em",
          paddingTop: 0,
          background:
            "linear-gradient(rgb(250,250,250), rgb(255,255,255), rgb(250,250,250))"
        }}
      >
        <h3 className={classes.h3}>Стоимость fashion-тура в Тбилиси</h3>
        <p className={classes.p}>
          65 <sup>000</sup> &#8381;
        </p>
      </Paper>
    </div>
  );
};

export default withStyles(wStyles)(Price);
