import {
  imagemakerSecondDegree,
  // imageStyle,
  fashionStyling,
  polittechnology,
  designAndDecoration,
  visualMerchendesign,
  aristotwist,
  styleYourLife,
  interiorYourLife,
  businessAnalitic,
  chatBot,
  // idfw,
  bFashionSprint,
  aibotProductowner
} from "./elements";

export const content = {
  head: "Профессиональное образование",
  tiles: [
    imagemakerSecondDegree,
    styleYourLife,
    interiorYourLife,
    fashionStyling,
    designAndDecoration,
    visualMerchendesign,
    chatBot,
    // bFashionSprint,
    // aibotProductowner,
    // idfw,
    polittechnology,
    aristotwist,
    // imageStyle,
    businessAnalitic
  ],
  hash: [
    {
      text: "#модаистиль",
      link: 0,
      list: [
        imagemakerSecondDegree,
        styleYourLife,
        fashionStyling,
        visualMerchendesign
      ]
    },
    {
      text: "#дизайнинтерьера",
      link: 1,
      list: [designAndDecoration, interiorYourLife]
    },
    {
      text: "#дизайнодежды",
      link: 2,
      list: [bFashionSprint]
    },
    {
      text: "#имиджрепутация",
      link: 3,
      list: [polittechnology, aristotwist]
    },
    {
      text: "#ITпрофессия",
      link: 4,
      list: [chatBot, businessAnalitic, aibotProductowner]
    }
  ],
  subMenu: false
};
