import React from "react";

import Paragraph from "../../components/p-array";
import List from "../../components/list";

export default function Publication() {
  var styles = {
    text: {
      fontSize: "1em",
      color: "rgb(100,100,100)",
      padding: 5,
      margin: 0
    },
    h2: {
      fontFamily: "Lora",
      fontSize: "1.8em",
      color: "rgb(80,80,80)",
      textAlign: "center",
      fontWeight: 500,
      margin: 8,
      padding: "24px 0",
      paddingTop: 32
    },
    h3: {
      fontFamily: "Lora",
      fontSize: "1.1em",
      color: "rgb(100,100,100)",
      textAlign: "left",
      fontWeight: 700,
      margin: 0,
      padding: 4,
      paddingBottom: 0
    }
  };
  return (
    <div style={{ paddingTop: 40 }}>
      <h2 style={styles.h2}>Что Вы получите в результате прохождения курса?</h2>
      <Paragraph
        content={[
          "У Вас сформируется полная система навыков и знаний, позволяющих создать индивидуальный стиль человека."
        ]}
      />
      <h3 style={styles.h3}>Для этого Вы:</h3>
      <List
        arr={[
          "Освоите правила сочетания цветов в одежде, начнете разбираться во всем многообразии оттенков",
          "Изучите несколько систем цветового анализа внешности (сезонный, дирекционный, тоновый)",
          "Узнаете, какие бывают типы фигур, и как с помощью одежды можно корректировать или подчеркивать особенности фигуры",
          "Изучите потребительские свойства вещей, узнаете свойства материалов, из которых они сделаны, и научитесь применять эти знания для создания стильного образа",
          "Запомните названия всех элементов одежды и аксессуаров, изучите профессиональную терминологию fashion-индустрии",
          "Узнаете детально о современных стилях в одежде, выявите их отличительные черты и особенности",
          "Изучите уникальный метод создания индивидуального стиля: теорию стилевых направлений. Он поможет вам «читать внешность» клиента, определяя стилевые предрасположенности по чертам лица",
          "Разберетесь в особенностях мужской стилистики. Научитесь работать как с клиентами -женщинами, так и с мужчинами",
          "Научитесь подбирать вещи в соответствии с ситуацией и дресс-кодом: офис, официальное мероприятие, коктейль, личная встреча",
          "Сможете расставлять в образе правильные акценты при помощи подходящего макияжа, прически и других элементов",
          "Сформируете рациональный гардероб, выбрав один из возможных типов: капсульный, комплектный или базовый",
          "Сможете легко создавать комплекты и формировать индивидуальный стиль с учетом модных тенденций",
          "Будете продумывать логичные шопинг-маршруты, в том числе узнаете множество онлайн магазинов и особенности шопинга за границей",
          "Пройдете практику шопинга, которая поможет вам выяснить, на что следует обращать внимание в тех или иных магазинах при выборе вещей",
          "Изучите крайне важный момент – психологию работы с клиентами, что позволит вам найти подход к каждому человеку, выяснить, чего он хочет от своего нового образа и помочь ему, используя свои знания",
          "Научитесь актуальным способам продвижения себя в Инстаграм"
        ]}
      />
      <Paragraph
        content={[
          "Вы станете настоящим профессионалом в имидж-консультировании, реальным дипломированным специалистом, что выгодно выделит Вас среди окружающих и добавит доверия у клиентов."
        ]}
      />
    </div>
  );
}
