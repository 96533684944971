import React from "react";
import { stylesDark, styles } from "./styles";
import { withStyles } from "@material-ui/styles";
import { Tesises } from "./tesises";
import { contents } from "./contents-lending";
import {
  BuilderLayout,
  TitleH2,
  CardImage,
  CardImageText,
  ShessBlock,
  Actions,
  ShessArray,
  MessageOnPaper,
  Cards3,
  VideosTile,
  ScrollingLink,
  Message,
  AboutProfession,
  Principes
} from "../../patterns/pattern-fs/components";
import { MiniForm } from "../../components/forms";
import Banners from "./banners";

const wStyles = theme => ({
  ...stylesDark,
  ...styles
});

function Top(props) {
  return (
    <div>
      <>
        <BuilderLayout
          contents={[
            <ShessBlock
              content={{
                left: contents.head.left,
                right: contents.head.right
              }}
            />,
            <>
              <TitleH2
                content="Профессиональное образование"
                scroll="programma"
              />
              <ShessBlock
                content={{
                  left: (
                    <CardImage content={contents.cardConsultingStyle.image} />
                  ),
                  right: (
                    <CardImageText
                      content={contents.cardConsultingStyle.text}
                    />
                  )
                }}
                mediaLeft={true}
                mobileMediaUp={true}
              />
            </>,
            <ShessBlock
              content={{
                right: <CardImage content={contents.cardImagemaker.image} />,
                left: <CardImageText content={contents.cardImagemaker.text} />
              }}
              mediaLeft={false}
              mobileMediaUp={true}
            />,
            <ShessBlock
              content={{
                left: <CardImage content={contents.cardPhotoStylist.image} />,
                right: (
                  <CardImageText content={contents.cardPhotoStylist.text} />
                )
              }}
              mediaLeft={true}
              mobileMediaUp={true}
            />,
            <>
              <ScrollingLink scrollLink="free" />
              <ShessBlock
                content={{
                  right: (
                    <img src={contents.bot.image} style={{ maxWidth: 320 }} />
                  ),
                  left: contents.bot.text
                }}
                mediaLeft={false}
              />
            </>,
            <ShessBlock
              content={{
                left: contents.bot.banner,
                right: contents.bot.textBanner
              }}
              mediaLeft={true}
              vAlign="center"
            />,
            <>
              <TitleH2 content="Личное развитие" scroll="forself" />
              <ShessBlock
                content={{
                  left: <CardImage content={contents.cardSelfStyle.image} />,
                  right: <CardImageText content={contents.cardSelfStyle.text} />
                }}
                mediaLeft={true}
                mobileMediaUp={true}
              />
            </>,
            <>
              <TitleH2
                content="Записаться на пробные занятия, получить программы курсов или квалифицированную консультацию"
                scroll="register"
              />
              <MiniForm formName="miniforma" />
            </>,
            <>
              <TitleH2 content="Что дает обучение? Изменения в жизни, реализацию планов или творческое удовлетворение?" />
              {/*<ShessBuilder content={contents.textAndMedia} vAlign="center" />*/}
              <ShessBlock
                content={{
                  left: contents.textAndMedia[0].media,
                  right: contents.textAndMedia[0].text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <ShessBlock
              content={{
                left: contents.textAndMedia[1].text,
                right: contents.textAndMedia[1].media
              }}
              vAlign="center"
            />,
            <>
              <ScrollingLink scrollLink={contents.textAndMedia[2].scroll} />
              <ShessBlock
                content={{
                  left: contents.textAndMedia[3].media,
                  right: contents.textAndMedia[3].text
                }}
                mediaLeft={true}
                vAlign="center"
              />
            </>,
            <ShessBlock
              content={{
                left: contents.textAndMedia[4].text,
                right: contents.textAndMedia[4].media
              }}
              vAlign="center"
            />,
            <>
              <TitleH2 content={contents.tesises.head} />
              <Tesises content={contents.tesises.content} />
            </>,
            <MessageOnPaper content={contents.garanty} />,
            // <SocnetBlock head={contents.headMiddleForm} />,

            // <MiniForm formName="have-questions" />,
            <>
              <ScrollingLink scrollLink="about" />
              <ShessBlock
                content={{
                  title: contents.about.head,
                  left: contents.about.text,
                  right: contents.about.image
                }}
              />
            </>,
            <Actions content={contents.teachersAndGraduatesInProjects} />,
            contents.education,
            <>
              <TitleH2 content="Вам будет интересно и полезно учиться в ВШСДТ, если Вы:" />
              <ShessArray content={contents.digitPuntcs} />
            </>,
            <AboutProfession content={contents.aboutProfeshion} />,
            <Principes content={contents.principes} />,
            <>
              <TitleH2 content="Форматы обучения. Стоимость" scroll="price" />
              <Cards3 content={contents.formats} md={3} />
            </>,
            <>
              <TitleH2
                content="Смотрите больше историй успеха"
                scroll="graduates"
              />
              <VideosTile content={contents.videos} />
            </>,
            // <AfterEducation />,
            <Message content={contents.youtube} />,
            <ShessBlock
              content={{
                left: (
                  <img src={contents.bot.image} style={{ maxWidth: 320 }} />
                ),
                right: contents.bot.text
              }}
              mediaLeft={true}
            />,
            <ShessBlock
              content={{
                right: contents.bot.banner,
                left: contents.bot.textBanner
              }}
              mediaLeft={false}
              vAlign="center"
            />,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
            </>
          ]}
        />
      </>
    </div>
  );
}

export default withStyles(wStyles)(Top);
