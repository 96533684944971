import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const wStyles = theme => ({
  button: {
    border: "1px solid rgb(100,100,100)",
    padding: "0.5rem 1rem",
    background: "rgb(255,255,255)",
    color: "rgb(100,100,100)",
    transition: "0.5s color, 0.5s background",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      color: "rgb(255,255,255)",
      background: "rgb(0,0,0)",
      transition: "0.5s color, 0.5s background"
    }
  },
  buttonSelect: {
    border: "1px solid rgb(100,100,100)",
    padding: "0.5rem 1rem",
    background: "rgb(0,0,0)",
    color: "rgb(255,255,255)",
    transition: "0.5s color, 0.5s background",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      color: "rgb(100,100,100)",
      background: "rgb(255,255,255)",
      transition: "0.5s color, 0.5s background"
    }
  },
  "@media (max-width: 959px)": {
    button: {
      border: "1px solid rgb(100,100,100)",
      padding: "0.5rem 1rem",
      background: "rgb(255,255,255)",
      color: "rgb(100,100,100)",
      textAlign: "center",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(100,100,100)",
        background: "rgb(255,255,255)"
      }
    },
    buttonSelect: {
      border: "1px solid rgb(100,100,100)",
      padding: "0.5rem 1rem",
      background: "rgb(0,0,0)",
      color: "rgb(255,255,255)",
      textAlign: "center",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(255,255,255)",
        background: "rgb(0,0,0)"
      }
    }
  }
});

function ButtonsWithSpoler(props) {
  const { classes } = props;
  let openState = props.open ? props.open : false;
  const [open, setOpen] = React.useState(openState);
  let size;
  let count = 0;
  for (let i = 0; i < 4; i++) {
    if (props[`button${i + 1}`]) count++;
  }
  switch (count) {
    case 1:
      size = 10;
      break;
    case 2:
      size = 5;
      break;
    case 3:
      size = 4;
      break;
    default:
      size = 3;
  }

  return (
    <div>
      <Grid container justify="center" alignItems="stretch" spacing={2}>
        {props.button1 && (
          <Grid item xs={12} md={size}>
            {props.short1 && (
              <div style={{ marginBottom: "2rem" }}>{props.short1}</div>
            )}
            <div
              className={open === 1 ? classes.buttonSelect : classes.button}
              onClick={() => setOpen(open !== 1 ? 1 : false)}
            >
              {props.short1 ? "подробнее" : props.button1}
            </div>
          </Grid>
        )}
        {props.button2 && (
          <Grid item xs={12} md={size}>
            {props.short2 && (
              <div style={{ marginBottom: "2rem" }}>{props.short2}</div>
            )}
            <div
              className={open === 2 ? classes.buttonSelect : classes.button}
              onClick={() => setOpen(open !== 2 ? 2 : false)}
            >
              {props.short2 ? "подробнее" : props.button2}
            </div>
          </Grid>
        )}
        {props.button3 && (
          <Grid item xs={12} md={size}>
            {props.short3 && (
              <div style={{ marginBottom: "2rem" }}>{props.short3}</div>
            )}
            <div
              className={open === 3 ? classes.buttonSelect : classes.button}
              onClick={() => setOpen(open !== 3 ? 3 : false)}
            >
              {props.short3 ? "подробнее" : props.button3}
            </div>
          </Grid>
        )}
        {props.button4 && (
          <Grid item xs={12} md={size}>
            {props.short4 && (
              <div style={{ marginBottom: "2rem" }}>{props.short4}</div>
            )}
            <div
              className={open === 4 ? classes.buttonSelect : classes.button}
              onClick={() => setOpen(open !== 4 ? 4 : false)}
            >
              {props.short4 ? "подробнее" : props.button4}
            </div>
          </Grid>
        )}
      </Grid>
      {props.spoler1 && (
        <div
          style={{
            maxHeight: open === 1 ? 1600 : 0,
            transition: "0.5s max-height",
            overflow: "hidden",
            maxWidth: 600,
            margin: "0 auto"
          }}
        >
          {props.spoler1}
        </div>
      )}
      {props.spoler2 && (
        <div
          style={{
            maxHeight: open === 2 ? 1600 : 0,
            transition: "0.5s max-height",
            overflow: "hidden",
            maxWidth: 600,
            margin: "0 auto"
          }}
        >
          {props.spoler2}
        </div>
      )}
      {props.spoler3 && (
        <div
          style={{
            maxHeight: open === 3 ? 1600 : 0,
            transition: "0.5s max-height",
            overflow: "hidden",
            maxWidth: 600,
            margin: "0 auto"
          }}
        >
          {props.spoler3}
        </div>
      )}
      {props.spoler4 && (
        <div
          style={{
            maxHeight: open === 4 ? 800 : 0,
            transition: "0.5s max-height",
            overflow: "hidden",
            maxWidth: 600,
            margin: "0 auto"
          }}
        >
          {props.spoler4}
        </div>
      )}
    </div>
  );
}

export default withStyles(wStyles)(ButtonsWithSpoler);
