import React from "react";
import { withStyles } from "@material-ui/styles";
import { styles } from "./styles";
import ButtonsSpoler from "./buttons-with-spoler";

const wStyles = theme => styles;

const Price = props => {
  const { classes } = props;
  const content = props.content;

  return (
    <div className={classes.box}>
      <h2 className={classes.h2} style={{ fontFamily: "PT Sans Narrow" }}>
        {props.title || "Стоимость обучения"}
      </h2>
      {props.pay === 3 ? (
        <ButtonsSpoler
          button1={content.ofline.textButton}
          spoler1={content.ofline.text}
          button2={content.online.textButton}
          spoler2={content.online.text}
          button3={content.distant.textButton}
          spoler3={content.distant.text}
          count={3}
          open={props.open}
        />
      ) : (
        <ButtonsSpoler
          button1={content.ofline ? content.ofline.textButton : false}
          spoler1={content.ofline ? content.ofline.text : false}
          short1={content.ofline ? content.ofline.short : false}
          button2={content.online ? content.online.textButton : false}
          spoler2={content.online ? content.online.text : false}
          short2={content.online ? content.online.short : false}
          button3={content.distant ? content.distant.textButton : false}
          spoler3={content.distant ? content.distant.text : false}
          short3={content.distant ? content.distant.short : false}
          button4={content.free ? content.free.textButton : false}
          spoler4={content.free ? content.free.text : false}
          short4={content.free ? content.free.short : false}
          open={props.open}
        />
      )}
      {props.afterPrice && (
        <div
          style={{
            margin: "2rem auto",
            textAlign: "justify",
            boxSizing: "border-box",
            maxWidth: 600,
            width: "max-content",
            fontFamily: "Roboto",
            color: "rgb(100,100,100)",
            fontSize: "1rem"
          }}
        >
          {props.afterPrice}
        </div>
      )}
    </div>
  );
};

export default withStyles(wStyles)(Price);
