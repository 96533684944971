import React from "react";
import Page from "../../components/page";
import Helmet from "react-helmet";
import {
  migranov,
  zhilova,
  shabalina,
  bonetskaya,
  oMaltseva
} from "./content/short";
import { Short } from "./teacher-pattern";
import { BuilderLayout, TitleH2 } from "../../patterns/pattern-fs/components";
import { otherTeachers } from "./photo-items";
import Person from "../../components/persones";

export default function Teachers(props) {
  const content = [migranov, zhilova, shabalina, bonetskaya, oMaltseva];

  return (
    <>
      <Helmet>
        <title>Наши преподаватели</title>
        <meta name="keywords" content={"ВШСДТ: Наши преподаватели"} />
      </Helmet>
      <Page lable="Преподаватели">
        <BuilderLayout
          contents={[
            <TitleH2 content="Наши преподаватели" />,
            content.map(el => (
              <div style={{ marginBottom: "2rem" }}>
                <Short content={el} />
              </div>
            )),
            <Person teachers={otherTeachers} />
          ]}
        />
      </Page>
    </>
  );
}
