import React from "react";
import { content as c } from "./content";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { BuilderLayout, TitleH1 } from "../../patterns/pattern-fs/components";
import { JobList } from "./jobs-list";

export default function JobOpportunity(props) {
  return (
    <div>
      <Helmet>
        <title>Вакансии в индустрии моды и дизайна</title>
        <meta
          name="keywords"
          content="вакансия, работа, имидж, стиль, дизайн, имиджмейкер, стилист, дизайнер, журналист, редактор, копирайтер, консультант"
        />
        <meta
          name="descript"
          content="Предложения о постоянной или проектной работе в индустрии моды и дизайна. Вакансии стилистов, дизайнеров, имиджмейкеров, редакторов, копирайтеров, журналистов, консультантов и других специалистов, востребованных на российском рынке"
        />
      </Helmet>
      <Page lable="Вакансии">
        <BuilderLayout
          contents={[
            <TitleH1 content="Предложения о работе" />,
            <JobList content={c} />
          ]}
        />
      </Page>
    </div>
  );
}
