import React from "react";
import Helmet from "react-helmet";
import { contents } from "./contents";
import Page from "../../components/page";
import Video from "../../components/video";
import {
  BuilderLayout,
  HeadBlock,
  ShessBlock,
  SocnetBlock,
  Teachers,
  Programma
} from "../../patterns/pattern-fs/components";

const Fashion = props => {
  return (
    <>
      <Helmet>
        <title>Прерафаэлиты. Быть самим собой</title>
        <meta
          name="keywords"
          content="прерафаэлиты, искусство, стиль, курс, обучение, учиться, лекции"
        />
        <meta
          name="descript"
          content="В цикле лекций об искусстве прерафаэлитов будет рассмотрена знаково-символическая система художественного образа «Братства»: новое понимание цвета и композиции, исповедальность и театрализация, правдоподобие и символизм, натурализм и мистичность, авангардизм и архаизм, синтез литературы, жизни и творчества, телесный психологизм, двойственность и вариативность категории красоты и образа женщины."
        />
      </Helmet>
      <Page lable="Прерафаэлиты" lableMobile="Прерафаэлиты">
        <BuilderLayout
          contents={[
            <HeadBlock content={contents.head} />,
            <ShessBlock
              content={{
                left: <Video link={contents.screen2.video} video />,
                right: contents.text1
              }}
              mediaLeft={true}
              vAlign="center"
            />,
            <Programma content={{ programma: contents.programma }} />,
            <Teachers
              content={{
                teachers: contents.teachers
              }}
            />,
            <SocnetBlock />
          ]}
        />
      </Page>
    </>
  );
};

export default Fashion;
