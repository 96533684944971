import React from "react";
import Datas from "./datas";

export default function Catalog() {
  return (
    <div>
      <Datas />
    </div>
  );
}
