import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { contents as c } from "./contents";
import Page from "../../components/page";
import Banners from "./banners";
import {
  HeadBlock,
  Teachers,
  Programma,
  Text3Block,
  HowIsTheTrainingGoing,
  AfterEducation,
  AboutProfession,
  Principes,
  Text2,
  DatesBlock, //screen2
  BuilderLayout,
  TitleH2,
  TextAndTags,
  ShessBlock,
  ScrollingLink,
  MessageOnPaper,
  SpaceElement
} from "../../patterns/pattern-fs/components";
import { MiniForm } from "../../components/forms";

const Fashion = props => {
  return (
    <div>
      <Helmet>
        <title>Стилист фотосессий — обучение очно и онлайн</title>
        <meta
          name="keywords"
          content="стилист, фото, сессия, курсы, обучение, школа, учиться, где, профессия"
        />
        <meta
          name="descript"
          content="Изучите профессию стилиста: мы научим создавать образы и стилизовать фотосессии, работать в команде и с командой, организовывать, бюджетировать.Для офлайн и онлайн журналов, fashion-брендов, рекламы, портфолио."
        />
      </Helmet>
      <Page lable="Fashion Styling" lableMobile="#курсыстилистов">
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content="Креативные профессии" />
              <DatesBlock content={c.screen2} />
            </>,
            <>
              <TitleH2 content="Как проходит обучение" />
              <HowIsTheTrainingGoing content={c.howIsTheTrainingGoing} />
            </>,
            <>
              <TitleH2 content="Расписание наборов 2022" />
              <TextAndTags content={{ text: c.schedule, tags: false }} />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} style={{ paddingTop: 0 }} />
              <MiniForm formName="miniform-fashion-styling" />
            </>,
            <Programma
              content={{
                programma: c.programma,
                variants: c.variants
              }}
            />,
            <ShessBlock
              content={{
                right: c.shess.media,
                left: c.shess.text
              }}
              mediaLeft={false}
              vAlign="center"
              allWhite={true}
            />,
            <AfterEducation content={c.afterEducation} />,
            <>
              <Teachers
                content={{ teachers: c.teachers, teacherHead: c.teacherHead }}
              />
            </>,

            <Programma
              content={{ programma: c.additional, variants: false }}
              noScroll
            />,
            <>
              <ScrollingLink scrollLink="price" />
              <Programma
                content={{ programma: c.price, variant: false }}
                noScroll
              />
              <div style={{ height: "4rem" }} />
              <MessageOnPaper content={c.garanty} />
              <div style={{ height: "4rem" }} />
              <p className="p-constructor center">
                <strong>
                  Раннее бронирование: в январе на обучение с февраля 2022 года
                  договора заключаются со скидкой
                </strong>
              </p>
            </>,
            <AboutProfession content={c.aboutProfeshion} />,
            <Principes content={c.principes} />,

            <>
              <TitleH2 content="Открытое образование" scroll="freeeducation" />
              <ShessBlock
                content={{
                  left: c.bot.image,
                  right: c.bot.text
                }}
                mediaLeft={true}
              />
              <div style={{ height: "4rem" }} />
              <ShessBlock
                content={{
                  right: c.freeEdu.image,
                  left: c.freeEdu.text
                }}
                mediaLeft={false}
              />
              <Link to="/openedu">
                <div className="button">
                  Смотреть все курсы открытого образования
                </div>
              </Link>
            </>,
            <>
              <TitleH2 content="Вас может заинтересовать" />
              <Banners />
              <SpaceElement />
            </>
          ]}
        />
      </Page>
    </div>
  );
};

export default Fashion;
