import React from "react";
import List from "../../components/list";

export const content = [
  {
    date: new Date(2021, 8, 16),
    id: "160921",
    title: <>Редактор в рубрику о моде и бьюти портала femmie.ru</>,
    text: (
      <>
        <p>Задачи:</p>
        <List
          arr={[
            "вникать в каждую тему и придумывать, как ее подать (доказательно с пруфами или в виде легкого чтива на вечерок);",
            "писать понятные ТЗ;",
            "руководить командой авторов (искать новых, прощаться со старыми, следить за дедлайнами, доводить тексты до ума, наставлять на путь истинный);",
            "растить авторов и себя в профессиональным плане;",
            "укладываться в сроки"
          ]}
        />
        <p>Работа удаленная, фултайм.</p>
        <p>
          Femmie.ru — это издание обо всем, что волнует современную женщину. Мы
          пишем про моду, уход за собой, психологию отношений, полезные привычки
          и много чего еще. Нас читают больше 10 млн в месяц.
        </p>
        <p>
          <a
            href="https://docs.google.com/forms/d/1SkuQHea6et0laT2soAR5Z5CwPo3uo8-xbQTrfZLpW1Q/edit"
            target="_blank"
          >
            Подробнее о вакансии
          </a>
        </p>
      </>
    ),
    image: "/img/jobopportunity/fammie.svg"
  }
];
