import React from "react";
import Helmet from "react-helmet";
import Page from "../../components/page";
import { content as c } from "./content";
import { MiniForm } from "../../components/forms";
import {
  BuilderLayout,
  HeadBlock,
  TitleH2,
  ShessBlock,
  Programma,
  Teachers,
  Text3Block
} from "../../patterns/pattern-fs/components";
import { ImageText } from "./image-text";
import Video from "../../components/video";
import Ig from "../../components/instagram";
import { Grid } from "@material-ui/core";
import List from "../../components/list";

function ChatBot(props) {
  return (
    <>
      <Helmet>
        <title>{c.title}</title>
        <meta name="keywords" content={c.keywords} />
        <meta name="descript" content={c.descript} />
      </Helmet>
      <Page lable={c.lable}>
        <BuilderLayout
          contents={[
            <HeadBlock content={c.head} />,
            <Text3Block content={c.text3block} />,
            <>
              <TitleH2 content={"Организаторы"} scroll="partners" />
              <ImageText content={c.zolotova} classBox="mb" />
              <ImageText content={c.rivodo} classBox="mb" />
            </>,
            <ShessBlock
              content={{
                left: <Video link={c.screen2.video} />,
                right: c.screen2.text
              }}
              mediaLeft
              mobileMediaUp
              vAlign="center"
            />,
            <Programma
              content={{
                programma: c.programma,
                variants: false
              }}
            />,
            <ShessBlock
              content={{
                left: c.rgsu.text,
                right: c.rgsu.image
              }}
              vAlign="center"
            />,
            <>
              <TitleH2 content={"Партнеры"} />
              <Grid container justify="center">
                {c.partners.map((el, i) => (
                  <Grid item xs={12} md={3} key={`partners-${i}`}>
                    <img src={el} alt="" />
                  </Grid>
                ))}
              </Grid>
            </>,
            <>
              <TitleH2 content={"Отзывы"} />
              <Grid container spacing={3} justify="center" alignItems="center">
                {c.reviews.map(el => (
                  <>
                    <Grid item xs={12} md={2}>
                      <img
                        src={el.image}
                        style={{ borderRadius: "50%" }}
                        alt={el.title}
                      />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <p>
                        <strong>{el.title}</strong>
                      </p>
                      <p>{el.text}</p>
                      <Ig a={el.instagram} />
                    </Grid>
                  </>
                ))}
              </Grid>
            </>,
            <Teachers
              content={{ teachers: c.teachers, teachersHead: c.teacherHead }}
            />,
            <>
              <TitleH2 content="Стоимость обучения" scroll="price" />
              <List
                arr={[
                  "Цена в 2021 — 1500$",
                  "Цены в 2022 — 1900$ + % с продаж и NFT",
                  "Цена одного места — дает право доступа и участию всей команды проекта ( до 5 чел.)",
                  "Возможны условия рассрочки"
                ]}
                num="none"
                olStyle={{ marginLeft: 0, paddingLeft: 0 }}
                classLi="p-constructor"
              />
            </>,
            <>
              <TitleH2 content={c.miniFormHead} scroll="singup" />
              <MiniForm formName="miniforma-chatbot" />
            </>
          ]}
        />
      </Page>
    </>
  );
}

export default ChatBot;
