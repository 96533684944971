import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Кузина Татьяна Олеговна",
        family: "Акимова",
        metaKeywords: "",
        metaDescript: "",
        head: "Кузина Татьяна Олеговна",
        image: "/img/about/kuzina.jpg",
        text: {
          edu: "Закончила ВШСДТ в 2021 году",
          exp: [
            "Провожу индивидуальные консультации, составляю книги стиля, готовлю съёмки-стилизации для частных клиентов.",
            "Специализируюсь на работе с российскими, СНГ, украинскими и грузинскими дизайнерами."
          ],
          motto: false
        }
      }}
    />
  );
}
