import React from "react";
import Helmet from "react-helmet";
import { content as c } from "./content";
import Page from "../../components/page";
import "./style.css";

export default function PriceList(props) {
  return (
    <div>
      <Helmet>
        <title>Прайс лист ВШСДТ</title>
        <meta
          name="keywords"
          content="стоимость обучения, школа, курсы, рассрочка, обучение"
        />
        <meta
          name="descript"
          content="	Высшая школа стилистики, дизайна и технологий. Актуальные курсы и стоимость обучения"
        />
      </Helmet>
      <Page lable={c.lable}>
        <div className="price">
          <h1>{c.head}</h1>
          {c.before.map(el => (
            <p>{el}</p>
          ))}
          {c.courses.map((el, key) => (
            <div className={key % 2 ? "course-box right" : "course-box"}>
              <h2>
                {el.title}. {el.duration}
              </h2>
              <p>
                <strong>Доступные форматы:</strong> {el.formats}
              </p>
              <p>
                <strong>Стоимость:</strong> {el.price.ofline ? null : el.price}
              </p>
              {el.price.ofline && <p>&emsp;&emsp;очно {el.price.ofline}</p>}
              {el.price.online && <p>&emsp;&emsp;онлайн {el.price.online}</p>}
              {el.creditOne ? (
                <p>{el.creditOne}</p>
              ) : (
                <p>
                  <strong>Рассрочка:</strong> {el.credit}
                </p>
              )}
            </div>
          ))}
          <p className="comment">{c.after}</p>
        </div>
      </Page>
    </div>
  );
}
