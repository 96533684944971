import React from "react";

export default function Short() {
  return (
    <div>
      <h2
        style={{
          fontSize: "1.8em",
          color: "rgb(100,100,100)",
          fontWeight: 700,
          textAlign: "left",
          margin: 0,
          padding: "24px 0",
          textTransform: "uppercase"
        }}
      >
        Дистанционное обучение
      </h2>
      <p
        style={{
          textAlign: "justify",
          margin: 0,
          padding: 0,
          color: "rgb(100,100,100)"
        }}
      >
        C 2013 года Высшая школа стилистики предлагает всем желающим пройти
        он-лайн курсы как по отдельным узкоспециализированным направлениям, так
        и по программам профессиональной переподготовки. Как и многие
        государственные Вузы, а также крупные коммерческие учебные заведения,
        Высшая Школа стилистики использует специализированную образовательную
        платформу Moodle (https://moodle.org/) для предоставления качественного
        образования дистанционно (далее СДО &mdash; система дистанционного
        обучения).
      </p>
    </div>
  );
}
