import React from "react";

import Head from "../../components/header-title";

export default function Header() {
  return (
    <Head
      head="Антикризисное управление розничным магазином в индустрии моды"
      subtitle="Курс | 4 встречи | 8 академ.часов | ОЧНО | ONLINE"
    />
  );
}
