import React from "react";
import List from "../../components/list";
import Page from "./page";
import styles from "./style";

export default function Component(props) {
  return (
    <Page
      title={"Интерьерный дизайн. Интенсив"}
      faqultet={"Дизайн интерьера"}
      head={{
        text: (
          <span>
            Интерьерный дизайн.
            <br />
            Интенсив
          </span>
        ),
        image: "url(/img/interior-design/fullscreen.jpg)",
        descript: "От идеи до эскиз-проекта квартиры. Для начинающих.",
        colorText: "rgb(255,255,255)",
        colorBlock: "rgba(0,0,0,0.6)"
      }}
      info={[
        "Узнайте о профессии и этапах дизайн-проектирования",
        "Научитесь искать идеи и создавать концепцию интерьера",
        "Воплотите свою идею на основе проекта квартиры-студии"
      ]}
      short={[
        "3 месяца | ",
        "24 занятия | ",
        "72 академических часа",
        <br />,
        "ОЧНО | ",
        "ДИСТАНЦИОННО"
      ]}
      descript={{
        text: (
          <div>
            <p style={styles.p}>
              <b>1 неделя</b>
              : вы изучите особенности работы с заказчиками, научитесь читать
              планировки
            </p>
            <p style={styles.p1}>
              <b>3 недели</b>
              : вы освоите креативные техники создания концепции жилой среды
            </p>
            <p style={styles.p1}>
              <b>6 недель</b>
              : вы познакомитесь с техниками визуализации вашей идеи
            </p>
            <p style={styles.p1}>
              <b>12 недель</b> и вы освоите базовые приемы создания и
              презентации концепции дизайн-проекта
            </p>
            <p style={styles.p1}>
              <b>Дополнительно</b> – открываем курс по стилевым направлениям в
              дизайне
            </p>
            <p style={styles.p1}>
              <b>Результат обучения</b>
              : разработка стилевого решения, создание эскиз-проекта с
              визуализацией в 2D графике
            </p>
          </div>
        ),
        image: "/img/interior-design/illustration.jpg"
      }}
      dates="25 января"
      avance="2000"
      price="5000"
      nameOfCredit="Интерьерный дизайн. Интенсив"
      linkBilling="https://billing.styleschool.ru/event/mTaknRNNduXrh7g7Q"
      condition
      programma={[
        {
          head: "Концептуальный модуль",
          text: (
            <List
              arr={[
                "Интерьер в историческом и современном контексте",
                "Современные стилевые направления, тенденции и материалы",
                "Источники вдохновения.Идея и реализация",
                "Концептуальное проектирование"
              ]}
              style={styles.p}
            />
          )
        },
        {
          head: "Модуль Дизайн и стилизация",
          text: (
            <List
              arr={[
                "Приемы и способы стилизации",
                "Цвет. Цветовые гармонии. Элементы цветопсихологии",
                "Композиция как способ гармонизации пространства",
                "Основы проектной графики. Графические инструменты"
              ]}
              style={styles.p}
            />
          )
        },
        {
          head: "Модуль Проектная реализация",
          text: (
            <List
              arr={[
                "Планировочное решение",
                "Дизайн-проект: структура, подача",
                "Презентация идеи. Moodboard. Concept board",
                "Визуализация идеи дизайн-проекта в 2D графике"
              ]}
              style={styles.p}
            />
          )
        }
      ]}
      note={
        <div>
          <p style={styles.p}>
            В программу курса включено онлайн обучение основам работы в
            графических редакторах <b>Adobe Photoshop</b>, <b>Gimp</b>.
          </p>
          <p style={styles.p}>
            Требование для изучения графических программ: владение компьютером
            на уровне продвинутого пользователя, наличие установленных
            графических программ или установка и использование облачных ресурсов
            в соответствии с предоставленной инструкцией.
          </p>
        </div>
      }
      leftNote={
        <p style={styles.p}>
          Успешное окончание курса позволит создать интерьер своей квартиры или
          продолжить обучение на курсе профессиональной переподготовки.
        </p>
      }
      rightNote={
        <p style={styles.p}>
          Выпускникам курса выдается сертификат ВШС при условии выполнения
          итоговой работы. Данная работа будет считаться вступительной на
          годовой курс профподготовки.
        </p>
      }
      formsEducation
      about={
        "Интенсивный курс по дизайну интерьера поможет понять суть профессии и принципы дизайн-мышления, приобрести первые необходимые навыки для тех, кто планирует пройти профессиональное обучение, но при этом ранее дизайну не обучался."
      }
      teacher={{
        name: "Марина Костарнова",
        text:
          "Практикующий дизайнер (Бельгия). 20 лет стаж преподавания, 10 лет руководства кафедрой дизайна в России",
        image: "/img/interior-design/teacher03.jpg"
      }}
      banners
    />
  );
}
