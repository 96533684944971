import React from "react";
import { Youtube } from "../root-test2/icons";
import { rgbToHex } from "@material-ui/core";

export const content = {
  head: (
    <span>
      Центр карьеры
      <br />
      Высшей школы стилистики, дизайна и технологий
    </span>
  ),
  advantage: [
    "Резюме лучших учеников ВШСДТ, у которых сильные и проработанные проекты автоматически попадают в базу специалистов Центра карьеры на сайте Высшей школы стилистики, дизайна и технологий что позволяет находить клиентов, заказы и зарабатывать в профессии.",
    "Возможность проходить стажировки и практики в реальных проектах, в том числе на ТВ, в глянцевых изданиях, неделях моды. Обучение работе в команде профессионалов fashion индустрии, организация коллабораций,  рекламных интеграций, работа с инфлюэнсерами. Формирование портфолио для дальнейшей работы.",
    "Возможность собрать команду для реализации ваших коммерческих проектов. Если у вас есть большой проект и нужны специалисты для его реализации (стилисты, фотографы, дизайнеры, бизнес-консультанты, визажисты и др.) вы сможете найти их в нашей базе.",
    "Возможность использовать площадки ВШСДТ для организации и проведения профессиональных мероприятий для участников клуба."
  ],
  about:
    "Центр карьеры — центр коллабораций и совместного развития бизнеса. Используйте систему организации обучения, площадки и ресурсы ВШСДТ для организации и проведения профессиональных мероприятий. Своих и партнерских. Все это — возможности для членов клуба и центра карьеры ВШСДТ.",
  tiles: [
    {
      text:
        "База специалистов / стилистов фотосессий, имидж-консультантов, шоперов",
      link: "/students-resume",
      image: "/img/career/basa-resumes.jpg",
      buttonWithForm: "отправить заявку"
    },
    {
      text: "Консалтинг от экспертов школы / Закрытый клуб ВШСДТ",
      link: "",
      buttonWithForm: "стать экспертом",
      video: "rYKZAsSnC84"
    }
  ],
  tiles2: [
    {
      text: "Партнеры",
      link: "",
      image: "/img/career/partners.jpg",
      buttonWithForm: "предложить сотрудничество"
    },
    {
      text: "Вакансии / Стажировки",
      link: "",
      image: "/img/career/vacansies.jpg",
      buttonWithForm: "запрос на специалиста"
    }
  ],
  tiles3: [
    {
      text: "Интервью с экспертами fashion рынка",
      buttonLink: "https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ",
      buttonLinkContent: (
        <span>
          <span style={{ filter: "invert(0)" }}>Видео канал ВШСДТ&emsp;</span>
          <Youtube color="rgb(0,0,0)" size="1rem" />
        </span>
      ),
      video: "fOZzI3bKXIU"
    },
    // {
    //   text: "Учебный процесс: видео, фото",
    //   link: ""
    // },
    {
      text: "Практики / Стажировки",
      link: "/",
      image: "/img/career/practics.jpg"
    }
    // {
    //   text: "Стажировка на Milan Design Week",
    //   link: ""
    // }
  ],
  resumesText:
    "Резюме лучших студентов и выпускников ВШСДТ размещаются в базе специалистов Центра карьеры. Эксперты школы помогают находить проекты и зарабатывать в профессии.",
  vacansiesText:
    "Соберите команду для реализации ваших коммерческих проектов. Если у вас есть большой проект и нужны специалисты для его реализации (стилисты, фотографы, дизайнеры, бизнес-консультанты, визажисты и др.) — вы сможете найти их здесь.",
  practicsText:
    "Участвуйте в реальных проектах: на ТВ, в глянцевых изданиях, неделях моды. Работайте в команде профессионалов fashion индустрии, инициируйте коллаборации,  рекламные интеграции, работайте с инфлюэнсерами. Создавайте и расширяйте портфолио для дальнейшей карьеры и бизнеса."
};
