import React from "react";
import Helmet from "react-helmet";
import Page from "./page";
import PageGlobal from "../../components/page";

function MainPage(props) {
  return (
    <div>
      <Helmet>
        <title>
          Ассоциация выпускников Высшей школы стилистики, дизайна и технологий
        </title>
        <meta
          name="descript"
          content="Ассоциация выпускников Высшей школы стилистики, дизайна и технологии. Нетворкинг, актуальные темы для дизайнеров и стилистов, возможности и сотрудничество"
        />
        <meta name="keywords" content="ассоциация,ВШСДТ,студент,выпускник" />
      </Helmet>
      <PageGlobal lable="Ассоциация">
        <Page />
      </PageGlobal>
    </div>
  );
}

export default MainPage;
