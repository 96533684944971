import React from "react";
import Card from "../card";

export default function CardModern(props) {
  return (
    <Card
      head="Искусство"
      title="Первые школы дизайна: Баухауз и ВХУТЕМАС"
      teacher="Галина Варакина"
      descript={<span>on-line</span>}
      online
      date="20 июня"
      time={<span>19:00</span>}
      duration=""
      image="/img/modern-fashion-style/school-design.jpg"
      text={
        <span>
          Первая часть: понятие «Баухауз» теперь ни у кого не вызывает
          недоумения. Он прочно вошел в нашу жизнь как название современного
          стиля архитектуры и интерьера. Тем не менее, еще несколько десятилетий
          назад это была настоящая революция в стилистике и в эстетике,
          затронувшая не только высокий дизайн и архитектуру, но и обыденную
          жизнь людей.
          <br />
          Вторая часть лекции: В 1920-е годы в Советской России на волне
          эпохальных завоеваний происходит формирование нового государства,
          нового человека и его культуры. Своеобразным преломлением
          революционных событий стало зарождение нового искусства и дизайна.
          Именно в этом время открывается ВХУТЕМАС – один из центров
          авангардизма, давший начало отечественному дизайну.
        </span>
      }
      q
      // free
      part
      // partPrice="600"
      // price="5900"
      color="rose"
      id="ZzkB46QxSLEjchBQi"
      setBillingId={props.setBillingId}
    />
  );
}
