import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import List from "../../components/list";
import { stylesDark, styles } from "./styles";
import Video from "../../components/video";
import { withStyles } from "@material-ui/styles";
// import Blocks from "./blocks";
import { ShessBlocks } from "./shess-blocks";
import ScrollableAnchor from "react-scrollable-anchor";
import { News } from "./news";
import { content } from "./contents";
import {
  Vk,
  Facebook,
  Instagram,
  Youtube,
  Path
  /* Login2 */
} from "./icons";
import { Rutube } from "../../components/icons";
import { projects } from "./projects";

const wStyles = theme => ({
  ...stylesDark,
  ...styles
});
const font = "Alegreya Sans";

function Top(props) {
  const { classes } = props;

  const MobileLeft = props => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            background: "rgb(255,255,255)",
            padding: "2rem",
            paddingBottom: 0
          }}
        >
          <div
            style={{
              textAlign: "center",
              padding: "2rem",
              paddingTop: "4rem",
              paddingBottom: 0,
              background: "rgb(25,25,25)"
            }}
          >
            <img
              src="/img/root-test2/emblema-3d-5.png"
              alt="Высшая школа стилистики, дитзайна и технологий"
              style={{
                maxWidth: "100%"
              }}
            />
            <div style={{ height: "3rem" }} />
            <div className={classes.icons}>
              <a href="https://imagemakerpro.ru" target="_blank">
                <img
                  src="/img/root-test2/regicon.png"
                  alt="ВШСДТ"
                  style={{
                    height: 18,
                    width: "auto"
                  }}
                />
              </a>
              &ensp;
              <a href="https://vk.com/stylemsk" target="_blank">
                <Vk color="rgb(200,200,200)" size="24px" width="1.2" />
              </a>
              &ensp;
              <a href="https://open-ai.ru/" target="_blank">
                <img
                  src="/img/other/open-ai.png"
                  style={{ height: 18, width: "auto" }}
                  alt="Open AI"
                />
              </a>
              &ensp;
              <a
                href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
                target="_blank"
              >
                <Youtube color="rgb(200,200,200)" size="20px" />
              </a>
              &ensp;
              <a href="https://rutube.ru/channel/23668241/" target="_blank">
                <Rutube color="rgb(200,200,200)" size={14} />
              </a>
              &ensp;
              <img
                src="/img/root-test2/vshsdt.png"
                alt="#ВШСДТ"
                style={{
                  height: 14 // 18
                }}
              />
            </div>
          </div>
        </Grid>
      </React.Fragment>
    );
  };

  const MobileRight = props => {
    return (
      <React.Fragment>
        <Grid item xs={12} md={6} style={{ background: "rgb(255,255,255)" }}>
          <div
            className={classes.nameSchool}
            style={{
              fontFamily: "PT Sans Narrow",
              textAlign: "center"
            }}
          >
            Высшая школа стилистики,
            <br />
            дизайна и технологий
          </div>
          <div
            className={classes.descriptSchool}
            style={{
              fontFamily: font
            }}
          >
            <Hidden smDown>
              Мода, дизайн, IT: профессиональное образование
              <br />и повышение квалификации в креативных индустриях
            </Hidden>
            <Hidden mdUp>
              Мода, дизайн, IT: профессиональное образование
              <br />и повышение квалификации
              <br />в креативных индустриях
            </Hidden>
          </div>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Grid container justify="space-between" alignItems="stretch">
          <Hidden smDown>
            <Grid
              item
              md={12}
              style={{ background: "rgb(255,255,255)" }}
              className={classes.spaceTop}
            />
            <Grid
              item
              md={12}
              style={{
                backgroundImage:
                  "url('/img/root-test2/emblema.png'),linear-gradient(to right, rgb(255,255,255) 0, rgb(255,255,255) 460px, rgb(25,25,25) 460px, rgb(25,25,25) 1000px)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 116px top 80px, left center",
                backgroundSize: "auto 393px, auto"
              }}
            >
              <div
                className={classes.nameSchool}
                style={{
                  fontFamily: "PT Sans Narrow", // "Alice",
                  fontSize: "3.7rem",
                  textShadow: "4px 4px 0px rgba(0,0,0,0.15)",
                  position: "sticky",
                  top: 0
                }}
              >
                Высшая школа стилистики,
                <br />
                дизайна и технологий
              </div>
              <div
                className={classes.descriptSchool}
                style={{
                  fontFamily: font
                }}
              >
                Мода, дизайн, IT: профессиональное образование
                <br />и повышение квалификации в креативных индустриях
              </div>
            </Grid>
            <Grid
              item
              md={6}
              style={{
                background:
                  "linear-gradient(to right, rgb(255,255,255) 0, rgb(255,255,255) 460px, rgb(25,25,25) 460px, rgb(25,25,25) 500px)"
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2.3rem"
                }}
              >
                <a href="#next">
                  <Path color="rgb(100,100,100)" size={32} />
                </a>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              style={{
                background: "rgb(25,25,25)"
              }}
            >
              <div className={classes.icons}>
                <a href="https://imagemakerpro.ru" target="_blank">
                  <img
                    src="/img/root-test2/regicon.png"
                    alt="ВШСДТ"
                    style={{
                      height: 18,
                      width: "auto"
                    }}
                  />
                </a>
                &ensp;
                <a href="https://vk.com/stylemsk" target="_blank">
                  <Vk color="rgb(200,200,200)" size="24px" width="1.2" />
                </a>
                &ensp;
                <a href="https://open-ai.ru/" target="_blank">
                  <img
                    src="/img/other/open-ai.png"
                    style={{ height: 18, width: "auto" }}
                    alt="Open AI"
                  />
                </a>
                &ensp;
                <a
                  href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ"
                  target="_blank"
                >
                  <Youtube color="rgb(200,200,200)" size="20px" />
                </a>
                &ensp;
                <a href="https://rutube.ru/channel/23668241/" target="_blank">
                  <Rutube color="rgb(200,200,200)" size={14} />
                </a>
                &ensp;
                <img
                  src="/img/root-test2/vshsdt.png"
                  alt="#ВШСДТ"
                  style={{
                    height: 14
                  }}
                />
              </div>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <MobileLeft />
            <MobileRight />
          </Hidden>
          <Grid item xs={12} md={12} className={classes.space} />
          <ScrollableAnchor id="next">
            <div style={{ height: 1 }} />
          </ScrollableAnchor>
          <Grid item xs={12} md={12} className={classes.space} />
          <Grid item xs={12} md={12}>
            <News
              content={projects}
              head={content.titleProjects}
              headStyle={{ textAlign: "left" }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.space} />
          <Grid item xs={12} md={12}>
            {content.titleScr2}
            <ShessBlocks content={content.profEdu} />
          </Grid>
          <Grid item xs={12} md={12} className={classes.space} />
          <Grid item xs={12} md={12}>
            <News headStyle={{ textAlign: "left" }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container justify="center">
              <Grid item xs={12} md={6}>
                <h2
                  className={classes.titleBig}
                  style={{ textAlign: "center" }}
                >
                  Перейти в раздел:
                </h2>
                {/* <List arr={links} /> */}
                <List
                  arr={content.links.map((el, key) => (
                    <Link to={el.link} key={`link-home-page-${key}`}>
                      {el.text}
                    </Link>
                  ))}
                  num="none"
                  style={{ paddingBottom: "1rem", textAlign: "center" }}
                  olStyle={{ marginLeft: 0, paddingLeft: 0 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.space} />
          <Grid item xs={12} md={12}>
            <h2
              className={classes.head}
              style={{
                textAlign: "center",
                fontFamily: "PT Sans Narrow"
              }}
            >
              Интервью с выпускниками факультета моды
            </h2>
          </Grid>
          {content.videos.map((el, key) => (
            <>
              <Grid item xs={12} md={4} key={`key-root-page-${key}`}>
                <Video link={el} h={360} w={400} />
              </Grid>
            </>
          ))}
          <Grid item xs={12} md={12}>
            <p className="p-constructor text-center">
              Подписывайтесь на Youtube канал ВШСДТ ТВ:
              <br />
              интервью с преподавателями, экспертами, выпускниками, открытые
              лекции, обзоры трендов
            </p>
            <p className="text-center">
              <a href="https://www.youtube.com/channel/UC8ByVFm0GkcauK5h_t2BTwQ">
                Перейти на Youtube канал &gt;&gt;&gt;
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={12} className={classes.space} />
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(wStyles)(Top);
