import React from "react";
import { Check } from "../../components/title-w-svg-icon/check";

export const content = {
  head: {
    title:
      "Партнерская программа ВШСДТ для лекторов и частных образовательных организаций",
    text: (
      <>
        Конкурентные преимущества для ваших образовательных продуктов.
        Предложение действует для частных лекторов и образовательных проектов,
        заинтересованных в законном ведении профессионального обучения, выдаче
        документов установленного государством образца, участии в грантах,
        объединению ресурсов и совместном продвижении образовательных продуктов.
      </>
    )
  },
  nav: [
    {
      image: "/img/partners-pro/mission.jpg",
      title: "СИЛА",
      text: (
        <>
          Субсидируйте обучение за счет государственных грантов,
          пре&shy;дос&shy;тав&shy;ляй&shy;те гранты, работайте на базе ведущих
          вузов, выда&shy;вай&shy;те дипломы лучших рос&shy;сий&shy;ских
          обра&shy;зова&shy;тель&shy;ных брендов, трудо&shy;устраи&shy;вайте
          выпуск&shy;ников по заявкам крупнейших российских компаний
        </>
      ),
      link: "mission"
    },
    {
      image: "/img/partners-pro/safety.jpg",
      title: "БЕЗОПАСНОСТЬ",
      text: (
        <>
          Соответствуйте закону — получайте поддержку в оформлении
          образовательных программ, ведите деятельность на основании
          образовательных лицензий. Минимизируйте риски, предоставляйте
          банковские рассрочки и пользуйтесь ведением документооборота
        </>
      ),
      link: "safety"
    },
    {
      image: "/img/partners-pro/freedom.jpg",
      title: "СВОБОДА",
      text: (
        <>
          Выбирайте уровень поддержки, пользуйтесь системами автоматизации
          образовательных услуг на базе разговорного искусственного интеллекта.
          Сконцентрируйтесь на клиентах и качестве образовательных продуктов,
          освобождаясь от типовых рутинных процессов
        </>
      ),
      link: "freedom"
    }
  ],
  mission: {
    background: "/img/partners-pro/mission-background.jpeg",
    content: {
      title: "Миссия",
      text: (
        <>
          Миссия партнерской программы — создание синергетического эффекта от
          сотрудничества проверенных временем ведущих вузов и инновационных
          образовательных проектов. Наша сила в союзе творческой энергии авторов
          образовательных продуктов и мощи некоммерческих, государственных,
          корпоративных и профессиональных ресурсов
        </>
      )
    }
  },
  freedom: {
    title: "Почувствуйте свободу",
    afterTitle: "Возложите на нас необходимое количество рутинных процессов",
    content: [
      {
        title: "Документооборот",
        text:
          "Оформление рабочих документов в соответствии с 237 ФЗ об образовании, ведение личных дел студентов"
      },
      {
        title: "Сопровождение",
        text:
          "Организация и контроль образовательных процессов: расписаний, занятий, преподавателей, отчетности"
      },
      {
        title: "Автоматизация",
        text:
          "Внедрение в процессы обучения диалоговых систем на базе разговорного искусственного интеллекта"
      }
    ],
    link:
      "https://docs.google.com/forms/d/e/1FAIpQLSc85IyK5dop7IrzeCew2PK-F2xiBmLNON2t6-FdPILkHy7DbQ/viewform?usp=sf_link"
  },
  safety: {
    title: "Войдите в безопасную среду",
    afterTitle: "С нами действуйте на основе закона об образовании",
    content: [
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Лицензии</h3>
            <p>
              Ведите деятельность на основании образовательных лицензий
              партнеров без дополнительной отчетности
            </p>
          </>
        )
      },
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Локация</h3>
            <p>
              Используйте просторные, оборудованные аудитории исторических
              зданий с высокой доступностью
            </p>
          </>
        )
      },
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Финансы</h3>
            <p>
              Минимизируйте риски, передайте банковское обслуживание клиентов и
              договорные обязательства
            </p>
          </>
        )
      }
    ]
  },
  power: {
    title: "Обретите силу",
    afterTitle: "Используйте наши ресурсы и связи",
    content: [
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Программы</h3>
            <p>
              Используйте разработанные авторские курсы, подключите опытных
              тренеров и преподавателей ведущих вузов
            </p>
          </>
        )
      },
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Дипломы</h3>
            <p>
              Выдавайте документы установленного государством образца. Ваши
              выпускники получат налоговый вычет
            </p>
          </>
        )
      },
      {
        icon: <Check size={48} color="rgb(250,128,30)" />,
        text: (
          <>
            <h3>Продвижение</h3>
            <p>
              Подключайтесь к банковским рассрочкам, образовательным грантам,
              трудоустройству, взаимному продвижению
            </p>
          </>
        )
      }
    ]
  },
  whyus: [
    <>
      <h3>СКОРОСТЬ</h3>
      <p>Рассмотрение заявки о партнерстве за 5 рабочих дней</p>
    </>,
    <>
      <h3>ГИБКОСТЬ</h3>
      <p>Гибкие условия участия в партнерской программе</p>
    </>,
    <>
      <h3>ДОСТУПНОСТЬ</h3>
      <p>Стоимость партнерской программы от 5% с оборота</p>
    </>,
    <>
      <h3>ОПЫТ</h3>
      <p>Консультанты с более чем 30 летним опытом в сфере образования</p>
    </>,
    <>
      <h3>ПАРТНЕРЫ</h3>
      <p>Государственные вузы, учебные центры, частные тренеры</p>
    </>,
    <>
      <h3>СООБЩЕСТВО</h3>
      <p>Руководители и предприниматели, преподаватели и выпускники</p>
    </>
  ],
  partens: [
    {
      image: "/img/partners-pro/mfti.png",
      text: "Московский физико-технический институт"
    },
    {
      image: "/img/partners-pro/mpsu.png",
      text: "Московский психолого-социальный университет"
    },
    {
      image: "/img/partners-pro/mhpi.png",
      text: "Московский художественно-промышленный институт"
    },
    {
      image: "/img/partners-pro/tehnograd.png",
      text: "Инновационно-образовательный комплекс Техноград"
    }
  ]
};
