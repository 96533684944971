import React from "react";
import { ListItem } from "../list-w-icon/list";
import "./style.css";

export function DigICircle(props) {
  return <div className="number-icon">{props.number}</div>;
}

export function NumberList(props) {
  const c = props.content;
  return c.map((el, key) => (
    <ListItem icon={<DigICircle number={key + 1} />} text={el.text} />
  ));
}

export function NumberList6x2(props) {
  const c = props.content;
  return (
    <div className="grid-6x2">
      {c.map((el, key) => (
        <ListItem icon={<DigICircle number={key + 1} />} text={el} />
      ))}
    </div>
  );
}
