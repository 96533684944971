import React from "react";
import { colors, useStyles } from "./styles";

const About = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        background: colors.indigo,
        color: colors.lightForText,
        padding: 24
      }}
    >
      <h2 className={classes.h2}>О курсе</h2>
      <p className={classes.p}>
        Внешность, костюм, аксессуары, make-up, причёска &mdash; все эти
        визуальные аспекты можно объединить в одно понятие. И название этому
        понятию &mdash; «стиль». Что же такое стиль? Стиль &mdash; это идея,
        послание, смысл и содержание человека. Если стиль подобран правильно, то
        он становится понятен окружающим. Стилисты-имиджмейкеры, визажисты и
        парикмахеры работают с непривычным набором инструментов, такими как:
        внешность человека, его индивидуальность, цвет, линии пропорции,
        эмоциональность... Зачастую, не знание основ стилистики, стилевых групп
        и приемов приводят к тому, что уникальная творческая работа превращается
        в банальное выполнение технических приёмов. Современным
        стилистам-визажистам и парикмахерам нужно уметь не только создавать
        make-up и прическу к заданному костюму, но и самим видеть, создавать ,
        улучшать целостный образ , а так же давать грамотные и верные
        рекомендации своим клиентам. Изучение стилевых групп, стилевых доминант
        внешности, в буквальном смысле «чтение по лицам» &mdash; дадут Вам новые
        возможности в создании уникальных и стильных образов.
      </p>
    </div>
  );
};

export default About;
