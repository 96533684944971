export const content = {
  head: {
    head: "Смотрите открытые лекции",
    lable: "Спасибо"
  },
  head1: {
    headDescript: "Профессиональное образование",
    head: "Костюм «для дела»: правила новой публичности",
    headAfterDescript:
      "Деловой стиль, деловой костюм, деловая репутация — факторы успешности. Получите актуальные знания для дела",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 1 месяц, 18 академических часа",
      "Смотрите первую лекцию курса бесплатно сейчас"
    ],
    text: "",
    imageSource: "/img/business-style-g/business-style.jpg",
    video: "mWjpmmp17vc",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    },
    sizeVideo: true
  },
  head2: {
    headDescript: "Профессиональное образование",
    head: "Главные тенденции современной моды",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "1 месяц, 24 академических часа",
      "Смотрите первую лекцию курса бесплатно сейчас"
    ],
    headAfterDescript: "Обучение профессии дизайнера интерьеров",
    text: "",
    imageSource: "/img/trends-of-modern-fashion/trends-of-fashion.jpg",
    video: "7jVcZHuP4MU",
    sizeVideo: true
  }
};
