import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import Cot from "./cot";

export default function ProgrammaComponent(props) {
  const content = [
    {
      title: (
        <span style={{ fontFamily: props.font.title }}>
          1, 2, 3 месяцы: 1-й уровень.
          <br />
          Основы имиджмейкинга (Style Basic). 120 академ. часов
        </span>
      ),
      text: (
        <React.Fragment>
          <Paragraph
            content={[
              "Все для правильного старта карьеры и творчества в индустрии.",
              "В процесс обучения входят: система подбора одежды, аксессуаров, образа от колористики до стилей и гардероба. Актуальные правила покупки стильных и модных вещей."
            ]}
          />
          <List
            arr={[
              "колористика и цветовой анализ внешности как профессиональный инструмент стилиста",
              "психология формы и силуэтов, современные типологии фигур, возможности и способы визуальной коррекции с помощью одежды",
              "изучение основных групп современных стилей как системы для транслирования необходимого впечатления в имидже",
              "изучение аксессуаров, головных уборов, обуви, правил и способов их подбора с учетом fashiontrends",
              "профессиональный глоссарий стилиста, изучение профессиональной лексики и терминологии",
              "теория стилевых направлений как действенный инструмент в создании индивидуального стиля",
              "мужская стилистика, ее особенности, практика применения и современные тренды",
              "изучение типов современного гардероба, создание гардеробных капсул",
              "теория и практика шопинга, в том числе on-line шопинга"
            ]}
          />
          <Paragraph
            content={[
              <span style={{ fontWeight: 700 }}>
                <img
                  src="/img/green/star.svg"
                  alt=""
                  style={{ height: 16, marginBottom: -2 }}
                />
                &ensp;Итоговая аттестация: ЗАЧЕТ (тест)
              </span>
            ]}
          />
        </React.Fragment>
      )
    },
    {
      title: (
        <span style={{ fontFamily: props.font.title }}>
          4, 5, 6 месяцы: 2-й уровень.
          <br />
          Имидж-консалтинг (Personal styling). 120 академ. часов
        </span>
      ),
      text: (
        <React.Fragment>
          <Paragraph
            content={[
              "Специализация: имидж-стилист, имидж-консультант, шопер.",
              "В процесс обучения входят: алгоритмы и инструменты консультирования клиентов по моде и стилю, шопинг сопровождение. Мужская и женская стилистика."
            ]}
          />
          <List
            arr={[
              "управление впечатлением",
              "структура имиджа, оценка впечатления",
              "психологические особенности разработки имиджа",
              "имиджевое восприятие и инструменты влияния",
              "концепция индивидуального стиля. Теория стилевых направлений",
              "концепция индивидуального стиля. Смешение стилевых направлений",
              "мужской стиль. Особенности стильного образа у мужчин",
              "дресс-коды, этикет",
              "практика формирования клиентского STYLE BOOK",
              "изучение особенностей презентации себя как профессионала в соц. сетях (Instagram)"
            ]}
          />
          <Paragraph
            content={[
              "Специальный модуль: Профайлинг",
              <List
                arr={[
                  "изучение психологии клиентов, правил коммуникаций с клиентами",
                  "современная система профайлинга и психотипирования",
                  "алгоритмы работы с клиентами"
                ]}
              />,
              <span style={{ fontWeight: 700 }}>
                <img
                  src="/img/green/star.svg"
                  alt=""
                  style={{ height: 16, marginBottom: -2 }}
                />
                &ensp;ЭКЗАМЕН (защита проектной работы)
              </span>
            ]}
          />
        </React.Fragment>
      )
    },
    {
      title: (
        <span style={{ fontFamily: props.font.title }}>
          7-10 месяцы: 3-й уровень.
          <br />
          Стилистика моды (Fashion styling). 180 академ. часов
        </span>
      ),
      text: (
        <React.Fragment>
          <Paragraph
            content={[
              "Специализация: стилист фотосессий, стилист модных проектов",
              "В процесс обучения входят: стилизация событий в индустрии моды (фотосессии, показы, ТВ), проекты для fashion retail. Развитие креативности"
            ]}
          />
          <List
            arr={[
              "изучение истории моды от древности до наших дней как необходимой базы в практике fashion-стилиста",
              "изучение специальных дисциплин по композиции для стилистов",
              "развитие навыков креативного мышления (эвристика)",
              "изучение метода ассоциаций для создания художественного образа или fashion-образа",
              "метод эмоциональных линий",
              "особенности стилизации проектов для клиентов и глянца",
              "источники вдохновения для имиджмейкеров и стилистов, метод исследования",
              "практика создания и презентации mood-boards",
              "изучение основ коммуникаций стилистов с фотографами, моделями, визажистами",
              "практика работы в команде и реализации fashion-съемки",
              "практика работы в команде и реализации имиджевой сьемки",
              "практика работы в команде и реализации предметной сьемки"
            ]}
          />
          <Paragraph
            content={[
              "Специальный модуль: Теория и практика моды",
              <List
                arr={[
                  "мода как система, мода как бизнес",
                  "теория и аналитика fashion-индустрии",
                  "анализ модных трендов и обзор актуальных показов",
                  "сегменты fashion-рынка",
                  "понятие и анализ ДНК брендов"
                ]}
              />,
              <span style={{ fontWeight: 700 }}>
                <img
                  src="/img/green/star.svg"
                  alt=""
                  style={{ height: 16, marginBottom: -2 }}
                />
                &ensp;ЭКЗАМЕН (защита проектной работы)
              </span>
            ]}
          />
        </React.Fragment>
      )
    },
    {
      title: (
        <span style={{ fontFamily: props.font.title }}>
          11 месяц: 4-й уровень.
          <br />
          Личный бренд (Personal branding). 60 академ. часов
        </span>
      ),
      text: (
        <React.Fragment>
          <Paragraph
            content={[
              "Специализация: Создание стиля и личного бренда выпускника",
              "В финале обучения: подготовка к защите дипломной работы, формирование портфолио, стиля и стратегии профессионального продвижения."
            ]}
          />
          <List
            arr={[
              "имидж-технологии в управлении деловой репутацией",
              "профессиональный стиль",
              "этапы создания личного бренда",
              "способы продвижения профессиональных услуг стилиста и имиджмейкера на рынке",
              "формирование портфолио стилиста",
              "подготовка к защите дипломной работы"
            ]}
          />
          <Paragraph
            content={[
              "Специальный модуль: Основы создания текстов о моде",
              <List
                arr={[
                  "блоггинг как творчество и блоггинг как бизнес",
                  "создание текстов о моде и стиле",
                  "создания текстов и подбора иллюстраций для интернет и соц. сетей"
                ]}
              />
            ]}
          />
        </React.Fragment>
      )
    },
    {
      title: "В процессе обучения в программу включены:",
      text: (
        <List
          arr={[
            "МАСТЕР-КЛАССЫ С ПРИГЛАШЕННЫМИ ЭКСПЕРТАМИ",
            "ПОСЕЩЕНИЕ ПОКАЗОВ И НЕДЕЛЬ МОДЫ",
            "УЧАСТИЕ В МОДНЫХ ПРОЕКТАХ",
            "ОБЩЕНИЕ И ЗНАКОМСТВО С БУДУЩИМИ ПАРТНЕРАМИ И ЕДИНОМЫШЛЕННИКАМИ",
            "ПОСТОЯННАЯ СТИЛЬНАЯ АТМОСФЕРА"
          ]}
        />
      )
    },
    {
      title: false,
      text: (
        <Paragraph
          content={[
            <span
              style={{
                fontSize: "1.4em",
                fontWeight: 500,
                color: "rgb(80,80,80)"
              }}
            >
              <img
                src="/img/green/seal.svg"
                alt=""
                style={{ height: 24, marginBottom: -5 }}
              />
              Итоговая аттестация: <b>защита дипломной работы</b>
            </span>
          ]}
          c
        />
      )
    }
  ];

  return (
    <div>
      <Cot
        font={props.font.title}
        noHead={true}
        open={true}
        text={content.map(
          (el, i) =>
            el.title ? (
              <div>
                <h3
                  style={{
                    fontFamily: props.font ? props.font.title : "inherit",
                    fontSize: "1.4em",
                    fontWeight: 500,
                    textAlign: "center",
                    color: "rgb(100,0,0)"
                  }}
                >
                  {el.title}
                </h3>
                <div
                  style={{
                    fontSize: "1.1em"
                  }}
                >
                  {el.text}
                </div>
              </div>
            ) : (
              el.text
            )
        )}
      />
    </div>
  );
}
