import React from "react";
import G from "../graduate-pattern";

export default function Page(props) {
  return (
    <G
      content={{
        title: "Сотникова Алина Сергеевна",
        family: "Сотникова",
        metaKeywords: "",
        metaDescript: "",
        head: "Сотникова Алина Сергеевна",
        image: "/img/career/sotnikova.jpg",
        text: {
          edu:
            "Закончила ВШСДТ в 2021 году, курс «Имидж-консалтинг и fashion-styling»",
          exp: false,
          motto: false
        }
      }}
    />
  );
}
