import React from "react";
import Page from "../../components/page";
import Helmet from "react-helmet";
import Layout from "./layout";
import { content } from "./content";

function Ephires(props) {
  return (
    <div>
      <Helmet>
        <title>Открытые мероприятия — встречи и эфиры ВШСДТ</title>
        <meta
          name="keywords"
          content="открытые, мероприятия, встречи, эфиры, лекции"
        />
        <meta
          name="descript"
          content="Открытые мероприятия, лекции, встречи с экспертами и выпускниками Высшей школы стилистики, дизайна и технологий (ВШСДТ)"
        />
      </Helmet>
      <Page lable={content.head.lable} lableMobile={content.head.lable}>
        <Layout contents={content} />
      </Page>
    </div>
  );
}

export default Ephires;
