import React from "react";
import { Grid } from "@material-ui/core";

function WathApp(props) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 1219.547 1225.016">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="609.77"
        y1="1190.114"
        x2="609.77"
        y2="21.084"
      >
        <stop offset="0" stop-color="#20b038" />
        <stop offset="1" stop-color="#60d66a" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M27.875 1190.114l82.211-300.18c-50.719-87.852-77.391-187.523-77.359-289.602.133-319.398 260.078-579.25 579.469-579.25 155.016.07 300.508 60.398 409.898 169.891 109.414 109.492 169.633 255.031 169.57 409.812-.133 319.406-260.094 579.281-579.445 579.281-.023 0 .016 0 0 0h-.258c-96.977-.031-192.266-24.375-276.898-70.5l-307.188 80.548z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#FFF"
        d="M462.273 349.294c-11.234-24.977-23.062-25.477-33.75-25.914-8.742-.375-18.75-.352-28.742-.352-10 0-26.25 3.758-39.992 18.766-13.75 15.008-52.5 51.289-52.5 125.078 0 73.797 53.75 145.102 61.242 155.117 7.5 10 103.758 166.266 256.203 226.383 126.695 49.961 152.477 40.023 179.977 37.523s88.734-36.273 101.234-71.297c12.5-35.016 12.5-65.031 8.75-71.305-3.75-6.25-13.75-10-28.75-17.5s-88.734-43.789-102.484-48.789-23.75-7.5-33.75 7.516c-10 15-38.727 48.773-47.477 58.773-8.75 10.023-17.5 11.273-32.5 3.773-15-7.523-63.305-23.344-120.609-74.438-44.586-39.75-74.688-88.844-83.438-103.859-8.75-15-.938-23.125 6.586-30.602 6.734-6.719 15-17.508 22.5-26.266 7.484-8.758 9.984-15.008 14.984-25.008 5-10.016 2.5-18.773-1.25-26.273s-32.898-81.67-46.234-111.326z"
      />
      <path
        fill="#FFF"
        d="M1036.898 176.091C923.562 62.677 772.859.185 612.297.114 281.43.114 12.172 269.286 12.039 600.137 12 705.896 39.633 809.13 92.156 900.13L7 1211.067l318.203-83.438c87.672 47.812 186.383 73.008 286.836 73.047h.255.003c330.812 0 600.109-269.219 600.25-600.055.055-160.343-62.328-311.108-175.649-424.53zm-424.601 923.242h-.195c-89.539-.047-177.344-24.086-253.93-69.531l-18.227-10.805-188.828 49.508 50.414-184.039-11.875-18.867c-49.945-79.414-76.312-171.188-76.273-265.422.109-274.992 223.906-498.711 499.102-498.711 133.266.055 258.516 52 352.719 146.266 94.195 94.266 146.031 219.578 145.992 352.852-.118 274.999-223.923 498.749-498.899 498.749z"
      />
    </svg>
  );
}

function Telegram(props) {
  return (
    <svg
      enable-background="new 0 0 24 24"
      height={props.size * 1.1}
      viewBox="0 0 24 24"
      width={props.size * 1.1}
    >
      <circle cx="12" cy="13" fill="#ffffff" r="12" />
      <circle cx="12" cy="13" fill="#039be5" r="10" />
      <path
        d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z"
        fill="#fff"
      />
    </svg>
  );
}

function Phones(props) {
  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      {props.name && (
        <Grid item xs={6} md={4} style={{ textAlign: "left" }}>
          {props.name}
        </Grid>
      )}
      {props.phone && (
        <Grid
          item
          xs={props.circle ? 6 : 12}
          md={4}
          style={{ textAlign: "left" }}
        >
          +{props.link}
        </Grid>
      )}
      {props.link && (
        <Grid item xs={props.circle ? 2 : 3} md={props.circle ? 1 : 4}>
          <a
            href={
              props.link.indexOf("https") > -1
                ? props.link
                : `whatsapp://send?phone=+${props.link}`
            }
          >
            {props.circle ? (
              <WathApp size={props.size} />
            ) : (
              <div
                style={{
                  background:
                    "linear-gradient(to top, #20b038 0%, #60d66a 100%)",
                  borderRadius: 4,
                  height: props.size + 8,
                  maxWidth: "100%",
                  padding: "6px 4px 0 4px",
                  textAlign: "center"
                }}
              >
                <WathApp size={props.size} />
              </div>
            )}
          </a>
        </Grid>
      )}
      <Grid item xs={props.circle ? 2 : 3} md={props.circle ? 1 : 4}>
        <a
          href={
            props.linkForTelegram
              ? `tg://join?invite=${props.linkForTelegram}`
              : `tg://resolve?domain=${props.nicTgm}`
          }
        >
          {props.circle ? (
            <Telegram size={props.size} />
          ) : (
            <div
              style={{
                background: "#039be5",
                borderRadius: 4,
                height: props.size + 8,
                maxWidth: "100%",
                padding: "6px 4px 0 4px",
                textAlign: "center"
              }}
            >
              <Telegram size={props.size} />
            </div>
          )}
        </a>
      </Grid>

      {/* &ensp;
      <a href={`viber://add?number=${props.link}`}>
        <svg
          style={{ enableBackground: "new 0 0 512 512" }}
          version="1.1"
          viewBox="0 0 512 350"
          width={props.size * 1.3}
          height={props.size * 1.3}
        >
          <g id="Layer_1" />
          <g id="Layer_2">
            <g>
              <path
                style={{ display: "none", fill: "#FEFEFE" }}
                d="M512.4,512.4c-170.7,0-341.3,0-512,0c0-170.7,0-341.3,0-512c170.7,0,341.3,0,512,0    C512.4,171.1,512.4,341.8,512.4,512.4z"
              />
              <path
                style={{ fill: "#7143B9" }}
                d="M172,401.3c0.1-10.3,0.3-20.6,0.4-30.9c0-2.4,0.3-2.1-2-3.1c-12.8-5.1-24.7-11.5-35.2-20.5    c-11.1-9.5-19.8-20.7-26.2-33.8c-6.1-12.6-9.5-26-11.4-39.8c-0.7-5.4-1.1-10.8-1.1-16.2c0-5.1-0.8-10.2-0.9-15.3    c-0.1-3.6-0.2-7.3-0.1-10.9c0.1-7.4-0.6-14.8-0.3-22.2c0.6-16.8,3.6-33.2,10.2-48.8c9.4-22.2,24.8-39,45.8-50.8    c15.8-8.8,32.6-14.4,50.4-17.3c11-1.8,22-2.8,33.1-3.1c13.2-0.4,26.4-0.3,39.7-0.1c17,0.2,33.8,1.8,50.3,5.8    c15.7,3.7,30.5,9.6,44.1,18.3c16.5,10.5,29.1,24.4,37.6,42.1c5.3,10.9,8.5,22.4,10.6,34.3c1.5,8.8,2.1,17.6,2,26.5    c-0.1,9.4-0.3,18.8-0.9,28.1c-0.4,6.4-0.4,12.8-0.7,19.2c-1.1,17.7-4.7,34.8-12.5,50.8c-9.6,19.6-24.1,34.4-43.1,45.1    c-15.7,8.8-32.5,14.3-50.1,17.3c-13.8,2.3-27.8,3.4-41.8,3.4c-8.6,0-17.2-0.1-25.8,0c-4.4,0-8.8,0.1-13.2-0.4    c-1.4-0.1-2.4,0.3-3.3,1.4c-6.9,8-13.8,16-20.8,24c-8.6,9.9-17.2,19.8-25.7,29.6c-0.9,1-1.9,2-3,2.7c-2.7,1.8-5.5,0.8-6.2-2.4    c-0.2-0.7-0.1-1.6-0.1-2.3C171.7,421.8,171.9,411.5,172,401.3C172,401.3,172,401.3,172,401.3z"
              />
              <path
                style={{ fill: "#FFFFFF" }}
                d="M158.3,179.9c0.3-12.2,5.9-21.4,16-28c4.2-2.8,8.8-5,13.8-6c5.2-1.1,10.2-0.4,14.6,2.9    c2.9,2.1,5.3,4.7,7.7,7.3c8,8.8,14.8,18.3,20.3,28.9c0.9,1.8,1.7,3.7,2.3,5.7c1.7,5.7,0.4,10.7-3.4,15.1c-1.4,1.6-3,3-4.7,4.3    c-1.6,1.1-3.2,2.3-4.7,3.7c-3.5,3.3-4.5,7.3-3.4,11.9c0.4,1.9,1.1,3.7,1.8,5.4c4.1,9,9.6,17.1,16.7,24.1    c4.2,4.1,8.7,7.7,13.7,10.8c4.8,3,9.6,5.8,15,7.7c5,1.8,8.8,0.7,12.1-3.5c1.4-1.8,2.6-3.7,4.2-5.4c3-3.2,6.4-5.6,10.7-6.6    c4.7-1.1,9.2-0.3,13.5,1.6c4.1,1.7,7.7,4.3,11.3,6.8c7.2,5,14.2,10.3,21,15.8c1.8,1.5,3.4,3.2,4.9,5.1c3.5,4.6,4.2,9.7,2.7,15.1    c-1.4,5.2-4,9.7-7.4,13.8c-5.9,7.2-13.1,12.6-22,15.5c-2.4,0.8-4.8,1.3-7.3,1.5c-3.2,0.3-6.2-0.8-9.2-1.7    c-2.9-0.8-5.6-2.2-8.3-3.4c-13.5-6-26.5-13.1-38.8-21.3c-18.5-12.3-35-26.7-49.4-43.6c-10.7-12.6-20-26.2-27.9-40.8    c-5.1-9.3-9.6-18.9-13.4-28.8C159.2,189.5,158.3,184,158.3,179.9z"
              />
              <path
                style={{ fill: "#FFFFFF" }}
                d="M350.9,220.6c-0.1,3.3,0.3,5.7,0,8c-0.7,5.7-5.7,8.7-11,6.5c-3.2-1.3-4.2-4.1-4.5-7.2    c-0.3-3.8-0.6-7.6-1.2-11.4c-2-11.7-5.2-23-11.5-33.2c-4.7-7.6-10.8-13.8-18.4-18.7c-8.8-5.7-18.6-9-28.8-11.3    c-4.4-1-8.9-1.8-13.4-2.2c-1.1-0.1-2.1-0.3-3.1-0.7c-3.1-1.1-4.7-3.4-4.5-6.7c0.4-5.8,3.7-8.1,8.2-8.1c17.1-0.3,32.7,4.2,47,13.5    c8.4,5.5,15.9,12,22.1,19.9C343.8,184.3,350.8,201.7,350.9,220.6z"
              />
              <path
                style={{ fill: "#FFFFFF" }}
                d="M325,218.8c-0.1,1.7,0.2,3.9-0.3,6.1c-0.7,3.7-3.3,5.8-7.2,5.9c-4.1,0.1-6.7-1.7-7.6-5.4    c-0.4-1.4-0.4-2.9-0.6-4.4c-0.7-6.7-2.3-13.3-4.9-19.6c-3.6-9-10.1-15.1-19-18.9c-6.2-2.6-12.6-4.5-19.2-5.5    c-1.8-0.3-3.5-0.7-5.1-1.5c-0.8-0.4-1.5-0.9-2-1.6c-2.2-2.7-2.1-5.6-0.9-8.6c1-2.7,3.1-4.2,6-4.5c2.4-0.3,4.8,0.1,7.2,0.5    c6.7,1,13.2,2.7,19.4,5.6c7.9,3.8,14.7,9.1,20.5,15.7c4.9,5.7,8.6,12,11,19.1C324,207.2,324.9,212.7,325,218.8z"
              />
              <path
                style={{ fill: "#FFFFFF" }}
                d="M300.1,216.9c0,2,0,4-0.7,5.9c-1,2.9-3.1,4.6-6.1,5c-3.1,0.4-5.6-0.7-7.3-3.3c-0.9-1.3-1.3-2.7-1.5-4.2    c-0.4-2.5-0.7-5.1-1.7-7.4c-2.1-5.1-6.1-8.1-11.3-9.5c-1-0.3-2.1-0.5-3.1-0.7c-0.8-0.2-1.7-0.4-2.5-0.6c-3.7-1.1-5.6-3.4-5.6-6.6    c0-3.8,2.1-6.9,5.6-7.9c3.4-1,6.9-0.8,10.3,0.1c8.1,2.3,14.1,7.4,18.8,14.2C298.1,206.3,300.1,211.3,300.1,216.9z"
              />
            </g>
          </g>
        </svg>
      </a>
      &ensp;
      <a href={`tel:+${props.link}`}>
        <svg
          width={props.size || "24px"}
          height={props.size || "24px"}
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color || "rgb(123,143,87)"}
            d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
          />
        </svg>
      </a> */}
    </Grid>
  );
}

export default Phones;
