import React from "react";
import List from "../../components/list";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import Forma from "../../components/credit-form";
import { dates, Month } from "../shedule/dates";
import Paragraph from "../../components/p-array";
import { TurnedIn } from "@material-ui/icons";

const style = {
  h3: {
    fontFamily: "Roboto",
    fontSize: "1.2em",
    color: "rgb(100,100,100)",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "1.3em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "1em",
    color: "rgb(100,100,100)",
    textAlign: "justify",
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0
  }
};

export const contents = {
  head: {
    headDescript: "Профессиональное образование",
    head: "Основы motion design и анимации",
    headAfterDescript:
      "Креативные анимационные ролики с нуля: от идеи до готовой работы.",
    descript: [
      "Дистанционно",
      "Удостоверение о повышении квалификации",
      "Длительность: 1 месяц, 30 академических часов",
      "Рассрочка, налоговый вычет",
      "От 1990 руб. в месяц"
    ],
    text: "",
    imageSource: "/img/pro-motion-design/background.jpg",
    noVideo: true,
    styleHead: {
      fontSize: "3.5rem",
      lineHeight: "2.5rem"
    }
  },
  dates: {
    online: {
      day: dates.proMotionDesign.online.getDate(),
      month: Month(dates.proMotionDesign.online.getMonth()),
      type: "дистанционно"
    },
    ofline: false
  },
  screen2: {
    dates: false,
    quote: {
      text: (
        <span style={{ fontStyle: "normal", fontWeight: 700 }}>
          Для кого подойдет курс
        </span>
      )
    },
    text: (
      <List
        arr={[
          "Новичкам",
          "Дизайнерам",
          "Видеомонтажерам",
          "Маркетологам",
          "Фотографам",
          "Специалистам по рекламе"
        ]}
      />
    ),
    video: "/media/pro-motion-design-01.mp4",
    videoOnly: true,
    videoWidth: 360
  },
  text1: (
    <Paragraph
      content={[
        "В процессе обучения на курсе «PRO MOTION DESIGN» вы, под руководством опытного наставника научитесь разбираться в основах дизайна и анимации. Изучите четыре современных инструмента для создания 2d анимации и графики. Научитесь создавать рекламные ролики и анимировать объекты. Освоите работу с кадром, научитесь готовить модели для анимации, применять спецэффекты в работе.",
        "В ходе обучения выполните самостоятельно практические работы – 2 анимационных ролика. Оформите своё портфолио моушн-дизайнера и узнаете, как и где найти первые проекты и первых клиентов.",
        "И самое главное, узнаете секреты создания продающих видео!",
        "После обучения вы сможете самостоятельно делать рекламные графические ролики и тизеры, создавать анимационные видео с различными персонажами и объектами, изготавливать анимационный онлайн контент.",
        "Вы пройдете весь путь, от создания идеи, до готового видео с собственной музыкальной композицией и уникальной анимацией. "
      ]}
    />
  ),
  programma: {
    head: "Программа курса",
    content: [
      <List
        style={styles.p}
        num="none"
        olStyle={{ marginLeft: 0, paddingLeft: 0 }}
        arr={[
          "10 уроков MS Powerpoint",
          "7 уроков Powtoon",
          "5 уроков Adobe After Effects",
          "4 урока Spark Ar",
          "Один work shop с автором курса в режиме реального времени",
          "Получите перечень ресурсов, клиентов для поиска заказов",
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            В процессе обучения
          </h2>,
          <List
            style={styles.p}
            arr={[
              "Научитесь разбираться в основах дизайна и анимации.",
              "Изучите четыре современных инструмента для создания 2d анимации и графики.",
              "Научитесь создавать рекламные ролики и анимировать объекты.",
              "Освоите работу с кадром, научитесь готовить модели для анимации, применять спецэффекты в работе.",
              "В ходе обучения выполните самостоятельно практические работы — 2 анимационных ролика.",
              "Оформите своё портфолио моушн-дизайнера и узнаете, как и где найти первые проекты и первых клиентов.",
              "И самое главное, узнаете секреты создания продающих видео!"
            ]}
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            num="none"
          />,
          <h2
            style={{
              ...styles.h2,
              fontFamily: "PT Sans Narrow",
              textAlign: "left"
            }}
          >
            Результат обучения
          </h2>,
          <List
            style={styles.p}
            olStyle={{ marginLeft: 0, paddingLeft: 0 }}
            arr={[
              "После обучения вы сможете самостоятельно делать рекламные графические ролики и тизеры.",
              "Создавать анимационные видео с различными персонажами и объектами.",
              "Изготавливать анимационный онлайн контент."
            ]}
            num="none"
          />
        ]}
      />
    ]
  },
  teachers: [
    {
      image: "/img/pro-motion-design/anashkina-sq.jpg",
      name: (
        <>
          Автор и преподаватель курса:
          <br />
          Анашкина Елена
        </>
      ),
      text: (
        <List
          arr={[
            "IT-эксперт, который обеспечивает людей самыми востребованными профессиями.",
            "Основатель проекта «Одна точка».",
            "Более 14 лет в области цифрового дизайна и анимации.",
            "Более 4 лет занимается аналитикой, работой с данными, проектированием и программированием систем.",
            "С легкостью развеет миф о том, что «IT не для девочек»."
          ]}
          num="none"
        />
      ),
      instagram: false
      // buttons: {
      //   detail: "/natalya_gorskyh",
      //   linkInside: true
      // }
    }
  ],
  pay: {
    distant: {
      textButton: "Дистанционно",
      text: (
        <>
          <h3 style={styles.h3}>Стоимость обучения в дистанционном формате</h3>
          <p style={{ ...style.text, textAlign: "center" }}>Характеристики:</p>
          <div style={style.text}>
            Документ об окончании: удостоверение о повышении квалификации
            <br />
            Длительность: 26 онлайн уроков, 30 академических часов
            <br />
            Доступ к курсу: 180 дней
            <br />
            Формат обучения: самостоятельное изучение материала
          </div>
          <div style={style.text}>
            <List
              arr={[
                <>
                  Вариант оплаты
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>
                          19
                          <sup>900</sup>
                          рублей
                        </strong>{" "}
                        — единовременный платеж
                      </>
                    ]}
                  />
                </>,
                <>
                  Вариант оплаты: банковская рассрочка без переплаты на 10
                  месяцев
                  <br />
                  <List
                    style={style.text}
                    arr={[
                      <>
                        <strong>1 990 рублей</strong> ежемесячный платеж
                      </>,
                      <>
                        итого 10 платежей в сумме — 19
                        <sup>900</sup>
                        рублей
                        <br />
                        <div style={{ paddingTop: "1rem" }}>
                          <Forma
                            sum="19900.00"
                            name="Курс PRO MOTION DESIGN (дистанционное-обучение)"
                            test={false}
                            yellow
                          />
                        </div>
                      </>
                    ]}
                  />
                </>
              ]}
              style={style.text}
              num="digital"
            />
          </div>
        </>
      )
    }
  },
  graduatesWork: [
    {
      link: "/media/pro-motion-design-02.mp4",
      hVideo: 340
    },
    {
      link: "/media/pro-motion-design-03.mp4",
      hVideo: 340
    }
  ]
};
