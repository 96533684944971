import React from "react";

export default function About() {
  return (
    <p
      style={{
        fontFamily: "inherit",
        fontSize: "1em"
      }}
    >
      Курс посвящен разбору одной из самых актуальных тем, о которой говорят
      сегодня fashion-эксперты и арт-критики, дизайнеры и художники, фотографы и
      галеристы, маркетологи и искусствоведы &ndash; общность траекторий
      развития моды и искусства. Эта глобальная тема будет сужена до одного
      важнейшего ее аспекта: что есть концептуальное искусство и можно ли его
      называть искусством, в чем суть концептуальной моды и как относится к
      одежде, которая не принимается и не используется большинством.
    </p>
  );
}
