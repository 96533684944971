import React from "react";
import Helmet from "react-helmet";
import { Form } from "./functions";
import Page from "../../components/page";
import "./style.css";

const Fashion = props => {
  const title = "Расписание курсов";
  return (
    <>
      <Helmet>
        <title>Расписание курсов ВШСДТ</title>
        <meta
          name="keywords"
          content="расписание, курсы, школа, онлайн, дистанционное, обучение"
        />
        <meta
          name="descript"
          content="Высшая школа стилистики, дизайна и технологий. Актуальное расписание. Годовое обучение. Очное обучение. Дистанционное обучение. Практики. Стажировки"
        />
      </Helmet>
      <Page lable={title}>
        <h1 className="head-level1" style={{ fontFamily: "PT Sans Narrow" }}>
          {title}
        </h1>
        <Form />
        {/* <CreateListSchedule start={new Date()} last={new Date(2022, 0, 1)} /> */}
      </Page>
    </>
  );
};

export default Fashion;
