import React from "react";
import List from "../../components/list";
import Paragraph from "../../components/p-array";
import { styles } from "../profession-imagemaker-stylist-shopper/styles";
import { Spoler } from "../../components/spoler/spoler";
import { dates, Month } from "../shedule/dates";
import ButtonForm from "../../components/button-messages";
import ButtonFree from "./button-free";
import Video from "../../components/video";
import Link from "react-router-dom/Link";
import Ig from "../../components/instagram";
import Slider from "react-slick";

const date = {
  online:
    dates.imagemaker.online.getDate() +
    " " +
    Month(dates.imagemaker.online.getMonth()),
  ofline:
    dates.imagemaker.ofline.getDate() +
    " " +
    Month(dates.imagemaker.ofline.getMonth()),
  online2:
    dates.imagemaker2.online.getDate() +
    " " +
    Month(dates.imagemaker2.online.getMonth())
};

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const contents = {
  noForms: true,
  head: {
    headDescript: "Профессиональное образование",
    title: "Курсы имиджмейкеров",
    head: (
      <>
        Профессия:
        <br />
        стилист-имиджмейкер
        <br />
        <span
          style={{
            fontSize: ".8em",
            fontFamily: "inherit"
          }}
        >
          как второе высшее
        </span>
      </>
    ),
    headAfterDescript: (
      <>
        Комплексная программа обучения. Полное погружение в атмосферу изучения
        моды, в практику имидж-консультирования частных клиентов, в
        бизнес-консультирование, в работу с fashion брендами и стилизацию
        фотосъемок
      </>
    ),
    descript: [
      <div style={{ paddingTop: "1rem" }}>
        Выбор траектории обучения из 3 модулей
      </div>,
      "Полный цикл: три специализации, три диплома",
      "Длительность от 4 месяцев до полутора лет",
      "Формат очно и онлайн. Практика",
      "Доступны: рассрочка, кредит, скидки",
      "Подготовительный курс без оплаты",
      "Налоговый вычет после обучения"
    ],
    text: "",
    imageComment: false,
    imageSource: "/img/second-degree-imagemaker/fullscreen-480.jpg",
    styleHead: {
      fontSize: "2.5rem",
      lineHeight: "3rem"
    },
    hashTags: [
      {
        link: "#programma",
        text: "программа"
      },
      {
        link: "#teachers",
        text: "преподаватели"
      },
      {
        link: "#price",
        text: "стоимость"
      }
    ],
    noVideo: true,
    // sizeVideo: 190,
    contacts: [
      {
        phone: "+7 (916) 058 00 92",
        phoneLink: "tel:+79160580092",
        name: "Евгения",
        tg: "evgeniya_pimenova",
        wa: "79160580092"
      },
      {
        phone: "+7 (915) 034 20 20",
        phoneLink: "tel:+79150342020",
        name: "Иван",
        tg: "@IvanGulienko",
        wa: "79150342020"
      }
    ]
  },
  text3block: [
    {
      title: "Ближайшие даты",
      text: [<>очно и онлайн</>, <>с {date.ofline}</>]
    },
    {
      title: "Профессии",
      text: ["Стилист, имиджмейкер,", "стилист фотосессий"]
    },
    {
      title: "Преподаватели",
      text: ["Российские и зарубежные практики —", "стилисты, fashion эксперты"]
    }
  ],
  academy: {
    title: "Академическое образование + реальная практика",
    text: (
      <>
        <p className="p-constructor">
          Программа готовит обучающихся к профессиональной деятельности в
          качестве стилистов, имиджмейкеров: консуль­тантов по имиджу и стилю,
          шоппинг ассистентов, стилистов фотосессий, тренд-аналитиков,
          имидж-консультантов для сферы бизнеса и политики.
        </p>
        <p className="p-constructor">
          Отдельные модули могут изучаться в целях повышения квалификации
          предпринимателей и специалистов компаний, ведущих деятельность в
          индустрии моды, дизайна, PR и корпоративной культуры, образования.
        </p>
        <p className="p-constructor">
          В программе предусмотрена достаточная теоретическая подготовка для
          развития как творческого, так и профес&shy;сионального мышления.
          Теория закрепляется регулярными практическими занятиями для
          приобретения устойчивых навыков в изучаемой профессиональной области.
        </p>
      </>
    ),
    image: (
      <img
        src="/img/second-degree-imagemaker/acad-education-g.jpg"
        alt="Академическое образование"
        style={{}}
      />
    )
  },
  text1: (
    <>
      <p className="p-constructor">
        Обучение по программе «Консультант по стилю» по государственному гранту.
      </p>
      <p className="p-constructor">
        Обучение реализуется в онлайн формате с живым общением с преподавателями
        на базе Национального исследовательского Томского государственного
        университета совместно с АНО ДПО Высшая школа стилистики, дизайна и
        технологий.
      </p>
      <p>
        <Link to="/style-consultant-tgu">Подробнее…</Link>
      </p>
    </>
  ),
  text1Media: (
    <>
      <div>
        <a href="/style-consultant-tgu" target="_blank">
          <img
            src="/img/second-degree-imagemaker/tgu-education.png"
            alt="Консультант по стилю. Бесплатное обучение по гос гранту"
            style={{ border: "1px solid black" }}
          />
        </a>
      </div>
    </>
  ),
  infos2: {
    head: "Индивидуальная траектория обучения",
    picture: "digital",
    pictureWord: "путь",
    noShess: true,
    texts: [
      <>
        Насыщенная программа. Большой объем знаний и домашних заданий. Минимум
        12 часов будут посвящены курсу еженедельно.
      </>,
      <>
        Индивидуальная программа. Собеседование,
        квали&shy;фици&shy;рован&shy;ная консуль&shy;тация. Предло&shy;жение с
        учетом ваших целей, возмож&shy;ностей и пожеланий.
      </>
    ]
  },
  infos3: <ButtonForm componentLink={<ButtonFree />} linkType="component" />,
  shess: [
    {
      media: (
        <img
          src="/img/sdi/diplom-2.jpg"
          style={{ maxWidth: 400, height: "auto" }}
        />
      ),
      text: (
        <p className="p-constructor">
          Слушатели, успешно прошедшие обучение, выполнившие учебный план в
          соответствии с рабочими программами модулей, сдавшие проектные работы,
          не имеющие академической задолженности и представившие все необходимые
          документы, получают документы установленного государством образца о
          профессиональной переподготовке или повышения квалификации с правом
          деятель­ности в изу­чен­ной профес­сио­наль­ной области по программам
          «Консультант по имиджу и стилю», «Имиджмейкер», «Фэшн стилист, стилист
          фотосессий»
        </p>
      )
    }
  ],
  textAndMedia: {
    head:
      "Что дает обучение? Изменения в жизни, реализацию планов или творческое удовлетворение?",
    content: [
      {
        text: (
          <>
            <h3 className="h3-constructor">Делайте мир прекрасным!</h3>
            <p className="p-constructor">
              Вы тоже сможете, как и наши выпускницы, заниматься любимым делом,
              стать свободными в своем выборе, понимать моду и создавать
              индивидуальный стиль.
            </p>
            <p className="p-constructor">
              Посмотрите интервью с успешным имидж-стилистом Лидой Акимовой{" "}
              <a href="https://instagram.com/lididilisa" target="_blank">
                @lididilisa
              </a>{" "}
              Лида активно консультирует клиентов, заряжает всех супер
              позитивной энергией, проводит мастер-классы и теперь она наставник
              и ведущая специальных очных практик для студентов в ВШСДТ
            </p>
          </>
        ),
        media: <Video link="fOZzI3bKXIU" />
      },
      {
        text: (
          <>
            <h3 className="h3-constructor">Реальная практика</h3>
            <p className="p-constructor no-padding">
              В процессе и после обучения в ВШСДТ публикации в международных
              журналах — это реально!
            </p>
            <p className="p-constructor no-padding">
              Жанна Рожнова <Ig a="zhannarozhnova" />, Имидж-стилист, визажист:
            </p>
            <List
              arr={[
                "стилизации фотосессий и публикации в международных журналах"
              ]}
              style={{ color: "rgb(100,100,100)" }}
            />
            <p className="p-constructor no-padding">
              Мария Стецурина <Ig a="mariastetsurina" /> и<br />
              Евгения Веретина <Ig a="everetina_official" />
            </p>
            <List
              arr={["участие в фотосессии для обложки французского журнала"]}
              style={{ color: "rgb(100,100,100)" }}
            />
            <p className="p-constructor">
              Смотрите{" "}
              <a href="/news/17082021" target="_blank">
                подробнее…
              </a>
            </p>
          </>
        ),
        media: (
          <div
            style={{
              width: 320,
              height: 240,
              margin: "0 auto"
            }}
          >
            <Slider {...settings}>
              <div>
                <Video link="YVpzoQ9aIfI" />
              </div>
              <div>
                <img src="/img/news/17082021_malvie.jpg" />
              </div>
            </Slider>
          </div>
        )
      },
      {
        text: (
          <>
            <div className="block-left-space">
              <h3 className="h3-constructor">Какие перспективы у профессии</h3>
              <p className="p-constructor">
                Смотрите вебинар о перспективах раз­вития в профес­сии стилиста,
                имидж­мейкера: специализации в профессии, успеш­ные кейсы и
                истории. Оцените, какие ниши и сег­менты можно занять в
                индус­трии моды. Убедитесь, что это по-настоящему по­тряса­ющая
                и творческая работа.
              </p>
              <p className="p-constructor">
                Спикер — Елена Прохорович <Ig a="elenacult_com" />
              </p>
            </div>
          </>
        ),
        media: <Video link="AGcaa7kGFLE" />
      }
    ]
  },
  miniFormHead: (
    <>
      Записаться на пробные занятия, получить полную программу курса или
      консультацию по обучению
    </>
  ),
  programma: {
    head: "Программа курса / траектория обучения",
    content: [
      <p>
        Комплексная траектория подготовки специалистов в области
        имидж-консалтинга и фэшн стилистики состоит из трех модулей по четыре
        месяца. Слушатели могут выбирать последовательность изучения модулей в
        зависимости от расписания наборов. Между модулями можно делать перерывы
        до 2-3 месяцев.
      </p>,
      <p>
        Можно выбрать только один или два интересующих модуля. Каждый модуль —
        это законченная программа обучения, дающая навыки в определенной сфере
        профессиональной деятельности.
      </p>,
      <p className="mt">
        <strong>
          Модуль / Профессия:
          <br />
          Консультант по имиджу и стилю
        </strong>
        <br />
        <span className="fsmall">
          Длительность: 4 месяца, 160 академ.часов + срс
          <br />
          Категория слушателей: базовое образование – без ограничений
        </span>
      </p>,
      <p>
        <strong>Цель:</strong>
        <br />
        овладеть на практике технологиями имидж-консультирования на основе
        подбора цвета, кроя, фактур, рисунка, гардеробных капсул в соответствии
        с индивидуальными физиологическими и психологическими особенностями
        человека (модели), модными трендами
      </p>,
      <p>
        <strong>Содержание:</strong>
      </p>,
      <List
        arr={[
          <>
            Мода XXI века, направления и тренды: основные группы современных
            стилей
          </>,
          <>Колористика и подбор индивидуальной цветовой палитры</>,
          <>Форма современного костюма. Типология фигур</>,
          <>Возможности и принципы визуальной коррекции фигур</>,
          <>
            Профессиональный глоссарий, изучение профессиональной лексики и
            терминологии
          </>,
          <>Материаловедение. Виды одежды</>,
          <>
            Аксессуары, прическа, макияж. Правила и способы подбора с учетом
            fashiontrends
          </>,
          <>Современный мужской стиль: образный подход, идеи, элементы</>,
          <>Стилевые типажи. Правила смешения стилей</>,
          <>
            Формирование рационального гардероба с учетом особенностей и запроса
            клиента
          </>,
          <>Алгоритмы работы с клиентами</>,
          <>Теория и практика шопинга</>,
          <p>
            <strong>Итог обучения:</strong>
          </p>,
          <>Формирование индивидуального и делового стиля</>,
          <>Создание стильных образов с учетом модных тенденций</>,
          <>Создание и оформление Stye Book (Книги стиля)</>,
          <>Презентация и продвижения услуг</>,
          <>Итоговая аттестация: экзамен</>,
          <>
            <strong>Документ:</strong> Диплом установленного государством
            образца
          </>
        ]}
        style={styles.p}
        olStyle={{ marginLeft: 10, paddingLeft: 0 }}
        num="none"
      />,
      <p className="mt">
        <strong>
          Модуль / Профессия:
          <br />
          Имиджмейкер
        </strong>
        <br />
        <span className="fsmall">
          Длительность: 4 месяца, 160 академ.часов +срс
          <br />
          Категория слушателей: базовое образование в области имиджа и стиля
        </span>
      </p>,
      <p>
        <strong>Цель:</strong>
        <br />
        овладеть на практике технологиями консультирования корпоративных и
        частных клиентов с учетом профессионального вида деятельности, в том
        числе с учетом взаимосвязи имиджа, позиционирования и репутации персоны
        (лидера), с управлением впечатлением и стратегией продвижения
      </p>,
      <p>
        <strong>Содержание:</strong>
      </p>,
      <List
        arr={[
          <>Публичный имидж как коммуникация</>,
          <>
            Принципы формирования общественного мнения и создания публичной
            карьеры
          </>,
          <>
            Идеологическое пространство современного мира: политика, бизнес,
            государство, философия
          </>,
          <>Психологические и социологические теории лидерства</>,
          <>
            Личность как основа имиджа. Визуальный образ и его роль в публичном
            имидже
          </>,
          <>Теория социального влияния. Управление социальными группами</>,
          <>Инструменты управления впечатлением</>,
          <>Психодиагностика и стилевые предпочтения</>,
          <>
            Принципы формирования профессионального стиля в современных реалиях
          </>,
          <>Дресс-коды, функциональный подход к мужской и женской стилистике</>,
          <>
            Поведенческие особенности презентации имиджа: формирования стиля
            публичного выступления
          </>,
          <>Оптимальное использование и практика применения PR-инструментов</>,
          <p>
            <strong>Итог обучения:</strong>
          </p>,
          <>Стратегия персонального бренда</>,
          <>Публикации: анализ имиджа публичных персон</>,
          <>Имидж-проект: разработка, тестирование, корректировка</>,
          <>Итоговая аттестация: зачет</>,
          <>
            <strong>Документ:</strong> Диплом установленного государством
            образца
          </>
        ]}
        style={styles.p}
        olStyle={{ marginLeft: 10, paddingLeft: 0 }}
        num="none"
      />,
      <p className="mt">
        <strong>
          Модуль / Профессия:
          <br />
          Фэшн стилист, стилист фотосессий
        </strong>
        <br />
        <span className="fsmall">
          Длительность: 4 месяца, 160 академ.часов +срс
          <br />
          Категория слушателей: базовое образование – без ограничений
        </span>
      </p>,
      <p>
        <strong>Цель:</strong>
        <br />
        овладеть на практике технологиями подготовки (организации, стилизации) и
        проведения fashion фотосессий для изданий (СМИ), дизайнеров, для
        портфолио, социальных сетей, частных клиентов
      </p>,
      <p>
        <strong>Содержание:</strong>
      </p>,
      <List
        arr={[
          <>Источники современной моды: история моды XX века</>,
          <>Мода XXI века, направления и тренды: ДНК брендов, идеи коллекций</>,
          <>
            Творческие методы в создании проектов. Методики стилизации.
            Ассоциации
          </>,
          <>Творческие методы в создании проектов. Эвристика</>,
          <>Fashion-стилистика. Законы и приемы композиции</>,
          <>Основы проектной работы − организации фотосессий</>,
          <>Принципы коммуникаций в проектах, работа с командой</>,
          <>Специфика работы стилиста с глянцем, рекламой, для подиума</>,
          <>
            Практика подготовки фотопроекта: концепция, выбор модели, локации
          </>,
          <>Практика реализации фотопроектов: фотосъемка на условиях TFP</>,
          <>Постпродакшн: презентация результатов, работа с медиаресурсами</>,
          <>Юридические аспекты работы стилиста</>,
          <p>
            <strong>Итог обучения:</strong>
          </p>,
          <>• студийная фотосессия</>,
          <>• фотосессия streetstyle</>,
          <>• фотосессия editorial</>,
          <>Итоговая аттестация: экзамен</>,
          <>
            <strong>Документ:</strong> Диплом установленного государством
            образца
          </>
        ]}
        style={styles.p}
        olStyle={{ marginLeft: 10, paddingLeft: 0 }}
        num="none"
      />,
      <p className="mt">
        <strong>
          Факультативный модуль.
          <br />
          Профессия: Fasnion-аналитик
        </strong>
        <br />
        <span className="fsmall">
          Длительность: 2 месяца, 40 академ.часов
          <br />
          Категория слушателей: базовое образование в области имиджа и стиля
        </span>
      </p>,
      <p>
        <strong>Содержание:</strong>
      </p>,
      <List
        arr={[
          <>Индустрия моды: art + бизнес + идеология</>,
          <>
            Структура индустрии: корпорации, бренды, «предсказатели»,
            «создатели» и «распространители» трендов
          </>,
          <>
            Основные стратегии развития модной индустрии и их связь с идеологией
          </>,
          <>
            Образный язык моды. Визуальные тренды последних лет: что продается
          </>,
          <>Стилевая система моды: что меняется и не меняется в моде</>,
          <>Концепты образов, коллекций и показов: три времени одновременно</>,
          <>
            Традиционные каналы коммуникации: недели моды, показы, лидеры
            мнений, стритстайл, авторский текст
          </>,
          <>Digital-коммуникации. Постмода: art + digital technology</>,
          <p>
            <strong>Итог обучения:</strong>
          </p>,
          <>Посты, публикации: анализ образов, коллекций, показов брендов;</>,
          <>анализ трендов, стратегий индустрии моды</>,
          <>Итоговая аттестация: зачет</>,
          <>
            <strong>Документ:</strong> Удостоверение о повышении квалификации
            (доп. услуга)
          </>
        ]}
        style={styles.p}
        olStyle={{ marginLeft: 10, paddingLeft: 0 }}
        num="none"
      />
    ]
  },
  formats: {
    head: "Дополнительная информация",
    content: [
      <Spoler
        title={<>Формат обучения: Очное обучение</>}
        text={
          <Paragraph
            content={[
              <>
                Максимальное количество практических занятий проходите очно (не
                менее 2х раз по графику + доп. занятия). Теорию Вы изучаете
                онлайн, в непосредственном контакте с преподавателями или в
                записи, как Вам удобнее. Новый очный формат актуален тем, кто
                ведет активный образ жизни, постоянно путешествует, и кто
                настроен при этом на долгосрочный полноценный результат.
                Максимум практики на проектах и мастер-классы с экспертами
                доступны именно в этом формате.
              </>,
              <>Группы: дневные, вечерние, выходного дня.</>,
              <>
                В процессе обучения: лекционные занятия онлайн, практика в
                аудиториях, в ТЦ, шоу-румах, музеях, на модных мероприятиях,
                фотосессиях, самостоятельная работа.
              </>,
              <>
                Преимущества: лекционные занятия без пропусков, практику можно
                восстановить без потери качества освоения материала. После
                обучения все лекционные занятия по программе курса будут
                доступны в записи в личном кабинете в течение 6 месяцев.
              </>
            ]}
          />
        }
      />,
      <Spoler
        title={<>Формат обучения: Онлайн (дистанционное) обучение</>}
        text={
          <Paragraph
            content={[
              <>
                Это обучение, где основное внимание уделяется живому общению с
                преподавателями онлайн в сочетании с изучением учебного
                материала в записи. Такой формат прекрасно заменяет очное
                обучение, когда в силу занятости или удаленности необходимо
                гибко подходить к планированию времени и его длительности для
                изучения отдельных дисциплин. На основе этого формата можно
                эффективно подбирать индивидуальную траекторию обучения. В
                процессе обучения предусмотрены защиты проектных и дипломных
                работ онлайн.
              </>,
              <>
                Для студентов, у которых есть возможность приехать в один из
                городов с очным или заочным обучением, предусмотрена возможность
                прохождения дополнительной очной практики.
              </>,
              <>Группы: утренние, вечерние, выходного дня</>,
              <>
                Формат обучения: более 70% занятий — живое общение с
                преподавателями и группой, + записи лекций + самостоятельная
                работа
              </>,
              <>
                Все занятия доступны в записи в личном кабинете в процессе
                обучение и в течение 6 месяцев после обучения.
              </>
            ]}
          />
        }
      />,
      <Spoler
        title="Варианты обучения: дипломная программа, вольный слушатель, отдельные модули"
        text={
          <>
            <p>
              <strong>Дипломная программа</strong>
            </p>
            <List
              arr={[
                <>
                  Обучение по программам «Имидж-консалтинг», «Имидж-консалтинг и
                  fashion-styling», «Консультант по имиджу и стилю»,
                  «Имиджмейкер», «Стилист фотосессий» реализуется на основании
                  Лицензии на образовательную деятельность и соответствует
                  требованиям, предъявляемым к программам профессиональной
                  переподготовки
                </>,
                <>
                  Нагрузка по программе, с учетом самостоятельной работы, не
                  менее 12 академических часов в неделю
                </>,
                <>
                  Ограничений для поступления нет. Обучение доступно для
                  начинающих
                </>,
                <>
                  Требования в процессе обучения: обязательно выполнение д/з,
                  тестов, экзамен.
                </>,
                <>
                  Выпускники других образовательных учреждений принимаются с
                  зачетом соответствующих дисциплин
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
            <p>
              <strong>Вольный слушатель</strong>
            </p>
            <List
              arr={[
                <>
                  Доступно обучение без требований к выполнению заданий и
                  экзаменов.
                </>,
                <>Вольные слушатели после обучения получают сертификат</>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
            <p>
              <strong>
                Обучение по программе отдельного интересующего модуля / теме
              </strong>
            </p>
            <List
              arr={[
                <>
                  Вы можете выбирать интересующие дисциплины/модули с
                  полноценным вовлечением в образовательный процесс с получением
                  удостоверения о повышении квалификации или диплома о
                  профессиональной переподготовке в зависимости от выбранного
                  набора модулей и тем.
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title="Альтернативные программы: 4 варианта"
        text={
          <>
            <List
              arr={[
                <>
                  <Link to="/style-your-life">
                    Консультант по стилю. Быстрый старт
                  </Link>{" "}
                  (диплом), 4 месяца
                </>,
                <>
                  <Link to="/fashion-styling">
                    Профессия: стилист фотосессий
                  </Link>{" "}
                  (повышение квалификации), 4 месяца
                </>,
                <>
                  <Link to="/style-consultant-tgu">
                    Консультант по стилю. Обучение оплачивает государство
                  </Link>{" "}
                  (повышение квалификации), 2.5 месяца
                </>,
                <>
                  <Link to="/self-style">Сам себе стилист</Link> (базовый курс),
                  1 месяц
                </>
              ]}
              style={styles.p}
              olStyle={{ marginLeft: 0, paddingLeft: 0 }}
              num="none"
            />
          </>
        }
      />,
      <Spoler
        title="Личный кабинет"
        text={
          <>
            <p className="p-constructor">
              {" "}
              Все обучающиеся получают доступ в личный кабинет с текущей
              информацией по обучению, доступом к материалам лекций. В удобное
              время вы сможете просматривать занятия и выполнять домашние
              задания. Главное — соблюдать график учебного процесса в целом. Вы
              сможете оценить работы других студентов и задать вопрос куратору
              курса, а также воспользоваться поддержкой специалистов учебного
              отдела.
            </p>
            <p className="p-constructor">
              Студенты так же получают доступ в закрытую группу (чат), где
              общаются с одногруппниками
            </p>
          </>
        }
      />
    ]
  },
  bot: {
    image: <img src="/img/other/open_lesson.jpg" alt="Бот Валерия" />,
    text: (
      <>
        <p className="p-constructor">
          <strong>Телеграмм группа открытого образования ВШСДТ</strong>
        </p>
        <p className="p-constructor">
          В группе Открытого образования публикуем информацию об открытых курсах
          и мероприятиях, или социальных проектах
        </p>
        <p className="p-constructor">
          Здесь Вы узнаете, когда и как большую часть материалов можно изучить
          без оплат и условий
        </p>
        <a href="https://t.me/+Sfmarwgq2CtCji81">
          <div
            style={{
              background: "rgb(40,168,233)",
              padding: ".5rem 1rem",
              color: "rgb(255,255,255)",
              textAlign: "center",
              margin: "2rem auto 0 auto",
              maxWidth: "max-content"
            }}
          >
            <img
              src="/img/telegram-bw.svg"
              style={{ height: "1rem", width: "1rem" }}
            />
            &ensp;
            <span>Подписаться</span>
          </div>
        </a>
      </>
    )
  },
  schedule: (
    <>
      <p>
        с 08.09.22 поток 2.150, модуль Консультант по стилю, формат очно с
        применением онлайн технологий, будни/вх день/вечер, предзапись
      </p>
      <p>
        с 08.09.22 поток 2.150, модуль Консультант по стилю, формат онлайн
        будни/вх день/вечер, предзапись
      </p>
      <p>
        с 15.11.22 поток 2.149, модуль Имиджмейкер, формат онлайн, будни,
        утро/вечер, предзапись
      </p>
      {/* <Spoler
        title="Показать все расписание"
        text={
          <>
            <p>
              группа 2.147 (онлайн/заочное обучение, вт., чт., сб. 10:00-13:00
              по мск), годовая программа обучения с 09.02.22
            </p>
            <p>
              группа 2.148 (очное-new обучение дневное / вечернее, дни
              уточняются), обучение с 16.02.22 по 8‑ми месячной программе
            </p>
          </>
        }
      /> */}
    </>
  ),
  aboutEducation: (
    <span>
      Программа обучения в очном и онлайн форматах состоит из 125&nbsp;занятий
      по 2&nbsp;академических часа.
      <br />
      График проведения занятий: по 2&nbsp;занятия 2-3&nbsp;раза в неделю.
      <br />
      Длительность всего курса — 5+&nbsp;месяцев.
    </span>
  ),
  afterEducation: {
    head: <>Смотрите интервью с выпускниками и партнерами ВШСДТ</>,
    title: <>Результат обучения — карьера, бизнес, профессия</>,
    videos: ["rYKZAsSnC84", "0s4k1IH3Pik", "gZeWO85gha0"]
  },
  teachers: [
    {
      image: "/img/teachers/320x240/svetlana-kononets.jpg",
      imageFilter: true,
      name: "Светлана Шабалина",
      text:
        "Практикующий имидж-стилист, дизайнер одежды, преподаватель Санкт-Петербургской школы телевидения, преподаватель Высшей школы стилистики.",
      instagram: false,
      buttons: {
        detail: "/svetlana_kononets",
        linkInside: true,
        video: "a1T_ln5fDlE"
      }
    },
    {
      image: "/img/teachers/moatti.jpg",
      name: "Юлия Моатти (Франция, Канны)",
      text: (
        <List
          arr={[
            <>Fashion стилист (Лазурный берег)</>,
            <>16 лет опыта в международной fashion индустрии</>,
            <>проекты для Valentino, YSL, Fendi, Linda Farrow</>,
            <>
              рекламные компании и editorial съемки для Eric Zemmour, Chopard,
              Misaki, журналов L'Officiel, Riviera Magazine, Lucy mag, Huf mag и
              др.
            </>
          ]}
        />
      ),
      instagram: "yuliamoatti_stylist",
      buttons: false
    },
    {
      image: "/img/fashion-styling/migranov.jpg",
      name: "Руслан Мигранов",
      text:
        "Ведущий лекций по теме «История моды XX века» в рамках \
      программы профессиональной переподготовки «Современные технологии \
      в работе имиджмейкера: персональный и корпоративный стиль». \
      Преподаватель курса «Визуальный мерчендайзинг и витринистика», \
      ведущий отдельных мастер-классов по моде и витринистике, а \
      также выездного арт-тура в Венецию «Мода и искусство Венеции»",
      instagram: false,
      buttons: {
        detail: "/ruslan_migranov",
        linkInside: true
      }
    },
    {
      image: "/img/teachers/320x240/liana-bahova.jpg",
      imageFilter: true,
      name: "Лиана Бахова",
      text:
        "Имидж-консультант, профайлер, автор программ по управлению впечатлением в бизнесе и межличностных коммуникациях",
      instagram: false,
      buttons: {
        detail: "/liana_bahova",
        linkInside: true,
        video: "m7lYgO78wNM",
        videoText: "видео"
      }
    },
    {
      image: "/img/fashion-styling/bonetskaya.jpg",
      name: "Марина Бонецкая",
      text:
        "Художник-дизайнер, доцент, автор курсов по созданию корпоративного и персонального имиджа и стиля, член Международной Организации «Союз дизайнеров».",
      instagram: false,
      buttons: {
        detail: "/marina_bonetskaya",
        linkInside: true
      }
    },
    {
      image: "/img/fashion-styling/stulov-st.jpg",
      name: "Евгений Стулов",
      text: (
        <List
          arr={[
            "Фотограф",
            "Преподаватель курса по специфике работы стилиста на fashion съемках; истории и практической стороне фотографии в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
            "Креативный директор Siberian Fashion Week",
            "Музыкальный продюсер, композитор, основатель и участник электронного проекта SOLARIS"
          ]}
        />
      ),
      instagram: "solaris_foto",
      buttons: {
        detail: false,
        video: "Fps72ns-MyI"
      }
    },
    {
      image: "/img/fashion-styling/maltseva-st.jpg",
      name: "Евгения Мальцева",
      text: (
        <List
          arr={[
            "Режиссер-постановщик модных показов",
            "Преподаватель курса «Стилизация модных показов» в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
            "Президент Siberian Fashion Week. CEO / Founder European Model Academy",
            "Профессиональный коуч по дисциплинам: дефиле, фотопозирование. Автор мастер-классов для профессиональных моделей и директоров модельных агентств"
          ]}
        />
      ),
      instagram: "evmaltseva",
      buttons: false
    },
    {
      image: "/img/fashion-styling/zhilova-e.jpg",
      name: "Эмилия Жилова",
      text: (
        <List
          arr={[
            "Продюсер фотосъемок, мужской стилист-имиджмейкер, beauty/fashion фотограф",
            "Преподаватель курса  «Продюсирование fashion съемок» в Высшей Школе Стилистики Дизайна и Технологий (ВШСДТ)",
            "Многократный призер фотоконкурсов (International Photography Awards, RussianArtWeek), колумнист Designers From Russia https://designersfromrussia.ru/",
            "MBA в РЭУ им. Плеханова, работа на руководящих должностях"
          ]}
        />
      ),
      instagram: "emiliazhilova",
      buttons: false
    },
    {
      image: "/img/political-style/teacher02.jpg",
      name: "Валерий Чередниченко",
      imageFilter: true,
      text: (
        <>
          Кандидат экономических наук, социолог, бизнес-тренер, политтехнолог
          Директор Института стратегических исследований и проектов.
          <br />
          Заведующий кафедрой партийной школы «Единой России» в Московской
          области Автор более 130 статей, монографий и методических пособий в
          области социологии, общественного мнения, политической рекламы и
          имиджа.
          <br />В течение 20 лет участвовал в различных политических кампаниях
          по выборам президентов России, Украины, Монголии, Абхазии, депутатов
          Государственной Думы, мэров городов и глав районов
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/valery_cherednichenko",
        linkInside: true
      }
    },
    {
      image: "/img/political-style/teacher01.jpg",
      name: "Мария Родина",
      imageFilter: true,
      text: (
        <>
          Кандидат политических наук, психолог, бизнес-тренер, политтехнолог
          <br />
          Директор фонда «Общественных инициатив»
          <br />
          Провела более 30 исследовательских проектов в области изучения
          политического и социального пространства
          <br />
          Разработала и реализацовала несколько десятков политических проектов,
          проектов в области политической рекламы и имиджа
          <br />
          Руководила кампаниями по выбору депутатов ГД, глав районов Московской
          области
        </>
      ),
      instagram: false,
      buttons: {
        detail: "/maria_rodina",
        linkInside: true
      }
    }
  ],
  teachersHead: "Преподаватели ",
  //очно   онлайн   дисциплины по выбору
  prices: {
    head: "Стоимость обучения",
    content: [
      <List
        arr={[
          <>Подготовительный курс онлайн — без оплаты, открытый доступ</>,
          <>Стоимость отдельного основного модуля (онлайн): 99600 рублей</>,
          <>
            Стоимость очной практики к основному модулю, 2 раза в неделю (8 раз
            в месяц) − 5 тыс.рублей в месяц
          </>
        ]}
      />,
      <center>
        <strong>Доступны:</strong>
      </center>,
      <List
        arr={[
          <>
            рассрочки от 3 до 12 платежей, кредиты на условиях банков-партнеров
          </>,
          <>скидка при раннем бронировании* — до 10 тыс.рублей</>,
          <>скидки по программе лояльности* — до 20%</>
        ]}
      />,
      <p style={{ fontSize: ".8em" }}>
        * Условия предоставления скидок, рассрочек, кредитов уточняйте у
        специалистов учебного отдела
        <br />
        ** Полная стоимость обучения в очном или дистанционном форматах
        рассчитывается в зависимости от выбранной траектории обучения с учетом
        скидок и рассрочек, закрепляется в договоре на обучение
      </p>,
      <Spoler
        title="Пример расчета стоимости очного обучения с применением дистанционных технологий"
        text={
          <Paragraph
            content={[
              <>•&emsp;Подготовительный курс онлайн — без оплаты</>,
              <>•&emsp;Стоимость 1-го модуля — 100 тыс.рублей</>,
              <>•&emsp;Стоимость 2-го модуля — 90 тыс.рублей</>,
              <>•&emsp;Стоимость 3-го модуля — 80 тыс.рублей</>,
              <>•&emsp;полная стоимость обучения — 270 тыс.рублей</>,
              <>
                Расчет приведен с учетом заключения договора на полный цикл
                обучения по трем модулям.
              </>,
              <>
                Ожидаемая длительность обучения на 3-х модулях зависит от
                успеваемости слушателя и сроков реализации программ по мере
                набора групп и составляет от 1 года до полутора лет
              </>
            ]}
          />
        }
      />,
      <Spoler
        title="Пример расчета стоимости онлайн обучения (заочного с применением дистанционных технологий)"
        text={
          <Paragraph
            content={[
              <>•&emsp;Подготовительный курс онлайн — без оплаты</>,
              <>•&emsp;Стоимость 1-го модуля — 80 тыс.рублей</>,
              <>•&emsp;Стоимость 2-го модуля — 75 тыс.рублей</>,
              <>•&emsp;Стоимость 3-го модуля — 60 тыс.рублей</>,
              <>•&emsp;полная стоимость обучения — 210 тыс.рублей</>,
              <>
                Расчет приведен с учетом заключения договора на полный цикл
                обучения по трем модулям.
              </>,
              <>
                Ожидаемая длительность обучения на 3-х модулях зависит от
                успеваемости слушателя и сроков реализации программ по мере
                набора групп и составляет от 1 года до полутора лет
              </>
            ]}
          />
        }
      />
    ]
  },

  garanty: {
    title: "Финансовая гарантия",
    text: (
      <>
        В программе действует безусловная гарантия на возврат 100% денег в
        течении 14 дней. Это значит, что если вы по какой-то причине передумаете
        учавствовать, мы вернем всю сумму
      </>
    )
  },
  cardSelfStyle: {
    image: {
      title: <>САМ СЕБЕ СТИЛИСТ</>,
      image: "/img/sdi/self-style.jpg"
    },
    text: {
      title: "Интенсив",
      diplom: "Сертификат",
      duration: "Длительность обучения: 1,5 месяца",
      specialisation:
        "Найти свой стиль, отвлечься и погрузиться в моду, получить удовольствие от процесса",
      process: (
        <Paragraph
          content={[
            <>
              Современные стилевые направления выстроятся в стройную систему, и
              вы поймете, как их смешивать, чтобы получать стильные эксклюзивные
              комплекты.
            </>,
            <>
              Все привычные и навязанные обществом стереотипы будут стерты,
              останется только индивидуальность каждого человека в разных ее
              проявлениях.
            </>
          ]}
        />
      ),
      link: {
        l: "/self-style",
        text: "подробнее"
      }
    }
  },

  cardConsultingStyleAndDesign: {
    image: {
      title: <>КОНСУЛЬТАНТ ПО ДИЗАЙНУ ИНТЕРЬЕРА</>,
      image: "/img/sdi/consulting-style-design.jpg"
    },
    text: {
      title: "Профессиональная переподготовка",
      diplom: "Диплом гос. Университета",
      duration: "Длительность обучения: 3,5 месяца",
      specialisation: (
        <>
          Создание Design Project folder и реализации своих идей в дизайне
          интерьера. Получение дополнительного дохода от консультирования по
          стилю и декорированию интерьера.
        </>
      ),
      process: (
        <List
          arr={[
            <>Style book — стиль, цвето-текстурная карта интерьера</>,
            <>Moodboard — «simple board» — 3D collage</>,
            <>Планировочное решение</>,
            <>Ведомость отделочных работ и материалов</>,
            <>Ведомость мебели и декора</>,
            <>Design Project folder</>,
            <>Все, что позволит Вам внедрить Вашу идею в реальном интерьере</>
          ]}
          num="none"
          olStyle={{ paddingLeft: 0 }}
        />
      ),
      link: {
        l: "/interior-your-life",
        text: "подробнее"
      }
    }
  }
};
